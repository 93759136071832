import {Injectable} from '@angular/core'
import {
  Applicant,
  Car,
  Child,
  Income,
  Loan,
  Property
} from '@sparbanken-syd/kalpylator'
import {BorgoPreconditions} from './borgo.service'

@Injectable({
  providedIn: 'root'
})
export class TypesService {

  /**
   * Simple abstraction to ge a proper loan
   *
   * @private
   */
  public getLoan(): Loan {
    return {
      amount: 0, id: '',
      interestRate: -1,
      loanType: 'MORTGAGE',
      mortgage: -1,
      type: 'loans'
    }
  }

  public getApplicant(): Applicant {
    return {
      id: '',
      type: 'applicants',
      cityDweller: false,
      occupationType: 'EMPLOYED',
      name: '',
      retired: false
    }
  }

  public getChild(): Child {
    return {
      id: '', type: 'children'
    }
  }

  public getIncome(): Income {
    return {
      applicantId: '', monthlyIncome: 0, taxFreeIncome: 0,
      id: '', type: 'incomes'
    }
  }

  public getProperty(): Property {
    return {
      id: '',
      type: 'properties',
      primary: false,
      propertyType: 'VILLA'
    }
  }

  public getCar(): Car {
    return {
      carType: 'OWN',
      id: '',
      type: 'cars',
      monthlyCost: 0
    }
  }

  public getBorgoPreconditions(): BorgoPreconditions {
    return {
      bottenPant: true,
      code220: true,
      foreignOrBox: true,
      protectedIdentity: true,
      samTaxerad: true,
      tredjemansPant: true,
      foreignCurrency: true,
      bostadsratt: true,
      aKassa: true,
      noConstructionCredit: true,
      thirdCountry: true,
      rentedOut: true,
      noAmortization: true,
      noCurrentBorgo: true
    }
  }
}
