var f = {
    1060: 34.602,
    1080: 34.507,
    1081: 34.517,
    1082: 35.112,
    1083: 34.592,
    1214: 32.832,
    1230: 31.142,
    1231: 31.972,
    1233: 30.502,
    1256: 33.057,
    1257: 31.107,
    1260: 33.072,
    1261: 30.502,
    1262: 31.507,
    1263: 32.157,
    1264: 32.532,
    1265: 33.007,
    1266: 33.102,
    1267: 33.847,
    1270: 32.622,
    1272: 34.517,
    1273: 34.832,
    1275: 32.867,
    1276: 32.782,
    1277: 32.462,
    1278: 32.352,
    1280: 33.162,
    1281: 33.222,
    1282: 32.197,
    1283: 32.169,
    1284: 31.697,
    1285: 32.467,
    1286: 32.092,
    1287: 32.322,
    1290: 33.352,
    1291: 32.617,
    1292: 32.267,
    1293: 33.272,
    1315: 34.977,
    1380: 33.139,
    1381: 33.677,
    1382: 33.427,
    1383: 32.602,
    1384: 33.677,
    1401: 32.827,
    1402: 32.242,
    1407: 33.872,
    1415: 34.002,
    1419: 34.102,
    1421: 34.867,
    1427: 34.412,
    1430: 35.792,
    1435: 34.037,
    1438: 35.779,
    1439: 35.275,
    1440: 34.192,
    1441: 32.962,
    1442: 33.974,
    1443: 33.947,
    1444: 34.325,
    1445: 34.135,
    1446: 33.734,
    1447: 34.885,
    1452: 33.992,
    1460: 35.439,
    1461: 35.107,
    1462: 34.797,
    1463: 33.972,
    1465: 34.542,
    1466: 34.383,
    1470: 34.88,
    1471: 34.51,
    1472: 33.98,
    1473: 34.114,
    1480: 33.302,
    1481: 32.667,
    1482: 33.757,
    1484: 34.862,
    1485: 34.522,
    1486: 34.277,
    1487: 34.579,
    1488: 34.73,
    1489: 33.674,
    1490: 33.775,
    1491: 33.482,
    1492: 34.855,
    1493: 33.695,
    1494: 33.809,
    1495: 34.289,
    1496: 34.064,
    1497: 34.26,
    1498: 34.52,
    1499: 34.355,
    1715: 34.864,
    1730: 34.889,
    1737: 34.672,
    1760: 35.325,
    1761: 35.29,
    1762: 34.529,
    1763: 34.859,
    1764: 35.105,
    1765: 34.762,
    1766: 34.219,
    1780: 33.722,
    1781: 34.514,
    1782: 34.909,
    1783: 34.579,
    1784: 34.349,
    1785: 34.12,
    1814: 35.677,
    1860: 35.447,
    1861: 34.732,
    1862: 36.04,
    1863: 35.317,
    1864: 34.722,
    1880: 34.377,
    1881: 34.627,
    1882: 34.972,
    1883: 35.04,
    1884: 35.532,
    1885: 35.547,
    1904: 34.272,
    1907: 34.057,
    1960: 33.802,
    1961: 33.527,
    1962: 34.512,
    1980: 31.997,
    1981: 34.212,
    1982: 33.872,
    1983: 33.842,
    1984: 34.222,
    2021: 34.862,
    2023: 35.082,
    2026: 34.812,
    2029: 34.407,
    2031: 34.387,
    2034: 34.917,
    2039: 35.462,
    2061: 34.981,
    2062: 34.812,
    2080: 34.482,
    2081: 34.767,
    2082: 34.887,
    2083: 34.752,
    2084: 34.462,
    2085: 34.502,
    2101: 35.152,
    2104: 35.312,
    2121: 34.252,
    2132: 34.942,
    2161: 34.702,
    2180: 34.497,
    2181: 33.962,
    2182: 33.992,
    2183: 34.157,
    2184: 33.864,
    2260: 35.682,
    2262: 35.457,
    2280: 35.642,
    2281: 34.792,
    2282: 35.507,
    2283: 35.692,
    2284: 35.002,
    2303: 35.947,
    2305: 36.117,
    2309: 34.853,
    2313: 35.947,
    2321: 34.897,
    2326: 35.247,
    2361: 35.372,
    2380: 34.582,
    2401: 35.477,
    2403: 35.282,
    2404: 35.587,
    2409: 35.362,
    2417: 35.527,
    2418: 35.652,
    2421: 35.452,
    2422: 35.902,
    2425: 36.077,
    2460: 35.482,
    2462: 35.677,
    2463: 35.877,
    2480: 34.932,
    2481: 35.327,
    2482: 34.842,
    2505: 35.042,
    2506: 35.742,
    2510: 35.217,
    2513: 34.967,
    2514: 34.657,
    2518: 33.967,
    2521: 35.645,
    2523: 34.627,
    2560: 34.572,
    2580: 34.727,
    2581: 34.327,
    2582: 34.692,
    2583: 34.632,
    2584: 35.162,
    "0114": 32.497,
    "0115": 32.13,
    "0117": 29.627,
    "0120": 32.072,
    "0123": 32.242,
    "0125": 32.347,
    "0126": 32.527,
    "0127": 33.312,
    "0128": 32.746,
    "0136": 32.762,
    "0138": 32.567,
    "0139": 32.497,
    "0140": 33.162,
    "0160": 30.597,
    "0162": 31.272,
    "0163": 31.177,
    "0180": 30.775,
    "0181": 33.342,
    "0182": 30.977,
    "0183": 32.27,
    "0184": 30.372,
    "0186": 30.832,
    "0187": 32.382,
    "0188": 32.907,
    "0191": 33.122,
    "0192": 33.042,
    "0305": 34.137,
    "0319": 35.242,
    "0330": 33.412,
    "0331": 35.147,
    "0360": 33.987,
    "0380": 33.677,
    "0381": 33.887,
    "0382": 34.312,
    "0428": 34.297,
    "0461": 33.872,
    "0480": 33.322,
    "0481": 33.847,
    "0482": 34.112,
    "0483": 33.752,
    "0484": 33.617,
    "0486": 33.422,
    "0488": 32.992,
    "0509": 35.002,
    "0512": 35.142,
    "0513": 33.922,
    "0560": 34.222,
    "0561": 34.857,
    "0562": 34.552,
    "0563": 34.982,
    "0580": 32.657,
    "0581": 34.327,
    "0582": 34.582,
    "0583": 34.127,
    "0584": 35.267,
    "0586": 34.302,
    "0604": 34.837,
    "0617": 34.977,
    "0642": 34.945,
    "0643": 34.8,
    "0662": 34.617,
    "0665": 34.072,
    "0680": 34.442,
    "0682": 35.027,
    "0683": 34.027,
    "0684": 34.582,
    "0685": 34.727,
    "0686": 35.047,
    "0687": 34.58,
    "0760": 34.752,
    "0761": 34.752,
    "0763": 35.027,
    "0764": 34.407,
    "0765": 34.712,
    "0767": 34.307,
    "0780": 32.992,
    "0781": 33.947,
    "0821": 34.997,
    "0834": 34.692,
    "0840": 35.122,
    "0860": 34.717,
    "0861": 34.857,
    "0862": 34.697,
    "0880": 34.572,
    "0881": 35.052,
    "0882": 35.087,
    "0883": 34.047,
    "0884": 35.157,
    "0885": 34.487,
    "0980": 34.802
  },
  A = {
    29: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 22],
      2101: [153, 0, 150, 3, 153, 52],
      2201: [164, 0, 158, 5, 164, 82],
      2301: [174, 0, 166, 8, 174, 112],
      2401: [185, 0, 175, 10, 185, 142],
      2501: [195, 0, 183, 12, 195, 172],
      2601: [206, 0, 191, 14, 206, 202],
      2701: [216, 0, 200, 16, 232, 232],
      2801: [218, 0, 200, 18, 262, 262],
      2901: [229, 0, 208, 21, 292, 292],
      3001: [239, 0, 216, 23, 322, 322],
      3101: [250, 0, 225, 25, 352, 352],
      3201: [260, 0, 233, 27, 382, 382],
      3301: [271, 0, 241, 29, 412, 412],
      3401: [273, 0, 241, 31, 442, 442],
      3501: [284, 0, 250, 34, 472, 472],
      3601: [294, 0, 258, 36, 502, 502],
      3701: [305, 0, 266, 38, 532, 532],
      3801: [315, 0, 275, 40, 562, 562],
      3901: [325, 0, 283, 42, 592, 592],
      4001: [328, 0, 283, 44, 622, 622],
      4101: [338, 0, 291, 46, 652, 652],
      4201: [349, 0, 300, 49, 682, 682],
      4301: [359, 0, 308, 61, 712, 712],
      4401: [370, 0, 316, 81, 742, 742],
      4501: [380, 0, 325, 102, 772, 772],
      4601: [382, 0, 325, 122, 802, 802],
      4701: [392, 0, 333, 143, 827, 827],
      4801: [403, 0, 341, 163, 852, 852],
      4901: [413, 0, 350, 183, 877, 877],
      5001: [423, 0, 358, 203, 899, 899],
      5101: [433, 0, 366, 223, 924, 924],
      5201: [443, 0, 375, 243, 947, 947],
      5301: [445, 19, 376, 263, 972, 972],
      5401: [455, 42, 386, 283, 997, 997],
      5501: [465, 64, 396, 303, 1019, 1019],
      5601: [475, 87, 406, 322, 1043, 1043],
      5701: [485, 109, 416, 342, 1065, 1065],
      5801: [495, 132, 426, 361, 1090, 1090],
      5901: [497, 154, 427, 381, 1114, 1114],
      6001: [507, 177, 437, 400, 1136, 1136],
      6101: [517, 199, 447, 420, 1160, 1160],
      6201: [527, 219, 457, 439, 1182, 1182],
      6301: [537, 242, 467, 459, 1207, 1207],
      6401: [547, 264, 477, 478, 1231, 1231],
      6501: [549, 287, 479, 498, 1253, 1253],
      6601: [559, 309, 488, 517, 1277, 1277],
      6701: [569, 332, 498, 537, 1299, 1299],
      6801: [579, 354, 508, 556, 1324, 1324],
      6901: [589, 377, 518, 576, 1348, 1348],
      7001: [599, 399, 528, 595, 1370, 1370],
      7101: [601, 422, 530, 615, 1394, 1394],
      7201: [611, 444, 540, 634, 1416, 1416],
      7301: [621, 467, 550, 654, 1441, 1441],
      7401: [632, 487, 560, 673, 1465, 1465],
      7501: [650, 509, 570, 693, 1487, 1487],
      7601: [668, 532, 580, 712, 1511, 1511],
      7701: [686, 554, 589, 732, 1533, 1533],
      7801: [705, 577, 591, 751, 1558, 1558],
      7901: [723, 599, 601, 771, 1582, 1582],
      8001: [741, 622, 611, 790, 1604, 1604],
      8101: [759, 644, 621, 810, 1628, 1628],
      8201: [777, 667, 631, 829, 1650, 1650],
      8301: [796, 689, 641, 849, 1675, 1675],
      8401: [814, 712, 642, 868, 1699, 1699],
      8501: [832, 734, 652, 887, 1721, 1721],
      8601: [850, 754, 662, 907, 1745, 1745],
      8701: [868, 777, 672, 926, 1767, 1767],
      8801: [887, 799, 682, 946, 1792, 1792],
      8901: [905, 822, 692, 966, 1816, 1816],
      9001: [923, 844, 694, 985, 1838, 1838],
      9101: [941, 867, 704, 1004, 1862, 1862],
      9201: [959, 889, 714, 1024, 1884, 1884],
      9301: [977, 904, 723, 1043, 1909, 1909],
      9401: [996, 924, 733, 1063, 1933, 1933],
      9501: [1014, 942, 742, 1082, 1955, 1955],
      9601: [1032, 959, 744, 1102, 1979, 1979],
      9701: [1050, 977, 753, 1121, 2001, 2001],
      9801: [1068, 997, 763, 1141, 2026, 2026],
      9901: [1087, 1014, 773, 1160, 2050, 2050],
      10001: [1105, 1031, 782, 1180, 2072, 2072],
      10101: [1123, 1048, 792, 1199, 2096, 2096],
      10201: [1141, 1068, 802, 1219, 2118, 2118],
      10301: [1159, 1085, 803, 1238, 2143, 2143],
      10401: [1177, 1102, 812, 1258, 2167, 2167],
      10501: [1196, 1119, 822, 1277, 2189, 2189],
      10601: [1214, 1138, 832, 1297, 2213, 2213],
      10701: [1232, 1155, 841, 1316, 2235, 2235],
      10801: [1250, 1173, 851, 1336, 2260, 2260],
      10901: [1268, 1190, 852, 1355, 2284, 2284],
      11001: [1286, 1209, 862, 1375, 2306, 2306],
      11101: [1305, 1226, 872, 1394, 2330, 2330],
      11201: [1323, 1243, 881, 1414, 2352, 2352],
      11301: [1341, 1263, 891, 1433, 2377, 2377],
      11401: [1359, 1280, 901, 1453, 2401, 2401],
      11501: [1377, 1297, 902, 1472, 2423, 2423],
      11601: [1396, 1314, 911, 1492, 2447, 2447],
      11701: [1414, 1333, 921, 1511, 2469, 2469],
      11801: [1432, 1350, 931, 1531, 2494, 2494],
      11901: [1450, 1368, 940, 1550, 2518, 2518],
      12001: [1468, 1385, 950, 1570, 2540, 2540],
      12101: [1487, 1404, 951, 1589, 2564, 2564],
      12201: [1505, 1421, 961, 1609, 2586, 2586],
      12301: [1523, 1438, 971, 1628, 2611, 2611],
      12401: [1541, 1455, 980, 1648, 2635, 2635],
      12501: [1559, 1475, 990, 1667, 2657, 2657],
      12601: [1578, 1492, 1e3, 1687, 2681, 2681],
      12701: [1596, 1509, 1009, 1706, 2703, 2703],
      12801: [1614, 1526, 1010, 1726, 2728, 2728],
      12901: [1632, 1545, 1020, 1745, 2750, 2750],
      13001: [1650, 1563, 1030, 1765, 2779, 2779],
      13101: [1669, 1580, 1039, 1785, 2808, 2808],
      13201: [1687, 1599, 1049, 1804, 2837, 2837],
      13301: [1706, 1616, 1059, 1824, 2867, 2867],
      13401: [1724, 1633, 1060, 1844, 2896, 2896],
      13501: [1743, 1650, 1070, 1864, 2925, 2925],
      13601: [1761, 1670, 1079, 1883, 2954, 2954],
      13701: [1780, 1687, 1089, 1903, 2984, 2984],
      13801: [1798, 1704, 1099, 1922, 3012, 3012],
      13901: [1815, 1721, 1108, 1941, 3041, 3041],
      14001: [1833, 1740, 1110, 1960, 3069, 3069],
      14101: [1850, 1758, 1119, 1979, 3097, 3097],
      14201: [1867, 1775, 1129, 1997, 3125, 3125],
      14301: [1885, 1792, 1138, 2016, 3154, 3154],
      14401: [1902, 1811, 1148, 2035, 3182, 3182],
      14501: [1920, 1828, 1158, 2054, 3210, 3210],
      14601: [1937, 1845, 1159, 2072, 3238, 3238],
      14701: [1955, 1862, 1169, 2091, 3267, 3267],
      14801: [1972, 1882, 1178, 2110, 3297, 3297],
      14901: [1990, 1899, 1188, 2129, 3328, 3328],
      15001: [2007, 1916, 1198, 2148, 3358, 3358],
      15101: [2025, 1933, 1207, 2166, 3389, 3389],
      15201: [2042, 1953, 1217, 2185, 3422, 3422],
      15301: [2060, 1970, 1218, 2204, 3453, 3453],
      15401: [2079, 1989, 1228, 2225, 3483, 3483],
      15501: [2102, 2013, 1238, 2250, 3514, 3514],
      15601: [2126, 2038, 1248, 2275, 3544, 3544],
      15701: [2150, 2062, 1258, 2299, 3577, 3577],
      15801: [2173, 2087, 1268, 2324, 3608, 3608],
      15901: [2197, 2111, 1270, 2349, 3638, 3638],
      16001: [2221, 2135, 1280, 2373, 3669, 3669],
      16101: [2245, 2160, 1291, 2398, 3700, 3700],
      16201: [2268, 2184, 1301, 2423, 3733, 3733],
      16301: [2292, 2208, 1311, 2448, 3763, 3763],
      16401: [2316, 2233, 1321, 2472, 3794, 3794],
      16501: [2339, 2257, 1323, 2497, 3824, 3824],
      16601: [2363, 2282, 1333, 2522, 3855, 3855],
      16701: [2387, 2306, 1343, 2547, 3888, 3888],
      16801: [2411, 2330, 1353, 2571, 3919, 3919],
      16901: [2434, 2355, 1363, 2596, 3949, 3949],
      17001: [2458, 2377, 1373, 2621, 3980, 3980],
      17101: [2482, 2401, 1375, 2646, 4011, 4011],
      17201: [2505, 2425, 1385, 2670, 4043, 4043],
      17301: [2529, 2450, 1395, 2695, 4074, 4074],
      17401: [2553, 2474, 1405, 2720, 4105, 4105],
      17501: [2577, 2499, 1416, 2744, 4135, 4135],
      17601: [2600, 2523, 1426, 2769, 4166, 4166],
      17701: [2624, 2547, 1436, 2794, 4199, 4199],
      17801: [2648, 2572, 1438, 2819, 4229, 4229],
      17901: [2671, 2596, 1448, 2843, 4260, 4260],
      18001: [2695, 2620, 1458, 2868, 4291, 4291],
      18101: [2719, 2645, 1468, 2893, 4321, 4321],
      18201: [2743, 2669, 1478, 2917, 4354, 4354],
      18301: [2766, 2694, 1488, 2942, 4385, 4385],
      18401: [2790, 2718, 1490, 2967, 4415, 4415],
      18501: [2814, 2742, 1500, 2992, 4446, 4446],
      18601: [2837, 2767, 1510, 3016, 4477, 4477],
      18701: [2861, 2791, 1520, 3041, 4510, 4510],
      18801: [2885, 2815, 1531, 3066, 4540, 4540],
      18901: [2909, 2840, 1541, 3091, 4571, 4571],
      19001: [2932, 2864, 1543, 3115, 4601, 4601],
      19101: [2956, 2889, 1553, 3140, 4632, 4632],
      19201: [2980, 2913, 1563, 3165, 4665, 4665],
      19301: [3004, 2937, 1573, 3190, 4696, 4696],
      19401: [3027, 2962, 1583, 3214, 4726, 4726],
      19501: [3051, 2986, 1593, 3239, 4757, 4757],
      19601: [3075, 3010, 1595, 3264, 4787, 4787],
      19701: [3098, 3033, 1604, 3288, 4820, 4820],
      19801: [3122, 3057, 1613, 3313, 4851, 4851],
      19901: [3146, 3081, 1622, 3338, 4881, 4881],
      20001: [3193, 3128, 1641, 3387, 4943, 4943],
      20201: [3241, 3172, 1651, 3437, 5006, 5006],
      20401: [3288, 3219, 1670, 3486, 5067, 5067],
      20601: [3336, 3267, 1688, 3536, 5131, 5131],
      20801: [3383, 3314, 1699, 3585, 5192, 5192],
      21001: [3430, 3361, 1717, 3635, 5253, 5253],
      21201: [3478, 3408, 1736, 3684, 5317, 5317],
      21401: [3525, 3455, 1746, 3733, 5378, 5378],
      21601: [3573, 3502, 1765, 3783, 5442, 5442],
      21801: [3620, 3549, 1783, 3832, 5503, 5503],
      22001: [3668, 3596, 1794, 3882, 5564, 5564],
      22201: [3715, 3643, 1812, 3931, 5628, 5628],
      22401: [3762, 3690, 1831, 3981, 5689, 5689],
      22601: [3810, 3737, 1849, 4030, 5753, 5753],
      22801: [3858, 3784, 1860, 4081, 5815, 5815],
      23001: [3907, 3832, 1878, 4132, 5878, 5878],
      23201: [3957, 3879, 1897, 4183, 5943, 5943],
      23401: [4006, 3923, 1907, 4234, 6006, 6006],
      23601: [4055, 3970, 1926, 4285, 6071, 6071],
      23801: [4104, 4015, 1944, 4336, 6134, 6134],
      24001: [4153, 4065, 1954, 4387, 6197, 6197],
      24201: [4202, 4112, 1973, 4438, 6262, 6262],
      24401: [4251, 4161, 1992, 4489, 6325, 6325],
      24601: [4300, 4208, 2002, 4541, 6390, 6390],
      24801: [4349, 4255, 2021, 4592, 6453, 6453],
      25001: [4398, 4305, 2039, 4643, 6516, 6516],
      25201: [4447, 4352, 2050, 4694, 6581, 6581],
      25401: [4496, 4399, 2068, 4745, 6644, 6644],
      25601: [4546, 4448, 2087, 4796, 6709, 6709],
      25801: [4595, 4495, 2097, 4847, 6772, 6772],
      26001: [4644, 4542, 2116, 4898, 6835, 6835],
      26201: [4693, 4592, 2134, 4949, 6900, 6900],
      26401: [4742, 4639, 2145, 5001, 6963, 6963],
      26601: [4791, 4688, 2163, 5052, 7028, 7028],
      26801: [4840, 4736, 2182, 5103, 7091, 7091],
      27001: [4889, 4783, 2192, 5154, 7154, 7154],
      27201: [4938, 4832, 2211, 5205, 7219, 7219],
      27401: [4987, 4879, 2230, 5256, 7282, 7282],
      27601: [5036, 4926, 2248, 5307, 7347, 7347],
      27801: [5085, 4976, 2259, 5358, 7410, 7410],
      28001: [5134, 5023, 2277, 5409, 7473, 7473],
      28201: [5184, 5072, 2296, 5460, 7538, 7538],
      28401: [5233, 5119, 2306, 5512, 7601, 7601],
      28601: [5282, 5166, 2325, 5563, 7666, 7666],
      28801: [5331, 5216, 2343, 5614, 7729, 7729],
      29001: [5380, 5263, 2354, 5665, 7792, 7792],
      29201: [5429, 5310, 2372, 5716, 7857, 7857],
      29401: [5478, 5359, 2391, 5767, 7920, 7920],
      29601: [5527, 5406, 2402, 5818, 7985, 7985],
      29801: [5576, 5456, 2451, 5869, 8048, 8048],
      30001: [5625, 5503, 2498, 5920, 8111, 8111],
      30201: [5674, 5550, 2545, 5972, 8176, 8176],
      30401: [5723, 5600, 2595, 6023, 8239, 8239],
      30601: [5773, 5647, 2642, 6074, 8304, 8304],
      30801: [5822, 5694, 2689, 6125, 8367, 8367],
      31001: [5871, 5743, 2738, 6176, 8430, 8430],
      31201: [5920, 5791, 2786, 6227, 8495, 8495],
      31401: [5969, 5839, 2834, 6278, 8558, 8558],
      31601: [6018, 5890, 2885, 6329, 8623, 8623],
      31801: [6067, 5938, 2933, 6380, 8686, 8686],
      32001: [6116, 5989, 2984, 6431, 8749, 8749],
      32201: [6165, 6037, 3032, 6483, 8814, 8814],
      32401: [6214, 6085, 3081, 6534, 8877, 8877],
      32601: [6263, 6136, 3131, 6585, 8942, 8942],
      32801: [6313, 6184, 3180, 6636, 9005, 9005],
      33001: [6362, 6233, 3228, 6687, 9068, 9068],
      33201: [6411, 6284, 3279, 6738, 9133, 9133],
      33401: [6460, 6332, 3327, 6789, 9196, 9196],
      33601: [6509, 6383, 3378, 6840, 9261, 9261],
      33801: [6558, 6431, 3426, 6891, 9324, 9324],
      34001: [6607, 6479, 3474, 6942, 9387, 9387],
      34201: [6656, 6530, 3525, 6994, 9452, 9452],
      34401: [6705, 6578, 3574, 7045, 9515, 9515],
      34601: [6754, 6627, 3622, 7096, 9580, 9580],
      34801: [6803, 6677, 3673, 7147, 9643, 9643],
      35001: [6852, 6726, 3721, 7198, 9706, 9706],
      35201: [6902, 6774, 3769, 7249, 9771, 9771],
      35401: [6951, 6825, 3820, 7300, 9834, 9834],
      35601: [7e3, 6873, 3868, 7351, 9899, 9899],
      35801: [7049, 6924, 3919, 7402, 9962, 9962],
      36001: [7098, 6972, 3967, 7454, 10025, 10025],
      36201: [7147, 7021, 4016, 7505, 10090, 10090],
      36401: [7196, 7071, 4067, 7556, 10153, 10153],
      36601: [7245, 7120, 4115, 7607, 10218, 10218],
      36801: [7294, 7168, 4163, 7658, 10281, 10281],
      37001: [7343, 7219, 4214, 7709, 10344, 10344],
      37201: [7392, 7267, 4262, 7760, 10409, 10409],
      37401: [7441, 7318, 4313, 7811, 10472, 10472],
      37601: [7490, 7364, 4359, 7862, 10532, 10532],
      37801: [7539, 7412, 4407, 7913, 10590, 10590],
      38001: [7588, 7460, 4456, 7964, 10648, 10648],
      38201: [7637, 7511, 4506, 8015, 10706, 10706],
      38401: [7687, 7560, 4555, 8066, 10764, 10764],
      38601: [7745, 7618, 4613, 8117, 10822, 10822],
      38801: [7803, 7676, 4671, 8167, 10880, 10880],
      39001: [7861, 7734, 4729, 8218, 10938, 10938],
      39201: [7919, 7792, 4787, 8269, 10996, 10996],
      39401: [7977, 7850, 4845, 8320, 11054, 11054],
      39601: [8035, 7908, 4903, 8371, 11112, 11112],
      39801: [8093, 7966, 4961, 8422, 11170, 11170],
      40001: [8151, 8024, 5019, 8473, 11228, 11228],
      40201: [8209, 8082, 5077, 8524, 11286, 11286],
      40401: [8267, 8140, 5135, 8574, 11344, 11344],
      40601: [8325, 8198, 5193, 8625, 11402, 11402],
      40801: [8383, 8256, 5251, 8676, 11460, 11460],
      41001: [8441, 8314, 5309, 8727, 11518, 11518],
      41201: [8499, 8372, 5367, 8778, 11576, 11576],
      41401: [8557, 8430, 5425, 8829, 11634, 11634],
      41601: [8615, 8488, 5483, 8880, 11692, 11692],
      41801: [8673, 8546, 5541, 8931, 11750, 11750],
      42001: [8731, 8604, 5599, 8981, 11808, 11808],
      42201: [8789, 8662, 5657, 9032, 11866, 11866],
      42401: [8847, 8720, 5715, 9083, 11924, 11924],
      42601: [8905, 8778, 5773, 9134, 11982, 11982],
      42801: [8963, 8836, 5831, 9185, 12040, 12040],
      43001: [9021, 8894, 5889, 9236, 12098, 12098],
      43201: [9079, 8952, 5947, 9287, 12156, 12156],
      43401: [9137, 9010, 6005, 9338, 12214, 12214],
      43601: [9195, 9068, 6063, 9388, 12272, 12272],
      43801: [9253, 9126, 6121, 9439, 12330, 12330],
      44001: [9311, 9184, 6179, 9490, 12388, 12388],
      44201: [9369, 9242, 6237, 9541, 12446, 12446],
      44401: [9427, 9300, 6295, 9592, 12504, 12504],
      44601: [9485, 9358, 6353, 9643, 12562, 12562],
      44801: [9543, 9416, 6411, 9694, 12620, 12620],
      45001: [9601, 9474, 6469, 9745, 12678, 12678],
      45201: [9659, 9532, 6527, 9795, 12736, 12736],
      45401: [9717, 9590, 6585, 9846, 12794, 12794],
      45601: [9775, 9648, 6643, 9897, 12852, 12852],
      45801: [9833, 9706, 6701, 9948, 12910, 12910],
      46001: [9891, 9764, 6759, 9999, 12968, 12968],
      46201: [9949, 9822, 6817, 10050, 13026, 13026],
      46401: [10007, 9880, 6875, 10101, 13084, 13084],
      46601: [10065, 9938, 6933, 10152, 13142, 13142],
      46801: [10123, 9996, 6991, 10202, 13200, 13200],
      47001: [10181, 10054, 7049, 10253, 13258, 13258],
      47201: [10239, 10112, 7107, 10304, 13316, 13316],
      47401: [10297, 10170, 7165, 10355, 13374, 13374],
      47601: [10355, 10228, 7223, 10406, 13432, 13432],
      47801: [10413, 10286, 7281, 10457, 13490, 13490],
      48001: [10471, 10344, 7339, 10508, 13548, 13548],
      48201: [10529, 10402, 7397, 10559, 13606, 13606],
      48401: [10587, 10460, 7455, 10609, 13664, 13664],
      48601: [10645, 10518, 7513, 10660, 13722, 13722],
      48801: [10703, 10576, 7571, 10711, 13780, 13780],
      49001: [10761, 10634, 7629, 10762, 13838, 13838],
      49201: [10819, 10692, 7687, 10813, 13896, 13896],
      49401: [10877, 10750, 7745, 10864, 13954, 13954],
      49601: [10935, 10808, 7803, 10915, 14012, 14012],
      49801: [10993, 10866, 7861, 10965, 14070, 14070],
      50001: [11051, 10924, 7923, 11016, 14128, 14128],
      50201: [11109, 10982, 7987, 11067, 14186, 14186],
      50401: [11167, 11040, 8051, 11118, 14244, 14244],
      50601: [11225, 11098, 8115, 11169, 14302, 14302],
      50801: [11283, 11156, 8179, 11220, 14360, 14360],
      51001: [11341, 11214, 8243, 11271, 14418, 14418],
      51201: [11424, 11293, 8329, 11347, 14501, 14501],
      51401: [11522, 11388, 8429, 11437, 14599, 14599],
      51601: [11620, 11482, 8530, 11528, 14697, 14697],
      51801: [11718, 11574, 8627, 11619, 14795, 14795],
      52001: [11816, 11668, 8728, 11710, 14893, 14893],
      52201: [11914, 11762, 8828, 11801, 14991, 14991],
      52401: [12012, 11856, 8928, 11892, 15089, 15089],
      52601: [12110, 11951, 9028, 11983, 15187, 15187],
      52801: [12208, 12045, 9129, 12074, 15285, 15285],
      53001: [12306, 12139, 9229, 12164, 15383, 15383],
      53201: [12404, 12233, 9329, 12255, 15481, 15481],
      53401: [12502, 12325, 9427, 12346, 15579, 15579],
      53601: [12600, 12419, 9527, 12437, 15677, 15677],
      53801: [12698, 12514, 9628, 12528, 15775, 15775],
      54001: [12796, 12608, 9728, 12619, 15873, 15873],
      54201: [12894, 12702, 9828, 12710, 15971, 15971],
      54401: [12992, 12796, 9928, 12801, 16069, 16069],
      54601: [13090, 12891, 10029, 12891, 16167, 16167],
      54801: [13188, 12985, 10129, 12982, 16265, 16265],
      55001: [13286, 13079, 10229, 13073, 16363, 16363],
      55201: [13384, 13171, 10327, 13164, 16461, 16461],
      55401: [13482, 13265, 10427, 13255, 16559, 16559],
      55601: [13580, 13360, 10527, 13346, 16657, 16657],
      55801: [13678, 13454, 10628, 13437, 16755, 16755],
      56001: [13776, 13548, 10728, 13528, 16853, 16853],
      56201: [13874, 13642, 10828, 13619, 16951, 16951],
      56401: [13972, 13737, 10928, 13709, 17049, 17049],
      56601: [14070, 13831, 11029, 13800, 17147, 17147],
      56801: [14168, 13923, 11126, 13891, 17245, 17245],
      57001: [14266, 14017, 11227, 13982, 17343, 17343],
      57201: [14364, 14111, 11327, 14073, 17441, 17441],
      57401: [14462, 14205, 11427, 14164, 17539, 17539],
      57601: [14560, 14300, 11527, 14255, 17637, 17637],
      57801: [14658, 14394, 11628, 14345, 17735, 17735],
      58001: [14756, 14485, 11725, 14436, 17833, 17833],
      58201: [14854, 14583, 11829, 14527, 17931, 17931],
      58401: [14952, 14681, 11933, 14618, 18029, 18029],
      58601: [15050, 14779, 12037, 14709, 18127, 18127],
      58801: [15148, 14877, 12141, 14800, 18225, 18225],
      59001: [15246, 14975, 12245, 14891, 18323, 18323],
      59201: [15344, 15073, 12349, 14982, 18421, 18421],
      59401: [15442, 15171, 12453, 15072, 18519, 18519],
      59601: [15540, 15269, 12557, 15163, 18617, 18617],
      59801: [15638, 15367, 12661, 15254, 18715, 18715],
      60001: [15736, 15465, 12765, 15345, 18813, 18813],
      60201: [15834, 15563, 12869, 15436, 18911, 18911],
      60401: [15932, 15661, 12973, 15527, 19009, 19009],
      60601: [16030, 15759, 13077, 15618, 19107, 19107],
      60801: [16128, 15857, 13181, 15709, 19205, 19205],
      61001: [16226, 15955, 13285, 15799, 19303, 19303],
      61201: [16324, 16053, 13389, 15890, 19401, 19401],
      61401: [16422, 16151, 13493, 15981, 19499, 19499],
      61601: [16520, 16249, 13597, 16072, 19597, 19597],
      61801: [16618, 16347, 13701, 16163, 19695, 19695],
      62001: [16716, 16445, 13805, 16254, 19793, 19793],
      62201: [16814, 16543, 13909, 16345, 19891, 19891],
      62401: [16912, 16641, 14013, 16435, 19989, 19989],
      62601: [17010, 16739, 14117, 16526, 20087, 20087],
      62801: [17108, 16837, 14221, 16617, 20185, 20185],
      63001: [17206, 16935, 14325, 16708, 20283, 20283],
      63201: [17304, 17033, 14429, 16799, 20381, 20381],
      63401: [17402, 17131, 14533, 16890, 20479, 20479],
      63601: [17500, 17229, 14637, 16981, 20577, 20577],
      63801: [17598, 17327, 14741, 17072, 20675, 20675],
      64001: [17696, 17425, 14845, 17162, 20773, 20773],
      64201: [17794, 17523, 14949, 17253, 20871, 20871],
      64401: [17892, 17621, 15053, 17344, 20969, 20969],
      64601: [17994, 17727, 15165, 17435, 21067, 21067],
      64801: [18098, 17829, 15273, 17526, 21165, 21165],
      65001: [18202, 17935, 15385, 17617, 21263, 21263],
      65201: [18306, 18037, 15493, 17708, 21361, 21361],
      65401: [18410, 18139, 15601, 17799, 21459, 21459],
      65601: [18514, 18245, 15713, 17889, 21557, 21557],
      65801: [18618, 18348, 15821, 17980, 21655, 21655],
      66001: [18722, 18454, 15934, 18071, 21753, 21753],
      66201: [18826, 18556, 16042, 18162, 21851, 21851],
      66401: [18930, 18658, 16150, 18253, 21949, 21949],
      66601: [19034, 18764, 16262, 18344, 22047, 22047],
      66801: [19138, 18866, 16370, 18435, 22145, 22145],
      67001: [19242, 18968, 16478, 18526, 22243, 22243],
      67201: [19346, 19074, 16590, 18616, 22341, 22341],
      67401: [19450, 19176, 16698, 18707, 22439, 22439],
      67601: [19554, 19283, 16810, 18798, 22537, 22537],
      67801: [19658, 19385, 16919, 18889, 22635, 22635],
      68001: [19762, 19487, 17027, 18980, 22733, 22733],
      68201: [19866, 19593, 17139, 19071, 22831, 22831],
      68401: [19970, 19695, 17247, 19162, 22929, 22929],
      68601: [20074, 19797, 17355, 19253, 23027, 23027],
      68801: [20178, 19903, 17467, 19343, 23125, 23125],
      69001: [20282, 20005, 17575, 19434, 23223, 23223],
      69201: [20386, 20112, 17687, 19525, 23321, 23321],
      69401: [20490, 20214, 17795, 19616, 23419, 23419],
      69601: [20594, 20316, 17904, 19707, 23517, 23517],
      69801: [20698, 20422, 18016, 19798, 23615, 23615],
      70001: [20802, 20524, 18124, 19889, 23713, 23713],
      70201: [20906, 20626, 18232, 19980, 23811, 23811],
      70401: [21010, 20732, 18344, 20070, 23909, 23909],
      70601: [21114, 20834, 18452, 20161, 24007, 24007],
      70801: [21218, 20940, 18564, 20252, 24105, 24105],
      71001: [21322, 21043, 18672, 20343, 24203, 24203],
      71201: [21426, 21145, 18780, 20434, 24301, 24301],
      71401: [21530, 21251, 18893, 20525, 24399, 24399],
      71601: [21634, 21353, 19001, 20616, 24497, 24497],
      71801: [21738, 21459, 19113, 20707, 24595, 24595],
      72001: [21842, 21561, 19221, 20797, 24693, 24693],
      72201: [21946, 21663, 19329, 20888, 24791, 24791],
      72401: [22050, 21769, 19441, 20979, 24889, 24889],
      72601: [22154, 21871, 19549, 21070, 24987, 24987],
      72801: [22258, 21974, 19657, 21161, 25085, 25085],
      73001: [22362, 22080, 19770, 21252, 25183, 25183],
      73201: [22466, 22182, 19878, 21343, 25281, 25281],
      73401: [22570, 22288, 19990, 21434, 25379, 25379],
      73601: [22674, 22390, 20098, 21524, 25477, 25477],
      73801: [22778, 22492, 20206, 21615, 25575, 25575],
      74001: [22882, 22598, 20318, 21706, 25673, 25673],
      74201: [22986, 22700, 20426, 21797, 25771, 25771],
      74401: [23090, 22802, 20534, 21888, 25869, 25869],
      74601: [23194, 22909, 20646, 21979, 25967, 25967],
      74801: [23298, 23011, 20755, 22070, 26065, 26065],
      75001: [23402, 23117, 20867, 22160, 26163, 26163],
      75201: [23506, 23219, 20975, 22251, 26261, 26261],
      75401: [23610, 23321, 21083, 22342, 26359, 26359],
      75601: [23714, 23427, 21195, 22433, 26457, 26457],
      75801: [23818, 23529, 21303, 22524, 26555, 26555],
      76001: [23922, 23631, 21411, 22615, 26653, 26653],
      76201: [24026, 23738, 21523, 22706, 26751, 26751],
      76401: [24130, 23840, 21631, 22797, 26849, 26849],
      76601: [24234, 23946, 21744, 22887, 26947, 26947],
      76801: [24338, 24048, 21852, 22978, 27045, 27045],
      77001: [24442, 24150, 21960, 23069, 27143, 27143],
      77201: [24546, 24256, 22072, 23160, 27241, 27241],
      77401: [24650, 24358, 22180, 23251, 27339, 27339],
      77601: [24754, 24460, 22288, 23342, 27437, 27437],
      77801: [24858, 24566, 22400, 23433, 27535, 27535],
      78001: [24962, 24669, 22508, 23524, 27633, 27633],
      78201: [25066, 24775, 22621, 23614, 27731, 27731],
      78401: [25170, 24877, 22729, 23705, 27829, 27829],
      78601: [25274, 24979, 22837, 23796, 27927, 27927],
      78801: [25378, 25085, 22949, 23887, 28025, 28025],
      79001: [25482, 25187, 23057, 23978, 28123, 28123],
      79201: [25586, 25289, 23165, 24069, 28221, 28221],
      79401: [25690, 25395, 23277, 24160, 28319, 28319],
      79601: [25794, 25497, 23385, 24251, 28417, 28417],
      79801: [25898, 25604, 23497, 24341, 28515, 28515],
      80001: [32, 32, 29, 30, 36, 36, "%"],
      80201: [32, 32, 29, 31, 36, 36, "%"],
      80401: [33, 32, 30, 31, 36, 36, "%"],
      82001: [33, 33, 30, 31, 36, 36, "%"],
      83601: [33, 33, 31, 31, 36, 36, "%"],
      84801: [34, 33, 31, 31, 36, 36, "%"],
      85401: [34, 33, 31, 31, 37, 37, "%"],
      86001: [34, 33, 31, 32, 37, 37, "%"],
      86401: [34, 34, 31, 32, 37, 37, "%"],
      87201: [34, 34, 32, 32, 37, 37, "%"],
      89601: [35, 34, 32, 32, 37, 37, "%"],
      91201: [35, 34, 33, 32, 37, 37, "%"],
      91401: [35, 35, 33, 32, 37, 37, "%"],
      92801: [35, 35, 33, 33, 38, 38, "%"],
      95001: [36, 35, 33, 33, 38, 38, "%"],
      95401: [36, 35, 34, 33, 38, 38, "%"],
      97001: [36, 36, 34, 33, 38, 38, "%"],
      100001: [36, 36, 35, 33, 38, 38, "%"],
      100401: [36, 36, 35, 34, 38, 38, "%"],
      101201: [37, 36, 35, 34, 38, 38, "%"],
      101601: [37, 36, 35, 34, 39, 39, "%"],
      103401: [37, 37, 35, 34, 39, 39, "%"],
      105201: [37, 37, 36, 34, 39, 39, "%"],
      108201: [38, 37, 36, 34, 39, 39, "%"],
      109601: [38, 37, 36, 35, 39, 39, "%"],
      110601: [38, 38, 36, 35, 39, 39, "%"],
      111001: [38, 38, 37, 35, 39, 39, "%"],
      112401: [38, 38, 37, 35, 40, 40, "%"],
      116201: [39, 38, 37, 35, 40, 40, "%"],
      117401: [39, 38, 38, 35, 40, 40, "%"],
      119001: [39, 39, 38, 35, 40, 40, "%"],
      120801: [39, 39, 38, 36, 40, 40, "%"],
      124601: [39, 39, 39, 36, 40, 40, "%"],
      125601: [40, 39, 39, 36, 41, 41, "%"],
      128601: [40, 40, 39, 36, 41, 41, "%"],
      132801: [40, 40, 40, 36, 41, 41, "%"],
      134201: [40, 40, 40, 37, 41, 41, "%"],
      136401: [41, 40, 40, 37, 41, 41, "%"],
      140001: [41, 41, 40, 37, 41, 41, "%"],
      142201: [41, 41, 41, 37, 41, 41, "%"],
      142401: [41, 41, 41, 37, 42, 42, "%"],
      149401: [42, 41, 41, 37, 42, 42, "%"],
      151201: [42, 41, 41, 38, 42, 42, "%"],
      153601: [42, 42, 42, 38, 42, 42, "%"],
      164201: [42, 42, 42, 38, 43, 43, "%"],
      165201: [43, 42, 42, 38, 43, 43, "%"],
      170001: [43, 43, 43, 38, 43, 43, "%"],
      173001: [43, 43, 43, 39, 43, 43, "%"],
      194201: [44, 44, 44, 39, 44, 44, "%"],
      202201: [44, 44, 44, 40, 44, 44, "%"],
      237401: [45, 45, 45, 40, 45, 45, "%"],
      243201: [45, 45, 45, 41, 45, 45, "%"],
      305001: [45, 45, 45, 42, 45, 45, "%"],
      305201: [46, 46, 46, 42, 46, 46, "%"],
      408801: [46, 46, 46, 43, 46, 46, "%"],
      427201: [47, 47, 47, 43, 47, 47, "%"],
      620001: [47, 47, 47, 44, 47, 47, "%"],
      712201: [48, 48, 48, 44, 48, 48, "%"],
      1282001: [48, 48, 48, 45, 48, 48, "%"]
    },
    30: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 23],
      2101: [153, 0, 150, 3, 153, 54],
      2201: [164, 0, 158, 5, 164, 85],
      2301: [174, 0, 166, 8, 174, 116],
      2401: [185, 0, 175, 10, 185, 147],
      2501: [195, 0, 183, 12, 195, 178],
      2601: [206, 0, 191, 14, 209, 209],
      2701: [216, 0, 200, 16, 240, 240],
      2801: [218, 0, 200, 18, 271, 271],
      2901: [229, 0, 208, 21, 302, 302],
      3001: [239, 0, 216, 23, 333, 333],
      3101: [250, 0, 225, 25, 364, 364],
      3201: [260, 0, 233, 27, 395, 395],
      3301: [271, 0, 241, 29, 426, 426],
      3401: [273, 0, 241, 31, 457, 457],
      3501: [284, 0, 250, 34, 488, 488],
      3601: [294, 0, 258, 36, 519, 519],
      3701: [305, 0, 266, 38, 550, 550],
      3801: [315, 0, 275, 40, 581, 581],
      3901: [325, 0, 283, 42, 612, 612],
      4001: [328, 0, 283, 44, 643, 643],
      4101: [338, 0, 291, 46, 674, 674],
      4201: [349, 0, 300, 49, 705, 705],
      4301: [359, 0, 308, 61, 736, 736],
      4401: [370, 0, 316, 82, 767, 767],
      4501: [380, 0, 325, 104, 798, 798],
      4601: [382, 0, 325, 125, 829, 829],
      4701: [392, 0, 333, 146, 855, 855],
      4801: [403, 0, 341, 166, 880, 880],
      4901: [413, 0, 350, 187, 906, 906],
      5001: [423, 0, 358, 208, 929, 929],
      5101: [433, 0, 366, 229, 955, 955],
      5201: [443, 0, 375, 249, 979, 979],
      5301: [445, 20, 376, 270, 1004, 1004],
      5401: [455, 43, 386, 291, 1030, 1030],
      5501: [465, 67, 396, 311, 1053, 1053],
      5601: [475, 90, 406, 331, 1078, 1078],
      5701: [485, 113, 416, 351, 1101, 1101],
      5801: [495, 136, 426, 372, 1126, 1126],
      5901: [497, 160, 427, 392, 1151, 1151],
      6001: [507, 183, 437, 412, 1174, 1174],
      6101: [517, 206, 447, 432, 1199, 1199],
      6201: [527, 227, 457, 452, 1222, 1222],
      6301: [537, 250, 467, 472, 1247, 1247],
      6401: [547, 273, 477, 493, 1272, 1272],
      6501: [549, 297, 479, 513, 1295, 1295],
      6601: [559, 320, 488, 533, 1320, 1320],
      6701: [569, 343, 498, 553, 1343, 1343],
      6801: [579, 366, 508, 573, 1368, 1368],
      6901: [589, 390, 518, 593, 1393, 1393],
      7001: [599, 413, 528, 613, 1416, 1416],
      7101: [601, 436, 530, 634, 1441, 1441],
      7201: [614, 459, 540, 654, 1464, 1464],
      7301: [633, 483, 550, 674, 1489, 1489],
      7401: [651, 503, 560, 694, 1514, 1514],
      7501: [670, 526, 570, 714, 1537, 1537],
      7601: [689, 550, 580, 734, 1562, 1562],
      7701: [708, 573, 589, 754, 1585, 1585],
      7801: [727, 596, 591, 775, 1610, 1610],
      7901: [745, 619, 601, 795, 1635, 1635],
      8001: [764, 643, 611, 815, 1658, 1658],
      8101: [783, 666, 621, 835, 1683, 1683],
      8201: [802, 689, 631, 855, 1706, 1706],
      8301: [820, 712, 641, 875, 1731, 1731],
      8401: [839, 736, 642, 895, 1756, 1756],
      8501: [858, 759, 652, 916, 1779, 1779],
      8601: [877, 780, 662, 936, 1804, 1804],
      8701: [896, 803, 672, 956, 1827, 1827],
      8801: [915, 826, 682, 976, 1852, 1852],
      8901: [933, 849, 692, 996, 1877, 1877],
      9001: [952, 873, 694, 1016, 1900, 1900],
      9101: [971, 896, 704, 1037, 1925, 1925],
      9201: [990, 919, 714, 1057, 1948, 1948],
      9301: [1008, 935, 723, 1077, 1973, 1973],
      9401: [1027, 955, 733, 1097, 1998, 1998],
      9501: [1046, 973, 742, 1117, 2021, 2021],
      9601: [1065, 991, 744, 1137, 2046, 2046],
      9701: [1084, 1010, 753, 1157, 2069, 2069],
      9801: [1102, 1030, 763, 1178, 2094, 2094],
      9901: [1121, 1048, 773, 1198, 2119, 2119],
      10001: [1140, 1066, 782, 1218, 2142, 2142],
      10101: [1159, 1083, 792, 1238, 2167, 2167],
      10201: [1178, 1103, 802, 1258, 2190, 2190],
      10301: [1196, 1121, 803, 1278, 2215, 2215],
      10401: [1215, 1139, 812, 1298, 2240, 2240],
      10501: [1234, 1156, 822, 1318, 2263, 2263],
      10601: [1253, 1177, 832, 1339, 2288, 2288],
      10701: [1271, 1194, 841, 1359, 2311, 2311],
      10801: [1290, 1212, 851, 1379, 2336, 2336],
      10901: [1309, 1229, 852, 1399, 2361, 2361],
      11001: [1328, 1250, 862, 1419, 2384, 2384],
      11101: [1347, 1267, 872, 1439, 2409, 2409],
      11201: [1365, 1285, 881, 1460, 2432, 2432],
      11301: [1384, 1305, 891, 1480, 2457, 2457],
      11401: [1403, 1323, 901, 1500, 2482, 2482],
      11501: [1422, 1340, 902, 1520, 2505, 2505],
      11601: [1441, 1358, 911, 1540, 2530, 2530],
      11701: [1459, 1378, 921, 1560, 2553, 2553],
      11801: [1478, 1396, 931, 1581, 2578, 2578],
      11901: [1497, 1413, 940, 1601, 2603, 2603],
      12001: [1516, 1431, 950, 1621, 2626, 2626],
      12101: [1535, 1451, 951, 1641, 2651, 2651],
      12201: [1553, 1469, 961, 1661, 2674, 2674],
      12301: [1572, 1487, 971, 1681, 2699, 2699],
      12401: [1591, 1504, 980, 1701, 2724, 2724],
      12501: [1610, 1524, 990, 1721, 2747, 2747],
      12601: [1629, 1542, 1e3, 1742, 2772, 2772],
      12701: [1647, 1560, 1009, 1762, 2795, 2795],
      12801: [1666, 1577, 1010, 1782, 2820, 2820],
      12901: [1685, 1597, 1020, 1802, 2843, 2843],
      13001: [1704, 1615, 1030, 1822, 2873, 2873],
      13101: [1723, 1633, 1039, 1843, 2903, 2903],
      13201: [1742, 1653, 1049, 1863, 2934, 2934],
      13301: [1761, 1671, 1059, 1884, 2964, 2964],
      13401: [1780, 1688, 1060, 1904, 2994, 2994],
      13501: [1799, 1706, 1070, 1925, 3024, 3024],
      13601: [1819, 1726, 1079, 1945, 3055, 3055],
      13701: [1838, 1744, 1089, 1965, 3085, 3085],
      13801: [1856, 1761, 1099, 1985, 3114, 3114],
      13901: [1874, 1779, 1108, 2005, 3144, 3144],
      14001: [1892, 1799, 1110, 2024, 3173, 3173],
      14101: [1910, 1817, 1119, 2044, 3202, 3202],
      14201: [1928, 1834, 1129, 2063, 3231, 3231],
      14301: [1946, 1852, 1138, 2082, 3261, 3261],
      14401: [1965, 1872, 1148, 2102, 3290, 3290],
      14501: [1983, 1890, 1158, 2121, 3319, 3319],
      14601: [2001, 1908, 1159, 2141, 3348, 3348],
      14701: [2019, 1925, 1169, 2160, 3378, 3378],
      14801: [2037, 1945, 1178, 2180, 3409, 3409],
      14901: [2055, 1963, 1188, 2199, 3441, 3441],
      15001: [2073, 1981, 1198, 2219, 3473, 3473],
      15101: [2091, 1998, 1207, 2238, 3504, 3504],
      15201: [2109, 2018, 1217, 2258, 3539, 3539],
      15301: [2128, 2036, 1218, 2277, 3570, 3570],
      15401: [2147, 2056, 1228, 2299, 3602, 3602],
      15501: [2171, 2081, 1238, 2324, 3634, 3634],
      15601: [2196, 2107, 1248, 2350, 3665, 3665],
      15701: [2221, 2132, 1258, 2376, 3699, 3699],
      15801: [2245, 2157, 1268, 2401, 3731, 3731],
      15901: [2270, 2182, 1270, 2427, 3763, 3763],
      16001: [2294, 2208, 1280, 2452, 3795, 3795],
      16101: [2319, 2233, 1291, 2478, 3826, 3826],
      16201: [2343, 2258, 1301, 2504, 3860, 3860],
      16301: [2368, 2283, 1311, 2529, 3892, 3892],
      16401: [2392, 2308, 1321, 2555, 3924, 3924],
      16501: [2417, 2334, 1323, 2580, 3955, 3955],
      16601: [2442, 2359, 1333, 2606, 3987, 3987],
      16701: [2466, 2384, 1343, 2632, 4021, 4021],
      16801: [2491, 2409, 1353, 2657, 4053, 4053],
      16901: [2515, 2434, 1363, 2683, 4085, 4085],
      17001: [2540, 2457, 1373, 2708, 4116, 4116],
      17101: [2564, 2482, 1375, 2734, 4148, 4148],
      17201: [2589, 2508, 1385, 2760, 4182, 4182],
      17301: [2613, 2533, 1395, 2785, 4214, 4214],
      17401: [2638, 2558, 1405, 2811, 4245, 4245],
      17501: [2663, 2583, 1416, 2836, 4277, 4277],
      17601: [2687, 2608, 1426, 2862, 4309, 4309],
      17701: [2712, 2634, 1436, 2888, 4343, 4343],
      17801: [2736, 2659, 1438, 2913, 4375, 4375],
      17901: [2761, 2684, 1448, 2939, 4406, 4406],
      18001: [2785, 2709, 1458, 2964, 4438, 4438],
      18101: [2810, 2734, 1468, 2990, 4470, 4470],
      18201: [2834, 2760, 1478, 3016, 4504, 4504],
      18301: [2859, 2785, 1488, 3041, 4536, 4536],
      18401: [2884, 2810, 1490, 3067, 4567, 4567],
      18501: [2908, 2835, 1500, 3092, 4599, 4599],
      18601: [2933, 2861, 1510, 3118, 4631, 4631],
      18701: [2957, 2886, 1520, 3144, 4665, 4665],
      18801: [2982, 2911, 1531, 3169, 4696, 4696],
      18901: [3006, 2936, 1541, 3195, 4728, 4728],
      19001: [3031, 2961, 1543, 3220, 4760, 4760],
      19101: [3055, 2987, 1553, 3246, 4791, 4791],
      19201: [3080, 3012, 1563, 3272, 4826, 4826],
      19301: [3105, 3037, 1573, 3297, 4857, 4857],
      19401: [3129, 3062, 1583, 3323, 4889, 4889],
      19501: [3154, 3087, 1593, 3348, 4921, 4921],
      19601: [3178, 3112, 1595, 3374, 4952, 4952],
      19701: [3203, 3136, 1604, 3400, 4986, 4986],
      19801: [3227, 3161, 1613, 3425, 5018, 5018],
      19901: [3252, 3185, 1622, 3451, 5050, 5050],
      20001: [3301, 3234, 1641, 3502, 5113, 5113],
      20201: [3350, 3280, 1651, 3553, 5179, 5179],
      20401: [3399, 3329, 1670, 3604, 5242, 5242],
      20601: [3448, 3378, 1688, 3656, 5308, 5308],
      20801: [3497, 3426, 1699, 3707, 5372, 5372],
      21001: [3546, 3475, 1717, 3758, 5435, 5435],
      21201: [3596, 3524, 1736, 3809, 5501, 5501],
      21401: [3645, 3573, 1746, 3860, 5564, 5564],
      21601: [3694, 3621, 1765, 3912, 5630, 5630],
      21801: [3743, 3670, 1783, 3963, 5693, 5693],
      22001: [3792, 3719, 1794, 4014, 5757, 5757],
      22201: [3841, 3768, 1812, 4065, 5823, 5823],
      22401: [3890, 3816, 1831, 4116, 5886, 5886],
      22601: [3939, 3865, 1849, 4168, 5952, 5952],
      22801: [3989, 3914, 1860, 4220, 6016, 6016],
      23001: [4040, 3963, 1878, 4272, 6081, 6081],
      23201: [4091, 4011, 1897, 4325, 6149, 6149],
      23401: [4142, 4058, 1907, 4378, 6214, 6214],
      23601: [4192, 4107, 1926, 4431, 6281, 6281],
      23801: [4243, 4153, 1944, 4484, 6346, 6346],
      24001: [4294, 4204, 1954, 4537, 6411, 6411],
      24201: [4345, 4253, 1973, 4590, 6479, 6479],
      24401: [4395, 4304, 1992, 4642, 6544, 6544],
      24601: [4446, 4353, 2002, 4695, 6611, 6611],
      24801: [4497, 4401, 2021, 4748, 6676, 6676],
      25001: [4548, 4453, 2039, 4801, 6741, 6741],
      25201: [4598, 4501, 2050, 4854, 6809, 6809],
      25401: [4649, 4550, 2068, 4907, 6874, 6874],
      25601: [4700, 4601, 2087, 4960, 6941, 6941],
      25801: [4751, 4650, 2097, 5012, 7006, 7006],
      26001: [4801, 4699, 2116, 5065, 7071, 7071],
      26201: [4852, 4750, 2134, 5118, 7139, 7139],
      26401: [4903, 4799, 2145, 5171, 7204, 7204],
      26601: [4954, 4850, 2163, 5224, 7271, 7271],
      26801: [5004, 4899, 2182, 5277, 7336, 7336],
      27001: [5055, 4947, 2192, 5329, 7401, 7401],
      27201: [5106, 4999, 2211, 5382, 7469, 7469],
      27401: [5157, 5047, 2230, 5435, 7534, 7534],
      27601: [5208, 5096, 2248, 5488, 7601, 7601],
      27801: [5258, 5147, 2259, 5541, 7666, 7666],
      28001: [5309, 5196, 2277, 5594, 7731, 7731],
      28201: [5360, 5247, 2296, 5647, 7799, 7799],
      28401: [5411, 5296, 2306, 5699, 7864, 7864],
      28601: [5461, 5345, 2340, 5752, 7931, 7931],
      28801: [5512, 5396, 2391, 5805, 7996, 7996],
      29001: [5563, 5445, 2440, 5858, 8061, 8061],
      29201: [5614, 5493, 2489, 5911, 8129, 8129],
      29401: [5664, 5545, 2540, 5964, 8194, 8194],
      29601: [5715, 5593, 2589, 6017, 8261, 8261],
      29801: [5766, 5645, 2640, 6069, 8326, 8326],
      30001: [5817, 5693, 2688, 6122, 8391, 8391],
      30201: [5867, 5742, 2737, 6175, 8459, 8459],
      30401: [5918, 5793, 2789, 6228, 8524, 8524],
      30601: [5969, 5842, 2837, 6281, 8591, 8591],
      30801: [6020, 5891, 2886, 6334, 8656, 8656],
      31001: [6070, 5942, 2937, 6386, 8721, 8721],
      31201: [6121, 5991, 2986, 6439, 8789, 8789],
      31401: [6172, 6041, 3036, 6492, 8854, 8854],
      31601: [6223, 6094, 3089, 6545, 8921, 8921],
      31801: [6273, 6144, 3139, 6598, 8986, 8986],
      32001: [6324, 6196, 3191, 6651, 9051, 9051],
      32201: [6375, 6246, 3241, 6704, 9119, 9119],
      32401: [6426, 6296, 3291, 6756, 9184, 9184],
      32601: [6476, 6349, 3344, 6809, 9251, 9251],
      32801: [6527, 6399, 3394, 6862, 9316, 9316],
      33001: [6578, 6449, 3444, 6915, 9381, 9381],
      33201: [6629, 6501, 3496, 6968, 9449, 9449],
      33401: [6679, 6551, 3546, 7021, 9514, 9514],
      33601: [6730, 6604, 3599, 7074, 9581, 9581],
      33801: [6781, 6654, 3649, 7126, 9646, 9646],
      34001: [6832, 6704, 3699, 7179, 9711, 9711],
      34201: [6882, 6756, 3751, 7232, 9779, 9779],
      34401: [6933, 6806, 3801, 7285, 9844, 9844],
      34601: [6984, 6856, 3851, 7338, 9911, 9911],
      34801: [7035, 6909, 3904, 7391, 9976, 9976],
      35001: [7085, 6959, 3954, 7443, 10041, 10041],
      35201: [7136, 7009, 4004, 7496, 10109, 10109],
      35401: [7187, 7061, 4056, 7549, 10174, 10174],
      35601: [7238, 7111, 4106, 7602, 10241, 10241],
      35801: [7288, 7164, 4159, 7655, 10306, 10306],
      36001: [7339, 7214, 4209, 7708, 10371, 10371],
      36201: [7390, 7264, 4259, 7761, 10439, 10439],
      36401: [7441, 7316, 4311, 7813, 10504, 10504],
      36601: [7492, 7366, 4361, 7866, 10571, 10571],
      36801: [7542, 7416, 4411, 7919, 10636, 10636],
      37001: [7593, 7469, 4464, 7972, 10701, 10701],
      37201: [7644, 7519, 4514, 8025, 10769, 10769],
      37401: [7694, 7571, 4566, 8078, 10834, 10834],
      37601: [7745, 7619, 4614, 8130, 10896, 10896],
      37801: [7796, 7669, 4664, 8183, 10956, 10956],
      38001: [7846, 7719, 4714, 8236, 11016, 11016],
      38201: [7897, 7771, 4766, 8288, 11076, 11076],
      38401: [7948, 7821, 4816, 8341, 11136, 11136],
      38601: [8008, 7881, 4876, 8393, 11196, 11196],
      38801: [8068, 7941, 4936, 8446, 11256, 11256],
      39001: [8128, 8001, 4996, 8499, 11316, 11316],
      39201: [8188, 8061, 5056, 8551, 11376, 11376],
      39401: [8248, 8121, 5116, 8604, 11436, 11436],
      39601: [8308, 8181, 5176, 8657, 11496, 11496],
      39801: [8368, 8241, 5236, 8709, 11556, 11556],
      40001: [8428, 8301, 5296, 8762, 11616, 11616],
      40201: [8488, 8361, 5356, 8814, 11676, 11676],
      40401: [8548, 8421, 5416, 8867, 11736, 11736],
      40601: [8608, 8481, 5476, 8920, 11796, 11796],
      40801: [8668, 8541, 5536, 8972, 11856, 11856],
      41001: [8728, 8601, 5596, 9025, 11916, 11916],
      41201: [8788, 8661, 5656, 9078, 11976, 11976],
      41401: [8848, 8721, 5716, 9130, 12036, 12036],
      41601: [8908, 8781, 5776, 9183, 12096, 12096],
      41801: [8968, 8841, 5836, 9235, 12156, 12156],
      42001: [9028, 8901, 5896, 9288, 12216, 12216],
      42201: [9088, 8961, 5956, 9341, 12276, 12276],
      42401: [9148, 9021, 6016, 9393, 12336, 12336],
      42601: [9208, 9081, 6076, 9446, 12396, 12396],
      42801: [9268, 9141, 6136, 9498, 12456, 12456],
      43001: [9328, 9201, 6196, 9551, 12516, 12516],
      43201: [9388, 9261, 6256, 9604, 12576, 12576],
      43401: [9448, 9321, 6316, 9656, 12636, 12636],
      43601: [9508, 9381, 6376, 9709, 12696, 12696],
      43801: [9568, 9441, 6436, 9761, 12756, 12756],
      44001: [9628, 9501, 6496, 9814, 12816, 12816],
      44201: [9688, 9561, 6556, 9867, 12876, 12876],
      44401: [9748, 9621, 6616, 9919, 12936, 12936],
      44601: [9808, 9681, 6676, 9972, 12996, 12996],
      44801: [9868, 9741, 6736, 10025, 13056, 13056],
      45001: [9928, 9801, 6796, 10077, 13116, 13116],
      45201: [9988, 9861, 6856, 10130, 13176, 13176],
      45401: [10048, 9921, 6916, 10182, 13236, 13236],
      45601: [10108, 9981, 6976, 10235, 13296, 13296],
      45801: [10168, 10041, 7036, 10288, 13356, 13356],
      46001: [10228, 10101, 7096, 10340, 13416, 13416],
      46201: [10288, 10161, 7156, 10393, 13476, 13476],
      46401: [10348, 10221, 7216, 10445, 13536, 13536],
      46601: [10408, 10281, 7276, 10498, 13596, 13596],
      46801: [10468, 10341, 7336, 10551, 13656, 13656],
      47001: [10528, 10401, 7396, 10603, 13716, 13716],
      47201: [10588, 10461, 7456, 10656, 13776, 13776],
      47401: [10648, 10521, 7516, 10709, 13836, 13836],
      47601: [10708, 10581, 7576, 10761, 13896, 13896],
      47801: [10768, 10641, 7636, 10814, 13956, 13956],
      48001: [10828, 10701, 7696, 10866, 14016, 14016],
      48201: [10888, 10761, 7756, 10919, 14076, 14076],
      48401: [10948, 10821, 7816, 10972, 14136, 14136],
      48601: [11008, 10881, 7876, 11024, 14196, 14196],
      48801: [11068, 10941, 7936, 11077, 14256, 14256],
      49001: [11128, 11001, 7996, 11130, 14316, 14316],
      49201: [11188, 11061, 8056, 11182, 14376, 14376],
      49401: [11248, 11121, 8116, 11235, 14436, 14436],
      49601: [11308, 11181, 8176, 11287, 14496, 14496],
      49801: [11368, 11241, 8236, 11340, 14556, 14556],
      50001: [11428, 11301, 8301, 11393, 14616, 14616],
      50201: [11488, 11361, 8367, 11445, 14676, 14676],
      50401: [11548, 11421, 8433, 11498, 14736, 14736],
      50601: [11608, 11481, 8499, 11550, 14796, 14796],
      50801: [11668, 11541, 8565, 11603, 14856, 14856],
      51001: [11728, 11601, 8631, 11656, 14916, 14916],
      51201: [11813, 11684, 8719, 11733, 15001, 15001],
      51401: [11913, 11781, 8823, 11826, 15101, 15101],
      51601: [12013, 11879, 8926, 11919, 15201, 15201],
      51801: [12113, 11974, 9027, 12011, 15301, 15301],
      52001: [12213, 12071, 9131, 12104, 15401, 15401],
      52201: [12313, 12169, 9234, 12196, 15501, 15501],
      52401: [12413, 12266, 9338, 12289, 15601, 15601],
      52601: [12513, 12364, 9441, 12382, 15701, 15701],
      52801: [12613, 12461, 9545, 12474, 15801, 15801],
      53001: [12713, 12559, 9648, 12567, 15901, 15901],
      53201: [12813, 12656, 9752, 12659, 16001, 16001],
      53401: [12913, 12751, 9853, 12752, 16101, 16101],
      53601: [13013, 12849, 9956, 12845, 16201, 16201],
      53801: [13113, 12946, 10060, 12937, 16301, 16301],
      54001: [13213, 13044, 10163, 13030, 16401, 16401],
      54201: [13313, 13141, 10267, 13123, 16501, 16501],
      54401: [13413, 13239, 10370, 13215, 16601, 16601],
      54601: [13513, 13336, 10474, 13308, 16701, 16701],
      54801: [13613, 13434, 10577, 13400, 16801, 16801],
      55001: [13713, 13531, 10681, 13493, 16901, 16901],
      55201: [13813, 13626, 10782, 13586, 17001, 17001],
      55401: [13913, 13724, 10885, 13678, 17101, 17101],
      55601: [14013, 13821, 10989, 13771, 17201, 17201],
      55801: [14113, 13919, 11092, 13863, 17301, 17301],
      56001: [14213, 14016, 11196, 13956, 17401, 17401],
      56201: [14313, 14114, 11299, 14049, 17501, 17501],
      56401: [14413, 14211, 11403, 14141, 17601, 17601],
      56601: [14513, 14309, 11506, 14234, 17701, 17701],
      56801: [14613, 14404, 11607, 14327, 17801, 17801],
      57001: [14713, 14501, 11711, 14419, 17901, 17901],
      57201: [14813, 14599, 11814, 14512, 18001, 18001],
      57401: [14913, 14696, 11918, 14604, 18101, 18101],
      57601: [15013, 14794, 12021, 14697, 18201, 18201],
      57801: [15113, 14891, 12125, 14790, 18301, 18301],
      58001: [15213, 14984, 12224, 14882, 18401, 18401],
      58201: [15313, 15084, 12330, 14975, 18501, 18501],
      58401: [15413, 15184, 12436, 15068, 18601, 18601],
      58601: [15513, 15284, 12542, 15160, 18701, 18701],
      58801: [15613, 15384, 12648, 15253, 18801, 18801],
      59001: [15713, 15484, 12754, 15345, 18901, 18901],
      59201: [15813, 15584, 12860, 15438, 19001, 19001],
      59401: [15913, 15684, 12966, 15531, 19101, 19101],
      59601: [16013, 15784, 13072, 15623, 19201, 19201],
      59801: [16113, 15884, 13178, 15716, 19301, 19301],
      60001: [16213, 15984, 13284, 15808, 19401, 19401],
      60201: [16313, 16084, 13390, 15901, 19501, 19501],
      60401: [16413, 16184, 13496, 15994, 19601, 19601],
      60601: [16513, 16284, 13602, 16086, 19701, 19701],
      60801: [16613, 16384, 13708, 16179, 19801, 19801],
      61001: [16713, 16484, 13814, 16272, 19901, 19901],
      61201: [16813, 16584, 13920, 16364, 20001, 20001],
      61401: [16913, 16684, 14026, 16457, 20101, 20101],
      61601: [17013, 16784, 14132, 16549, 20201, 20201],
      61801: [17113, 16884, 14238, 16642, 20301, 20301],
      62001: [17213, 16984, 14344, 16735, 20401, 20401],
      62201: [17313, 17084, 14450, 16827, 20501, 20501],
      62401: [17413, 17184, 14556, 16920, 20601, 20601],
      62601: [17513, 17284, 14662, 17012, 20701, 20701],
      62801: [17613, 17384, 14768, 17105, 20801, 20801],
      63001: [17713, 17484, 14874, 17198, 20901, 20901],
      63201: [17813, 17584, 14980, 17290, 21001, 21001],
      63401: [17913, 17684, 15086, 17383, 21101, 21101],
      63601: [18013, 17784, 15192, 17476, 21201, 21201],
      63801: [18113, 17884, 15298, 17568, 21301, 21301],
      64001: [18213, 17984, 15404, 17661, 21401, 21401],
      64201: [18313, 18084, 15510, 17753, 21501, 21501],
      64401: [18413, 18184, 15616, 17846, 21601, 21601],
      64601: [18518, 18293, 15731, 17939, 21701, 21701],
      64801: [18624, 18397, 15841, 18031, 21801, 21801],
      65001: [18730, 18505, 15955, 18124, 21901, 21901],
      65201: [18836, 18609, 16065, 18216, 22001, 22001],
      65401: [18942, 18714, 16175, 18309, 22101, 22101],
      65601: [19048, 18822, 16290, 18402, 22201, 22201],
      65801: [19154, 18926, 16400, 18494, 22301, 22301],
      66001: [19260, 19034, 16514, 18587, 22401, 22401],
      66201: [19366, 19139, 16624, 18680, 22501, 22501],
      66401: [19472, 19243, 16735, 18772, 22601, 22601],
      66601: [19578, 19351, 16849, 18865, 22701, 22701],
      66801: [19684, 19455, 16959, 18957, 22801, 22801],
      67001: [19790, 19559, 17069, 19050, 22901, 22901],
      67201: [19896, 19668, 17184, 19143, 23001, 23001],
      67401: [20002, 19772, 17294, 19235, 23101, 23101],
      67601: [20108, 19880, 17408, 19328, 23201, 23201],
      67801: [20214, 19984, 17518, 19421, 23301, 23301],
      68001: [20320, 20089, 17628, 19513, 23401, 23401],
      68201: [20426, 20197, 17743, 19606, 23501, 23501],
      68401: [20532, 20301, 17853, 19698, 23601, 23601],
      68601: [20638, 20405, 17963, 19791, 23701, 23701],
      68801: [20744, 20514, 18077, 19884, 23801, 23801],
      69001: [20850, 20618, 18188, 19976, 23901, 23901],
      69201: [20956, 20726, 18302, 20069, 24001, 24001],
      69401: [21062, 20830, 18412, 20161, 24101, 24101],
      69601: [21168, 20934, 18522, 20254, 24201, 24201],
      69801: [21274, 21043, 18637, 20347, 24301, 24301],
      70001: [21380, 21147, 18747, 20439, 24401, 24401],
      70201: [21486, 21251, 18857, 20532, 24501, 24501],
      70401: [21592, 21359, 18971, 20625, 24601, 24601],
      70601: [21698, 21464, 19081, 20717, 24701, 24701],
      70801: [21804, 21572, 19196, 20810, 24801, 24801],
      71001: [21910, 21676, 19306, 20902, 24901, 24901],
      71201: [22016, 21780, 19416, 20995, 25001, 25001],
      71401: [22122, 21889, 19530, 21088, 25101, 25101],
      71601: [22228, 21993, 19641, 21180, 25201, 25201],
      71801: [22334, 22101, 19755, 21273, 25301, 25301],
      72001: [22440, 22205, 19865, 21365, 25401, 25401],
      72201: [22546, 22309, 19975, 21458, 25501, 25501],
      72401: [22652, 22418, 20090, 21551, 25601, 25601],
      72601: [22758, 22522, 20200, 21643, 25701, 25701],
      72801: [22864, 22626, 20310, 21736, 25801, 25801],
      73001: [22970, 22734, 20424, 21829, 25901, 25901],
      73201: [23076, 22839, 20534, 21921, 26001, 26001],
      73401: [23182, 22947, 20649, 22014, 26101, 26101],
      73601: [23288, 23051, 20759, 22106, 26201, 26201],
      73801: [23394, 23155, 20869, 22199, 26301, 26301],
      74001: [23500, 23264, 20983, 22292, 26401, 26401],
      74201: [23606, 23368, 21094, 22384, 26501, 26501],
      74401: [23712, 23472, 21204, 22477, 26601, 26601],
      74601: [23818, 23580, 21318, 22569, 26701, 26701],
      74801: [23924, 23684, 21428, 22662, 26801, 26801],
      75001: [24030, 23793, 21543, 22755, 26901, 26901],
      75201: [24136, 23897, 21653, 22847, 27001, 27001],
      75401: [24242, 24001, 21763, 22940, 27101, 27101],
      75601: [24348, 24109, 21877, 23033, 27201, 27201],
      75801: [24454, 24214, 21987, 23125, 27301, 27301],
      76001: [24560, 24318, 22098, 23218, 27401, 27401],
      76201: [24666, 24426, 22212, 23311, 27501, 27501],
      76401: [24772, 24530, 22322, 23403, 27601, 27601],
      76601: [24878, 24639, 22436, 23496, 27701, 27701],
      76801: [24984, 24743, 22547, 23588, 27801, 27801],
      77001: [25090, 24847, 22657, 23681, 27901, 27901],
      77201: [25196, 24955, 22771, 23774, 28001, 28001],
      77401: [25302, 25059, 22881, 23866, 28101, 28101],
      77601: [25408, 25164, 22991, 23959, 28201, 28201],
      77801: [25514, 25272, 23106, 24051, 28301, 28301],
      78001: [25620, 25376, 23216, 24144, 28401, 28401],
      78201: [25726, 25484, 23330, 24237, 28501, 28501],
      78401: [25832, 25589, 23440, 24329, 28601, 28601],
      78601: [25938, 25693, 23551, 24422, 28701, 28701],
      78801: [26044, 25801, 23665, 24514, 28801, 28801],
      79001: [26150, 25905, 23775, 24607, 28901, 28901],
      79201: [26256, 26009, 23885, 24700, 29001, 29001],
      79401: [26362, 26118, 24e3, 24792, 29101, 29101],
      79601: [26468, 26222, 24110, 24885, 29201, 29201],
      79801: [26574, 26330, 24224, 24978, 29301, 29301],
      80001: [33, 33, 30, 31, 37, 37, "%"],
      80601: [33, 33, 31, 31, 37, 37, "%"],
      81001: [34, 33, 31, 31, 37, 37, "%"],
      81401: [34, 33, 31, 32, 37, 37, "%"],
      82401: [34, 34, 31, 32, 37, 37, "%"],
      84001: [34, 34, 32, 32, 37, 37, "%"],
      85401: [35, 34, 32, 32, 38, 38, "%"],
      86801: [35, 35, 32, 32, 38, 38, "%"],
      87401: [35, 35, 32, 33, 38, 38, "%"],
      87601: [35, 35, 33, 33, 38, 38, "%"],
      90401: [36, 35, 33, 33, 38, 38, "%"],
      91401: [36, 35, 34, 33, 38, 38, "%"],
      91801: [36, 36, 34, 33, 38, 38, "%"],
      93001: [36, 36, 34, 33, 39, 39, "%"],
      94201: [36, 36, 34, 34, 39, 39, "%"],
      95801: [37, 36, 35, 34, 39, 39, "%"],
      97401: [37, 37, 35, 34, 39, 39, "%"],
      100401: [37, 37, 36, 34, 39, 39, "%"],
      101801: [37, 37, 36, 34, 40, 40, "%"],
      102001: [38, 37, 36, 34, 40, 40, "%"],
      102201: [38, 37, 36, 35, 40, 40, "%"],
      103801: [38, 38, 36, 35, 40, 40, "%"],
      105601: [38, 38, 37, 35, 40, 40, "%"],
      109001: [39, 38, 37, 35, 40, 40, "%"],
      111001: [39, 39, 37, 35, 40, 40, "%"],
      111401: [39, 39, 38, 35, 40, 40, "%"],
      111601: [39, 39, 38, 36, 40, 40, "%"],
      112601: [39, 39, 38, 36, 41, 41, "%"],
      117201: [40, 39, 38, 36, 41, 41, "%"],
      117801: [40, 39, 39, 36, 41, 41, "%"],
      119401: [40, 40, 39, 36, 41, 41, "%"],
      123001: [40, 40, 39, 37, 41, 41, "%"],
      125001: [40, 40, 40, 37, 41, 41, "%"],
      125801: [40, 40, 40, 37, 42, 42, "%"],
      126601: [41, 40, 40, 37, 42, 42, "%"],
      129001: [41, 41, 40, 37, 42, 42, "%"],
      133201: [41, 41, 41, 37, 42, 42, "%"],
      137001: [41, 41, 41, 38, 42, 42, "%"],
      137601: [42, 41, 41, 38, 42, 42, "%"],
      140401: [42, 42, 41, 38, 42, 42, "%"],
      142401: [42, 42, 42, 38, 42, 42, "%"],
      142601: [42, 42, 42, 38, 43, 43, "%"],
      150601: [43, 42, 42, 38, 43, 43, "%"],
      153801: [43, 43, 43, 38, 43, 43, "%"],
      154401: [43, 43, 43, 39, 43, 43, "%"],
      164401: [43, 43, 43, 39, 44, 44, "%"],
      166401: [44, 43, 43, 39, 44, 44, "%"],
      170401: [44, 44, 44, 39, 44, 44, "%"],
      177201: [44, 44, 44, 40, 44, 44, "%"],
      194401: [45, 45, 45, 40, 45, 45, "%"],
      207601: [45, 45, 45, 41, 45, 45, "%"],
      237601: [46, 46, 46, 41, 46, 46, "%"],
      250801: [46, 46, 46, 42, 46, 46, "%"],
      305601: [47, 47, 47, 42, 47, 47, "%"],
      316801: [47, 47, 47, 43, 47, 47, "%"],
      427801: [48, 48, 48, 43, 48, 48, "%"],
      429601: [48, 48, 48, 44, 48, 48, "%"],
      667201: [48, 48, 48, 45, 48, 48, "%"],
      713201: [49, 49, 49, 45, 49, 49, "%"],
      1492601: [49, 49, 49, 46, 49, 49, "%"]
    },
    31: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 23],
      2101: [153, 0, 150, 3, 153, 55],
      2201: [164, 0, 158, 5, 164, 87],
      2301: [174, 0, 166, 8, 174, 119],
      2401: [185, 0, 175, 10, 185, 151],
      2501: [195, 0, 183, 12, 195, 183],
      2601: [206, 0, 191, 14, 215, 215],
      2701: [216, 0, 200, 16, 247, 247],
      2801: [218, 0, 200, 18, 279, 279],
      2901: [229, 0, 208, 21, 311, 311],
      3001: [239, 0, 216, 23, 343, 343],
      3101: [250, 0, 225, 25, 375, 375],
      3201: [260, 0, 233, 27, 407, 407],
      3301: [271, 0, 241, 29, 439, 439],
      3401: [273, 0, 241, 31, 471, 471],
      3501: [284, 0, 250, 34, 503, 503],
      3601: [294, 0, 258, 36, 535, 535],
      3701: [305, 0, 266, 38, 567, 567],
      3801: [315, 0, 275, 40, 600, 600],
      3901: [325, 0, 283, 42, 631, 631],
      4001: [328, 0, 283, 44, 663, 663],
      4101: [338, 0, 291, 46, 695, 695],
      4201: [349, 0, 300, 49, 727, 727],
      4301: [359, 0, 308, 62, 759, 759],
      4401: [370, 0, 316, 83, 791, 791],
      4501: [380, 0, 325, 105, 823, 823],
      4601: [382, 0, 325, 127, 855, 855],
      4701: [392, 0, 333, 149, 882, 882],
      4801: [403, 0, 341, 170, 909, 909],
      4901: [413, 0, 350, 192, 935, 935],
      5001: [423, 0, 358, 213, 959, 959],
      5101: [433, 0, 366, 234, 986, 986],
      5201: [443, 0, 375, 256, 1010, 1010],
      5301: [445, 21, 376, 277, 1037, 1037],
      5401: [455, 45, 386, 299, 1063, 1063],
      5501: [465, 69, 396, 319, 1087, 1087],
      5601: [475, 93, 406, 340, 1113, 1113],
      5701: [485, 117, 416, 361, 1136, 1136],
      5801: [495, 141, 426, 382, 1163, 1163],
      5901: [497, 165, 427, 403, 1189, 1189],
      6001: [507, 189, 437, 423, 1212, 1212],
      6101: [517, 213, 447, 444, 1238, 1238],
      6201: [527, 234, 457, 465, 1262, 1262],
      6301: [537, 258, 467, 486, 1288, 1288],
      6401: [547, 282, 477, 507, 1314, 1314],
      6501: [549, 306, 479, 527, 1337, 1337],
      6601: [559, 330, 488, 548, 1363, 1363],
      6701: [569, 354, 498, 569, 1386, 1386],
      6801: [579, 378, 508, 590, 1413, 1413],
      6901: [589, 402, 518, 611, 1439, 1439],
      7001: [599, 426, 528, 631, 1462, 1462],
      7101: [612, 450, 530, 652, 1488, 1488],
      7201: [632, 474, 540, 673, 1511, 1511],
      7301: [651, 498, 550, 694, 1538, 1538],
      7401: [671, 519, 560, 715, 1564, 1564],
      7501: [690, 543, 570, 736, 1587, 1587],
      7601: [709, 567, 580, 756, 1613, 1613],
      7701: [729, 591, 589, 777, 1636, 1636],
      7801: [748, 615, 591, 798, 1663, 1663],
      7901: [768, 639, 601, 819, 1689, 1689],
      8001: [787, 663, 611, 840, 1712, 1712],
      8101: [807, 687, 621, 860, 1738, 1738],
      8201: [826, 711, 631, 881, 1761, 1761],
      8301: [845, 735, 641, 902, 1788, 1788],
      8401: [865, 759, 642, 923, 1814, 1814],
      8501: [884, 783, 652, 944, 1837, 1837],
      8601: [904, 805, 662, 964, 1863, 1863],
      8701: [923, 829, 672, 985, 1886, 1886],
      8801: [942, 853, 682, 1006, 1913, 1913],
      8901: [962, 877, 692, 1027, 1939, 1939],
      9001: [981, 901, 694, 1048, 1962, 1962],
      9101: [1001, 925, 704, 1069, 1988, 1988],
      9201: [1020, 949, 714, 1089, 2011, 2011],
      9301: [1039, 965, 723, 1110, 2038, 2038],
      9401: [1059, 986, 733, 1131, 2064, 2064],
      9501: [1078, 1005, 742, 1152, 2087, 2087],
      9601: [1098, 1023, 744, 1173, 2113, 2113],
      9701: [1117, 1042, 753, 1193, 2136, 2136],
      9801: [1136, 1063, 763, 1214, 2163, 2163],
      9901: [1156, 1082, 773, 1235, 2189, 2189],
      10001: [1175, 1100, 782, 1256, 2212, 2212],
      10101: [1195, 1118, 792, 1277, 2238, 2238],
      10201: [1214, 1139, 802, 1297, 2261, 2261],
      10301: [1233, 1157, 803, 1318, 2288, 2288],
      10401: [1253, 1176, 812, 1339, 2314, 2314],
      10501: [1272, 1194, 822, 1360, 2337, 2337],
      10601: [1292, 1215, 832, 1381, 2363, 2363],
      10701: [1311, 1233, 841, 1401, 2386, 2386],
      10801: [1331, 1251, 851, 1422, 2413, 2413],
      10901: [1350, 1269, 852, 1443, 2439, 2439],
      11001: [1369, 1290, 862, 1464, 2462, 2462],
      11101: [1389, 1308, 872, 1485, 2488, 2488],
      11201: [1408, 1327, 881, 1505, 2511, 2511],
      11301: [1428, 1347, 891, 1526, 2538, 2538],
      11401: [1447, 1366, 901, 1547, 2564, 2564],
      11501: [1466, 1384, 902, 1568, 2587, 2587],
      11601: [1486, 1402, 911, 1589, 2613, 2613],
      11701: [1505, 1423, 921, 1609, 2636, 2636],
      11801: [1525, 1441, 931, 1630, 2663, 2663],
      11901: [1544, 1459, 940, 1651, 2689, 2689],
      12001: [1563, 1478, 950, 1672, 2712, 2712],
      12101: [1583, 1498, 951, 1693, 2738, 2738],
      12201: [1602, 1517, 961, 1713, 2761, 2761],
      12301: [1622, 1535, 971, 1734, 2788, 2788],
      12401: [1641, 1553, 980, 1755, 2814, 2814],
      12501: [1660, 1574, 990, 1776, 2837, 2837],
      12601: [1680, 1592, 1e3, 1797, 2863, 2863],
      12701: [1699, 1610, 1009, 1818, 2886, 2886],
      12801: [1719, 1629, 1010, 1838, 2913, 2913],
      12901: [1738, 1649, 1020, 1859, 2936, 2936],
      13001: [1758, 1668, 1030, 1880, 2967, 2967],
      13101: [1777, 1686, 1039, 1901, 2998, 2998],
      13201: [1797, 1707, 1049, 1922, 3030, 3030],
      13301: [1817, 1725, 1059, 1943, 3061, 3061],
      13401: [1836, 1743, 1060, 1965, 3092, 3092],
      13501: [1856, 1761, 1070, 1986, 3123, 3123],
      13601: [1876, 1782, 1079, 2007, 3155, 3155],
      13701: [1896, 1801, 1089, 2028, 3186, 3186],
      13801: [1915, 1819, 1099, 2048, 3217, 3217],
      13901: [1933, 1837, 1108, 2068, 3247, 3247],
      14001: [1952, 1858, 1110, 2089, 3277, 3277],
      14101: [1971, 1876, 1119, 2109, 3307, 3307],
      14201: [1989, 1894, 1129, 2129, 3338, 3338],
      14301: [2008, 1913, 1138, 2149, 3368, 3368],
      14401: [2027, 1933, 1148, 2169, 3398, 3398],
      14501: [2045, 1952, 1158, 2189, 3428, 3428],
      14601: [2064, 1970, 1159, 2209, 3459, 3459],
      14701: [2083, 1988, 1169, 2229, 3489, 3489],
      14801: [2102, 2009, 1178, 2249, 3522, 3522],
      14901: [2120, 2027, 1188, 2269, 3554, 3554],
      15001: [2139, 2045, 1198, 2290, 3587, 3587],
      15101: [2158, 2064, 1207, 2310, 3620, 3620],
      15201: [2177, 2084, 1217, 2330, 3655, 3655],
      15301: [2195, 2103, 1218, 2350, 3688, 3688],
      15401: [2215, 2123, 1228, 2372, 3721, 3721],
      15501: [2241, 2149, 1238, 2399, 3754, 3754],
      15601: [2266, 2176, 1248, 2425, 3786, 3786],
      15701: [2292, 2202, 1258, 2452, 3822, 3822],
      15801: [2317, 2228, 1268, 2478, 3854, 3854],
      15901: [2342, 2254, 1270, 2505, 3887, 3887],
      16001: [2368, 2280, 1280, 2531, 3920, 3920],
      16101: [2393, 2306, 1291, 2558, 3953, 3953],
      16201: [2418, 2332, 1301, 2584, 3988, 3988],
      16301: [2444, 2358, 1311, 2611, 4021, 4021],
      16401: [2469, 2384, 1321, 2637, 4054, 4054],
      16501: [2495, 2410, 1323, 2664, 4086, 4086],
      16601: [2520, 2436, 1333, 2690, 4119, 4119],
      16701: [2545, 2462, 1343, 2717, 4154, 4154],
      16801: [2571, 2488, 1353, 2743, 4187, 4187],
      16901: [2596, 2514, 1363, 2769, 4220, 4220],
      17001: [2622, 2538, 1373, 2796, 4253, 4253],
      17101: [2647, 2564, 1375, 2822, 4286, 4286],
      17201: [2672, 2590, 1385, 2849, 4321, 4321],
      17301: [2698, 2616, 1395, 2875, 4354, 4354],
      17401: [2723, 2642, 1405, 2902, 4386, 4386],
      17501: [2748, 2668, 1416, 2928, 4419, 4419],
      17601: [2774, 2694, 1426, 2955, 4452, 4452],
      17701: [2799, 2720, 1436, 2981, 4487, 4487],
      17801: [2825, 2746, 1438, 3008, 4520, 4520],
      17901: [2850, 2772, 1448, 3034, 4553, 4553],
      18001: [2875, 2798, 1458, 3061, 4585, 4585],
      18101: [2901, 2824, 1468, 3087, 4618, 4618],
      18201: [2926, 2850, 1478, 3114, 4653, 4653],
      18301: [2952, 2876, 1488, 3140, 4686, 4686],
      18401: [2977, 2902, 1490, 3167, 4719, 4719],
      18501: [3002, 2928, 1500, 3193, 4752, 4752],
      18601: [3028, 2954, 1510, 3219, 4785, 4785],
      18701: [3053, 2980, 1520, 3246, 4820, 4820],
      18801: [3079, 3006, 1531, 3273, 4853, 4853],
      18901: [3104, 3032, 1541, 3299, 4885, 4885],
      19001: [3129, 3058, 1543, 3325, 4918, 4918],
      19101: [3155, 3084, 1553, 3352, 4951, 4951],
      19201: [3180, 3110, 1563, 3378, 4986, 4986],
      19301: [3206, 3136, 1573, 3405, 5019, 5019],
      19401: [3231, 3163, 1583, 3431, 5052, 5052],
      19501: [3256, 3189, 1593, 3458, 5085, 5085],
      19601: [3282, 3214, 1595, 3484, 5117, 5117],
      19701: [3307, 3239, 1604, 3511, 5153, 5153],
      19801: [3332, 3265, 1613, 3537, 5185, 5185],
      19901: [3358, 3290, 1622, 3564, 5218, 5218],
      20001: [3409, 3340, 1641, 3617, 5284, 5284],
      20201: [3459, 3388, 1651, 3670, 5352, 5352],
      20401: [3510, 3438, 1670, 3722, 5417, 5417],
      20601: [3561, 3489, 1688, 3775, 5485, 5485],
      20801: [3612, 3539, 1699, 3828, 5551, 5551],
      21001: [3662, 3590, 1717, 3881, 5616, 5616],
      21201: [3713, 3640, 1736, 3934, 5685, 5685],
      21401: [3764, 3691, 1746, 3987, 5750, 5750],
      21601: [3815, 3741, 1765, 4040, 5818, 5818],
      21801: [3866, 3791, 1783, 4093, 5884, 5884],
      22001: [3916, 3842, 1794, 4146, 5949, 5949],
      22201: [3967, 3892, 1812, 4199, 6017, 6017],
      22401: [4018, 3943, 1831, 4252, 6083, 6083],
      22601: [4069, 3993, 1849, 4305, 6151, 6151],
      22801: [4120, 4043, 1860, 4359, 6217, 6217],
      23001: [4173, 4094, 1878, 4413, 6285, 6285],
      23201: [4225, 4144, 1897, 4468, 6354, 6354],
      23401: [4278, 4192, 1907, 4522, 6421, 6421],
      23601: [4330, 4243, 1926, 4577, 6491, 6491],
      23801: [4383, 4290, 1944, 4632, 6558, 6558],
      24001: [4435, 4343, 1954, 4686, 6626, 6626],
      24201: [4487, 4394, 1973, 4741, 6695, 6695],
      24401: [4540, 4447, 1992, 4795, 6762, 6762],
      24601: [4592, 4497, 2002, 4850, 6832, 6832],
      24801: [4645, 4548, 2021, 4905, 6899, 6899],
      25001: [4697, 4601, 2039, 4959, 6967, 6967],
      25201: [4750, 4651, 2050, 5014, 7036, 7036],
      25401: [4802, 4701, 2068, 5068, 7103, 7103],
      25601: [4854, 4754, 2087, 5123, 7173, 7173],
      25801: [4907, 4805, 2097, 5178, 7240, 7240],
      26001: [4959, 4855, 2116, 5232, 7308, 7308],
      26201: [5012, 4908, 2134, 5287, 7377, 7377],
      26401: [5064, 4959, 2145, 5341, 7444, 7444],
      26601: [5117, 5011, 2163, 5396, 7514, 7514],
      26801: [5169, 5062, 2182, 5451, 7581, 7581],
      27001: [5221, 5112, 2192, 5505, 7649, 7649],
      27201: [5274, 5165, 2211, 5560, 7718, 7718],
      27401: [5326, 5216, 2230, 5614, 7785, 7785],
      27601: [5379, 5266, 2261, 5669, 7855, 7855],
      27801: [5431, 5319, 2314, 5724, 7922, 7922],
      28001: [5483, 5369, 2365, 5778, 7990, 7990],
      28201: [5536, 5422, 2418, 5833, 8059, 8059],
      28401: [5588, 5473, 2468, 5887, 8126, 8126],
      28601: [5641, 5523, 2518, 5942, 8196, 8196],
      28801: [5693, 5576, 2571, 5997, 8263, 8263],
      29001: [5746, 5627, 2622, 6051, 8331, 8331],
      29201: [5798, 5677, 2672, 6106, 8400, 8400],
      29401: [5850, 5730, 2725, 6160, 8467, 8467],
      29601: [5903, 5780, 2775, 6215, 8537, 8537],
      29801: [5955, 5833, 2828, 6269, 8604, 8604],
      30001: [6008, 5884, 2879, 6324, 8672, 8672],
      30201: [6060, 5934, 2929, 6379, 8741, 8741],
      30401: [6113, 5987, 2982, 6433, 8808, 8808],
      30601: [6165, 6037, 3033, 6488, 8878, 8878],
      30801: [6217, 6088, 3083, 6542, 8945, 8945],
      31001: [6270, 6141, 3136, 6597, 9013, 9013],
      31201: [6322, 6192, 3187, 6652, 9082, 9082],
      31401: [6375, 6243, 3238, 6706, 9149, 9149],
      31601: [6427, 6297, 3293, 6761, 9219, 9219],
      31801: [6480, 6349, 3344, 6815, 9286, 9286],
      32001: [6532, 6403, 3399, 6870, 9354, 9354],
      32201: [6585, 6455, 3450, 6925, 9423, 9423],
      32401: [6637, 6507, 3502, 6979, 9490, 9490],
      32601: [6689, 6561, 3556, 7034, 9560, 9560],
      32801: [6742, 6613, 3608, 7088, 9627, 9627],
      33001: [6794, 6664, 3659, 7143, 9695, 9695],
      33201: [6847, 6719, 3714, 7198, 9764, 9764],
      33401: [6899, 6770, 3765, 7252, 9831, 9831],
      33601: [6952, 6824, 3820, 7307, 9901, 9901],
      33801: [7004, 6876, 3871, 7361, 9968, 9968],
      34001: [7056, 6928, 3923, 7416, 10036, 10036],
      34201: [7109, 6982, 3977, 7471, 10105, 10105],
      34401: [7161, 7034, 4029, 7525, 10172, 10172],
      34601: [7214, 7085, 4081, 7580, 10242, 10242],
      34801: [7266, 7140, 4135, 7634, 10309, 10309],
      35001: [7318, 7191, 4186, 7689, 10377, 10377],
      35201: [7371, 7243, 4238, 7744, 10446, 10446],
      35401: [7423, 7297, 4292, 7798, 10513, 10513],
      35601: [7476, 7349, 4344, 7853, 10583, 10583],
      35801: [7528, 7403, 4398, 7907, 10650, 10650],
      36001: [7581, 7455, 4450, 7962, 10718, 10718],
      36201: [7633, 7506, 4502, 8016, 10787, 10787],
      36401: [7685, 7561, 4556, 8071, 10854, 10854],
      36601: [7738, 7612, 4608, 8126, 10924, 10924],
      36801: [7790, 7664, 4659, 8180, 10991, 10991],
      37001: [7843, 7718, 4713, 8235, 11059, 11059],
      37201: [7895, 7770, 4765, 8289, 11128, 11128],
      37401: [7948, 7824, 4819, 8344, 11195, 11195],
      37601: [8e3, 7873, 4868, 8398, 11260, 11260],
      37801: [8052, 7925, 4920, 8453, 11322, 11322],
      38001: [8104, 7977, 4972, 8507, 11384, 11384],
      38201: [8156, 8031, 5026, 8562, 11446, 11446],
      38401: [8210, 8083, 5078, 8616, 11508, 11508],
      38601: [8272, 8145, 5140, 8670, 11570, 11570],
      38801: [8334, 8207, 5202, 8725, 11632, 11632],
      39001: [8396, 8269, 5264, 8779, 11694, 11694],
      39201: [8458, 8331, 5326, 8833, 11756, 11756],
      39401: [8520, 8393, 5388, 8888, 11818, 11818],
      39601: [8582, 8455, 5450, 8942, 11880, 11880],
      39801: [8644, 8517, 5512, 8996, 11942, 11942],
      40001: [8706, 8579, 5574, 9051, 12004, 12004],
      40201: [8768, 8641, 5636, 9105, 12066, 12066],
      40401: [8830, 8703, 5698, 9159, 12128, 12128],
      40601: [8892, 8765, 5760, 9214, 12190, 12190],
      40801: [8954, 8827, 5822, 9268, 12252, 12252],
      41001: [9016, 8889, 5884, 9323, 12314, 12314],
      41201: [9078, 8951, 5946, 9377, 12376, 12376],
      41401: [9140, 9013, 6008, 9431, 12438, 12438],
      41601: [9202, 9075, 6070, 9486, 12500, 12500],
      41801: [9264, 9137, 6132, 9540, 12562, 12562],
      42001: [9326, 9199, 6194, 9594, 12624, 12624],
      42201: [9388, 9261, 6256, 9649, 12686, 12686],
      42401: [9450, 9323, 6318, 9703, 12748, 12748],
      42601: [9512, 9385, 6380, 9757, 12810, 12810],
      42801: [9574, 9447, 6442, 9812, 12872, 12872],
      43001: [9636, 9509, 6504, 9866, 12934, 12934],
      43201: [9698, 9571, 6566, 9921, 12996, 12996],
      43401: [9760, 9633, 6628, 9975, 13058, 13058],
      43601: [9822, 9695, 6690, 10029, 13120, 13120],
      43801: [9884, 9757, 6752, 10084, 13182, 13182],
      44001: [9946, 9819, 6814, 10138, 13244, 13244],
      44201: [10008, 9881, 6876, 10192, 13306, 13306],
      44401: [10070, 9943, 6938, 10247, 13368, 13368],
      44601: [10132, 10005, 7e3, 10301, 13430, 13430],
      44801: [10194, 10067, 7062, 10355, 13492, 13492],
      45001: [10256, 10129, 7124, 10410, 13554, 13554],
      45201: [10318, 10191, 7186, 10464, 13616, 13616],
      45401: [10380, 10253, 7248, 10519, 13678, 13678],
      45601: [10442, 10315, 7310, 10573, 13740, 13740],
      45801: [10504, 10377, 7372, 10627, 13802, 13802],
      46001: [10566, 10439, 7434, 10682, 13864, 13864],
      46201: [10628, 10501, 7496, 10736, 13926, 13926],
      46401: [10690, 10563, 7558, 10790, 13988, 13988],
      46601: [10752, 10625, 7620, 10845, 14050, 14050],
      46801: [10814, 10687, 7682, 10899, 14112, 14112],
      47001: [10876, 10749, 7744, 10953, 14174, 14174],
      47201: [10938, 10811, 7806, 11008, 14236, 14236],
      47401: [11e3, 10873, 7868, 11062, 14298, 14298],
      47601: [11062, 10935, 7930, 11116, 14360, 14360],
      47801: [11124, 10997, 7992, 11171, 14422, 14422],
      48001: [11186, 11059, 8054, 11225, 14484, 14484],
      48201: [11248, 11121, 8116, 11280, 14546, 14546],
      48401: [11310, 11183, 8178, 11334, 14608, 14608],
      48601: [11372, 11245, 8240, 11388, 14670, 14670],
      48801: [11434, 11307, 8302, 11443, 14732, 14732],
      49001: [11496, 11369, 8364, 11497, 14794, 14794],
      49201: [11558, 11431, 8426, 11551, 14856, 14856],
      49401: [11620, 11493, 8488, 11606, 14918, 14918],
      49601: [11682, 11555, 8550, 11660, 14980, 14980],
      49801: [11744, 11617, 8612, 11714, 15042, 15042],
      50001: [11806, 11679, 8678, 11769, 15104, 15104],
      50201: [11868, 11741, 8746, 11823, 15166, 15166],
      50401: [11930, 11803, 8814, 11878, 15228, 15228],
      50601: [11992, 11865, 8882, 11932, 15290, 15290],
      50801: [12054, 11927, 8950, 11986, 15352, 15352],
      51001: [12116, 11989, 9018, 12041, 15414, 15414],
      51201: [12203, 12074, 9110, 12120, 15501, 15501],
      51401: [12305, 12175, 9216, 12214, 15603, 15603],
      51601: [12407, 12275, 9323, 12309, 15705, 15705],
      51801: [12509, 12373, 9427, 12403, 15807, 15807],
      52001: [12611, 12474, 9534, 12497, 15909, 15909],
      52201: [12713, 12575, 9641, 12592, 16011, 16011],
      52401: [12815, 12676, 9748, 12686, 16113, 16113],
      52601: [12917, 12776, 9854, 12781, 16215, 16215],
      52801: [13019, 12877, 9961, 12875, 16317, 16317],
      53001: [13121, 12978, 10068, 12969, 16419, 16419],
      53201: [13223, 13079, 10175, 13064, 16521, 16521],
      53401: [13325, 13177, 10279, 13158, 16623, 16623],
      53601: [13427, 13278, 10385, 13252, 16725, 16725],
      53801: [13529, 13378, 10492, 13347, 16827, 16827],
      54001: [13631, 13479, 10599, 13441, 16929, 16929],
      54201: [13733, 13580, 10706, 13535, 17031, 17031],
      54401: [13835, 13681, 10812, 13630, 17133, 17133],
      54601: [13937, 13781, 10919, 13724, 17235, 17235],
      54801: [14039, 13882, 11026, 13818, 17337, 17337],
      55001: [14141, 13983, 11133, 13913, 17439, 17439],
      55201: [14243, 14081, 11237, 14007, 17541, 17541],
      55401: [14345, 14182, 11344, 14102, 17643, 17643],
      55601: [14447, 14283, 11450, 14196, 17745, 17745],
      55801: [14549, 14383, 11557, 14290, 17847, 17847],
      56001: [14651, 14484, 11664, 14385, 17949, 17949],
      56201: [14753, 14585, 11771, 14479, 18051, 18051],
      56401: [14855, 14686, 11877, 14573, 18153, 18153],
      56601: [14957, 14786, 11984, 14668, 18255, 18255],
      56801: [15059, 14884, 12088, 14762, 18357, 18357],
      57001: [15161, 14985, 12195, 14856, 18459, 18459],
      57201: [15263, 15086, 12302, 14951, 18561, 18561],
      57401: [15365, 15187, 12409, 15045, 18663, 18663],
      57601: [15467, 15287, 12515, 15140, 18765, 18765],
      57801: [15569, 15388, 12622, 15234, 18867, 18867],
      58001: [15671, 15484, 12724, 15328, 18969, 18969],
      58201: [15773, 15586, 12832, 15423, 19071, 19071],
      58401: [15875, 15688, 12940, 15517, 19173, 19173],
      58601: [15977, 15790, 13048, 15611, 19275, 19275],
      58801: [16079, 15892, 13156, 15706, 19377, 19377],
      59001: [16181, 15994, 13264, 15800, 19479, 19479],
      59201: [16283, 16096, 13372, 15894, 19581, 19581],
      59401: [16385, 16198, 13480, 15989, 19683, 19683],
      59601: [16487, 16300, 13588, 16083, 19785, 19785],
      59801: [16589, 16402, 13696, 16178, 19887, 19887],
      60001: [16691, 16504, 13804, 16272, 19989, 19989],
      60201: [16793, 16606, 13912, 16366, 20091, 20091],
      60401: [16895, 16708, 14020, 16461, 20193, 20193],
      60601: [16997, 16810, 14128, 16555, 20295, 20295],
      60801: [17099, 16912, 14236, 16649, 20397, 20397],
      61001: [17201, 17014, 14344, 16744, 20499, 20499],
      61201: [17303, 17116, 14452, 16838, 20601, 20601],
      61401: [17405, 17218, 14560, 16932, 20703, 20703],
      61601: [17507, 17320, 14668, 17027, 20805, 20805],
      61801: [17609, 17422, 14776, 17121, 20907, 20907],
      62001: [17711, 17524, 14884, 17215, 21009, 21009],
      62201: [17813, 17626, 14992, 17310, 21111, 21111],
      62401: [17915, 17728, 15100, 17404, 21213, 21213],
      62601: [18017, 17830, 15208, 17499, 21315, 21315],
      62801: [18119, 17932, 15316, 17593, 21417, 21417],
      63001: [18221, 18034, 15424, 17687, 21519, 21519],
      63201: [18323, 18136, 15532, 17782, 21621, 21621],
      63401: [18425, 18238, 15640, 17876, 21723, 21723],
      63601: [18527, 18340, 15748, 17970, 21825, 21825],
      63801: [18629, 18442, 15856, 18065, 21927, 21927],
      64001: [18731, 18544, 15964, 18159, 22029, 22029],
      64201: [18833, 18646, 16072, 18253, 22131, 22131],
      64401: [18935, 18748, 16180, 18348, 22233, 22233],
      64601: [19041, 18859, 16296, 18442, 22335, 22335],
      64801: [19149, 18965, 16409, 18537, 22437, 22437],
      65001: [19257, 19075, 16525, 18631, 22539, 22539],
      65201: [19365, 19182, 16637, 18725, 22641, 22641],
      65401: [19473, 19288, 16750, 18820, 22743, 22743],
      65601: [19581, 19398, 16866, 18914, 22845, 22845],
      65801: [19689, 19505, 16978, 19008, 22947, 22947],
      66001: [19797, 19615, 17095, 19103, 23049, 23049],
      66201: [19905, 19721, 17207, 19197, 23151, 23151],
      66401: [20013, 19828, 17319, 19291, 23253, 23253],
      66601: [20121, 19938, 17436, 19386, 23355, 23355],
      66801: [20229, 20044, 17548, 19480, 23457, 23457],
      67001: [20337, 20151, 17660, 19575, 23559, 23559],
      67201: [20445, 20261, 17777, 19669, 23661, 23661],
      67401: [20553, 20367, 17889, 19763, 23763, 23763],
      67601: [20661, 20478, 18006, 19858, 23865, 23865],
      67801: [20769, 20584, 18118, 19952, 23967, 23967],
      68001: [20877, 20690, 18230, 20046, 24069, 24069],
      68201: [20985, 20801, 18347, 20141, 24171, 24171],
      68401: [21093, 20907, 18459, 20235, 24273, 24273],
      68601: [21201, 21013, 18571, 20329, 24375, 24375],
      68801: [21309, 21124, 18688, 20424, 24477, 24477],
      69001: [21417, 21230, 18800, 20518, 24579, 24579],
      69201: [21525, 21341, 18916, 20612, 24681, 24681],
      69401: [21633, 21447, 19029, 20707, 24783, 24783],
      69601: [21741, 21553, 19141, 20801, 24885, 24885],
      69801: [21849, 21664, 19257, 20896, 24987, 24987],
      70001: [21957, 21770, 19370, 20990, 25089, 25089],
      70201: [22065, 21876, 19482, 21084, 25191, 25191],
      70401: [22173, 21987, 19598, 21179, 25293, 25293],
      70601: [22281, 22093, 19711, 21273, 25395, 25395],
      70801: [22389, 22203, 19827, 21367, 25497, 25497],
      71001: [22497, 22310, 19939, 21462, 25599, 25599],
      71201: [22605, 22416, 20052, 21556, 25701, 25701],
      71401: [22713, 22526, 20168, 21650, 25803, 25803],
      71601: [22821, 22633, 20280, 21745, 25905, 25905],
      71801: [22929, 22743, 20397, 21839, 26007, 26007],
      72001: [23037, 22849, 20509, 21934, 26109, 26109],
      72201: [23145, 22956, 20621, 22028, 26211, 26211],
      72401: [23253, 23066, 20738, 22122, 26313, 26313],
      72601: [23361, 23172, 20850, 22217, 26415, 26415],
      72801: [23469, 23279, 20962, 22311, 26517, 26517],
      73001: [23577, 23389, 21079, 22405, 26619, 26619],
      73201: [23685, 23495, 21191, 22500, 26721, 26721],
      73401: [23793, 23606, 21308, 22594, 26823, 26823],
      73601: [23901, 23712, 21420, 22688, 26925, 26925],
      73801: [24009, 23818, 21532, 22783, 27027, 27027],
      74001: [24117, 23929, 21649, 22877, 27129, 27129],
      74201: [24225, 24035, 21761, 22972, 27231, 27231],
      74401: [24333, 24141, 21873, 23066, 27333, 27333],
      74601: [24441, 24252, 21990, 23160, 27435, 27435],
      74801: [24549, 24358, 22102, 23255, 27537, 27537],
      75001: [24657, 24469, 22218, 23349, 27639, 27639],
      75201: [24765, 24575, 22331, 23443, 27741, 27741],
      75401: [24873, 24681, 22443, 23538, 27843, 27843],
      75601: [24981, 24792, 22559, 23632, 27945, 27945],
      75801: [25089, 24898, 22672, 23726, 28047, 28047],
      76001: [25197, 25004, 22784, 23821, 28149, 28149],
      76201: [25305, 25115, 22900, 23915, 28251, 28251],
      76401: [25413, 25221, 23013, 24009, 28353, 28353],
      76601: [25521, 25331, 23129, 24104, 28455, 28455],
      76801: [25629, 25438, 23241, 24198, 28557, 28557],
      77001: [25737, 25544, 23354, 24293, 28659, 28659],
      77201: [25845, 25654, 23470, 24387, 28761, 28761],
      77401: [25953, 25761, 23582, 24481, 28863, 28863],
      77601: [26061, 25867, 23695, 24576, 28965, 28965],
      77801: [26169, 25977, 23811, 24670, 29067, 29067],
      78001: [26277, 26084, 23923, 24764, 29169, 29169],
      78201: [26385, 26194, 24040, 24859, 29271, 29271],
      78401: [26493, 26300, 24152, 24953, 29373, 29373],
      78601: [26601, 26407, 24264, 25047, 29475, 29475],
      78801: [26709, 26517, 24381, 25142, 29577, 29577],
      79001: [26817, 26623, 24493, 25236, 29679, 29679],
      79201: [26925, 26730, 24605, 25331, 29781, 29781],
      79401: [27033, 26840, 24722, 25425, 29883, 29883],
      79601: [27141, 26946, 24834, 25519, 29985, 29985],
      79801: [27249, 27057, 24951, 25614, 30087, 30087],
      80001: [34, 34, 31, 32, 38, 38, "%"],
      80801: [34, 34, 32, 32, 38, 38, "%"],
      81601: [35, 34, 32, 32, 38, 38, "%"],
      82601: [35, 35, 32, 33, 38, 38, "%"],
      84201: [35, 35, 33, 33, 38, 38, "%"],
      85601: [35, 35, 33, 33, 39, 39, "%"],
      86201: [36, 35, 33, 33, 39, 39, "%"],
      87201: [36, 36, 33, 33, 39, 39, "%"],
      87801: [36, 36, 34, 33, 39, 39, "%"],
      88601: [36, 36, 34, 34, 39, 39, "%"],
      91001: [37, 36, 34, 34, 39, 39, "%"],
      91801: [37, 36, 35, 34, 39, 39, "%"],
      92201: [37, 37, 35, 34, 39, 39, "%"],
      93001: [37, 37, 35, 34, 40, 40, "%"],
      95601: [37, 37, 35, 35, 40, 40, "%"],
      96001: [37, 37, 36, 35, 40, 40, "%"],
      96601: [38, 37, 36, 35, 40, 40, "%"],
      97801: [38, 38, 36, 35, 40, 40, "%"],
      100801: [38, 38, 37, 35, 40, 40, "%"],
      102001: [38, 38, 37, 35, 41, 41, "%"],
      102801: [39, 38, 37, 35, 41, 41, "%"],
      103801: [39, 38, 37, 36, 41, 41, "%"],
      104201: [39, 39, 37, 36, 41, 41, "%"],
      105801: [39, 39, 38, 36, 41, 41, "%"],
      110001: [40, 39, 38, 36, 41, 41, "%"],
      111401: [40, 40, 38, 36, 41, 41, "%"],
      111601: [40, 40, 39, 36, 41, 41, "%"],
      112601: [40, 40, 39, 36, 42, 42, "%"],
      113401: [40, 40, 39, 37, 42, 42, "%"],
      118001: [41, 40, 40, 37, 42, 42, "%"],
      119601: [41, 41, 40, 37, 42, 42, "%"],
      125201: [41, 41, 41, 38, 42, 42, "%"],
      126001: [41, 41, 41, 38, 43, 43, "%"],
      127601: [42, 41, 41, 38, 43, 43, "%"],
      129401: [42, 42, 41, 38, 43, 43, "%"],
      133401: [42, 42, 42, 38, 43, 43, "%"],
      138601: [43, 42, 42, 38, 43, 43, "%"],
      139601: [43, 42, 42, 39, 43, 43, "%"],
      140601: [43, 43, 42, 39, 43, 43, "%"],
      142601: [43, 43, 43, 39, 43, 43, "%"],
      142801: [43, 43, 43, 39, 44, 44, "%"],
      151801: [44, 43, 43, 39, 44, 44, "%"],
      154201: [44, 44, 44, 39, 44, 44, "%"],
      157801: [44, 44, 44, 40, 44, 44, "%"],
      164801: [44, 44, 44, 40, 45, 45, "%"],
      167801: [45, 44, 44, 40, 45, 45, "%"],
      170601: [45, 45, 45, 40, 45, 45, "%"],
      181401: [45, 45, 45, 41, 45, 45, "%"],
      194601: [46, 46, 46, 41, 46, 46, "%"],
      213401: [46, 46, 46, 42, 46, 46, "%"],
      238001: [47, 47, 47, 42, 47, 47, "%"],
      259001: [47, 47, 47, 43, 47, 47, "%"],
      306001: [48, 48, 48, 43, 48, 48, "%"],
      329401: [48, 48, 48, 44, 48, 48, "%"],
      428401: [49, 49, 49, 44, 49, 49, "%"],
      452401: [49, 49, 49, 45, 49, 49, "%"],
      714001: [50, 50, 50, 45, 50, 50, "%"],
      721801: [50, 50, 50, 46, 50, 50, "%"],
      1782601: [50, 50, 50, 47, 50, 50, "%"]
    },
    32: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 24],
      2101: [153, 0, 150, 3, 153, 57],
      2201: [164, 0, 158, 5, 164, 90],
      2301: [174, 0, 166, 8, 174, 123],
      2401: [185, 0, 175, 10, 185, 156],
      2501: [195, 0, 183, 12, 195, 189],
      2601: [206, 0, 191, 14, 222, 222],
      2701: [216, 0, 200, 16, 255, 255],
      2801: [218, 0, 200, 18, 288, 288],
      2901: [229, 0, 208, 21, 321, 321],
      3001: [239, 0, 216, 23, 354, 354],
      3101: [250, 0, 225, 25, 387, 387],
      3201: [260, 0, 233, 27, 420, 420],
      3301: [271, 0, 241, 29, 453, 453],
      3401: [273, 0, 241, 31, 486, 486],
      3501: [284, 0, 250, 34, 519, 519],
      3601: [294, 0, 258, 36, 552, 552],
      3701: [305, 0, 266, 38, 585, 585],
      3801: [315, 0, 275, 40, 618, 618],
      3901: [325, 0, 283, 42, 651, 651],
      4001: [328, 0, 283, 44, 684, 684],
      4101: [338, 0, 291, 46, 717, 717],
      4201: [349, 0, 300, 49, 750, 750],
      4301: [359, 0, 308, 62, 783, 783],
      4401: [370, 0, 316, 84, 816, 816],
      4501: [380, 0, 325, 107, 849, 849],
      4601: [382, 0, 325, 129, 882, 882],
      4701: [392, 0, 333, 152, 910, 910],
      4801: [403, 0, 341, 174, 937, 937],
      4901: [413, 0, 350, 196, 965, 965],
      5001: [423, 0, 358, 218, 989, 989],
      5101: [433, 0, 366, 240, 1017, 1017],
      5201: [443, 0, 375, 262, 1042, 1042],
      5301: [445, 21, 376, 284, 1069, 1069],
      5401: [455, 46, 386, 306, 1097, 1097],
      5501: [465, 71, 396, 328, 1121, 1121],
      5601: [475, 96, 406, 349, 1148, 1148],
      5701: [485, 120, 416, 371, 1172, 1172],
      5801: [495, 145, 426, 392, 1199, 1199],
      5901: [497, 170, 427, 414, 1226, 1226],
      6001: [507, 195, 437, 435, 1250, 1250],
      6101: [517, 219, 447, 457, 1277, 1277],
      6201: [527, 241, 457, 478, 1301, 1301],
      6301: [537, 266, 467, 499, 1328, 1328],
      6401: [547, 291, 477, 521, 1355, 1355],
      6501: [549, 316, 479, 542, 1379, 1379],
      6601: [559, 340, 488, 564, 1406, 1406],
      6701: [569, 365, 498, 585, 1430, 1430],
      6801: [579, 390, 508, 607, 1457, 1457],
      6901: [590, 415, 518, 628, 1484, 1484],
      7001: [610, 439, 528, 650, 1508, 1508],
      7101: [630, 464, 530, 671, 1535, 1535],
      7201: [650, 489, 540, 693, 1559, 1559],
      7301: [670, 514, 550, 714, 1586, 1586],
      7401: [690, 536, 560, 736, 1613, 1613],
      7501: [710, 560, 570, 757, 1637, 1637],
      7601: [730, 585, 580, 779, 1664, 1664],
      7701: [750, 610, 589, 800, 1688, 1688],
      7801: [770, 635, 591, 821, 1715, 1715],
      7901: [790, 659, 601, 843, 1742, 1742],
      8001: [810, 684, 611, 864, 1766, 1766],
      8101: [830, 709, 621, 886, 1793, 1793],
      8201: [850, 734, 631, 907, 1817, 1817],
      8301: [870, 758, 641, 929, 1844, 1844],
      8401: [890, 783, 642, 950, 1871, 1871],
      8501: [910, 808, 652, 972, 1895, 1895],
      8601: [930, 830, 662, 993, 1922, 1922],
      8701: [950, 855, 672, 1015, 1946, 1946],
      8801: [970, 879, 682, 1036, 1973, 1973],
      8901: [990, 904, 692, 1058, 2e3, 2e3],
      9001: [1010, 929, 694, 1079, 2024, 2024],
      9101: [1030, 954, 704, 1101, 2051, 2051],
      9201: [1050, 978, 714, 1122, 2075, 2075],
      9301: [1070, 995, 723, 1143, 2102, 2102],
      9401: [1090, 1017, 733, 1165, 2129, 2129],
      9501: [1110, 1036, 742, 1186, 2153, 2153],
      9601: [1130, 1055, 744, 1208, 2180, 2180],
      9701: [1150, 1075, 753, 1229, 2204, 2204],
      9801: [1170, 1097, 763, 1251, 2231, 2231],
      9901: [1190, 1116, 773, 1272, 2258, 2258],
      10001: [1210, 1134, 782, 1294, 2282, 2282],
      10101: [1231, 1153, 792, 1315, 2309, 2309],
      10201: [1250, 1175, 802, 1337, 2333, 2333],
      10301: [1271, 1194, 803, 1358, 2360, 2360],
      10401: [1291, 1212, 812, 1380, 2387, 2387],
      10501: [1311, 1231, 822, 1401, 2411, 2411],
      10601: [1331, 1253, 832, 1423, 2438, 2438],
      10701: [1351, 1271, 841, 1444, 2462, 2462],
      10801: [1371, 1290, 851, 1465, 2489, 2489],
      10901: [1391, 1309, 852, 1487, 2516, 2516],
      11001: [1411, 1331, 862, 1508, 2540, 2540],
      11101: [1431, 1349, 872, 1530, 2567, 2567],
      11201: [1451, 1368, 881, 1551, 2591, 2591],
      11301: [1471, 1390, 891, 1573, 2618, 2618],
      11401: [1491, 1409, 901, 1594, 2645, 2645],
      11501: [1511, 1427, 902, 1616, 2669, 2669],
      11601: [1531, 1446, 911, 1637, 2696, 2696],
      11701: [1551, 1468, 921, 1659, 2720, 2720],
      11801: [1571, 1486, 931, 1680, 2747, 2747],
      11901: [1591, 1505, 940, 1702, 2774, 2774],
      12001: [1611, 1524, 950, 1723, 2798, 2798],
      12101: [1631, 1546, 951, 1745, 2825, 2825],
      12201: [1651, 1564, 961, 1766, 2849, 2849],
      12301: [1671, 1583, 971, 1787, 2876, 2876],
      12401: [1691, 1602, 980, 1809, 2903, 2903],
      12501: [1711, 1624, 990, 1830, 2927, 2927],
      12601: [1731, 1642, 1e3, 1852, 2954, 2954],
      12701: [1751, 1661, 1009, 1873, 2978, 2978],
      12801: [1771, 1680, 1010, 1895, 3005, 3005],
      12901: [1791, 1701, 1020, 1916, 3029, 3029],
      13001: [1811, 1720, 1030, 1938, 3061, 3061],
      13101: [1832, 1739, 1039, 1960, 3094, 3094],
      13201: [1852, 1761, 1049, 1981, 3126, 3126],
      13301: [1872, 1779, 1059, 2003, 3158, 3158],
      13401: [1893, 1798, 1060, 2025, 3190, 3190],
      13501: [1913, 1817, 1070, 2047, 3223, 3223],
      13601: [1933, 1839, 1079, 2068, 3255, 3255],
      13701: [1953, 1857, 1089, 2090, 3287, 3287],
      13801: [1973, 1876, 1099, 2111, 3319, 3319],
      13901: [1992, 1895, 1108, 2132, 3350, 3350],
      14001: [2012, 1916, 1110, 2153, 3381, 3381],
      14101: [2031, 1935, 1119, 2174, 3413, 3413],
      14201: [2050, 1954, 1129, 2194, 3444, 3444],
      14301: [2070, 1973, 1138, 2215, 3475, 3475],
      14401: [2089, 1994, 1148, 2236, 3506, 3506],
      14501: [2108, 2013, 1158, 2257, 3538, 3538],
      14601: [2128, 2032, 1159, 2277, 3569, 3569],
      14701: [2147, 2051, 1169, 2298, 3600, 3600],
      14801: [2166, 2072, 1178, 2319, 3634, 3634],
      14901: [2186, 2091, 1188, 2340, 3668, 3668],
      15001: [2205, 2110, 1198, 2360, 3702, 3702],
      15101: [2224, 2129, 1207, 2381, 3735, 3735],
      15201: [2244, 2150, 1217, 2402, 3772, 3772],
      15301: [2263, 2169, 1218, 2423, 3806, 3806],
      15401: [2284, 2191, 1228, 2446, 3840, 3840],
      15501: [2310, 2217, 1238, 2473, 3873, 3873],
      15601: [2336, 2244, 1248, 2501, 3907, 3907],
      15701: [2362, 2271, 1258, 2528, 3944, 3944],
      15801: [2389, 2298, 1268, 2555, 3978, 3978],
      15901: [2415, 2325, 1270, 2583, 4011, 4011],
      16001: [2441, 2352, 1280, 2610, 4045, 4045],
      16101: [2467, 2379, 1291, 2637, 4079, 4079],
      16201: [2493, 2406, 1301, 2665, 4116, 4116],
      16301: [2520, 2432, 1311, 2692, 4150, 4150],
      16401: [2546, 2459, 1321, 2719, 4183, 4183],
      16501: [2572, 2486, 1323, 2747, 4217, 4217],
      16601: [2598, 2513, 1333, 2774, 4251, 4251],
      16701: [2625, 2540, 1343, 2802, 4288, 4288],
      16801: [2651, 2567, 1353, 2829, 4321, 4321],
      16901: [2677, 2594, 1363, 2856, 4355, 4355],
      17001: [2703, 2618, 1373, 2884, 4389, 4389],
      17101: [2730, 2645, 1375, 2911, 4423, 4423],
      17201: [2756, 2672, 1385, 2938, 4459, 4459],
      17301: [2782, 2699, 1395, 2966, 4493, 4493],
      17401: [2808, 2725, 1405, 2993, 4527, 4527],
      17501: [2834, 2752, 1416, 3020, 4561, 4561],
      17601: [2861, 2779, 1426, 3048, 4595, 4595],
      17701: [2887, 2806, 1436, 3075, 4631, 4631],
      17801: [2913, 2833, 1438, 3102, 4665, 4665],
      17901: [2939, 2860, 1448, 3130, 4699, 4699],
      18001: [2966, 2887, 1458, 3157, 4733, 4733],
      18101: [2992, 2914, 1468, 3184, 4767, 4767],
      18201: [3018, 2940, 1478, 3212, 4803, 4803],
      18301: [3044, 2967, 1488, 3239, 4837, 4837],
      18401: [3070, 2994, 1490, 3266, 4871, 4871],
      18501: [3097, 3021, 1500, 3294, 4905, 4905],
      18601: [3123, 3048, 1510, 3321, 4939, 4939],
      18701: [3149, 3075, 1520, 3348, 4975, 4975],
      18801: [3175, 3102, 1531, 3376, 5009, 5009],
      18901: [3202, 3129, 1541, 3403, 5043, 5043],
      19001: [3228, 3155, 1543, 3430, 5077, 5077],
      19101: [3254, 3182, 1553, 3458, 5110, 5110],
      19201: [3280, 3209, 1563, 3485, 5147, 5147],
      19301: [3306, 3236, 1573, 3512, 5181, 5181],
      19401: [3333, 3263, 1583, 3540, 5215, 5215],
      19501: [3359, 3290, 1593, 3567, 5248, 5248],
      19601: [3385, 3316, 1595, 3594, 5282, 5282],
      19701: [3411, 3342, 1604, 3622, 5319, 5319],
      19801: [3438, 3368, 1613, 3649, 5353, 5353],
      19901: [3464, 3394, 1622, 3676, 5386, 5386],
      20001: [3516, 3446, 1641, 3731, 5454, 5454],
      20201: [3569, 3496, 1651, 3786, 5525, 5525],
      20401: [3621, 3548, 1670, 3841, 5592, 5592],
      20601: [3674, 3600, 1688, 3895, 5663, 5663],
      20801: [3726, 3652, 1699, 3950, 5730, 5730],
      21001: [3778, 3704, 1717, 4005, 5798, 5798],
      21201: [3831, 3756, 1736, 4059, 5868, 5868],
      21401: [3883, 3808, 1746, 4114, 5936, 5936],
      21601: [3936, 3860, 1765, 4169, 6006, 6006],
      21801: [3988, 3913, 1783, 4223, 6074, 6074],
      22001: [4041, 3965, 1794, 4278, 6142, 6142],
      22201: [4093, 4017, 1812, 4333, 6212, 6212],
      22401: [4146, 4069, 1831, 4387, 6280, 6280],
      22601: [4198, 4121, 1849, 4442, 6350, 6350],
      22801: [4251, 4173, 1860, 4498, 6419, 6419],
      23001: [4306, 4225, 1878, 4554, 6488, 6488],
      23201: [4360, 4277, 1897, 4610, 6560, 6560],
      23401: [4414, 4327, 1907, 4667, 6629, 6629],
      23601: [4468, 4379, 1926, 4723, 6701, 6701],
      23801: [4522, 4428, 1944, 4779, 6771, 6771],
      24001: [4576, 4483, 1954, 4836, 6840, 6840],
      24201: [4630, 4535, 1973, 4892, 6912, 6912],
      24401: [4684, 4590, 1992, 4948, 6981, 6981],
      24601: [4738, 4642, 2002, 5005, 7053, 7053],
      24801: [4792, 4694, 2021, 5061, 7123, 7123],
      25001: [4847, 4749, 2039, 5117, 7192, 7192],
      25201: [4901, 4801, 2050, 5174, 7264, 7264],
      25401: [4955, 4853, 2068, 5230, 7333, 7333],
      25601: [5009, 4907, 2087, 5287, 7405, 7405],
      25801: [5063, 4959, 2097, 5343, 7475, 7475],
      26001: [5117, 5011, 2116, 5399, 7544, 7544],
      26201: [5171, 5066, 2134, 5456, 7616, 7616],
      26401: [5225, 5118, 2145, 5512, 7685, 7685],
      26601: [5279, 5173, 2168, 5568, 7757, 7757],
      26801: [5333, 5225, 2220, 5625, 7827, 7827],
      27001: [5388, 5277, 2272, 5681, 7896, 7896],
      27201: [5442, 5332, 2327, 5737, 7968, 7968],
      27401: [5496, 5384, 2379, 5793, 8037, 8037],
      27601: [5550, 5436, 2431, 5850, 8109, 8109],
      27801: [5604, 5491, 2486, 5906, 8179, 8179],
      28001: [5658, 5543, 2538, 5962, 8248, 8248],
      28201: [5712, 5597, 2593, 6019, 8320, 8320],
      28401: [5766, 5650, 2645, 6075, 8389, 8389],
      28601: [5820, 5702, 2697, 6132, 8461, 8461],
      28801: [5874, 5756, 2751, 6188, 8531, 8531],
      29001: [5928, 5808, 2804, 6244, 8600, 8600],
      29201: [5983, 5860, 2856, 6301, 8672, 8672],
      29401: [6037, 5915, 2910, 6357, 8741, 8741],
      29601: [6091, 5967, 2962, 6413, 8813, 8813],
      29801: [6145, 6022, 3017, 6470, 8883, 8883],
      30001: [6199, 6074, 3069, 6526, 8952, 8952],
      30201: [6253, 6126, 3121, 6582, 9024, 9024],
      30401: [6307, 6181, 3176, 6639, 9093, 9093],
      30601: [6361, 6233, 3228, 6695, 9165, 9165],
      30801: [6415, 6285, 3280, 6751, 9235, 9235],
      31001: [6469, 6340, 3335, 6808, 9304, 9304],
      31201: [6524, 6392, 3387, 6864, 9376, 9376],
      31401: [6578, 6445, 3441, 6920, 9445, 9445],
      31601: [6632, 6501, 3497, 6977, 9517, 9517],
      31801: [6686, 6555, 3550, 7033, 9587, 9587],
      32001: [6740, 6611, 3606, 7089, 9656, 9656],
      32201: [6794, 6664, 3659, 7146, 9728, 9728],
      32401: [6848, 6717, 3713, 7202, 9797, 9797],
      32601: [6902, 6773, 3769, 7258, 9869, 9869],
      32801: [6956, 6827, 3822, 7315, 9939, 9939],
      33001: [7010, 6880, 3875, 7371, 10008, 10008],
      33201: [7065, 6936, 3931, 7427, 10080, 10080],
      33401: [7119, 6989, 3985, 7484, 10149, 10149],
      33601: [7173, 7045, 4041, 7540, 10221, 10221],
      33801: [7227, 7099, 4094, 7596, 10291, 10291],
      34001: [7281, 7152, 4147, 7653, 10360, 10360],
      34201: [7335, 7208, 4203, 7709, 10432, 10432],
      34401: [7389, 7261, 4257, 7765, 10501, 10501],
      34601: [7443, 7315, 4310, 7822, 10573, 10573],
      34801: [7497, 7371, 4366, 7878, 10643, 10643],
      35001: [7551, 7424, 4419, 7934, 10712, 10712],
      35201: [7606, 7477, 4473, 7991, 10784, 10784],
      35401: [7660, 7533, 4529, 8047, 10853, 10853],
      35601: [7714, 7587, 4582, 8103, 10925, 10925],
      35801: [7768, 7643, 4638, 8160, 10995, 10995],
      36001: [7822, 7696, 4691, 8216, 11064, 11064],
      36201: [7876, 7749, 4745, 8272, 11136, 11136],
      36401: [7930, 7805, 4801, 8329, 11205, 11205],
      36601: [7984, 7859, 4854, 8385, 11277, 11277],
      36801: [8038, 7912, 4907, 8441, 11347, 11347],
      37001: [8092, 7968, 4963, 8498, 11416, 11416],
      37201: [8147, 8021, 5017, 8554, 11488, 11488],
      37401: [8201, 8077, 5073, 8610, 11557, 11557],
      37601: [8255, 8128, 5123, 8667, 11624, 11624],
      37801: [8308, 8181, 5177, 8723, 11688, 11688],
      38001: [8362, 8235, 5230, 8779, 11752, 11752],
      38201: [8416, 8291, 5286, 8835, 11816, 11816],
      38401: [8471, 8344, 5339, 8891, 11880, 11880],
      38601: [8535, 8408, 5403, 8947, 11944, 11944],
      38801: [8599, 8472, 5467, 9003, 12008, 12008],
      39001: [8663, 8536, 5531, 9059, 12072, 12072],
      39201: [8727, 8600, 5595, 9115, 12136, 12136],
      39401: [8791, 8664, 5659, 9171, 12200, 12200],
      39601: [8855, 8728, 5723, 9228, 12264, 12264],
      39801: [8919, 8792, 5787, 9284, 12328, 12328],
      40001: [8983, 8856, 5851, 9340, 12392, 12392],
      40201: [9047, 8920, 5915, 9396, 12456, 12456],
      40401: [9111, 8984, 5979, 9452, 12520, 12520],
      40601: [9175, 9048, 6043, 9508, 12584, 12584],
      40801: [9239, 9112, 6107, 9564, 12648, 12648],
      41001: [9303, 9176, 6171, 9620, 12712, 12712],
      41201: [9367, 9240, 6235, 9677, 12776, 12776],
      41401: [9431, 9304, 6299, 9733, 12840, 12840],
      41601: [9495, 9368, 6363, 9789, 12904, 12904],
      41801: [9559, 9432, 6427, 9845, 12968, 12968],
      42001: [9623, 9496, 6491, 9901, 13032, 13032],
      42201: [9687, 9560, 6555, 9957, 13096, 13096],
      42401: [9751, 9624, 6619, 10013, 13160, 13160],
      42601: [9815, 9688, 6683, 10069, 13224, 13224],
      42801: [9879, 9752, 6747, 10125, 13288, 13288],
      43001: [9943, 9816, 6811, 10181, 13352, 13352],
      43201: [10007, 9880, 6875, 10237, 13416, 13416],
      43401: [10071, 9944, 6939, 10294, 13480, 13480],
      43601: [10135, 10008, 7003, 10350, 13544, 13544],
      43801: [10199, 10072, 7067, 10406, 13608, 13608],
      44001: [10263, 10136, 7131, 10462, 13672, 13672],
      44201: [10327, 10200, 7195, 10518, 13736, 13736],
      44401: [10391, 10264, 7259, 10574, 13800, 13800],
      44601: [10455, 10328, 7323, 10630, 13864, 13864],
      44801: [10519, 10392, 7387, 10686, 13928, 13928],
      45001: [10583, 10456, 7451, 10742, 13992, 13992],
      45201: [10647, 10520, 7515, 10799, 14056, 14056],
      45401: [10711, 10584, 7579, 10855, 14120, 14120],
      45601: [10775, 10648, 7643, 10911, 14184, 14184],
      45801: [10839, 10712, 7707, 10967, 14248, 14248],
      46001: [10903, 10776, 7771, 11023, 14312, 14312],
      46201: [10967, 10840, 7835, 11079, 14376, 14376],
      46401: [11031, 10904, 7899, 11135, 14440, 14440],
      46601: [11095, 10968, 7963, 11191, 14504, 14504],
      46801: [11159, 11032, 8027, 11247, 14568, 14568],
      47001: [11223, 11096, 8091, 11303, 14632, 14632],
      47201: [11287, 11160, 8155, 11360, 14696, 14696],
      47401: [11351, 11224, 8219, 11416, 14760, 14760],
      47601: [11415, 11288, 8283, 11472, 14824, 14824],
      47801: [11479, 11352, 8347, 11528, 14888, 14888],
      48001: [11543, 11416, 8411, 11584, 14952, 14952],
      48201: [11607, 11480, 8475, 11640, 15016, 15016],
      48401: [11671, 11544, 8539, 11696, 15080, 15080],
      48601: [11735, 11608, 8603, 11752, 15144, 15144],
      48801: [11799, 11672, 8667, 11808, 15208, 15208],
      49001: [11863, 11736, 8731, 11865, 15272, 15272],
      49201: [11927, 11800, 8795, 11921, 15336, 15336],
      49401: [11991, 11864, 8859, 11977, 15400, 15400],
      49601: [12055, 11928, 8923, 12033, 15464, 15464],
      49801: [12119, 11992, 8987, 12089, 15528, 15528],
      50001: [12183, 12056, 9056, 12145, 15592, 15592],
      50201: [12247, 12120, 9126, 12201, 15656, 15656],
      50401: [12311, 12184, 9196, 12257, 15720, 15720],
      50601: [12375, 12248, 9266, 12313, 15784, 15784],
      50801: [12439, 12312, 9336, 12369, 15848, 15848],
      51001: [12503, 12376, 9406, 12426, 15912, 15912],
      51201: [12592, 12464, 9500, 12507, 16001, 16001],
      51401: [12696, 12568, 9610, 12603, 16105, 16105],
      51601: [12800, 12672, 9720, 12699, 16209, 16209],
      51801: [12904, 12773, 9827, 12795, 16313, 16313],
      52001: [13008, 12877, 9937, 12891, 16417, 16417],
      52201: [13112, 12981, 10047, 12987, 16521, 16521],
      52401: [13216, 13085, 10157, 13083, 16625, 16625],
      52601: [13320, 13189, 10267, 13179, 16729, 16729],
      52801: [13424, 13293, 10377, 13276, 16833, 16833],
      53001: [13528, 13397, 10487, 13372, 16937, 16937],
      53201: [13632, 13501, 10597, 13468, 17041, 17041],
      53401: [13736, 13603, 10705, 13564, 17145, 17145],
      53601: [13840, 13707, 10815, 13660, 17249, 17249],
      53801: [13944, 13811, 10925, 13756, 17353, 17353],
      54001: [14048, 13915, 11035, 13852, 17457, 17457],
      54201: [14152, 14019, 11145, 13948, 17561, 17561],
      54401: [14256, 14123, 11255, 14044, 17665, 17665],
      54601: [14360, 14227, 11365, 14140, 17769, 17769],
      54801: [14464, 14331, 11475, 14237, 17873, 17873],
      55001: [14568, 14435, 11585, 14333, 17977, 17977],
      55201: [14672, 14536, 11692, 14429, 18081, 18081],
      55401: [14776, 14640, 11802, 14525, 18185, 18185],
      55601: [14880, 14744, 11912, 14621, 18289, 18289],
      55801: [14984, 14848, 12022, 14717, 18393, 18393],
      56001: [15088, 14952, 12132, 14813, 18497, 18497],
      56201: [15192, 15056, 12242, 14909, 18601, 18601],
      56401: [15296, 15160, 12352, 15005, 18705, 18705],
      56601: [15400, 15264, 12462, 15102, 18809, 18809],
      56801: [15504, 15365, 12569, 15198, 18913, 18913],
      57001: [15608, 15469, 12679, 15294, 19017, 19017],
      57201: [15712, 15573, 12789, 15390, 19121, 19121],
      57401: [15816, 15677, 12899, 15486, 19225, 19225],
      57601: [15920, 15781, 13009, 15582, 19329, 19329],
      57801: [16024, 15885, 13119, 15678, 19433, 19433],
      58001: [16128, 15984, 13224, 15774, 19537, 19537],
      58201: [16232, 16088, 13334, 15870, 19641, 19641],
      58401: [16336, 16192, 13444, 15966, 19745, 19745],
      58601: [16440, 16296, 13554, 16063, 19849, 19849],
      58801: [16544, 16400, 13664, 16159, 19953, 19953],
      59001: [16648, 16504, 13774, 16255, 20057, 20057],
      59201: [16752, 16608, 13884, 16351, 20161, 20161],
      59401: [16856, 16712, 13994, 16447, 20265, 20265],
      59601: [16960, 16816, 14104, 16543, 20369, 20369],
      59801: [17064, 16920, 14214, 16639, 20473, 20473],
      60001: [17168, 17024, 14324, 16735, 20577, 20577],
      60201: [17272, 17128, 14434, 16831, 20681, 20681],
      60401: [17376, 17232, 14544, 16928, 20785, 20785],
      60601: [17480, 17336, 14654, 17024, 20889, 20889],
      60801: [17584, 17440, 14764, 17120, 20993, 20993],
      61001: [17688, 17544, 14874, 17216, 21097, 21097],
      61201: [17792, 17648, 14984, 17312, 21201, 21201],
      61401: [17896, 17752, 15094, 17408, 21305, 21305],
      61601: [18e3, 17856, 15204, 17504, 21409, 21409],
      61801: [18104, 17960, 15314, 17600, 21513, 21513],
      62001: [18208, 18064, 15424, 17696, 21617, 21617],
      62201: [18312, 18168, 15534, 17792, 21721, 21721],
      62401: [18416, 18272, 15644, 17889, 21825, 21825],
      62601: [18520, 18376, 15754, 17985, 21929, 21929],
      62801: [18624, 18480, 15864, 18081, 22033, 22033],
      63001: [18728, 18584, 15974, 18177, 22137, 22137],
      63201: [18832, 18688, 16084, 18273, 22241, 22241],
      63401: [18936, 18792, 16194, 18369, 22345, 22345],
      63601: [19040, 18896, 16304, 18465, 22449, 22449],
      63801: [19144, 19e3, 16414, 18561, 22553, 22553],
      64001: [19248, 19104, 16524, 18657, 22657, 22657],
      64201: [19352, 19208, 16634, 18754, 22761, 22761],
      64401: [19456, 19312, 16744, 18850, 22865, 22865],
      64601: [19565, 19424, 16862, 18946, 22969, 22969],
      64801: [19675, 19533, 16977, 19042, 23073, 23073],
      65001: [19785, 19645, 17095, 19138, 23177, 23177],
      65201: [19895, 19754, 17210, 19234, 23281, 23281],
      65401: [20005, 19862, 17324, 19330, 23385, 23385],
      65601: [20115, 19975, 17443, 19426, 23489, 23489],
      65801: [20225, 20083, 17557, 19522, 23593, 23593],
      66001: [20335, 20196, 17676, 19618, 23697, 23697],
      66201: [20445, 20304, 17790, 19715, 23801, 23801],
      66401: [20555, 20412, 17904, 19811, 23905, 23905],
      66601: [20665, 20525, 18023, 19907, 24009, 24009],
      66801: [20775, 20633, 18137, 20003, 24113, 24113],
      67001: [20885, 20742, 18252, 20099, 24217, 24217],
      67201: [20995, 20854, 18370, 20195, 24321, 24321],
      67401: [21105, 20963, 18485, 20291, 24425, 24425],
      67601: [21215, 21075, 18603, 20387, 24529, 24529],
      67801: [21325, 21184, 18718, 20483, 24633, 24633],
      68001: [21435, 21292, 18832, 20580, 24737, 24737],
      68201: [21545, 21405, 18951, 20676, 24841, 24841],
      68401: [21655, 21513, 19065, 20772, 24945, 24945],
      68601: [21765, 21621, 19179, 20868, 25049, 25049],
      68801: [21875, 21734, 19298, 20964, 25153, 25153],
      69001: [21985, 21842, 19412, 21060, 25257, 25257],
      69201: [22095, 21955, 19531, 21156, 25361, 25361],
      69401: [22205, 22063, 19645, 21252, 25465, 25465],
      69601: [22315, 22172, 19760, 21348, 25569, 25569],
      69801: [22425, 22284, 19878, 21444, 25673, 25673],
      70001: [22535, 22393, 19993, 21541, 25777, 25777],
      70201: [22645, 22501, 20107, 21637, 25881, 25881],
      70401: [22755, 22614, 20226, 21733, 25985, 25985],
      70601: [22865, 22722, 20340, 21829, 26089, 26089],
      70801: [22975, 22835, 20459, 21925, 26193, 26193],
      71001: [23085, 22943, 20573, 22021, 26297, 26297],
      71201: [23195, 23051, 20687, 22117, 26401, 26401],
      71401: [23305, 23164, 20806, 22213, 26505, 26505],
      71601: [23415, 23272, 20920, 22309, 26609, 26609],
      71801: [23525, 23385, 21039, 22405, 26713, 26713],
      72001: [23635, 23493, 21153, 22502, 26817, 26817],
      72201: [23745, 23602, 21268, 22598, 26921, 26921],
      72401: [23855, 23714, 21386, 22694, 27025, 27025],
      72601: [23965, 23823, 21501, 22790, 27129, 27129],
      72801: [24075, 23931, 21615, 22886, 27233, 27233],
      73001: [24185, 24044, 21734, 22982, 27337, 27337],
      73201: [24295, 24152, 21848, 23078, 27441, 27441],
      73401: [24405, 24265, 21967, 23174, 27545, 27545],
      73601: [24515, 24373, 22081, 23270, 27649, 27649],
      73801: [24625, 24481, 22195, 23367, 27753, 27753],
      74001: [24735, 24594, 22314, 23463, 27857, 27857],
      74201: [24845, 24702, 22428, 23559, 27961, 27961],
      74401: [24955, 24811, 22543, 23655, 28065, 28065],
      74601: [25065, 24923, 22661, 23751, 28169, 28169],
      74801: [25175, 25032, 22776, 23847, 28273, 28273],
      75001: [25285, 25144, 22894, 23943, 28377, 28377],
      75201: [25395, 25253, 23009, 24039, 28481, 28481],
      75401: [25505, 25361, 23123, 24135, 28585, 28585],
      75601: [25615, 25474, 23242, 24231, 28689, 28689],
      75801: [25725, 25582, 23356, 24328, 28793, 28793],
      76001: [25835, 25690, 23470, 24424, 28897, 28897],
      76201: [25945, 25803, 23589, 24520, 29001, 29001],
      76401: [26055, 25911, 23703, 24616, 29105, 29105],
      76601: [26165, 26024, 23822, 24712, 29209, 29209],
      76801: [26275, 26132, 23936, 24808, 29313, 29313],
      77001: [26385, 26241, 24051, 24904, 29417, 29417],
      77201: [26495, 26353, 24169, 25e3, 29521, 29521],
      77401: [26605, 26462, 24284, 25096, 29625, 29625],
      77601: [26715, 26570, 24398, 25193, 29729, 29729],
      77801: [26825, 26683, 24517, 25289, 29833, 29833],
      78001: [26935, 26791, 24631, 25385, 29937, 29937],
      78201: [27045, 26904, 24750, 25481, 30041, 30041],
      78401: [27155, 27012, 24864, 25577, 30145, 30145],
      78601: [27265, 27120, 24978, 25673, 30249, 30249],
      78801: [27375, 27233, 25097, 25769, 30353, 30353],
      79001: [27485, 27341, 25211, 25865, 30457, 30457],
      79201: [27595, 27450, 25326, 25961, 30561, 30561],
      79401: [27705, 27562, 25444, 26057, 30665, 30665],
      79601: [27815, 27671, 25559, 26154, 30769, 30769],
      79801: [27925, 27783, 25677, 26250, 30873, 30873],
      80001: [35, 35, 32, 33, 39, 39, "%"],
      81201: [35, 35, 33, 33, 39, 39, "%"],
      82401: [36, 35, 33, 33, 39, 39, "%"],
      83001: [36, 36, 33, 33, 39, 39, "%"],
      83601: [36, 36, 33, 34, 39, 39, "%"],
      84401: [36, 36, 34, 34, 39, 39, "%"],
      85801: [36, 36, 34, 34, 40, 40, "%"],
      86801: [37, 36, 34, 34, 40, 40, "%"],
      87601: [37, 37, 34, 34, 40, 40, "%"],
      88001: [37, 37, 35, 34, 40, 40, "%"],
      89801: [37, 37, 35, 35, 40, 40, "%"],
      91801: [38, 37, 35, 35, 40, 40, "%"],
      92001: [38, 37, 36, 35, 40, 40, "%"],
      92601: [38, 38, 36, 35, 40, 40, "%"],
      93201: [38, 38, 36, 35, 41, 41, "%"],
      96201: [38, 38, 37, 35, 41, 41, "%"],
      97001: [38, 38, 37, 36, 41, 41, "%"],
      97401: [39, 38, 37, 36, 41, 41, "%"],
      98201: [39, 39, 37, 36, 41, 41, "%"],
      101001: [39, 39, 38, 36, 41, 41, "%"],
      102001: [39, 39, 38, 36, 42, 42, "%"],
      103601: [40, 39, 38, 36, 42, 42, "%"],
      104601: [40, 40, 38, 36, 42, 42, "%"],
      105401: [40, 40, 38, 37, 42, 42, "%"],
      106201: [40, 40, 39, 37, 42, 42, "%"],
      110801: [41, 40, 39, 37, 42, 42, "%"],
      111801: [41, 41, 39, 37, 42, 42, "%"],
      112001: [41, 41, 40, 37, 42, 42, "%"],
      112801: [41, 41, 40, 37, 43, 43, "%"],
      115401: [41, 41, 40, 38, 43, 43, "%"],
      118401: [41, 41, 41, 38, 43, 43, "%"],
      119001: [42, 41, 41, 38, 43, 43, "%"],
      120001: [42, 42, 41, 38, 43, 43, "%"],
      125601: [42, 42, 42, 38, 43, 43, "%"],
      126001: [42, 42, 42, 38, 44, 44, "%"],
      127601: [42, 42, 42, 39, 44, 44, "%"],
      128401: [43, 42, 42, 39, 44, 44, "%"],
      129601: [43, 43, 42, 39, 44, 44, "%"],
      133601: [43, 43, 43, 39, 44, 44, "%"],
      139601: [44, 43, 43, 39, 44, 44, "%"],
      141001: [44, 44, 43, 39, 44, 44, "%"],
      142401: [44, 44, 43, 40, 44, 44, "%"],
      142801: [44, 44, 44, 40, 44, 44, "%"],
      143001: [44, 44, 44, 40, 45, 45, "%"],
      153001: [45, 44, 44, 40, 45, 45, "%"],
      154401: [45, 45, 45, 40, 45, 45, "%"],
      161401: [45, 45, 45, 41, 45, 45, "%"],
      165001: [45, 45, 45, 41, 46, 46, "%"],
      169201: [46, 45, 45, 41, 46, 46, "%"],
      170801: [46, 46, 46, 41, 46, 46, "%"],
      186001: [46, 46, 46, 42, 46, 46, "%"],
      195001: [47, 47, 47, 42, 47, 47, "%"],
      219401: [47, 47, 47, 43, 47, 47, "%"],
      238201: [48, 48, 48, 43, 48, 48, "%"],
      267801: [48, 48, 48, 44, 48, 48, "%"],
      306401: [49, 49, 49, 44, 49, 49, "%"],
      343001: [49, 49, 49, 45, 49, 49, "%"],
      429001: [50, 50, 50, 45, 50, 50, "%"],
      477601: [50, 50, 50, 46, 50, 50, "%"],
      715001: [51, 51, 51, 46, 51, 51, "%"],
      785201: [51, 51, 51, 47, 51, 51, "%"]
    },
    33: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 25],
      2101: [153, 0, 150, 3, 153, 59],
      2201: [164, 0, 158, 5, 164, 93],
      2301: [174, 0, 166, 8, 174, 127],
      2401: [185, 0, 175, 10, 185, 161],
      2501: [195, 0, 183, 12, 195, 195],
      2601: [206, 0, 191, 14, 229, 229],
      2701: [216, 0, 200, 16, 263, 263],
      2801: [218, 0, 200, 18, 297, 297],
      2901: [229, 0, 208, 21, 331, 331],
      3001: [239, 0, 216, 23, 365, 365],
      3101: [250, 0, 225, 25, 399, 399],
      3201: [260, 0, 233, 27, 433, 433],
      3301: [271, 0, 241, 29, 467, 467],
      3401: [273, 0, 241, 31, 501, 501],
      3501: [284, 0, 250, 34, 535, 535],
      3601: [294, 0, 258, 36, 569, 569],
      3701: [305, 0, 266, 38, 603, 603],
      3801: [315, 0, 275, 40, 637, 637],
      3901: [325, 0, 283, 42, 671, 671],
      4001: [328, 0, 283, 44, 705, 705],
      4101: [338, 0, 291, 46, 739, 739],
      4201: [349, 0, 300, 49, 773, 773],
      4301: [359, 0, 308, 62, 807, 807],
      4401: [370, 0, 316, 85, 841, 841],
      4501: [380, 0, 325, 109, 875, 875],
      4601: [382, 0, 325, 132, 909, 909],
      4701: [392, 0, 333, 155, 937, 937],
      4801: [403, 0, 341, 177, 966, 966],
      4901: [413, 0, 350, 200, 994, 994],
      5001: [423, 0, 358, 223, 1019, 1019],
      5101: [433, 0, 366, 246, 1048, 1048],
      5201: [443, 0, 375, 268, 1073, 1073],
      5301: [445, 22, 376, 291, 1102, 1102],
      5401: [455, 48, 386, 314, 1130, 1130],
      5501: [465, 73, 396, 336, 1155, 1155],
      5601: [475, 99, 406, 358, 1183, 1183],
      5701: [485, 124, 416, 380, 1208, 1208],
      5801: [495, 150, 426, 402, 1235, 1235],
      5901: [497, 175, 427, 425, 1263, 1263],
      6001: [507, 201, 437, 447, 1288, 1288],
      6101: [517, 226, 447, 469, 1316, 1316],
      6201: [527, 249, 457, 491, 1341, 1341],
      6301: [537, 274, 467, 513, 1368, 1368],
      6401: [547, 300, 477, 535, 1396, 1396],
      6501: [549, 325, 479, 557, 1421, 1421],
      6601: [559, 351, 488, 579, 1449, 1449],
      6701: [569, 376, 498, 601, 1474, 1474],
      6801: [585, 402, 508, 624, 1501, 1501],
      6901: [606, 427, 518, 646, 1529, 1529],
      7001: [627, 453, 528, 668, 1554, 1554],
      7101: [647, 478, 530, 690, 1582, 1582],
      7201: [668, 504, 540, 712, 1607, 1607],
      7301: [689, 529, 550, 734, 1634, 1634],
      7401: [709, 552, 560, 756, 1662, 1662],
      7501: [730, 577, 570, 779, 1687, 1687],
      7601: [751, 603, 580, 801, 1715, 1715],
      7701: [771, 628, 589, 823, 1740, 1740],
      7801: [792, 654, 591, 845, 1767, 1767],
      7901: [812, 679, 601, 867, 1795, 1795],
      8001: [833, 705, 611, 889, 1820, 1820],
      8101: [854, 730, 621, 911, 1848, 1848],
      8201: [874, 756, 631, 933, 1873, 1873],
      8301: [895, 781, 641, 956, 1900, 1900],
      8401: [916, 807, 642, 978, 1928, 1928],
      8501: [936, 832, 652, 1e3, 1953, 1953],
      8601: [957, 855, 662, 1022, 1981, 1981],
      8701: [977, 881, 672, 1044, 2006, 2006],
      8801: [998, 906, 682, 1066, 2033, 2033],
      8901: [1019, 932, 692, 1088, 2061, 2061],
      9001: [1039, 957, 694, 1110, 2086, 2086],
      9101: [1060, 983, 704, 1133, 2114, 2114],
      9201: [1081, 1008, 714, 1155, 2139, 2139],
      9301: [1101, 1025, 723, 1177, 2166, 2166],
      9401: [1122, 1048, 733, 1199, 2194, 2194],
      9501: [1143, 1068, 742, 1221, 2219, 2219],
      9601: [1163, 1087, 744, 1243, 2247, 2247],
      9701: [1184, 1107, 753, 1265, 2272, 2272],
      9801: [1204, 1130, 763, 1287, 2299, 2299],
      9901: [1225, 1149, 773, 1310, 2327, 2327],
      10001: [1246, 1169, 782, 1332, 2352, 2352],
      10101: [1266, 1188, 792, 1354, 2380, 2380],
      10201: [1287, 1210, 802, 1376, 2405, 2405],
      10301: [1308, 1230, 803, 1398, 2432, 2432],
      10401: [1328, 1249, 812, 1420, 2460, 2460],
      10501: [1349, 1269, 822, 1442, 2485, 2485],
      10601: [1370, 1291, 832, 1464, 2513, 2513],
      10701: [1390, 1310, 841, 1486, 2538, 2538],
      10801: [1411, 1330, 851, 1509, 2565, 2565],
      10901: [1431, 1349, 852, 1531, 2593, 2593],
      11001: [1452, 1371, 862, 1553, 2618, 2618],
      11101: [1473, 1391, 872, 1575, 2646, 2646],
      11201: [1493, 1410, 881, 1597, 2671, 2671],
      11301: [1514, 1432, 891, 1619, 2698, 2698],
      11401: [1535, 1451, 901, 1642, 2726, 2726],
      11501: [1555, 1471, 902, 1664, 2751, 2751],
      11601: [1576, 1490, 911, 1686, 2779, 2779],
      11701: [1596, 1512, 921, 1708, 2804, 2804],
      11801: [1617, 1532, 931, 1730, 2831, 2831],
      11901: [1638, 1551, 940, 1752, 2859, 2859],
      12001: [1658, 1571, 950, 1774, 2884, 2884],
      12101: [1679, 1593, 951, 1796, 2912, 2912],
      12201: [1700, 1612, 961, 1818, 2937, 2937],
      12301: [1720, 1632, 971, 1841, 2964, 2964],
      12401: [1741, 1651, 980, 1863, 2992, 2992],
      12501: [1762, 1673, 990, 1885, 3017, 3017],
      12601: [1782, 1693, 1e3, 1907, 3045, 3045],
      12701: [1803, 1712, 1009, 1929, 3070, 3070],
      12801: [1823, 1731, 1010, 1951, 3097, 3097],
      12901: [1844, 1753, 1020, 1973, 3122, 3122],
      13001: [1865, 1773, 1030, 1996, 3156, 3156],
      13101: [1886, 1792, 1039, 2018, 3189, 3189],
      13201: [1907, 1814, 1049, 2040, 3222, 3222],
      13301: [1928, 1834, 1059, 2063, 3255, 3255],
      13401: [1949, 1853, 1060, 2085, 3289, 3289],
      13501: [1970, 1873, 1070, 2108, 3322, 3322],
      13601: [1990, 1895, 1079, 2130, 3355, 3355],
      13701: [2011, 1914, 1089, 2153, 3388, 3388],
      13801: [2032, 1934, 1099, 2174, 3421, 3421],
      13901: [2052, 1953, 1108, 2196, 3453, 3453],
      14001: [2072, 1975, 1110, 2217, 3486, 3486],
      14101: [2091, 1995, 1119, 2239, 3518, 3518],
      14201: [2111, 2014, 1129, 2260, 3550, 3550],
      14301: [2131, 2033, 1138, 2281, 3582, 3582],
      14401: [2151, 2056, 1148, 2303, 3614, 3614],
      14501: [2171, 2075, 1158, 2324, 3647, 3647],
      14601: [2191, 2094, 1159, 2346, 3679, 3679],
      14701: [2211, 2114, 1169, 2367, 3711, 3711],
      14801: [2231, 2136, 1178, 2388, 3746, 3746],
      14901: [2251, 2155, 1188, 2410, 3781, 3781],
      15001: [2271, 2175, 1198, 2431, 3816, 3816],
      15101: [2291, 2194, 1207, 2453, 3851, 3851],
      15201: [2311, 2216, 1217, 2474, 3889, 3889],
      15301: [2331, 2236, 1218, 2496, 3924, 3924],
      15401: [2352, 2258, 1228, 2520, 3958, 3958],
      15501: [2379, 2285, 1238, 2548, 3993, 3993],
      15601: [2406, 2313, 1248, 2576, 4028, 4028],
      15701: [2433, 2341, 1258, 2604, 4066, 4066],
      15801: [2460, 2369, 1268, 2633, 4101, 4101],
      15901: [2487, 2396, 1270, 2661, 4136, 4136],
      16001: [2514, 2424, 1280, 2689, 4171, 4171],
      16101: [2542, 2452, 1291, 2717, 4206, 4206],
      16201: [2569, 2479, 1301, 2745, 4243, 4243],
      16301: [2596, 2507, 1311, 2774, 4278, 4278],
      16401: [2623, 2535, 1321, 2802, 4313, 4313],
      16501: [2650, 2563, 1323, 2830, 4348, 4348],
      16601: [2677, 2590, 1333, 2858, 4383, 4383],
      16701: [2704, 2618, 1343, 2887, 4421, 4421],
      16801: [2731, 2646, 1353, 2915, 4456, 4456],
      16901: [2758, 2673, 1363, 2943, 4491, 4491],
      17001: [2785, 2698, 1373, 2971, 4526, 4526],
      17101: [2812, 2726, 1375, 2999, 4561, 4561],
      17201: [2839, 2754, 1385, 3028, 4598, 4598],
      17301: [2866, 2781, 1395, 3056, 4633, 4633],
      17401: [2893, 2809, 1405, 3084, 4668, 4668],
      17501: [2920, 2837, 1416, 3112, 4703, 4703],
      17601: [2947, 2865, 1426, 3140, 4738, 4738],
      17701: [2975, 2892, 1436, 3169, 4775, 4775],
      17801: [3002, 2920, 1438, 3197, 4810, 4810],
      17901: [3029, 2948, 1448, 3225, 4845, 4845],
      18001: [3056, 2975, 1458, 3253, 4880, 4880],
      18101: [3083, 3003, 1468, 3281, 4915, 4915],
      18201: [3110, 3031, 1478, 3310, 4953, 4953],
      18301: [3137, 3059, 1488, 3338, 4988, 4988],
      18401: [3164, 3086, 1490, 3366, 5023, 5023],
      18501: [3191, 3114, 1500, 3394, 5058, 5058],
      18601: [3218, 3142, 1510, 3423, 5093, 5093],
      18701: [3245, 3169, 1520, 3451, 5130, 5130],
      18801: [3272, 3197, 1531, 3479, 5165, 5165],
      18901: [3299, 3225, 1541, 3507, 5200, 5200],
      19001: [3326, 3253, 1543, 3535, 5235, 5235],
      19101: [3353, 3280, 1553, 3564, 5270, 5270],
      19201: [3380, 3308, 1563, 3592, 5308, 5308],
      19301: [3407, 3336, 1573, 3620, 5343, 5343],
      19401: [3435, 3363, 1583, 3648, 5377, 5377],
      19501: [3462, 3391, 1593, 3676, 5412, 5412],
      19601: [3489, 3418, 1595, 3705, 5447, 5447],
      19701: [3516, 3445, 1604, 3733, 5485, 5485],
      19801: [3543, 3472, 1613, 3761, 5520, 5520],
      19901: [3570, 3499, 1622, 3789, 5555, 5555],
      20001: [3624, 3553, 1641, 3846, 5625, 5625],
      20201: [3678, 3604, 1651, 3902, 5697, 5697],
      20401: [3732, 3657, 1670, 3959, 5767, 5767],
      20601: [3786, 3711, 1688, 4015, 5840, 5840],
      20801: [3840, 3765, 1699, 4072, 5910, 5910],
      21001: [3895, 3819, 1717, 4128, 5979, 5979],
      21201: [3949, 3872, 1736, 4184, 6052, 6052],
      21401: [4003, 3926, 1746, 4241, 6122, 6122],
      21601: [4057, 3980, 1765, 4297, 6194, 6194],
      21801: [4111, 4034, 1783, 4354, 6264, 6264],
      22001: [4165, 4087, 1794, 4410, 6334, 6334],
      22201: [4219, 4141, 1812, 4467, 6407, 6407],
      22401: [4273, 4195, 1831, 4523, 6477, 6477],
      22601: [4327, 4249, 1849, 4579, 6549, 6549],
      22801: [4383, 4302, 1860, 4637, 6620, 6620],
      23001: [4438, 4356, 1878, 4695, 6692, 6692],
      23201: [4494, 4410, 1897, 4753, 6766, 6766],
      23401: [4550, 4461, 1907, 4811, 6837, 6837],
      23601: [4606, 4515, 1926, 4869, 6912, 6912],
      23801: [4661, 4566, 1944, 4927, 6983, 6983],
      24001: [4717, 4622, 1954, 4985, 7055, 7055],
      24201: [4773, 4676, 1973, 5043, 7129, 7129],
      24401: [4829, 4732, 1992, 5101, 7200, 7200],
      24601: [4885, 4786, 2002, 5160, 7275, 7275],
      24801: [4940, 4840, 2021, 5218, 7346, 7346],
      25001: [4996, 4896, 2039, 5276, 7418, 7418],
      25201: [5052, 4950, 2050, 5334, 7492, 7492],
      25401: [5108, 5004, 2068, 5392, 7563, 7563],
      25601: [5163, 5060, 2087, 5450, 7638, 7638],
      25801: [5219, 5114, 2109, 5508, 7709, 7709],
      26001: [5275, 5168, 2163, 5566, 7781, 7781],
      26201: [5331, 5224, 2219, 5624, 7855, 7855],
      26401: [5386, 5278, 2273, 5682, 7926, 7926],
      26601: [5442, 5334, 2330, 5740, 8001, 8001],
      26801: [5498, 5388, 2383, 5798, 8072, 8072],
      27001: [5554, 5442, 2437, 5856, 8144, 8144],
      27201: [5609, 5498, 2494, 5915, 8218, 8218],
      27401: [5665, 5552, 2547, 5973, 8289, 8289],
      27601: [5721, 5606, 2601, 6031, 8364, 8364],
      27801: [5777, 5662, 2657, 6089, 8435, 8435],
      28001: [5833, 5716, 2711, 6147, 8507, 8507],
      28201: [5888, 5773, 2768, 6205, 8581, 8581],
      28401: [5944, 5826, 2821, 6263, 8652, 8652],
      28601: [6e3, 5880, 2875, 6321, 8727, 8727],
      28801: [6056, 5936, 2932, 6379, 8798, 8798],
      29001: [6111, 5990, 2985, 6437, 8870, 8870],
      29201: [6167, 6044, 3039, 6495, 8944, 8944],
      29401: [6223, 6100, 3096, 6553, 9015, 9015],
      29601: [6279, 6154, 3149, 6612, 9090, 9090],
      29801: [6334, 6211, 3206, 6670, 9161, 9161],
      30001: [6390, 6264, 3259, 6728, 9233, 9233],
      30201: [6446, 6318, 3313, 6786, 9307, 9307],
      30401: [6502, 6375, 3370, 6844, 9378, 9378],
      30601: [6558, 6428, 3423, 6902, 9453, 9453],
      30801: [6613, 6482, 3477, 6960, 9524, 9524],
      31001: [6669, 6538, 3534, 7018, 9596, 9596],
      31201: [6725, 6593, 3588, 7076, 9670, 9670],
      31401: [6781, 6648, 3643, 7134, 9741, 9741],
      31601: [6836, 6705, 3700, 7192, 9816, 9816],
      31801: [6892, 6760, 3755, 7250, 9887, 9887],
      32001: [6948, 6818, 3813, 7308, 9959, 9959],
      32201: [7004, 6873, 3868, 7367, 10033, 10033],
      32401: [7059, 6928, 3923, 7425, 10104, 10104],
      32601: [7115, 6986, 3981, 7483, 10179, 10179],
      32801: [7171, 7041, 4036, 7541, 10250, 10250],
      33001: [7227, 7096, 4091, 7599, 10322, 10322],
      33201: [7283, 7154, 4149, 7657, 10396, 10396],
      33401: [7338, 7209, 4204, 7715, 10467, 10467],
      33601: [7394, 7266, 4261, 7773, 10542, 10542],
      33801: [7450, 7321, 4316, 7831, 10613, 10613],
      34001: [7506, 7376, 4371, 7889, 10685, 10685],
      34201: [7561, 7434, 4429, 7947, 10759, 10759],
      34401: [7617, 7489, 4484, 8005, 10830, 10830],
      34601: [7673, 7544, 4539, 8064, 10905, 10905],
      34801: [7729, 7602, 4597, 8122, 10976, 10976],
      35001: [7784, 7657, 4652, 8180, 11048, 11048],
      35201: [7840, 7712, 4707, 8238, 11122, 11122],
      35401: [7896, 7770, 4765, 8296, 11193, 11193],
      35601: [7952, 7825, 4820, 8354, 11268, 11268],
      35801: [8008, 7882, 4878, 8412, 11339, 11339],
      36001: [8063, 7937, 4932, 8470, 11411, 11411],
      36201: [8119, 7992, 4987, 8528, 11485, 11485],
      36401: [8175, 8050, 5045, 8586, 11556, 11556],
      36601: [8231, 8105, 5100, 8644, 11631, 11631],
      36801: [8286, 8160, 5155, 8702, 11702, 11702],
      37001: [8342, 8218, 5213, 8761, 11774, 11774],
      37201: [8398, 8273, 5268, 8819, 11848, 11848],
      37401: [8454, 8331, 5326, 8877, 11919, 11919],
      37601: [8509, 8383, 5378, 8935, 11988, 11988],
      37801: [8565, 8438, 5433, 8992, 12054, 12054],
      38001: [8620, 8493, 5488, 9050, 12120, 12120],
      38201: [8676, 8551, 5546, 9108, 12186, 12186],
      38401: [8733, 8606, 5601, 9166, 12252, 12252],
      38601: [8799, 8672, 5667, 9224, 12318, 12318],
      38801: [8865, 8738, 5733, 9282, 12384, 12384],
      39001: [8931, 8804, 5799, 9340, 12450, 12450],
      39201: [8997, 8870, 5865, 9397, 12516, 12516],
      39401: [9063, 8936, 5931, 9455, 12582, 12582],
      39601: [9129, 9002, 5997, 9513, 12648, 12648],
      39801: [9195, 9068, 6063, 9571, 12714, 12714],
      40001: [9261, 9134, 6129, 9629, 12780, 12780],
      40201: [9327, 9200, 6195, 9687, 12846, 12846],
      40401: [9393, 9266, 6261, 9745, 12912, 12912],
      40601: [9459, 9332, 6327, 9802, 12978, 12978],
      40801: [9525, 9398, 6393, 9860, 13044, 13044],
      41001: [9591, 9464, 6459, 9918, 13110, 13110],
      41201: [9657, 9530, 6525, 9976, 13176, 13176],
      41401: [9723, 9596, 6591, 10034, 13242, 13242],
      41601: [9789, 9662, 6657, 10092, 13308, 13308],
      41801: [9855, 9728, 6723, 10149, 13374, 13374],
      42001: [9921, 9794, 6789, 10207, 13440, 13440],
      42201: [9987, 9860, 6855, 10265, 13506, 13506],
      42401: [10053, 9926, 6921, 10323, 13572, 13572],
      42601: [10119, 9992, 6987, 10381, 13638, 13638],
      42801: [10185, 10058, 7053, 10439, 13704, 13704],
      43001: [10251, 10124, 7119, 10497, 13770, 13770],
      43201: [10317, 10190, 7185, 10554, 13836, 13836],
      43401: [10383, 10256, 7251, 10612, 13902, 13902],
      43601: [10449, 10322, 7317, 10670, 13968, 13968],
      43801: [10515, 10388, 7383, 10728, 14034, 14034],
      44001: [10581, 10454, 7449, 10786, 14100, 14100],
      44201: [10647, 10520, 7515, 10844, 14166, 14166],
      44401: [10713, 10586, 7581, 10902, 14232, 14232],
      44601: [10779, 10652, 7647, 10959, 14298, 14298],
      44801: [10845, 10718, 7713, 11017, 14364, 14364],
      45001: [10911, 10784, 7779, 11075, 14430, 14430],
      45201: [10977, 10850, 7845, 11133, 14496, 14496],
      45401: [11043, 10916, 7911, 11191, 14562, 14562],
      45601: [11109, 10982, 7977, 11249, 14628, 14628],
      45801: [11175, 11048, 8043, 11306, 14694, 14694],
      46001: [11241, 11114, 8109, 11364, 14760, 14760],
      46201: [11307, 11180, 8175, 11422, 14826, 14826],
      46401: [11373, 11246, 8241, 11480, 14892, 14892],
      46601: [11439, 11312, 8307, 11538, 14958, 14958],
      46801: [11505, 11378, 8373, 11596, 15024, 15024],
      47001: [11571, 11444, 8439, 11654, 15090, 15090],
      47201: [11637, 11510, 8505, 11711, 15156, 15156],
      47401: [11703, 11576, 8571, 11769, 15222, 15222],
      47601: [11769, 11642, 8637, 11827, 15288, 15288],
      47801: [11835, 11708, 8703, 11885, 15354, 15354],
      48001: [11901, 11774, 8769, 11943, 15420, 15420],
      48201: [11967, 11840, 8835, 12001, 15486, 15486],
      48401: [12033, 11906, 8901, 12058, 15552, 15552],
      48601: [12099, 11972, 8967, 12116, 15618, 15618],
      48801: [12165, 12038, 9033, 12174, 15684, 15684],
      49001: [12231, 12104, 9099, 12232, 15750, 15750],
      49201: [12297, 12170, 9165, 12290, 15816, 15816],
      49401: [12363, 12236, 9231, 12348, 15882, 15882],
      49601: [12429, 12302, 9297, 12406, 15948, 15948],
      49801: [12495, 12368, 9363, 12463, 16014, 16014],
      50001: [12561, 12434, 9433, 12521, 16080, 16080],
      50201: [12627, 12500, 9505, 12579, 16146, 16146],
      50401: [12693, 12566, 9577, 12637, 16212, 16212],
      50601: [12759, 12632, 9649, 12695, 16278, 16278],
      50801: [12825, 12698, 9721, 12753, 16344, 16344],
      51001: [12891, 12764, 9793, 12811, 16410, 16410],
      51201: [12982, 12854, 9890, 12893, 16501, 16501],
      51401: [13088, 12962, 10003, 12991, 16607, 16607],
      51601: [13194, 13069, 10117, 13089, 16713, 16713],
      51801: [13300, 13173, 10227, 13187, 16819, 16819],
      52001: [13406, 13281, 10340, 13285, 16925, 16925],
      52201: [13512, 13388, 10454, 13383, 17031, 17031],
      52401: [13618, 13495, 10567, 13480, 17137, 17137],
      52601: [13724, 13602, 10680, 13578, 17243, 17243],
      52801: [13830, 13710, 10793, 13676, 17349, 17349],
      53001: [13936, 13817, 10907, 13774, 17455, 17455],
      53201: [14042, 13924, 11020, 13872, 17561, 17561],
      53401: [14148, 14029, 11130, 13970, 17667, 17667],
      53601: [14254, 14136, 11244, 14068, 17773, 17773],
      53801: [14360, 14243, 11357, 14165, 17879, 17879],
      54001: [14466, 14350, 11470, 14263, 17985, 17985],
      54201: [14572, 14458, 11583, 14361, 18091, 18091],
      54401: [14678, 14565, 11697, 14459, 18197, 18197],
      54601: [14784, 14672, 11810, 14557, 18303, 18303],
      54801: [14890, 14779, 11923, 14655, 18409, 18409],
      55001: [14996, 14887, 12036, 14753, 18515, 18515],
      55201: [15102, 14991, 12147, 14850, 18621, 18621],
      55401: [15208, 15098, 12260, 14948, 18727, 18727],
      55601: [15314, 15206, 12373, 15046, 18833, 18833],
      55801: [15420, 15313, 12487, 15144, 18939, 18939],
      56001: [15526, 15420, 12600, 15242, 19045, 19045],
      56201: [15632, 15527, 12713, 15340, 19151, 19151],
      56401: [15738, 15635, 12826, 15437, 19257, 19257],
      56601: [15844, 15742, 12940, 15535, 19363, 19363],
      56801: [15950, 15846, 13050, 15633, 19469, 19469],
      57001: [16056, 15954, 13163, 15731, 19575, 19575],
      57201: [16162, 16061, 13277, 15829, 19681, 19681],
      57401: [16268, 16168, 13390, 15927, 19787, 19787],
      57601: [16374, 16275, 13503, 16025, 19893, 19893],
      57801: [16480, 16383, 13616, 16122, 19999, 19999],
      58001: [16586, 16483, 13723, 16220, 20105, 20105],
      58201: [16692, 16589, 13835, 16318, 20211, 20211],
      58401: [16798, 16695, 13947, 16416, 20317, 20317],
      58601: [16904, 16801, 14059, 16514, 20423, 20423],
      58801: [17010, 16907, 14171, 16612, 20529, 20529],
      59001: [17116, 17013, 14283, 16709, 20635, 20635],
      59201: [17222, 17119, 14395, 16807, 20741, 20741],
      59401: [17328, 17225, 14507, 16905, 20847, 20847],
      59601: [17434, 17331, 14619, 17003, 20953, 20953],
      59801: [17540, 17437, 14731, 17101, 21059, 21059],
      60001: [17646, 17543, 14843, 17199, 21165, 21165],
      60201: [17752, 17649, 14955, 17297, 21271, 21271],
      60401: [17858, 17755, 15067, 17394, 21377, 21377],
      60601: [17964, 17861, 15179, 17492, 21483, 21483],
      60801: [18070, 17967, 15291, 17590, 21589, 21589],
      61001: [18176, 18073, 15403, 17688, 21695, 21695],
      61201: [18282, 18179, 15515, 17786, 21801, 21801],
      61401: [18388, 18285, 15627, 17884, 21907, 21907],
      61601: [18494, 18391, 15739, 17982, 22013, 22013],
      61801: [18600, 18497, 15851, 18079, 22119, 22119],
      62001: [18706, 18603, 15963, 18177, 22225, 22225],
      62201: [18812, 18709, 16075, 18275, 22331, 22331],
      62401: [18918, 18815, 16187, 18373, 22437, 22437],
      62601: [19024, 18921, 16299, 18471, 22543, 22543],
      62801: [19130, 19027, 16411, 18569, 22649, 22649],
      63001: [19236, 19133, 16523, 18666, 22755, 22755],
      63201: [19342, 19239, 16635, 18764, 22861, 22861],
      63401: [19448, 19345, 16747, 18862, 22967, 22967],
      63601: [19554, 19451, 16859, 18960, 23073, 23073],
      63801: [19660, 19557, 16971, 19058, 23179, 23179],
      64001: [19766, 19663, 17083, 19156, 23285, 23285],
      64201: [19872, 19769, 17195, 19254, 23391, 23391],
      64401: [19978, 19875, 17307, 19351, 23497, 23497],
      64601: [20088, 19990, 17428, 19449, 23603, 23603],
      64801: [20200, 20101, 17544, 19547, 23709, 23709],
      65001: [20312, 20215, 17665, 19645, 23815, 23815],
      65201: [20424, 20326, 17782, 19743, 23921, 23921],
      65401: [20536, 20436, 17898, 19841, 24027, 24027],
      65601: [20648, 20551, 18019, 19939, 24133, 24133],
      65801: [20760, 20662, 18135, 20036, 24239, 24239],
      66001: [20872, 20776, 18256, 20134, 24345, 24345],
      66201: [20984, 20887, 18373, 20232, 24451, 24451],
      66401: [21096, 20997, 18489, 20330, 24557, 24557],
      66601: [21208, 21112, 18610, 20428, 24663, 24663],
      66801: [21320, 21222, 18726, 20526, 24769, 24769],
      67001: [21432, 21333, 18843, 20623, 24875, 24875],
      67201: [21544, 21448, 18964, 20721, 24981, 24981],
      67401: [21656, 21558, 19080, 20819, 25087, 25087],
      67601: [21768, 21673, 19201, 20917, 25193, 25193],
      67801: [21880, 21783, 19317, 21015, 25299, 25299],
      68001: [21992, 21894, 19434, 21113, 25405, 25405],
      68201: [22104, 22009, 19554, 21211, 25511, 25511],
      68401: [22216, 22119, 19671, 21308, 25617, 25617],
      68601: [22328, 22229, 19787, 21406, 25723, 25723],
      68801: [22440, 22344, 19908, 21504, 25829, 25829],
      69001: [22552, 22455, 20025, 21602, 25935, 25935],
      69201: [22664, 22570, 20145, 21700, 26041, 26041],
      69401: [22776, 22680, 20262, 21798, 26147, 26147],
      69601: [22888, 22790, 20378, 21895, 26253, 26253],
      69801: [23e3, 22905, 20499, 21993, 26359, 26359],
      70001: [23112, 23016, 20616, 22091, 26465, 26465],
      70201: [23224, 23126, 20732, 22189, 26571, 26571],
      70401: [23336, 23241, 20853, 22287, 26677, 26677],
      70601: [23448, 23351, 20969, 22385, 26783, 26783],
      70801: [23560, 23466, 21090, 22483, 26889, 26889],
      71001: [23672, 23577, 21206, 22580, 26995, 26995],
      71201: [23784, 23687, 21323, 22678, 27101, 27101],
      71401: [23896, 23802, 21444, 22776, 27207, 27207],
      71601: [24008, 23912, 21560, 22874, 27313, 27313],
      71801: [24120, 24027, 21681, 22972, 27419, 27419],
      72001: [24232, 24137, 21797, 23070, 27525, 27525],
      72201: [24344, 24248, 21914, 23168, 27631, 27631],
      72401: [24456, 24363, 22035, 23265, 27737, 27737],
      72601: [24568, 24473, 22151, 23363, 27843, 27843],
      72801: [24680, 24584, 22267, 23461, 27949, 27949],
      73001: [24792, 24698, 22388, 23559, 28055, 28055],
      73201: [24904, 24809, 22505, 23657, 28161, 28161],
      73401: [25016, 24924, 22625, 23755, 28267, 28267],
      73601: [25128, 25034, 22742, 23852, 28373, 28373],
      73801: [25240, 25144, 22858, 23950, 28479, 28479],
      74001: [25352, 25259, 22979, 24048, 28585, 28585],
      74201: [25464, 25370, 23096, 24146, 28691, 28691],
      74401: [25576, 25480, 23212, 24244, 28797, 28797],
      74601: [25688, 25595, 23333, 24342, 28903, 28903],
      74801: [25800, 25705, 23449, 24440, 29009, 29009],
      75001: [25912, 25820, 23570, 24537, 29115, 29115],
      75201: [26024, 25931, 23686, 24635, 29221, 29221],
      75401: [26136, 26041, 23803, 24733, 29327, 29327],
      75601: [26248, 26156, 23924, 24831, 29433, 29433],
      75801: [26360, 26266, 24040, 24929, 29539, 29539],
      76001: [26472, 26377, 24157, 25027, 29645, 29645],
      76201: [26584, 26492, 24277, 25125, 29751, 29751],
      76401: [26696, 26602, 24394, 25222, 29857, 29857],
      76601: [26808, 26717, 24515, 25320, 29963, 29963],
      76801: [26920, 26827, 24631, 25418, 30069, 30069],
      77001: [27032, 26938, 24747, 25516, 30175, 30175],
      77201: [27144, 27052, 24868, 25614, 30281, 30281],
      77401: [27256, 27163, 24985, 25712, 30387, 30387],
      77601: [27368, 27273, 25101, 25809, 30493, 30493],
      77801: [27480, 27388, 25222, 25907, 30599, 30599],
      78001: [27592, 27499, 25338, 26005, 30705, 30705],
      78201: [27704, 27613, 25459, 26103, 30811, 30811],
      78401: [27816, 27724, 25576, 26201, 30917, 30917],
      78601: [27928, 27834, 25692, 26299, 31023, 31023],
      78801: [28040, 27949, 25813, 26397, 31129, 31129],
      79001: [28152, 28059, 25929, 26494, 31235, 31235],
      79201: [28264, 28170, 26046, 26592, 31341, 31341],
      79401: [28376, 28285, 26167, 26690, 31447, 31447],
      79601: [28488, 28395, 26283, 26788, 31553, 31553],
      79801: [28600, 28510, 26404, 26886, 31659, 31659],
      80001: [36, 36, 33, 34, 40, 40, "%"],
      81401: [36, 36, 34, 34, 40, 40, "%"],
      83001: [37, 36, 34, 34, 40, 40, "%"],
      83401: [37, 37, 34, 34, 40, 40, "%"],
      84801: [37, 37, 35, 35, 40, 40, "%"],
      85801: [37, 37, 35, 35, 41, 41, "%"],
      87401: [38, 37, 35, 35, 41, 41, "%"],
      88001: [38, 38, 35, 35, 41, 41, "%"],
      88401: [38, 38, 36, 35, 41, 41, "%"],
      91201: [38, 38, 36, 36, 41, 41, "%"],
      92401: [39, 38, 37, 36, 41, 41, "%"],
      93001: [39, 39, 37, 36, 41, 41, "%"],
      93201: [39, 39, 37, 36, 42, 42, "%"],
      96601: [39, 39, 38, 36, 42, 42, "%"],
      98001: [40, 39, 38, 36, 42, 42, "%"],
      98601: [40, 40, 38, 37, 42, 42, "%"],
      101401: [40, 40, 39, 37, 42, 42, "%"],
      102201: [40, 40, 39, 37, 43, 43, "%"],
      104401: [41, 40, 39, 37, 43, 43, "%"],
      105001: [41, 41, 39, 37, 43, 43, "%"],
      106401: [41, 41, 40, 37, 43, 43, "%"],
      107201: [41, 41, 40, 38, 43, 43, "%"],
      111601: [42, 41, 40, 38, 43, 43, "%"],
      112201: [42, 42, 41, 38, 43, 43, "%"],
      113001: [42, 42, 41, 38, 44, 44, "%"],
      117401: [42, 42, 41, 39, 44, 44, "%"],
      118601: [42, 42, 42, 39, 44, 44, "%"],
      119801: [43, 42, 42, 39, 44, 44, "%"],
      120401: [43, 43, 42, 39, 44, 44, "%"],
      125801: [43, 43, 43, 39, 44, 44, "%"],
      126201: [43, 43, 43, 39, 45, 45, "%"],
      129401: [44, 43, 43, 39, 45, 45, "%"],
      130001: [44, 44, 43, 40, 45, 45, "%"],
      134001: [44, 44, 44, 40, 45, 45, "%"],
      140801: [45, 44, 44, 40, 45, 45, "%"],
      141401: [45, 45, 44, 40, 45, 45, "%"],
      143201: [45, 45, 45, 40, 46, 46, "%"],
      145401: [45, 45, 45, 41, 46, 46, "%"],
      154201: [46, 45, 45, 41, 46, 46, "%"],
      154801: [46, 46, 46, 41, 46, 46, "%"],
      165001: [46, 46, 46, 42, 46, 46, "%"],
      165201: [46, 46, 46, 42, 47, 47, "%"],
      170401: [47, 46, 46, 42, 47, 47, "%"],
      171001: [47, 47, 47, 42, 47, 47, "%"],
      190601: [47, 47, 47, 43, 47, 47, "%"],
      195201: [48, 48, 48, 43, 48, 48, "%"],
      225801: [48, 48, 48, 44, 48, 48, "%"],
      238601: [49, 49, 49, 44, 49, 49, "%"],
      276801: [49, 49, 49, 45, 49, 49, "%"],
      306801: [50, 50, 50, 45, 50, 50, "%"],
      357801: [50, 50, 50, 46, 50, 50, "%"],
      429601: [51, 51, 51, 46, 51, 51, "%"],
      505201: [51, 51, 51, 47, 51, 51, "%"],
      716001: [52, 52, 52, 47, 52, 52, "%"],
      860001: [52, 52, 52, 48, 52, 52, "%"]
    },
    34: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 26],
      2101: [153, 0, 150, 3, 153, 61],
      2201: [164, 0, 158, 5, 164, 96],
      2301: [174, 0, 166, 8, 174, 131],
      2401: [185, 0, 175, 10, 185, 166],
      2501: [195, 0, 183, 12, 201, 201],
      2601: [206, 0, 191, 14, 236, 236],
      2701: [216, 0, 200, 16, 271, 271],
      2801: [218, 0, 200, 18, 306, 306],
      2901: [229, 0, 208, 21, 341, 341],
      3001: [239, 0, 216, 23, 376, 376],
      3101: [250, 0, 225, 25, 411, 411],
      3201: [260, 0, 233, 27, 446, 446],
      3301: [271, 0, 241, 29, 481, 481],
      3401: [273, 0, 241, 31, 516, 516],
      3501: [284, 0, 250, 34, 551, 551],
      3601: [294, 0, 258, 36, 586, 586],
      3701: [305, 0, 266, 38, 621, 621],
      3801: [315, 0, 275, 40, 656, 656],
      3901: [325, 0, 283, 42, 691, 691],
      4001: [328, 0, 283, 44, 726, 726],
      4101: [338, 0, 291, 46, 761, 761],
      4201: [349, 0, 300, 49, 796, 796],
      4301: [359, 0, 308, 63, 831, 831],
      4401: [370, 0, 316, 86, 866, 866],
      4501: [380, 0, 325, 110, 901, 901],
      4601: [382, 0, 325, 134, 936, 936],
      4701: [392, 0, 333, 158, 965, 965],
      4801: [403, 0, 341, 181, 994, 994],
      4901: [413, 0, 350, 204, 1023, 1023],
      5001: [423, 0, 358, 228, 1049, 1049],
      5101: [433, 0, 366, 251, 1079, 1079],
      5201: [443, 0, 375, 274, 1105, 1105],
      5301: [445, 23, 376, 298, 1134, 1134],
      5401: [455, 49, 386, 321, 1163, 1163],
      5501: [465, 75, 396, 344, 1189, 1189],
      5601: [475, 102, 406, 367, 1218, 1218],
      5701: [485, 128, 416, 390, 1243, 1243],
      5801: [495, 154, 426, 413, 1272, 1272],
      5901: [497, 180, 427, 435, 1300, 1300],
      6001: [507, 207, 437, 458, 1326, 1326],
      6101: [517, 233, 447, 481, 1355, 1355],
      6201: [527, 256, 457, 504, 1380, 1380],
      6301: [537, 282, 467, 527, 1409, 1409],
      6401: [547, 309, 477, 549, 1437, 1437],
      6501: [549, 335, 479, 572, 1463, 1463],
      6601: [559, 361, 488, 595, 1492, 1492],
      6701: [580, 387, 498, 618, 1517, 1517],
      6801: [601, 414, 508, 640, 1546, 1546],
      6901: [622, 440, 518, 663, 1574, 1574],
      7001: [644, 466, 528, 686, 1600, 1600],
      7101: [665, 492, 530, 709, 1629, 1629],
      7201: [686, 519, 540, 732, 1654, 1654],
      7301: [707, 545, 550, 754, 1683, 1683],
      7401: [729, 568, 560, 777, 1711, 1711],
      7501: [750, 594, 570, 800, 1737, 1737],
      7601: [771, 621, 580, 823, 1766, 1766],
      7701: [792, 647, 589, 845, 1791, 1791],
      7801: [814, 673, 591, 868, 1820, 1820],
      7901: [835, 699, 601, 891, 1848, 1848],
      8001: [856, 726, 611, 914, 1874, 1874],
      8101: [877, 752, 621, 937, 1903, 1903],
      8201: [899, 778, 631, 959, 1928, 1928],
      8301: [920, 804, 641, 982, 1957, 1957],
      8401: [941, 831, 642, 1005, 1985, 1985],
      8501: [962, 857, 652, 1028, 2011, 2011],
      8601: [984, 880, 662, 1051, 2040, 2040],
      8701: [1005, 907, 672, 1073, 2065, 2065],
      8801: [1026, 933, 682, 1096, 2094, 2094],
      8901: [1047, 959, 692, 1119, 2122, 2122],
      9001: [1068, 985, 694, 1142, 2148, 2148],
      9101: [1090, 1012, 704, 1165, 2177, 2177],
      9201: [1111, 1038, 714, 1187, 2202, 2202],
      9301: [1132, 1055, 723, 1210, 2231, 2231],
      9401: [1154, 1079, 733, 1233, 2259, 2259],
      9501: [1175, 1099, 742, 1256, 2285, 2285],
      9601: [1196, 1119, 744, 1279, 2314, 2314],
      9701: [1217, 1140, 753, 1301, 2339, 2339],
      9801: [1239, 1163, 763, 1324, 2368, 2368],
      9901: [1260, 1183, 773, 1347, 2396, 2396],
      10001: [1281, 1203, 782, 1370, 2422, 2422],
      10101: [1302, 1223, 792, 1393, 2451, 2451],
      10201: [1323, 1246, 802, 1415, 2476, 2476],
      10301: [1345, 1266, 803, 1438, 2505, 2505],
      10401: [1366, 1286, 812, 1461, 2533, 2533],
      10501: [1387, 1306, 822, 1483, 2559, 2559],
      10601: [1408, 1329, 832, 1506, 2588, 2588],
      10701: [1430, 1349, 841, 1529, 2613, 2613],
      10801: [1451, 1369, 851, 1552, 2642, 2642],
      10901: [1472, 1389, 852, 1575, 2670, 2670],
      11001: [1493, 1412, 862, 1597, 2696, 2696],
      11101: [1515, 1432, 872, 1620, 2725, 2725],
      11201: [1536, 1452, 881, 1643, 2750, 2750],
      11301: [1557, 1474, 891, 1666, 2779, 2779],
      11401: [1579, 1494, 901, 1689, 2807, 2807],
      11501: [1600, 1514, 902, 1711, 2833, 2833],
      11601: [1621, 1534, 911, 1734, 2862, 2862],
      11701: [1642, 1557, 921, 1757, 2887, 2887],
      11801: [1663, 1577, 931, 1780, 2916, 2916],
      11901: [1685, 1597, 940, 1803, 2944, 2944],
      12001: [1706, 1617, 950, 1825, 2970, 2970],
      12101: [1727, 1640, 951, 1848, 2999, 2999],
      12201: [1748, 1660, 961, 1871, 3024, 3024],
      12301: [1770, 1680, 971, 1894, 3053, 3053],
      12401: [1791, 1700, 980, 1917, 3081, 3081],
      12501: [1812, 1723, 990, 1939, 3107, 3107],
      12601: [1833, 1743, 1e3, 1962, 3136, 3136],
      12701: [1855, 1763, 1009, 1985, 3161, 3161],
      12801: [1876, 1783, 1010, 2008, 3190, 3190],
      12901: [1897, 1805, 1020, 2030, 3215, 3215],
      13001: [1919, 1825, 1030, 2053, 3250, 3250],
      13101: [1940, 1845, 1039, 2076, 3284, 3284],
      13201: [1962, 1868, 1049, 2100, 3318, 3318],
      13301: [1983, 1888, 1059, 2123, 3352, 3352],
      13401: [2005, 1908, 1060, 2146, 3387, 3387],
      13501: [2026, 1928, 1070, 2169, 3421, 3421],
      13601: [2048, 1951, 1079, 2192, 3455, 3455],
      13701: [2069, 1971, 1089, 2215, 3489, 3489],
      13801: [2090, 1991, 1099, 2237, 3523, 3523],
      13901: [2111, 2011, 1108, 2259, 3556, 3556],
      14001: [2131, 2034, 1110, 2282, 3590, 3590],
      14101: [2152, 2054, 1119, 2304, 3623, 3623],
      14201: [2172, 2074, 1129, 2326, 3656, 3656],
      14301: [2193, 2094, 1138, 2348, 3689, 3689],
      14401: [2213, 2117, 1148, 2370, 3723, 3723],
      14501: [2234, 2137, 1158, 2392, 3756, 3756],
      14601: [2254, 2157, 1159, 2414, 3789, 3789],
      14701: [2275, 2177, 1169, 2436, 3822, 3822],
      14801: [2296, 2199, 1178, 2458, 3858, 3858],
      14901: [2316, 2219, 1188, 2480, 3894, 3894],
      15001: [2337, 2239, 1198, 2502, 3930, 3930],
      15101: [2357, 2259, 1207, 2524, 3966, 3966],
      15201: [2378, 2282, 1217, 2547, 4005, 4005],
      15301: [2398, 2302, 1218, 2569, 4041, 4041],
      15401: [2420, 2325, 1228, 2593, 4077, 4077],
      15501: [2448, 2353, 1238, 2622, 4113, 4113],
      15601: [2476, 2382, 1248, 2651, 4149, 4149],
      15701: [2504, 2411, 1258, 2681, 4188, 4188],
      15801: [2532, 2439, 1268, 2710, 4224, 4224],
      15901: [2560, 2468, 1270, 2739, 4260, 4260],
      16001: [2588, 2496, 1280, 2768, 4296, 4296],
      16101: [2616, 2525, 1291, 2797, 4332, 4332],
      16201: [2644, 2553, 1301, 2826, 4371, 4371],
      16301: [2672, 2582, 1311, 2855, 4407, 4407],
      16401: [2699, 2610, 1321, 2884, 4443, 4443],
      16501: [2727, 2639, 1323, 2913, 4479, 4479],
      16601: [2755, 2667, 1333, 2942, 4515, 4515],
      16701: [2783, 2696, 1343, 2971, 4554, 4554],
      16801: [2811, 2725, 1353, 3001, 4590, 4590],
      16901: [2839, 2753, 1363, 3030, 4626, 4626],
      17001: [2867, 2779, 1373, 3059, 4662, 4662],
      17101: [2895, 2807, 1375, 3088, 4698, 4698],
      17201: [2923, 2836, 1385, 3117, 4737, 4737],
      17301: [2951, 2864, 1395, 3146, 4773, 4773],
      17401: [2978, 2893, 1405, 3175, 4809, 4809],
      17501: [3006, 2921, 1416, 3204, 4845, 4845],
      17601: [3034, 2950, 1426, 3233, 4881, 4881],
      17701: [3062, 2979, 1436, 3262, 4920, 4920],
      17801: [3090, 3007, 1438, 3291, 4956, 4956],
      17901: [3118, 3036, 1448, 3320, 4992, 4992],
      18001: [3146, 3064, 1458, 3350, 5028, 5028],
      18101: [3174, 3093, 1468, 3379, 5064, 5064],
      18201: [3202, 3121, 1478, 3408, 5102, 5102],
      18301: [3229, 3150, 1488, 3437, 5139, 5139],
      18401: [3257, 3178, 1490, 3466, 5175, 5175],
      18501: [3285, 3207, 1500, 3495, 5211, 5211],
      18601: [3313, 3236, 1510, 3524, 5247, 5247],
      18701: [3341, 3264, 1520, 3553, 5285, 5285],
      18801: [3369, 3293, 1531, 3582, 5321, 5321],
      18901: [3397, 3321, 1541, 3611, 5357, 5357],
      19001: [3425, 3350, 1543, 3640, 5393, 5393],
      19101: [3453, 3378, 1553, 3670, 5429, 5429],
      19201: [3480, 3407, 1563, 3699, 5468, 5468],
      19301: [3508, 3435, 1573, 3728, 5504, 5504],
      19401: [3536, 3464, 1583, 3757, 5540, 5540],
      19501: [3564, 3492, 1593, 3786, 5576, 5576],
      19601: [3592, 3520, 1595, 3815, 5612, 5612],
      19701: [3620, 3548, 1604, 3844, 5651, 5651],
      19801: [3648, 3576, 1613, 3873, 5687, 5687],
      19901: [3676, 3603, 1622, 3902, 5723, 5723],
      20001: [3732, 3659, 1641, 3960, 5795, 5795],
      20201: [3787, 3712, 1651, 4019, 5870, 5870],
      20401: [3843, 3767, 1670, 4077, 5942, 5942],
      20601: [3899, 3822, 1688, 4135, 6017, 6017],
      20801: [3955, 3878, 1699, 4193, 6089, 6089],
      21001: [4011, 3933, 1717, 4251, 6161, 6161],
      21201: [4066, 3989, 1736, 4310, 6236, 6236],
      21401: [4122, 4044, 1746, 4368, 6308, 6308],
      21601: [4178, 4099, 1765, 4426, 6383, 6383],
      21801: [4234, 4155, 1783, 4484, 6455, 6455],
      22001: [4289, 4210, 1794, 4542, 6527, 6527],
      22201: [4345, 4266, 1812, 4600, 6602, 6602],
      22401: [4401, 4321, 1831, 4659, 6674, 6674],
      22601: [4457, 4377, 1849, 4717, 6748, 6748],
      22801: [4514, 4432, 1860, 4776, 6821, 6821],
      23001: [4571, 4487, 1878, 4836, 6895, 6895],
      23201: [4629, 4543, 1897, 4896, 6972, 6972],
      23401: [4686, 4595, 1907, 4955, 7045, 7045],
      23601: [4743, 4651, 1926, 5015, 7122, 7122],
      23801: [4801, 4703, 1944, 5075, 7195, 7195],
      24001: [4858, 4762, 1954, 5135, 7269, 7269],
      24201: [4916, 4817, 1973, 5195, 7346, 7346],
      24401: [4973, 4875, 1992, 5254, 7419, 7419],
      24601: [5031, 4931, 2002, 5314, 7496, 7496],
      24801: [5088, 4986, 2021, 5374, 7569, 7569],
      25001: [5145, 5044, 2040, 5434, 7643, 7643],
      25201: [5203, 5100, 2095, 5494, 7720, 7720],
      25401: [5260, 5155, 2150, 5554, 7793, 7793],
      25601: [5318, 5213, 2208, 5613, 7870, 7870],
      25801: [5375, 5269, 2264, 5673, 7943, 7943],
      26001: [5433, 5324, 2319, 5733, 8017, 8017],
      26201: [5490, 5382, 2378, 5793, 8094, 8094],
      26401: [5548, 5438, 2433, 5853, 8167, 8167],
      26601: [5605, 5496, 2491, 5913, 8244, 8244],
      26801: [5662, 5551, 2547, 5972, 8317, 8317],
      27001: [5720, 5607, 2602, 6032, 8391, 8391],
      27201: [5777, 5665, 2660, 6092, 8468, 8468],
      27401: [5835, 5720, 2716, 6152, 8541, 8541],
      27601: [5892, 5776, 2771, 6212, 8618, 8618],
      27801: [5950, 5834, 2829, 6271, 8691, 8691],
      28001: [6007, 5889, 2885, 6331, 8765, 8765],
      28201: [6065, 5948, 2943, 6391, 8842, 8842],
      28401: [6122, 6003, 2998, 6451, 8915, 8915],
      28601: [6179, 6058, 3054, 6511, 8992, 8992],
      28801: [6237, 6117, 3112, 6571, 9065, 9065],
      29001: [6294, 6172, 3167, 6630, 9139, 9139],
      29201: [6352, 6227, 3223, 6690, 9216, 9216],
      29401: [6409, 6286, 3281, 6750, 9289, 9289],
      29601: [6467, 6341, 3336, 6810, 9366, 9366],
      29801: [6524, 6399, 3394, 6870, 9439, 9439],
      30001: [6581, 6455, 3450, 6930, 9513, 9513],
      30201: [6639, 6510, 3505, 6989, 9590, 9590],
      30401: [6696, 6568, 3564, 7049, 9663, 9663],
      30601: [6754, 6624, 3619, 7109, 9740, 9740],
      30801: [6811, 6679, 3674, 7169, 9813, 9813],
      31001: [6869, 6737, 3732, 7229, 9887, 9887],
      31201: [6926, 6793, 3788, 7289, 9964, 9964],
      31401: [6984, 6850, 3845, 7348, 10037, 10037],
      31601: [7041, 6909, 3904, 7408, 10114, 10114],
      31801: [7098, 6966, 3961, 7468, 10187, 10187],
      32001: [7156, 7025, 4021, 7528, 10261, 10261],
      32201: [7213, 7082, 4077, 7588, 10338, 10338],
      32401: [7271, 7139, 4134, 7647, 10411, 10411],
      32601: [7328, 7198, 4193, 7707, 10488, 10488],
      32801: [7386, 7255, 4250, 7767, 10561, 10561],
      33001: [7443, 7312, 4307, 7827, 10635, 10635],
      33201: [7501, 7371, 4366, 7887, 10712, 10712],
      33401: [7558, 7428, 4423, 7947, 10785, 10785],
      33601: [7615, 7487, 4482, 8006, 10862, 10862],
      33801: [7673, 7544, 4539, 8066, 10935, 10935],
      34001: [7730, 7601, 4596, 8126, 11009, 11009],
      34201: [7788, 7660, 4655, 8186, 11086, 11086],
      34401: [7845, 7717, 4712, 8246, 11159, 11159],
      34601: [7903, 7773, 4769, 8306, 11236, 11236],
      34801: [7960, 7833, 4828, 8365, 11309, 11309],
      35001: [8017, 7890, 4885, 8425, 11383, 11383],
      35201: [8075, 7946, 4941, 8485, 11460, 11460],
      35401: [8132, 8006, 5001, 8545, 11533, 11533],
      35601: [8190, 8062, 5058, 8605, 11610, 11610],
      35801: [8247, 8122, 5117, 8664, 11683, 11683],
      36001: [8305, 8179, 5174, 8724, 11757, 11757],
      36201: [8362, 8235, 5230, 8784, 11834, 11834],
      36401: [8420, 8295, 5290, 8844, 11907, 11907],
      36601: [8477, 8351, 5347, 8904, 11984, 11984],
      36801: [8534, 8408, 5403, 8964, 12057, 12057],
      37001: [8592, 8468, 5463, 9023, 12131, 12131],
      37201: [8649, 8524, 5519, 9083, 12208, 12208],
      37401: [8707, 8584, 5579, 9143, 12281, 12281],
      37601: [8764, 8638, 5633, 9203, 12352, 12352],
      37801: [8821, 8694, 5689, 9262, 12420, 12420],
      38001: [8878, 8751, 5746, 9322, 12488, 12488],
      38201: [8936, 8810, 5806, 9382, 12556, 12556],
      38401: [8994, 8867, 5862, 9441, 12624, 12624],
      38601: [9062, 8935, 5930, 9501, 12692, 12692],
      38801: [9130, 9003, 5998, 9560, 12760, 12760],
      39001: [9198, 9071, 6066, 9620, 12828, 12828],
      39201: [9266, 9139, 6134, 9679, 12896, 12896],
      39401: [9334, 9207, 6202, 9739, 12964, 12964],
      39601: [9402, 9275, 6270, 9799, 13032, 13032],
      39801: [9470, 9343, 6338, 9858, 13100, 13100],
      40001: [9538, 9411, 6406, 9918, 13168, 13168],
      40201: [9606, 9479, 6474, 9977, 13236, 13236],
      40401: [9674, 9547, 6542, 10037, 13304, 13304],
      40601: [9742, 9615, 6610, 10097, 13372, 13372],
      40801: [9810, 9683, 6678, 10156, 13440, 13440],
      41001: [9878, 9751, 6746, 10216, 13508, 13508],
      41201: [9946, 9819, 6814, 10276, 13576, 13576],
      41401: [10014, 9887, 6882, 10335, 13644, 13644],
      41601: [10082, 9955, 6950, 10395, 13712, 13712],
      41801: [10150, 10023, 7018, 10454, 13780, 13780],
      42001: [10218, 10091, 7086, 10514, 13848, 13848],
      42201: [10286, 10159, 7154, 10573, 13916, 13916],
      42401: [10354, 10227, 7222, 10633, 13984, 13984],
      42601: [10422, 10295, 7290, 10693, 14052, 14052],
      42801: [10490, 10363, 7358, 10752, 14120, 14120],
      43001: [10558, 10431, 7426, 10812, 14188, 14188],
      43201: [10626, 10499, 7494, 10871, 14256, 14256],
      43401: [10694, 10567, 7562, 10931, 14324, 14324],
      43601: [10762, 10635, 7630, 10991, 14392, 14392],
      43801: [10830, 10703, 7698, 11050, 14460, 14460],
      44001: [10898, 10771, 7766, 11110, 14528, 14528],
      44201: [10966, 10839, 7834, 11169, 14596, 14596],
      44401: [11034, 10907, 7902, 11229, 14664, 14664],
      44601: [11102, 10975, 7970, 11289, 14732, 14732],
      44801: [11170, 11043, 8038, 11348, 14800, 14800],
      45001: [11238, 11111, 8106, 11408, 14868, 14868],
      45201: [11306, 11179, 8174, 11467, 14936, 14936],
      45401: [11374, 11247, 8242, 11527, 15004, 15004],
      45601: [11442, 11315, 8310, 11586, 15072, 15072],
      45801: [11510, 11383, 8378, 11646, 15140, 15140],
      46001: [11578, 11451, 8446, 11706, 15208, 15208],
      46201: [11646, 11519, 8514, 11765, 15276, 15276],
      46401: [11714, 11587, 8582, 11825, 15344, 15344],
      46601: [11782, 11655, 8650, 11884, 15412, 15412],
      46801: [11850, 11723, 8718, 11944, 15480, 15480],
      47001: [11918, 11791, 8786, 12004, 15548, 15548],
      47201: [11986, 11859, 8854, 12063, 15616, 15616],
      47401: [12054, 11927, 8922, 12123, 15684, 15684],
      47601: [12122, 11995, 8990, 12182, 15752, 15752],
      47801: [12190, 12063, 9058, 12242, 15820, 15820],
      48001: [12258, 12131, 9126, 12302, 15888, 15888],
      48201: [12326, 12199, 9194, 12361, 15956, 15956],
      48401: [12394, 12267, 9262, 12421, 16024, 16024],
      48601: [12462, 12335, 9330, 12480, 16092, 16092],
      48801: [12530, 12403, 9398, 12540, 16160, 16160],
      49001: [12598, 12471, 9466, 12600, 16228, 16228],
      49201: [12666, 12539, 9534, 12659, 16296, 16296],
      49401: [12734, 12607, 9602, 12719, 16364, 16364],
      49601: [12802, 12675, 9670, 12778, 16432, 16432],
      49801: [12870, 12743, 9738, 12838, 16500, 16500],
      50001: [12938, 12811, 9811, 12898, 16568, 16568],
      50201: [13006, 12879, 9885, 12957, 16636, 16636],
      50401: [13074, 12947, 9959, 13017, 16704, 16704],
      50601: [13142, 13015, 10033, 13076, 16772, 16772],
      50801: [13210, 13083, 10107, 13136, 16840, 16840],
      51001: [13278, 13151, 10181, 13195, 16908, 16908],
      51201: [13371, 13245, 10280, 13280, 17001, 17001],
      51401: [13479, 13355, 10397, 13380, 17109, 17109],
      51601: [13587, 13466, 10513, 13479, 17217, 17217],
      51801: [13695, 13573, 10627, 13579, 17325, 17325],
      52001: [13803, 13684, 10744, 13678, 17433, 17433],
      52201: [13911, 13794, 10860, 13778, 17541, 17541],
      52401: [14019, 13905, 10977, 13878, 17649, 17649],
      52601: [14127, 14015, 11093, 13977, 17757, 17757],
      52801: [14235, 14126, 11210, 14077, 17865, 17865],
      53001: [14343, 14236, 11326, 14176, 17973, 17973],
      53201: [14451, 14347, 11443, 14276, 18081, 18081],
      53401: [14559, 14454, 11556, 14376, 18189, 18189],
      53601: [14667, 14565, 11673, 14475, 18297, 18297],
      53801: [14775, 14675, 11789, 14575, 18405, 18405],
      54001: [14883, 14786, 11906, 14674, 18513, 18513],
      54201: [14991, 14896, 12022, 14774, 18621, 18621],
      54401: [15099, 15007, 12139, 14874, 18729, 18729],
      54601: [15207, 15117, 12255, 14973, 18837, 18837],
      54801: [15315, 15228, 12372, 15073, 18945, 18945],
      55001: [15423, 15338, 12488, 15172, 19053, 19053],
      55201: [15531, 15446, 12602, 15272, 19161, 19161],
      55401: [15639, 15557, 12718, 15372, 19269, 19269],
      55601: [15747, 15667, 12835, 15471, 19377, 19377],
      55801: [15855, 15778, 12951, 15571, 19485, 19485],
      56001: [15963, 15888, 13068, 15670, 19593, 19593],
      56201: [16071, 15999, 13184, 15770, 19701, 19701],
      56401: [16179, 16109, 13301, 15869, 19809, 19809],
      56601: [16287, 16220, 13417, 15969, 19917, 19917],
      56801: [16395, 16327, 13531, 16069, 20025, 20025],
      57001: [16503, 16438, 13648, 16168, 20133, 20133],
      57201: [16611, 16548, 13764, 16268, 20241, 20241],
      57401: [16719, 16659, 13881, 16367, 20349, 20349],
      57601: [16827, 16769, 13997, 16467, 20457, 20457],
      57801: [16935, 16880, 14114, 16567, 20565, 20565],
      58001: [17043, 16983, 14223, 16666, 20673, 20673],
      58201: [17151, 17091, 14337, 16766, 20781, 20781],
      58401: [17259, 17199, 14451, 16865, 20889, 20889],
      58601: [17367, 17307, 14565, 16965, 20997, 20997],
      58801: [17475, 17415, 14679, 17065, 21105, 21105],
      59001: [17583, 17523, 14793, 17164, 21213, 21213],
      59201: [17691, 17631, 14907, 17264, 21321, 21321],
      59401: [17799, 17739, 15021, 17363, 21429, 21429],
      59601: [17907, 17847, 15135, 17463, 21537, 21537],
      59801: [18015, 17955, 15249, 17563, 21645, 21645],
      60001: [18123, 18063, 15363, 17662, 21753, 21753],
      60201: [18231, 18171, 15477, 17762, 21861, 21861],
      60401: [18339, 18279, 15591, 17861, 21969, 21969],
      60601: [18447, 18387, 15705, 17961, 22077, 22077],
      60801: [18555, 18495, 15819, 18061, 22185, 22185],
      61001: [18663, 18603, 15933, 18160, 22293, 22293],
      61201: [18771, 18711, 16047, 18260, 22401, 22401],
      61401: [18879, 18819, 16161, 18359, 22509, 22509],
      61601: [18987, 18927, 16275, 18459, 22617, 22617],
      61801: [19095, 19035, 16389, 18558, 22725, 22725],
      62001: [19203, 19143, 16503, 18658, 22833, 22833],
      62201: [19311, 19251, 16617, 18758, 22941, 22941],
      62401: [19419, 19359, 16731, 18857, 23049, 23049],
      62601: [19527, 19467, 16845, 18957, 23157, 23157],
      62801: [19635, 19575, 16959, 19056, 23265, 23265],
      63001: [19743, 19683, 17073, 19156, 23373, 23373],
      63201: [19851, 19791, 17187, 19256, 23481, 23481],
      63401: [19959, 19899, 17301, 19355, 23589, 23589],
      63601: [20067, 20007, 17415, 19455, 23697, 23697],
      63801: [20175, 20115, 17529, 19554, 23805, 23805],
      64001: [20283, 20223, 17643, 19654, 23913, 23913],
      64201: [20391, 20331, 17757, 19754, 24021, 24021],
      64401: [20499, 20439, 17871, 19853, 24129, 24129],
      64601: [20612, 20556, 17994, 19953, 24237, 24237],
      64801: [20726, 20669, 18112, 20052, 24345, 24345],
      65001: [20840, 20786, 18235, 20152, 24453, 24453],
      65201: [20954, 20898, 18354, 20252, 24561, 24561],
      65401: [21068, 21011, 18472, 20351, 24669, 24669],
      65601: [21182, 21128, 18595, 20451, 24777, 24777],
      65801: [21296, 21240, 18714, 20550, 24885, 24885],
      66001: [21410, 21357, 18837, 20650, 24993, 24993],
      66201: [21524, 21470, 18955, 20750, 25101, 25101],
      66401: [21638, 21582, 19074, 20849, 25209, 25209],
      66601: [21752, 21699, 19197, 20949, 25317, 25317],
      66801: [21866, 21812, 19315, 21048, 25425, 25425],
      67001: [21980, 21924, 19434, 21148, 25533, 25533],
      67201: [22094, 22041, 19557, 21248, 25641, 25641],
      67401: [22208, 22154, 19675, 21347, 25749, 25749],
      67601: [22322, 22271, 19798, 21447, 25857, 25857],
      67801: [22436, 22383, 19917, 21546, 25965, 25965],
      68001: [22550, 22496, 20035, 21646, 26073, 26073],
      68201: [22664, 22613, 20158, 21745, 26181, 26181],
      68401: [22778, 22725, 20277, 21845, 26289, 26289],
      68601: [22892, 22838, 20395, 21945, 26397, 26397],
      68801: [23006, 22955, 20518, 22044, 26505, 26505],
      69001: [23120, 23067, 20637, 22144, 26613, 26613],
      69201: [23234, 23184, 20760, 22243, 26721, 26721],
      69401: [23348, 23297, 20878, 22343, 26829, 26829],
      69601: [23462, 23409, 20997, 22443, 26937, 26937],
      69801: [23576, 23526, 21120, 22542, 27045, 27045],
      70001: [23690, 23639, 21238, 22642, 27153, 27153],
      70201: [23804, 23751, 21357, 22741, 27261, 27261],
      70401: [23918, 23868, 21480, 22841, 27369, 27369],
      70601: [24032, 23981, 21598, 22941, 27477, 27477],
      70801: [24146, 24098, 21721, 23040, 27585, 27585],
      71001: [24260, 24210, 21840, 23140, 27693, 27693],
      71201: [24374, 24323, 21958, 23239, 27801, 27801],
      71401: [24488, 24440, 22081, 23339, 27909, 27909],
      71601: [24602, 24552, 22200, 23439, 28017, 28017],
      71801: [24716, 24669, 22323, 23538, 28125, 28125],
      72001: [24830, 24782, 22441, 23638, 28233, 28233],
      72201: [24944, 24894, 22560, 23737, 28341, 28341],
      72401: [25058, 25011, 22683, 23837, 28449, 28449],
      72601: [25172, 25124, 22801, 23937, 28557, 28557],
      72801: [25286, 25236, 22920, 24036, 28665, 28665],
      73001: [25400, 25353, 23043, 24136, 28773, 28773],
      73201: [25514, 25466, 23161, 24235, 28881, 28881],
      73401: [25628, 25583, 23284, 24335, 28989, 28989],
      73601: [25742, 25695, 23403, 24434, 29097, 29097],
      73801: [25856, 25808, 23521, 24534, 29205, 29205],
      74001: [25970, 25925, 23644, 24634, 29313, 29313],
      74201: [26084, 26037, 23763, 24733, 29421, 29421],
      74401: [26198, 26150, 23881, 24833, 29529, 29529],
      74601: [26312, 26267, 24004, 24932, 29637, 29637],
      74801: [26426, 26379, 24123, 25032, 29745, 29745],
      75001: [26540, 26496, 24246, 25132, 29853, 29853],
      75201: [26654, 26609, 24364, 25231, 29961, 29961],
      75401: [26768, 26721, 24483, 25331, 30069, 30069],
      75601: [26882, 26838, 24606, 25430, 30177, 30177],
      75801: [26996, 26951, 24724, 25530, 30285, 30285],
      76001: [27110, 27063, 24843, 25630, 30393, 30393],
      76201: [27224, 27180, 24966, 25729, 30501, 30501],
      76401: [27338, 27293, 25084, 25829, 30609, 30609],
      76601: [27452, 27410, 25207, 25928, 30717, 30717],
      76801: [27566, 27522, 25326, 26028, 30825, 30825],
      77001: [27680, 27635, 25444, 26128, 30933, 30933],
      77201: [27794, 27752, 25567, 26227, 31041, 31041],
      77401: [27908, 27864, 25686, 26327, 31149, 31149],
      77601: [28022, 27977, 25804, 26426, 31257, 31257],
      77801: [28136, 28094, 25927, 26526, 31365, 31365],
      78001: [28250, 28206, 26046, 26626, 31473, 31473],
      78201: [28364, 28323, 26169, 26725, 31581, 31581],
      78401: [28478, 28436, 26287, 26825, 31689, 31689],
      78601: [28592, 28548, 26406, 26924, 31797, 31797],
      78801: [28706, 28665, 26529, 27024, 31905, 31905],
      79001: [28820, 28778, 26647, 27123, 32013, 32013],
      79201: [28934, 28890, 26766, 27223, 32121, 32121],
      79401: [29048, 29007, 26889, 27323, 32229, 32229],
      79601: [29162, 29120, 27007, 27422, 32337, 32337],
      79801: [29276, 29237, 27130, 27522, 32445, 32445],
      80001: [37, 37, 34, 34, 41, 41, "%"],
      80401: [37, 37, 34, 35, 41, 41, "%"],
      81801: [37, 37, 35, 35, 41, 41, "%"],
      83601: [38, 37, 35, 35, 41, 41, "%"],
      83801: [38, 38, 35, 35, 41, 41, "%"],
      85001: [38, 38, 36, 35, 41, 41, "%"],
      86001: [38, 38, 36, 36, 42, 42, "%"],
      88201: [39, 38, 36, 36, 42, 42, "%"],
      88401: [39, 39, 36, 36, 42, 42, "%"],
      88601: [39, 39, 37, 36, 42, 42, "%"],
      92601: [39, 39, 38, 37, 42, 42, "%"],
      93201: [40, 39, 38, 37, 42, 42, "%"],
      93401: [40, 40, 38, 37, 43, 43, "%"],
      96801: [40, 40, 39, 37, 43, 43, "%"],
      98801: [41, 40, 39, 37, 43, 43, "%"],
      99001: [41, 41, 39, 37, 43, 43, "%"],
      100001: [41, 41, 39, 38, 43, 43, "%"],
      101601: [41, 41, 40, 38, 43, 43, "%"],
      102401: [41, 41, 40, 38, 44, 44, "%"],
      105201: [42, 41, 40, 38, 44, 44, "%"],
      105401: [42, 42, 40, 38, 44, 44, "%"],
      106801: [42, 42, 41, 38, 44, 44, "%"],
      109001: [42, 42, 41, 39, 44, 44, "%"],
      112401: [43, 42, 41, 39, 44, 44, "%"],
      112601: [43, 43, 42, 39, 44, 44, "%"],
      113201: [43, 43, 42, 39, 45, 45, "%"],
      119001: [43, 43, 43, 39, 45, 45, "%"],
      119601: [43, 43, 43, 40, 45, 45, "%"],
      120801: [44, 44, 43, 40, 45, 45, "%"],
      126201: [44, 44, 44, 40, 45, 45, "%"],
      126401: [44, 44, 44, 40, 46, 46, "%"],
      130401: [45, 45, 44, 40, 46, 46, "%"],
      132401: [45, 45, 44, 41, 46, 46, "%"],
      134201: [45, 45, 45, 41, 46, 46, "%"],
      141601: [45, 46, 45, 41, 46, 46, "%"],
      141801: [46, 46, 45, 41, 46, 46, "%"],
      143201: [46, 46, 45, 41, 47, 47, "%"],
      143401: [46, 46, 46, 41, 47, 47, "%"],
      148401: [46, 46, 46, 42, 47, 47, "%"],
      155001: [46, 47, 47, 42, 47, 47, "%"],
      155401: [47, 47, 47, 42, 47, 47, "%"],
      165401: [47, 47, 47, 42, 48, 48, "%"],
      168601: [47, 47, 47, 43, 48, 48, "%"],
      171201: [47, 48, 48, 43, 48, 48, "%"],
      171801: [48, 48, 48, 43, 48, 48, "%"],
      195401: [49, 49, 49, 44, 49, 49, "%"],
      232401: [49, 49, 49, 45, 49, 49, "%"],
      238801: [50, 50, 50, 45, 50, 50, "%"],
      286601: [50, 50, 50, 46, 50, 50, "%"],
      307201: [51, 51, 51, 46, 51, 51, "%"],
      373401: [51, 51, 51, 47, 51, 51, "%"],
      430001: [52, 52, 52, 47, 52, 52, "%"],
      536201: [52, 52, 52, 48, 52, 52, "%"],
      716801: [53, 53, 53, 48, 53, 53, "%"],
      949801: [53, 53, 53, 49, 53, 53, "%"]
    },
    35: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 26],
      2101: [153, 0, 150, 3, 153, 62],
      2201: [164, 0, 158, 5, 164, 98],
      2301: [174, 0, 166, 8, 174, 134],
      2401: [185, 0, 175, 10, 185, 170],
      2501: [195, 0, 183, 12, 206, 206],
      2601: [206, 0, 191, 14, 242, 242],
      2701: [216, 0, 200, 16, 278, 278],
      2801: [218, 0, 200, 18, 314, 314],
      2901: [229, 0, 208, 21, 350, 350],
      3001: [239, 0, 216, 23, 386, 386],
      3101: [250, 0, 225, 25, 422, 422],
      3201: [260, 0, 233, 27, 458, 458],
      3301: [271, 0, 241, 29, 494, 494],
      3401: [273, 0, 241, 31, 530, 530],
      3501: [284, 0, 250, 34, 566, 566],
      3601: [294, 0, 258, 36, 602, 602],
      3701: [305, 0, 266, 38, 638, 638],
      3801: [315, 0, 275, 40, 675, 675],
      3901: [325, 0, 283, 42, 710, 710],
      4001: [328, 0, 283, 44, 746, 746],
      4101: [338, 0, 291, 46, 782, 782],
      4201: [349, 0, 300, 49, 818, 818],
      4301: [359, 0, 308, 63, 854, 854],
      4401: [370, 0, 316, 87, 890, 890],
      4501: [380, 0, 325, 112, 926, 926],
      4601: [382, 0, 325, 136, 962, 962],
      4701: [392, 0, 333, 161, 992, 992],
      4801: [403, 0, 341, 185, 1022, 1022],
      4901: [413, 0, 350, 209, 1052, 1052],
      5001: [423, 0, 358, 233, 1079, 1079],
      5101: [433, 0, 366, 257, 1109, 1109],
      5201: [443, 0, 375, 281, 1136, 1136],
      5301: [445, 23, 376, 305, 1166, 1166],
      5401: [455, 50, 386, 329, 1196, 1196],
      5501: [465, 77, 396, 352, 1223, 1223],
      5601: [475, 104, 406, 376, 1252, 1252],
      5701: [485, 131, 416, 399, 1279, 1279],
      5801: [495, 158, 426, 423, 1308, 1308],
      5901: [497, 185, 427, 446, 1338, 1338],
      6001: [507, 212, 437, 470, 1364, 1364],
      6101: [517, 239, 447, 493, 1393, 1393],
      6201: [527, 263, 457, 517, 1420, 1420],
      6301: [537, 290, 467, 540, 1449, 1449],
      6401: [547, 317, 477, 564, 1479, 1479],
      6501: [551, 344, 479, 587, 1505, 1505],
      6601: [573, 371, 488, 610, 1534, 1534],
      6701: [595, 398, 498, 634, 1561, 1561],
      6801: [617, 425, 508, 657, 1590, 1590],
      6901: [639, 452, 518, 681, 1620, 1620],
      7001: [660, 479, 528, 704, 1646, 1646],
      7101: [682, 506, 530, 728, 1675, 1675],
      7201: [704, 533, 540, 751, 1702, 1702],
      7301: [726, 560, 550, 775, 1731, 1731],
      7401: [748, 584, 560, 798, 1761, 1761],
      7501: [770, 611, 570, 821, 1787, 1787],
      7601: [792, 638, 580, 845, 1816, 1816],
      7701: [813, 665, 589, 868, 1843, 1843],
      7801: [835, 692, 591, 892, 1872, 1872],
      7901: [857, 719, 601, 915, 1902, 1902],
      8001: [879, 746, 611, 939, 1928, 1928],
      8101: [901, 773, 621, 962, 1957, 1957],
      8201: [923, 800, 631, 985, 1984, 1984],
      8301: [945, 827, 641, 1009, 2013, 2013],
      8401: [967, 854, 642, 1032, 2043, 2043],
      8501: [988, 881, 652, 1056, 2069, 2069],
      8601: [1010, 905, 662, 1079, 2098, 2098],
      8701: [1032, 932, 672, 1103, 2125, 2125],
      8801: [1054, 959, 682, 1126, 2154, 2154],
      8901: [1076, 986, 692, 1150, 2184, 2184],
      9001: [1098, 1013, 694, 1173, 2210, 2210],
      9101: [1120, 1040, 704, 1197, 2239, 2239],
      9201: [1141, 1067, 714, 1220, 2266, 2266],
      9301: [1163, 1085, 723, 1243, 2295, 2295],
      9401: [1185, 1109, 733, 1267, 2325, 2325],
      9501: [1207, 1130, 742, 1290, 2351, 2351],
      9601: [1229, 1151, 744, 1314, 2380, 2380],
      9701: [1251, 1172, 753, 1337, 2407, 2407],
      9801: [1273, 1196, 763, 1361, 2436, 2436],
      9901: [1294, 1217, 773, 1384, 2466, 2466],
      10001: [1316, 1238, 782, 1408, 2492, 2492],
      10101: [1338, 1258, 792, 1431, 2521, 2521],
      10201: [1360, 1282, 802, 1454, 2548, 2548],
      10301: [1382, 1302, 803, 1478, 2577, 2577],
      10401: [1404, 1323, 812, 1501, 2607, 2607],
      10501: [1425, 1343, 822, 1525, 2633, 2633],
      10601: [1447, 1367, 832, 1548, 2662, 2662],
      10701: [1469, 1387, 841, 1572, 2689, 2689],
      10801: [1491, 1408, 851, 1595, 2718, 2718],
      10901: [1513, 1429, 852, 1619, 2748, 2748],
      11001: [1535, 1452, 862, 1642, 2774, 2774],
      11101: [1557, 1473, 872, 1666, 2803, 2803],
      11201: [1578, 1493, 881, 1689, 2830, 2830],
      11301: [1600, 1517, 891, 1712, 2859, 2859],
      11401: [1622, 1537, 901, 1736, 2889, 2889],
      11501: [1644, 1558, 902, 1759, 2915, 2915],
      11601: [1666, 1578, 911, 1783, 2944, 2944],
      11701: [1688, 1602, 921, 1806, 2971, 2971],
      11801: [1710, 1622, 931, 1830, 3e3, 3e3],
      11901: [1732, 1643, 940, 1853, 3030, 3030],
      12001: [1753, 1664, 950, 1876, 3056, 3056],
      12101: [1775, 1687, 951, 1900, 3085, 3085],
      12201: [1797, 1708, 961, 1923, 3112, 3112],
      12301: [1819, 1728, 971, 1947, 3141, 3141],
      12401: [1841, 1749, 980, 1970, 3171, 3171],
      12501: [1863, 1772, 990, 1994, 3197, 3197],
      12601: [1885, 1793, 1e3, 2017, 3226, 3226],
      12701: [1906, 1813, 1009, 2041, 3253, 3253],
      12801: [1928, 1834, 1010, 2064, 3282, 3282],
      12901: [1950, 1857, 1020, 2087, 3309, 3309],
      13001: [1972, 1878, 1030, 2111, 3344, 3344],
      13101: [1994, 1899, 1039, 2135, 3379, 3379],
      13201: [2016, 1922, 1049, 2159, 3414, 3414],
      13301: [2039, 1943, 1059, 2182, 3450, 3450],
      13401: [2061, 1963, 1060, 2206, 3485, 3485],
      13501: [2083, 1984, 1070, 2230, 3520, 3520],
      13601: [2105, 2007, 1079, 2254, 3555, 3555],
      13701: [2127, 2028, 1089, 2277, 3591, 3591],
      13801: [2149, 2048, 1099, 2300, 3625, 3625],
      13901: [2170, 2069, 1108, 2323, 3660, 3660],
      14001: [2191, 2092, 1110, 2346, 3694, 3694],
      14101: [2212, 2113, 1119, 2369, 3728, 3728],
      14201: [2233, 2134, 1129, 2391, 3762, 3762],
      14301: [2254, 2154, 1138, 2414, 3797, 3797],
      14401: [2276, 2178, 1148, 2437, 3831, 3831],
      14501: [2297, 2198, 1158, 2459, 3865, 3865],
      14601: [2318, 2219, 1159, 2482, 3899, 3899],
      14701: [2339, 2239, 1169, 2505, 3934, 3934],
      14801: [2360, 2263, 1178, 2528, 3971, 3971],
      14901: [2381, 2283, 1188, 2550, 4008, 4008],
      15001: [2403, 2304, 1198, 2573, 4045, 4045],
      15101: [2424, 2325, 1207, 2596, 4082, 4082],
      15201: [2445, 2348, 1217, 2619, 4122, 4122],
      15301: [2466, 2369, 1218, 2642, 4159, 4159],
      15401: [2489, 2392, 1228, 2667, 4196, 4196],
      15501: [2518, 2421, 1238, 2697, 4233, 4233],
      15601: [2546, 2451, 1248, 2727, 4270, 4270],
      15701: [2575, 2480, 1258, 2757, 4310, 4310],
      15801: [2604, 2510, 1268, 2787, 4347, 4347],
      15901: [2632, 2539, 1270, 2817, 4384, 4384],
      16001: [2661, 2568, 1280, 2847, 4422, 4422],
      16101: [2690, 2598, 1291, 2877, 4459, 4459],
      16201: [2719, 2627, 1301, 2907, 4499, 4499],
      16301: [2747, 2656, 1311, 2937, 4536, 4536],
      16401: [2776, 2686, 1321, 2967, 4573, 4573],
      16501: [2805, 2715, 1323, 2997, 4610, 4610],
      16601: [2834, 2745, 1333, 3026, 4647, 4647],
      16701: [2862, 2774, 1343, 3056, 4687, 4687],
      16801: [2891, 2803, 1353, 3086, 4724, 4724],
      16901: [2920, 2833, 1363, 3116, 4761, 4761],
      17001: [2949, 2859, 1373, 3146, 4798, 4798],
      17101: [2977, 2889, 1375, 3176, 4836, 4836],
      17201: [3006, 2918, 1385, 3206, 4875, 4875],
      17301: [3035, 2947, 1395, 3236, 4913, 4913],
      17401: [3064, 2977, 1405, 3266, 4950, 4950],
      17501: [3092, 3006, 1416, 3296, 4987, 4987],
      17601: [3121, 3036, 1426, 3326, 5024, 5024],
      17701: [3150, 3065, 1436, 3356, 5064, 5064],
      17801: [3178, 3094, 1438, 3386, 5101, 5101],
      17901: [3207, 3124, 1448, 3416, 5138, 5138],
      18001: [3236, 3153, 1458, 3446, 5175, 5175],
      18101: [3265, 3182, 1468, 3476, 5212, 5212],
      18201: [3293, 3212, 1478, 3506, 5252, 5252],
      18301: [3322, 3241, 1488, 3536, 5289, 5289],
      18401: [3351, 3270, 1490, 3566, 5326, 5326],
      18501: [3380, 3300, 1500, 3596, 5363, 5363],
      18601: [3408, 3329, 1510, 3626, 5401, 5401],
      18701: [3437, 3359, 1520, 3656, 5441, 5441],
      18801: [3466, 3388, 1531, 3686, 5478, 5478],
      18901: [3494, 3417, 1541, 3716, 5515, 5515],
      19001: [3523, 3447, 1543, 3746, 5552, 5552],
      19101: [3552, 3476, 1553, 3775, 5589, 5589],
      19201: [3581, 3505, 1563, 3805, 5629, 5629],
      19301: [3609, 3535, 1573, 3835, 5666, 5666],
      19401: [3638, 3564, 1583, 3865, 5703, 5703],
      19501: [3667, 3594, 1593, 3895, 5740, 5740],
      19601: [3696, 3623, 1595, 3925, 5777, 5777],
      19701: [3724, 3651, 1604, 3955, 5817, 5817],
      19801: [3753, 3680, 1613, 3985, 5854, 5854],
      19901: [3782, 3708, 1622, 4015, 5891, 5891],
      20001: [3839, 3765, 1641, 4075, 5966, 5966],
      20201: [3897, 3819, 1651, 4135, 6043, 6043],
      20401: [3954, 3876, 1670, 4195, 6117, 6117],
      20601: [4012, 3934, 1688, 4255, 6194, 6194],
      20801: [4069, 3991, 1699, 4315, 6268, 6268],
      21001: [4127, 4048, 1717, 4375, 6342, 6342],
      21201: [4184, 4105, 1736, 4435, 6420, 6420],
      21401: [4241, 4162, 1746, 4494, 6494, 6494],
      21601: [4299, 4219, 1765, 4554, 6571, 6571],
      21801: [4356, 4276, 1783, 4614, 6645, 6645],
      22001: [4414, 4333, 1794, 4674, 6719, 6719],
      22201: [4471, 4390, 1812, 4734, 6796, 6796],
      22401: [4529, 4447, 1831, 4794, 6870, 6870],
      22601: [4586, 4504, 1849, 4854, 6948, 6948],
      22801: [4645, 4561, 1860, 4915, 7023, 7023],
      23001: [4704, 4619, 1878, 4976, 7099, 7099],
      23201: [4763, 4676, 1897, 5038, 7177, 7177],
      23401: [4822, 4730, 1907, 5100, 7253, 7253],
      23601: [4881, 4787, 1926, 5161, 7332, 7332],
      23801: [4940, 4841, 1944, 5223, 7408, 7408],
      24001: [4999, 4901, 1954, 5284, 7484, 7484],
      24201: [5058, 4958, 1992, 5346, 7562, 7562],
      24401: [5118, 5018, 2038, 5407, 7638, 7638],
      24601: [5177, 5075, 2081, 5469, 7717, 7717],
      24801: [5236, 5132, 2124, 5531, 7793, 7793],
      25001: [5295, 5192, 2187, 5592, 7869, 7869],
      25201: [5354, 5249, 2244, 5654, 7947, 7947],
      25401: [5413, 5306, 2302, 5715, 8023, 8023],
      25601: [5472, 5366, 2361, 5777, 8102, 8102],
      25801: [5531, 5423, 2419, 5838, 8178, 8178],
      26001: [5590, 5480, 2476, 5900, 8254, 8254],
      26201: [5650, 5540, 2536, 5962, 8332, 8332],
      26401: [5709, 5598, 2593, 6023, 8408, 8408],
      26601: [5768, 5657, 2653, 6085, 8487, 8487],
      26801: [5827, 5715, 2710, 6146, 8563, 8563],
      27001: [5886, 5772, 2767, 6208, 8639, 8639],
      27201: [5945, 5832, 2827, 6269, 8717, 8717],
      27401: [6004, 5889, 2884, 6331, 8793, 8793],
      27601: [6063, 5946, 2941, 6393, 8872, 8872],
      27801: [6122, 6006, 3001, 6454, 8948, 8948],
      28001: [6182, 6063, 3058, 6516, 9024, 9024],
      28201: [6241, 6123, 3118, 6577, 9102, 9102],
      28401: [6300, 6180, 3175, 6639, 9178, 9178],
      28601: [6359, 6237, 3232, 6700, 9257, 9257],
      28801: [6418, 6297, 3292, 6762, 9333, 9333],
      29001: [6477, 6354, 3349, 6823, 9409, 9409],
      29201: [6536, 6411, 3406, 6885, 9487, 9487],
      29401: [6595, 6471, 3466, 6947, 9563, 9563],
      29601: [6655, 6528, 3523, 7008, 9642, 9642],
      29801: [6714, 6588, 3583, 7070, 9718, 9718],
      30001: [6773, 6645, 3640, 7131, 9794, 9794],
      30201: [6832, 6702, 3697, 7193, 9872, 9872],
      30401: [6891, 6762, 3757, 7255, 9948, 9948],
      30601: [6950, 6819, 3814, 7316, 10027, 10027],
      30801: [7009, 6876, 3871, 7378, 10103, 10103],
      31001: [7068, 6936, 3931, 7439, 10179, 10179],
      31201: [7127, 6994, 3989, 7501, 10257, 10257],
      31401: [7186, 7052, 4047, 7562, 10333, 10333],
      31601: [7246, 7113, 4108, 7624, 10412, 10412],
      31801: [7305, 7171, 4167, 7686, 10488, 10488],
      32001: [7364, 7233, 4228, 7747, 10564, 10564],
      32201: [7423, 7291, 4286, 7809, 10642, 10642],
      32401: [7482, 7349, 4345, 7870, 10718, 10718],
      32601: [7541, 7411, 4406, 7932, 10797, 10797],
      32801: [7600, 7469, 4464, 7993, 10873, 10873],
      33001: [7659, 7527, 4522, 8055, 10949, 10949],
      33201: [7719, 7589, 4584, 8117, 11027, 11027],
      33401: [7778, 7647, 4642, 8178, 11103, 11103],
      33601: [7837, 7708, 4703, 8240, 11182, 11182],
      33801: [7896, 7766, 4762, 8301, 11258, 11258],
      34001: [7955, 7825, 4820, 8363, 11334, 11334],
      34201: [8014, 7886, 4881, 8424, 11412, 11412],
      34401: [8073, 7944, 4940, 8486, 11488, 11488],
      34601: [8132, 8003, 4998, 8548, 11567, 11567],
      34801: [8191, 8064, 5059, 8609, 11643, 11643],
      35001: [8250, 8122, 5117, 8671, 11719, 11719],
      35201: [8310, 8181, 5176, 8732, 11797, 11797],
      35401: [8369, 8242, 5237, 8794, 11873, 11873],
      35601: [8428, 8300, 5295, 8855, 11952, 11952],
      35801: [8487, 8362, 5357, 8917, 12028, 12028],
      36001: [8546, 8420, 5415, 8978, 12104, 12104],
      36201: [8605, 8478, 5473, 9040, 12182, 12182],
      36401: [8664, 8539, 5535, 9102, 12258, 12258],
      36601: [8723, 8598, 5593, 9163, 12337, 12337],
      36801: [8783, 8656, 5651, 9225, 12413, 12413],
      37001: [8842, 8717, 5712, 9286, 12489, 12489],
      37201: [8901, 8776, 5771, 9348, 12567, 12567],
      37401: [8960, 8837, 5832, 9409, 12643, 12643],
      37601: [9019, 8892, 5887, 9471, 12716, 12716],
      37801: [9078, 8951, 5946, 9532, 12786, 12786],
      38001: [9137, 9009, 6004, 9594, 12856, 12856],
      38201: [9195, 9070, 6065, 9655, 12926, 12926],
      38401: [9256, 9129, 6124, 9716, 12996, 12996],
      38601: [9326, 9199, 6194, 9778, 13066, 13066],
      38801: [9396, 9269, 6264, 9839, 13136, 13136],
      39001: [9466, 9339, 6334, 9900, 13206, 13206],
      39201: [9536, 9409, 6404, 9962, 13276, 13276],
      39401: [9606, 9479, 6474, 10023, 13346, 13346],
      39601: [9676, 9549, 6544, 10084, 13416, 13416],
      39801: [9746, 9619, 6614, 10146, 13486, 13486],
      40001: [9816, 9689, 6684, 10207, 13556, 13556],
      40201: [9886, 9759, 6754, 10268, 13626, 13626],
      40401: [9956, 9829, 6824, 10330, 13696, 13696],
      40601: [10026, 9899, 6894, 10391, 13766, 13766],
      40801: [10096, 9969, 6964, 10452, 13836, 13836],
      41001: [10166, 10039, 7034, 10514, 13906, 13906],
      41201: [10236, 10109, 7104, 10575, 13976, 13976],
      41401: [10306, 10179, 7174, 10636, 14046, 14046],
      41601: [10376, 10249, 7244, 10698, 14116, 14116],
      41801: [10446, 10319, 7314, 10759, 14186, 14186],
      42001: [10516, 10389, 7384, 10820, 14256, 14256],
      42201: [10586, 10459, 7454, 10882, 14326, 14326],
      42401: [10656, 10529, 7524, 10943, 14396, 14396],
      42601: [10726, 10599, 7594, 11004, 14466, 14466],
      42801: [10796, 10669, 7664, 11066, 14536, 14536],
      43001: [10866, 10739, 7734, 11127, 14606, 14606],
      43201: [10936, 10809, 7804, 11188, 14676, 14676],
      43401: [11006, 10879, 7874, 11250, 14746, 14746],
      43601: [11076, 10949, 7944, 11311, 14816, 14816],
      43801: [11146, 11019, 8014, 11372, 14886, 14886],
      44001: [11216, 11089, 8084, 11434, 14956, 14956],
      44201: [11286, 11159, 8154, 11495, 15026, 15026],
      44401: [11356, 11229, 8224, 11556, 15096, 15096],
      44601: [11426, 11299, 8294, 11618, 15166, 15166],
      44801: [11496, 11369, 8364, 11679, 15236, 15236],
      45001: [11566, 11439, 8434, 11740, 15306, 15306],
      45201: [11636, 11509, 8504, 11802, 15376, 15376],
      45401: [11706, 11579, 8574, 11863, 15446, 15446],
      45601: [11776, 11649, 8644, 11924, 15516, 15516],
      45801: [11846, 11719, 8714, 11986, 15586, 15586],
      46001: [11916, 11789, 8784, 12047, 15656, 15656],
      46201: [11986, 11859, 8854, 12108, 15726, 15726],
      46401: [12056, 11929, 8924, 12170, 15796, 15796],
      46601: [12126, 11999, 8994, 12231, 15866, 15866],
      46801: [12196, 12069, 9064, 12292, 15936, 15936],
      47001: [12266, 12139, 9134, 12354, 16006, 16006],
      47201: [12336, 12209, 9204, 12415, 16076, 16076],
      47401: [12406, 12279, 9274, 12476, 16146, 16146],
      47601: [12476, 12349, 9344, 12538, 16216, 16216],
      47801: [12546, 12419, 9414, 12599, 16286, 16286],
      48001: [12616, 12489, 9484, 12660, 16356, 16356],
      48201: [12686, 12559, 9554, 12722, 16426, 16426],
      48401: [12756, 12629, 9624, 12783, 16496, 16496],
      48601: [12826, 12699, 9694, 12844, 16566, 16566],
      48801: [12896, 12769, 9764, 12906, 16636, 16636],
      49001: [12966, 12839, 9834, 12967, 16706, 16706],
      49201: [13036, 12909, 9904, 13028, 16776, 16776],
      49401: [13106, 12979, 9974, 13090, 16846, 16846],
      49601: [13176, 13049, 10044, 13151, 16916, 16916],
      49801: [13246, 13119, 10114, 13212, 16986, 16986],
      50001: [13316, 13189, 10188, 13274, 17056, 17056],
      50201: [13386, 13259, 10264, 13335, 17126, 17126],
      50401: [13456, 13329, 10340, 13396, 17196, 17196],
      50601: [13526, 13399, 10416, 13458, 17266, 17266],
      50801: [13596, 13469, 10492, 13519, 17336, 17336],
      51001: [13666, 13539, 10568, 13580, 17406, 17406],
      51201: [13761, 13635, 10671, 13667, 17501, 17501],
      51401: [13871, 13749, 10790, 13768, 17611, 17611],
      51601: [13981, 13862, 10910, 13869, 17721, 17721],
      51801: [14091, 13973, 11027, 13971, 17831, 17831],
      52001: [14201, 14087, 11147, 14072, 17941, 17941],
      52201: [14311, 14201, 11266, 14173, 18051, 18051],
      52401: [14421, 14314, 11386, 14275, 18161, 18161],
      52601: [14531, 14428, 11506, 14376, 18271, 18271],
      52801: [14641, 14542, 11626, 14477, 18381, 18381],
      53001: [14751, 14656, 11745, 14579, 18491, 18491],
      53201: [14861, 14769, 11865, 14680, 18601, 18601],
      53401: [14971, 14880, 11982, 14781, 18711, 18711],
      53601: [15081, 14994, 12102, 14883, 18821, 18821],
      53801: [15191, 15108, 12222, 14984, 18931, 18931],
      54001: [15301, 15221, 12341, 15085, 19041, 19041],
      54201: [15411, 15335, 12461, 15187, 19151, 19151],
      54401: [15521, 15449, 12581, 15288, 19261, 19261],
      54601: [15631, 15563, 12701, 15389, 19371, 19371],
      54801: [15741, 15676, 12820, 15491, 19481, 19481],
      55001: [15851, 15790, 12940, 15592, 19591, 19591],
      55201: [15961, 15901, 13057, 15694, 19701, 19701],
      55401: [16071, 16015, 13177, 15795, 19811, 19811],
      55601: [16181, 16129, 13296, 15896, 19921, 19921],
      55801: [16291, 16242, 13416, 15998, 20031, 20031],
      56001: [16401, 16356, 13536, 16099, 20141, 20141],
      56201: [16511, 16470, 13656, 16200, 20251, 20251],
      56401: [16621, 16584, 13775, 16302, 20361, 20361],
      56601: [16731, 16697, 13895, 16403, 20471, 20471],
      56801: [16841, 16808, 14012, 16504, 20581, 20581],
      57001: [16951, 16922, 14132, 16606, 20691, 20691],
      57201: [17061, 17036, 14251, 16707, 20801, 20801],
      57401: [17171, 17149, 14371, 16808, 20911, 20911],
      57601: [17281, 17263, 14491, 16910, 21021, 21021],
      57801: [17391, 17377, 14611, 17011, 21131, 21131],
      58001: [17501, 17483, 14723, 17112, 21241, 21241],
      58201: [17611, 17593, 14839, 17214, 21351, 21351],
      58401: [17721, 17703, 14955, 17315, 21461, 21461],
      58601: [17831, 17813, 15071, 17416, 21571, 21571],
      58801: [17941, 17923, 15187, 17518, 21681, 21681],
      59001: [18051, 18033, 15303, 17619, 21791, 21791],
      59201: [18161, 18143, 15419, 17720, 21901, 21901],
      59401: [18271, 18253, 15535, 17822, 22011, 22011],
      59601: [18381, 18363, 15651, 17923, 22121, 22121],
      59801: [18491, 18473, 15767, 18024, 22231, 22231],
      60001: [18601, 18583, 15883, 18126, 22341, 22341],
      60201: [18711, 18693, 15999, 18227, 22451, 22451],
      60401: [18821, 18803, 16115, 18328, 22561, 22561],
      60601: [18931, 18913, 16231, 18430, 22671, 22671],
      60801: [19041, 19023, 16347, 18531, 22781, 22781],
      61001: [19151, 19133, 16463, 18632, 22891, 22891],
      61201: [19261, 19243, 16579, 18734, 23001, 23001],
      61401: [19371, 19353, 16695, 18835, 23111, 23111],
      61601: [19481, 19463, 16811, 18936, 23221, 23221],
      61801: [19591, 19573, 16927, 19038, 23331, 23331],
      62001: [19701, 19683, 17043, 19139, 23441, 23441],
      62201: [19811, 19793, 17159, 19240, 23551, 23551],
      62401: [19921, 19903, 17275, 19342, 23661, 23661],
      62601: [20031, 20013, 17391, 19443, 23771, 23771],
      62801: [20141, 20123, 17507, 19544, 23881, 23881],
      63001: [20251, 20233, 17623, 19646, 23991, 23991],
      63201: [20361, 20343, 17739, 19747, 24101, 24101],
      63401: [20471, 20453, 17855, 19848, 24211, 24211],
      63601: [20581, 20563, 17971, 19950, 24321, 24321],
      63801: [20691, 20673, 18087, 20051, 24431, 24431],
      64001: [20801, 20783, 18203, 20152, 24541, 24541],
      64201: [20911, 20893, 18319, 20254, 24651, 24651],
      64401: [21021, 21003, 18435, 20355, 24761, 24761],
      64601: [21135, 21122, 18560, 20456, 24871, 24871],
      64801: [21251, 21236, 18680, 20558, 24981, 24981],
      65001: [21367, 21356, 18805, 20659, 25091, 25091],
      65201: [21483, 21470, 18926, 20760, 25201, 25201],
      65401: [21599, 21585, 19047, 20862, 25311, 25311],
      65601: [21715, 21704, 19172, 20963, 25421, 25421],
      65801: [21831, 21819, 19292, 21064, 25531, 25531],
      66001: [21947, 21938, 19418, 21166, 25641, 25641],
      66201: [22063, 22052, 19538, 21267, 25751, 25751],
      66401: [22179, 22167, 19659, 21368, 25861, 25861],
      66601: [22295, 22286, 19784, 21470, 25971, 25971],
      66801: [22411, 22401, 19904, 21571, 26081, 26081],
      67001: [22527, 22515, 20025, 21672, 26191, 26191],
      67201: [22643, 22634, 20150, 21774, 26301, 26301],
      67401: [22759, 22749, 20271, 21875, 26411, 26411],
      67601: [22875, 22868, 20396, 21976, 26521, 26521],
      67801: [22991, 22983, 20517, 22078, 26631, 26631],
      68001: [23107, 23097, 20637, 22179, 26741, 26741],
      68201: [23223, 23216, 20762, 22280, 26851, 26851],
      68401: [23339, 23331, 20883, 22382, 26961, 26961],
      68601: [23455, 23446, 21003, 22483, 27071, 27071],
      68801: [23571, 23565, 21129, 22584, 27181, 27181],
      69001: [23687, 23679, 21249, 22686, 27291, 27291],
      69201: [23803, 23799, 21374, 22787, 27401, 27401],
      69401: [23919, 23913, 21495, 22888, 27511, 27511],
      69601: [24035, 24028, 21616, 22990, 27621, 27621],
      69801: [24151, 24147, 21741, 23091, 27731, 27731],
      70001: [24267, 24262, 21861, 23192, 27841, 27841],
      70201: [24383, 24376, 21982, 23294, 27951, 27951],
      70401: [24499, 24495, 22107, 23395, 28061, 28061],
      70601: [24615, 24610, 22228, 23496, 28171, 28171],
      70801: [24731, 24729, 22353, 23598, 28281, 28281],
      71001: [24847, 24844, 22473, 23699, 28391, 28391],
      71201: [24963, 24958, 22594, 23801, 28501, 28501],
      71401: [25079, 25077, 22719, 23902, 28611, 28611],
      71601: [25195, 25192, 22840, 24003, 28721, 28721],
      71801: [25311, 25311, 22965, 24104, 28831, 28831],
      72001: [25427, 25426, 23085, 24206, 28941, 28941],
      72201: [25543, 25540, 23206, 24307, 29051, 29051],
      72401: [25659, 25659, 23331, 24408, 29161, 29161],
      72601: [25775, 25774, 23452, 24510, 29271, 29271],
      72801: [25891, 25889, 23572, 24611, 29381, 29381],
      73001: [26007, 26008, 23698, 24712, 29491, 29491],
      73201: [26123, 26122, 23818, 24814, 29601, 29601],
      73401: [26239, 26241, 23943, 24915, 29711, 29711],
      73601: [26355, 26356, 24064, 25017, 29821, 29821],
      73801: [26471, 26471, 24184, 25118, 29931, 29931],
      74001: [26587, 26590, 24310, 25219, 30041, 30041],
      74201: [26703, 26704, 24430, 25321, 30151, 30151],
      74401: [26819, 26819, 24551, 25422, 30261, 30261],
      74601: [26935, 26938, 24676, 25523, 30371, 30371],
      74801: [27051, 27053, 24797, 25625, 30481, 30481],
      75001: [27167, 27172, 24922, 25726, 30591, 30591],
      75201: [27283, 27286, 25042, 25827, 30701, 30701],
      75401: [27399, 27401, 25163, 25929, 30811, 30811],
      75601: [27515, 27520, 25288, 26030, 30921, 30921],
      75801: [27631, 27635, 25409, 26131, 31031, 31031],
      76001: [27747, 27749, 25529, 26233, 31141, 31141],
      76201: [27863, 27869, 25654, 26334, 31251, 31251],
      76401: [27979, 27983, 25775, 26435, 31361, 31361],
      76601: [28095, 28102, 25900, 26537, 31471, 31471],
      76801: [28211, 28217, 26021, 26638, 31581, 31581],
      77001: [28327, 28331, 26141, 26739, 31691, 31691],
      77201: [28443, 28451, 26266, 26841, 31801, 31801],
      77401: [28559, 28565, 26387, 26942, 31911, 31911],
      77601: [28675, 28680, 26508, 27043, 32021, 32021],
      77801: [28791, 28799, 26633, 27145, 32131, 32131],
      78001: [28907, 28914, 26753, 27246, 32241, 32241],
      78201: [29023, 29033, 26879, 27347, 32351, 32351],
      78401: [29139, 29147, 26999, 27449, 32461, 32461],
      78601: [29255, 29262, 27120, 27550, 32571, 32571],
      78801: [29371, 29381, 27245, 27651, 32681, 32681],
      79001: [29487, 29496, 27365, 27753, 32791, 32791],
      79201: [29603, 29610, 27486, 27854, 32901, 32901],
      79401: [29719, 29729, 27611, 27955, 33011, 33011],
      79601: [29835, 29844, 27732, 28057, 33121, 33121],
      79801: [29951, 29963, 27857, 28158, 33231, 33231],
      80001: [37, 38, 35, 35, 42, 42, "%"],
      80201: [38, 38, 35, 35, 42, 42, "%"],
      81401: [38, 38, 35, 36, 42, 42, "%"],
      82001: [38, 38, 36, 36, 42, 42, "%"],
      84201: [39, 39, 36, 36, 42, 42, "%"],
      85401: [39, 39, 37, 36, 42, 42, "%"],
      86001: [39, 39, 37, 36, 43, 43, "%"],
      87201: [39, 39, 37, 37, 43, 43, "%"],
      88601: [39, 40, 37, 37, 43, 43, "%"],
      88801: [40, 40, 37, 37, 43, 43, "%"],
      89001: [40, 40, 38, 37, 43, 43, "%"],
      92801: [40, 40, 39, 37, 43, 43, "%"],
      93601: [40, 41, 39, 37, 44, 44, "%"],
      93801: [41, 41, 39, 38, 44, 44, "%"],
      97201: [41, 41, 40, 38, 44, 44, "%"],
      99401: [41, 42, 40, 38, 44, 44, "%"],
      99601: [42, 42, 40, 38, 44, 44, "%"],
      101601: [42, 42, 40, 39, 44, 44, "%"],
      102001: [42, 42, 41, 39, 44, 44, "%"],
      102401: [42, 42, 41, 39, 45, 45, "%"],
      105801: [42, 43, 41, 39, 45, 45, "%"],
      106001: [43, 43, 41, 39, 45, 45, "%"],
      107001: [43, 43, 42, 39, 45, 45, "%"],
      110801: [43, 43, 42, 40, 45, 45, "%"],
      112801: [43, 43, 43, 40, 45, 45, "%"],
      113001: [43, 44, 43, 40, 45, 45, "%"],
      113201: [43, 44, 43, 40, 46, 46, "%"],
      113401: [44, 44, 43, 40, 46, 46, "%"],
      119201: [44, 44, 44, 40, 46, 46, "%"],
      121201: [44, 45, 44, 40, 46, 46, "%"],
      121601: [44, 45, 44, 41, 46, 46, "%"],
      121801: [45, 45, 44, 41, 46, 46, "%"],
      126401: [45, 45, 45, 41, 46, 46, "%"],
      126601: [45, 45, 45, 41, 47, 47, "%"],
      130801: [45, 46, 45, 41, 47, 47, "%"],
      131401: [46, 46, 45, 41, 47, 47, "%"],
      134401: [46, 46, 46, 41, 47, 47, "%"],
      134801: [46, 46, 46, 42, 47, 47, "%"],
      142001: [46, 47, 46, 42, 47, 47, "%"],
      143001: [47, 47, 46, 42, 47, 47, "%"],
      143401: [47, 47, 46, 42, 48, 48, "%"],
      143601: [47, 47, 47, 42, 48, 48, "%"],
      151401: [47, 47, 47, 43, 48, 48, "%"],
      155401: [47, 48, 48, 43, 48, 48, "%"],
      156601: [48, 48, 48, 43, 48, 48, "%"],
      165601: [48, 48, 48, 43, 49, 49, "%"],
      171401: [48, 49, 49, 43, 49, 49, "%"],
      172601: [48, 49, 49, 44, 49, 49, "%"],
      173001: [49, 49, 49, 44, 49, 49, "%"],
      195601: [50, 50, 50, 44, 50, 50, "%"],
      200601: [50, 50, 50, 45, 50, 50, "%"],
      239201: [51, 51, 51, 45, 51, 51, "%"],
      239401: [51, 51, 51, 46, 51, 51, "%"],
      296801: [51, 51, 51, 47, 51, 51, "%"],
      307601: [52, 52, 52, 47, 52, 52, "%"],
      390601: [52, 52, 52, 48, 52, 52, "%"],
      430601: [53, 53, 53, 48, 53, 53, "%"],
      570601: [53, 53, 53, 49, 53, 53, "%"],
      717801: [54, 54, 54, 49, 54, 54, "%"],
      1059001: [54, 54, 54, 50, 54, 54, "%"]
    },
    36: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 27],
      2101: [153, 0, 150, 3, 153, 64],
      2201: [164, 0, 158, 5, 164, 101],
      2301: [174, 0, 166, 8, 174, 138],
      2401: [185, 0, 175, 10, 185, 175],
      2501: [195, 0, 183, 12, 212, 212],
      2601: [206, 0, 191, 14, 249, 249],
      2701: [216, 0, 200, 16, 286, 286],
      2801: [218, 0, 200, 18, 323, 323],
      2901: [229, 0, 208, 21, 360, 360],
      3001: [239, 0, 216, 23, 397, 397],
      3101: [250, 0, 225, 25, 434, 434],
      3201: [260, 0, 233, 27, 471, 471],
      3301: [271, 0, 241, 29, 508, 508],
      3401: [273, 0, 241, 31, 545, 545],
      3501: [284, 0, 250, 34, 582, 582],
      3601: [294, 0, 258, 36, 619, 619],
      3701: [305, 0, 266, 38, 656, 656],
      3801: [315, 0, 275, 40, 693, 693],
      3901: [325, 0, 283, 42, 730, 730],
      4001: [328, 0, 283, 44, 767, 767],
      4101: [338, 0, 291, 46, 804, 804],
      4201: [349, 0, 300, 49, 841, 841],
      4301: [359, 0, 308, 63, 878, 878],
      4401: [370, 0, 316, 89, 915, 915],
      4501: [380, 0, 325, 114, 952, 952],
      4601: [382, 0, 325, 139, 989, 989],
      4701: [392, 0, 333, 164, 1020, 1020],
      4801: [403, 0, 341, 188, 1051, 1051],
      4901: [413, 0, 350, 213, 1082, 1082],
      5001: [423, 0, 358, 238, 1109, 1109],
      5101: [433, 0, 366, 262, 1140, 1140],
      5201: [443, 0, 375, 287, 1168, 1168],
      5301: [445, 24, 376, 312, 1199, 1199],
      5401: [455, 52, 386, 337, 1230, 1230],
      5501: [465, 80, 396, 361, 1257, 1257],
      5601: [475, 107, 406, 385, 1287, 1287],
      5701: [485, 135, 416, 409, 1314, 1314],
      5801: [495, 163, 426, 433, 1345, 1345],
      5901: [497, 191, 427, 457, 1375, 1375],
      6001: [507, 218, 437, 481, 1402, 1402],
      6101: [517, 246, 447, 505, 1432, 1432],
      6201: [527, 271, 457, 530, 1459, 1459],
      6301: [537, 299, 467, 554, 1490, 1490],
      6401: [547, 326, 477, 578, 1520, 1520],
      6501: [565, 354, 479, 602, 1547, 1547],
      6601: [588, 382, 488, 626, 1577, 1577],
      6701: [610, 410, 498, 650, 1604, 1604],
      6801: [632, 437, 508, 674, 1635, 1635],
      6901: [655, 465, 518, 698, 1665, 1665],
      7001: [677, 493, 528, 722, 1692, 1692],
      7101: [700, 521, 530, 747, 1722, 1722],
      7201: [722, 548, 540, 771, 1749, 1749],
      7301: [745, 576, 550, 795, 1780, 1780],
      7401: [767, 601, 560, 819, 1810, 1810],
      7501: [790, 628, 570, 843, 1837, 1837],
      7601: [812, 656, 580, 867, 1867, 1867],
      7701: [835, 684, 589, 891, 1894, 1894],
      7801: [857, 712, 591, 915, 1925, 1925],
      7901: [880, 739, 601, 939, 1955, 1955],
      8001: [902, 767, 611, 963, 1982, 1982],
      8101: [925, 795, 621, 988, 2012, 2012],
      8201: [947, 823, 631, 1012, 2039, 2039],
      8301: [970, 850, 641, 1036, 2070, 2070],
      8401: [992, 878, 642, 1060, 2100, 2100],
      8501: [1014, 906, 652, 1084, 2127, 2127],
      8601: [1037, 931, 662, 1108, 2157, 2157],
      8701: [1059, 958, 672, 1132, 2184, 2184],
      8801: [1082, 986, 682, 1156, 2215, 2215],
      8901: [1104, 1014, 692, 1180, 2245, 2245],
      9001: [1127, 1042, 694, 1204, 2272, 2272],
      9101: [1149, 1069, 704, 1229, 2302, 2302],
      9201: [1172, 1097, 714, 1253, 2329, 2329],
      9301: [1194, 1116, 723, 1277, 2360, 2360],
      9401: [1217, 1140, 733, 1301, 2390, 2390],
      9501: [1239, 1162, 742, 1325, 2417, 2417],
      9601: [1262, 1183, 744, 1349, 2447, 2447],
      9701: [1284, 1205, 753, 1373, 2474, 2474],
      9801: [1307, 1230, 763, 1397, 2505, 2505],
      9901: [1329, 1251, 773, 1421, 2535, 2535],
      10001: [1351, 1272, 782, 1445, 2562, 2562],
      10101: [1374, 1293, 792, 1470, 2592, 2592],
      10201: [1396, 1317, 802, 1494, 2619, 2619],
      10301: [1419, 1339, 803, 1518, 2650, 2650],
      10401: [1441, 1360, 812, 1542, 2680, 2680],
      10501: [1464, 1381, 822, 1566, 2707, 2707],
      10601: [1486, 1405, 832, 1590, 2737, 2737],
      10701: [1509, 1426, 841, 1614, 2764, 2764],
      10801: [1531, 1447, 851, 1638, 2795, 2795],
      10901: [1554, 1468, 852, 1663, 2825, 2825],
      11001: [1576, 1493, 862, 1687, 2852, 2852],
      11101: [1599, 1514, 872, 1711, 2882, 2882],
      11201: [1621, 1535, 881, 1735, 2909, 2909],
      11301: [1644, 1559, 891, 1759, 2940, 2940],
      11401: [1666, 1580, 901, 1783, 2970, 2970],
      11501: [1689, 1601, 902, 1807, 2997, 2997],
      11601: [1711, 1623, 911, 1831, 3027, 3027],
      11701: [1733, 1647, 921, 1855, 3054, 3054],
      11801: [1756, 1668, 931, 1879, 3085, 3085],
      11901: [1778, 1689, 940, 1904, 3115, 3115],
      12001: [1801, 1710, 950, 1928, 3142, 3142],
      12101: [1823, 1734, 951, 1952, 3172, 3172],
      12201: [1846, 1755, 961, 1976, 3199, 3199],
      12301: [1868, 1777, 971, 2e3, 3230, 3230],
      12401: [1891, 1798, 980, 2024, 3260, 3260],
      12501: [1913, 1822, 990, 2048, 3287, 3287],
      12601: [1936, 1843, 1e3, 2072, 3317, 3317],
      12701: [1958, 1864, 1009, 2096, 3344, 3344],
      12801: [1981, 1885, 1010, 2120, 3375, 3375],
      12901: [2003, 1909, 1020, 2145, 3402, 3402],
      13001: [2026, 1931, 1030, 2169, 3438, 3438],
      13101: [2049, 1952, 1039, 2193, 3474, 3474],
      13201: [2071, 1976, 1049, 2218, 3511, 3511],
      13301: [2094, 1997, 1059, 2242, 3547, 3547],
      13401: [2117, 2018, 1060, 2266, 3583, 3583],
      13501: [2140, 2039, 1070, 2291, 3619, 3619],
      13601: [2162, 2064, 1079, 2315, 3656, 3656],
      13701: [2185, 2085, 1089, 2340, 3692, 3692],
      13801: [2207, 2106, 1099, 2363, 3727, 3727],
      13901: [2229, 2127, 1108, 2387, 3763, 3763],
      14001: [2251, 2151, 1110, 2410, 3798, 3798],
      14101: [2273, 2172, 1119, 2434, 3833, 3833],
      14201: [2294, 2193, 1129, 2457, 3868, 3868],
      14301: [2316, 2215, 1138, 2480, 3904, 3904],
      14401: [2338, 2239, 1148, 2504, 3939, 3939],
      14501: [2360, 2260, 1158, 2527, 3974, 3974],
      14601: [2381, 2281, 1159, 2551, 4009, 4009],
      14701: [2403, 2302, 1169, 2574, 4045, 4045],
      14801: [2425, 2326, 1178, 2597, 4083, 4083],
      14901: [2447, 2348, 1188, 2621, 4121, 4121],
      15001: [2468, 2369, 1198, 2644, 4159, 4159],
      15101: [2490, 2390, 1207, 2668, 4197, 4197],
      15201: [2512, 2414, 1217, 2691, 4239, 4239],
      15301: [2534, 2435, 1218, 2714, 4277, 4277],
      15401: [2557, 2459, 1228, 2741, 4315, 4315],
      15501: [2587, 2489, 1238, 2771, 4353, 4353],
      15601: [2616, 2520, 1248, 2802, 4391, 4391],
      15701: [2646, 2550, 1258, 2833, 4432, 4432],
      15801: [2676, 2580, 1268, 2864, 4471, 4471],
      15901: [2705, 2610, 1270, 2895, 4509, 4509],
      16001: [2735, 2641, 1280, 2926, 4547, 4547],
      16101: [2764, 2671, 1291, 2956, 4585, 4585],
      16201: [2794, 2701, 1301, 2987, 4626, 4626],
      16301: [2823, 2731, 1311, 3018, 4665, 4665],
      16401: [2853, 2761, 1321, 3049, 4703, 4703],
      16501: [2882, 2792, 1323, 3080, 4741, 4741],
      16601: [2912, 2822, 1333, 3111, 4779, 4779],
      16701: [2942, 2852, 1343, 3141, 4820, 4820],
      16801: [2971, 2882, 1353, 3172, 4858, 4858],
      16901: [3001, 2912, 1363, 3203, 4897, 4897],
      17001: [3030, 2940, 1373, 3234, 4935, 4935],
      17101: [3060, 2970, 1375, 3265, 4973, 4973],
      17201: [3089, 3e3, 1385, 3296, 5014, 5014],
      17301: [3119, 3030, 1395, 3326, 5052, 5052],
      17401: [3149, 3060, 1405, 3357, 5090, 5090],
      17501: [3178, 3091, 1416, 3388, 5129, 5129],
      17601: [3208, 3121, 1426, 3419, 5167, 5167],
      17701: [3237, 3151, 1436, 3450, 5208, 5208],
      17801: [3267, 3181, 1438, 3481, 5246, 5246],
      17901: [3296, 3211, 1448, 3511, 5284, 5284],
      18001: [3326, 3242, 1458, 3542, 5323, 5323],
      18101: [3356, 3272, 1468, 3573, 5361, 5361],
      18201: [3385, 3302, 1478, 3604, 5402, 5402],
      18301: [3415, 3332, 1488, 3635, 5440, 5440],
      18401: [3444, 3363, 1490, 3666, 5478, 5478],
      18501: [3474, 3393, 1500, 3696, 5516, 5516],
      18601: [3503, 3423, 1510, 3727, 5555, 5555],
      18701: [3533, 3453, 1520, 3758, 5596, 5596],
      18801: [3563, 3483, 1531, 3789, 5634, 5634],
      18901: [3592, 3514, 1541, 3820, 5672, 5672],
      19001: [3622, 3544, 1543, 3851, 5710, 5710],
      19101: [3651, 3574, 1553, 3881, 5748, 5748],
      19201: [3681, 3604, 1563, 3912, 5790, 5790],
      19301: [3710, 3634, 1573, 3943, 5828, 5828],
      19401: [3740, 3665, 1583, 3974, 5866, 5866],
      19501: [3769, 3695, 1593, 4005, 5904, 5904],
      19601: [3799, 3725, 1595, 4035, 5942, 5942],
      19701: [3829, 3754, 1604, 4066, 5983, 5983],
      19801: [3858, 3783, 1613, 4097, 6022, 6022],
      19901: [3888, 3813, 1622, 4128, 6060, 6060],
      20001: [3947, 3871, 1641, 4190, 6136, 6136],
      20201: [4006, 3927, 1651, 4251, 6216, 6216],
      20401: [4065, 3986, 1670, 4313, 6292, 6292],
      20601: [4124, 4045, 1688, 4375, 6371, 6371],
      20801: [4183, 4103, 1699, 4436, 6448, 6448],
      21001: [4243, 4162, 1717, 4498, 6524, 6524],
      21201: [4302, 4221, 1736, 4560, 6603, 6603],
      21401: [4361, 4280, 1746, 4621, 6680, 6680],
      21601: [4420, 4338, 1765, 4683, 6759, 6759],
      21801: [4479, 4397, 1783, 4745, 6835, 6835],
      22001: [4538, 4456, 1794, 4806, 6912, 6912],
      22201: [4597, 4515, 1812, 4868, 6991, 6991],
      22401: [4656, 4573, 1831, 4930, 7067, 7067],
      22601: [4716, 4632, 1849, 4991, 7147, 7147],
      22801: [4776, 4691, 1860, 5054, 7224, 7224],
      23001: [4836, 4750, 1878, 5117, 7302, 7302],
      23201: [4897, 4808, 1912, 5181, 7383, 7383],
      23401: [4958, 4864, 1954, 5244, 7461, 7461],
      23601: [5019, 4923, 1999, 5307, 7542, 7542],
      23801: [5080, 4979, 2041, 5371, 7620, 7620],
      24001: [5140, 5041, 2088, 5434, 7698, 7698],
      24201: [5201, 5099, 2133, 5497, 7779, 7779],
      24401: [5262, 5161, 2181, 5560, 7857, 7857],
      24601: [5323, 5220, 2226, 5624, 7938, 7938],
      24801: [5384, 5278, 2270, 5687, 8016, 8016],
      25001: [5444, 5340, 2335, 5750, 8094, 8094],
      25201: [5505, 5399, 2394, 5814, 8175, 8175],
      25401: [5566, 5458, 2453, 5877, 8253, 8253],
      25601: [5627, 5519, 2514, 5940, 8334, 8334],
      25801: [5687, 5578, 2573, 6004, 8412, 8412],
      26001: [5748, 5637, 2632, 6067, 8490, 8490],
      26201: [5809, 5699, 2694, 6130, 8571, 8571],
      26401: [5870, 5757, 2752, 6194, 8649, 8649],
      26601: [5931, 5819, 2814, 6257, 8730, 8730],
      26801: [5991, 5878, 2873, 6320, 8808, 8808],
      27001: [6052, 5936, 2932, 6383, 8886, 8886],
      27201: [6113, 5998, 2993, 6447, 8967, 8967],
      27401: [6174, 6057, 3052, 6510, 9045, 9045],
      27601: [6235, 6116, 3111, 6574, 9126, 9126],
      27801: [6295, 6177, 3172, 6637, 9204, 9204],
      28001: [6356, 6236, 3231, 6700, 9282, 9282],
      28201: [6417, 6298, 3293, 6763, 9363, 9363],
      28401: [6478, 6357, 3352, 6827, 9441, 9441],
      28601: [6538, 6415, 3410, 6890, 9522, 9522],
      28801: [6599, 6477, 3472, 6953, 9600, 9600],
      29001: [6660, 6536, 3531, 7017, 9678, 9678],
      29201: [6721, 6594, 3590, 7080, 9759, 9759],
      29401: [6782, 6656, 3651, 7143, 9837, 9837],
      29601: [6842, 6715, 3710, 7207, 9918, 9918],
      29801: [6903, 6777, 3772, 7270, 9996, 9996],
      30001: [6964, 6835, 3830, 7333, 10074, 10074],
      30201: [7025, 6894, 3889, 7397, 10155, 10155],
      30401: [7085, 6956, 3951, 7460, 10233, 10233],
      30601: [7146, 7015, 4010, 7523, 10314, 10314],
      30801: [7207, 7073, 4068, 7586, 10392, 10392],
      31001: [7268, 7135, 4130, 7650, 10470, 10470],
      31201: [7329, 7194, 4189, 7713, 10551, 10551],
      31401: [7389, 7254, 4249, 7776, 10629, 10629],
      31601: [7450, 7317, 4312, 7840, 10710, 10710],
      31801: [7511, 7377, 4372, 7903, 10788, 10788],
      32001: [7572, 7440, 4435, 7966, 10866, 10866],
      32201: [7633, 7500, 4495, 8030, 10947, 10947],
      32401: [7693, 7560, 4555, 8093, 11025, 11025],
      32601: [7754, 7623, 4618, 8156, 11106, 11106],
      32801: [7815, 7683, 4678, 8220, 11184, 11184],
      33001: [7876, 7743, 4738, 8283, 11262, 11262],
      33201: [7936, 7806, 4801, 8346, 11343, 11343],
      33401: [7997, 7866, 4861, 8410, 11421, 11421],
      33601: [8058, 7929, 4924, 8473, 11502, 11502],
      33801: [8119, 7989, 4984, 8536, 11580, 11580],
      34001: [8180, 8049, 5044, 8599, 11658, 11658],
      34201: [8240, 8112, 5107, 8663, 11739, 11739],
      34401: [8301, 8172, 5167, 8726, 11817, 11817],
      34601: [8362, 8232, 5227, 8789, 11898, 11898],
      34801: [8423, 8295, 5290, 8853, 11976, 11976],
      35001: [8483, 8355, 5350, 8916, 12054, 12054],
      35201: [8544, 8415, 5410, 8979, 12135, 12135],
      35401: [8605, 8478, 5473, 9043, 12213, 12213],
      35601: [8666, 8538, 5533, 9106, 12294, 12294],
      35801: [8727, 8601, 5596, 9169, 12372, 12372],
      36001: [8787, 8661, 5656, 9233, 12450, 12450],
      36201: [8848, 8721, 5716, 9296, 12531, 12531],
      36401: [8909, 8784, 5779, 9359, 12609, 12609],
      36601: [8970, 8844, 5839, 9423, 12690, 12690],
      36801: [9031, 8904, 5899, 9486, 12768, 12768],
      37001: [9091, 8967, 5962, 9549, 12846, 12846],
      37201: [9152, 9027, 6022, 9612, 12927, 12927],
      37401: [9213, 9090, 6085, 9676, 13005, 13005],
      37601: [9274, 9147, 6142, 9739, 13080, 13080],
      37801: [9334, 9207, 6202, 9802, 13152, 13152],
      38001: [9395, 9267, 6262, 9865, 13224, 13224],
      38201: [9455, 9330, 6325, 9928, 13296, 13296],
      38401: [9517, 9390, 6385, 9991, 13368, 13368],
      38601: [9589, 9462, 6457, 10054, 13440, 13440],
      38801: [9661, 9534, 6529, 10117, 13512, 13512],
      39001: [9733, 9606, 6601, 10181, 13584, 13584],
      39201: [9805, 9678, 6673, 10244, 13656, 13656],
      39401: [9877, 9750, 6745, 10307, 13728, 13728],
      39601: [9949, 9822, 6817, 10370, 13800, 13800],
      39801: [10021, 9894, 6889, 10433, 13872, 13872],
      40001: [10093, 9966, 6961, 10496, 13944, 13944],
      40201: [10165, 10038, 7033, 10559, 14016, 14016],
      40401: [10237, 10110, 7105, 10622, 14088, 14088],
      40601: [10309, 10182, 7177, 10685, 14160, 14160],
      40801: [10381, 10254, 7249, 10748, 14232, 14232],
      41001: [10453, 10326, 7321, 10811, 14304, 14304],
      41201: [10525, 10398, 7393, 10874, 14376, 14376],
      41401: [10597, 10470, 7465, 10937, 14448, 14448],
      41601: [10669, 10542, 7537, 11001, 14520, 14520],
      41801: [10741, 10614, 7609, 11064, 14592, 14592],
      42001: [10813, 10686, 7681, 11127, 14664, 14664],
      42201: [10885, 10758, 7753, 11190, 14736, 14736],
      42401: [10957, 10830, 7825, 11253, 14808, 14808],
      42601: [11029, 10902, 7897, 11316, 14880, 14880],
      42801: [11101, 10974, 7969, 11379, 14952, 14952],
      43001: [11173, 11046, 8041, 11442, 15024, 15024],
      43201: [11245, 11118, 8113, 11505, 15096, 15096],
      43401: [11317, 11190, 8185, 11568, 15168, 15168],
      43601: [11389, 11262, 8257, 11631, 15240, 15240],
      43801: [11461, 11334, 8329, 11694, 15312, 15312],
      44001: [11533, 11406, 8401, 11758, 15384, 15384],
      44201: [11605, 11478, 8473, 11821, 15456, 15456],
      44401: [11677, 11550, 8545, 11884, 15528, 15528],
      44601: [11749, 11622, 8617, 11947, 15600, 15600],
      44801: [11821, 11694, 8689, 12010, 15672, 15672],
      45001: [11893, 11766, 8761, 12073, 15744, 15744],
      45201: [11965, 11838, 8833, 12136, 15816, 15816],
      45401: [12037, 11910, 8905, 12199, 15888, 15888],
      45601: [12109, 11982, 8977, 12262, 15960, 15960],
      45801: [12181, 12054, 9049, 12325, 16032, 16032],
      46001: [12253, 12126, 9121, 12388, 16104, 16104],
      46201: [12325, 12198, 9193, 12452, 16176, 16176],
      46401: [12397, 12270, 9265, 12515, 16248, 16248],
      46601: [12469, 12342, 9337, 12578, 16320, 16320],
      46801: [12541, 12414, 9409, 12641, 16392, 16392],
      47001: [12613, 12486, 9481, 12704, 16464, 16464],
      47201: [12685, 12558, 9553, 12767, 16536, 16536],
      47401: [12757, 12630, 9625, 12830, 16608, 16608],
      47601: [12829, 12702, 9697, 12893, 16680, 16680],
      47801: [12901, 12774, 9769, 12956, 16752, 16752],
      48001: [12973, 12846, 9841, 13019, 16824, 16824],
      48201: [13045, 12918, 9913, 13082, 16896, 16896],
      48401: [13117, 12990, 9985, 13145, 16968, 16968],
      48601: [13189, 13062, 10057, 13208, 17040, 17040],
      48801: [13261, 13134, 10129, 13271, 17112, 17112],
      49001: [13333, 13206, 10201, 13335, 17184, 17184],
      49201: [13405, 13278, 10273, 13398, 17256, 17256],
      49401: [13477, 13350, 10345, 13461, 17328, 17328],
      49601: [13549, 13422, 10417, 13524, 17400, 17400],
      49801: [13621, 13494, 10489, 13587, 17472, 17472],
      50001: [13693, 13566, 10566, 13650, 17544, 17544],
      50201: [13765, 13638, 10644, 13713, 17616, 17616],
      50401: [13837, 13710, 10722, 13776, 17688, 17688],
      50601: [13909, 13782, 10800, 13839, 17760, 17760],
      50801: [13981, 13854, 10878, 13902, 17832, 17832],
      51001: [14053, 13926, 10956, 13965, 17904, 17904],
      51201: [14150, 14025, 11061, 14054, 18001, 18001],
      51401: [14262, 14142, 11184, 14157, 18113, 18113],
      51601: [14374, 14259, 11307, 14260, 18225, 18225],
      51801: [14486, 14373, 11427, 14363, 18337, 18337],
      52001: [14598, 14490, 11550, 14466, 18449, 18449],
      52201: [14710, 14607, 11673, 14569, 18561, 18561],
      52401: [14822, 14724, 11796, 14672, 18673, 18673],
      52601: [14934, 14841, 11919, 14775, 18785, 18785],
      52801: [15046, 14958, 12042, 14878, 18897, 18897],
      53001: [15158, 15075, 12165, 14981, 19009, 19009],
      53201: [15270, 15192, 12288, 15084, 19121, 19121],
      53401: [15382, 15306, 12408, 15187, 19233, 19233],
      53601: [15494, 15423, 12531, 15290, 19345, 19345],
      53801: [15606, 15540, 12654, 15394, 19457, 19457],
      54001: [15718, 15657, 12777, 15497, 19569, 19569],
      54201: [15830, 15774, 12900, 15600, 19681, 19681],
      54401: [15942, 15891, 13023, 15703, 19793, 19793],
      54601: [16054, 16008, 13146, 15806, 19905, 19905],
      54801: [16166, 16125, 13269, 15909, 20017, 20017],
      55001: [16278, 16242, 13392, 16012, 20129, 20129],
      55201: [16390, 16356, 13512, 16115, 20241, 20241],
      55401: [16502, 16473, 13635, 16218, 20353, 20353],
      55601: [16614, 16590, 13758, 16321, 20465, 20465],
      55801: [16726, 16707, 13881, 16424, 20577, 20577],
      56001: [16838, 16824, 14004, 16527, 20689, 20689],
      56201: [16950, 16941, 14127, 16631, 20801, 20801],
      56401: [17062, 17058, 14250, 16734, 20913, 20913],
      56601: [17174, 17175, 14373, 16837, 21025, 21025],
      56801: [17286, 17289, 14493, 16940, 21137, 21137],
      57001: [17398, 17406, 14616, 17043, 21249, 21249],
      57201: [17510, 17523, 14739, 17146, 21361, 21361],
      57401: [17622, 17640, 14862, 17249, 21473, 21473],
      57601: [17734, 17757, 14985, 17352, 21585, 21585],
      57801: [17846, 17874, 15108, 17455, 21697, 21697],
      58001: [17958, 17982, 15222, 17558, 21809, 21809],
      58201: [18070, 18094, 15340, 17661, 21921, 21921],
      58401: [18182, 18206, 15458, 17764, 22033, 22033],
      58601: [18294, 18318, 15576, 17867, 22145, 22145],
      58801: [18406, 18430, 15694, 17971, 22257, 22257],
      59001: [18518, 18542, 15812, 18074, 22369, 22369],
      59201: [18630, 18654, 15930, 18177, 22481, 22481],
      59401: [18742, 18766, 16048, 18280, 22593, 22593],
      59601: [18854, 18878, 16166, 18383, 22705, 22705],
      59801: [18966, 18990, 16284, 18486, 22817, 22817],
      60001: [19078, 19102, 16402, 18589, 22929, 22929],
      60201: [19190, 19214, 16520, 18692, 23041, 23041],
      60401: [19302, 19326, 16638, 18795, 23153, 23153],
      60601: [19414, 19438, 16756, 18898, 23265, 23265],
      60801: [19526, 19550, 16874, 19001, 23377, 23377],
      61001: [19638, 19662, 16992, 19104, 23489, 23489],
      61201: [19750, 19774, 17110, 19208, 23601, 23601],
      61401: [19862, 19886, 17228, 19311, 23713, 23713],
      61601: [19974, 19998, 17346, 19414, 23825, 23825],
      61801: [20086, 20110, 17464, 19517, 23937, 23937],
      62001: [20198, 20222, 17582, 19620, 24049, 24049],
      62201: [20310, 20334, 17700, 19723, 24161, 24161],
      62401: [20422, 20446, 17818, 19826, 24273, 24273],
      62601: [20534, 20558, 17936, 19929, 24385, 24385],
      62801: [20646, 20670, 18054, 20032, 24497, 24497],
      63001: [20758, 20782, 18172, 20135, 24609, 24609],
      63201: [20870, 20894, 18290, 20238, 24721, 24721],
      63401: [20982, 21006, 18408, 20341, 24833, 24833],
      63601: [21094, 21118, 18526, 20444, 24945, 24945],
      63801: [21206, 21230, 18644, 20548, 25057, 25057],
      64001: [21318, 21342, 18762, 20651, 25169, 25169],
      64201: [21430, 21454, 18880, 20754, 25281, 25281],
      64401: [21542, 21566, 18998, 20857, 25393, 25393],
      64601: [21659, 21688, 19126, 20960, 25505, 25505],
      64801: [21777, 21804, 19248, 21063, 25617, 25617],
      65001: [21895, 21926, 19376, 21166, 25729, 25729],
      65201: [22013, 22042, 19498, 21269, 25841, 25841],
      65401: [22131, 22159, 19621, 21372, 25953, 25953],
      65601: [22249, 22280, 19748, 21475, 26065, 26065],
      65801: [22367, 22397, 19871, 21578, 26177, 26177],
      66001: [22485, 22518, 19998, 21681, 26289, 26289],
      66201: [22603, 22635, 20121, 21785, 26401, 26401],
      66401: [22721, 22752, 20244, 21888, 26513, 26513],
      66601: [22839, 22873, 20371, 21991, 26625, 26625],
      66801: [22957, 22990, 20494, 22094, 26737, 26737],
      67001: [23075, 23106, 20616, 22197, 26849, 26849],
      67201: [23193, 23228, 20744, 22300, 26961, 26961],
      67401: [23311, 23344, 20866, 22403, 27073, 27073],
      67601: [23429, 23466, 20994, 22506, 27185, 27185],
      67801: [23547, 23582, 21116, 22609, 27297, 27297],
      68001: [23665, 23699, 21239, 22712, 27409, 27409],
      68201: [23783, 23820, 21366, 22815, 27521, 27521],
      68401: [23901, 23937, 21489, 22918, 27633, 27633],
      68601: [24019, 24054, 21612, 23021, 27745, 27745],
      68801: [24137, 24175, 21739, 23125, 27857, 27857],
      69001: [24255, 24292, 21862, 23228, 27969, 27969],
      69201: [24373, 24413, 21989, 23331, 28081, 28081],
      69401: [24491, 24530, 22112, 23434, 28193, 28193],
      69601: [24609, 24646, 22234, 23537, 28305, 28305],
      69801: [24727, 24768, 22362, 23640, 28417, 28417],
      70001: [24845, 24884, 22484, 23743, 28529, 28529],
      70201: [24963, 25001, 22607, 23846, 28641, 28641],
      70401: [25081, 25122, 22734, 23949, 28753, 28753],
      70601: [25199, 25239, 22857, 24052, 28865, 28865],
      70801: [25317, 25360, 22984, 24155, 28977, 28977],
      71001: [25435, 25477, 23107, 24258, 29089, 29089],
      71201: [25553, 25594, 23230, 24362, 29201, 29201],
      71401: [25671, 25715, 23357, 24465, 29313, 29313],
      71601: [25789, 25832, 23480, 24568, 29425, 29425],
      71801: [25907, 25953, 23607, 24671, 29537, 29537],
      72001: [26025, 26070, 23730, 24774, 29649, 29649],
      72201: [26143, 26186, 23852, 24877, 29761, 29761],
      72401: [26261, 26308, 23980, 24980, 29873, 29873],
      72601: [26379, 26424, 24102, 25083, 29985, 29985],
      72801: [26497, 26541, 24225, 25186, 30097, 30097],
      73001: [26615, 26662, 24352, 25289, 30209, 30209],
      73201: [26733, 26779, 24475, 25392, 30321, 30321],
      73401: [26851, 26900, 24602, 25495, 30433, 30433],
      73601: [26969, 27017, 24725, 25599, 30545, 30545],
      73801: [27087, 27134, 24848, 25702, 30657, 30657],
      74001: [27205, 27255, 24975, 25805, 30769, 30769],
      74201: [27323, 27372, 25098, 25908, 30881, 30881],
      74401: [27441, 27488, 25220, 26011, 30993, 30993],
      74601: [27559, 27610, 25348, 26114, 31105, 31105],
      74801: [27677, 27726, 25470, 26217, 31217, 31217],
      75001: [27795, 27848, 25598, 26320, 31329, 31329],
      75201: [27913, 27964, 25720, 26423, 31441, 31441],
      75401: [28031, 28081, 25843, 26526, 31553, 31553],
      75601: [28149, 28202, 25970, 26629, 31665, 31665],
      75801: [28267, 28319, 26093, 26732, 31777, 31777],
      76001: [28385, 28436, 26216, 26835, 31889, 31889],
      76201: [28503, 28557, 26343, 26939, 32001, 32001],
      76401: [28621, 28674, 26466, 27042, 32113, 32113],
      76601: [28739, 28795, 26593, 27145, 32225, 32225],
      76801: [28857, 28912, 26716, 27248, 32337, 32337],
      77001: [28975, 29028, 26838, 27351, 32449, 32449],
      77201: [29093, 29150, 26966, 27454, 32561, 32561],
      77401: [29211, 29266, 27088, 27557, 32673, 32673],
      77601: [29329, 29383, 27211, 27660, 32785, 32785],
      77801: [29447, 29504, 27338, 27763, 32897, 32897],
      78001: [29565, 29621, 27461, 27866, 33009, 33009],
      78201: [29683, 29742, 27588, 27969, 33121, 33121],
      78401: [29801, 29859, 27711, 28072, 33233, 33233],
      78601: [29919, 29976, 27834, 28176, 33345, 33345],
      78801: [30037, 30097, 27961, 28279, 33457, 33457],
      79001: [30155, 30214, 28084, 28382, 33569, 33569],
      79201: [30273, 30330, 28206, 28485, 33681, 33681],
      79401: [30391, 30452, 28334, 28588, 33793, 33793],
      79601: [30509, 30568, 28456, 28691, 33905, 33905],
      79801: [30627, 30690, 28584, 28794, 34017, 34017],
      80001: [38, 38, 36, 36, 43, 43, "%"],
      80401: [38, 39, 36, 36, 43, 43, "%"],
      80801: [39, 39, 36, 36, 43, 43, "%"],
      82401: [39, 39, 37, 36, 43, 43, "%"],
      82601: [39, 39, 37, 37, 43, 43, "%"],
      84601: [39, 40, 37, 37, 43, 43, "%"],
      84801: [40, 40, 37, 37, 43, 43, "%"],
      85601: [40, 40, 38, 37, 43, 43, "%"],
      86201: [40, 40, 38, 37, 44, 44, "%"],
      88401: [40, 40, 38, 38, 44, 44, "%"],
      89001: [40, 41, 38, 38, 44, 44, "%"],
      89201: [40, 41, 39, 38, 44, 44, "%"],
      89401: [41, 41, 39, 38, 44, 44, "%"],
      93201: [41, 41, 40, 38, 44, 44, "%"],
      93601: [41, 41, 40, 38, 45, 45, "%"],
      94001: [41, 42, 40, 38, 45, 45, "%"],
      94601: [42, 42, 40, 38, 45, 45, "%"],
      95201: [42, 42, 40, 39, 45, 45, "%"],
      97401: [42, 42, 41, 39, 45, 45, "%"],
      99801: [42, 43, 41, 39, 45, 45, "%"],
      100401: [43, 43, 41, 39, 45, 45, "%"],
      102201: [43, 43, 42, 39, 45, 45, "%"],
      102601: [43, 43, 42, 39, 46, 46, "%"],
      103201: [43, 43, 42, 40, 46, 46, "%"],
      106001: [43, 44, 42, 40, 46, 46, "%"],
      106801: [44, 44, 42, 40, 46, 46, "%"],
      107401: [44, 44, 43, 40, 46, 46, "%"],
      112601: [44, 44, 43, 41, 46, 46, "%"],
      113201: [44, 45, 44, 41, 46, 46, "%"],
      113401: [44, 45, 44, 41, 47, 47, "%"],
      114201: [45, 45, 44, 41, 47, 47, "%"],
      119601: [45, 45, 45, 41, 47, 47, "%"],
      121601: [45, 46, 45, 41, 47, 47, "%"],
      122601: [46, 46, 45, 41, 47, 47, "%"],
      123801: [46, 46, 45, 42, 47, 47, "%"],
      126601: [46, 46, 46, 42, 47, 47, "%"],
      126801: [46, 46, 46, 42, 48, 48, "%"],
      131201: [46, 47, 46, 42, 48, 48, "%"],
      132401: [47, 47, 46, 42, 48, 48, "%"],
      134801: [47, 47, 47, 42, 48, 48, "%"],
      137401: [47, 47, 47, 43, 48, 48, "%"],
      142401: [47, 48, 47, 43, 48, 48, "%"],
      143601: [47, 48, 47, 43, 49, 49, "%"],
      144001: [48, 48, 48, 43, 49, 49, "%"],
      154601: [48, 48, 48, 44, 49, 49, "%"],
      155601: [48, 49, 49, 44, 49, 49, "%"],
      157801: [49, 49, 49, 44, 49, 49, "%"],
      165801: [49, 49, 49, 44, 50, 50, "%"],
      171601: [49, 50, 50, 44, 50, 50, "%"],
      174401: [50, 50, 50, 44, 50, 50, "%"],
      176601: [50, 50, 50, 45, 50, 50, "%"],
      196001: [51, 51, 51, 45, 51, 51, "%"],
      205801: [51, 51, 51, 46, 51, 51, "%"],
      239601: [52, 52, 52, 46, 52, 52, "%"],
      246601: [52, 52, 52, 47, 52, 52, "%"],
      307801: [52, 52, 52, 48, 52, 52, "%"],
      308001: [53, 53, 53, 48, 53, 53, "%"],
      409001: [53, 53, 53, 49, 53, 53, "%"],
      431201: [54, 54, 54, 49, 54, 54, "%"],
      609401: [54, 54, 54, 50, 54, 54, "%"],
      718801: [55, 55, 55, 50, 55, 55, "%"],
      1195201: [55, 55, 55, 51, 55, 55, "%"]
    },
    37: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 28],
      2101: [153, 0, 150, 3, 153, 66],
      2201: [164, 0, 158, 5, 164, 104],
      2301: [174, 0, 166, 8, 174, 142],
      2401: [185, 0, 175, 10, 185, 180],
      2501: [195, 0, 183, 12, 218, 218],
      2601: [206, 0, 191, 14, 256, 256],
      2701: [216, 0, 200, 16, 294, 294],
      2801: [218, 0, 200, 18, 332, 332],
      2901: [229, 0, 208, 21, 370, 370],
      3001: [239, 0, 216, 23, 408, 408],
      3101: [250, 0, 225, 25, 446, 446],
      3201: [260, 0, 233, 27, 484, 484],
      3301: [271, 0, 241, 29, 522, 522],
      3401: [273, 0, 241, 31, 560, 560],
      3501: [284, 0, 250, 34, 598, 598],
      3601: [294, 0, 258, 36, 636, 636],
      3701: [305, 0, 266, 38, 674, 674],
      3801: [315, 0, 275, 40, 712, 712],
      3901: [325, 0, 283, 42, 750, 750],
      4001: [328, 0, 283, 44, 788, 788],
      4101: [338, 0, 291, 46, 826, 826],
      4201: [349, 0, 300, 49, 864, 864],
      4301: [359, 0, 308, 64, 902, 902],
      4401: [370, 0, 316, 90, 940, 940],
      4501: [380, 0, 325, 115, 978, 978],
      4601: [382, 0, 325, 141, 1016, 1016],
      4701: [392, 0, 333, 167, 1048, 1048],
      4801: [403, 0, 341, 192, 1079, 1079],
      4901: [413, 0, 350, 217, 1111, 1111],
      5001: [423, 0, 358, 243, 1139, 1139],
      5101: [433, 0, 366, 268, 1171, 1171],
      5201: [443, 0, 375, 293, 1200, 1200],
      5301: [445, 25, 376, 319, 1231, 1231],
      5401: [455, 53, 386, 344, 1263, 1263],
      5501: [465, 82, 396, 369, 1291, 1291],
      5601: [475, 110, 406, 394, 1322, 1322],
      5701: [485, 139, 416, 419, 1350, 1350],
      5801: [495, 167, 426, 443, 1381, 1381],
      5901: [497, 196, 427, 468, 1412, 1412],
      6001: [507, 224, 437, 493, 1440, 1440],
      6101: [517, 253, 447, 518, 1471, 1471],
      6201: [527, 278, 457, 542, 1499, 1499],
      6301: [537, 307, 467, 567, 1530, 1530],
      6401: [556, 335, 477, 592, 1561, 1561],
      6501: [579, 364, 479, 617, 1589, 1589],
      6601: [602, 392, 488, 642, 1620, 1620],
      6701: [625, 421, 498, 666, 1648, 1648],
      6801: [648, 449, 508, 691, 1679, 1679],
      6901: [671, 478, 518, 716, 1710, 1710],
      7001: [694, 506, 528, 740, 1738, 1738],
      7101: [717, 535, 530, 765, 1769, 1769],
      7201: [740, 563, 540, 790, 1797, 1797],
      7301: [764, 592, 550, 815, 1828, 1828],
      7401: [787, 617, 560, 840, 1859, 1859],
      7501: [810, 645, 570, 864, 1887, 1887],
      7601: [833, 674, 580, 889, 1918, 1918],
      7701: [856, 702, 589, 914, 1946, 1946],
      7801: [879, 731, 591, 939, 1977, 1977],
      7901: [902, 759, 601, 963, 2008, 2008],
      8001: [925, 788, 611, 988, 2036, 2036],
      8101: [948, 816, 621, 1013, 2067, 2067],
      8201: [971, 845, 631, 1038, 2095, 2095],
      8301: [994, 873, 641, 1063, 2126, 2126],
      8401: [1017, 902, 642, 1087, 2157, 2157],
      8501: [1040, 930, 652, 1112, 2185, 2185],
      8601: [1064, 956, 662, 1137, 2216, 2216],
      8701: [1087, 984, 672, 1161, 2244, 2244],
      8801: [1110, 1013, 682, 1186, 2275, 2275],
      8901: [1133, 1041, 692, 1211, 2306, 2306],
      9001: [1156, 1070, 694, 1236, 2334, 2334],
      9101: [1179, 1098, 704, 1261, 2365, 2365],
      9201: [1202, 1127, 714, 1285, 2393, 2393],
      9301: [1225, 1146, 723, 1310, 2424, 2424],
      9401: [1248, 1171, 733, 1335, 2455, 2455],
      9501: [1271, 1193, 742, 1360, 2483, 2483],
      9601: [1294, 1215, 744, 1384, 2514, 2514],
      9701: [1317, 1238, 753, 1409, 2542, 2542],
      9801: [1341, 1263, 763, 1434, 2573, 2573],
      9901: [1364, 1285, 773, 1459, 2604, 2604],
      10001: [1387, 1307, 782, 1483, 2632, 2632],
      10101: [1410, 1328, 792, 1508, 2663, 2663],
      10201: [1433, 1353, 802, 1533, 2691, 2691],
      10301: [1456, 1375, 803, 1558, 2722, 2722],
      10401: [1479, 1397, 812, 1583, 2753, 2753],
      10501: [1502, 1418, 822, 1607, 2781, 2781],
      10601: [1525, 1443, 832, 1632, 2812, 2812],
      10701: [1548, 1465, 841, 1657, 2840, 2840],
      10801: [1571, 1487, 851, 1682, 2871, 2871],
      10901: [1595, 1508, 852, 1706, 2902, 2902],
      11001: [1618, 1533, 862, 1731, 2930, 2930],
      11101: [1641, 1555, 872, 1756, 2961, 2961],
      11201: [1664, 1577, 881, 1781, 2989, 2989],
      11301: [1687, 1601, 891, 1805, 3020, 3020],
      11401: [1710, 1623, 901, 1830, 3051, 3051],
      11501: [1733, 1645, 902, 1855, 3079, 3079],
      11601: [1756, 1667, 911, 1880, 3110, 3110],
      11701: [1779, 1691, 921, 1904, 3138, 3138],
      11801: [1802, 1713, 931, 1929, 3169, 3169],
      11901: [1825, 1735, 940, 1954, 3200, 3200],
      12001: [1848, 1757, 950, 1979, 3228, 3228],
      12101: [1872, 1781, 951, 2004, 3259, 3259],
      12201: [1895, 1803, 961, 2028, 3287, 3287],
      12301: [1918, 1825, 971, 2053, 3318, 3318],
      12401: [1941, 1847, 980, 2078, 3349, 3349],
      12501: [1964, 1871, 990, 2103, 3377, 3377],
      12601: [1987, 1893, 1e3, 2127, 3408, 3408],
      12701: [2010, 1915, 1009, 2152, 3436, 3436],
      12801: [2033, 1937, 1010, 2177, 3467, 3467],
      12901: [2056, 1961, 1020, 2202, 3495, 3495],
      13001: [2079, 1983, 1030, 2227, 3532, 3532],
      13101: [2103, 2005, 1039, 2252, 3569, 3569],
      13201: [2126, 2030, 1049, 2277, 3607, 3607],
      13301: [2150, 2052, 1059, 2302, 3644, 3644],
      13401: [2173, 2073, 1060, 2327, 3681, 3681],
      13501: [2196, 2095, 1070, 2352, 3718, 3718],
      13601: [2220, 2120, 1079, 2377, 3756, 3756],
      13701: [2243, 2142, 1089, 2402, 3793, 3793],
      13801: [2266, 2163, 1099, 2426, 3830, 3830],
      13901: [2288, 2185, 1108, 2450, 3866, 3866],
      14001: [2311, 2210, 1110, 2475, 3902, 3902],
      14101: [2333, 2232, 1119, 2499, 3938, 3938],
      14201: [2355, 2253, 1129, 2523, 3975, 3975],
      14301: [2378, 2275, 1138, 2547, 4011, 4011],
      14401: [2400, 2300, 1148, 2571, 4047, 4047],
      14501: [2422, 2322, 1158, 2595, 4083, 4083],
      14601: [2445, 2343, 1159, 2619, 4120, 4120],
      14701: [2467, 2365, 1169, 2643, 4156, 4156],
      14801: [2489, 2390, 1178, 2667, 4195, 4195],
      14901: [2512, 2412, 1188, 2691, 4234, 4234],
      15001: [2534, 2433, 1198, 2715, 4274, 4274],
      15101: [2557, 2455, 1207, 2739, 4313, 4313],
      15201: [2579, 2480, 1217, 2763, 4355, 4355],
      15301: [2602, 2502, 1218, 2787, 4395, 4395],
      15401: [2626, 2526, 1228, 2814, 4434, 4434],
      15501: [2656, 2557, 1238, 2846, 4473, 4473],
      15601: [2686, 2589, 1248, 2878, 4512, 4512],
      15701: [2717, 2620, 1258, 2909, 4555, 4555],
      15801: [2747, 2651, 1268, 2941, 4594, 4594],
      15901: [2778, 2682, 1270, 2973, 4633, 4633],
      16001: [2808, 2713, 1280, 3005, 4672, 4672],
      16101: [2838, 2744, 1291, 3036, 4712, 4712],
      16201: [2869, 2775, 1301, 3068, 4754, 4754],
      16301: [2899, 2806, 1311, 3100, 4793, 4793],
      16401: [2930, 2837, 1321, 3131, 4833, 4833],
      16501: [2960, 2868, 1323, 3163, 4872, 4872],
      16601: [2990, 2899, 1333, 3195, 4911, 4911],
      16701: [3021, 2930, 1343, 3226, 4953, 4953],
      16801: [3051, 2961, 1353, 3258, 4993, 4993],
      16901: [3082, 2992, 1363, 3290, 5032, 5032],
      17001: [3112, 3020, 1373, 3322, 5071, 5071],
      17101: [3143, 3051, 1375, 3353, 5111, 5111],
      17201: [3173, 3082, 1385, 3385, 5153, 5153],
      17301: [3203, 3113, 1395, 3417, 5192, 5192],
      17401: [3234, 3144, 1405, 3448, 5231, 5231],
      17501: [3264, 3175, 1416, 3480, 5271, 5271],
      17601: [3294, 3206, 1426, 3512, 5310, 5310],
      17701: [3325, 3237, 1436, 3543, 5352, 5352],
      17801: [3355, 3268, 1438, 3575, 5391, 5391],
      17901: [3386, 3299, 1448, 3607, 5431, 5431],
      18001: [3416, 3330, 1458, 3639, 5470, 5470],
      18101: [3446, 3362, 1468, 3670, 5509, 5509],
      18201: [3477, 3393, 1478, 3702, 5551, 5551],
      18301: [3507, 3424, 1488, 3734, 5591, 5591],
      18401: [3538, 3455, 1490, 3765, 5630, 5630],
      18501: [3568, 3486, 1500, 3797, 5669, 5669],
      18601: [3598, 3517, 1510, 3829, 5709, 5709],
      18701: [3629, 3548, 1520, 3860, 5751, 5751],
      18801: [3659, 3579, 1531, 3892, 5790, 5790],
      18901: [3690, 3610, 1541, 3924, 5829, 5829],
      19001: [3720, 3641, 1543, 3956, 5869, 5869],
      19101: [3751, 3672, 1553, 3987, 5908, 5908],
      19201: [3781, 3703, 1563, 4019, 5950, 5950],
      19301: [3811, 3734, 1573, 4051, 5990, 5990],
      19401: [3842, 3765, 1583, 4082, 6029, 6029],
      19501: [3872, 3796, 1593, 4114, 6068, 6068],
      19601: [3902, 3827, 1595, 4146, 6107, 6107],
      19701: [3933, 3857, 1604, 4178, 6150, 6150],
      19801: [3963, 3887, 1613, 4209, 6189, 6189],
      19901: [3994, 3917, 1622, 4241, 6228, 6228],
      20001: [4055, 3978, 1641, 4304, 6307, 6307],
      20201: [4115, 4035, 1651, 4368, 6388, 6388],
      20401: [4176, 4095, 1670, 4431, 6467, 6467],
      20601: [4237, 4156, 1688, 4495, 6548, 6548],
      20801: [4298, 4216, 1699, 4558, 6627, 6627],
      21001: [4359, 4277, 1717, 4621, 6705, 6705],
      21201: [4419, 4337, 1736, 4685, 6787, 6787],
      21401: [4480, 4398, 1746, 4748, 6866, 6866],
      21601: [4541, 4458, 1765, 4812, 6947, 6947],
      21801: [4602, 4518, 1783, 4875, 7026, 7026],
      22001: [4663, 4579, 1794, 4938, 7104, 7104],
      22201: [4723, 4639, 1813, 5002, 7186, 7186],
      22401: [4784, 4700, 1859, 5065, 7264, 7264],
      22601: [4845, 4760, 1906, 5129, 7346, 7346],
      22801: [4907, 4820, 1952, 5193, 7425, 7425],
      23001: [4969, 4881, 1999, 5258, 7506, 7506],
      23201: [5032, 4941, 2045, 5323, 7589, 7589],
      23401: [5094, 4999, 2089, 5388, 7669, 7669],
      23601: [5157, 5059, 2135, 5453, 7752, 7752],
      23801: [5219, 5116, 2178, 5518, 7832, 7832],
      24001: [5281, 5180, 2228, 5583, 7913, 7913],
      24201: [5344, 5240, 2274, 5648, 7996, 7996],
      24401: [5406, 5304, 2324, 5713, 8076, 8076],
      24601: [5469, 5364, 2370, 5779, 8159, 8159],
      24801: [5531, 5425, 2416, 5844, 8239, 8239],
      25001: [5594, 5488, 2483, 5909, 8320, 8320],
      25201: [5656, 5548, 2544, 5974, 8403, 8403],
      25401: [5719, 5609, 2604, 6039, 8483, 8483],
      25601: [5781, 5672, 2667, 6104, 8566, 8566],
      25801: [5844, 5733, 2728, 6169, 8646, 8646],
      26001: [5906, 5793, 2788, 6234, 8727, 8727],
      26201: [5969, 5857, 2852, 6299, 8810, 8810],
      26401: [6031, 5917, 2912, 6364, 8890, 8890],
      26601: [6093, 5980, 2976, 6429, 8973, 8973],
      26801: [6156, 6041, 3036, 6494, 9053, 9053],
      27001: [6218, 6101, 3096, 6559, 9134, 9134],
      27201: [6281, 6165, 3160, 6624, 9217, 9217],
      27401: [6343, 6225, 3220, 6689, 9297, 9297],
      27601: [6406, 6286, 3281, 6754, 9380, 9380],
      27801: [6468, 6349, 3344, 6819, 9460, 9460],
      28001: [6531, 6409, 3405, 6884, 9541, 9541],
      28201: [6593, 6473, 3468, 6950, 9624, 9624],
      28401: [6656, 6533, 3528, 7015, 9704, 9704],
      28601: [6718, 6594, 3589, 7080, 9787, 9787],
      28801: [6780, 6657, 3652, 7145, 9867, 9867],
      29001: [6843, 6718, 3713, 7210, 9948, 9948],
      29201: [6905, 6778, 3773, 7275, 10031, 10031],
      29401: [6968, 6841, 3837, 7340, 10111, 10111],
      29601: [7030, 6902, 3897, 7405, 10194, 10194],
      29801: [7093, 6965, 3960, 7470, 10274, 10274],
      30001: [7155, 7026, 4021, 7535, 10355, 10355],
      30201: [7218, 7086, 4081, 7600, 10438, 10438],
      30401: [7280, 7150, 4145, 7665, 10518, 10518],
      30601: [7343, 7210, 4205, 7730, 10601, 10601],
      30801: [7405, 7270, 4266, 7795, 10681, 10681],
      31001: [7467, 7334, 4329, 7860, 10762, 10762],
      31201: [7530, 7395, 4390, 7925, 10845, 10845],
      31401: [7592, 7456, 4451, 7990, 10925, 10925],
      31601: [7655, 7521, 4516, 8056, 11008, 11008],
      31801: [7717, 7583, 4578, 8121, 11088, 11088],
      32001: [7780, 7647, 4643, 8186, 11169, 11169],
      32201: [7842, 7709, 4704, 8251, 11252, 11252],
      32401: [7905, 7771, 4766, 8316, 11332, 11332],
      32601: [7967, 7835, 4831, 8381, 11415, 11415],
      32801: [8030, 7897, 4892, 8446, 11495, 11495],
      33001: [8092, 7959, 4954, 8511, 11576, 11576],
      33201: [8154, 8024, 5019, 8576, 11659, 11659],
      33401: [8217, 8085, 5080, 8641, 11739, 11739],
      33601: [8279, 8150, 5145, 8706, 11822, 11822],
      33801: [8342, 8212, 5207, 8771, 11902, 11902],
      34001: [8404, 8273, 5268, 8836, 11983, 11983],
      34201: [8467, 8338, 5333, 8901, 12066, 12066],
      34401: [8529, 8400, 5395, 8966, 12146, 12146],
      34601: [8592, 8461, 5457, 9031, 12229, 12229],
      34801: [8654, 8526, 5521, 9096, 12309, 12309],
      35001: [8717, 8588, 5583, 9161, 12390, 12390],
      35201: [8779, 8649, 5645, 9227, 12473, 12473],
      35401: [8841, 8714, 5709, 9292, 12553, 12553],
      35601: [8904, 8776, 5771, 9357, 12636, 12636],
      35801: [8966, 8841, 5836, 9422, 12716, 12716],
      36001: [9029, 8902, 5897, 9487, 12797, 12797],
      36201: [9091, 8964, 5959, 9552, 12880, 12880],
      36401: [9154, 9029, 6024, 9617, 12960, 12960],
      36601: [9216, 9090, 6086, 9682, 13043, 13043],
      36801: [9279, 9152, 6147, 9747, 13123, 13123],
      37001: [9341, 9217, 6212, 9812, 13204, 13204],
      37201: [9404, 9278, 6274, 9877, 13287, 13287],
      37401: [9466, 9343, 6338, 9942, 13367, 13367],
      37601: [9528, 9402, 6397, 10007, 13444, 13444],
      37801: [9591, 9463, 6459, 10072, 13518, 13518],
      38001: [9653, 9525, 6520, 10137, 13592, 13592],
      38201: [9715, 9590, 6585, 10202, 13666, 13666],
      38401: [9779, 9652, 6647, 10266, 13740, 13740],
      38601: [9853, 9726, 6721, 10331, 13814, 13814],
      38801: [9927, 9800, 6795, 10396, 13888, 13888],
      39001: [10001, 9874, 6869, 10461, 13962, 13962],
      39201: [10075, 9948, 6943, 10526, 14036, 14036],
      39401: [10149, 10022, 7017, 10590, 14110, 14110],
      39601: [10223, 10096, 7091, 10655, 14184, 14184],
      39801: [10297, 10170, 7165, 10720, 14258, 14258],
      40001: [10371, 10244, 7239, 10785, 14332, 14332],
      40201: [10445, 10318, 7313, 10850, 14406, 14406],
      40401: [10519, 10392, 7387, 10915, 14480, 14480],
      40601: [10593, 10466, 7461, 10979, 14554, 14554],
      40801: [10667, 10540, 7535, 11044, 14628, 14628],
      41001: [10741, 10614, 7609, 11109, 14702, 14702],
      41201: [10815, 10688, 7683, 11174, 14776, 14776],
      41401: [10889, 10762, 7757, 11239, 14850, 14850],
      41601: [10963, 10836, 7831, 11304, 14924, 14924],
      41801: [11037, 10910, 7905, 11368, 14998, 14998],
      42001: [11111, 10984, 7979, 11433, 15072, 15072],
      42201: [11185, 11058, 8053, 11498, 15146, 15146],
      42401: [11259, 11132, 8127, 11563, 15220, 15220],
      42601: [11333, 11206, 8201, 11628, 15294, 15294],
      42801: [11407, 11280, 8275, 11692, 15368, 15368],
      43001: [11481, 11354, 8349, 11757, 15442, 15442],
      43201: [11555, 11428, 8423, 11822, 15516, 15516],
      43401: [11629, 11502, 8497, 11887, 15590, 15590],
      43601: [11703, 11576, 8571, 11952, 15664, 15664],
      43801: [11777, 11650, 8645, 12017, 15738, 15738],
      44001: [11851, 11724, 8719, 12081, 15812, 15812],
      44201: [11925, 11798, 8793, 12146, 15886, 15886],
      44401: [11999, 11872, 8867, 12211, 15960, 15960],
      44601: [12073, 11946, 8941, 12276, 16034, 16034],
      44801: [12147, 12020, 9015, 12341, 16108, 16108],
      45001: [12221, 12094, 9089, 12406, 16182, 16182],
      45201: [12295, 12168, 9163, 12470, 16256, 16256],
      45401: [12369, 12242, 9237, 12535, 16330, 16330],
      45601: [12443, 12316, 9311, 12600, 16404, 16404],
      45801: [12517, 12390, 9385, 12665, 16478, 16478],
      46001: [12591, 12464, 9459, 12730, 16552, 16552],
      46201: [12665, 12538, 9533, 12795, 16626, 16626],
      46401: [12739, 12612, 9607, 12859, 16700, 16700],
      46601: [12813, 12686, 9681, 12924, 16774, 16774],
      46801: [12887, 12760, 9755, 12989, 16848, 16848],
      47001: [12961, 12834, 9829, 13054, 16922, 16922],
      47201: [13035, 12908, 9903, 13119, 16996, 16996],
      47401: [13109, 12982, 9977, 13183, 17070, 17070],
      47601: [13183, 13056, 10051, 13248, 17144, 17144],
      47801: [13257, 13130, 10125, 13313, 17218, 17218],
      48001: [13331, 13204, 10199, 13378, 17292, 17292],
      48201: [13405, 13278, 10273, 13443, 17366, 17366],
      48401: [13479, 13352, 10347, 13508, 17440, 17440],
      48601: [13553, 13426, 10421, 13572, 17514, 17514],
      48801: [13627, 13500, 10495, 13637, 17588, 17588],
      49001: [13701, 13574, 10569, 13702, 17662, 17662],
      49201: [13775, 13648, 10643, 13767, 17736, 17736],
      49401: [13849, 13722, 10717, 13832, 17810, 17810],
      49601: [13923, 13796, 10791, 13897, 17884, 17884],
      49801: [13997, 13870, 10865, 13961, 17958, 17958],
      50001: [14071, 13944, 10943, 14026, 18032, 18032],
      50201: [14145, 14018, 11023, 14091, 18106, 18106],
      50401: [14219, 14092, 11103, 14156, 18180, 18180],
      50601: [14293, 14166, 11183, 14221, 18254, 18254],
      50801: [14367, 14240, 11263, 14285, 18328, 18328],
      51001: [14441, 14314, 11343, 14350, 18402, 18402],
      51201: [14540, 14415, 11451, 14440, 18501, 18501],
      51401: [14654, 14536, 11577, 14545, 18615, 18615],
      51601: [14768, 14656, 11704, 14650, 18729, 18729],
      51801: [14882, 14773, 11827, 14755, 18843, 18843],
      52001: [14996, 14893, 11953, 14859, 18957, 18957],
      52201: [15110, 15013, 12079, 14964, 19071, 19071],
      52401: [15224, 15134, 12206, 15069, 19185, 19185],
      52601: [15338, 15254, 12332, 15174, 19299, 19299],
      52801: [15452, 15374, 12458, 15279, 19413, 19413],
      53001: [15566, 15494, 12584, 15384, 19527, 19527],
      53201: [15680, 15615, 12711, 15488, 19641, 19641],
      53401: [15794, 15732, 12834, 15593, 19755, 19755],
      53601: [15908, 15852, 12960, 15698, 19869, 19869],
      53801: [16022, 15972, 13086, 15803, 19983, 19983],
      54001: [16136, 16093, 13212, 15908, 20097, 20097],
      54201: [16250, 16213, 13339, 16013, 20211, 20211],
      54401: [16364, 16333, 13465, 16117, 20325, 20325],
      54601: [16478, 16453, 13591, 16222, 20439, 20439],
      54801: [16592, 16574, 13717, 16327, 20553, 20553],
      55001: [16706, 16694, 13844, 16432, 20667, 20667],
      55201: [16820, 16811, 13967, 16537, 20781, 20781],
      55401: [16934, 16931, 14093, 16641, 20895, 20895],
      55601: [17048, 17052, 14219, 16746, 21009, 21009],
      55801: [17162, 17172, 14346, 16851, 21123, 21123],
      56001: [17276, 17292, 14472, 16956, 21237, 21237],
      56201: [17390, 17412, 14598, 17061, 21351, 21351],
      56401: [17504, 17533, 14724, 17166, 21465, 21465],
      56601: [17618, 17653, 14851, 17270, 21579, 21579],
      56801: [17732, 17770, 14974, 17375, 21693, 21693],
      57001: [17846, 17890, 15100, 17480, 21807, 21807],
      57201: [17960, 18010, 15226, 17585, 21921, 21921],
      57401: [18074, 18131, 15353, 17690, 22035, 22035],
      57601: [18188, 18251, 15479, 17795, 22149, 22149],
      57801: [18302, 18371, 15605, 17899, 22263, 22263],
      58001: [18416, 18482, 15722, 18004, 22377, 22377],
      58201: [18530, 18596, 15842, 18109, 22491, 22491],
      58401: [18644, 18710, 15962, 18214, 22605, 22605],
      58601: [18758, 18824, 16082, 18319, 22719, 22719],
      58801: [18872, 18938, 16202, 18423, 22833, 22833],
      59001: [18986, 19052, 16322, 18528, 22947, 22947],
      59201: [19100, 19166, 16442, 18633, 23061, 23061],
      59401: [19214, 19280, 16562, 18738, 23175, 23175],
      59601: [19328, 19394, 16682, 18843, 23289, 23289],
      59801: [19442, 19508, 16802, 18948, 23403, 23403],
      60001: [19556, 19622, 16922, 19052, 23517, 23517],
      60201: [19670, 19736, 17042, 19157, 23631, 23631],
      60401: [19784, 19850, 17162, 19262, 23745, 23745],
      60601: [19898, 19964, 17282, 19367, 23859, 23859],
      60801: [20012, 20078, 17402, 19472, 23973, 23973],
      61001: [20126, 20192, 17522, 19577, 24087, 24087],
      61201: [20240, 20306, 17642, 19681, 24201, 24201],
      61401: [20354, 20420, 17762, 19786, 24315, 24315],
      61601: [20468, 20534, 17882, 19891, 24429, 24429],
      61801: [20582, 20648, 18002, 19996, 24543, 24543],
      62001: [20696, 20762, 18122, 20101, 24657, 24657],
      62201: [20810, 20876, 18242, 20206, 24771, 24771],
      62401: [20924, 20990, 18362, 20310, 24885, 24885],
      62601: [21038, 21104, 18482, 20415, 24999, 24999],
      62801: [21152, 21218, 18602, 20520, 25113, 25113],
      63001: [21266, 21332, 18722, 20625, 25227, 25227],
      63201: [21380, 21446, 18842, 20730, 25341, 25341],
      63401: [21494, 21560, 18962, 20834, 25455, 25455],
      63601: [21608, 21674, 19082, 20939, 25569, 25569],
      63801: [21722, 21788, 19202, 21044, 25683, 25683],
      64001: [21836, 21902, 19322, 21149, 25797, 25797],
      64201: [21950, 22016, 19442, 21254, 25911, 25911],
      64401: [22064, 22130, 19562, 21359, 26025, 26025],
      64601: [22182, 22254, 19691, 21463, 26139, 26139],
      64801: [22302, 22372, 19816, 21568, 26253, 26253],
      65001: [22422, 22496, 19946, 21673, 26367, 26367],
      65201: [22542, 22615, 20070, 21778, 26481, 26481],
      65401: [22662, 22733, 20195, 21883, 26595, 26595],
      65601: [22782, 22857, 20325, 21988, 26709, 26709],
      65801: [22902, 22976, 20449, 22092, 26823, 26823],
      66001: [23022, 23099, 20579, 22197, 26937, 26937],
      66201: [23142, 23218, 20704, 22302, 27051, 27051],
      66401: [23262, 23337, 20828, 22407, 27165, 27165],
      66601: [23382, 23460, 20958, 22512, 27279, 27279],
      66801: [23502, 23579, 21083, 22616, 27393, 27393],
      67001: [23622, 23698, 21207, 22721, 27507, 27507],
      67201: [23742, 23821, 21337, 22826, 27621, 27621],
      67401: [23862, 23940, 21462, 22931, 27735, 27735],
      67601: [23982, 24063, 21591, 23036, 27849, 27849],
      67801: [24102, 24182, 21716, 23141, 27963, 27963],
      68001: [24222, 24301, 21841, 23245, 28077, 28077],
      68201: [24342, 24424, 21970, 23350, 28191, 28191],
      68401: [24462, 24543, 22095, 23455, 28305, 28305],
      68601: [24582, 24662, 22220, 23560, 28419, 28419],
      68801: [24702, 24785, 22349, 23665, 28533, 28533],
      69001: [24822, 24904, 22474, 23770, 28647, 28647],
      69201: [24942, 25028, 22603, 23874, 28761, 28761],
      69401: [25062, 25146, 22728, 23979, 28875, 28875],
      69601: [25182, 25265, 22853, 24084, 28989, 28989],
      69801: [25302, 25389, 22982, 24189, 29103, 29103],
      70001: [25422, 25507, 23107, 24294, 29217, 29217],
      70201: [25542, 25626, 23232, 24399, 29331, 29331],
      70401: [25662, 25750, 23361, 24503, 29445, 29445],
      70601: [25782, 25868, 23486, 24608, 29559, 29559],
      70801: [25902, 25992, 23616, 24713, 29673, 29673],
      71001: [26022, 26111, 23740, 24818, 29787, 29787],
      71201: [26142, 26229, 23865, 24923, 29901, 29901],
      71401: [26262, 26353, 23995, 25027, 30015, 30015],
      71601: [26382, 26472, 24119, 25132, 30129, 30129],
      71801: [26502, 26595, 24249, 25237, 30243, 30243],
      72001: [26622, 26714, 24374, 25342, 30357, 30357],
      72201: [26742, 26833, 24498, 25447, 30471, 30471],
      72401: [26862, 26956, 24628, 25552, 30585, 30585],
      72601: [26982, 27075, 24753, 25656, 30699, 30699],
      72801: [27102, 27194, 24877, 25761, 30813, 30813],
      73001: [27222, 27317, 25007, 25866, 30927, 30927],
      73201: [27342, 27436, 25132, 25971, 31041, 31041],
      73401: [27462, 27559, 25261, 26076, 31155, 31155],
      73601: [27582, 27678, 25386, 26181, 31269, 31269],
      73801: [27702, 27797, 25511, 26285, 31383, 31383],
      74001: [27822, 27920, 25640, 26390, 31497, 31497],
      74201: [27942, 28039, 25765, 26495, 31611, 31611],
      74401: [28062, 28158, 25890, 26600, 31725, 31725],
      74601: [28182, 28281, 26019, 26705, 31839, 31839],
      74801: [28302, 28400, 26144, 26809, 31953, 31953],
      75001: [28422, 28524, 26273, 26914, 32067, 32067],
      75201: [28542, 28642, 26398, 27019, 32181, 32181],
      75401: [28662, 28761, 26523, 27124, 32295, 32295],
      75601: [28782, 28885, 26652, 27229, 32409, 32409],
      75801: [28902, 29003, 26777, 27334, 32523, 32523],
      76001: [29022, 29122, 26902, 27438, 32637, 32637],
      76201: [29142, 29246, 27031, 27543, 32751, 32751],
      76401: [29262, 29364, 27156, 27648, 32865, 32865],
      76601: [29382, 29488, 27286, 27753, 32979, 32979],
      76801: [29502, 29607, 27410, 27858, 33093, 33093],
      77001: [29622, 29725, 27535, 27963, 33207, 33207],
      77201: [29742, 29849, 27665, 28067, 33321, 33321],
      77401: [29862, 29968, 27789, 28172, 33435, 33435],
      77601: [29982, 30086, 27914, 28277, 33549, 33549],
      77801: [30102, 30210, 28044, 28382, 33663, 33663],
      78001: [30222, 30329, 28168, 28487, 33777, 33777],
      78201: [30342, 30452, 28298, 28592, 33891, 33891],
      78401: [30462, 30571, 28423, 28696, 34005, 34005],
      78601: [30582, 30690, 28547, 28801, 34119, 34119],
      78801: [30702, 30813, 28677, 28906, 34233, 34233],
      79001: [30822, 30932, 28802, 29011, 34347, 34347],
      79201: [30942, 31051, 28926, 29116, 34461, 34461],
      79401: [31062, 31174, 29056, 29220, 34575, 34575],
      79601: [31182, 31293, 29181, 29325, 34689, 34689],
      79801: [31302, 31416, 29310, 29430, 34803, 34803],
      80001: [39, 39, 37, 37, 44, 44, "%"],
      80801: [39, 40, 37, 37, 44, 44, "%"],
      81401: [40, 40, 37, 37, 44, 44, "%"],
      82601: [40, 40, 38, 37, 44, 44, "%"],
      83801: [40, 40, 38, 38, 44, 44, "%"],
      84801: [40, 41, 38, 38, 44, 44, "%"],
      85601: [41, 41, 38, 38, 44, 44, "%"],
      86001: [41, 41, 39, 38, 44, 44, "%"],
      86201: [41, 41, 39, 38, 45, 45, "%"],
      89401: [41, 42, 39, 38, 45, 45, "%"],
      89601: [41, 42, 40, 38, 45, 45, "%"],
      89801: [41, 42, 40, 39, 45, 45, "%"],
      90201: [42, 42, 40, 39, 45, 45, "%"],
      93401: [42, 42, 41, 39, 45, 45, "%"],
      93801: [42, 42, 41, 39, 46, 46, "%"],
      94401: [42, 43, 41, 39, 46, 46, "%"],
      95401: [43, 43, 41, 39, 46, 46, "%"],
      96801: [43, 43, 41, 40, 46, 46, "%"],
      97801: [43, 43, 42, 40, 46, 46, "%"],
      100001: [43, 44, 42, 40, 46, 46, "%"],
      101001: [44, 44, 42, 40, 46, 46, "%"],
      102401: [44, 44, 43, 40, 46, 46, "%"],
      102801: [44, 44, 43, 40, 47, 47, "%"],
      104801: [44, 44, 43, 41, 47, 47, "%"],
      106401: [44, 45, 43, 41, 47, 47, "%"],
      107601: [45, 45, 44, 41, 47, 47, "%"],
      113401: [45, 45, 45, 41, 47, 47, "%"],
      113601: [45, 46, 45, 41, 48, 48, "%"],
      114401: [45, 46, 45, 42, 48, 48, "%"],
      115001: [46, 46, 45, 42, 48, 48, "%"],
      119801: [46, 46, 46, 42, 48, 48, "%"],
      122001: [46, 47, 46, 42, 48, 48, "%"],
      123601: [47, 47, 46, 42, 48, 48, "%"],
      126001: [47, 47, 46, 43, 48, 48, "%"],
      127001: [47, 47, 47, 43, 49, 49, "%"],
      131401: [47, 48, 47, 43, 49, 49, "%"],
      133401: [48, 48, 47, 43, 49, 49, "%"],
      135001: [48, 48, 48, 43, 49, 49, "%"],
      140201: [48, 48, 48, 44, 49, 49, "%"],
      142601: [48, 49, 48, 44, 49, 49, "%"],
      143801: [48, 49, 48, 44, 50, 50, "%"],
      144201: [48, 49, 49, 44, 50, 50, "%"],
      145001: [49, 49, 49, 44, 50, 50, "%"],
      155801: [49, 50, 50, 44, 50, 50, "%"],
      157801: [49, 50, 50, 45, 50, 50, "%"],
      159001: [50, 50, 50, 45, 50, 50, "%"],
      166001: [50, 50, 50, 45, 51, 51, "%"],
      171801: [50, 51, 51, 45, 51, 51, "%"],
      175601: [51, 51, 51, 45, 51, 51, "%"],
      180801: [51, 51, 51, 46, 51, 51, "%"],
      196201: [51, 52, 52, 46, 52, 52, "%"],
      196401: [52, 52, 52, 46, 52, 52, "%"],
      211401: [52, 52, 52, 47, 52, 52, "%"],
      239801: [53, 53, 53, 47, 53, 53, "%"],
      254401: [53, 53, 53, 48, 53, 53, "%"],
      308401: [54, 54, 54, 48, 54, 54, "%"],
      319401: [54, 54, 54, 49, 54, 54, "%"],
      429001: [54, 54, 54, 50, 54, 54, "%"],
      431801: [55, 55, 55, 50, 55, 55, "%"],
      653401: [55, 55, 55, 51, 55, 55, "%"],
      719601: [56, 56, 56, 51, 56, 56, "%"],
      1369801: [56, 56, 56, 52, 56, 56, "%"]
    },
    38: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 29],
      2101: [153, 0, 150, 3, 153, 68],
      2201: [164, 0, 158, 5, 164, 107],
      2301: [174, 0, 166, 8, 174, 146],
      2401: [185, 0, 175, 10, 185, 185],
      2501: [195, 0, 183, 12, 224, 224],
      2601: [206, 0, 191, 14, 263, 263],
      2701: [216, 0, 200, 16, 302, 302],
      2801: [218, 0, 200, 18, 341, 341],
      2901: [229, 0, 208, 21, 380, 380],
      3001: [239, 0, 216, 23, 419, 419],
      3101: [250, 0, 225, 25, 458, 458],
      3201: [260, 0, 233, 27, 497, 497],
      3301: [271, 0, 241, 29, 536, 536],
      3401: [273, 0, 241, 31, 575, 575],
      3501: [284, 0, 250, 34, 614, 614],
      3601: [294, 0, 258, 36, 653, 653],
      3701: [305, 0, 266, 38, 692, 692],
      3801: [315, 0, 275, 40, 731, 731],
      3901: [325, 0, 283, 42, 770, 770],
      4001: [328, 0, 283, 44, 809, 809],
      4101: [338, 0, 291, 46, 848, 848],
      4201: [349, 0, 300, 49, 887, 887],
      4301: [359, 0, 308, 64, 926, 926],
      4401: [370, 0, 316, 91, 965, 965],
      4501: [380, 0, 325, 117, 1004, 1004],
      4601: [382, 0, 325, 143, 1043, 1043],
      4701: [392, 0, 333, 170, 1075, 1075],
      4801: [403, 0, 341, 196, 1108, 1108],
      4901: [413, 0, 350, 222, 1140, 1140],
      5001: [423, 0, 358, 248, 1169, 1169],
      5101: [433, 0, 366, 274, 1202, 1202],
      5201: [443, 0, 375, 300, 1231, 1231],
      5301: [445, 25, 376, 326, 1264, 1264],
      5401: [455, 55, 386, 352, 1296, 1296],
      5501: [465, 84, 396, 377, 1325, 1325],
      5601: [475, 113, 406, 403, 1357, 1357],
      5701: [485, 142, 416, 428, 1386, 1386],
      5801: [495, 172, 426, 454, 1417, 1417],
      5901: [497, 201, 427, 479, 1449, 1449],
      6001: [507, 230, 437, 504, 1478, 1478],
      6101: [517, 259, 447, 530, 1510, 1510],
      6201: [527, 285, 457, 555, 1539, 1539],
      6301: [545, 315, 467, 581, 1570, 1570],
      6401: [569, 344, 477, 606, 1602, 1602],
      6501: [593, 373, 479, 632, 1631, 1631],
      6601: [616, 402, 488, 657, 1663, 1663],
      6701: [640, 432, 498, 682, 1692, 1692],
      6801: [664, 461, 508, 708, 1723, 1723],
      6901: [687, 490, 518, 733, 1755, 1755],
      7001: [711, 519, 528, 759, 1784, 1784],
      7101: [735, 549, 530, 784, 1816, 1816],
      7201: [758, 578, 540, 809, 1845, 1845],
      7301: [782, 607, 550, 835, 1876, 1876],
      7401: [806, 633, 560, 860, 1908, 1908],
      7501: [830, 662, 570, 886, 1937, 1937],
      7601: [853, 692, 580, 911, 1969, 1969],
      7701: [877, 721, 589, 937, 1998, 1998],
      7801: [901, 750, 591, 962, 2029, 2029],
      7901: [924, 779, 601, 988, 2061, 2061],
      8001: [948, 809, 611, 1013, 2090, 2090],
      8101: [972, 838, 621, 1038, 2122, 2122],
      8201: [995, 867, 631, 1064, 2151, 2151],
      8301: [1019, 896, 641, 1089, 2182, 2182],
      8401: [1043, 926, 642, 1115, 2214, 2214],
      8501: [1066, 955, 652, 1140, 2243, 2243],
      8601: [1090, 981, 662, 1166, 2275, 2275],
      8701: [1114, 1010, 672, 1191, 2304, 2304],
      8801: [1138, 1039, 682, 1216, 2336, 2336],
      8901: [1161, 1069, 692, 1242, 2367, 2367],
      9001: [1185, 1098, 694, 1267, 2396, 2396],
      9101: [1209, 1127, 704, 1293, 2428, 2428],
      9201: [1232, 1156, 714, 1318, 2457, 2457],
      9301: [1256, 1176, 723, 1343, 2488, 2488],
      9401: [1280, 1202, 733, 1369, 2520, 2520],
      9501: [1303, 1225, 742, 1394, 2549, 2549],
      9601: [1327, 1247, 744, 1420, 2581, 2581],
      9701: [1351, 1270, 753, 1445, 2610, 2610],
      9801: [1375, 1296, 763, 1471, 2641, 2641],
      9901: [1398, 1319, 773, 1496, 2673, 2673],
      10001: [1422, 1341, 782, 1521, 2702, 2702],
      10101: [1446, 1363, 792, 1547, 2734, 2734],
      10201: [1469, 1389, 802, 1572, 2763, 2763],
      10301: [1493, 1411, 803, 1598, 2794, 2794],
      10401: [1517, 1433, 812, 1623, 2826, 2826],
      10501: [1540, 1456, 822, 1648, 2855, 2855],
      10601: [1564, 1481, 832, 1674, 2887, 2887],
      10701: [1588, 1503, 841, 1699, 2916, 2916],
      10801: [1612, 1526, 851, 1725, 2947, 2947],
      10901: [1635, 1548, 852, 1750, 2979, 2979],
      11001: [1659, 1574, 862, 1776, 3008, 3008],
      11101: [1683, 1596, 872, 1801, 3040, 3040],
      11201: [1706, 1618, 881, 1826, 3069, 3069],
      11301: [1730, 1644, 891, 1852, 3100, 3100],
      11401: [1754, 1666, 901, 1877, 3132, 3132],
      11501: [1777, 1688, 902, 1903, 3161, 3161],
      11601: [1801, 1711, 911, 1928, 3193, 3193],
      11701: [1825, 1736, 921, 1954, 3222, 3222],
      11801: [1849, 1758, 931, 1979, 3253, 3253],
      11901: [1872, 1781, 940, 2005, 3285, 3285],
      12001: [1896, 1803, 950, 2030, 3314, 3314],
      12101: [1920, 1829, 951, 2055, 3346, 3346],
      12201: [1943, 1851, 961, 2081, 3375, 3375],
      12301: [1967, 1873, 971, 2106, 3406, 3406],
      12401: [1991, 1896, 980, 2132, 3438, 3438],
      12501: [2014, 1921, 990, 2157, 3467, 3467],
      12601: [2038, 1943, 1e3, 2182, 3499, 3499],
      12701: [2062, 1966, 1009, 2208, 3528, 3528],
      12801: [2086, 1988, 1010, 2233, 3559, 3559],
      12901: [2109, 2013, 1020, 2259, 3588, 3588],
      13001: [2133, 2036, 1030, 2284, 3626, 3626],
      13101: [2157, 2058, 1039, 2310, 3665, 3665],
      13201: [2181, 2084, 1049, 2336, 3703, 3703],
      13301: [2205, 2106, 1059, 2361, 3741, 3741],
      13401: [2229, 2128, 1060, 2387, 3779, 3779],
      13501: [2253, 2151, 1070, 2413, 3818, 3818],
      13601: [2277, 2176, 1079, 2439, 3856, 3856],
      13701: [2301, 2198, 1089, 2464, 3894, 3894],
      13801: [2324, 2221, 1099, 2489, 3932, 3932],
      13901: [2347, 2243, 1108, 2514, 3969, 3969],
      14001: [2370, 2268, 1110, 2539, 4006, 4006],
      14101: [2393, 2291, 1119, 2564, 4044, 4044],
      14201: [2416, 2313, 1129, 2588, 4081, 4081],
      14301: [2439, 2336, 1138, 2613, 4118, 4118],
      14401: [2462, 2361, 1148, 2638, 4155, 4155],
      14501: [2485, 2383, 1158, 2662, 4193, 4193],
      14601: [2508, 2406, 1159, 2687, 4230, 4230],
      14701: [2531, 2428, 1169, 2712, 4267, 4267],
      14801: [2554, 2453, 1178, 2737, 4307, 4307],
      14901: [2577, 2476, 1188, 2761, 4348, 4348],
      15001: [2600, 2498, 1198, 2786, 4388, 4388],
      15101: [2623, 2520, 1207, 2811, 4428, 4428],
      15201: [2646, 2546, 1217, 2836, 4472, 4472],
      15301: [2669, 2568, 1218, 2860, 4512, 4512],
      15401: [2694, 2594, 1228, 2888, 4553, 4553],
      15501: [2725, 2625, 1238, 2920, 4593, 4593],
      15601: [2756, 2657, 1248, 2953, 4633, 4633],
      15701: [2788, 2689, 1258, 2986, 4677, 4677],
      15801: [2819, 2721, 1268, 3018, 4717, 4717],
      15901: [2850, 2753, 1270, 3051, 4757, 4757],
      16001: [2881, 2785, 1280, 3083, 4798, 4798],
      16101: [2913, 2817, 1291, 3116, 4838, 4838],
      16201: [2944, 2849, 1301, 3148, 4882, 4882],
      16301: [2975, 2880, 1311, 3181, 4922, 4922],
      16401: [3006, 2912, 1321, 3214, 4962, 4962],
      16501: [3038, 2944, 1323, 3246, 5003, 5003],
      16601: [3069, 2976, 1333, 3279, 5043, 5043],
      16701: [3100, 3008, 1343, 3311, 5087, 5087],
      16801: [3131, 3040, 1353, 3344, 5127, 5127],
      16901: [3163, 3072, 1363, 3377, 5167, 5167],
      17001: [3194, 3100, 1373, 3409, 5208, 5208],
      17101: [3225, 3132, 1375, 3442, 5248, 5248],
      17201: [3256, 3164, 1385, 3474, 5291, 5291],
      17301: [3288, 3196, 1395, 3507, 5332, 5332],
      17401: [3319, 3228, 1405, 3539, 5372, 5372],
      17501: [3350, 3260, 1416, 3572, 5412, 5412],
      17601: [3381, 3292, 1426, 3605, 5453, 5453],
      17701: [3413, 3324, 1436, 3637, 5496, 5496],
      17801: [3444, 3355, 1438, 3670, 5537, 5537],
      17901: [3475, 3387, 1448, 3702, 5577, 5577],
      18001: [3506, 3419, 1458, 3735, 5617, 5617],
      18101: [3537, 3451, 1468, 3767, 5658, 5658],
      18201: [3569, 3483, 1478, 3800, 5701, 5701],
      18301: [3600, 3515, 1488, 3833, 5742, 5742],
      18401: [3631, 3547, 1490, 3865, 5782, 5782],
      18501: [3662, 3579, 1500, 3898, 5822, 5822],
      18601: [3694, 3611, 1510, 3930, 5863, 5863],
      18701: [3725, 3642, 1520, 3963, 5906, 5906],
      18801: [3756, 3674, 1531, 3996, 5946, 5946],
      18901: [3787, 3706, 1541, 4028, 5987, 5987],
      19001: [3819, 3738, 1543, 4061, 6027, 6027],
      19101: [3850, 3770, 1553, 4093, 6067, 6067],
      19201: [3881, 3802, 1563, 4126, 6111, 6111],
      19301: [3912, 3834, 1573, 4158, 6151, 6151],
      19401: [3944, 3865, 1583, 4191, 6192, 6192],
      19501: [3975, 3897, 1593, 4223, 6232, 6232],
      19601: [4006, 3929, 1595, 4256, 6272, 6272],
      19701: [4037, 3960, 1604, 4289, 6316, 6316],
      19801: [4068, 3991, 1613, 4321, 6356, 6356],
      19901: [4100, 4022, 1622, 4354, 6396, 6396],
      20001: [4162, 4084, 1641, 4419, 6477, 6477],
      20201: [4225, 4143, 1651, 4484, 6561, 6561],
      20401: [4287, 4205, 1670, 4549, 6642, 6642],
      20601: [4350, 4267, 1688, 4614, 6726, 6726],
      20801: [4412, 4329, 1699, 4680, 6806, 6806],
      21001: [4475, 4391, 1717, 4745, 6887, 6887],
      21201: [4537, 4453, 1736, 4810, 6971, 6971],
      21401: [4600, 4515, 1746, 4875, 7051, 7051],
      21601: [4662, 4577, 1793, 4940, 7135, 7135],
      21801: [4724, 4640, 1841, 5005, 7216, 7216],
      22001: [4787, 4702, 1889, 5070, 7297, 7297],
      22201: [4849, 4764, 1938, 5136, 7381, 7381],
      22401: [4912, 4826, 1986, 5201, 7461, 7461],
      22601: [4974, 4888, 2034, 5266, 7545, 7545],
      22801: [5038, 4950, 2082, 5332, 7627, 7627],
      23001: [5102, 5012, 2130, 5399, 7709, 7709],
      23201: [5166, 5074, 2178, 5466, 7795, 7795],
      23401: [5230, 5133, 2223, 5532, 7877, 7877],
      23601: [5294, 5195, 2271, 5599, 7962, 7962],
      23801: [5358, 5254, 2316, 5666, 8045, 8045],
      24001: [5423, 5319, 2367, 5733, 8127, 8127],
      24201: [5487, 5381, 2415, 5800, 8213, 8213],
      24401: [5551, 5447, 2466, 5866, 8295, 8295],
      24601: [5615, 5509, 2515, 5933, 8380, 8380],
      24801: [5679, 5571, 2563, 6e3, 8463, 8463],
      25001: [5743, 5636, 2631, 6067, 8545, 8545],
      25201: [5807, 5698, 2693, 6134, 8631, 8631],
      25401: [5871, 5760, 2755, 6200, 8713, 8713],
      25601: [5936, 5825, 2820, 6267, 8798, 8798],
      25801: [6e3, 5887, 2883, 6334, 8881, 8881],
      26001: [6064, 5949, 2945, 6401, 8963, 8963],
      26201: [6128, 6015, 3010, 6468, 9049, 9049],
      26401: [6192, 6077, 3072, 6534, 9131, 9131],
      26601: [6256, 6142, 3137, 6601, 9216, 9216],
      26801: [6320, 6204, 3199, 6668, 9299, 9299],
      27001: [6384, 6266, 3261, 6735, 9381, 9381],
      27201: [6449, 6331, 3326, 6802, 9467, 9467],
      27401: [6513, 6393, 3389, 6868, 9549, 9549],
      27601: [6577, 6455, 3451, 6935, 9634, 9634],
      27801: [6641, 6521, 3516, 7002, 9717, 9717],
      28001: [6705, 6583, 3578, 7069, 9799, 9799],
      28201: [6769, 6648, 3643, 7136, 9885, 9885],
      28401: [6833, 6710, 3705, 7202, 9967, 9967],
      28601: [6898, 6772, 3767, 7269, 10052, 10052],
      28801: [6962, 6837, 3832, 7336, 10135, 10135],
      29001: [7026, 6899, 3895, 7403, 10217, 10217],
      29201: [7090, 6961, 3957, 7470, 10303, 10303],
      29401: [7154, 7027, 4022, 7536, 10385, 10385],
      29601: [7218, 7089, 4084, 7603, 10470, 10470],
      29801: [7282, 7154, 4149, 7670, 10553, 10553],
      30001: [7346, 7216, 4211, 7737, 10635, 10635],
      30201: [7411, 7278, 4273, 7804, 10721, 10721],
      30401: [7475, 7343, 4339, 7870, 10803, 10803],
      30601: [7539, 7405, 4401, 7937, 10888, 10888],
      30801: [7603, 7467, 4463, 8004, 10971, 10971],
      31001: [7667, 7533, 4528, 8071, 11053, 11053],
      31201: [7731, 7595, 4590, 8138, 11139, 11139],
      31401: [7795, 7658, 4654, 8204, 11221, 11221],
      31601: [7859, 7725, 4720, 8271, 11306, 11306],
      31801: [7924, 7788, 4783, 8338, 11389, 11389],
      32001: [7988, 7855, 4850, 8405, 11471, 11471],
      32201: [8052, 7918, 4913, 8472, 11557, 11557],
      32401: [8116, 7981, 4977, 8538, 11639, 11639],
      32601: [8180, 8048, 5043, 8605, 11724, 11724],
      32801: [8244, 8111, 5106, 8672, 11807, 11807],
      33001: [8308, 8175, 5170, 8739, 11889, 11889],
      33201: [8372, 8241, 5236, 8806, 11975, 11975],
      33401: [8437, 8304, 5300, 8872, 12057, 12057],
      33601: [8501, 8371, 5366, 8939, 12142, 12142],
      33801: [8565, 8434, 5429, 9006, 12225, 12225],
      34001: [8629, 8498, 5493, 9073, 12307, 12307],
      34201: [8693, 8564, 5559, 9140, 12393, 12393],
      34401: [8757, 8627, 5623, 9206, 12475, 12475],
      34601: [8821, 8691, 5686, 9273, 12560, 12560],
      34801: [8886, 8757, 5752, 9340, 12643, 12643],
      35001: [8950, 8821, 5816, 9407, 12725, 12725],
      35201: [9014, 8884, 5879, 9474, 12811, 12811],
      35401: [9078, 8950, 5946, 9540, 12893, 12893],
      35601: [9142, 9014, 6009, 9607, 12978, 12978],
      35801: [9206, 9080, 6075, 9674, 13061, 13061],
      36001: [9270, 9144, 6139, 9741, 13143, 13143],
      36201: [9334, 9207, 6202, 9808, 13229, 13229],
      36401: [9398, 9273, 6269, 9874, 13311, 13311],
      36601: [9463, 9337, 6332, 9941, 13396, 13396],
      36801: [9527, 9400, 6395, 10008, 13479, 13479],
      37001: [9591, 9467, 6462, 10075, 13561, 13561],
      37201: [9655, 9530, 6525, 10142, 13647, 13647],
      37401: [9719, 9596, 6592, 10208, 13729, 13729],
      37601: [9783, 9657, 6652, 10275, 13808, 13808],
      37801: [9847, 9720, 6715, 10342, 13884, 13884],
      38001: [9911, 9783, 6778, 10408, 13960, 13960],
      38201: [9975, 9850, 6845, 10475, 14036, 14036],
      38401: [10040, 9913, 6908, 10541, 14112, 14112],
      38601: [10116, 9989, 6984, 10608, 14188, 14188],
      38801: [10192, 10065, 7060, 10675, 14264, 14264],
      39001: [10268, 10141, 7136, 10741, 14340, 14340],
      39201: [10344, 10217, 7212, 10808, 14416, 14416],
      39401: [10420, 10293, 7288, 10874, 14492, 14492],
      39601: [10496, 10369, 7364, 10941, 14568, 14568],
      39801: [10572, 10445, 7440, 11007, 14644, 14644],
      40001: [10648, 10521, 7516, 11074, 14720, 14720],
      40201: [10724, 10597, 7592, 11141, 14796, 14796],
      40401: [10800, 10673, 7668, 11207, 14872, 14872],
      40601: [10876, 10749, 7744, 11274, 14948, 14948],
      40801: [10952, 10825, 7820, 11340, 15024, 15024],
      41001: [11028, 10901, 7896, 11407, 15100, 15100],
      41201: [11104, 10977, 7972, 11473, 15176, 15176],
      41401: [11180, 11053, 8048, 11540, 15252, 15252],
      41601: [11256, 11129, 8124, 11607, 15328, 15328],
      41801: [11332, 11205, 8200, 11673, 15404, 15404],
      42001: [11408, 11281, 8276, 11740, 15480, 15480],
      42201: [11484, 11357, 8352, 11806, 15556, 15556],
      42401: [11560, 11433, 8428, 11873, 15632, 15632],
      42601: [11636, 11509, 8504, 11939, 15708, 15708],
      42801: [11712, 11585, 8580, 12006, 15784, 15784],
      43001: [11788, 11661, 8656, 12073, 15860, 15860],
      43201: [11864, 11737, 8732, 12139, 15936, 15936],
      43401: [11940, 11813, 8808, 12206, 16012, 16012],
      43601: [12016, 11889, 8884, 12272, 16088, 16088],
      43801: [12092, 11965, 8960, 12339, 16164, 16164],
      44001: [12168, 12041, 9036, 12405, 16240, 16240],
      44201: [12244, 12117, 9112, 12472, 16316, 16316],
      44401: [12320, 12193, 9188, 12538, 16392, 16392],
      44601: [12396, 12269, 9264, 12605, 16468, 16468],
      44801: [12472, 12345, 9340, 12672, 16544, 16544],
      45001: [12548, 12421, 9416, 12738, 16620, 16620],
      45201: [12624, 12497, 9492, 12805, 16696, 16696],
      45401: [12700, 12573, 9568, 12871, 16772, 16772],
      45601: [12776, 12649, 9644, 12938, 16848, 16848],
      45801: [12852, 12725, 9720, 13004, 16924, 16924],
      46001: [12928, 12801, 9796, 13071, 17e3, 17e3],
      46201: [13004, 12877, 9872, 13138, 17076, 17076],
      46401: [13080, 12953, 9948, 13204, 17152, 17152],
      46601: [13156, 13029, 10024, 13271, 17228, 17228],
      46801: [13232, 13105, 10100, 13337, 17304, 17304],
      47001: [13308, 13181, 10176, 13404, 17380, 17380],
      47201: [13384, 13257, 10252, 13470, 17456, 17456],
      47401: [13460, 13333, 10328, 13537, 17532, 17532],
      47601: [13536, 13409, 10404, 13604, 17608, 17608],
      47801: [13612, 13485, 10480, 13670, 17684, 17684],
      48001: [13688, 13561, 10556, 13737, 17760, 17760],
      48201: [13764, 13637, 10632, 13803, 17836, 17836],
      48401: [13840, 13713, 10708, 13870, 17912, 17912],
      48601: [13916, 13789, 10784, 13936, 17988, 17988],
      48801: [13992, 13865, 10860, 14003, 18064, 18064],
      49001: [14068, 13941, 10936, 14070, 18140, 18140],
      49201: [14144, 14017, 11012, 14136, 18216, 18216],
      49401: [14220, 14093, 11088, 14203, 18292, 18292],
      49601: [14296, 14169, 11164, 14269, 18368, 18368],
      49801: [14372, 14245, 11240, 14336, 18444, 18444],
      50001: [14448, 14321, 11321, 14402, 18520, 18520],
      50201: [14524, 14397, 11403, 14469, 18596, 18596],
      50401: [14600, 14473, 11485, 14536, 18672, 18672],
      50601: [14676, 14549, 11567, 14602, 18748, 18748],
      50801: [14752, 14625, 11649, 14669, 18824, 18824],
      51001: [14828, 14701, 11731, 14735, 18900, 18900],
      51201: [14929, 14806, 11841, 14827, 19001, 19001],
      51401: [15045, 14929, 11971, 14933, 19117, 19117],
      51601: [15161, 15053, 12100, 15040, 19233, 19233],
      51801: [15277, 15173, 12227, 15147, 19349, 19349],
      52001: [15393, 15296, 12356, 15253, 19465, 19465],
      52201: [15509, 15420, 12486, 15360, 19581, 19581],
      52401: [15625, 15543, 12615, 15466, 19697, 19697],
      52601: [15741, 15667, 12745, 15573, 19813, 19813],
      52801: [15857, 15790, 12874, 15679, 19929, 19929],
      53001: [15973, 15914, 13004, 15786, 20045, 20045],
      53201: [16089, 16037, 13133, 15893, 20161, 20161],
      53401: [16205, 16158, 13260, 15999, 20277, 20277],
      53601: [16321, 16281, 13389, 16106, 20393, 20393],
      53801: [16437, 16405, 13519, 16212, 20509, 20509],
      54001: [16553, 16528, 13648, 16319, 20625, 20625],
      54201: [16669, 16652, 13778, 16425, 20741, 20741],
      54401: [16785, 16775, 13907, 16532, 20857, 20857],
      54601: [16901, 16899, 14037, 16639, 20973, 20973],
      54801: [17017, 17022, 14166, 16745, 21089, 21089],
      55001: [17133, 17146, 14296, 16852, 21205, 21205],
      55201: [17249, 17266, 14422, 16958, 21321, 21321],
      55401: [17365, 17390, 14551, 17065, 21437, 21437],
      55601: [17481, 17513, 14681, 17171, 21553, 21553],
      55801: [17597, 17637, 14810, 17278, 21669, 21669],
      56001: [17713, 17760, 14940, 17384, 21785, 21785],
      56201: [17829, 17884, 15069, 17491, 21901, 21901],
      56401: [17945, 18007, 15199, 17598, 22017, 22017],
      56601: [18061, 18131, 15328, 17704, 22133, 22133],
      56801: [18177, 18251, 15455, 17811, 22249, 22249],
      57001: [18293, 18374, 15584, 17917, 22365, 22365],
      57201: [18409, 18498, 15714, 18024, 22481, 22481],
      57401: [18525, 18621, 15843, 18130, 22597, 22597],
      57601: [18641, 18745, 15973, 18237, 22713, 22713],
      57801: [18757, 18868, 16102, 18344, 22829, 22829],
      58001: [18873, 18982, 16222, 18450, 22945, 22945],
      58201: [18989, 19098, 16344, 18557, 23061, 23061],
      58401: [19105, 19214, 16466, 18663, 23177, 23177],
      58601: [19221, 19330, 16588, 18770, 23293, 23293],
      58801: [19337, 19446, 16710, 18876, 23409, 23409],
      59001: [19453, 19562, 16832, 18983, 23525, 23525],
      59201: [19569, 19678, 16954, 19090, 23641, 23641],
      59401: [19685, 19794, 17076, 19196, 23757, 23757],
      59601: [19801, 19910, 17198, 19303, 23873, 23873],
      59801: [19917, 20026, 17320, 19409, 23989, 23989],
      60001: [20033, 20142, 17442, 19516, 24105, 24105],
      60201: [20149, 20258, 17564, 19622, 24221, 24221],
      60401: [20265, 20374, 17686, 19729, 24337, 24337],
      60601: [20381, 20490, 17808, 19836, 24453, 24453],
      60801: [20497, 20606, 17930, 19942, 24569, 24569],
      61001: [20613, 20722, 18052, 20049, 24685, 24685],
      61201: [20729, 20838, 18174, 20155, 24801, 24801],
      61401: [20845, 20954, 18296, 20262, 24917, 24917],
      61601: [20961, 21070, 18418, 20368, 25033, 25033],
      61801: [21077, 21186, 18540, 20475, 25149, 25149],
      62001: [21193, 21302, 18662, 20582, 25265, 25265],
      62201: [21309, 21418, 18784, 20688, 25381, 25381],
      62401: [21425, 21534, 18906, 20795, 25497, 25497],
      62601: [21541, 21650, 19028, 20901, 25613, 25613],
      62801: [21657, 21766, 19150, 21008, 25729, 25729],
      63001: [21773, 21882, 19272, 21114, 25845, 25845],
      63201: [21889, 21998, 19394, 21221, 25961, 25961],
      63401: [22005, 22114, 19516, 21328, 26077, 26077],
      63601: [22121, 22230, 19638, 21434, 26193, 26193],
      63801: [22237, 22346, 19760, 21541, 26309, 26309],
      64001: [22353, 22462, 19882, 21647, 26425, 26425],
      64201: [22469, 22578, 20004, 21754, 26541, 26541],
      64401: [22585, 22694, 20126, 21860, 26657, 26657],
      64601: [22705, 22819, 20257, 21967, 26773, 26773],
      64801: [22827, 22940, 20384, 22074, 26889, 26889],
      65001: [22949, 23066, 20516, 22180, 27005, 27005],
      65201: [23071, 23187, 20643, 22287, 27121, 27121],
      65401: [23193, 23308, 20769, 22393, 27237, 27237],
      65601: [23315, 23433, 20901, 22500, 27353, 27353],
      65801: [23437, 23554, 21028, 22606, 27469, 27469],
      66001: [23559, 23680, 21160, 22713, 27585, 27585],
      66201: [23682, 23801, 21286, 22820, 27701, 27701],
      66401: [23803, 23921, 21413, 22926, 27817, 27817],
      66601: [23925, 24047, 21545, 23033, 27933, 27933],
      66801: [24047, 24168, 21672, 23139, 28049, 28049],
      67001: [24169, 24289, 21799, 23246, 28165, 28165],
      67201: [24291, 24414, 21930, 23352, 28281, 28281],
      67401: [24413, 24535, 22057, 23459, 28397, 28397],
      67601: [24535, 24661, 22189, 23565, 28513, 28513],
      67801: [24657, 24782, 22316, 23672, 28629, 28629],
      68001: [24779, 24903, 22442, 23779, 28745, 28745],
      68201: [24901, 25028, 22574, 23885, 28861, 28861],
      68401: [25023, 25149, 22701, 23992, 28977, 28977],
      68601: [25145, 25270, 22828, 24098, 29093, 29093],
      68801: [25267, 25396, 22959, 24205, 29209, 29209],
      69001: [25389, 25516, 23086, 24311, 29325, 29325],
      69201: [25511, 25642, 23218, 24418, 29441, 29441],
      69401: [25633, 25763, 23345, 24525, 29557, 29557],
      69601: [25755, 25884, 23472, 24631, 29673, 29673],
      69801: [25877, 26009, 23603, 24738, 29789, 29789],
      70001: [25999, 26130, 23730, 24844, 29905, 29905],
      70201: [26121, 26251, 23857, 24951, 30021, 30021],
      70401: [26243, 26377, 23989, 25057, 30137, 30137],
      70601: [26365, 26498, 24115, 25164, 30253, 30253],
      70801: [26487, 26623, 24247, 25271, 30369, 30369],
      71001: [26609, 26744, 24374, 25377, 30485, 30485],
      71201: [26732, 26865, 24501, 25484, 30601, 30601],
      71401: [26853, 26991, 24632, 25590, 30717, 30717],
      71601: [26975, 27111, 24759, 25697, 30833, 30833],
      71801: [27097, 27237, 24891, 25803, 30949, 30949],
      72001: [27219, 27358, 25018, 25910, 31065, 31065],
      72201: [27341, 27479, 25145, 26017, 31181, 31181],
      72401: [27463, 27604, 25276, 26123, 31297, 31297],
      72601: [27585, 27725, 25403, 26230, 31413, 31413],
      72801: [27707, 27846, 25530, 26336, 31529, 31529],
      73001: [27829, 27972, 25662, 26443, 31645, 31645],
      73201: [27951, 28093, 25788, 26549, 31761, 31761],
      73401: [28073, 28218, 25920, 26656, 31877, 31877],
      73601: [28195, 28339, 26047, 26763, 31993, 31993],
      73801: [28317, 28460, 26174, 26869, 32109, 32109],
      74001: [28439, 28586, 26305, 26976, 32225, 32225],
      74201: [28561, 28706, 26432, 27082, 32341, 32341],
      74401: [28683, 28827, 26559, 27189, 32457, 32457],
      74601: [28805, 28953, 26691, 27295, 32573, 32573],
      74801: [28927, 29074, 26818, 27402, 32689, 32689],
      75001: [29049, 29199, 26949, 27509, 32805, 32805],
      75201: [29171, 29320, 27076, 27615, 32921, 32921],
      75401: [29293, 29441, 27203, 27722, 33037, 33037],
      75601: [29415, 29567, 27335, 27828, 33153, 33153],
      75801: [29537, 29688, 27461, 27935, 33269, 33269],
      76001: [29659, 29808, 27588, 28041, 33385, 33385],
      76201: [29782, 29934, 27720, 28148, 33501, 33501],
      76401: [29903, 30055, 27847, 28255, 33617, 33617],
      76601: [30025, 30181, 27978, 28361, 33733, 33733],
      76801: [30147, 30301, 28105, 28468, 33849, 33849],
      77001: [30269, 30422, 28232, 28574, 33965, 33965],
      77201: [30391, 30548, 28364, 28681, 34081, 34081],
      77401: [30513, 30669, 28491, 28787, 34197, 34197],
      77601: [30635, 30790, 28617, 28894, 34313, 34313],
      77801: [30757, 30915, 28749, 29001, 34429, 34429],
      78001: [30879, 31036, 28876, 29107, 34545, 34545],
      78201: [31001, 31162, 29008, 29214, 34661, 34661],
      78401: [31123, 31283, 29134, 29320, 34777, 34777],
      78601: [31245, 31403, 29261, 29427, 34893, 34893],
      78801: [31367, 31529, 29393, 29533, 35009, 35009],
      79001: [31489, 31650, 29520, 29640, 35125, 35125],
      79201: [31611, 31771, 29647, 29746, 35241, 35241],
      79401: [31733, 31896, 29778, 29853, 35357, 35357],
      79601: [31855, 32017, 29905, 29960, 35473, 35473],
      79801: [31977, 32143, 30037, 30066, 35589, 35589],
      80001: [40, 40, 38, 38, 45, 45, "%"],
      81201: [40, 41, 38, 38, 45, 45, "%"],
      82001: [41, 41, 38, 38, 45, 45, "%"],
      82801: [41, 41, 39, 38, 45, 45, "%"],
      84801: [41, 41, 39, 39, 45, 45, "%"],
      85201: [41, 42, 39, 39, 45, 45, "%"],
      86201: [42, 42, 40, 39, 45, 45, "%"],
      86401: [42, 42, 40, 39, 46, 46, "%"],
      89801: [42, 43, 41, 39, 46, 46, "%"],
      90801: [43, 43, 41, 39, 46, 46, "%"],
      91001: [43, 43, 41, 40, 46, 46, "%"],
      93801: [43, 43, 42, 40, 46, 46, "%"],
      94001: [43, 43, 42, 40, 47, 47, "%"],
      94801: [43, 44, 42, 40, 47, 47, "%"],
      96001: [44, 44, 42, 40, 47, 47, "%"],
      98001: [44, 44, 43, 40, 47, 47, "%"],
      98201: [44, 44, 43, 41, 47, 47, "%"],
      100401: [44, 45, 43, 41, 47, 47, "%"],
      101801: [45, 45, 43, 41, 47, 47, "%"],
      102801: [45, 45, 44, 41, 48, 48, "%"],
      106401: [45, 45, 44, 42, 48, 48, "%"],
      106801: [45, 46, 44, 42, 48, 48, "%"],
      108001: [45, 46, 45, 42, 48, 48, "%"],
      108401: [46, 46, 45, 42, 48, 48, "%"],
      113601: [46, 46, 45, 42, 49, 49, "%"],
      113801: [46, 46, 46, 42, 49, 49, "%"],
      114001: [46, 47, 46, 42, 49, 49, "%"],
      116001: [47, 47, 46, 42, 49, 49, "%"],
      116401: [47, 47, 46, 43, 49, 49, "%"],
      120001: [47, 47, 47, 43, 49, 49, "%"],
      122201: [47, 48, 47, 43, 49, 49, "%"],
      124601: [48, 48, 47, 43, 49, 49, "%"],
      127001: [48, 48, 47, 43, 50, 50, "%"],
      127201: [48, 48, 48, 43, 50, 50, "%"],
      128201: [48, 48, 48, 44, 50, 50, "%"],
      131801: [48, 49, 48, 44, 50, 50, "%"],
      134401: [49, 49, 48, 44, 50, 50, "%"],
      135201: [49, 49, 49, 44, 50, 50, "%"],
      142801: [49, 49, 49, 45, 50, 50, "%"],
      143001: [49, 50, 49, 45, 50, 50, "%"],
      144001: [49, 50, 49, 45, 51, 51, "%"],
      144401: [49, 50, 50, 45, 51, 51, "%"],
      146201: [50, 50, 50, 45, 51, 51, "%"],
      156201: [50, 51, 51, 45, 51, 51, "%"],
      160201: [51, 51, 51, 45, 51, 51, "%"],
      161201: [51, 51, 51, 46, 51, 51, "%"],
      166201: [51, 51, 51, 46, 52, 52, "%"],
      172001: [51, 52, 52, 46, 52, 52, "%"],
      177001: [52, 52, 52, 46, 52, 52, "%"],
      185001: [52, 52, 52, 47, 52, 52, "%"],
      196401: [52, 53, 53, 47, 53, 53, "%"],
      197801: [53, 53, 53, 47, 53, 53, "%"],
      217001: [53, 53, 53, 48, 53, 53, "%"],
      240201: [54, 54, 54, 48, 54, 54, "%"],
      262401: [54, 54, 54, 49, 54, 54, "%"],
      308801: [55, 55, 55, 49, 55, 55, "%"],
      331801: [55, 55, 55, 50, 55, 55, "%"],
      432401: [56, 56, 56, 50, 56, 56, "%"],
      451001: [56, 56, 56, 51, 56, 56, "%"],
      703801: [56, 56, 56, 52, 56, 56, "%"],
      720601: [57, 57, 57, 52, 57, 57, "%"],
      1601001: [57, 57, 57, 53, 57, 57, "%"]
    },
    39: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 29],
      2101: [153, 0, 150, 3, 153, 69],
      2201: [164, 0, 158, 5, 164, 109],
      2301: [174, 0, 166, 8, 174, 149],
      2401: [185, 0, 175, 10, 189, 189],
      2501: [195, 0, 183, 12, 229, 229],
      2601: [206, 0, 191, 14, 269, 269],
      2701: [216, 0, 200, 16, 309, 309],
      2801: [218, 0, 200, 18, 349, 349],
      2901: [229, 0, 208, 21, 389, 389],
      3001: [239, 0, 216, 23, 429, 429],
      3101: [250, 0, 225, 25, 469, 469],
      3201: [260, 0, 233, 27, 509, 509],
      3301: [271, 0, 241, 29, 549, 549],
      3401: [273, 0, 241, 31, 589, 589],
      3501: [284, 0, 250, 34, 629, 629],
      3601: [294, 0, 258, 36, 669, 669],
      3701: [305, 0, 266, 38, 709, 709],
      3801: [315, 0, 275, 40, 750, 750],
      3901: [325, 0, 283, 42, 789, 789],
      4001: [328, 0, 283, 44, 829, 829],
      4101: [338, 0, 291, 46, 869, 869],
      4201: [349, 0, 300, 49, 909, 909],
      4301: [359, 0, 308, 64, 949, 949],
      4401: [370, 0, 316, 92, 989, 989],
      4501: [380, 0, 325, 119, 1029, 1029],
      4601: [382, 0, 325, 146, 1069, 1069],
      4701: [392, 0, 333, 173, 1103, 1103],
      4801: [403, 0, 341, 199, 1136, 1136],
      4901: [413, 0, 350, 226, 1169, 1169],
      5001: [423, 0, 358, 253, 1199, 1199],
      5101: [433, 0, 366, 279, 1233, 1233],
      5201: [443, 0, 375, 306, 1263, 1263],
      5301: [445, 26, 376, 333, 1296, 1296],
      5401: [455, 56, 386, 359, 1329, 1329],
      5501: [465, 86, 396, 386, 1359, 1359],
      5601: [475, 116, 406, 412, 1392, 1392],
      5701: [485, 146, 416, 438, 1421, 1421],
      5801: [495, 176, 426, 464, 1454, 1454],
      5901: [497, 206, 427, 490, 1487, 1487],
      6001: [507, 236, 437, 516, 1516, 1516],
      6101: [517, 266, 447, 542, 1549, 1549],
      6201: [534, 293, 457, 568, 1578, 1578],
      6301: [558, 323, 467, 594, 1611, 1611],
      6401: [582, 353, 477, 620, 1644, 1644],
      6501: [606, 383, 479, 646, 1673, 1673],
      6601: [631, 413, 488, 673, 1706, 1706],
      6701: [655, 443, 498, 699, 1735, 1735],
      6801: [679, 473, 508, 725, 1768, 1768],
      6901: [704, 503, 518, 751, 1801, 1801],
      7001: [728, 533, 528, 777, 1830, 1830],
      7101: [752, 563, 530, 803, 1863, 1863],
      7201: [777, 593, 540, 829, 1892, 1892],
      7301: [801, 623, 550, 855, 1925, 1925],
      7401: [825, 649, 560, 881, 1958, 1958],
      7501: [850, 679, 570, 907, 1987, 1987],
      7601: [874, 709, 580, 933, 2020, 2020],
      7701: [898, 739, 589, 959, 2049, 2049],
      7801: [922, 769, 591, 986, 2082, 2082],
      7901: [947, 799, 601, 1012, 2115, 2115],
      8001: [971, 829, 611, 1038, 2144, 2144],
      8101: [995, 859, 621, 1064, 2177, 2177],
      8201: [1020, 889, 631, 1090, 2206, 2206],
      8301: [1044, 919, 641, 1116, 2239, 2239],
      8401: [1068, 949, 642, 1142, 2272, 2272],
      8501: [1093, 979, 652, 1168, 2301, 2301],
      8601: [1117, 1006, 662, 1194, 2334, 2334],
      8701: [1141, 1036, 672, 1220, 2363, 2363],
      8801: [1166, 1066, 682, 1246, 2396, 2396],
      8901: [1190, 1096, 692, 1272, 2429, 2429],
      9001: [1214, 1126, 694, 1298, 2458, 2458],
      9101: [1239, 1156, 704, 1325, 2491, 2491],
      9201: [1263, 1186, 714, 1351, 2520, 2520],
      9301: [1287, 1206, 723, 1377, 2553, 2553],
      9401: [1311, 1233, 733, 1403, 2586, 2586],
      9501: [1336, 1256, 742, 1429, 2615, 2615],
      9601: [1360, 1279, 744, 1455, 2648, 2648],
      9701: [1384, 1303, 753, 1481, 2677, 2677],
      9801: [1409, 1329, 763, 1507, 2710, 2710],
      9901: [1433, 1352, 773, 1533, 2743, 2743],
      10001: [1457, 1375, 782, 1559, 2772, 2772],
      10101: [1482, 1398, 792, 1586, 2805, 2805],
      10201: [1506, 1424, 802, 1611, 2834, 2834],
      10301: [1530, 1447, 803, 1638, 2867, 2867],
      10401: [1555, 1470, 812, 1664, 2900, 2900],
      10501: [1579, 1493, 822, 1690, 2929, 2929],
      10601: [1603, 1519, 832, 1716, 2962, 2962],
      10701: [1627, 1542, 841, 1742, 2991, 2991],
      10801: [1652, 1565, 851, 1768, 3024, 3024],
      10901: [1676, 1588, 852, 1794, 3057, 3057],
      11001: [1700, 1614, 862, 1820, 3086, 3086],
      11101: [1725, 1637, 872, 1846, 3119, 3119],
      11201: [1749, 1660, 881, 1872, 3148, 3148],
      11301: [1773, 1686, 891, 1898, 3181, 3181],
      11401: [1798, 1709, 901, 1925, 3214, 3214],
      11501: [1822, 1732, 902, 1951, 3243, 3243],
      11601: [1846, 1755, 911, 1977, 3276, 3276],
      11701: [1870, 1781, 921, 2003, 3305, 3305],
      11801: [1895, 1804, 931, 2029, 3338, 3338],
      11901: [1919, 1827, 940, 2055, 3371, 3371],
      12001: [1943, 1850, 950, 2081, 3400, 3400],
      12101: [1968, 1876, 951, 2107, 3433, 3433],
      12201: [1992, 1899, 961, 2133, 3462, 3462],
      12301: [2016, 1922, 971, 2159, 3495, 3495],
      12401: [2041, 1944, 980, 2185, 3528, 3528],
      12501: [2065, 1971, 990, 2211, 3557, 3557],
      12601: [2089, 1994, 1e3, 2238, 3590, 3590],
      12701: [2114, 2016, 1009, 2264, 3619, 3619],
      12801: [2138, 2039, 1010, 2290, 3652, 3652],
      12901: [2162, 2065, 1020, 2316, 3681, 3681],
      13001: [2187, 2088, 1030, 2342, 3721, 3721],
      13101: [2211, 2111, 1039, 2368, 3760, 3760],
      13201: [2236, 2137, 1049, 2395, 3799, 3799],
      13301: [2261, 2160, 1059, 2421, 3838, 3838],
      13401: [2285, 2183, 1060, 2448, 3878, 3878],
      13501: [2310, 2206, 1070, 2474, 3917, 3917],
      13601: [2334, 2232, 1079, 2500, 3956, 3956],
      13701: [2359, 2255, 1089, 2527, 3995, 3995],
      13801: [2383, 2278, 1099, 2552, 4034, 4034],
      13901: [2406, 2301, 1108, 2578, 4072, 4072],
      14001: [2430, 2327, 1110, 2603, 4111, 4111],
      14101: [2454, 2350, 1119, 2629, 4149, 4149],
      14201: [2477, 2373, 1129, 2654, 4187, 4187],
      14301: [2501, 2396, 1138, 2679, 4225, 4225],
      14401: [2524, 2422, 1148, 2705, 4263, 4263],
      14501: [2548, 2445, 1158, 2730, 4302, 4302],
      14601: [2572, 2468, 1159, 2755, 4340, 4340],
      14701: [2595, 2491, 1169, 2781, 4378, 4378],
      14801: [2619, 2517, 1178, 2806, 4420, 4420],
      14901: [2642, 2540, 1188, 2831, 4461, 4461],
      15001: [2666, 2563, 1198, 2857, 4503, 4503],
      15101: [2690, 2586, 1207, 2882, 4544, 4544],
      15201: [2713, 2612, 1217, 2908, 4589, 4589],
      15301: [2737, 2635, 1218, 2933, 4630, 4630],
      15401: [2762, 2661, 1228, 2962, 4671, 4671],
      15501: [2794, 2693, 1238, 2995, 4713, 4713],
      15601: [2826, 2726, 1248, 3028, 4754, 4754],
      15701: [2859, 2759, 1258, 3062, 4799, 4799],
      15801: [2891, 2792, 1268, 3095, 4840, 4840],
      15901: [2923, 2824, 1270, 3129, 4882, 4882],
      16001: [2955, 2857, 1280, 3162, 4923, 4923],
      16101: [2987, 2890, 1291, 3196, 4965, 4965],
      16201: [3019, 2922, 1301, 3229, 5009, 5009],
      16301: [3051, 2955, 1311, 3263, 5051, 5051],
      16401: [3083, 2988, 1321, 3296, 5092, 5092],
      16501: [3115, 3021, 1323, 3329, 5134, 5134],
      16601: [3147, 3053, 1333, 3363, 5175, 5175],
      16701: [3179, 3086, 1343, 3396, 5220, 5220],
      16801: [3211, 3119, 1353, 3430, 5261, 5261],
      16901: [3243, 3151, 1363, 3463, 5303, 5303],
      17001: [3276, 3181, 1373, 3497, 5344, 5344],
      17101: [3308, 3214, 1375, 3530, 5386, 5386],
      17201: [3340, 3246, 1385, 3564, 5430, 5430],
      17301: [3372, 3279, 1395, 3597, 5472, 5472],
      17401: [3404, 3312, 1405, 3631, 5513, 5513],
      17501: [3436, 3344, 1416, 3664, 5554, 5554],
      17601: [3468, 3377, 1426, 3697, 5596, 5596],
      17701: [3500, 3410, 1436, 3731, 5640, 5640],
      17801: [3532, 3443, 1438, 3764, 5682, 5682],
      17901: [3564, 3475, 1448, 3798, 5723, 5723],
      18001: [3596, 3508, 1458, 3831, 5765, 5765],
      18101: [3628, 3541, 1468, 3865, 5806, 5806],
      18201: [3660, 3573, 1478, 3898, 5851, 5851],
      18301: [3693, 3606, 1488, 3932, 5892, 5892],
      18401: [3725, 3639, 1490, 3965, 5934, 5934],
      18501: [3757, 3671, 1500, 3998, 5975, 5975],
      18601: [3789, 3704, 1510, 4032, 6017, 6017],
      18701: [3821, 3737, 1520, 4065, 6061, 6061],
      18801: [3853, 3770, 1531, 4099, 6103, 6103],
      18901: [3885, 3802, 1541, 4132, 6144, 6144],
      19001: [3917, 3835, 1543, 4166, 6186, 6186],
      19101: [3949, 3868, 1553, 4199, 6227, 6227],
      19201: [3981, 3900, 1563, 4233, 6272, 6272],
      19301: [4013, 3933, 1573, 4266, 6313, 6313],
      19401: [4045, 3966, 1583, 4299, 6354, 6354],
      19501: [4077, 3999, 1593, 4333, 6396, 6396],
      19601: [4109, 4031, 1595, 4366, 6437, 6437],
      19701: [4142, 4063, 1604, 4400, 6482, 6482],
      19801: [4174, 4095, 1613, 4433, 6523, 6523],
      19901: [4206, 4126, 1622, 4467, 6565, 6565],
      20001: [4270, 4190, 1641, 4534, 6648, 6648],
      20201: [4334, 4251, 1651, 4601, 6734, 6734],
      20401: [4398, 4314, 1670, 4667, 6817, 6817],
      20601: [4462, 4378, 1688, 4734, 6903, 6903],
      20801: [4526, 4442, 1714, 4801, 6986, 6986],
      21001: [4591, 4506, 1764, 4868, 7068, 7068],
      21201: [4655, 4569, 1813, 4935, 7155, 7155],
      21401: [4719, 4633, 1863, 5002, 7237, 7237],
      21601: [4783, 4697, 1913, 5069, 7323, 7323],
      21801: [4847, 4761, 1963, 5136, 7406, 7406],
      22001: [4911, 4824, 2012, 5203, 7489, 7489],
      22201: [4976, 4888, 2062, 5269, 7575, 7575],
      22401: [5040, 4952, 2112, 5336, 7658, 7658],
      22601: [5104, 5016, 2162, 5403, 7744, 7744],
      22801: [5169, 5079, 2211, 5471, 7828, 7828],
      23001: [5235, 5143, 2261, 5540, 7913, 7913],
      23201: [5301, 5207, 2311, 5608, 8e3, 8e3],
      23401: [5366, 5268, 2357, 5677, 8085, 8085],
      23601: [5432, 5331, 2407, 5745, 8173, 8173],
      23801: [5498, 5392, 2454, 5814, 8257, 8257],
      24001: [5564, 5459, 2507, 5882, 8342, 8342],
      24201: [5630, 5523, 2556, 5951, 8429, 8429],
      24401: [5695, 5589, 2609, 6019, 8514, 8514],
      24601: [5761, 5653, 2659, 6088, 8602, 8602],
      24801: [5827, 5717, 2709, 6157, 8686, 8686],
      25001: [5893, 5784, 2779, 6225, 8771, 8771],
      25201: [5958, 5848, 2843, 6294, 8858, 8858],
      25401: [6024, 5911, 2907, 6362, 8943, 8943],
      25601: [6090, 5978, 2973, 6431, 9031, 9031],
      25801: [6156, 6042, 3037, 6499, 9115, 9115],
      26001: [6222, 6106, 3101, 6568, 9200, 9200],
      26201: [6287, 6173, 3168, 6636, 9287, 9287],
      26401: [6353, 6237, 3232, 6705, 9372, 9372],
      26601: [6419, 6303, 3299, 6773, 9460, 9460],
      26801: [6485, 6367, 3362, 6842, 9544, 9544],
      27001: [6551, 6431, 3426, 6910, 9629, 9629],
      27201: [6616, 6498, 3493, 6979, 9716, 9716],
      27401: [6682, 6562, 3557, 7048, 9801, 9801],
      27601: [6748, 6625, 3621, 7116, 9889, 9889],
      27801: [6814, 6692, 3687, 7185, 9973, 9973],
      28001: [6880, 6756, 3751, 7253, 10058, 10058],
      28201: [6945, 6823, 3818, 7322, 10145, 10145],
      28401: [7011, 6887, 3882, 7390, 10230, 10230],
      28601: [7077, 6951, 3946, 7459, 10318, 10318],
      28801: [7143, 7017, 4013, 7527, 10402, 10402],
      29001: [7209, 7081, 4076, 7596, 10487, 10487],
      29201: [7274, 7145, 4140, 7665, 10574, 10574],
      29401: [7340, 7212, 4207, 7733, 10659, 10659],
      29601: [7406, 7276, 4271, 7802, 10747, 10747],
      29801: [7472, 7343, 4338, 7870, 10831, 10831],
      30001: [7538, 7406, 4401, 7939, 10916, 10916],
      30201: [7603, 7470, 4465, 8007, 11003, 11003],
      30401: [7669, 7537, 4532, 8076, 11088, 11088],
      30601: [7735, 7601, 4596, 8144, 11176, 11176],
      30801: [7801, 7665, 4660, 8213, 11260, 11260],
      31001: [7867, 7731, 4727, 8281, 11345, 11345],
      31201: [7933, 7796, 4791, 8350, 11432, 11432],
      31401: [7998, 7861, 4856, 8418, 11517, 11517],
      31601: [8064, 7929, 4924, 8487, 11605, 11605],
      31801: [8130, 7994, 4989, 8556, 11689, 11689],
      32001: [8196, 8062, 5057, 8624, 11774, 11774],
      32201: [8261, 8127, 5122, 8693, 11861, 11861],
      32401: [8327, 8192, 5187, 8761, 11946, 11946],
      32601: [8393, 8260, 5255, 8830, 12034, 12034],
      32801: [8459, 8325, 5320, 8898, 12118, 12118],
      33001: [8525, 8390, 5385, 8967, 12203, 12203],
      33201: [8590, 8459, 5454, 9035, 12290, 12290],
      33401: [8656, 8524, 5519, 9104, 12375, 12375],
      33601: [8722, 8592, 5587, 9173, 12463, 12463],
      33801: [8788, 8657, 5652, 9241, 12547, 12547],
      34001: [8854, 8722, 5717, 9310, 12632, 12632],
      34201: [8919, 8790, 5785, 9378, 12719, 12719],
      34401: [8985, 8855, 5850, 9447, 12804, 12804],
      34601: [9051, 8920, 5915, 9515, 12892, 12892],
      34801: [9117, 8988, 5983, 9584, 12976, 12976],
      35001: [9183, 9053, 6048, 9652, 13061, 13061],
      35201: [9248, 9118, 6113, 9721, 13148, 13148],
      35401: [9314, 9187, 6182, 9789, 13233, 13233],
      35601: [9380, 9252, 6247, 9858, 13321, 13321],
      35801: [9446, 9320, 6315, 9927, 13405, 13405],
      36001: [9512, 9385, 6380, 9995, 13490, 13490],
      36201: [9577, 9450, 6445, 10064, 13577, 13577],
      36401: [9643, 9518, 6513, 10132, 13662, 13662],
      36601: [9709, 9583, 6578, 10201, 13750, 13750],
      36801: [9775, 9648, 6643, 10269, 13834, 13834],
      37001: [9841, 9716, 6711, 10338, 13919, 13919],
      37201: [9906, 9781, 6776, 10406, 14006, 14006],
      37401: [9972, 9850, 6845, 10475, 14091, 14091],
      37601: [10038, 9911, 6906, 10543, 14172, 14172],
      37801: [10103, 9976, 6971, 10612, 14250, 14250],
      38001: [10169, 10041, 7036, 10680, 14328, 14328],
      38201: [10234, 10110, 7105, 10748, 14406, 14406],
      38401: [10302, 10175, 7170, 10817, 14484, 14484],
      38601: [10380, 10253, 7248, 10885, 14562, 14562],
      38801: [10458, 10331, 7326, 10953, 14640, 14640],
      39001: [10536, 10409, 7404, 11021, 14718, 14718],
      39201: [10614, 10487, 7482, 11090, 14796, 14796],
      39401: [10692, 10565, 7560, 11158, 14874, 14874],
      39601: [10770, 10643, 7638, 11226, 14952, 14952],
      39801: [10848, 10721, 7716, 11295, 15030, 15030],
      40001: [10926, 10799, 7794, 11363, 15108, 15108],
      40201: [11004, 10877, 7872, 11431, 15186, 15186],
      40401: [11082, 10955, 7950, 11500, 15264, 15264],
      40601: [11160, 11033, 8028, 11568, 15342, 15342],
      40801: [11238, 11111, 8106, 11636, 15420, 15420],
      41001: [11316, 11189, 8184, 11705, 15498, 15498],
      41201: [11394, 11267, 8262, 11773, 15576, 15576],
      41401: [11472, 11345, 8340, 11841, 15654, 15654],
      41601: [11550, 11423, 8418, 11910, 15732, 15732],
      41801: [11628, 11501, 8496, 11978, 15810, 15810],
      42001: [11706, 11579, 8574, 12046, 15888, 15888],
      42201: [11784, 11657, 8652, 12114, 15966, 15966],
      42401: [11862, 11735, 8730, 12183, 16044, 16044],
      42601: [11940, 11813, 8808, 12251, 16122, 16122],
      42801: [12018, 11891, 8886, 12319, 16200, 16200],
      43001: [12096, 11969, 8964, 12388, 16278, 16278],
      43201: [12174, 12047, 9042, 12456, 16356, 16356],
      43401: [12252, 12125, 9120, 12524, 16434, 16434],
      43601: [12330, 12203, 9198, 12593, 16512, 16512],
      43801: [12408, 12281, 9276, 12661, 16590, 16590],
      44001: [12486, 12359, 9354, 12729, 16668, 16668],
      44201: [12564, 12437, 9432, 12798, 16746, 16746],
      44401: [12642, 12515, 9510, 12866, 16824, 16824],
      44601: [12720, 12593, 9588, 12934, 16902, 16902],
      44801: [12798, 12671, 9666, 13003, 16980, 16980],
      45001: [12876, 12749, 9744, 13071, 17058, 17058],
      45201: [12954, 12827, 9822, 13139, 17136, 17136],
      45401: [13032, 12905, 9900, 13207, 17214, 17214],
      45601: [13110, 12983, 9978, 13276, 17292, 17292],
      45801: [13188, 13061, 10056, 13344, 17370, 17370],
      46001: [13266, 13139, 10134, 13412, 17448, 17448],
      46201: [13344, 13217, 10212, 13481, 17526, 17526],
      46401: [13422, 13295, 10290, 13549, 17604, 17604],
      46601: [13500, 13373, 10368, 13617, 17682, 17682],
      46801: [13578, 13451, 10446, 13686, 17760, 17760],
      47001: [13656, 13529, 10524, 13754, 17838, 17838],
      47201: [13734, 13607, 10602, 13822, 17916, 17916],
      47401: [13812, 13685, 10680, 13891, 17994, 17994],
      47601: [13890, 13763, 10758, 13959, 18072, 18072],
      47801: [13968, 13841, 10836, 14027, 18150, 18150],
      48001: [14046, 13919, 10914, 14096, 18228, 18228],
      48201: [14124, 13997, 10992, 14164, 18306, 18306],
      48401: [14202, 14075, 11070, 14232, 18384, 18384],
      48601: [14280, 14153, 11148, 14300, 18462, 18462],
      48801: [14358, 14231, 11226, 14369, 18540, 18540],
      49001: [14436, 14309, 11304, 14437, 18618, 18618],
      49201: [14514, 14387, 11382, 14505, 18696, 18696],
      49401: [14592, 14465, 11460, 14574, 18774, 18774],
      49601: [14670, 14543, 11538, 14642, 18852, 18852],
      49801: [14748, 14621, 11616, 14710, 18930, 18930],
      50001: [14826, 14699, 11698, 14779, 19008, 19008],
      50201: [14904, 14777, 11782, 14847, 19086, 19086],
      50401: [14982, 14855, 11866, 14915, 19164, 19164],
      50601: [15060, 14933, 11950, 14984, 19242, 19242],
      50801: [15138, 15011, 12034, 15052, 19320, 19320],
      51001: [15216, 15089, 12118, 15120, 19398, 19398],
      51201: [15319, 15196, 12232, 15214, 19501, 19501],
      51401: [15437, 15323, 12364, 15322, 19619, 19619],
      51601: [15555, 15449, 12497, 15430, 19737, 19737],
      51801: [15673, 15573, 12627, 15538, 19855, 19855],
      52001: [15791, 15700, 12759, 15647, 19973, 19973],
      52201: [15909, 15826, 12892, 15755, 20091, 20091],
      52401: [16027, 15953, 13025, 15863, 20209, 20209],
      52601: [16145, 16080, 13158, 15972, 20327, 20327],
      52801: [16263, 16207, 13290, 16080, 20445, 20445],
      53001: [16381, 16333, 13423, 16188, 20563, 20563],
      53201: [16499, 16460, 13556, 16297, 20681, 20681],
      53401: [16617, 16584, 13685, 16405, 20799, 20799],
      53601: [16735, 16710, 13818, 16513, 20917, 20917],
      53801: [16853, 16837, 13951, 16622, 21035, 21035],
      54001: [16971, 16964, 14084, 16730, 21153, 21153],
      54201: [17089, 17091, 14216, 16838, 21271, 21271],
      54401: [17207, 17217, 14349, 16947, 21389, 21389],
      54601: [17325, 17344, 14482, 17055, 21507, 21507],
      54801: [17443, 17471, 14615, 17163, 21625, 21625],
      55001: [17561, 17598, 14747, 17271, 21743, 21743],
      55201: [17679, 17721, 14877, 17380, 21861, 21861],
      55401: [17797, 17848, 15010, 17488, 21979, 21979],
      55601: [17915, 17975, 15142, 17596, 22097, 22097],
      55801: [18033, 18101, 15275, 17705, 22215, 22215],
      56001: [18151, 18228, 15408, 17813, 22333, 22333],
      56201: [18269, 18355, 15541, 17921, 22451, 22451],
      56401: [18387, 18482, 15673, 18030, 22569, 22569],
      56601: [18505, 18608, 15806, 18138, 22687, 22687],
      56801: [18623, 18732, 15936, 18246, 22805, 22805],
      57001: [18741, 18859, 16068, 18355, 22923, 22923],
      57201: [18859, 18985, 16201, 18463, 23041, 23041],
      57401: [18977, 19112, 16334, 18571, 23159, 23159],
      57601: [19095, 19239, 16467, 18680, 23277, 23277],
      57801: [19213, 19366, 16599, 18788, 23395, 23395],
      58001: [19331, 19481, 16721, 18896, 23513, 23513],
      58201: [19449, 19599, 16845, 19004, 23631, 23631],
      58401: [19567, 19717, 16969, 19113, 23749, 23749],
      58601: [19685, 19835, 17093, 19221, 23867, 23867],
      58801: [19803, 19953, 17217, 19329, 23985, 23985],
      59001: [19921, 20071, 17341, 19438, 24103, 24103],
      59201: [20039, 20189, 17465, 19546, 24221, 24221],
      59401: [20157, 20307, 17589, 19654, 24339, 24339],
      59601: [20275, 20425, 17713, 19763, 24457, 24457],
      59801: [20393, 20543, 17837, 19871, 24575, 24575],
      60001: [20511, 20661, 17961, 19979, 24693, 24693],
      60201: [20629, 20779, 18085, 20088, 24811, 24811],
      60401: [20747, 20897, 18209, 20196, 24929, 24929],
      60601: [20865, 21015, 18333, 20304, 25047, 25047],
      60801: [20983, 21133, 18457, 20413, 25165, 25165],
      61001: [21101, 21251, 18581, 20521, 25283, 25283],
      61201: [21219, 21369, 18705, 20629, 25401, 25401],
      61401: [21337, 21487, 18829, 20737, 25519, 25519],
      61601: [21455, 21605, 18953, 20846, 25637, 25637],
      61801: [21573, 21723, 19077, 20954, 25755, 25755],
      62001: [21691, 21841, 19201, 21062, 25873, 25873],
      62201: [21809, 21959, 19325, 21171, 25991, 25991],
      62401: [21927, 22077, 19449, 21279, 26109, 26109],
      62601: [22045, 22195, 19573, 21387, 26227, 26227],
      62801: [22163, 22313, 19697, 21496, 26345, 26345],
      63001: [22281, 22431, 19821, 21604, 26463, 26463],
      63201: [22399, 22549, 19945, 21712, 26581, 26581],
      63401: [22517, 22667, 20069, 21821, 26699, 26699],
      63601: [22635, 22785, 20193, 21929, 26817, 26817],
      63801: [22753, 22903, 20317, 22037, 26935, 26935],
      64001: [22871, 23021, 20441, 22146, 27053, 27053],
      64201: [22989, 23139, 20565, 22254, 27171, 27171],
      64401: [23107, 23257, 20689, 22362, 27289, 27289],
      64601: [23229, 23385, 20823, 22471, 27407, 27407],
      64801: [23353, 23508, 20952, 22579, 27525, 27525],
      65001: [23477, 23636, 21086, 22687, 27643, 27643],
      65201: [23601, 23759, 21215, 22795, 27761, 27761],
      65401: [23725, 23882, 21344, 22904, 27879, 27879],
      65601: [23849, 24010, 21477, 23012, 27997, 27997],
      65801: [23973, 24133, 21606, 23120, 28115, 28115],
      66001: [24097, 24260, 21740, 23229, 28233, 28233],
      66201: [24221, 24383, 21869, 23337, 28351, 28351],
      66401: [24345, 24506, 21998, 23445, 28469, 28469],
      66601: [24469, 24634, 22132, 23554, 28587, 28587],
      66801: [24593, 24757, 22261, 23662, 28705, 28705],
      67001: [24717, 24880, 22390, 23770, 28823, 28823],
      67201: [24841, 25008, 22524, 23879, 28941, 28941],
      67401: [24965, 25131, 22652, 23987, 29059, 29059],
      67601: [25089, 25258, 22786, 24095, 29177, 29177],
      67801: [25213, 25381, 22915, 24204, 29295, 29295],
      68001: [25337, 25504, 23044, 24312, 29413, 29413],
      68201: [25461, 25632, 23178, 24420, 29531, 29531],
      68401: [25585, 25755, 23307, 24528, 29649, 29649],
      68601: [25709, 25878, 23436, 24637, 29767, 29767],
      68801: [25833, 26006, 23570, 24745, 29885, 29885],
      69001: [25957, 26129, 23699, 24853, 30003, 30003],
      69201: [26081, 26257, 23832, 24962, 30121, 30121],
      69401: [26205, 26379, 23961, 25070, 30239, 30239],
      69601: [26329, 26502, 24090, 25178, 30357, 30357],
      69801: [26453, 26630, 24224, 25287, 30475, 30475],
      70001: [26577, 26753, 24353, 25395, 30593, 30593],
      70201: [26701, 26876, 24482, 25503, 30711, 30711],
      70401: [26825, 27004, 24616, 25612, 30829, 30829],
      70601: [26949, 27127, 24745, 25720, 30947, 30947],
      70801: [27073, 27255, 24878, 25828, 31065, 31065],
      71001: [27197, 27378, 25007, 25937, 31183, 31183],
      71201: [27321, 27500, 25136, 26045, 31301, 31301],
      71401: [27445, 27628, 25270, 26153, 31419, 31419],
      71601: [27569, 27751, 25399, 26261, 31537, 31537],
      71801: [27693, 27879, 25533, 26370, 31655, 31655],
      72001: [27817, 28002, 25662, 26478, 31773, 31773],
      72201: [27941, 28125, 25791, 26586, 31891, 31891],
      72401: [28065, 28253, 25925, 26695, 32009, 32009],
      72601: [28189, 28376, 26053, 26803, 32127, 32127],
      72801: [28313, 28499, 26182, 26911, 32245, 32245],
      73001: [28437, 28626, 26316, 27020, 32363, 32363],
      73201: [28561, 28749, 26445, 27128, 32481, 32481],
      73401: [28685, 28877, 26579, 27236, 32599, 32599],
      73601: [28809, 29e3, 26708, 27345, 32717, 32717],
      73801: [28933, 29123, 26837, 27453, 32835, 32835],
      74001: [29057, 29251, 26971, 27561, 32953, 32953],
      74201: [29181, 29374, 27100, 27670, 33071, 33071],
      74401: [29305, 29497, 27228, 27778, 33189, 33189],
      74601: [29429, 29624, 27362, 27886, 33307, 33307],
      74801: [29553, 29747, 27491, 27994, 33425, 33425],
      75001: [29677, 29875, 27625, 28103, 33543, 33543],
      75201: [29801, 29998, 27754, 28211, 33661, 33661],
      75401: [29925, 30121, 27883, 28319, 33779, 33779],
      75601: [30049, 30249, 28017, 28428, 33897, 33897],
      75801: [30173, 30372, 28146, 28536, 34015, 34015],
      76001: [30297, 30495, 28275, 28644, 34133, 34133],
      76201: [30421, 30623, 28408, 28753, 34251, 34251],
      76401: [30545, 30745, 28537, 28861, 34369, 34369],
      76601: [30669, 30873, 28671, 28969, 34487, 34487],
      76801: [30793, 30996, 28800, 29078, 34605, 34605],
      77001: [30917, 31119, 28929, 29186, 34723, 34723],
      77201: [31041, 31247, 29063, 29294, 34841, 34841],
      77401: [31165, 31370, 29192, 29403, 34959, 34959],
      77601: [31289, 31493, 29321, 29511, 35077, 35077],
      77801: [31413, 31621, 29454, 29619, 35195, 35195],
      78001: [31537, 31744, 29583, 29727, 35313, 35313],
      78201: [31661, 31871, 29717, 29836, 35431, 35431],
      78401: [31785, 31994, 29846, 29944, 35549, 35549],
      78601: [31909, 32117, 29975, 30052, 35667, 35667],
      78801: [32033, 32245, 30109, 30161, 35785, 35785],
      79001: [32157, 32368, 30238, 30269, 35903, 35903],
      79201: [32281, 32491, 30367, 30377, 36021, 36021],
      79401: [32405, 32619, 30501, 30486, 36139, 36139],
      79601: [32529, 32742, 30629, 30594, 36257, 36257],
      79801: [32653, 32869, 30763, 30702, 36375, 36375],
      80001: [41, 41, 39, 38, 46, 46, "%"],
      80601: [41, 41, 39, 39, 46, 46, "%"],
      81401: [41, 42, 39, 39, 46, 46, "%"],
      82601: [42, 42, 39, 39, 46, 46, "%"],
      83201: [42, 42, 40, 39, 46, 46, "%"],
      85601: [42, 43, 40, 39, 46, 46, "%"],
      86001: [42, 43, 40, 40, 46, 46, "%"],
      86401: [42, 43, 41, 40, 47, 47, "%"],
      86801: [43, 43, 41, 40, 47, 47, "%"],
      90201: [43, 44, 42, 40, 47, 47, "%"],
      91601: [44, 44, 42, 40, 47, 47, "%"],
      92401: [44, 44, 42, 41, 47, 47, "%"],
      94001: [44, 44, 43, 41, 48, 48, "%"],
      95201: [44, 45, 43, 41, 48, 48, "%"],
      96801: [45, 45, 43, 41, 48, 48, "%"],
      98401: [45, 45, 44, 41, 48, 48, "%"],
      99601: [45, 45, 44, 42, 48, 48, "%"],
      100801: [45, 46, 44, 42, 48, 48, "%"],
      102601: [46, 46, 44, 42, 48, 48, "%"],
      103001: [46, 46, 45, 42, 49, 49, "%"],
      107201: [46, 47, 45, 42, 49, 49, "%"],
      108201: [46, 47, 46, 43, 49, 49, "%"],
      109201: [47, 47, 46, 43, 49, 49, "%"],
      113801: [47, 47, 46, 43, 50, 50, "%"],
      114001: [47, 47, 47, 43, 50, 50, "%"],
      114401: [47, 48, 47, 43, 50, 50, "%"],
      116801: [48, 48, 47, 43, 50, 50, "%"],
      118401: [48, 48, 47, 44, 50, 50, "%"],
      120401: [48, 48, 48, 44, 50, 50, "%"],
      122601: [48, 49, 48, 44, 50, 50, "%"],
      125401: [49, 49, 48, 44, 50, 50, "%"],
      127201: [49, 49, 48, 44, 51, 51, "%"],
      127601: [49, 49, 49, 44, 51, 51, "%"],
      130601: [49, 49, 49, 45, 51, 51, "%"],
      132201: [49, 50, 49, 45, 51, 51, "%"],
      135401: [50, 50, 49, 45, 51, 51, "%"],
      135601: [50, 50, 50, 45, 51, 51, "%"],
      143201: [50, 51, 50, 45, 51, 51, "%"],
      144201: [50, 51, 50, 45, 52, 52, "%"],
      144601: [50, 51, 51, 45, 52, 52, "%"],
      145801: [50, 51, 51, 46, 52, 52, "%"],
      147201: [51, 51, 51, 46, 52, 52, "%"],
      156401: [51, 52, 52, 46, 52, 52, "%"],
      161201: [52, 52, 52, 46, 52, 52, "%"],
      164801: [52, 52, 52, 47, 52, 52, "%"],
      166401: [52, 52, 52, 47, 53, 53, "%"],
      172201: [52, 53, 53, 47, 53, 53, "%"],
      178201: [53, 53, 53, 47, 53, 53, "%"],
      189601: [53, 53, 53, 48, 53, 53, "%"],
      196801: [53, 54, 54, 48, 54, 54, "%"],
      199201: [54, 54, 54, 48, 54, 54, "%"],
      223001: [54, 54, 54, 49, 54, 54, "%"],
      240401: [55, 55, 55, 49, 55, 55, "%"],
      271001: [55, 55, 55, 50, 55, 55, "%"],
      309201: [56, 56, 56, 50, 56, 56, "%"],
      345201: [56, 56, 56, 51, 56, 56, "%"],
      433001: [57, 57, 57, 51, 57, 57, "%"],
      475001: [57, 57, 57, 52, 57, 57, "%"],
      721601: [58, 58, 58, 52, 58, 58, "%"],
      762001: [58, 58, 58, 53, 58, 58, "%"],
      1922601: [58, 58, 58, 54, 58, 58, "%"]
    },
    40: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 30],
      2101: [153, 0, 150, 3, 153, 71],
      2201: [164, 0, 158, 5, 164, 112],
      2301: [174, 0, 166, 8, 174, 153],
      2401: [185, 0, 175, 10, 194, 194],
      2501: [195, 0, 183, 12, 235, 235],
      2601: [206, 0, 191, 14, 276, 276],
      2701: [216, 0, 200, 16, 317, 317],
      2801: [218, 0, 200, 18, 358, 358],
      2901: [229, 0, 208, 21, 399, 399],
      3001: [239, 0, 216, 23, 440, 440],
      3101: [250, 0, 225, 25, 481, 481],
      3201: [260, 0, 233, 27, 522, 522],
      3301: [271, 0, 241, 29, 563, 563],
      3401: [273, 0, 241, 31, 604, 604],
      3501: [284, 0, 250, 34, 645, 645],
      3601: [294, 0, 258, 36, 686, 686],
      3701: [305, 0, 266, 38, 727, 727],
      3801: [315, 0, 275, 40, 768, 768],
      3901: [325, 0, 283, 42, 809, 809],
      4001: [328, 0, 283, 44, 850, 850],
      4101: [338, 0, 291, 46, 891, 891],
      4201: [349, 0, 300, 49, 932, 932],
      4301: [359, 0, 308, 65, 973, 973],
      4401: [370, 0, 316, 93, 1014, 1014],
      4501: [380, 0, 325, 120, 1055, 1055],
      4601: [382, 0, 325, 148, 1096, 1096],
      4701: [392, 0, 333, 176, 1130, 1130],
      4801: [403, 0, 341, 203, 1165, 1165],
      4901: [413, 0, 350, 230, 1199, 1199],
      5001: [423, 0, 358, 258, 1229, 1229],
      5101: [433, 0, 366, 285, 1264, 1264],
      5201: [443, 0, 375, 312, 1294, 1294],
      5301: [445, 27, 376, 340, 1329, 1329],
      5401: [455, 58, 386, 367, 1363, 1363],
      5501: [465, 88, 396, 394, 1393, 1393],
      5601: [475, 119, 406, 421, 1427, 1427],
      5701: [485, 150, 416, 447, 1457, 1457],
      5801: [495, 181, 426, 474, 1490, 1490],
      5901: [497, 211, 427, 501, 1524, 1524],
      6001: [507, 242, 437, 528, 1554, 1554],
      6101: [521, 273, 447, 554, 1588, 1588],
      6201: [546, 300, 457, 581, 1618, 1618],
      6301: [570, 331, 467, 608, 1651, 1651],
      6401: [595, 362, 477, 635, 1685, 1685],
      6501: [620, 392, 479, 661, 1715, 1715],
      6601: [645, 423, 488, 688, 1749, 1749],
      6701: [670, 454, 498, 715, 1779, 1779],
      6801: [695, 485, 508, 742, 1812, 1812],
      6901: [720, 515, 518, 768, 1846, 1846],
      7001: [745, 546, 528, 795, 1876, 1876],
      7101: [770, 577, 530, 822, 1910, 1910],
      7201: [795, 608, 540, 848, 1940, 1940],
      7301: [820, 638, 550, 875, 1973, 1973],
      7401: [845, 666, 560, 902, 2007, 2007],
      7501: [869, 696, 570, 929, 2037, 2037],
      7601: [894, 727, 580, 956, 2071, 2071],
      7701: [919, 758, 589, 982, 2101, 2101],
      7801: [944, 789, 591, 1009, 2134, 2134],
      7901: [969, 819, 601, 1036, 2168, 2168],
      8001: [994, 850, 611, 1062, 2198, 2198],
      8101: [1019, 881, 621, 1089, 2232, 2232],
      8201: [1044, 912, 631, 1116, 2262, 2262],
      8301: [1069, 942, 641, 1143, 2295, 2295],
      8401: [1094, 973, 642, 1169, 2329, 2329],
      8501: [1119, 1004, 652, 1196, 2359, 2359],
      8601: [1144, 1031, 662, 1223, 2393, 2393],
      8701: [1168, 1062, 672, 1250, 2423, 2423],
      8801: [1194, 1093, 682, 1277, 2456, 2456],
      8901: [1218, 1124, 692, 1303, 2490, 2490],
      9001: [1243, 1154, 694, 1330, 2520, 2520],
      9101: [1268, 1185, 704, 1357, 2554, 2554],
      9201: [1293, 1216, 714, 1383, 2584, 2584],
      9301: [1318, 1236, 723, 1410, 2617, 2617],
      9401: [1343, 1264, 733, 1437, 2651, 2651],
      9501: [1368, 1288, 742, 1464, 2681, 2681],
      9601: [1393, 1311, 744, 1490, 2715, 2715],
      9701: [1418, 1335, 753, 1517, 2745, 2745],
      9801: [1443, 1363, 763, 1544, 2778, 2778],
      9901: [1468, 1386, 773, 1571, 2812, 2812],
      10001: [1492, 1410, 782, 1597, 2842, 2842],
      10101: [1518, 1433, 792, 1624, 2876, 2876],
      10201: [1542, 1460, 802, 1651, 2906, 2906],
      10301: [1567, 1484, 803, 1678, 2939, 2939],
      10401: [1592, 1507, 812, 1704, 2973, 2973],
      10501: [1617, 1531, 822, 1731, 3003, 3003],
      10601: [1642, 1557, 832, 1758, 3037, 3037],
      10701: [1667, 1581, 841, 1784, 3067, 3067],
      10801: [1692, 1604, 851, 1811, 3100, 3100],
      10901: [1717, 1628, 852, 1838, 3134, 3134],
      11001: [1742, 1655, 862, 1865, 3164, 3164],
      11101: [1767, 1678, 872, 1892, 3198, 3198],
      11201: [1792, 1702, 881, 1918, 3228, 3228],
      11301: [1817, 1728, 891, 1945, 3261, 3261],
      11401: [1842, 1752, 901, 1972, 3295, 3295],
      11501: [1866, 1775, 902, 1998, 3325, 3325],
      11601: [1891, 1799, 911, 2025, 3359, 3359],
      11701: [1916, 1826, 921, 2052, 3389, 3389],
      11801: [1941, 1849, 931, 2079, 3422, 3422],
      11901: [1966, 1873, 940, 2105, 3456, 3456],
      12001: [1991, 1896, 950, 2132, 3486, 3486],
      12101: [2016, 1923, 951, 2159, 3520, 3520],
      12201: [2041, 1946, 961, 2186, 3550, 3550],
      12301: [2066, 1970, 971, 2212, 3583, 3583],
      12401: [2091, 1993, 980, 2239, 3617, 3617],
      12501: [2115, 2020, 990, 2266, 3647, 3647],
      12601: [2140, 2044, 1e3, 2293, 3681, 3681],
      12701: [2165, 2067, 1009, 2319, 3711, 3711],
      12801: [2190, 2091, 1010, 2346, 3744, 3744],
      12901: [2215, 2117, 1020, 2373, 3774, 3774],
      13001: [2240, 2141, 1030, 2400, 3815, 3815],
      13101: [2266, 2164, 1039, 2427, 3855, 3855],
      13201: [2291, 2191, 1049, 2454, 3895, 3895],
      13301: [2316, 2215, 1059, 2481, 3935, 3935],
      13401: [2341, 2238, 1060, 2508, 3976, 3976],
      13501: [2366, 2262, 1070, 2535, 4016, 4016],
      13601: [2392, 2289, 1079, 2562, 4056, 4056],
      13701: [2417, 2312, 1089, 2589, 4096, 4096],
      13801: [2441, 2336, 1099, 2615, 4136, 4136],
      13901: [2466, 2359, 1108, 2641, 4175, 4175],
      14001: [2490, 2386, 1110, 2668, 4215, 4215],
      14101: [2514, 2409, 1119, 2694, 4254, 4254],
      14201: [2538, 2433, 1129, 2720, 4293, 4293],
      14301: [2562, 2456, 1138, 2746, 4332, 4332],
      14401: [2587, 2483, 1148, 2772, 4372, 4372],
      14501: [2611, 2507, 1158, 2798, 4411, 4411],
      14601: [2635, 2530, 1159, 2824, 4450, 4450],
      14701: [2659, 2554, 1169, 2850, 4489, 4489],
      14801: [2683, 2580, 1178, 2876, 4532, 4532],
      14901: [2708, 2604, 1188, 2902, 4574, 4574],
      15001: [2732, 2627, 1198, 2928, 4617, 4617],
      15101: [2756, 2651, 1207, 2954, 4659, 4659],
      15201: [2781, 2678, 1217, 2980, 4705, 4705],
      15301: [2805, 2701, 1218, 3006, 4748, 4748],
      15401: [2831, 2728, 1228, 3035, 4790, 4790],
      15501: [2864, 2761, 1238, 3069, 4833, 4833],
      15601: [2897, 2795, 1248, 3104, 4875, 4875],
      15701: [2930, 2829, 1258, 3138, 4921, 4921],
      15801: [2962, 2862, 1268, 3172, 4964, 4964],
      15901: [2995, 2896, 1270, 3207, 5006, 5006],
      16001: [3028, 2929, 1280, 3241, 5049, 5049],
      16101: [3061, 2963, 1291, 3275, 5091, 5091],
      16201: [3094, 2996, 1301, 3310, 5137, 5137],
      16301: [3127, 3030, 1311, 3344, 5180, 5180],
      16401: [3160, 3063, 1321, 3378, 5222, 5222],
      16501: [3193, 3097, 1323, 3413, 5265, 5265],
      16601: [3226, 3130, 1333, 3447, 5307, 5307],
      16701: [3259, 3164, 1343, 3481, 5353, 5353],
      16801: [3292, 3198, 1353, 3516, 5395, 5395],
      16901: [3324, 3231, 1363, 3550, 5438, 5438],
      17001: [3357, 3261, 1373, 3584, 5480, 5480],
      17101: [3390, 3295, 1375, 3619, 5523, 5523],
      17201: [3423, 3328, 1385, 3653, 5569, 5569],
      17301: [3456, 3362, 1395, 3687, 5611, 5611],
      17401: [3489, 3395, 1405, 3722, 5654, 5654],
      17501: [3522, 3429, 1416, 3756, 5696, 5696],
      17601: [3555, 3463, 1426, 3790, 5739, 5739],
      17701: [3588, 3496, 1436, 3825, 5785, 5785],
      17801: [3621, 3530, 1438, 3859, 5827, 5827],
      17901: [3653, 3563, 1448, 3893, 5870, 5870],
      18001: [3686, 3597, 1458, 3928, 5912, 5912],
      18101: [3719, 3630, 1468, 3962, 5955, 5955],
      18201: [3752, 3664, 1478, 3996, 6e3, 6e3],
      18301: [3785, 3697, 1488, 4030, 6043, 6043],
      18401: [3818, 3731, 1490, 4065, 6086, 6086],
      18501: [3851, 3764, 1500, 4099, 6128, 6128],
      18601: [3884, 3798, 1510, 4133, 6171, 6171],
      18701: [3917, 3831, 1520, 4168, 6216, 6216],
      18801: [3950, 3865, 1531, 4202, 6259, 6259],
      18901: [3983, 3899, 1541, 4236, 6301, 6301],
      19001: [4016, 3932, 1543, 4271, 6344, 6344],
      19101: [4048, 3966, 1553, 4305, 6386, 6386],
      19201: [4081, 3999, 1563, 4339, 6432, 6432],
      19301: [4114, 4033, 1573, 4374, 6475, 6475],
      19401: [4147, 4066, 1583, 4408, 6517, 6517],
      19501: [4180, 4100, 1593, 4442, 6560, 6560],
      19601: [4213, 4133, 1595, 4477, 6602, 6602],
      19701: [4246, 4166, 1604, 4511, 6648, 6648],
      19801: [4279, 4198, 1613, 4545, 6691, 6691],
      19901: [4312, 4231, 1622, 4580, 6733, 6733],
      20001: [4378, 4296, 1641, 4648, 6818, 6818],
      20201: [4443, 4359, 1672, 4717, 6907, 6907],
      20401: [4509, 4424, 1724, 4785, 6992, 6992],
      20601: [4575, 4489, 1775, 4854, 7080, 7080],
      20801: [4641, 4555, 1827, 4923, 7165, 7165],
      21001: [4707, 4620, 1878, 4991, 7250, 7250],
      21201: [4772, 4686, 1929, 5060, 7338, 7338],
      21401: [4838, 4751, 1981, 5129, 7423, 7423],
      21601: [4904, 4816, 2032, 5197, 7512, 7512],
      21801: [4970, 4882, 2084, 5266, 7597, 7597],
      22001: [5036, 4947, 2135, 5335, 7682, 7682],
      22201: [5102, 5013, 2187, 5403, 7770, 7770],
      22401: [5167, 5078, 2238, 5472, 7855, 7855],
      22601: [5233, 5144, 2289, 5541, 7943, 7943],
      22801: [5300, 5209, 2341, 5610, 8029, 8029],
      23001: [5367, 5274, 2392, 5680, 8116, 8116],
      23201: [5435, 5340, 2444, 5751, 8206, 8206],
      23401: [5502, 5402, 2492, 5821, 8293, 8293],
      23601: [5570, 5467, 2543, 5891, 8383, 8383],
      23801: [5637, 5529, 2591, 5962, 8469, 8469],
      24001: [5705, 5598, 2646, 6032, 8556, 8556],
      24201: [5772, 5664, 2697, 6102, 8646, 8646],
      24401: [5840, 5732, 2752, 6172, 8733, 8733],
      24601: [5907, 5798, 2804, 6243, 8823, 8823],
      24801: [5975, 5863, 2855, 6313, 8909, 8909],
      25001: [6042, 5932, 2927, 6383, 8996, 8996],
      25201: [6110, 5997, 2992, 6454, 9086, 9086],
      25401: [6177, 6063, 3058, 6524, 9173, 9173],
      25601: [6245, 6131, 3126, 6594, 9263, 9263],
      25801: [6312, 6197, 3192, 6664, 9349, 9349],
      26001: [6379, 6262, 3257, 6735, 9436, 9436],
      26201: [6447, 6331, 3326, 6805, 9526, 9526],
      26401: [6514, 6396, 3391, 6875, 9613, 9613],
      26601: [6582, 6465, 3460, 6946, 9703, 9703],
      26801: [6649, 6530, 3526, 7016, 9789, 9789],
      27001: [6717, 6596, 3591, 7086, 9876, 9876],
      27201: [6784, 6664, 3660, 7156, 9966, 9966],
      27401: [6852, 6730, 3725, 7227, 10053, 10053],
      27601: [6919, 6795, 3790, 7297, 10143, 10143],
      27801: [6987, 6864, 3859, 7367, 10229, 10229],
      28001: [7054, 6929, 3925, 7438, 10316, 10316],
      28201: [7122, 6998, 3993, 7508, 10406, 10406],
      28401: [7189, 7064, 4059, 7578, 10493, 10493],
      28601: [7257, 7129, 4124, 7649, 10583, 10583],
      28801: [7324, 7198, 4193, 7719, 10669, 10669],
      29001: [7391, 7263, 4258, 7789, 10756, 10756],
      29201: [7459, 7328, 4324, 7859, 10846, 10846],
      29401: [7526, 7397, 4392, 7930, 10933, 10933],
      29601: [7594, 7463, 4458, 8e3, 11023, 11023],
      29801: [7661, 7531, 4526, 8070, 11109, 11109],
      30001: [7729, 7597, 4592, 8140, 11196, 11196],
      30201: [7796, 7662, 4657, 8211, 11286, 11286],
      30401: [7864, 7731, 4726, 8281, 11373, 11373],
      30601: [7931, 7796, 4791, 8351, 11463, 11463],
      30801: [7999, 7862, 4857, 8422, 11549, 11549],
      31001: [8066, 7930, 4925, 8492, 11636, 11636],
      31201: [8134, 7996, 4991, 8562, 11726, 11726],
      31401: [8201, 8063, 5058, 8633, 11813, 11813],
      31601: [8269, 8133, 5128, 8703, 11903, 11903],
      31801: [8336, 8199, 5195, 8773, 11989, 11989],
      32001: [8404, 8269, 5265, 8843, 12076, 12076],
      32201: [8471, 8336, 5331, 8914, 12166, 12166],
      32401: [8538, 8403, 5398, 8984, 12253, 12253],
      32601: [8606, 8473, 5468, 9054, 12343, 12343],
      32801: [8673, 8539, 5535, 9125, 12429, 12429],
      33001: [8741, 8606, 5601, 9195, 12516, 12516],
      33201: [8808, 8676, 5671, 9265, 12606, 12606],
      33401: [8876, 8743, 5738, 9335, 12693, 12693],
      33601: [8943, 8813, 5808, 9406, 12783, 12783],
      33801: [9011, 8879, 5875, 9476, 12869, 12869],
      34001: [9078, 8946, 5941, 9546, 12956, 12956],
      34201: [9146, 9016, 6011, 9617, 13046, 13046],
      34401: [9213, 9083, 6078, 9687, 13133, 13133],
      34601: [9281, 9149, 6145, 9757, 13223, 13223],
      34801: [9348, 9219, 6215, 9828, 13309, 13309],
      35001: [9416, 9286, 6281, 9898, 13396, 13396],
      35201: [9483, 9353, 6348, 9968, 13486, 13486],
      35401: [9551, 9423, 6418, 10038, 13573, 13573],
      35601: [9618, 9489, 6485, 10109, 13663, 13663],
      35801: [9685, 9559, 6555, 10179, 13749, 13749],
      36001: [9753, 9626, 6621, 10249, 13836, 13836],
      36201: [9820, 9693, 6688, 10320, 13926, 13926],
      36401: [9888, 9763, 6758, 10390, 14013, 14013],
      36601: [9955, 9829, 6825, 10460, 14103, 14103],
      36801: [10023, 9896, 6891, 10530, 14189, 14189],
      37001: [10090, 9966, 6961, 10601, 14276, 14276],
      37201: [10158, 10033, 7028, 10671, 14366, 14366],
      37401: [10225, 10103, 7098, 10741, 14453, 14453],
      37601: [10293, 10166, 7161, 10811, 14536, 14536],
      37801: [10360, 10233, 7228, 10881, 14616, 14616],
      38001: [10427, 10299, 7295, 10951, 14696, 14696],
      38201: [10494, 10369, 7365, 11022, 14776, 14776],
      38401: [10563, 10436, 7431, 11092, 14856, 14856],
      38601: [10643, 10516, 7511, 11162, 14936, 14936],
      38801: [10723, 10596, 7591, 11232, 15016, 15016],
      39001: [10803, 10676, 7671, 11302, 15096, 15096],
      39201: [10883, 10756, 7751, 11372, 15176, 15176],
      39401: [10963, 10836, 7831, 11442, 15256, 15256],
      39601: [11043, 10916, 7911, 11512, 15336, 15336],
      39801: [11123, 10996, 7991, 11582, 15416, 15416],
      40001: [11203, 11076, 8071, 11652, 15496, 15496],
      40201: [11283, 11156, 8151, 11722, 15576, 15576],
      40401: [11363, 11236, 8231, 11792, 15656, 15656],
      40601: [11443, 11316, 8311, 11862, 15736, 15736],
      40801: [11523, 11396, 8391, 11932, 15816, 15816],
      41001: [11603, 11476, 8471, 12002, 15896, 15896],
      41201: [11683, 11556, 8551, 12072, 15976, 15976],
      41401: [11763, 11636, 8631, 12142, 16056, 16056],
      41601: [11843, 11716, 8711, 12212, 16136, 16136],
      41801: [11923, 11796, 8791, 12283, 16216, 16216],
      42001: [12003, 11876, 8871, 12353, 16296, 16296],
      42201: [12083, 11956, 8951, 12423, 16376, 16376],
      42401: [12163, 12036, 9031, 12493, 16456, 16456],
      42601: [12243, 12116, 9111, 12563, 16536, 16536],
      42801: [12323, 12196, 9191, 12633, 16616, 16616],
      43001: [12403, 12276, 9271, 12703, 16696, 16696],
      43201: [12483, 12356, 9351, 12773, 16776, 16776],
      43401: [12563, 12436, 9431, 12843, 16856, 16856],
      43601: [12643, 12516, 9511, 12913, 16936, 16936],
      43801: [12723, 12596, 9591, 12983, 17016, 17016],
      44001: [12803, 12676, 9671, 13053, 17096, 17096],
      44201: [12883, 12756, 9751, 13123, 17176, 17176],
      44401: [12963, 12836, 9831, 13193, 17256, 17256],
      44601: [13043, 12916, 9911, 13263, 17336, 17336],
      44801: [13123, 12996, 9991, 13333, 17416, 17416],
      45001: [13203, 13076, 10071, 13403, 17496, 17496],
      45201: [13283, 13156, 10151, 13474, 17576, 17576],
      45401: [13363, 13236, 10231, 13544, 17656, 17656],
      45601: [13443, 13316, 10311, 13614, 17736, 17736],
      45801: [13523, 13396, 10391, 13684, 17816, 17816],
      46001: [13603, 13476, 10471, 13754, 17896, 17896],
      46201: [13683, 13556, 10551, 13824, 17976, 17976],
      46401: [13763, 13636, 10631, 13894, 18056, 18056],
      46601: [13843, 13716, 10711, 13964, 18136, 18136],
      46801: [13923, 13796, 10791, 14034, 18216, 18216],
      47001: [14003, 13876, 10871, 14104, 18296, 18296],
      47201: [14083, 13956, 10951, 14174, 18376, 18376],
      47401: [14163, 14036, 11031, 14244, 18456, 18456],
      47601: [14243, 14116, 11111, 14314, 18536, 18536],
      47801: [14323, 14196, 11191, 14384, 18616, 18616],
      48001: [14403, 14276, 11271, 14454, 18696, 18696],
      48201: [14483, 14356, 11351, 14524, 18776, 18776],
      48401: [14563, 14436, 11431, 14594, 18856, 18856],
      48601: [14643, 14516, 11511, 14664, 18936, 18936],
      48801: [14723, 14596, 11591, 14735, 19016, 19016],
      49001: [14803, 14676, 11671, 14805, 19096, 19096],
      49201: [14883, 14756, 11751, 14875, 19176, 19176],
      49401: [14963, 14836, 11831, 14945, 19256, 19256],
      49601: [15043, 14916, 11911, 15015, 19336, 19336],
      49801: [15123, 14996, 11991, 15085, 19416, 19416],
      50001: [15203, 15076, 12076, 15155, 19496, 19496],
      50201: [15283, 15156, 12162, 15225, 19576, 19576],
      50401: [15363, 15236, 12248, 15295, 19656, 19656],
      50601: [15443, 15316, 12334, 15365, 19736, 19736],
      50801: [15523, 15396, 12420, 15435, 19816, 19816],
      51001: [15603, 15476, 12506, 15505, 19896, 19896],
      51201: [15708, 15586, 12622, 15600, 20001, 20001],
      51401: [15828, 15716, 12758, 15710, 20121, 20121],
      51601: [15948, 15846, 12894, 15820, 20241, 20241],
      51801: [16068, 15973, 13027, 15930, 20361, 20361],
      52001: [16188, 16103, 13163, 16040, 20481, 20481],
      52201: [16308, 16233, 13299, 16151, 20601, 20601],
      52401: [16428, 16363, 13435, 16261, 20721, 20721],
      52601: [16548, 16493, 13571, 16371, 20841, 20841],
      52801: [16668, 16623, 13707, 16481, 20961, 20961],
      53001: [16788, 16753, 13843, 16591, 21081, 21081],
      53201: [16908, 16883, 13979, 16701, 21201, 21201],
      53401: [17028, 17009, 14111, 16811, 21321, 21321],
      53601: [17148, 17139, 14247, 16921, 21441, 21441],
      53801: [17268, 17269, 14383, 17031, 21561, 21561],
      54001: [17388, 17399, 14519, 17141, 21681, 21681],
      54201: [17508, 17529, 14655, 17251, 21801, 21801],
      54401: [17628, 17659, 14791, 17361, 21921, 21921],
      54601: [17748, 17789, 14927, 17471, 22041, 22041],
      54801: [17868, 17919, 15063, 17581, 22161, 22161],
      55001: [17988, 18049, 15199, 17691, 22281, 22281],
      55201: [18108, 18176, 15332, 17801, 22401, 22401],
      55401: [18228, 18306, 15468, 17911, 22521, 22521],
      55601: [18348, 18436, 15604, 18021, 22641, 22641],
      55801: [18468, 18566, 15740, 18132, 22761, 22761],
      56001: [18588, 18696, 15876, 18242, 22881, 22881],
      56201: [18708, 18826, 16012, 18352, 23001, 23001],
      56401: [18828, 18956, 16148, 18462, 23121, 23121],
      56601: [18948, 19086, 16284, 18572, 23241, 23241],
      56801: [19068, 19213, 16417, 18682, 23361, 23361],
      57001: [19188, 19343, 16553, 18792, 23481, 23481],
      57201: [19308, 19473, 16689, 18902, 23601, 23601],
      57401: [19428, 19603, 16825, 19012, 23721, 23721],
      57601: [19548, 19733, 16961, 19122, 23841, 23841],
      57801: [19668, 19863, 17097, 19232, 23961, 23961],
      58001: [19788, 19981, 17221, 19342, 24081, 24081],
      58201: [19908, 20101, 17347, 19452, 24201, 24201],
      58401: [20028, 20221, 17473, 19562, 24321, 24321],
      58601: [20148, 20341, 17599, 19672, 24441, 24441],
      58801: [20268, 20461, 17725, 19782, 24561, 24561],
      59001: [20388, 20581, 17851, 19892, 24681, 24681],
      59201: [20508, 20701, 17977, 20003, 24801, 24801],
      59401: [20628, 20821, 18103, 20113, 24921, 24921],
      59601: [20748, 20941, 18229, 20223, 25041, 25041],
      59801: [20868, 21061, 18355, 20333, 25161, 25161],
      60001: [20988, 21181, 18481, 20443, 25281, 25281],
      60201: [21108, 21301, 18607, 20553, 25401, 25401],
      60401: [21228, 21421, 18733, 20663, 25521, 25521],
      60601: [21348, 21541, 18859, 20773, 25641, 25641],
      60801: [21468, 21661, 18985, 20883, 25761, 25761],
      61001: [21588, 21781, 19111, 20993, 25881, 25881],
      61201: [21708, 21901, 19237, 21103, 26001, 26001],
      61401: [21828, 22021, 19363, 21213, 26121, 26121],
      61601: [21948, 22141, 19489, 21323, 26241, 26241],
      61801: [22068, 22261, 19615, 21433, 26361, 26361],
      62001: [22188, 22381, 19741, 21543, 26481, 26481],
      62201: [22308, 22501, 19867, 21653, 26601, 26601],
      62401: [22428, 22621, 19993, 21763, 26721, 26721],
      62601: [22548, 22741, 20119, 21873, 26841, 26841],
      62801: [22668, 22861, 20245, 21984, 26961, 26961],
      63001: [22788, 22981, 20371, 22094, 27081, 27081],
      63201: [22908, 23101, 20497, 22204, 27201, 27201],
      63401: [23028, 23221, 20623, 22314, 27321, 27321],
      63601: [23148, 23341, 20749, 22424, 27441, 27441],
      63801: [23268, 23461, 20875, 22534, 27561, 27561],
      64001: [23388, 23581, 21001, 22644, 27681, 27681],
      64201: [23508, 23701, 21127, 22754, 27801, 27801],
      64401: [23628, 23821, 21253, 22864, 27921, 27921],
      64601: [23752, 23951, 21389, 22974, 28041, 28041],
      64801: [23878, 24076, 21520, 23084, 28161, 28161],
      65001: [24004, 24206, 21656, 23194, 28281, 28281],
      65201: [24130, 24331, 21787, 23304, 28401, 28401],
      65401: [24256, 24456, 21918, 23414, 28521, 28521],
      65601: [24382, 24586, 22054, 23524, 28641, 28641],
      65801: [24508, 24711, 22185, 23634, 28761, 28761],
      66001: [24634, 24841, 22321, 23744, 28881, 28881],
      66201: [24760, 24966, 22452, 23855, 29001, 29001],
      66401: [24886, 25091, 22583, 23965, 29121, 29121],
      66601: [25012, 25221, 22719, 24075, 29241, 29241],
      66801: [25138, 25346, 22850, 24185, 29361, 29361],
      67001: [25264, 25471, 22981, 24295, 29481, 29481],
      67201: [25390, 25601, 23117, 24405, 29601, 29601],
      67401: [25516, 25726, 23248, 24515, 29721, 29721],
      67601: [25642, 25856, 23384, 24625, 29841, 29841],
      67801: [25768, 25981, 23515, 24735, 29961, 29961],
      68001: [25894, 26106, 23646, 24845, 30081, 30081],
      68201: [26020, 26236, 23782, 24955, 30201, 30201],
      68401: [26146, 26361, 23913, 25065, 30321, 30321],
      68601: [26272, 26486, 24044, 25175, 30441, 30441],
      68801: [26398, 26616, 24180, 25285, 30561, 30561],
      69001: [26524, 26741, 24311, 25395, 30681, 30681],
      69201: [26650, 26871, 24447, 25505, 30801, 30801],
      69401: [26776, 26996, 24578, 25615, 30921, 30921],
      69601: [26902, 27121, 24709, 25725, 31041, 31041],
      69801: [27028, 27251, 24845, 25836, 31161, 31161],
      70001: [27154, 27376, 24976, 25946, 31281, 31281],
      70201: [27280, 27501, 25107, 26056, 31401, 31401],
      70401: [27406, 27631, 25243, 26166, 31521, 31521],
      70601: [27532, 27756, 25374, 26276, 31641, 31641],
      70801: [27658, 27886, 25510, 26386, 31761, 31761],
      71001: [27784, 28011, 25641, 26496, 31881, 31881],
      71201: [27910, 28136, 25772, 26606, 32001, 32001],
      71401: [28036, 28266, 25908, 26716, 32121, 32121],
      71601: [28162, 28391, 26039, 26826, 32241, 32241],
      71801: [28288, 28521, 26175, 26936, 32361, 32361],
      72001: [28414, 28646, 26306, 27046, 32481, 32481],
      72201: [28540, 28771, 26437, 27156, 32601, 32601],
      72401: [28666, 28901, 26573, 27266, 32721, 32721],
      72601: [28792, 29026, 26704, 27376, 32841, 32841],
      72801: [28918, 29151, 26835, 27486, 32961, 32961],
      73001: [29044, 29281, 26971, 27596, 33081, 33081],
      73201: [29170, 29406, 27102, 27706, 33201, 33201],
      73401: [29296, 29536, 27238, 27817, 33321, 33321],
      73601: [29422, 29661, 27369, 27927, 33441, 33441],
      73801: [29548, 29786, 27500, 28037, 33561, 33561],
      74001: [29674, 29916, 27636, 28147, 33681, 33681],
      74201: [29800, 30041, 27767, 28257, 33801, 33801],
      74401: [29926, 30166, 27898, 28367, 33921, 33921],
      74601: [30052, 30296, 28034, 28477, 34041, 34041],
      74801: [30178, 30421, 28165, 28587, 34161, 34161],
      75001: [30304, 30551, 28301, 28697, 34281, 34281],
      75201: [30430, 30676, 28432, 28807, 34401, 34401],
      75401: [30556, 30801, 28563, 28917, 34521, 34521],
      75601: [30682, 30931, 28699, 29027, 34641, 34641],
      75801: [30808, 31056, 28830, 29137, 34761, 34761],
      76001: [30934, 31181, 28961, 29247, 34881, 34881],
      76201: [31060, 31311, 29097, 29357, 35001, 35001],
      76401: [31186, 31436, 29228, 29467, 35121, 35121],
      76601: [31312, 31566, 29364, 29577, 35241, 35241],
      76801: [31438, 31691, 29495, 29688, 35361, 35361],
      77001: [31564, 31816, 29626, 29798, 35481, 35481],
      77201: [31690, 31946, 29762, 29908, 35601, 35601],
      77401: [31816, 32071, 29893, 30018, 35721, 35721],
      77601: [31942, 32196, 30024, 30128, 35841, 35841],
      77801: [32068, 32326, 30160, 30238, 35961, 35961],
      78001: [32194, 32451, 30291, 30348, 36081, 36081],
      78201: [32320, 32581, 30427, 30458, 36201, 36201],
      78401: [32446, 32706, 30558, 30568, 36321, 36321],
      78601: [32572, 32831, 30689, 30678, 36441, 36441],
      78801: [32698, 32961, 30825, 30788, 36561, 36561],
      79001: [32824, 33086, 30956, 30898, 36681, 36681],
      79201: [32950, 33211, 31087, 31008, 36801, 36801],
      79401: [33076, 33341, 31223, 31118, 36921, 36921],
      79601: [33202, 33466, 31354, 31228, 37041, 37041],
      79801: [33328, 33596, 31490, 31338, 37161, 37161],
      80001: [42, 42, 39, 39, 46, 46, "%"],
      80201: [42, 42, 39, 39, 47, 47, "%"],
      80401: [42, 42, 40, 39, 47, 47, "%"],
      81601: [42, 42, 40, 40, 47, 47, "%"],
      81801: [42, 43, 40, 40, 47, 47, "%"],
      83201: [43, 43, 40, 40, 47, 47, "%"],
      83401: [43, 43, 41, 40, 47, 47, "%"],
      86001: [43, 44, 41, 40, 47, 47, "%"],
      86601: [43, 44, 41, 40, 48, 48, "%"],
      86801: [43, 44, 42, 40, 48, 48, "%"],
      87201: [43, 44, 42, 41, 48, 48, "%"],
      87401: [44, 44, 42, 41, 48, 48, "%"],
      90401: [44, 45, 43, 41, 48, 48, "%"],
      92201: [45, 45, 43, 41, 48, 48, "%"],
      93601: [45, 45, 43, 42, 48, 48, "%"],
      94201: [45, 45, 43, 42, 49, 49, "%"],
      94401: [45, 45, 44, 42, 49, 49, "%"],
      95601: [45, 46, 44, 42, 49, 49, "%"],
      97401: [46, 46, 44, 42, 49, 49, "%"],
      98601: [46, 46, 45, 42, 49, 49, "%"],
      101201: [46, 47, 45, 43, 49, 49, "%"],
      103201: [46, 47, 45, 43, 50, 50, "%"],
      103401: [47, 47, 46, 43, 50, 50, "%"],
      107601: [47, 48, 46, 43, 50, 50, "%"],
      108601: [47, 48, 47, 43, 50, 50, "%"],
      110001: [48, 48, 47, 44, 50, 50, "%"],
      114001: [48, 48, 47, 44, 51, 51, "%"],
      114201: [48, 48, 48, 44, 51, 51, "%"],
      114801: [48, 49, 48, 44, 51, 51, "%"],
      117601: [49, 49, 48, 44, 51, 51, "%"],
      120401: [49, 49, 48, 45, 51, 51, "%"],
      120601: [49, 49, 49, 45, 51, 51, "%"],
      123001: [49, 50, 49, 45, 51, 51, "%"],
      126401: [50, 50, 49, 45, 51, 51, "%"],
      127401: [50, 50, 49, 45, 52, 52, "%"],
      127801: [50, 50, 50, 45, 52, 52, "%"],
      132401: [50, 51, 50, 45, 52, 52, "%"],
      133001: [50, 51, 50, 46, 52, 52, "%"],
      135801: [50, 51, 51, 46, 52, 52, "%"],
      136401: [51, 51, 51, 46, 52, 52, "%"],
      143601: [51, 52, 51, 46, 52, 52, "%"],
      144401: [51, 52, 51, 46, 53, 53, "%"],
      144801: [51, 52, 52, 46, 53, 53, "%"],
      148401: [52, 52, 52, 46, 53, 53, "%"],
      148601: [52, 52, 52, 47, 53, 53, "%"],
      156601: [52, 53, 53, 47, 53, 53, "%"],
      162401: [53, 53, 53, 47, 53, 53, "%"],
      166601: [53, 53, 53, 47, 54, 54, "%"],
      168401: [53, 53, 53, 48, 54, 54, "%"],
      172401: [53, 54, 54, 48, 54, 54, "%"],
      179601: [54, 54, 54, 48, 54, 54, "%"],
      194201: [54, 54, 54, 49, 54, 54, "%"],
      197001: [54, 55, 55, 49, 55, 55, "%"],
      200801: [55, 55, 55, 49, 55, 55, "%"],
      229401: [55, 55, 55, 50, 55, 55, "%"],
      240801: [56, 56, 56, 50, 56, 56, "%"],
      280001: [56, 56, 56, 51, 56, 56, "%"],
      309601: [57, 57, 57, 51, 57, 57, "%"],
      359401: [57, 57, 57, 52, 57, 57, "%"],
      433401: [58, 58, 58, 52, 58, 58, "%"],
      501601: [58, 58, 58, 53, 58, 58, "%"],
      722401: [59, 59, 59, 53, 59, 59, "%"],
      829801: [59, 59, 59, 54, 59, 59, "%"]
    },
    41: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 31],
      2101: [153, 0, 150, 3, 153, 73],
      2201: [164, 0, 158, 5, 164, 115],
      2301: [174, 0, 166, 8, 174, 157],
      2401: [185, 0, 175, 10, 199, 199],
      2501: [195, 0, 183, 12, 241, 241],
      2601: [206, 0, 191, 14, 283, 283],
      2701: [216, 0, 200, 16, 325, 325],
      2801: [218, 0, 200, 18, 367, 367],
      2901: [229, 0, 208, 21, 409, 409],
      3001: [239, 0, 216, 23, 451, 451],
      3101: [250, 0, 225, 25, 493, 493],
      3201: [260, 0, 233, 27, 535, 535],
      3301: [271, 0, 241, 29, 577, 577],
      3401: [273, 0, 241, 31, 619, 619],
      3501: [284, 0, 250, 34, 661, 661],
      3601: [294, 0, 258, 36, 703, 703],
      3701: [305, 0, 266, 38, 745, 745],
      3801: [315, 0, 275, 40, 787, 787],
      3901: [325, 0, 283, 42, 829, 829],
      4001: [328, 0, 283, 44, 871, 871],
      4101: [338, 0, 291, 46, 913, 913],
      4201: [349, 0, 300, 49, 955, 955],
      4301: [359, 0, 308, 65, 997, 997],
      4401: [370, 0, 316, 94, 1039, 1039],
      4501: [380, 0, 325, 122, 1081, 1081],
      4601: [382, 0, 325, 150, 1123, 1123],
      4701: [392, 0, 333, 179, 1158, 1158],
      4801: [403, 0, 341, 207, 1193, 1193],
      4901: [413, 0, 350, 235, 1228, 1228],
      5001: [423, 0, 358, 263, 1259, 1259],
      5101: [433, 0, 366, 291, 1294, 1294],
      5201: [443, 0, 375, 319, 1326, 1326],
      5301: [445, 27, 376, 347, 1361, 1361],
      5401: [455, 59, 386, 375, 1396, 1396],
      5501: [465, 90, 396, 402, 1427, 1427],
      5601: [475, 122, 406, 430, 1461, 1461],
      5701: [485, 153, 416, 457, 1492, 1492],
      5801: [495, 185, 426, 484, 1527, 1527],
      5901: [497, 216, 427, 512, 1561, 1561],
      6001: [507, 248, 437, 539, 1592, 1592],
      6101: [532, 279, 447, 567, 1626, 1626],
      6201: [557, 307, 457, 594, 1657, 1657],
      6301: [583, 339, 467, 621, 1692, 1692],
      6401: [609, 370, 477, 649, 1726, 1726],
      6501: [634, 402, 479, 676, 1757, 1757],
      6601: [660, 433, 488, 704, 1791, 1791],
      6701: [685, 465, 498, 731, 1822, 1822],
      6801: [711, 496, 508, 758, 1857, 1857],
      6901: [736, 528, 518, 786, 1891, 1891],
      7001: [762, 559, 528, 813, 1922, 1922],
      7101: [787, 591, 530, 841, 1956, 1956],
      7201: [813, 622, 540, 868, 1987, 1987],
      7301: [838, 654, 550, 895, 2022, 2022],
      7401: [864, 682, 560, 923, 2056, 2056],
      7501: [889, 713, 570, 950, 2087, 2087],
      7601: [915, 745, 580, 978, 2121, 2121],
      7701: [940, 776, 589, 1005, 2152, 2152],
      7801: [966, 808, 591, 1032, 2187, 2187],
      7901: [992, 839, 601, 1060, 2221, 2221],
      8001: [1017, 871, 611, 1087, 2252, 2252],
      8101: [1043, 902, 621, 1115, 2286, 2286],
      8201: [1068, 934, 631, 1142, 2317, 2317],
      8301: [1094, 965, 641, 1169, 2352, 2352],
      8401: [1119, 997, 642, 1197, 2386, 2386],
      8501: [1145, 1028, 652, 1224, 2417, 2417],
      8601: [1170, 1056, 662, 1252, 2451, 2451],
      8701: [1196, 1088, 672, 1279, 2482, 2482],
      8801: [1221, 1119, 682, 1307, 2517, 2517],
      8901: [1247, 1151, 692, 1334, 2551, 2551],
      9001: [1272, 1182, 694, 1361, 2582, 2582],
      9101: [1298, 1214, 704, 1389, 2616, 2616],
      9201: [1323, 1245, 714, 1416, 2647, 2647],
      9301: [1349, 1266, 723, 1443, 2682, 2682],
      9401: [1375, 1294, 733, 1471, 2716, 2716],
      9501: [1400, 1319, 742, 1498, 2747, 2747],
      9601: [1426, 1343, 744, 1526, 2781, 2781],
      9701: [1451, 1368, 753, 1553, 2812, 2812],
      9801: [1477, 1396, 763, 1581, 2847, 2847],
      9901: [1502, 1420, 773, 1608, 2881, 2881],
      10001: [1528, 1444, 782, 1635, 2912, 2912],
      10101: [1553, 1468, 792, 1663, 2946, 2946],
      10201: [1579, 1496, 802, 1690, 2977, 2977],
      10301: [1604, 1520, 803, 1718, 3012, 3012],
      10401: [1630, 1544, 812, 1745, 3046, 3046],
      10501: [1655, 1568, 822, 1772, 3077, 3077],
      10601: [1681, 1595, 832, 1800, 3111, 3111],
      10701: [1706, 1619, 841, 1827, 3142, 3142],
      10801: [1732, 1644, 851, 1855, 3177, 3177],
      10901: [1758, 1668, 852, 1882, 3211, 3211],
      11001: [1783, 1695, 862, 1909, 3242, 3242],
      11101: [1809, 1719, 872, 1937, 3276, 3276],
      11201: [1834, 1743, 881, 1964, 3307, 3307],
      11301: [1860, 1771, 891, 1992, 3342, 3342],
      11401: [1885, 1795, 901, 2019, 3376, 3376],
      11501: [1911, 1819, 902, 2046, 3407, 3407],
      11601: [1936, 1843, 911, 2074, 3441, 3441],
      11701: [1962, 1870, 921, 2101, 3472, 3472],
      11801: [1987, 1894, 931, 2129, 3507, 3507],
      11901: [2013, 1919, 940, 2156, 3541, 3541],
      12001: [2038, 1943, 950, 2183, 3572, 3572],
      12101: [2064, 1970, 951, 2211, 3606, 3606],
      12201: [2089, 1994, 961, 2238, 3637, 3637],
      12301: [2115, 2018, 971, 2266, 3672, 3672],
      12401: [2141, 2042, 980, 2293, 3706, 3706],
      12501: [2166, 2070, 990, 2320, 3737, 3737],
      12601: [2192, 2094, 1e3, 2348, 3771, 3771],
      12701: [2217, 2118, 1009, 2375, 3802, 3802],
      12801: [2243, 2142, 1010, 2403, 3837, 3837],
      12901: [2268, 2169, 1020, 2430, 3868, 3868],
      13001: [2294, 2194, 1030, 2458, 3909, 3909],
      13101: [2320, 2218, 1039, 2485, 3950, 3950],
      13201: [2346, 2245, 1049, 2513, 3991, 3991],
      13301: [2371, 2269, 1059, 2541, 4033, 4033],
      13401: [2397, 2293, 1060, 2568, 4074, 4074],
      13501: [2423, 2317, 1070, 2596, 4115, 4115],
      13601: [2449, 2345, 1079, 2624, 4156, 4156],
      13701: [2475, 2369, 1089, 2651, 4198, 4198],
      13801: [2500, 2393, 1099, 2678, 4238, 4238],
      13901: [2525, 2417, 1108, 2705, 4279, 4279],
      14001: [2550, 2444, 1110, 2732, 4319, 4319],
      14101: [2574, 2469, 1119, 2759, 4359, 4359],
      14201: [2599, 2493, 1129, 2785, 4399, 4399],
      14301: [2624, 2517, 1138, 2812, 4440, 4440],
      14401: [2649, 2544, 1148, 2839, 4480, 4480],
      14501: [2674, 2568, 1158, 2865, 4520, 4520],
      14601: [2698, 2592, 1159, 2892, 4560, 4560],
      14701: [2723, 2616, 1169, 2919, 4601, 4601],
      14801: [2748, 2644, 1178, 2945, 4644, 4644],
      14901: [2773, 2668, 1188, 2972, 4688, 4688],
      15001: [2798, 2692, 1198, 2999, 4731, 4731],
      15101: [2823, 2716, 1207, 3026, 4775, 4775],
      15201: [2848, 2744, 1217, 3052, 4822, 4822],
      15301: [2872, 2768, 1218, 3079, 4866, 4866],
      15401: [2899, 2795, 1228, 3109, 4909, 4909],
      15501: [2933, 2829, 1238, 3144, 4953, 4953],
      15601: [2967, 2864, 1248, 3179, 4996, 4996],
      15701: [3e3, 2898, 1258, 3214, 5043, 5043],
      15801: [3034, 2933, 1268, 3250, 5087, 5087],
      15901: [3068, 2967, 1270, 3285, 5130, 5130],
      16001: [3102, 3001, 1280, 3320, 5174, 5174],
      16101: [3135, 3036, 1291, 3355, 5218, 5218],
      16201: [3169, 3070, 1301, 3390, 5265, 5265],
      16301: [3203, 3104, 1311, 3426, 5308, 5308],
      16401: [3237, 3139, 1321, 3461, 5352, 5352],
      16501: [3270, 3173, 1323, 3496, 5395, 5395],
      16601: [3304, 3208, 1333, 3531, 5439, 5439],
      16701: [3338, 3242, 1343, 3566, 5486, 5486],
      16801: [3372, 3276, 1353, 3602, 5530, 5530],
      16901: [3405, 3311, 1363, 3637, 5573, 5573],
      17001: [3439, 3342, 1373, 3672, 5617, 5617],
      17101: [3473, 3376, 1375, 3707, 5661, 5661],
      17201: [3507, 3410, 1385, 3742, 5707, 5707],
      17301: [3540, 3445, 1395, 3778, 5751, 5751],
      17401: [3574, 3479, 1405, 3813, 5795, 5795],
      17501: [3608, 3514, 1416, 3848, 5838, 5838],
      17601: [3642, 3548, 1426, 3883, 5882, 5882],
      17701: [3675, 3582, 1436, 3918, 5929, 5929],
      17801: [3709, 3617, 1438, 3953, 5972, 5972],
      17901: [3743, 3651, 1448, 3989, 6016, 6016],
      18001: [3777, 3685, 1458, 4024, 6060, 6060],
      18101: [3810, 3720, 1468, 4059, 6103, 6103],
      18201: [3844, 3754, 1478, 4094, 6150, 6150],
      18301: [3878, 3789, 1488, 4129, 6194, 6194],
      18401: [3912, 3823, 1490, 4165, 6237, 6237],
      18501: [3945, 3857, 1500, 4200, 6281, 6281],
      18601: [3979, 3892, 1510, 4235, 6325, 6325],
      18701: [4013, 3926, 1520, 4270, 6372, 6372],
      18801: [4047, 3960, 1531, 4305, 6415, 6415],
      18901: [4080, 3995, 1541, 4341, 6459, 6459],
      19001: [4114, 4029, 1543, 4376, 6502, 6502],
      19101: [4148, 4064, 1553, 4411, 6546, 6546],
      19201: [4181, 4098, 1563, 4446, 6593, 6593],
      19301: [4215, 4132, 1573, 4481, 6637, 6637],
      19401: [4249, 4167, 1583, 4517, 6680, 6680],
      19501: [4283, 4201, 1593, 4552, 6724, 6724],
      19601: [4316, 4235, 1598, 4587, 6767, 6767],
      19701: [4350, 4268, 1624, 4622, 6814, 6814],
      19801: [4384, 4302, 1651, 4657, 6858, 6858],
      19901: [4418, 4336, 1677, 4692, 6901, 6901],
      20001: [4485, 4403, 1731, 4763, 6989, 6989],
      20201: [4553, 4466, 1780, 4833, 7079, 7079],
      20401: [4620, 4533, 1833, 4904, 7166, 7166],
      20601: [4688, 4601, 1886, 4974, 7257, 7257],
      20801: [4755, 4668, 1939, 5044, 7344, 7344],
      21001: [4823, 4735, 1993, 5115, 7431, 7431],
      21201: [4890, 4802, 2046, 5185, 7522, 7522],
      21401: [4958, 4869, 2099, 5256, 7609, 7609],
      21601: [5025, 4936, 2152, 5326, 7700, 7700],
      21801: [5093, 5003, 2205, 5396, 7787, 7787],
      22001: [5160, 5070, 2258, 5467, 7874, 7874],
      22201: [5228, 5137, 2311, 5537, 7965, 7965],
      22401: [5295, 5204, 2364, 5607, 8052, 8052],
      22601: [5363, 5271, 2417, 5678, 8143, 8143],
      22801: [5431, 5338, 2470, 5749, 8231, 8231],
      23001: [5500, 5406, 2523, 5821, 8320, 8320],
      23201: [5569, 5473, 2576, 5893, 8412, 8412],
      23401: [5638, 5536, 2626, 5965, 8501, 8501],
      23601: [5708, 5603, 2679, 6037, 8593, 8593],
      23801: [5777, 5667, 2729, 6109, 8682, 8682],
      24001: [5846, 5738, 2785, 6181, 8771, 8771],
      24201: [5915, 5805, 2839, 6253, 8863, 8863],
      24401: [5984, 5875, 2895, 6325, 8952, 8952],
      24601: [6053, 5942, 2948, 6398, 9044, 9044],
      24801: [6122, 6009, 3001, 6469, 9133, 9133],
      25001: [6192, 6080, 3075, 6541, 9222, 9222],
      25201: [6261, 6147, 3142, 6614, 9314, 9314],
      25401: [6330, 6214, 3209, 6686, 9403, 9403],
      25601: [6399, 6284, 3279, 6758, 9495, 9495],
      25801: [6468, 6351, 3347, 6830, 9584, 9584],
      26001: [6537, 6418, 3414, 6902, 9673, 9673],
      26201: [6606, 6489, 3484, 6974, 9765, 9765],
      26401: [6676, 6556, 3551, 7046, 9854, 9854],
      26601: [6745, 6626, 3622, 7118, 9946, 9946],
      26801: [6814, 6694, 3689, 7190, 10035, 10035],
      27001: [6883, 6761, 3756, 7262, 10124, 10124],
      27201: [6952, 6831, 3826, 7334, 10216, 10216],
      27401: [7021, 6898, 3893, 7406, 10305, 10305],
      27601: [7091, 6965, 3960, 7478, 10397, 10397],
      27801: [7160, 7036, 4031, 7550, 10486, 10486],
      28001: [7229, 7103, 4098, 7622, 10575, 10575],
      28201: [7298, 7173, 4168, 7694, 10667, 10667],
      28401: [7367, 7240, 4235, 7766, 10756, 10756],
      28601: [7436, 7307, 4303, 7838, 10848, 10848],
      28801: [7505, 7378, 4373, 7910, 10937, 10937],
      29001: [7574, 7445, 4440, 7982, 11026, 11026],
      29201: [7644, 7512, 4507, 8054, 11118, 11118],
      29401: [7713, 7582, 4578, 8126, 11207, 11207],
      29601: [7782, 7649, 4645, 8198, 11299, 11299],
      29801: [7851, 7720, 4715, 8270, 11388, 11388],
      30001: [7920, 7787, 4782, 8342, 11477, 11477],
      30201: [7989, 7854, 4849, 8414, 11569, 11569],
      30401: [8058, 7925, 4920, 8486, 11658, 11658],
      30601: [8128, 7992, 4987, 8558, 11750, 11750],
      30801: [8197, 8059, 5054, 8630, 11839, 11839],
      31001: [8266, 8129, 5124, 8702, 11928, 11928],
      31201: [8335, 8197, 5192, 8775, 12020, 12020],
      31401: [8404, 8265, 5260, 8847, 12109, 12109],
      31601: [8473, 8337, 5332, 8919, 12201, 12201],
      31801: [8542, 8405, 5400, 8991, 12290, 12290],
      32001: [8611, 8477, 5472, 9063, 12379, 12379],
      32201: [8681, 8545, 5540, 9135, 12471, 12471],
      32401: [8750, 8613, 5609, 9207, 12560, 12560],
      32601: [8819, 8685, 5680, 9279, 12652, 12652],
      32801: [8888, 8753, 5749, 9351, 12741, 12741],
      33001: [8957, 8822, 5817, 9423, 12830, 12830],
      33201: [9026, 8894, 5889, 9495, 12922, 12922],
      33401: [9095, 8962, 5957, 9567, 13011, 13011],
      33601: [9165, 9034, 6029, 9639, 13103, 13103],
      33801: [9234, 9102, 6097, 9711, 13192, 13192],
      34001: [9303, 9170, 6165, 9783, 13281, 13281],
      34201: [9372, 9242, 6237, 9855, 13373, 13373],
      34401: [9441, 9310, 6306, 9927, 13462, 13462],
      34601: [9510, 9379, 6374, 9999, 13554, 13554],
      34801: [9580, 9450, 6446, 10071, 13643, 13643],
      35001: [9649, 9519, 6514, 10143, 13732, 13732],
      35201: [9718, 9587, 6582, 10215, 13824, 13824],
      35401: [9787, 9659, 6654, 10287, 13913, 13913],
      35601: [9856, 9727, 6722, 10359, 14005, 14005],
      35801: [9925, 9799, 6794, 10431, 14094, 14094],
      36001: [9994, 9867, 6862, 10503, 14183, 14183],
      36201: [10063, 9936, 6931, 10575, 14275, 14275],
      36401: [10133, 10007, 7003, 10647, 14364, 14364],
      36601: [10202, 10076, 7071, 10719, 14456, 14456],
      36801: [10271, 10144, 7139, 10791, 14545, 14545],
      37001: [10340, 10216, 7211, 10863, 14634, 14634],
      37201: [10409, 10284, 7279, 10936, 14726, 14726],
      37401: [10478, 10356, 7351, 11008, 14815, 14815],
      37601: [10547, 10421, 7416, 11079, 14900, 14900],
      37801: [10616, 10489, 7484, 11151, 14982, 14982],
      38001: [10685, 10557, 7553, 11223, 15064, 15064],
      38201: [10754, 10629, 7624, 11295, 15146, 15146],
      38401: [10824, 10698, 7693, 11367, 15228, 15228],
      38601: [10906, 10780, 7775, 11438, 15310, 15310],
      38801: [10988, 10862, 7857, 11510, 15392, 15392],
      39001: [11070, 10944, 7939, 11582, 15474, 15474],
      39201: [11152, 11026, 8021, 11654, 15556, 15556],
      39401: [11234, 11108, 8103, 11726, 15638, 15638],
      39601: [11316, 11190, 8185, 11797, 15720, 15720],
      39801: [11398, 11272, 8267, 11869, 15802, 15802],
      40001: [11480, 11354, 8349, 11941, 15884, 15884],
      40201: [11562, 11436, 8431, 12013, 15966, 15966],
      40401: [11644, 11518, 8513, 12085, 16048, 16048],
      40601: [11726, 11600, 8595, 12156, 16130, 16130],
      40801: [11808, 11682, 8677, 12228, 16212, 16212],
      41001: [11890, 11764, 8759, 12300, 16294, 16294],
      41201: [11973, 11846, 8841, 12372, 16376, 16376],
      41401: [12054, 11928, 8923, 12444, 16458, 16458],
      41601: [12136, 12010, 9005, 12515, 16540, 16540],
      41801: [12218, 12092, 9087, 12587, 16622, 16622],
      42001: [12300, 12174, 9169, 12659, 16704, 16704],
      42201: [12382, 12256, 9251, 12731, 16786, 16786],
      42401: [12464, 12338, 9333, 12803, 16868, 16868],
      42601: [12546, 12420, 9415, 12874, 16950, 16950],
      42801: [12628, 12502, 9497, 12946, 17032, 17032],
      43001: [12710, 12584, 9579, 13018, 17114, 17114],
      43201: [12792, 12666, 9661, 13090, 17196, 17196],
      43401: [12874, 12748, 9743, 13162, 17278, 17278],
      43601: [12956, 12830, 9825, 13233, 17360, 17360],
      43801: [13038, 12912, 9907, 13305, 17442, 17442],
      44001: [13120, 12994, 9989, 13377, 17524, 17524],
      44201: [13202, 13076, 10071, 13449, 17606, 17606],
      44401: [13284, 13158, 10153, 13521, 17688, 17688],
      44601: [13366, 13240, 10235, 13592, 17770, 17770],
      44801: [13448, 13322, 10317, 13664, 17852, 17852],
      45001: [13530, 13404, 10399, 13736, 17934, 17934],
      45201: [13612, 13486, 10481, 13808, 18016, 18016],
      45401: [13694, 13568, 10563, 13880, 18098, 18098],
      45601: [13776, 13650, 10645, 13951, 18180, 18180],
      45801: [13858, 13732, 10727, 14023, 18262, 18262],
      46001: [13940, 13814, 10809, 14095, 18344, 18344],
      46201: [14023, 13896, 10891, 14167, 18426, 18426],
      46401: [14104, 13978, 10973, 14239, 18508, 18508],
      46601: [14186, 14060, 11055, 14310, 18590, 18590],
      46801: [14268, 14142, 11137, 14382, 18672, 18672],
      47001: [14350, 14224, 11219, 14454, 18754, 18754],
      47201: [14432, 14306, 11301, 14526, 18836, 18836],
      47401: [14514, 14388, 11383, 14598, 18918, 18918],
      47601: [14596, 14470, 11465, 14670, 19e3, 19e3],
      47801: [14678, 14552, 11547, 14741, 19082, 19082],
      48001: [14760, 14634, 11629, 14813, 19164, 19164],
      48201: [14842, 14716, 11711, 14885, 19246, 19246],
      48401: [14924, 14798, 11793, 14957, 19328, 19328],
      48601: [15006, 14880, 11875, 15029, 19410, 19410],
      48801: [15088, 14962, 11957, 15100, 19492, 19492],
      49001: [15170, 15044, 12039, 15172, 19574, 19574],
      49201: [15252, 15126, 12121, 15244, 19656, 19656],
      49401: [15334, 15208, 12203, 15316, 19738, 19738],
      49601: [15416, 15290, 12285, 15388, 19820, 19820],
      49801: [15498, 15372, 12367, 15459, 19902, 19902],
      50001: [15580, 15454, 12453, 15531, 19984, 19984],
      50201: [15662, 15536, 12541, 15603, 20066, 20066],
      50401: [15744, 15618, 12629, 15675, 20148, 20148],
      50601: [15826, 15700, 12717, 15747, 20230, 20230],
      50801: [15908, 15782, 12805, 15818, 20312, 20312],
      51001: [15990, 15864, 12893, 15890, 20394, 20394],
      51201: [16098, 15976, 13012, 15987, 20501, 20501],
      51401: [16219, 16110, 13151, 16099, 20623, 20623],
      51601: [16341, 16243, 13291, 16211, 20745, 20745],
      51801: [16463, 16373, 13426, 16322, 20867, 20867],
      52001: [16585, 16506, 13566, 16434, 20989, 20989],
      52201: [16707, 16639, 13705, 16546, 21111, 21111],
      52401: [16829, 16772, 13844, 16658, 21233, 21233],
      52601: [16951, 16906, 13983, 16770, 21355, 21355],
      52801: [17073, 17039, 14123, 16881, 21477, 21477],
      53001: [17195, 17172, 14262, 16993, 21599, 21599],
      53201: [17317, 17305, 14401, 17105, 21721, 21721],
      53401: [17439, 17435, 14537, 17217, 21843, 21843],
      53601: [17561, 17568, 14676, 17329, 21965, 21965],
      53801: [17683, 17702, 14816, 17440, 22087, 22087],
      54001: [17805, 17835, 14955, 17552, 22209, 22209],
      54201: [17927, 17968, 15094, 17664, 22331, 22331],
      54401: [18049, 18101, 15233, 17776, 22453, 22453],
      54601: [18171, 18235, 15373, 17888, 22575, 22575],
      54801: [18293, 18368, 15512, 17999, 22697, 22697],
      55001: [18415, 18501, 15651, 18111, 22819, 22819],
      55201: [18537, 18631, 15787, 18223, 22941, 22941],
      55401: [18659, 18764, 15926, 18335, 23063, 23063],
      55601: [18781, 18898, 16065, 18447, 23185, 23185],
      55801: [18903, 19031, 16205, 18558, 23307, 23307],
      56001: [19025, 19164, 16344, 18670, 23429, 23429],
      56201: [19148, 19297, 16483, 18782, 23551, 23551],
      56401: [19269, 19431, 16622, 18894, 23673, 23673],
      56601: [19391, 19564, 16762, 19006, 23795, 23795],
      56801: [19513, 19694, 16897, 19117, 23917, 23917],
      57001: [19635, 19827, 17037, 19229, 24039, 24039],
      57201: [19757, 19960, 17176, 19341, 24161, 24161],
      57401: [19879, 20093, 17315, 19453, 24283, 24283],
      57601: [20001, 20227, 17454, 19565, 24405, 24405],
      57801: [20123, 20360, 17594, 19676, 24527, 24527],
      58001: [20245, 20481, 17721, 19788, 24649, 24649],
      58201: [20367, 20603, 17849, 19900, 24771, 24771],
      58401: [20489, 20725, 17977, 20012, 24893, 24893],
      58601: [20611, 20847, 18105, 20124, 25015, 25015],
      58801: [20733, 20969, 18233, 20235, 25137, 25137],
      59001: [20855, 21091, 18361, 20347, 25259, 25259],
      59201: [20977, 21213, 18489, 20459, 25381, 25381],
      59401: [21099, 21335, 18617, 20571, 25503, 25503],
      59601: [21221, 21457, 18745, 20683, 25625, 25625],
      59801: [21343, 21579, 18873, 20794, 25747, 25747],
      60001: [21465, 21701, 19001, 20906, 25869, 25869],
      60201: [21587, 21823, 19129, 21018, 25991, 25991],
      60401: [21709, 21945, 19257, 21130, 26113, 26113],
      60601: [21831, 22067, 19385, 21242, 26235, 26235],
      60801: [21953, 22189, 19513, 21353, 26357, 26357],
      61001: [22075, 22311, 19641, 21465, 26479, 26479],
      61201: [22198, 22433, 19769, 21577, 26601, 26601],
      61401: [22319, 22555, 19897, 21689, 26723, 26723],
      61601: [22441, 22677, 20025, 21801, 26845, 26845],
      61801: [22563, 22799, 20153, 21912, 26967, 26967],
      62001: [22685, 22921, 20281, 22024, 27089, 27089],
      62201: [22807, 23043, 20409, 22136, 27211, 27211],
      62401: [22929, 23165, 20537, 22248, 27333, 27333],
      62601: [23051, 23287, 20665, 22360, 27455, 27455],
      62801: [23173, 23409, 20793, 22471, 27577, 27577],
      63001: [23295, 23531, 20921, 22583, 27699, 27699],
      63201: [23417, 23653, 21049, 22695, 27821, 27821],
      63401: [23539, 23775, 21177, 22807, 27943, 27943],
      63601: [23661, 23897, 21305, 22919, 28065, 28065],
      63801: [23783, 24019, 21433, 23030, 28187, 28187],
      64001: [23905, 24141, 21561, 23142, 28309, 28309],
      64201: [24027, 24263, 21689, 23254, 28431, 28431],
      64401: [24149, 24385, 21817, 23366, 28553, 28553],
      64601: [24276, 24517, 21955, 23478, 28675, 28675],
      64801: [24404, 24644, 22088, 23589, 28797, 28797],
      65001: [24532, 24776, 22226, 23701, 28919, 28919],
      65201: [24660, 24903, 22359, 23813, 29041, 29041],
      65401: [24788, 25030, 22492, 23925, 29163, 29163],
      65601: [24916, 25162, 22630, 24037, 29285, 29285],
      65801: [25044, 25290, 22763, 24148, 29407, 29407],
      66001: [25172, 25422, 22902, 24260, 29529, 29529],
      66201: [25300, 25549, 23035, 24372, 29651, 29651],
      66401: [25428, 25676, 23168, 24484, 29773, 29773],
      66601: [25556, 25808, 23306, 24596, 29895, 29895],
      66801: [25684, 25935, 23439, 24707, 30017, 30017],
      67001: [25812, 26062, 23572, 24819, 30139, 30139],
      67201: [25940, 26194, 23710, 24931, 30261, 30261],
      67401: [26068, 26321, 23843, 25043, 30383, 30383],
      67601: [26196, 26454, 23981, 25155, 30505, 30505],
      67801: [26324, 26581, 24115, 25266, 30627, 30627],
      68001: [26452, 26708, 24248, 25378, 30749, 30749],
      68201: [26580, 26840, 24386, 25490, 30871, 30871],
      68401: [26708, 26967, 24519, 25602, 30993, 30993],
      68601: [26836, 27094, 24652, 25714, 31115, 31115],
      68801: [26964, 27226, 24790, 25825, 31237, 31237],
      69001: [27092, 27353, 24923, 25937, 31359, 31359],
      69201: [27220, 27486, 25061, 26049, 31481, 31481],
      69401: [27348, 27613, 25194, 26161, 31603, 31603],
      69601: [27476, 27740, 25328, 26273, 31725, 31725],
      69801: [27604, 27872, 25466, 26384, 31847, 31847],
      70001: [27732, 27999, 25599, 26496, 31969, 31969],
      70201: [27860, 28126, 25732, 26608, 32091, 32091],
      70401: [27988, 28258, 25870, 26720, 32213, 32213],
      70601: [28116, 28385, 26003, 26832, 32335, 32335],
      70801: [28244, 28517, 26141, 26943, 32457, 32457],
      71001: [28372, 28645, 26274, 27055, 32579, 32579],
      71201: [28500, 28772, 26407, 27167, 32701, 32701],
      71401: [28628, 28904, 26546, 27279, 32823, 32823],
      71601: [28756, 29031, 26679, 27391, 32945, 32945],
      71801: [28884, 29163, 26817, 27502, 33067, 33067],
      72001: [29012, 29290, 26950, 27614, 33189, 33189],
      72201: [29140, 29417, 27083, 27726, 33311, 33311],
      72401: [29268, 29549, 27221, 27838, 33433, 33433],
      72601: [29396, 29676, 27354, 27950, 33555, 33555],
      72801: [29524, 29804, 27487, 28061, 33677, 33677],
      73001: [29652, 29936, 27626, 28173, 33799, 33799],
      73201: [29780, 30063, 27759, 28285, 33921, 33921],
      73401: [29908, 30195, 27897, 28397, 34043, 34043],
      73601: [30036, 30322, 28030, 28509, 34165, 34165],
      73801: [30164, 30449, 28163, 28620, 34287, 34287],
      74001: [30292, 30581, 28301, 28732, 34409, 34409],
      74201: [30420, 30708, 28434, 28844, 34531, 34531],
      74401: [30548, 30835, 28567, 28956, 34653, 34653],
      74601: [30676, 30968, 28705, 29068, 34775, 34775],
      74801: [30804, 31095, 28839, 29179, 34897, 34897],
      75001: [30932, 31227, 28977, 29291, 35019, 35019],
      75201: [31060, 31354, 29110, 29403, 35141, 35141],
      75401: [31188, 31481, 29243, 29515, 35263, 35263],
      75601: [31316, 31613, 29381, 29627, 35385, 35385],
      75801: [31444, 31740, 29514, 29738, 35507, 35507],
      76001: [31572, 31867, 29647, 29850, 35629, 35629],
      76201: [31700, 32e3, 29785, 29962, 35751, 35751],
      76401: [31828, 32127, 29918, 30074, 35873, 35873],
      76601: [31956, 32259, 30057, 30186, 35995, 35995],
      76801: [32084, 32386, 30190, 30297, 36117, 36117],
      77001: [32212, 32513, 30323, 30409, 36239, 36239],
      77201: [32340, 32645, 30461, 30521, 36361, 36361],
      77401: [32468, 32772, 30594, 30633, 36483, 36483],
      77601: [32596, 32899, 30727, 30745, 36605, 36605],
      77801: [32724, 33031, 30865, 30856, 36727, 36727],
      78001: [32852, 33159, 30998, 30968, 36849, 36849],
      78201: [32980, 33291, 31137, 31080, 36971, 36971],
      78401: [33108, 33418, 31270, 31192, 37093, 37093],
      78601: [33236, 33545, 31403, 31304, 37215, 37215],
      78801: [33364, 33677, 31541, 31415, 37337, 37337],
      79001: [33492, 33804, 31674, 31527, 37459, 37459],
      79201: [33620, 33931, 31807, 31639, 37581, 37581],
      79401: [33748, 34063, 31945, 31751, 37703, 37703],
      79601: [33876, 34190, 32078, 31863, 37825, 37825],
      79801: [34004, 34323, 32216, 31974, 37947, 37947],
      80001: [43, 43, 40, 40, 47, 47, "%"],
      80201: [43, 43, 40, 40, 48, 48, "%"],
      80601: [43, 43, 41, 40, 48, 48, "%"],
      82201: [43, 44, 41, 40, 48, 48, "%"],
      82601: [43, 44, 41, 41, 48, 48, "%"],
      83601: [43, 44, 42, 41, 48, 48, "%"],
      83801: [44, 44, 42, 41, 48, 48, "%"],
      86201: [44, 45, 42, 41, 48, 48, "%"],
      86801: [44, 45, 42, 41, 49, 49, "%"],
      87001: [44, 45, 43, 41, 49, 49, "%"],
      88001: [45, 45, 43, 41, 49, 49, "%"],
      88401: [45, 45, 43, 42, 49, 49, "%"],
      90601: [45, 45, 44, 42, 49, 49, "%"],
      90801: [45, 46, 44, 42, 49, 49, "%"],
      92801: [46, 46, 44, 42, 49, 49, "%"],
      94201: [46, 46, 44, 42, 50, 50, "%"],
      94601: [46, 46, 45, 42, 50, 50, "%"],
      95001: [46, 46, 45, 43, 50, 50, "%"],
      95801: [46, 47, 45, 43, 50, 50, "%"],
      98201: [47, 47, 45, 43, 50, 50, "%"],
      99001: [47, 47, 46, 43, 50, 50, "%"],
      101601: [47, 48, 46, 43, 50, 50, "%"],
      102601: [47, 48, 46, 44, 50, 50, "%"],
      103201: [47, 48, 46, 44, 51, 51, "%"],
      103601: [47, 48, 47, 44, 51, 51, "%"],
      104201: [48, 48, 47, 44, 51, 51, "%"],
      108001: [48, 49, 47, 44, 51, 51, "%"],
      108801: [48, 49, 48, 44, 51, 51, "%"],
      110801: [49, 49, 48, 44, 51, 51, "%"],
      111601: [49, 49, 48, 45, 51, 51, "%"],
      114201: [49, 49, 48, 45, 52, 52, "%"],
      114601: [49, 49, 49, 45, 52, 52, "%"],
      115001: [49, 50, 49, 45, 52, 52, "%"],
      118401: [50, 50, 49, 45, 52, 52, "%"],
      121001: [50, 50, 50, 45, 52, 52, "%"],
      122401: [50, 50, 50, 46, 52, 52, "%"],
      123201: [50, 51, 50, 46, 52, 52, "%"],
      127201: [51, 51, 50, 46, 52, 52, "%"],
      127601: [51, 51, 50, 46, 53, 53, "%"],
      128001: [51, 51, 51, 46, 53, 53, "%"],
      132801: [51, 52, 51, 46, 53, 53, "%"],
      135401: [51, 52, 51, 47, 53, 53, "%"],
      136001: [51, 52, 52, 47, 53, 53, "%"],
      137401: [52, 52, 52, 47, 53, 53, "%"],
      144001: [52, 53, 52, 47, 53, 53, "%"],
      144601: [52, 53, 52, 47, 54, 54, "%"],
      145201: [52, 53, 53, 47, 54, 54, "%"],
      149401: [53, 53, 53, 47, 54, 54, "%"],
      151601: [53, 53, 53, 48, 54, 54, "%"],
      157001: [53, 54, 54, 48, 54, 54, "%"],
      163601: [54, 54, 54, 48, 54, 54, "%"],
      166801: [54, 54, 54, 48, 55, 55, "%"],
      172201: [54, 54, 54, 49, 55, 55, "%"],
      172601: [54, 55, 55, 49, 55, 55, "%"],
      181001: [55, 55, 55, 49, 55, 55, "%"],
      197201: [55, 56, 56, 49, 56, 56, "%"],
      199001: [55, 56, 56, 50, 56, 56, "%"],
      202201: [56, 56, 56, 50, 56, 56, "%"],
      236001: [56, 56, 56, 51, 56, 56, "%"],
      241001: [57, 57, 57, 51, 57, 57, "%"],
      289601: [57, 57, 57, 52, 57, 57, "%"],
      310001: [58, 58, 58, 52, 58, 58, "%"],
      374801: [58, 58, 58, 53, 58, 58, "%"],
      434001: [59, 59, 59, 53, 59, 59, "%"],
      530801: [59, 59, 59, 54, 59, 59, "%"],
      723401: [60, 60, 60, 54, 60, 60, "%"],
      910001: [60, 60, 60, 55, 60, 60, "%"]
    },
    42: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [151, 0, 150, 1, 151, 32],
      2101: [153, 0, 150, 3, 153, 75],
      2201: [164, 0, 158, 5, 164, 118],
      2301: [174, 0, 166, 8, 174, 161],
      2401: [185, 0, 175, 10, 204, 204],
      2501: [195, 0, 183, 12, 247, 247],
      2601: [206, 0, 191, 14, 290, 290],
      2701: [216, 0, 200, 16, 333, 333],
      2801: [218, 0, 200, 18, 376, 376],
      2901: [229, 0, 208, 21, 419, 419],
      3001: [239, 0, 216, 23, 462, 462],
      3101: [250, 0, 225, 25, 505, 505],
      3201: [260, 0, 233, 27, 548, 548],
      3301: [271, 0, 241, 29, 591, 591],
      3401: [273, 0, 241, 31, 634, 634],
      3501: [284, 0, 250, 34, 677, 677],
      3601: [294, 0, 258, 36, 720, 720],
      3701: [305, 0, 266, 38, 763, 763],
      3801: [315, 0, 275, 40, 806, 806],
      3901: [325, 0, 283, 42, 849, 849],
      4001: [328, 0, 283, 44, 892, 892],
      4101: [338, 0, 291, 46, 935, 935],
      4201: [349, 0, 300, 49, 978, 978],
      4301: [359, 0, 308, 66, 1021, 1021],
      4401: [370, 0, 316, 95, 1064, 1064],
      4501: [380, 0, 325, 124, 1107, 1107],
      4601: [382, 0, 325, 153, 1150, 1150],
      4701: [392, 0, 333, 182, 1186, 1186],
      4801: [403, 0, 341, 210, 1221, 1221],
      4901: [413, 0, 350, 239, 1257, 1257],
      5001: [423, 0, 358, 268, 1289, 1289],
      5101: [433, 0, 366, 296, 1325, 1325],
      5201: [443, 0, 375, 325, 1358, 1358],
      5301: [445, 28, 376, 354, 1393, 1393],
      5401: [455, 60, 386, 382, 1429, 1429],
      5501: [465, 93, 396, 410, 1461, 1461],
      5601: [475, 125, 406, 439, 1496, 1496],
      5701: [485, 157, 416, 467, 1528, 1528],
      5801: [495, 189, 426, 495, 1563, 1563],
      5901: [497, 222, 427, 523, 1598, 1598],
      6001: [517, 254, 437, 551, 1630, 1630],
      6101: [543, 286, 447, 579, 1665, 1665],
      6201: [569, 315, 457, 607, 1697, 1697],
      6301: [596, 347, 467, 635, 1732, 1732],
      6401: [622, 379, 477, 663, 1767, 1767],
      6501: [648, 412, 479, 691, 1799, 1799],
      6601: [674, 444, 488, 719, 1834, 1834],
      6701: [700, 476, 498, 747, 1866, 1866],
      6801: [726, 508, 508, 775, 1901, 1901],
      6901: [753, 541, 518, 803, 1936, 1936],
      7001: [779, 573, 528, 831, 1968, 1968],
      7101: [805, 605, 530, 859, 2003, 2003],
      7201: [831, 637, 540, 887, 2035, 2035],
      7301: [857, 670, 550, 916, 2070, 2070],
      7401: [883, 698, 560, 944, 2105, 2105],
      7501: [909, 730, 570, 972, 2137, 2137],
      7601: [936, 763, 580, 1e3, 2172, 2172],
      7701: [962, 795, 589, 1028, 2204, 2204],
      7801: [988, 827, 591, 1056, 2239, 2239],
      7901: [1014, 859, 601, 1084, 2274, 2274],
      8001: [1040, 892, 611, 1112, 2306, 2306],
      8101: [1066, 924, 621, 1140, 2341, 2341],
      8201: [1092, 956, 631, 1168, 2373, 2373],
      8301: [1119, 988, 641, 1196, 2408, 2408],
      8401: [1145, 1021, 642, 1224, 2443, 2443],
      8501: [1171, 1053, 652, 1252, 2475, 2475],
      8601: [1197, 1082, 662, 1280, 2510, 2510],
      8701: [1223, 1114, 672, 1308, 2542, 2542],
      8801: [1249, 1146, 682, 1337, 2577, 2577],
      8901: [1275, 1178, 692, 1365, 2612, 2612],
      9001: [1301, 1211, 694, 1393, 2644, 2644],
      9101: [1328, 1243, 704, 1421, 2679, 2679],
      9201: [1354, 1275, 714, 1449, 2711, 2711],
      9301: [1380, 1297, 723, 1477, 2746, 2746],
      9401: [1406, 1325, 733, 1505, 2781, 2781],
      9501: [1432, 1350, 742, 1533, 2813, 2813],
      9601: [1458, 1375, 744, 1561, 2848, 2848],
      9701: [1485, 1401, 753, 1589, 2880, 2880],
      9801: [1511, 1429, 763, 1617, 2915, 2915],
      9901: [1537, 1454, 773, 1645, 2950, 2950],
      10001: [1563, 1479, 782, 1673, 2982, 2982],
      10101: [1589, 1503, 792, 1701, 3017, 3017],
      10201: [1615, 1531, 802, 1729, 3049, 3049],
      10301: [1641, 1556, 803, 1757, 3084, 3084],
      10401: [1668, 1581, 812, 1786, 3119, 3119],
      10501: [1694, 1605, 822, 1813, 3151, 3151],
      10601: [1720, 1634, 832, 1842, 3186, 3186],
      10701: [1746, 1658, 841, 1870, 3218, 3218],
      10801: [1772, 1683, 851, 1898, 3253, 3253],
      10901: [1798, 1707, 852, 1926, 3288, 3288],
      11001: [1824, 1736, 862, 1954, 3320, 3320],
      11101: [1851, 1760, 872, 1982, 3355, 3355],
      11201: [1877, 1785, 881, 2010, 3387, 3387],
      11301: [1903, 1813, 891, 2038, 3422, 3422],
      11401: [1929, 1838, 901, 2066, 3457, 3457],
      11501: [1955, 1862, 902, 2094, 3489, 3489],
      11601: [1981, 1887, 911, 2122, 3524, 3524],
      11701: [2007, 1915, 921, 2150, 3556, 3556],
      11801: [2034, 1940, 931, 2178, 3591, 3591],
      11901: [2060, 1964, 940, 2206, 3626, 3626],
      12001: [2086, 1989, 950, 2234, 3658, 3658],
      12101: [2112, 2017, 951, 2263, 3693, 3693],
      12201: [2138, 2042, 961, 2291, 3725, 3725],
      12301: [2164, 2067, 971, 2319, 3760, 3760],
      12401: [2191, 2091, 980, 2347, 3795, 3795],
      12501: [2217, 2119, 990, 2375, 3827, 3827],
      12601: [2243, 2144, 1e3, 2403, 3862, 3862],
      12701: [2269, 2169, 1009, 2431, 3894, 3894],
      12801: [2295, 2193, 1010, 2459, 3929, 3929],
      12901: [2321, 2221, 1020, 2487, 3961, 3961],
      13001: [2348, 2246, 1030, 2515, 4003, 4003],
      13101: [2374, 2271, 1039, 2544, 4045, 4045],
      13201: [2400, 2299, 1049, 2572, 4088, 4088],
      13301: [2427, 2324, 1059, 2600, 4130, 4130],
      13401: [2453, 2348, 1060, 2629, 4172, 4172],
      13501: [2480, 2373, 1070, 2657, 4214, 4214],
      13601: [2506, 2401, 1079, 2685, 4257, 4257],
      13701: [2533, 2426, 1089, 2714, 4299, 4299],
      13801: [2558, 2450, 1099, 2741, 4340, 4340],
      13901: [2584, 2475, 1108, 2769, 4382, 4382],
      14001: [2609, 2503, 1110, 2796, 4423, 4423],
      14101: [2635, 2528, 1119, 2824, 4464, 4464],
      14201: [2660, 2552, 1129, 2851, 4505, 4505],
      14301: [2686, 2577, 1138, 2878, 4547, 4547],
      14401: [2711, 2605, 1148, 2906, 4588, 4588],
      14501: [2736, 2630, 1158, 2933, 4629, 4629],
      14601: [2762, 2655, 1159, 2960, 4670, 4670],
      14701: [2787, 2679, 1169, 2988, 4712, 4712],
      14801: [2813, 2707, 1178, 3015, 4756, 4756],
      14901: [2838, 2732, 1188, 3042, 4801, 4801],
      15001: [2864, 2757, 1198, 3070, 4846, 4846],
      15101: [2889, 2781, 1207, 3097, 4890, 4890],
      15201: [2915, 2809, 1217, 3125, 4939, 4939],
      15301: [2940, 2834, 1218, 3152, 4983, 4983],
      15401: [2968, 2862, 1228, 3182, 5028, 5028],
      15501: [3002, 2897, 1238, 3219, 5073, 5073],
      15601: [3037, 2933, 1248, 3255, 5117, 5117],
      15701: [3071, 2968, 1258, 3291, 5165, 5165],
      15801: [3106, 3003, 1268, 3327, 5210, 5210],
      15901: [3140, 3038, 1270, 3363, 5255, 5255],
      16001: [3175, 3074, 1280, 3399, 5300, 5300],
      16101: [3210, 3109, 1291, 3435, 5344, 5344],
      16201: [3244, 3144, 1301, 3471, 5392, 5392],
      16301: [3279, 3179, 1311, 3507, 5437, 5437],
      16401: [3313, 3214, 1321, 3543, 5482, 5482],
      16501: [3348, 3250, 1323, 3579, 5526, 5526],
      16601: [3382, 3285, 1333, 3615, 5571, 5571],
      16701: [3417, 3320, 1343, 3651, 5619, 5619],
      16801: [3452, 3355, 1353, 3687, 5664, 5664],
      16901: [3486, 3390, 1363, 3723, 5709, 5709],
      17001: [3521, 3422, 1373, 3760, 5753, 5753],
      17101: [3555, 3457, 1375, 3796, 5798, 5798],
      17201: [3590, 3493, 1385, 3832, 5846, 5846],
      17301: [3625, 3528, 1395, 3868, 5891, 5891],
      17401: [3659, 3563, 1405, 3904, 5935, 5935],
      17501: [3694, 3598, 1416, 3940, 5980, 5980],
      17601: [3728, 3633, 1426, 3976, 6025, 6025],
      17701: [3763, 3669, 1436, 4012, 6073, 6073],
      17801: [3797, 3704, 1438, 4048, 6118, 6118],
      17901: [3832, 3739, 1448, 4084, 6162, 6162],
      18001: [3867, 3774, 1458, 4120, 6207, 6207],
      18101: [3901, 3809, 1468, 4156, 6252, 6252],
      18201: [3936, 3845, 1478, 4192, 6300, 6300],
      18301: [3970, 3880, 1488, 4228, 6345, 6345],
      18401: [4005, 3915, 1490, 4264, 6389, 6389],
      18501: [4040, 3950, 1500, 4300, 6434, 6434],
      18601: [4074, 3986, 1510, 4336, 6479, 6479],
      18701: [4109, 4021, 1520, 4373, 6527, 6527],
      18801: [4143, 4056, 1531, 4409, 6571, 6571],
      18901: [4178, 4091, 1541, 4445, 6616, 6616],
      19001: [4212, 4126, 1543, 4481, 6661, 6661],
      19101: [4247, 4162, 1559, 4517, 6705, 6705],
      19201: [4282, 4197, 1588, 4553, 6754, 6754],
      19301: [4316, 4232, 1616, 4589, 6798, 6798],
      19401: [4351, 4267, 1644, 4625, 6843, 6843],
      19501: [4385, 4302, 1672, 4661, 6888, 6888],
      19601: [4420, 4337, 1700, 4697, 6932, 6932],
      19701: [4455, 4371, 1727, 4733, 6980, 6980],
      19801: [4489, 4406, 1755, 4769, 7025, 7025],
      19901: [4524, 4440, 1782, 4805, 7070, 7070],
      20001: [4593, 4509, 1837, 4877, 7159, 7159],
      20201: [4662, 4574, 1888, 4950, 7252, 7252],
      20401: [4731, 4643, 1943, 5022, 7341, 7341],
      20601: [4800, 4712, 1998, 5094, 7434, 7434],
      20801: [4869, 4780, 2052, 5166, 7524, 7524],
      21001: [4939, 4849, 2107, 5238, 7613, 7613],
      21201: [5008, 4918, 2162, 5310, 7706, 7706],
      21401: [5077, 4987, 2217, 5382, 7795, 7795],
      21601: [5146, 5055, 2271, 5455, 7888, 7888],
      21801: [5215, 5124, 2326, 5527, 7977, 7977],
      22001: [5284, 5193, 2381, 5599, 8067, 8067],
      22201: [5354, 5262, 2436, 5671, 8160, 8160],
      22401: [5423, 5330, 2490, 5743, 8249, 8249],
      22601: [5492, 5399, 2545, 5815, 8342, 8342],
      22801: [5562, 5468, 2600, 5888, 8432, 8432],
      23001: [5633, 5537, 2655, 5962, 8523, 8523],
      23201: [5704, 5605, 2709, 6036, 8618, 8618],
      23401: [5775, 5671, 2761, 6110, 8709, 8709],
      23601: [5845, 5740, 2815, 6183, 8803, 8803],
      23801: [5916, 5805, 2867, 6257, 8894, 8894],
      24001: [5987, 5877, 2925, 6331, 8985, 8985],
      24201: [6058, 5946, 2980, 6405, 9080, 9080],
      24401: [6129, 6018, 3038, 6478, 9171, 9171],
      24601: [6199, 6087, 3093, 6552, 9265, 9265],
      24801: [6270, 6155, 3147, 6626, 9356, 9356],
      25001: [6341, 6228, 3223, 6700, 9447, 9447],
      25201: [6412, 6296, 3292, 6774, 9542, 9542],
      25401: [6483, 6365, 3360, 6847, 9633, 9633],
      25601: [6553, 6437, 3432, 6921, 9727, 9727],
      25801: [6624, 6506, 3501, 6995, 9818, 9818],
      26001: [6695, 6575, 3570, 7069, 9909, 9909],
      26201: [6766, 6647, 3642, 7142, 10004, 10004],
      26401: [6837, 6716, 3711, 7216, 10095, 10095],
      26601: [6908, 6788, 3783, 7290, 10189, 10189],
      26801: [6978, 6857, 3852, 7364, 10280, 10280],
      27001: [7049, 6925, 3921, 7437, 10371, 10371],
      27201: [7120, 6998, 3993, 7511, 10466, 10466],
      27401: [7191, 7066, 4062, 7585, 10557, 10557],
      27601: [7262, 7135, 4130, 7659, 10651, 10651],
      27801: [7332, 7207, 4202, 7733, 10742, 10742],
      28001: [7403, 7276, 4271, 7806, 10833, 10833],
      28201: [7474, 7348, 4343, 7880, 10928, 10928],
      28401: [7545, 7417, 4412, 7954, 11019, 11019],
      28601: [7616, 7486, 4481, 8028, 11113, 11113],
      28801: [7686, 7558, 4553, 8102, 11204, 11204],
      29001: [7757, 7627, 4622, 8175, 11295, 11295],
      29201: [7828, 7695, 4691, 8249, 11390, 11390],
      29401: [7899, 7768, 4763, 8323, 11481, 11481],
      29601: [7970, 7836, 4832, 8397, 11575, 11575],
      29801: [8041, 7909, 4904, 8470, 11666, 11666],
      30001: [8111, 7977, 4972, 8544, 11757, 11757],
      30201: [8182, 8046, 5041, 8618, 11852, 11852],
      30401: [8253, 8118, 5114, 8692, 11943, 11943],
      30601: [8324, 8187, 5182, 8766, 12037, 12037],
      30801: [8395, 8256, 5251, 8839, 12128, 12128],
      31001: [8465, 8328, 5323, 8913, 12219, 12219],
      31201: [8536, 8397, 5392, 8987, 12314, 12314],
      31401: [8607, 8467, 5462, 9061, 12405, 12405],
      31601: [8678, 8541, 5536, 9134, 12499, 12499],
      31801: [8749, 8611, 5606, 9208, 12590, 12590],
      32001: [8819, 8684, 5679, 9282, 12681, 12681],
      32201: [8890, 8754, 5749, 9356, 12776, 12776],
      32401: [8961, 8824, 5819, 9429, 12867, 12867],
      32601: [9032, 8898, 5893, 9503, 12961, 12961],
      32801: [9103, 8968, 5963, 9577, 13052, 13052],
      33001: [9173, 9038, 6033, 9651, 13143, 13143],
      33201: [9244, 9111, 6106, 9725, 13238, 13238],
      33401: [9315, 9181, 6176, 9798, 13329, 13329],
      33601: [9386, 9255, 6250, 9872, 13423, 13423],
      33801: [9457, 9325, 6320, 9946, 13514, 13514],
      34001: [9528, 9395, 6390, 10020, 13605, 13605],
      34201: [9598, 9468, 6463, 10094, 13700, 13700],
      34401: [9669, 9538, 6533, 10167, 13791, 13791],
      34601: [9740, 9608, 6603, 10241, 13885, 13885],
      34801: [9811, 9682, 6677, 10315, 13976, 13976],
      35001: [9882, 9752, 6747, 10389, 14067, 14067],
      35201: [9952, 9822, 6817, 10462, 14162, 14162],
      35401: [10023, 9895, 6890, 10536, 14253, 14253],
      35601: [10094, 9965, 6960, 10610, 14347, 14347],
      35801: [10165, 10039, 7034, 10684, 14438, 14438],
      36001: [10236, 10109, 7104, 10757, 14529, 14529],
      36201: [10307, 10179, 7174, 10831, 14624, 14624],
      36401: [10377, 10252, 7247, 10905, 14715, 14715],
      36601: [10448, 10322, 7317, 10979, 14809, 14809],
      36801: [10519, 10392, 7387, 11053, 14900, 14900],
      37001: [10590, 10466, 7461, 11126, 14991, 14991],
      37201: [10661, 10536, 7531, 11200, 15086, 15086],
      37401: [10731, 10609, 7604, 11274, 15177, 15177],
      37601: [10802, 10676, 7671, 11348, 15264, 15264],
      37801: [10873, 10746, 7741, 11421, 15348, 15348],
      38001: [10943, 10816, 7811, 11495, 15432, 15432],
      38201: [11014, 10889, 7884, 11568, 15516, 15516],
      38401: [11086, 10959, 7954, 11642, 15600, 15600],
      38601: [11170, 11043, 8038, 11715, 15684, 15684],
      38801: [11254, 11127, 8122, 11789, 15768, 15768],
      39001: [11338, 11211, 8206, 11862, 15852, 15852],
      39201: [11422, 11295, 8290, 11936, 15936, 15936],
      39401: [11506, 11379, 8374, 12009, 16020, 16020],
      39601: [11590, 11463, 8458, 12083, 16104, 16104],
      39801: [11674, 11547, 8542, 12157, 16188, 16188],
      40001: [11758, 11631, 8626, 12230, 16272, 16272],
      40201: [11842, 11715, 8710, 12304, 16356, 16356],
      40401: [11926, 11799, 8794, 12377, 16440, 16440],
      40601: [12010, 11883, 8878, 12451, 16524, 16524],
      40801: [12094, 11967, 8962, 12524, 16608, 16608],
      41001: [12178, 12051, 9046, 12598, 16692, 16692],
      41201: [12262, 12135, 9130, 12671, 16776, 16776],
      41401: [12346, 12219, 9214, 12745, 16860, 16860],
      41601: [12430, 12303, 9298, 12818, 16944, 16944],
      41801: [12514, 12387, 9382, 12892, 17028, 17028],
      42001: [12598, 12471, 9466, 12966, 17112, 17112],
      42201: [12682, 12555, 9550, 13039, 17196, 17196],
      42401: [12766, 12639, 9634, 13113, 17280, 17280],
      42601: [12850, 12723, 9718, 13186, 17364, 17364],
      42801: [12934, 12807, 9802, 13260, 17448, 17448],
      43001: [13018, 12891, 9886, 13333, 17532, 17532],
      43201: [13102, 12975, 9970, 13407, 17616, 17616],
      43401: [13186, 13059, 10054, 13480, 17700, 17700],
      43601: [13270, 13143, 10138, 13554, 17784, 17784],
      43801: [13354, 13227, 10222, 13627, 17868, 17868],
      44001: [13438, 13311, 10306, 13701, 17952, 17952],
      44201: [13522, 13395, 10390, 13775, 18036, 18036],
      44401: [13606, 13479, 10474, 13848, 18120, 18120],
      44601: [13690, 13563, 10558, 13922, 18204, 18204],
      44801: [13774, 13647, 10642, 13995, 18288, 18288],
      45001: [13858, 13731, 10726, 14069, 18372, 18372],
      45201: [13942, 13815, 10810, 14142, 18456, 18456],
      45401: [14026, 13899, 10894, 14216, 18540, 18540],
      45601: [14110, 13983, 10978, 14289, 18624, 18624],
      45801: [14194, 14067, 11062, 14363, 18708, 18708],
      46001: [14278, 14151, 11146, 14436, 18792, 18792],
      46201: [14362, 14235, 11230, 14510, 18876, 18876],
      46401: [14446, 14319, 11314, 14584, 18960, 18960],
      46601: [14530, 14403, 11398, 14657, 19044, 19044],
      46801: [14614, 14487, 11482, 14731, 19128, 19128],
      47001: [14698, 14571, 11566, 14804, 19212, 19212],
      47201: [14782, 14655, 11650, 14878, 19296, 19296],
      47401: [14866, 14739, 11734, 14951, 19380, 19380],
      47601: [14950, 14823, 11818, 15025, 19464, 19464],
      47801: [15034, 14907, 11902, 15098, 19548, 19548],
      48001: [15118, 14991, 11986, 15172, 19632, 19632],
      48201: [15202, 15075, 12070, 15245, 19716, 19716],
      48401: [15286, 15159, 12154, 15319, 19800, 19800],
      48601: [15370, 15243, 12238, 15393, 19884, 19884],
      48801: [15454, 15327, 12322, 15466, 19968, 19968],
      49001: [15538, 15411, 12406, 15540, 20052, 20052],
      49201: [15622, 15495, 12490, 15613, 20136, 20136],
      49401: [15706, 15579, 12574, 15687, 20220, 20220],
      49601: [15790, 15663, 12658, 15760, 20304, 20304],
      49801: [15874, 15747, 12742, 15834, 20388, 20388],
      50001: [15958, 15831, 12831, 15907, 20472, 20472],
      50201: [16042, 15915, 12921, 15981, 20556, 20556],
      50401: [16126, 15999, 13011, 16054, 20640, 20640],
      50601: [16210, 16083, 13101, 16128, 20724, 20724],
      50801: [16294, 16167, 13191, 16202, 20808, 20808],
      51001: [16378, 16251, 13281, 16275, 20892, 20892],
      51201: [16487, 16367, 13402, 16374, 21001, 21001],
      51401: [16611, 16503, 13545, 16487, 21125, 21125],
      51601: [16735, 16640, 13687, 16601, 21249, 21249],
      51801: [16859, 16773, 13826, 16714, 21373, 21373],
      52001: [16983, 16909, 13969, 16828, 21497, 21497],
      52201: [17107, 17046, 14111, 16941, 21621, 21621],
      52401: [17231, 17182, 14254, 17055, 21745, 21745],
      52601: [17355, 17319, 14396, 17168, 21869, 21869],
      52801: [17479, 17455, 14539, 17282, 21993, 21993],
      53001: [17603, 17592, 14681, 17396, 22117, 22117],
      53201: [17727, 17728, 14824, 17509, 22241, 22241],
      53401: [17851, 17861, 14963, 17623, 22365, 22365],
      53601: [17975, 17998, 15105, 17736, 22489, 22489],
      53801: [18099, 18134, 15248, 17850, 22613, 22613],
      54001: [18223, 18271, 15390, 17963, 22737, 22737],
      54201: [18347, 18407, 15533, 18077, 22861, 22861],
      54401: [18471, 18544, 15675, 18190, 22985, 22985],
      54601: [18595, 18680, 15818, 18304, 23109, 23109],
      54801: [18719, 18817, 15960, 18417, 23233, 23233],
      55001: [18843, 18953, 16103, 18531, 23357, 23357],
      55201: [18967, 19086, 16242, 18645, 23481, 23481],
      55401: [19091, 19223, 16384, 18758, 23605, 23605],
      55601: [19215, 19359, 16527, 18872, 23729, 23729],
      55801: [19339, 19496, 16669, 18985, 23853, 23853],
      56001: [19463, 19632, 16812, 19099, 23977, 23977],
      56201: [19587, 19769, 16954, 19212, 24101, 24101],
      56401: [19711, 19905, 17097, 19326, 24225, 24225],
      56601: [19835, 20042, 17239, 19439, 24349, 24349],
      56801: [19959, 20175, 17378, 19553, 24473, 24473],
      57001: [20083, 20311, 17521, 19666, 24597, 24597],
      57201: [20207, 20448, 17663, 19780, 24721, 24721],
      57401: [20331, 20584, 17806, 19894, 24845, 24845],
      57601: [20455, 20721, 17948, 20007, 24969, 24969],
      57801: [20579, 20857, 18091, 20121, 25093, 25093],
      58001: [20703, 20980, 18220, 20234, 25217, 25217],
      58201: [20827, 21104, 18350, 20348, 25341, 25341],
      58401: [20951, 21228, 18480, 20461, 25465, 25465],
      58601: [21075, 21352, 18610, 20575, 25589, 25589],
      58801: [21199, 21476, 18740, 20688, 25713, 25713],
      59001: [21323, 21600, 18870, 20802, 25837, 25837],
      59201: [21447, 21724, 19e3, 20915, 25961, 25961],
      59401: [21571, 21848, 19130, 21029, 26085, 26085],
      59601: [21695, 21972, 19260, 21142, 26209, 26209],
      59801: [21819, 22096, 19390, 21256, 26333, 26333],
      60001: [21943, 22220, 19520, 21370, 26457, 26457],
      60201: [22067, 22344, 19650, 21483, 26581, 26581],
      60401: [22191, 22468, 19780, 21597, 26705, 26705],
      60601: [22315, 22592, 19910, 21710, 26829, 26829],
      60801: [22439, 22716, 20040, 21824, 26953, 26953],
      61001: [22563, 22840, 20170, 21937, 27077, 27077],
      61201: [22687, 22964, 20300, 22051, 27201, 27201],
      61401: [22811, 23088, 20430, 22164, 27325, 27325],
      61601: [22935, 23212, 20560, 22278, 27449, 27449],
      61801: [23059, 23336, 20690, 22391, 27573, 27573],
      62001: [23183, 23460, 20820, 22505, 27697, 27697],
      62201: [23307, 23584, 20950, 22619, 27821, 27821],
      62401: [23431, 23708, 21080, 22732, 27945, 27945],
      62601: [23555, 23832, 21210, 22846, 28069, 28069],
      62801: [23679, 23956, 21340, 22959, 28193, 28193],
      63001: [23803, 24080, 21470, 23073, 28317, 28317],
      63201: [23927, 24204, 21600, 23186, 28441, 28441],
      63401: [24051, 24328, 21730, 23300, 28565, 28565],
      63601: [24175, 24452, 21860, 23413, 28689, 28689],
      63801: [24299, 24576, 21990, 23527, 28813, 28813],
      64001: [24423, 24700, 22120, 23640, 28937, 28937],
      64201: [24547, 24824, 22250, 23754, 29061, 29061],
      64401: [24671, 24948, 22380, 23868, 29185, 29185],
      64601: [24799, 25083, 22521, 23981, 29309, 29309],
      64801: [24929, 25212, 22656, 24095, 29433, 29433],
      65001: [25059, 25346, 22796, 24208, 29557, 29557],
      65201: [25189, 25475, 22931, 24322, 29681, 29681],
      65401: [25319, 25605, 23066, 24435, 29805, 29805],
      65601: [25449, 25739, 23207, 24549, 29929, 29929],
      65801: [25579, 25868, 23342, 24662, 30053, 30053],
      66001: [25709, 26002, 23482, 24776, 30177, 30177],
      66201: [25839, 26132, 23617, 24890, 30301, 30301],
      66401: [25969, 26261, 23753, 25003, 30425, 30425],
      66601: [26099, 26395, 23893, 25117, 30549, 30549],
      66801: [26229, 26524, 24028, 25230, 30673, 30673],
      67001: [26359, 26653, 24163, 25344, 30797, 30797],
      67201: [26489, 26788, 24304, 25457, 30921, 30921],
      67401: [26619, 26917, 24439, 25571, 31045, 31045],
      67601: [26749, 27051, 24579, 25684, 31169, 31169],
      67801: [26879, 27180, 24714, 25798, 31293, 31293],
      68001: [27009, 27310, 24849, 25911, 31417, 31417],
      68201: [27139, 27444, 24990, 26025, 31541, 31541],
      68401: [27269, 27573, 25125, 26138, 31665, 31665],
      68601: [27399, 27702, 25260, 26252, 31789, 31789],
      68801: [27529, 27837, 25400, 26366, 31913, 31913],
      69001: [27659, 27966, 25536, 26479, 32037, 32037],
      69201: [27789, 28100, 25676, 26593, 32161, 32161],
      69401: [27919, 28229, 25811, 26706, 32285, 32285],
      69601: [28049, 28358, 25946, 26820, 32409, 32409],
      69801: [28179, 28493, 26087, 26933, 32533, 32533],
      70001: [28309, 28622, 26222, 27047, 32657, 32657],
      70201: [28439, 28751, 26357, 27160, 32781, 32781],
      70401: [28569, 28885, 26497, 27274, 32905, 32905],
      70601: [28699, 29015, 26632, 27387, 33029, 33029],
      70801: [28829, 29149, 26773, 27501, 33153, 33153],
      71001: [28959, 29278, 26908, 27615, 33277, 33277],
      71201: [29089, 29407, 27043, 27728, 33401, 33401],
      71401: [29219, 29542, 27183, 27842, 33525, 33525],
      71601: [29349, 29671, 27319, 27955, 33649, 33649],
      71801: [29479, 29805, 27459, 28069, 33773, 33773],
      72001: [29609, 29934, 27594, 28182, 33897, 33897],
      72201: [29739, 30063, 27729, 28296, 34021, 34021],
      72401: [29869, 30198, 27870, 28409, 34145, 34145],
      72601: [29999, 30327, 28005, 28523, 34269, 34269],
      72801: [30129, 30456, 28140, 28636, 34393, 34393],
      73001: [30259, 30590, 28280, 28750, 34517, 34517],
      73201: [30389, 30720, 28415, 28864, 34641, 34641],
      73401: [30519, 30854, 28556, 28977, 34765, 34765],
      73601: [30649, 30983, 28691, 29091, 34889, 34889],
      73801: [30779, 31112, 28826, 29204, 35013, 35013],
      74001: [30909, 31247, 28966, 29318, 35137, 35137],
      74201: [31039, 31376, 29102, 29431, 35261, 35261],
      74401: [31169, 31505, 29237, 29545, 35385, 35385],
      74601: [31299, 31639, 29377, 29658, 35509, 35509],
      74801: [31429, 31768, 29512, 29772, 35633, 35633],
      75001: [31559, 31903, 29653, 29885, 35757, 35757],
      75201: [31689, 32032, 29788, 29999, 35881, 35881],
      75401: [31819, 32161, 29923, 30113, 36005, 36005],
      75601: [31949, 32295, 30063, 30226, 36129, 36129],
      75801: [32079, 32425, 30198, 30340, 36253, 36253],
      76001: [32209, 32554, 30334, 30453, 36377, 36377],
      76201: [32339, 32688, 30474, 30567, 36501, 36501],
      76401: [32469, 32817, 30609, 30680, 36625, 36625],
      76601: [32599, 32952, 30749, 30794, 36749, 36749],
      76801: [32729, 33081, 30885, 30907, 36873, 36873],
      77001: [32859, 33210, 31020, 31021, 36997, 36997],
      77201: [32989, 33344, 31160, 31134, 37121, 37121],
      77401: [33119, 33473, 31295, 31248, 37245, 37245],
      77601: [33249, 33603, 31430, 31362, 37369, 37369],
      77801: [33379, 33737, 31571, 31475, 37493, 37493],
      78001: [33509, 33866, 31706, 31589, 37617, 37617],
      78201: [33639, 34e3, 31846, 31702, 37741, 37741],
      78401: [33769, 34130, 31981, 31816, 37865, 37865],
      78601: [33899, 34259, 32117, 31929, 37989, 37989],
      78801: [34029, 34393, 32257, 32043, 38113, 38113],
      79001: [34159, 34522, 32392, 32156, 38237, 38237],
      79201: [34289, 34651, 32527, 32270, 38361, 38361],
      79401: [34419, 34786, 32668, 32383, 38485, 38485],
      79601: [34549, 34915, 32803, 32497, 38609, 38609],
      79801: [34679, 35049, 32943, 32611, 38733, 38733],
      80001: [43, 44, 41, 41, 48, 48, "%"],
      80401: [44, 44, 41, 41, 49, 49, "%"],
      80801: [44, 44, 42, 41, 49, 49, "%"],
      82601: [44, 45, 42, 41, 49, 49, "%"],
      83801: [44, 45, 42, 42, 49, 49, "%"],
      84001: [44, 45, 43, 42, 49, 49, "%"],
      84401: [45, 45, 43, 42, 49, 49, "%"],
      86601: [45, 46, 43, 42, 49, 49, "%"],
      86801: [45, 46, 43, 42, 50, 50, "%"],
      87401: [45, 46, 44, 42, 50, 50, "%"],
      88801: [46, 46, 44, 42, 50, 50, "%"],
      89601: [46, 46, 44, 43, 50, 50, "%"],
      91001: [46, 46, 45, 43, 50, 50, "%"],
      91201: [46, 47, 45, 43, 50, 50, "%"],
      93601: [47, 47, 45, 43, 50, 50, "%"],
      94401: [47, 47, 45, 43, 51, 51, "%"],
      94801: [47, 47, 46, 43, 51, 51, "%"],
      96201: [47, 48, 46, 43, 51, 51, "%"],
      96401: [47, 48, 46, 44, 51, 51, "%"],
      98801: [48, 48, 46, 44, 51, 51, "%"],
      99201: [48, 48, 47, 44, 51, 51, "%"],
      102001: [48, 49, 47, 44, 51, 51, "%"],
      103401: [48, 49, 47, 44, 52, 52, "%"],
      104001: [48, 49, 48, 44, 52, 52, "%"],
      104201: [48, 49, 48, 45, 52, 52, "%"],
      104801: [49, 49, 48, 45, 52, 52, "%"],
      108201: [49, 50, 48, 45, 52, 52, "%"],
      109001: [49, 50, 49, 45, 52, 52, "%"],
      111601: [50, 50, 49, 45, 52, 52, "%"],
      113601: [50, 50, 49, 46, 52, 52, "%"],
      114201: [50, 50, 49, 46, 53, 53, "%"],
      114801: [50, 50, 50, 46, 53, 53, "%"],
      115401: [50, 51, 50, 46, 53, 53, "%"],
      119401: [51, 51, 50, 46, 53, 53, "%"],
      121201: [51, 51, 51, 46, 53, 53, "%"],
      123601: [51, 52, 51, 46, 53, 53, "%"],
      124601: [51, 52, 51, 47, 53, 53, "%"],
      127801: [51, 52, 51, 47, 54, 54, "%"],
      128201: [52, 52, 51, 47, 54, 54, "%"],
      128401: [52, 52, 52, 47, 54, 54, "%"],
      133201: [52, 53, 52, 47, 54, 54, "%"],
      136401: [52, 53, 53, 47, 54, 54, "%"],
      138001: [52, 53, 53, 48, 54, 54, "%"],
      138401: [53, 53, 53, 48, 54, 54, "%"],
      144201: [53, 54, 53, 48, 54, 54, "%"],
      144801: [53, 54, 53, 48, 55, 55, "%"],
      145401: [53, 54, 54, 48, 55, 55, "%"],
      150601: [54, 54, 54, 48, 55, 55, "%"],
      154801: [54, 54, 54, 49, 55, 55, "%"],
      157201: [54, 55, 55, 49, 55, 55, "%"],
      164801: [55, 55, 55, 49, 55, 55, "%"],
      167001: [55, 55, 55, 49, 56, 56, "%"],
      172801: [55, 56, 56, 49, 56, 56, "%"],
      176001: [55, 56, 56, 50, 56, 56, "%"],
      182201: [56, 56, 56, 50, 56, 56, "%"],
      197401: [56, 57, 57, 50, 57, 57, "%"],
      203601: [57, 57, 57, 50, 57, 57, "%"],
      204001: [57, 57, 57, 51, 57, 57, "%"],
      241401: [58, 58, 58, 51, 58, 58, "%"],
      242801: [58, 58, 58, 52, 58, 58, "%"],
      299601: [58, 58, 58, 53, 58, 58, "%"],
      310401: [59, 59, 59, 53, 59, 59, "%"],
      391201: [59, 59, 59, 54, 59, 59, "%"],
      434601: [60, 60, 60, 54, 60, 60, "%"],
      563401: [60, 60, 60, 55, 60, 60, "%"],
      724401: [61, 61, 61, 55, 61, 61, "%"],
      1006401: [61, 61, 61, 56, 61, 61, "%"]
    }
  },
  v = {
    1060: 34.618,
    1080: 34.523,
    1081: 34.533,
    1082: 34.958,
    1083: 34.608,
    1214: 32.848,
    1230: 31.158,
    1231: 31.988,
    1233: 30.518,
    1256: 33.073,
    1257: 31.123,
    1260: 33.088,
    1261: 30.518,
    1262: 31.523,
    1263: 32.223,
    1264: 32.548,
    1265: 33.023,
    1266: 33.118,
    1267: 33.863,
    1270: 32.638,
    1272: 34.578,
    1273: 34.848,
    1275: 32.883,
    1276: 32.798,
    1277: 32.478,
    1278: 32.368,
    1280: 33.178,
    1281: 33.238,
    1282: 32.213,
    1283: 32.186,
    1284: 31.713,
    1285: 32.483,
    1286: 32.108,
    1287: 32.338,
    1290: 33.368,
    1291: 32.633,
    1292: 32.283,
    1293: 33.288,
    1315: 34.993,
    1380: 33.156,
    1381: 33.693,
    1382: 33.443,
    1383: 32.618,
    1384: 33.693,
    1401: 32.843,
    1402: 32.208,
    1407: 33.888,
    1415: 34.018,
    1419: 34.118,
    1421: 34.733,
    1427: 34.428,
    1430: 35.808,
    1435: 34.053,
    1438: 35.795,
    1439: 35.291,
    1440: 33.708,
    1441: 32.978,
    1442: 33.99,
    1443: 33.963,
    1444: 34.34,
    1445: 34.151,
    1446: 33.751,
    1447: 34.9,
    1452: 34.008,
    1460: 35.456,
    1461: 35.123,
    1462: 34.813,
    1463: 33.988,
    1465: 34.558,
    1466: 34.4,
    1470: 34.296,
    1471: 34.526,
    1472: 33.995,
    1473: 34.13,
    1480: 33.318,
    1481: 32.683,
    1482: 33.793,
    1484: 34.878,
    1485: 34.538,
    1486: 34.293,
    1487: 34.596,
    1488: 34.745,
    1489: 33.691,
    1490: 33.79,
    1491: 33.498,
    1492: 34.87,
    1493: 33.71,
    1494: 33.826,
    1495: 34.306,
    1496: 34.081,
    1497: 34.311,
    1498: 34.535,
    1499: 34.37,
    1715: 35.48,
    1730: 35.505,
    1737: 35.288,
    1760: 35.941,
    1761: 35.906,
    1762: 35.145,
    1763: 35.475,
    1764: 35.721,
    1765: 35.378,
    1766: 34.835,
    1780: 34.338,
    1781: 35.13,
    1782: 35.526,
    1783: 35.196,
    1784: 34.965,
    1785: 34.736,
    1814: 35.693,
    1860: 35.983,
    1861: 34.748,
    1862: 36.056,
    1863: 35.333,
    1864: 34.738,
    1880: 34.393,
    1881: 34.643,
    1882: 34.988,
    1883: 35.056,
    1884: 35.548,
    1885: 35.563,
    1904: 34.298,
    1907: 34.073,
    1960: 33.818,
    1961: 33.543,
    1962: 34.528,
    1980: 32.013,
    1981: 34.228,
    1982: 33.888,
    1983: 33.858,
    1984: 34.238,
    2021: 35.238,
    2023: 35.448,
    2026: 35.178,
    2029: 34.773,
    2031: 34.753,
    2034: 35.283,
    2039: 35.828,
    2061: 35.347,
    2062: 35.178,
    2080: 34.848,
    2081: 35.133,
    2082: 35.243,
    2083: 35.118,
    2084: 34.828,
    2085: 34.868,
    2101: 35.168,
    2104: 35.328,
    2121: 34.268,
    2132: 34.958,
    2161: 34.718,
    2180: 34.513,
    2181: 33.978,
    2182: 34.008,
    2183: 34.173,
    2184: 33.879,
    2260: 35.613,
    2262: 35.418,
    2280: 35.658,
    2281: 34.873,
    2282: 35.523,
    2283: 35.708,
    2284: 35.018,
    2303: 35.963,
    2305: 36.133,
    2309: 34.869,
    2313: 35.963,
    2321: 34.913,
    2326: 35.263,
    2361: 35.388,
    2380: 34.598,
    2401: 35.493,
    2403: 35.298,
    2404: 35.603,
    2409: 35.378,
    2417: 35.543,
    2418: 35.668,
    2421: 35.468,
    2422: 35.918,
    2425: 36.093,
    2460: 35.498,
    2462: 35.943,
    2463: 35.893,
    2480: 34.948,
    2481: 35.343,
    2482: 34.858,
    2505: 35.058,
    2506: 35.758,
    2510: 35.233,
    2513: 34.983,
    2514: 34.673,
    2518: 34.733,
    2521: 35.662,
    2523: 34.643,
    2560: 34.588,
    2580: 34.743,
    2581: 34.343,
    2582: 34.708,
    2583: 34.648,
    2584: 35.178,
    "0114": 32.523,
    "0115": 32.145,
    "0117": 29.643,
    "0120": 32.088,
    "0123": 32.258,
    "0125": 32.363,
    "0126": 32.543,
    "0127": 33.228,
    "0128": 32.763,
    "0136": 32.548,
    "0138": 32.583,
    "0139": 32.513,
    "0140": 33.178,
    "0160": 30.613,
    "0162": 31.288,
    "0163": 31.193,
    "0180": 31.02,
    "0181": 33.358,
    "0182": 30.993,
    "0183": 32.285,
    "0184": 30.388,
    "0186": 30.348,
    "0187": 32.398,
    "0188": 32.923,
    "0191": 32.838,
    "0192": 33.058,
    "0305": 34.153,
    "0319": 35.258,
    "0330": 33.428,
    "0331": 35.163,
    "0360": 34.003,
    "0380": 33.693,
    "0381": 33.903,
    "0382": 34.308,
    "0428": 34.313,
    "0461": 33.888,
    "0480": 33.338,
    "0481": 33.863,
    "0482": 34.128,
    "0483": 33.768,
    "0484": 33.633,
    "0486": 33.438,
    "0488": 32.908,
    "0509": 34.818,
    "0512": 35.158,
    "0513": 33.938,
    "0560": 34.238,
    "0561": 34.873,
    "0562": 34.568,
    "0563": 34.998,
    "0580": 32.673,
    "0581": 34.343,
    "0582": 34.598,
    "0583": 34.143,
    "0584": 35.283,
    "0586": 34.318,
    "0604": 34.853,
    "0617": 35.018,
    "0642": 35.011,
    "0643": 34.816,
    "0662": 34.633,
    "0665": 34.088,
    "0680": 34.458,
    "0682": 35.043,
    "0683": 34.043,
    "0684": 34.598,
    "0685": 34.743,
    "0686": 35.063,
    "0687": 34.595,
    "0760": 34.768,
    "0761": 34.768,
    "0763": 35.043,
    "0764": 34.423,
    "0765": 34.728,
    "0767": 34.323,
    "0780": 33.008,
    "0781": 33.963,
    "0821": 35.038,
    "0834": 34.708,
    "0840": 35.138,
    "0860": 34.763,
    "0861": 34.873,
    "0862": 34.713,
    "0880": 34.588,
    "0881": 35.068,
    "0882": 35.103,
    "0883": 34.063,
    "0884": 35.173,
    "0885": 34.503,
    "0980": 34.818
  },
  R = {
    29: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 7],
      2101: [152, 0, 150, 2, 152, 37],
      2201: [163, 0, 158, 4, 163, 67],
      2301: [173, 0, 166, 7, 173, 97],
      2401: [184, 0, 175, 9, 184, 127],
      2501: [194, 0, 183, 11, 194, 157],
      2601: [205, 0, 191, 13, 205, 187],
      2701: [215, 0, 200, 15, 217, 217],
      2801: [217, 0, 200, 17, 247, 247],
      2901: [228, 0, 208, 19, 277, 277],
      3001: [238, 0, 216, 22, 307, 307],
      3101: [249, 0, 225, 24, 337, 337],
      3201: [259, 0, 233, 26, 367, 367],
      3301: [270, 0, 241, 28, 397, 397],
      3401: [272, 0, 241, 30, 427, 427],
      3501: [282, 0, 250, 32, 457, 457],
      3601: [293, 0, 258, 35, 487, 487],
      3701: [303, 0, 266, 37, 517, 517],
      3801: [314, 0, 275, 39, 547, 547],
      3901: [324, 0, 283, 41, 577, 577],
      4001: [327, 0, 283, 43, 607, 607],
      4101: [337, 0, 291, 45, 637, 637],
      4201: [348, 0, 300, 48, 667, 667],
      4301: [358, 0, 308, 50, 697, 697],
      4401: [369, 0, 316, 59, 727, 727],
      4501: [379, 0, 325, 80, 757, 757],
      4601: [381, 0, 325, 100, 787, 787],
      4701: [392, 0, 333, 121, 817, 817],
      4801: [402, 0, 341, 141, 844, 844],
      4901: [412, 0, 350, 161, 867, 867],
      5001: [422, 0, 358, 181, 892, 892],
      5101: [432, 0, 366, 201, 914, 914],
      5201: [442, 0, 375, 222, 939, 939],
      5301: [444, 0, 375, 242, 964, 964],
      5401: [454, 12, 384, 262, 987, 987],
      5501: [464, 34, 394, 282, 1012, 1012],
      5601: [474, 57, 404, 301, 1034, 1034],
      5701: [484, 79, 414, 321, 1058, 1058],
      5801: [494, 99, 423, 340, 1082, 1082],
      5901: [496, 122, 425, 360, 1104, 1104],
      6001: [506, 144, 435, 379, 1129, 1129],
      6101: [516, 167, 445, 398, 1151, 1151],
      6201: [526, 189, 455, 418, 1175, 1175],
      6301: [536, 212, 465, 438, 1199, 1199],
      6401: [546, 234, 475, 457, 1221, 1221],
      6501: [548, 257, 476, 477, 1246, 1246],
      6601: [558, 279, 486, 496, 1268, 1268],
      6701: [568, 302, 496, 515, 1292, 1292],
      6801: [578, 324, 506, 535, 1316, 1316],
      6901: [588, 347, 516, 554, 1338, 1338],
      7001: [598, 367, 526, 574, 1363, 1363],
      7101: [600, 389, 528, 593, 1385, 1385],
      7201: [610, 412, 538, 613, 1409, 1409],
      7301: [620, 434, 547, 632, 1433, 1433],
      7401: [630, 457, 557, 652, 1455, 1455],
      7501: [640, 479, 567, 671, 1480, 1480],
      7601: [650, 502, 577, 691, 1502, 1502],
      7701: [667, 524, 587, 710, 1526, 1526],
      7801: [685, 547, 589, 730, 1550, 1550],
      7901: [703, 569, 599, 749, 1572, 1572],
      8001: [721, 592, 609, 769, 1597, 1597],
      8101: [739, 614, 619, 788, 1619, 1619],
      8201: [758, 634, 629, 808, 1643, 1643],
      8301: [776, 657, 639, 827, 1667, 1667],
      8401: [794, 679, 640, 847, 1689, 1689],
      8501: [812, 702, 650, 866, 1714, 1714],
      8601: [830, 724, 660, 886, 1736, 1736],
      8701: [849, 747, 670, 905, 1760, 1760],
      8801: [867, 769, 680, 925, 1784, 1784],
      8901: [885, 792, 690, 944, 1806, 1806],
      9001: [903, 814, 692, 964, 1831, 1831],
      9101: [921, 837, 701, 983, 1853, 1853],
      9201: [939, 859, 711, 1003, 1877, 1877],
      9301: [958, 882, 721, 1022, 1901, 1901],
      9401: [976, 902, 731, 1042, 1923, 1923],
      9501: [994, 924, 741, 1061, 1948, 1948],
      9601: [1012, 939, 742, 1081, 1970, 1970],
      9701: [1030, 957, 752, 1100, 1994, 1994],
      9801: [1049, 974, 761, 1120, 2018, 2018],
      9901: [1067, 994, 771, 1139, 2040, 2040],
      10001: [1085, 1012, 781, 1159, 2065, 2065],
      10101: [1103, 1029, 790, 1178, 2087, 2087],
      10201: [1121, 1046, 800, 1198, 2111, 2111],
      10301: [1140, 1065, 801, 1217, 2135, 2135],
      10401: [1158, 1082, 811, 1237, 2157, 2157],
      10501: [1176, 1099, 821, 1256, 2182, 2182],
      10601: [1194, 1116, 830, 1275, 2204, 2204],
      10701: [1212, 1136, 840, 1295, 2228, 2228],
      10801: [1230, 1153, 850, 1315, 2252, 2252],
      10901: [1249, 1170, 851, 1334, 2274, 2274],
      11001: [1267, 1187, 860, 1354, 2299, 2299],
      11101: [1285, 1207, 870, 1373, 2321, 2321],
      11201: [1303, 1224, 880, 1392, 2345, 2345],
      11301: [1321, 1241, 889, 1412, 2369, 2369],
      11401: [1339, 1258, 899, 1431, 2391, 2391],
      11501: [1358, 1277, 900, 1451, 2416, 2416],
      11601: [1376, 1294, 910, 1470, 2438, 2438],
      11701: [1394, 1311, 920, 1490, 2462, 2462],
      11801: [1412, 1329, 929, 1509, 2486, 2486],
      11901: [1430, 1348, 939, 1529, 2508, 2508],
      12001: [1449, 1365, 949, 1548, 2533, 2533],
      12101: [1467, 1382, 950, 1568, 2555, 2555],
      12201: [1485, 1402, 960, 1587, 2579, 2579],
      12301: [1503, 1419, 969, 1607, 2603, 2603],
      12401: [1521, 1436, 979, 1626, 2625, 2625],
      12501: [1540, 1453, 988, 1646, 2650, 2650],
      12601: [1558, 1472, 998, 1665, 2672, 2672],
      12701: [1576, 1489, 1008, 1685, 2696, 2696],
      12801: [1594, 1506, 1009, 1704, 2720, 2720],
      12901: [1612, 1524, 1019, 1724, 2742, 2742],
      13001: [1631, 1543, 1028, 1743, 2767, 2767],
      13101: [1649, 1560, 1038, 1763, 2789, 2789],
      13201: [1667, 1577, 1047, 1782, 2813, 2813],
      13301: [1685, 1594, 1057, 1802, 2840, 2840],
      13401: [1704, 1614, 1059, 1822, 2869, 2869],
      13501: [1722, 1631, 1068, 1841, 2898, 2898],
      13601: [1740, 1648, 1078, 1861, 2928, 2928],
      13701: [1759, 1665, 1087, 1881, 2957, 2957],
      13801: [1777, 1684, 1097, 1901, 2986, 2986],
      13901: [1796, 1701, 1107, 1920, 3015, 3015],
      14001: [1814, 1719, 1108, 1940, 3045, 3045],
      14101: [1833, 1736, 1118, 1960, 3074, 3074],
      14201: [1850, 1755, 1127, 1979, 3102, 3102],
      14301: [1868, 1772, 1137, 1997, 3130, 3130],
      14401: [1885, 1789, 1146, 2016, 3158, 3158],
      14501: [1903, 1809, 1156, 2035, 3187, 3187],
      14601: [1920, 1826, 1158, 2054, 3215, 3215],
      14701: [1937, 1843, 1167, 2072, 3243, 3243],
      14801: [1955, 1860, 1177, 2091, 3271, 3271],
      14901: [1972, 1879, 1186, 2110, 3300, 3300],
      15001: [1990, 1896, 1196, 2129, 3328, 3328],
      15101: [2007, 1914, 1206, 2148, 3356, 3356],
      15201: [2025, 1931, 1215, 2166, 3384, 3384],
      15301: [2042, 1950, 1217, 2185, 3417, 3417],
      15401: [2060, 1967, 1226, 2204, 3448, 3448],
      15501: [2077, 1984, 1236, 2223, 3479, 3479],
      15601: [2095, 2001, 1245, 2242, 3509, 3509],
      15701: [2112, 2021, 1255, 2260, 3540, 3540],
      15801: [2131, 2038, 1265, 2281, 3573, 3573],
      15901: [2153, 2062, 1267, 2306, 3603, 3603],
      16001: [2176, 2084, 1277, 2331, 3634, 3634],
      16101: [2199, 2109, 1287, 2355, 3665, 3665],
      16201: [2222, 2130, 1297, 2380, 3695, 3695],
      16301: [2244, 2155, 1307, 2405, 3728, 3728],
      16401: [2267, 2179, 1317, 2430, 3759, 3759],
      16501: [2290, 2201, 1319, 2454, 3789, 3789],
      16601: [2313, 2226, 1329, 2479, 3820, 3820],
      16701: [2335, 2247, 1339, 2504, 3851, 3851],
      16801: [2358, 2272, 1349, 2528, 3883, 3883],
      16901: [2381, 2294, 1359, 2553, 3914, 3914],
      17001: [2404, 2318, 1369, 2578, 3945, 3945],
      17101: [2426, 2340, 1371, 2603, 3975, 3975],
      17201: [2449, 2364, 1381, 2627, 4006, 4006],
      17301: [2472, 2386, 1391, 2652, 4039, 4039],
      17401: [2495, 2411, 1401, 2677, 4069, 4069],
      17501: [2517, 2435, 1411, 2701, 4100, 4100],
      17601: [2540, 2457, 1421, 2726, 4131, 4131],
      17701: [2563, 2481, 1431, 2751, 4161, 4161],
      17801: [2586, 2503, 1433, 2776, 4194, 4194],
      17901: [2608, 2528, 1443, 2800, 4225, 4225],
      18001: [2631, 2550, 1453, 2825, 4255, 4255],
      18101: [2654, 2574, 1463, 2850, 4286, 4286],
      18201: [2677, 2596, 1473, 2875, 4317, 4317],
      18301: [2699, 2620, 1483, 2899, 4350, 4350],
      18401: [2722, 2645, 1485, 2924, 4380, 4380],
      18501: [2745, 2667, 1495, 2949, 4411, 4411],
      18601: [2768, 2691, 1505, 2973, 4441, 4441],
      18701: [2790, 2713, 1515, 2998, 4472, 4472],
      18801: [2813, 2737, 1525, 3023, 4505, 4505],
      18901: [2836, 2759, 1535, 3048, 4536, 4536],
      19001: [2859, 2784, 1537, 3072, 4566, 4566],
      19101: [2881, 2806, 1547, 3097, 4597, 4597],
      19201: [2904, 2830, 1557, 3122, 4627, 4627],
      19301: [2927, 2854, 1567, 3147, 4660, 4660],
      19401: [2950, 2876, 1577, 3171, 4691, 4691],
      19501: [2972, 2901, 1587, 3196, 4722, 4722],
      19601: [2995, 2923, 1589, 3221, 4752, 4752],
      19701: [3018, 2947, 1599, 3246, 4783, 4783],
      19801: [3041, 2969, 1609, 3270, 4816, 4816],
      19901: [3063, 2993, 1619, 3295, 4846, 4846],
      20001: [3109, 3040, 1639, 3344, 4908, 4908],
      20201: [3154, 3085, 1650, 3394, 4971, 4971],
      20401: [3200, 3130, 1669, 3443, 5032, 5032],
      20601: [3245, 3175, 1687, 3493, 5094, 5094],
      20801: [3291, 3220, 1697, 3542, 5157, 5157],
      21001: [3336, 3264, 1716, 3592, 5218, 5218],
      21201: [3382, 3311, 1734, 3641, 5282, 5282],
      21401: [3427, 3356, 1744, 3691, 5343, 5343],
      21601: [3473, 3401, 1763, 3740, 5404, 5404],
      21801: [3518, 3446, 1781, 3789, 5468, 5468],
      22001: [3564, 3490, 1792, 3839, 5529, 5529],
      22201: [3609, 3535, 1810, 3888, 5593, 5593],
      22401: [3655, 3580, 1829, 3938, 5654, 5654],
      22601: [3700, 3625, 1847, 3987, 5715, 5715],
      22801: [3746, 3669, 1857, 4037, 5779, 5779],
      23001: [3793, 3714, 1876, 4088, 5841, 5841],
      23201: [3840, 3761, 1894, 4139, 5907, 5907],
      23401: [3887, 3806, 1905, 4190, 5969, 5969],
      23601: [3934, 3851, 1923, 4241, 6032, 6032],
      23801: [3982, 3895, 1942, 4292, 6098, 6098],
      24001: [4029, 3940, 1952, 4343, 6160, 6160],
      24201: [4076, 3985, 1970, 4395, 6226, 6226],
      24401: [4123, 4029, 1989, 4446, 6288, 6288],
      24601: [4170, 4077, 1999, 4497, 6351, 6351],
      24801: [4217, 4124, 2018, 4548, 6417, 6417],
      25001: [4265, 4168, 2036, 4599, 6479, 6479],
      25201: [4312, 4215, 2046, 4650, 6545, 6545],
      25401: [4359, 4260, 2065, 4701, 6607, 6607],
      25601: [4406, 4307, 2084, 4752, 6670, 6670],
      25801: [4453, 4352, 2094, 4803, 6736, 6736],
      26001: [4500, 4399, 2112, 4854, 6798, 6798],
      26201: [4547, 4444, 2131, 4906, 6864, 6864],
      26401: [4595, 4491, 2141, 4957, 6927, 6927],
      26601: [4642, 4536, 2160, 5008, 6989, 6989],
      26801: [4689, 4583, 2178, 5059, 7055, 7055],
      27001: [4736, 4627, 2188, 5110, 7117, 7117],
      27201: [4783, 4674, 2207, 5161, 7183, 7183],
      27401: [4830, 4719, 2225, 5212, 7245, 7245],
      27601: [4877, 4766, 2244, 5263, 7308, 7308],
      27801: [4925, 4813, 2254, 5314, 7374, 7374],
      28001: [4972, 4858, 2273, 5365, 7436, 7436],
      28201: [5019, 4905, 2291, 5417, 7502, 7502],
      28401: [5066, 4950, 2302, 5468, 7564, 7564],
      28601: [5113, 4997, 2320, 5519, 7627, 7627],
      28801: [5160, 5042, 2339, 5570, 7693, 7693],
      29001: [5208, 5089, 2349, 5621, 7755, 7755],
      29201: [5255, 5134, 2367, 5672, 7821, 7821],
      29401: [5302, 5181, 2386, 5723, 7883, 7883],
      29601: [5349, 5225, 2396, 5774, 7946, 7946],
      29801: [5396, 5272, 2415, 5825, 8012, 8012],
      30001: [5443, 5317, 2433, 5877, 8074, 8074],
      30201: [5491, 5364, 2444, 5928, 8140, 8140],
      30401: [5538, 5411, 2462, 5979, 8202, 8202],
      30601: [5585, 5456, 2481, 6030, 8265, 8265],
      30801: [5632, 5503, 2491, 6081, 8331, 8331],
      31001: [5679, 5548, 2509, 6132, 8393, 8393],
      31201: [5726, 5595, 2528, 6183, 8459, 8459],
      31401: [5773, 5640, 2556, 6234, 8521, 8521],
      31601: [5820, 5687, 2603, 6285, 8584, 8584],
      31801: [5868, 5731, 2648, 6336, 8650, 8650],
      32001: [5915, 5779, 2695, 6388, 8712, 8712],
      32201: [5962, 5824, 2741, 6439, 8778, 8778],
      32401: [6009, 5873, 2789, 6490, 8840, 8840],
      32601: [6056, 5919, 2835, 6541, 8903, 8903],
      32801: [6103, 5967, 2884, 6592, 8969, 8969],
      33001: [6151, 6013, 2929, 6643, 9031, 9031],
      33201: [6198, 6061, 2978, 6694, 9097, 9097],
      33401: [6245, 6110, 3026, 6745, 9159, 9159],
      33601: [6292, 6156, 3072, 6796, 9222, 9222],
      33801: [6339, 6204, 3120, 6848, 9288, 9288],
      34001: [6386, 6250, 3166, 6899, 9350, 9350],
      34201: [6434, 6298, 3215, 6950, 9416, 9416],
      34401: [6481, 6344, 3261, 7001, 9478, 9478],
      34601: [6528, 6392, 3309, 7052, 9541, 9541],
      34801: [6575, 6438, 3355, 7103, 9607, 9607],
      35001: [6622, 6487, 3403, 7154, 9669, 9669],
      35201: [6669, 6533, 3449, 7205, 9735, 9735],
      35401: [6716, 6581, 3497, 7256, 9797, 9797],
      35601: [6764, 6627, 3543, 7307, 9860, 9860],
      35801: [6811, 6675, 3592, 7359, 9926, 9926],
      36001: [6858, 6723, 3640, 7410, 9988, 9988],
      36201: [6905, 6769, 3686, 7461, 10054, 10054],
      36401: [6952, 6818, 3734, 7512, 10116, 10116],
      36601: [6999, 6864, 3780, 7563, 10179, 10179],
      36801: [7046, 6912, 3828, 7614, 10245, 10245],
      37001: [7094, 6958, 3874, 7665, 10307, 10307],
      37201: [7141, 7006, 3923, 7716, 10373, 10373],
      37401: [7188, 7052, 3969, 7767, 10435, 10435],
      37601: [7235, 7100, 4017, 7818, 10498, 10498],
      37801: [7282, 7146, 4063, 7870, 10564, 10564],
      38001: [7329, 7195, 4111, 7921, 10626, 10626],
      38201: [7377, 7241, 4157, 7972, 10692, 10692],
      38401: [7424, 7289, 4206, 8023, 10754, 10754],
      38601: [7471, 7335, 4251, 8074, 10812, 10812],
      38801: [7518, 7381, 4297, 8125, 10870, 10870],
      39001: [7564, 7427, 4343, 8176, 10928, 10928],
      39201: [7611, 7475, 4392, 8226, 10986, 10986],
      39401: [7659, 7521, 4437, 8277, 11044, 11044],
      39601: [7717, 7579, 4495, 8328, 11102, 11102],
      39801: [7775, 7637, 4553, 8379, 11160, 11160],
      40001: [7833, 7695, 4611, 8430, 11218, 11218],
      40201: [7891, 7753, 4669, 8481, 11277, 11277],
      40401: [7949, 7811, 4727, 8532, 11334, 11334],
      40601: [8007, 7869, 4785, 8583, 11392, 11392],
      40801: [8065, 7927, 4843, 8633, 11450, 11450],
      41001: [8123, 7985, 4901, 8684, 11508, 11508],
      41201: [8181, 8043, 4959, 8735, 11566, 11566],
      41401: [8239, 8101, 5017, 8786, 11624, 11624],
      41601: [8297, 8159, 5075, 8837, 11682, 11682],
      41801: [8355, 8217, 5133, 8888, 11740, 11740],
      42001: [8413, 8275, 5191, 8939, 11798, 11798],
      42201: [8471, 8333, 5249, 8990, 11856, 11856],
      42401: [8529, 8391, 5307, 9040, 11914, 11914],
      42601: [8587, 8449, 5365, 9091, 11972, 11972],
      42801: [8645, 8507, 5423, 9142, 12030, 12030],
      43001: [8703, 8565, 5481, 9193, 12088, 12088],
      43201: [8761, 8623, 5539, 9244, 12146, 12146],
      43401: [8819, 8681, 5597, 9295, 12204, 12204],
      43601: [8877, 8739, 5656, 9346, 12262, 12262],
      43801: [8935, 8797, 5713, 9396, 12320, 12320],
      44001: [8993, 8855, 5771, 9447, 12378, 12378],
      44201: [9051, 8913, 5829, 9498, 12436, 12436],
      44401: [9109, 8971, 5887, 9549, 12494, 12494],
      44601: [9167, 9029, 5945, 9600, 12552, 12552],
      44801: [9225, 9087, 6003, 9651, 12610, 12610],
      45001: [9283, 9145, 6061, 9702, 12668, 12668],
      45201: [9341, 9203, 6119, 9753, 12727, 12727],
      45401: [9399, 9261, 6177, 9803, 12784, 12784],
      45601: [9457, 9319, 6235, 9854, 12842, 12842],
      45801: [9515, 9377, 6293, 9905, 12900, 12900],
      46001: [9573, 9435, 6351, 9956, 12958, 12958],
      46201: [9631, 9493, 6409, 10007, 13016, 13016],
      46401: [9689, 9551, 6467, 10058, 13074, 13074],
      46601: [9747, 9609, 6525, 10109, 13132, 13132],
      46801: [9805, 9667, 6583, 10160, 13190, 13190],
      47001: [9863, 9725, 6641, 10210, 13248, 13248],
      47201: [9921, 9783, 6699, 10261, 13306, 13306],
      47401: [9979, 9841, 6757, 10312, 13364, 13364],
      47601: [10037, 9899, 6815, 10363, 13422, 13422],
      47801: [10095, 9957, 6873, 10414, 13480, 13480],
      48001: [10153, 10015, 6931, 10465, 13538, 13538],
      48201: [10211, 10073, 6989, 10516, 13596, 13596],
      48401: [10269, 10131, 7047, 10567, 13654, 13654],
      48601: [10327, 10189, 7106, 10617, 13712, 13712],
      48801: [10385, 10247, 7163, 10668, 13770, 13770],
      49001: [10443, 10305, 7221, 10719, 13828, 13828],
      49201: [10501, 10363, 7279, 10770, 13886, 13886],
      49401: [10559, 10421, 7337, 10821, 13944, 13944],
      49601: [10617, 10479, 7395, 10872, 14002, 14002],
      49801: [10675, 10537, 7453, 10923, 14060, 14060],
      50001: [10733, 10595, 7511, 10974, 14118, 14118],
      50201: [10791, 10653, 7569, 11025, 14177, 14177],
      50401: [10849, 10711, 7627, 11075, 14234, 14234],
      50601: [10907, 10769, 7685, 11126, 14292, 14292],
      50801: [10965, 10827, 7743, 11177, 14350, 14350],
      51001: [11023, 10885, 7801, 11228, 14408, 14408],
      51201: [11081, 10943, 7859, 11279, 14466, 14466],
      51401: [11139, 11001, 7917, 11330, 14524, 14524],
      51601: [11197, 11059, 7975, 11381, 14582, 14582],
      51801: [11255, 11117, 8033, 11431, 14640, 14640],
      52001: [11313, 11175, 8091, 11482, 14698, 14698],
      52201: [11371, 11233, 8149, 11533, 14756, 14756],
      52401: [11429, 11291, 8207, 11584, 14814, 14814],
      52601: [11487, 11349, 8265, 11635, 14872, 14872],
      52801: [11545, 11407, 8323, 11686, 14930, 14930],
      53001: [11603, 11465, 8381, 11737, 14988, 14988],
      53201: [11661, 11523, 8439, 11788, 15046, 15046],
      53401: [11721, 11581, 8497, 11840, 15106, 15106],
      53601: [11819, 11673, 8589, 11931, 15204, 15204],
      53801: [11917, 11767, 8684, 12022, 15302, 15302],
      54001: [12015, 11861, 8778, 12113, 15400, 15400],
      54201: [12113, 11956, 8872, 12204, 15498, 15498],
      54401: [12211, 12050, 8966, 12294, 15596, 15596],
      54601: [12309, 12144, 9061, 12385, 15694, 15694],
      54801: [12407, 12238, 9155, 12476, 15792, 15792],
      55001: [12505, 12330, 9247, 12567, 15890, 15890],
      55201: [12603, 12424, 9341, 12658, 15988, 15988],
      55401: [12701, 12519, 9435, 12749, 16086, 16086],
      55601: [12799, 12613, 9529, 12840, 16184, 16184],
      55801: [12897, 12707, 9624, 12931, 16282, 16282],
      56001: [12995, 12801, 9718, 13021, 16380, 16380],
      56201: [13093, 12896, 9812, 13112, 16478, 16478],
      56401: [13191, 12990, 9906, 13203, 16576, 16576],
      56601: [13289, 13082, 9998, 13294, 16674, 16674],
      56801: [13387, 13176, 10092, 13385, 16772, 16772],
      57001: [13485, 13270, 10187, 13476, 16870, 16870],
      57201: [13583, 13364, 10281, 13567, 16968, 16968],
      57401: [13681, 13459, 10375, 13658, 17066, 17066],
      57601: [13779, 13553, 10469, 13748, 17164, 17164],
      57801: [13877, 13647, 10564, 13839, 17262, 17262],
      58001: [13975, 13741, 10658, 13930, 17360, 17360],
      58201: [14073, 13836, 10752, 14021, 17458, 17458],
      58401: [14171, 13928, 10844, 14112, 17556, 17556],
      58601: [14269, 14022, 10938, 14203, 17654, 17654],
      58801: [14367, 14116, 11033, 14294, 17752, 17752],
      59001: [14465, 14210, 11127, 14385, 17850, 17850],
      59201: [14563, 14305, 11221, 14475, 17948, 17948],
      59401: [14661, 14399, 11315, 14566, 18046, 18046],
      59601: [14759, 14493, 11410, 14657, 18144, 18144],
      59801: [14857, 14587, 11504, 14748, 18242, 18242],
      60001: [14955, 14679, 11596, 14839, 18340, 18340],
      60201: [15053, 14773, 11690, 14930, 18438, 18438],
      60401: [15151, 14868, 11784, 15021, 18536, 18536],
      60601: [15249, 14962, 11878, 15111, 18634, 18634],
      60801: [15347, 15056, 11973, 15202, 18732, 18732],
      61001: [15445, 15151, 12068, 15293, 18830, 18830],
      61201: [15543, 15249, 12166, 15384, 18928, 18928],
      61401: [15641, 15347, 12264, 15475, 19026, 19026],
      61601: [15739, 15445, 12362, 15566, 19124, 19124],
      61801: [15837, 15543, 12460, 15657, 19222, 19222],
      62001: [15935, 15641, 12558, 15748, 19320, 19320],
      62201: [16033, 15739, 12656, 15838, 19418, 19418],
      62401: [16131, 15837, 12754, 15929, 19516, 19516],
      62601: [16229, 15935, 12852, 16020, 19614, 19614],
      62801: [16327, 16033, 12950, 16111, 19712, 19712],
      63001: [16425, 16131, 13048, 16202, 19810, 19810],
      63201: [16523, 16229, 13146, 16293, 19908, 19908],
      63401: [16621, 16327, 13244, 16384, 20006, 20006],
      63601: [16719, 16425, 13342, 16475, 20104, 20104],
      63801: [16817, 16523, 13440, 16565, 20202, 20202],
      64001: [16915, 16621, 13538, 16656, 20300, 20300],
      64201: [17013, 16719, 13636, 16747, 20398, 20398],
      64401: [17111, 16817, 13734, 16838, 20496, 20496],
      64601: [17209, 16915, 13832, 16929, 20594, 20594],
      64801: [17307, 17013, 13930, 17020, 20692, 20692],
      65001: [17405, 17111, 14028, 17111, 20790, 20790],
      65201: [17503, 17209, 14126, 17202, 20888, 20888],
      65401: [17601, 17307, 14224, 17292, 20986, 20986],
      65601: [17699, 17405, 14322, 17383, 21084, 21084],
      65801: [17797, 17503, 14420, 17474, 21182, 21182],
      66001: [17895, 17601, 14518, 17565, 21280, 21280],
      66201: [17993, 17699, 14616, 17656, 21378, 21378],
      66401: [18091, 17797, 14714, 17747, 21476, 21476],
      66601: [18189, 17895, 14812, 17838, 21574, 21574],
      66801: [18287, 17993, 14910, 17929, 21672, 21672],
      67001: [18385, 18091, 15008, 18019, 21770, 21770],
      67201: [18483, 18189, 15106, 18110, 21868, 21868],
      67401: [18581, 18287, 15204, 18201, 21966, 21966],
      67601: [18679, 18385, 15302, 18292, 22064, 22064],
      67801: [18777, 18483, 15400, 18383, 22162, 22162],
      68001: [18875, 18581, 15498, 18474, 22260, 22260],
      68201: [18973, 18679, 15596, 18565, 22358, 22358],
      68401: [19071, 18777, 15694, 18656, 22456, 22456],
      68601: [19169, 18875, 15792, 18746, 22554, 22554],
      68801: [19267, 18973, 15890, 18837, 22652, 22652],
      69001: [19365, 19071, 15988, 18928, 22750, 22750],
      69201: [19463, 19169, 16086, 19019, 22848, 22848],
      69401: [19561, 19267, 16184, 19110, 22946, 22946],
      69601: [19659, 19365, 16282, 19201, 23044, 23044],
      69801: [19757, 19463, 16380, 19292, 23142, 23142],
      70001: [19855, 19561, 16478, 19383, 23240, 23240],
      70201: [19953, 19659, 16576, 19473, 23338, 23338],
      70401: [20051, 19757, 16674, 19564, 23436, 23436],
      70601: [20149, 19855, 16772, 19655, 23534, 23534],
      70801: [20247, 19953, 16870, 19746, 23632, 23632],
      71001: [20345, 20051, 16968, 19837, 23730, 23730],
      71201: [20443, 20149, 17066, 19928, 23828, 23828],
      71401: [20541, 20247, 17164, 20019, 23926, 23926],
      71601: [20639, 20345, 17262, 20109, 24024, 24024],
      71801: [20737, 20443, 17360, 20200, 24122, 24122],
      72001: [20835, 20541, 17458, 20291, 24220, 24220],
      72201: [20933, 20639, 17556, 20382, 24318, 24318],
      72401: [21031, 20737, 17654, 20473, 24416, 24416],
      72601: [21129, 20835, 17752, 20564, 24514, 24514],
      72801: [21227, 20933, 17850, 20655, 24612, 24612],
      73001: [21325, 21031, 17948, 20746, 24710, 24710],
      73201: [21423, 21129, 18046, 20836, 24808, 24808],
      73401: [21521, 21227, 18144, 20927, 24906, 24906],
      73601: [21619, 21325, 18242, 21018, 25004, 25004],
      73801: [21717, 21423, 18340, 21109, 25102, 25102],
      74001: [21815, 21521, 18438, 21200, 25200, 25200],
      74201: [21913, 21619, 18536, 21291, 25298, 25298],
      74401: [22011, 21717, 18634, 21382, 25396, 25396],
      74601: [22109, 21815, 18732, 21473, 25494, 25494],
      74801: [22207, 21913, 18830, 21563, 25592, 25592],
      75001: [22305, 22011, 18928, 21654, 25690, 25690],
      75201: [22403, 22109, 19026, 21745, 25788, 25788],
      75401: [22501, 22207, 19124, 21836, 25886, 25886],
      75601: [22599, 22305, 19222, 21927, 25984, 25984],
      75801: [22697, 22403, 19320, 22018, 26082, 26082],
      76001: [22795, 22501, 19418, 22109, 26180, 26180],
      76201: [22893, 22599, 19516, 22200, 26278, 26278],
      76401: [22991, 22697, 19614, 22290, 26376, 26376],
      76601: [23089, 22795, 19712, 22381, 26474, 26474],
      76801: [23187, 22893, 19810, 22472, 26572, 26572],
      77001: [23285, 22991, 19908, 22563, 26670, 26670],
      77201: [23383, 23089, 20006, 22654, 26768, 26768],
      77401: [23481, 23187, 20104, 22745, 26866, 26866],
      77601: [23579, 23285, 20202, 22836, 26964, 26964],
      77801: [23677, 23383, 20300, 22927, 27062, 27062],
      78001: [23775, 23481, 20398, 23017, 27160, 27160],
      78201: [23873, 23579, 20496, 23108, 27258, 27258],
      78401: [23971, 23677, 20594, 23199, 27356, 27356],
      78601: [24069, 23775, 20692, 23290, 27454, 27454],
      78801: [24167, 23873, 20790, 23381, 27552, 27552],
      79001: [24265, 23971, 20888, 23472, 27650, 27650],
      79201: [24363, 24069, 20986, 23563, 27748, 27748],
      79401: [24461, 24167, 21084, 23654, 27846, 27846],
      79601: [24559, 24265, 21182, 23744, 27944, 27944],
      79801: [24657, 24363, 21280, 23835, 28042, 28042],
      80001: [31, 31, 27, 30, 35, 35, "%"],
      82601: [31, 31, 27, 30, 36, 36, "%"],
      83001: [32, 31, 27, 30, 36, 36, "%"],
      83201: [32, 31, 28, 30, 36, 36, "%"],
      83601: [32, 31, 28, 31, 36, 36, "%"],
      84601: [32, 32, 28, 31, 36, 36, "%"],
      87401: [32, 32, 29, 31, 36, 36, "%"],
      88001: [33, 32, 29, 31, 36, 36, "%"],
      89201: [33, 32, 29, 31, 37, 37, "%"],
      89601: [33, 32, 29, 32, 37, 37, "%"],
      89801: [33, 33, 29, 32, 37, 37, "%"],
      91801: [33, 33, 30, 32, 37, 37, "%"],
      93801: [34, 33, 30, 32, 37, 37, "%"],
      95601: [34, 34, 30, 32, 37, 37, "%"],
      96601: [34, 34, 30, 33, 37, 37, "%"],
      96801: [34, 34, 31, 33, 37, 37, "%"],
      97001: [34, 34, 31, 33, 38, 38, "%"],
      100201: [35, 34, 31, 33, 38, 38, "%"],
      102201: [35, 35, 32, 33, 38, 38, "%"],
      104801: [35, 35, 32, 34, 38, 38, "%"],
      106201: [35, 35, 32, 34, 39, 39, "%"],
      107601: [36, 35, 32, 34, 39, 39, "%"],
      108601: [36, 35, 33, 34, 39, 39, "%"],
      109801: [36, 36, 33, 34, 39, 39, "%"],
      114401: [36, 36, 33, 35, 39, 39, "%"],
      115601: [36, 36, 34, 35, 39, 39, "%"],
      116201: [37, 36, 34, 35, 39, 39, "%"],
      117401: [37, 36, 34, 35, 40, 40, "%"],
      118601: [37, 37, 34, 35, 40, 40, "%"],
      123401: [37, 37, 35, 35, 40, 40, "%"],
      125801: [37, 37, 35, 36, 40, 40, "%"],
      126401: [38, 37, 35, 36, 40, 40, "%"],
      129001: [38, 38, 35, 36, 40, 40, "%"],
      131201: [38, 38, 35, 36, 41, 41, "%"],
      132601: [38, 38, 36, 36, 41, 41, "%"],
      138401: [39, 38, 36, 36, 41, 41, "%"],
      140001: [39, 38, 36, 37, 41, 41, "%"],
      141201: [39, 39, 36, 37, 41, 41, "%"],
      143201: [39, 39, 37, 37, 41, 41, "%"],
      148601: [39, 39, 37, 37, 42, 42, "%"],
      153001: [40, 39, 37, 37, 42, 42, "%"],
      155801: [40, 39, 38, 37, 42, 42, "%"],
      156001: [40, 40, 38, 37, 42, 42, "%"],
      157601: [40, 40, 38, 38, 42, 42, "%"],
      170601: [40, 40, 39, 38, 42, 42, "%"],
      171001: [41, 40, 39, 38, 42, 42, "%"],
      171601: [41, 40, 39, 38, 43, 43, "%"],
      174401: [41, 41, 39, 38, 43, 43, "%"],
      180201: [41, 41, 39, 39, 43, 43, "%"],
      188601: [41, 41, 40, 39, 43, 43, "%"],
      193801: [42, 41, 40, 39, 43, 43, "%"],
      197801: [42, 42, 40, 39, 43, 43, "%"],
      202801: [42, 42, 40, 39, 44, 44, "%"],
      210601: [42, 42, 40, 40, 44, 44, "%"],
      210801: [42, 42, 41, 40, 44, 44, "%"],
      223601: [43, 42, 41, 40, 44, 44, "%"],
      228201: [43, 43, 41, 40, 44, 44, "%"],
      238801: [43, 43, 42, 40, 44, 44, "%"],
      247801: [43, 43, 42, 40, 45, 45, "%"],
      253401: [43, 43, 42, 41, 45, 45, "%"],
      264401: [44, 43, 42, 41, 45, 45, "%"],
      269601: [44, 44, 42, 41, 45, 45, "%"],
      275601: [44, 44, 43, 41, 45, 45, "%"],
      317801: [44, 44, 43, 42, 45, 45, "%"],
      318601: [44, 44, 43, 42, 46, 46, "%"],
      323001: [45, 44, 43, 42, 46, 46, "%"],
      325801: [45, 44, 44, 42, 46, 46, "%"],
      329601: [45, 45, 44, 42, 46, 46, "%"],
      398201: [45, 45, 45, 42, 46, 46, "%"],
      415401: [46, 45, 45, 42, 46, 46, "%"],
      423801: [46, 46, 45, 42, 46, 46, "%"],
      426001: [46, 46, 45, 43, 46, 46, "%"],
      446201: [46, 46, 45, 43, 47, 47, "%"],
      511801: [46, 46, 46, 43, 47, 47, "%"],
      581601: [47, 46, 46, 43, 47, 47, "%"],
      593401: [47, 47, 46, 43, 47, 47, "%"],
      646001: [47, 47, 46, 44, 47, 47, "%"],
      716601: [47, 47, 47, 44, 47, 47, "%"],
      743801: [47, 47, 47, 44, 48, 48, "%"],
      969401: [48, 47, 47, 44, 48, 48, "%"],
      989001: [48, 48, 47, 44, 48, 48, "%"],
      1194601: [48, 48, 48, 44, 48, 48, "%"],
      1336201: [48, 48, 48, 45, 48, 48, "%"]
    },
    30: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 7],
      2101: [152, 0, 150, 2, 152, 38],
      2201: [163, 0, 158, 4, 163, 69],
      2301: [173, 0, 166, 7, 173, 100],
      2401: [184, 0, 175, 9, 184, 131],
      2501: [194, 0, 183, 11, 194, 162],
      2601: [205, 0, 191, 13, 205, 193],
      2701: [215, 0, 200, 15, 224, 224],
      2801: [217, 0, 200, 17, 255, 255],
      2901: [228, 0, 208, 19, 286, 286],
      3001: [238, 0, 216, 22, 317, 317],
      3101: [249, 0, 225, 24, 348, 348],
      3201: [259, 0, 233, 26, 379, 379],
      3301: [270, 0, 241, 28, 410, 410],
      3401: [272, 0, 241, 30, 441, 441],
      3501: [282, 0, 250, 32, 472, 472],
      3601: [293, 0, 258, 35, 503, 503],
      3701: [303, 0, 266, 37, 534, 534],
      3801: [314, 0, 275, 39, 565, 565],
      3901: [324, 0, 283, 41, 596, 596],
      4001: [327, 0, 283, 43, 627, 627],
      4101: [337, 0, 291, 45, 658, 658],
      4201: [348, 0, 300, 48, 689, 689],
      4301: [358, 0, 308, 50, 720, 720],
      4401: [369, 0, 316, 60, 751, 751],
      4501: [379, 0, 325, 81, 782, 782],
      4601: [381, 0, 325, 102, 813, 813],
      4701: [392, 0, 333, 123, 844, 844],
      4801: [402, 0, 341, 144, 873, 873],
      4901: [412, 0, 350, 165, 896, 896],
      5001: [422, 0, 358, 186, 922, 922],
      5101: [432, 0, 366, 206, 945, 945],
      5201: [442, 0, 375, 227, 971, 971],
      5301: [444, 0, 375, 248, 997, 997],
      5401: [454, 12, 384, 269, 1020, 1020],
      5501: [464, 36, 394, 289, 1045, 1045],
      5601: [474, 59, 404, 309, 1068, 1068],
      5701: [484, 82, 414, 329, 1093, 1093],
      5801: [494, 103, 423, 350, 1119, 1119],
      5901: [496, 126, 425, 370, 1141, 1141],
      6001: [506, 149, 435, 390, 1166, 1166],
      6101: [516, 173, 445, 410, 1189, 1189],
      6201: [526, 196, 455, 430, 1214, 1214],
      6301: [536, 219, 465, 450, 1240, 1240],
      6401: [546, 242, 475, 470, 1262, 1262],
      6501: [548, 266, 476, 491, 1287, 1287],
      6601: [558, 289, 486, 511, 1310, 1310],
      6701: [568, 312, 496, 531, 1335, 1335],
      6801: [578, 335, 506, 551, 1361, 1361],
      6901: [588, 359, 516, 571, 1383, 1383],
      7001: [598, 379, 526, 591, 1408, 1408],
      7101: [600, 402, 528, 611, 1431, 1431],
      7201: [610, 426, 538, 632, 1456, 1456],
      7301: [620, 449, 547, 652, 1482, 1482],
      7401: [631, 472, 557, 672, 1504, 1504],
      7501: [650, 495, 567, 692, 1529, 1529],
      7601: [668, 519, 577, 712, 1552, 1552],
      7701: [687, 542, 587, 732, 1577, 1577],
      7801: [706, 565, 589, 753, 1603, 1603],
      7901: [725, 588, 599, 773, 1625, 1625],
      8001: [744, 612, 609, 793, 1650, 1650],
      8101: [762, 635, 619, 813, 1673, 1673],
      8201: [781, 656, 629, 833, 1698, 1698],
      8301: [800, 679, 639, 853, 1724, 1724],
      8401: [819, 702, 640, 873, 1746, 1746],
      8501: [838, 725, 650, 894, 1771, 1771],
      8601: [856, 749, 660, 914, 1794, 1794],
      8701: [875, 772, 670, 934, 1819, 1819],
      8801: [894, 795, 680, 954, 1845, 1845],
      8901: [913, 818, 690, 974, 1867, 1867],
      9001: [932, 842, 692, 994, 1892, 1892],
      9101: [950, 865, 701, 1014, 1915, 1915],
      9201: [969, 888, 711, 1035, 1940, 1940],
      9301: [988, 911, 721, 1055, 1966, 1966],
      9401: [1007, 932, 731, 1075, 1988, 1988],
      9501: [1026, 955, 741, 1095, 2013, 2013],
      9601: [1044, 971, 742, 1115, 2036, 2036],
      9701: [1063, 989, 752, 1135, 2061, 2061],
      9801: [1082, 1007, 761, 1156, 2087, 2087],
      9901: [1101, 1028, 771, 1176, 2109, 2109],
      10001: [1120, 1045, 781, 1196, 2134, 2134],
      10101: [1138, 1063, 790, 1216, 2157, 2157],
      10201: [1157, 1081, 800, 1236, 2182, 2182],
      10301: [1176, 1101, 801, 1256, 2208, 2208],
      10401: [1195, 1119, 811, 1276, 2230, 2230],
      10501: [1214, 1136, 821, 1297, 2255, 2255],
      10601: [1232, 1154, 830, 1317, 2278, 2278],
      10701: [1251, 1174, 840, 1337, 2303, 2303],
      10801: [1270, 1192, 850, 1357, 2329, 2329],
      10901: [1289, 1209, 851, 1377, 2351, 2351],
      11001: [1307, 1227, 860, 1397, 2376, 2376],
      11101: [1326, 1247, 870, 1417, 2399, 2399],
      11201: [1345, 1265, 880, 1438, 2424, 2424],
      11301: [1364, 1282, 889, 1458, 2450, 2450],
      11401: [1383, 1300, 899, 1478, 2472, 2472],
      11501: [1401, 1320, 900, 1498, 2497, 2497],
      11601: [1420, 1338, 910, 1518, 2520, 2520],
      11701: [1439, 1355, 920, 1538, 2545, 2545],
      11801: [1458, 1373, 929, 1558, 2571, 2571],
      11901: [1477, 1393, 939, 1579, 2593, 2593],
      12001: [1495, 1411, 949, 1599, 2618, 2618],
      12101: [1514, 1429, 950, 1619, 2641, 2641],
      12201: [1533, 1449, 960, 1639, 2666, 2666],
      12301: [1552, 1466, 969, 1659, 2692, 2692],
      12401: [1571, 1484, 979, 1679, 2714, 2714],
      12501: [1589, 1502, 988, 1700, 2739, 2739],
      12601: [1608, 1522, 998, 1720, 2762, 2762],
      12701: [1627, 1540, 1008, 1740, 2787, 2787],
      12801: [1646, 1557, 1009, 1760, 2813, 2813],
      12901: [1665, 1575, 1019, 1780, 2835, 2835],
      13001: [1683, 1595, 1028, 1800, 2861, 2861],
      13101: [1702, 1613, 1038, 1820, 2883, 2883],
      13201: [1721, 1630, 1047, 1840, 2908, 2908],
      13301: [1740, 1648, 1057, 1861, 2936, 2936],
      13401: [1759, 1668, 1059, 1881, 2966, 2966],
      13501: [1778, 1686, 1068, 1902, 2997, 2997],
      13601: [1797, 1703, 1078, 1922, 3027, 3027],
      13701: [1816, 1721, 1087, 1943, 3057, 3057],
      13801: [1835, 1741, 1097, 1963, 3087, 3087],
      13901: [1854, 1759, 1107, 1983, 3118, 3118],
      14001: [1873, 1776, 1108, 2004, 3148, 3148],
      14101: [1892, 1794, 1118, 2024, 3178, 3178],
      14201: [1910, 1814, 1127, 2044, 3207, 3207],
      14301: [1929, 1832, 1137, 2063, 3236, 3236],
      14401: [1947, 1850, 1146, 2082, 3266, 3266],
      14501: [1965, 1870, 1156, 2102, 3295, 3295],
      14601: [1983, 1887, 1158, 2121, 3324, 3324],
      14701: [2001, 1905, 1167, 2141, 3353, 3353],
      14801: [2019, 1923, 1177, 2160, 3383, 3383],
      14901: [2037, 1943, 1186, 2180, 3412, 3412],
      15001: [2055, 1960, 1196, 2199, 3441, 3441],
      15101: [2073, 1978, 1206, 2218, 3470, 3470],
      15201: [2091, 1996, 1215, 2238, 3500, 3500],
      15301: [2109, 2016, 1217, 2257, 3534, 3534],
      15401: [2127, 2034, 1226, 2277, 3565, 3565],
      15501: [2146, 2051, 1236, 2296, 3597, 3597],
      15601: [2164, 2069, 1245, 2316, 3629, 3629],
      15701: [2182, 2089, 1255, 2335, 3661, 3661],
      15801: [2201, 2107, 1265, 2357, 3695, 3695],
      15901: [2224, 2132, 1267, 2382, 3726, 3726],
      16001: [2248, 2155, 1277, 2408, 3758, 3758],
      16101: [2271, 2180, 1287, 2433, 3790, 3790],
      16201: [2295, 2202, 1297, 2459, 3821, 3821],
      16301: [2319, 2228, 1307, 2485, 3856, 3856],
      16401: [2342, 2253, 1317, 2510, 3887, 3887],
      16501: [2366, 2276, 1319, 2536, 3919, 3919],
      16601: [2389, 2301, 1329, 2561, 3951, 3951],
      16701: [2413, 2323, 1339, 2587, 3982, 3982],
      16801: [2436, 2349, 1349, 2613, 4016, 4016],
      16901: [2460, 2371, 1359, 2638, 4048, 4048],
      17001: [2483, 2397, 1369, 2664, 4080, 4080],
      17101: [2507, 2419, 1371, 2689, 4111, 4111],
      17201: [2530, 2444, 1381, 2715, 4143, 4143],
      17301: [2554, 2467, 1391, 2741, 4177, 4177],
      17401: [2578, 2492, 1401, 2766, 4209, 4209],
      17501: [2601, 2518, 1411, 2792, 4241, 4241],
      17601: [2625, 2540, 1421, 2817, 4272, 4272],
      17701: [2648, 2565, 1431, 2843, 4304, 4304],
      17801: [2672, 2588, 1433, 2869, 4338, 4338],
      17901: [2695, 2613, 1443, 2894, 4370, 4370],
      18001: [2719, 2636, 1453, 2920, 4402, 4402],
      18101: [2742, 2661, 1463, 2945, 4433, 4433],
      18201: [2766, 2684, 1473, 2971, 4465, 4465],
      18301: [2790, 2709, 1483, 2997, 4499, 4499],
      18401: [2813, 2734, 1485, 3022, 4531, 4531],
      18501: [2837, 2757, 1495, 3048, 4562, 4562],
      18601: [2860, 2782, 1505, 3073, 4594, 4594],
      18701: [2884, 2805, 1515, 3099, 4626, 4626],
      18801: [2907, 2830, 1525, 3125, 4660, 4660],
      18901: [2931, 2853, 1535, 3150, 4692, 4692],
      19001: [2954, 2878, 1537, 3176, 4723, 4723],
      19101: [2978, 2901, 1547, 3201, 4755, 4755],
      19201: [3001, 2926, 1557, 3227, 4787, 4787],
      19301: [3025, 2951, 1567, 3253, 4821, 4821],
      19401: [3049, 2974, 1577, 3278, 4852, 4852],
      19501: [3072, 2999, 1587, 3304, 4884, 4884],
      19601: [3096, 3022, 1589, 3329, 4916, 4916],
      19701: [3119, 3047, 1599, 3355, 4948, 4948],
      19801: [3143, 3070, 1609, 3381, 4982, 4982],
      19901: [3166, 3095, 1619, 3406, 5013, 5013],
      20001: [3213, 3143, 1639, 3457, 5077, 5077],
      20201: [3261, 3190, 1650, 3509, 5143, 5143],
      20401: [3308, 3236, 1669, 3560, 5206, 5206],
      20601: [3355, 3283, 1687, 3611, 5269, 5269],
      20801: [3402, 3329, 1697, 3662, 5335, 5335],
      21001: [3449, 3375, 1716, 3713, 5398, 5398],
      21201: [3496, 3424, 1734, 3765, 5464, 5464],
      21401: [3543, 3470, 1744, 3816, 5528, 5528],
      21601: [3590, 3517, 1763, 3867, 5591, 5591],
      21801: [3637, 3563, 1781, 3918, 5657, 5657],
      22001: [3684, 3609, 1792, 3969, 5720, 5720],
      22201: [3732, 3656, 1810, 4021, 5786, 5786],
      22401: [3779, 3702, 1829, 4072, 5849, 5849],
      22601: [3826, 3748, 1847, 4123, 5913, 5913],
      22801: [3873, 3795, 1857, 4174, 5979, 5979],
      23001: [3922, 3841, 1876, 4227, 6044, 6044],
      23201: [3970, 3890, 1894, 4280, 6111, 6111],
      23401: [4019, 3936, 1905, 4333, 6176, 6176],
      23601: [4068, 3982, 1923, 4386, 6241, 6241],
      23801: [4117, 4029, 1942, 4438, 6309, 6309],
      24001: [4165, 4075, 1952, 4491, 6374, 6374],
      24201: [4214, 4121, 1970, 4544, 6441, 6441],
      24401: [4263, 4168, 1989, 4597, 6506, 6506],
      24601: [4312, 4216, 1999, 4650, 6571, 6571],
      24801: [4360, 4265, 2018, 4703, 6639, 6639],
      25001: [4409, 4311, 2036, 4755, 6704, 6704],
      25201: [4458, 4360, 2046, 4808, 6771, 6771],
      25401: [4507, 4406, 2065, 4861, 6836, 6836],
      25601: [4555, 4455, 2084, 4914, 6901, 6901],
      25801: [4604, 4501, 2094, 4967, 6969, 6969],
      26001: [4653, 4550, 2112, 5020, 7034, 7034],
      26201: [4702, 4597, 2131, 5073, 7101, 7101],
      26401: [4750, 4645, 2141, 5126, 7166, 7166],
      26601: [4799, 4692, 2160, 5178, 7231, 7231],
      26801: [4848, 4740, 2178, 5231, 7299, 7299],
      27001: [4897, 4787, 2188, 5284, 7364, 7364],
      27201: [4945, 4835, 2207, 5337, 7431, 7431],
      27401: [4994, 4882, 2225, 5390, 7496, 7496],
      27601: [5043, 4930, 2244, 5443, 7561, 7561],
      27801: [5092, 4979, 2254, 5495, 7629, 7629],
      28001: [5140, 5026, 2273, 5548, 7694, 7694],
      28201: [5189, 5074, 2291, 5601, 7761, 7761],
      28401: [5238, 5121, 2302, 5654, 7826, 7826],
      28601: [5287, 5169, 2320, 5707, 7891, 7891],
      28801: [5335, 5216, 2339, 5760, 7959, 7959],
      29001: [5384, 5264, 2349, 5812, 8024, 8024],
      29201: [5433, 5311, 2367, 5865, 8091, 8091],
      29401: [5482, 5359, 2386, 5918, 8156, 8156],
      29601: [5530, 5406, 2396, 5971, 8221, 8221],
      29801: [5579, 5455, 2415, 6024, 8289, 8289],
      30001: [5628, 5501, 2433, 6077, 8354, 8354],
      30201: [5677, 5550, 2466, 6130, 8421, 8421],
      30401: [5725, 5598, 2515, 6182, 8486, 8486],
      30601: [5774, 5645, 2561, 6235, 8551, 8551],
      30801: [5823, 5693, 2610, 6288, 8619, 8619],
      31001: [5872, 5740, 2656, 6341, 8684, 8684],
      31201: [5921, 5788, 2705, 6394, 8751, 8751],
      31401: [5969, 5835, 2751, 6447, 8816, 8816],
      31601: [6018, 5884, 2800, 6499, 8881, 8881],
      31801: [6067, 5930, 2846, 6552, 8949, 8949],
      32001: [6115, 5979, 2895, 6605, 9014, 9014],
      32201: [6164, 6026, 2943, 6658, 9081, 9081],
      32401: [6213, 6076, 2993, 6711, 9146, 9146],
      32601: [6262, 6124, 3040, 6764, 9211, 9211],
      32801: [6311, 6174, 3090, 6817, 9279, 9279],
      33001: [6359, 6221, 3138, 6869, 9344, 9344],
      33201: [6408, 6271, 3188, 6922, 9411, 9411],
      33401: [6457, 6321, 3238, 6975, 9476, 9476],
      33601: [6506, 6369, 3285, 7028, 9541, 9541],
      33801: [6554, 6419, 3335, 7081, 9609, 9609],
      34001: [6603, 6466, 3383, 7134, 9674, 9674],
      34201: [6652, 6516, 3433, 7187, 9741, 9741],
      34401: [6701, 6564, 3480, 7239, 9806, 9806],
      34601: [6749, 6614, 3530, 7292, 9871, 9871],
      34801: [6798, 6661, 3578, 7345, 9939, 9939],
      35001: [6847, 6711, 3628, 7398, 10004, 10004],
      35201: [6896, 6759, 3675, 7451, 10071, 10071],
      35401: [6944, 6809, 3725, 7504, 10136, 10136],
      35601: [6993, 6856, 3773, 7556, 10201, 10201],
      35801: [7042, 6906, 3823, 7609, 10269, 10269],
      36001: [7091, 6956, 3873, 7662, 10334, 10334],
      36201: [7139, 7004, 3920, 7715, 10401, 10401],
      36401: [7188, 7054, 3970, 7768, 10466, 10466],
      36601: [7237, 7101, 4018, 7821, 10531, 10531],
      36801: [7286, 7151, 4068, 7874, 10599, 10599],
      37001: [7334, 7199, 4115, 7926, 10664, 10664],
      37201: [7383, 7249, 4165, 7979, 10731, 10731],
      37401: [7432, 7296, 4213, 8032, 10796, 10796],
      37601: [7481, 7346, 4263, 8085, 10861, 10861],
      37801: [7529, 7394, 4310, 8138, 10929, 10929],
      38001: [7578, 7444, 4360, 8191, 10994, 10994],
      38201: [7627, 7491, 4408, 8244, 11061, 11061],
      38401: [7676, 7541, 4458, 8296, 11126, 11126],
      38601: [7724, 7589, 4505, 8349, 11186, 11186],
      38801: [7773, 7636, 4553, 8402, 11246, 11246],
      39001: [7821, 7684, 4600, 8454, 11306, 11306],
      39201: [7870, 7734, 4650, 8507, 11366, 11366],
      39401: [7919, 7781, 4698, 8559, 11426, 11426],
      39601: [7979, 7841, 4758, 8612, 11486, 11486],
      39801: [8039, 7901, 4818, 8665, 11546, 11546],
      40001: [8099, 7961, 4878, 8717, 11606, 11606],
      40201: [8159, 8021, 4938, 8770, 11666, 11666],
      40401: [8219, 8081, 4998, 8823, 11726, 11726],
      40601: [8279, 8141, 5058, 8875, 11786, 11786],
      40801: [8339, 8201, 5118, 8928, 11846, 11846],
      41001: [8399, 8261, 5178, 8980, 11906, 11906],
      41201: [8459, 8321, 5238, 9033, 11966, 11966],
      41401: [8519, 8381, 5298, 9086, 12026, 12026],
      41601: [8579, 8441, 5358, 9138, 12086, 12086],
      41801: [8639, 8501, 5418, 9191, 12146, 12146],
      42001: [8699, 8561, 5478, 9243, 12206, 12206],
      42201: [8759, 8621, 5538, 9296, 12266, 12266],
      42401: [8819, 8681, 5598, 9349, 12326, 12326],
      42601: [8879, 8741, 5658, 9401, 12386, 12386],
      42801: [8939, 8801, 5718, 9454, 12446, 12446],
      43001: [8999, 8861, 5778, 9507, 12506, 12506],
      43201: [9059, 8921, 5838, 9559, 12566, 12566],
      43401: [9119, 8981, 5898, 9612, 12626, 12626],
      43601: [9179, 9041, 5958, 9664, 12686, 12686],
      43801: [9239, 9101, 6018, 9717, 12746, 12746],
      44001: [9299, 9161, 6078, 9770, 12806, 12806],
      44201: [9359, 9221, 6138, 9822, 12866, 12866],
      44401: [9419, 9281, 6198, 9875, 12926, 12926],
      44601: [9479, 9341, 6258, 9928, 12986, 12986],
      44801: [9539, 9401, 6318, 9980, 13046, 13046],
      45001: [9599, 9461, 6378, 10033, 13106, 13106],
      45201: [9659, 9521, 6438, 10085, 13166, 13166],
      45401: [9719, 9581, 6498, 10138, 13226, 13226],
      45601: [9779, 9641, 6558, 10191, 13286, 13286],
      45801: [9839, 9701, 6618, 10243, 13346, 13346],
      46001: [9899, 9761, 6678, 10296, 13406, 13406],
      46201: [9959, 9821, 6738, 10348, 13466, 13466],
      46401: [10019, 9881, 6798, 10401, 13526, 13526],
      46601: [10079, 9941, 6858, 10454, 13586, 13586],
      46801: [10139, 10001, 6918, 10506, 13646, 13646],
      47001: [10199, 10061, 6978, 10559, 13706, 13706],
      47201: [10259, 10121, 7038, 10612, 13766, 13766],
      47401: [10319, 10181, 7098, 10664, 13826, 13826],
      47601: [10379, 10241, 7158, 10717, 13886, 13886],
      47801: [10439, 10301, 7218, 10769, 13946, 13946],
      48001: [10499, 10361, 7278, 10822, 14006, 14006],
      48201: [10559, 10421, 7338, 10875, 14066, 14066],
      48401: [10619, 10481, 7398, 10927, 14126, 14126],
      48601: [10679, 10541, 7458, 10980, 14186, 14186],
      48801: [10739, 10601, 7518, 11032, 14246, 14246],
      49001: [10799, 10661, 7578, 11085, 14306, 14306],
      49201: [10859, 10721, 7638, 11138, 14366, 14366],
      49401: [10919, 10781, 7698, 11190, 14426, 14426],
      49601: [10979, 10841, 7758, 11243, 14486, 14486],
      49801: [11039, 10901, 7818, 11296, 14546, 14546],
      50001: [11099, 10961, 7878, 11348, 14606, 14606],
      50201: [11159, 11021, 7938, 11401, 14666, 14666],
      50401: [11219, 11081, 7998, 11453, 14726, 14726],
      50601: [11279, 11141, 8058, 11506, 14786, 14786],
      50801: [11339, 11201, 8118, 11559, 14846, 14846],
      51001: [11399, 11261, 8178, 11611, 14906, 14906],
      51201: [11459, 11321, 8238, 11664, 14966, 14966],
      51401: [11519, 11381, 8298, 11716, 15026, 15026],
      51601: [11579, 11441, 8358, 11769, 15086, 15086],
      51801: [11639, 11501, 8418, 11822, 15146, 15146],
      52001: [11699, 11561, 8478, 11874, 15206, 15206],
      52201: [11759, 11621, 8538, 11927, 15266, 15266],
      52401: [11819, 11681, 8598, 11980, 15326, 15326],
      52601: [11879, 11741, 8658, 12032, 15386, 15386],
      52801: [11939, 11801, 8718, 12085, 15446, 15446],
      53001: [11999, 11861, 8778, 12137, 15506, 15506],
      53201: [12059, 11921, 8838, 12190, 15566, 15566],
      53401: [12121, 11981, 8898, 12244, 15628, 15628],
      53601: [12221, 12076, 8993, 12337, 15728, 15728],
      53801: [12321, 12174, 9090, 12430, 15828, 15828],
      54001: [12421, 12271, 9188, 12522, 15928, 15928],
      54201: [12521, 12369, 9285, 12615, 16028, 16028],
      54401: [12621, 12466, 9383, 12707, 16128, 16128],
      54601: [12721, 12564, 9480, 12800, 16228, 16228],
      54801: [12821, 12661, 9578, 12893, 16328, 16328],
      55001: [12921, 12756, 9673, 12985, 16428, 16428],
      55201: [13021, 12854, 9770, 13078, 16528, 16528],
      55401: [13121, 12951, 9868, 13170, 16628, 16628],
      55601: [13221, 13049, 9965, 13263, 16728, 16728],
      55801: [13321, 13146, 10063, 13356, 16828, 16828],
      56001: [13421, 13244, 10160, 13448, 16928, 16928],
      56201: [13521, 13341, 10258, 13541, 17028, 17028],
      56401: [13621, 13439, 10355, 13634, 17128, 17128],
      56601: [13721, 13534, 10450, 13726, 17228, 17228],
      56801: [13821, 13631, 10548, 13819, 17328, 17328],
      57001: [13921, 13729, 10645, 13911, 17428, 17428],
      57201: [14021, 13826, 10743, 14004, 17528, 17528],
      57401: [14121, 13924, 10840, 14097, 17628, 17628],
      57601: [14221, 14021, 10938, 14189, 17728, 17728],
      57801: [14321, 14119, 11035, 14282, 17828, 17828],
      58001: [14421, 14216, 11133, 14375, 17928, 17928],
      58201: [14521, 14314, 11230, 14467, 18028, 18028],
      58401: [14621, 14409, 11325, 14560, 18128, 18128],
      58601: [14721, 14506, 11423, 14652, 18228, 18228],
      58801: [14821, 14604, 11520, 14745, 18328, 18328],
      59001: [14921, 14701, 11618, 14838, 18428, 18428],
      59201: [15021, 14799, 11715, 14930, 18528, 18528],
      59401: [15121, 14896, 11813, 15023, 18628, 18628],
      59601: [15221, 14994, 11910, 15115, 18728, 18728],
      59801: [15321, 15091, 12008, 15208, 18828, 18828],
      60001: [15421, 15186, 12103, 15301, 18928, 18928],
      60201: [15521, 15284, 12200, 15393, 19028, 19028],
      60401: [15621, 15381, 12298, 15486, 19128, 19128],
      60601: [15721, 15479, 12395, 15579, 19228, 19228],
      60801: [15821, 15576, 12493, 15671, 19328, 19328],
      61001: [15921, 15674, 12590, 15764, 19428, 19428],
      61201: [16021, 15774, 12690, 15856, 19528, 19528],
      61401: [16121, 15874, 12790, 15949, 19628, 19628],
      61601: [16221, 15974, 12890, 16042, 19728, 19728],
      61801: [16321, 16074, 12990, 16134, 19828, 19828],
      62001: [16421, 16174, 13090, 16227, 19928, 19928],
      62201: [16521, 16274, 13190, 16319, 20028, 20028],
      62401: [16621, 16374, 13290, 16412, 20128, 20128],
      62601: [16721, 16474, 13390, 16505, 20228, 20228],
      62801: [16821, 16574, 13490, 16597, 20328, 20328],
      63001: [16921, 16674, 13590, 16690, 20428, 20428],
      63201: [17021, 16774, 13690, 16783, 20528, 20528],
      63401: [17121, 16874, 13790, 16875, 20628, 20628],
      63601: [17221, 16974, 13890, 16968, 20728, 20728],
      63801: [17321, 17074, 13990, 17060, 20828, 20828],
      64001: [17421, 17174, 14090, 17153, 20928, 20928],
      64201: [17521, 17274, 14190, 17246, 21028, 21028],
      64401: [17621, 17374, 14290, 17338, 21128, 21128],
      64601: [17721, 17474, 14390, 17431, 21228, 21228],
      64801: [17821, 17574, 14490, 17523, 21328, 21328],
      65001: [17921, 17674, 14590, 17616, 21428, 21428],
      65201: [18021, 17774, 14690, 17709, 21528, 21528],
      65401: [18121, 17874, 14790, 17801, 21628, 21628],
      65601: [18221, 17974, 14890, 17894, 21728, 21728],
      65801: [18321, 18074, 14990, 17987, 21828, 21828],
      66001: [18421, 18174, 15090, 18079, 21928, 21928],
      66201: [18521, 18274, 15190, 18172, 22028, 22028],
      66401: [18621, 18374, 15290, 18264, 22128, 22128],
      66601: [18721, 18474, 15390, 18357, 22228, 22228],
      66801: [18821, 18574, 15490, 18450, 22328, 22328],
      67001: [18921, 18674, 15590, 18542, 22428, 22428],
      67201: [19021, 18774, 15690, 18635, 22528, 22528],
      67401: [19121, 18874, 15790, 18728, 22628, 22628],
      67601: [19221, 18974, 15890, 18820, 22728, 22728],
      67801: [19321, 19074, 15990, 18913, 22828, 22828],
      68001: [19421, 19174, 16090, 19005, 22928, 22928],
      68201: [19521, 19274, 16190, 19098, 23028, 23028],
      68401: [19621, 19374, 16290, 19191, 23128, 23128],
      68601: [19721, 19474, 16390, 19283, 23228, 23228],
      68801: [19821, 19574, 16490, 19376, 23328, 23328],
      69001: [19921, 19674, 16590, 19468, 23428, 23428],
      69201: [20021, 19774, 16690, 19561, 23528, 23528],
      69401: [20121, 19874, 16790, 19654, 23628, 23628],
      69601: [20221, 19974, 16890, 19746, 23728, 23728],
      69801: [20321, 20074, 16990, 19839, 23828, 23828],
      70001: [20421, 20174, 17090, 19932, 23928, 23928],
      70201: [20521, 20274, 17190, 20024, 24028, 24028],
      70401: [20621, 20374, 17290, 20117, 24128, 24128],
      70601: [20721, 20474, 17390, 20209, 24228, 24228],
      70801: [20821, 20574, 17490, 20302, 24328, 24328],
      71001: [20921, 20674, 17590, 20395, 24428, 24428],
      71201: [21021, 20774, 17690, 20487, 24528, 24528],
      71401: [21121, 20874, 17790, 20580, 24628, 24628],
      71601: [21221, 20974, 17890, 20672, 24728, 24728],
      71801: [21321, 21074, 17990, 20765, 24828, 24828],
      72001: [21421, 21174, 18090, 20858, 24928, 24928],
      72201: [21521, 21274, 18190, 20950, 25028, 25028],
      72401: [21621, 21374, 18290, 21043, 25128, 25128],
      72601: [21721, 21474, 18390, 21136, 25228, 25228],
      72801: [21821, 21574, 18490, 21228, 25328, 25328],
      73001: [21921, 21674, 18590, 21321, 25428, 25428],
      73201: [22021, 21774, 18690, 21413, 25528, 25528],
      73401: [22121, 21874, 18790, 21506, 25628, 25628],
      73601: [22221, 21974, 18890, 21599, 25728, 25728],
      73801: [22321, 22074, 18990, 21691, 25828, 25828],
      74001: [22421, 22174, 19090, 21784, 25928, 25928],
      74201: [22521, 22274, 19190, 21876, 26028, 26028],
      74401: [22621, 22374, 19290, 21969, 26128, 26128],
      74601: [22721, 22474, 19390, 22062, 26228, 26228],
      74801: [22821, 22574, 19490, 22154, 26328, 26328],
      75001: [22921, 22674, 19590, 22247, 26428, 26428],
      75201: [23021, 22774, 19690, 22340, 26528, 26528],
      75401: [23121, 22874, 19790, 22432, 26628, 26628],
      75601: [23221, 22974, 19890, 22525, 26728, 26728],
      75801: [23321, 23074, 19990, 22617, 26828, 26828],
      76001: [23421, 23174, 20090, 22710, 26928, 26928],
      76201: [23521, 23274, 20190, 22803, 27028, 27028],
      76401: [23621, 23374, 20290, 22895, 27128, 27128],
      76601: [23721, 23474, 20390, 22988, 27228, 27228],
      76801: [23821, 23574, 20490, 23081, 27328, 27328],
      77001: [23921, 23674, 20590, 23173, 27428, 27428],
      77201: [24021, 23774, 20690, 23266, 27528, 27528],
      77401: [24121, 23874, 20790, 23358, 27628, 27628],
      77601: [24221, 23974, 20890, 23451, 27728, 27728],
      77801: [24321, 24074, 20990, 23544, 27828, 27828],
      78001: [24421, 24174, 21090, 23636, 27928, 27928],
      78201: [24521, 24274, 21190, 23729, 28028, 28028],
      78401: [24621, 24374, 21290, 23821, 28128, 28128],
      78601: [24721, 24474, 21390, 23914, 28228, 28228],
      78801: [24821, 24574, 21490, 24007, 28328, 28328],
      79001: [24921, 24674, 21590, 24099, 28428, 28428],
      79201: [25021, 24774, 21690, 24192, 28528, 28528],
      79401: [25121, 24874, 21790, 24285, 28628, 28628],
      79601: [25221, 24974, 21890, 24377, 28728, 28728],
      79801: [25321, 25074, 21990, 24470, 28828, 28828],
      80001: [32, 31, 28, 31, 36, 36, "%"],
      80601: [32, 32, 28, 31, 36, 36, "%"],
      82601: [32, 32, 28, 31, 37, 37, "%"],
      83601: [32, 32, 29, 31, 37, 37, "%"],
      83801: [33, 32, 29, 31, 37, 37, "%"],
      84801: [33, 32, 29, 32, 37, 37, "%"],
      85201: [33, 33, 29, 32, 37, 37, "%"],
      87801: [33, 33, 30, 32, 37, 37, "%"],
      88801: [34, 33, 30, 32, 37, 37, "%"],
      89201: [34, 33, 30, 32, 38, 38, "%"],
      90401: [34, 34, 30, 32, 38, 38, "%"],
      91001: [34, 34, 30, 33, 38, 38, "%"],
      92201: [34, 34, 31, 33, 38, 38, "%"],
      94601: [35, 34, 31, 33, 38, 38, "%"],
      96201: [35, 35, 31, 33, 38, 38, "%"],
      97001: [35, 35, 31, 33, 39, 39, "%"],
      97201: [35, 35, 32, 33, 39, 39, "%"],
      98001: [35, 35, 32, 34, 39, 39, "%"],
      101201: [36, 35, 32, 34, 39, 39, "%"],
      102801: [36, 36, 33, 34, 39, 39, "%"],
      106201: [36, 36, 33, 34, 40, 40, "%"],
      106401: [36, 36, 33, 35, 40, 40, "%"],
      108601: [37, 36, 33, 35, 40, 40, "%"],
      109001: [37, 36, 34, 35, 40, 40, "%"],
      110401: [37, 37, 34, 35, 40, 40, "%"],
      116001: [37, 37, 35, 35, 40, 40, "%"],
      116201: [37, 37, 35, 36, 40, 40, "%"],
      117401: [38, 37, 35, 36, 41, 41, "%"],
      119401: [38, 38, 35, 36, 41, 41, "%"],
      124201: [38, 38, 36, 36, 41, 41, "%"],
      127601: [39, 38, 36, 36, 41, 41, "%"],
      128201: [39, 38, 36, 37, 41, 41, "%"],
      129601: [39, 39, 36, 37, 41, 41, "%"],
      131401: [39, 39, 36, 37, 42, 42, "%"],
      133401: [39, 39, 37, 37, 42, 42, "%"],
      139601: [40, 39, 37, 37, 42, 42, "%"],
      142001: [40, 40, 37, 37, 42, 42, "%"],
      142601: [40, 40, 37, 38, 42, 42, "%"],
      144001: [40, 40, 38, 38, 42, 42, "%"],
      148801: [40, 40, 38, 38, 43, 43, "%"],
      154401: [41, 40, 38, 38, 43, 43, "%"],
      156601: [41, 40, 39, 38, 43, 43, "%"],
      157001: [41, 41, 39, 38, 43, 43, "%"],
      161001: [41, 41, 39, 39, 43, 43, "%"],
      171401: [41, 41, 40, 39, 43, 43, "%"],
      171801: [41, 41, 40, 39, 44, 44, "%"],
      172601: [42, 41, 40, 39, 44, 44, "%"],
      175401: [42, 42, 40, 39, 44, 44, "%"],
      184601: [42, 42, 40, 40, 44, 44, "%"],
      189401: [42, 42, 41, 40, 44, 44, "%"],
      195601: [43, 42, 41, 40, 44, 44, "%"],
      199001: [43, 43, 41, 40, 44, 44, "%"],
      203001: [43, 43, 41, 40, 45, 45, "%"],
      211801: [43, 43, 42, 40, 45, 45, "%"],
      216401: [43, 43, 42, 41, 45, 45, "%"],
      225801: [44, 43, 42, 41, 45, 45, "%"],
      229601: [44, 44, 42, 41, 45, 45, "%"],
      240001: [44, 44, 43, 41, 45, 45, "%"],
      248201: [44, 44, 43, 41, 46, 46, "%"],
      261401: [44, 44, 43, 42, 46, 46, "%"],
      266801: [45, 44, 43, 42, 46, 46, "%"],
      271201: [45, 45, 43, 42, 46, 46, "%"],
      277001: [45, 45, 44, 42, 46, 46, "%"],
      319001: [45, 45, 44, 42, 47, 47, "%"],
      326001: [46, 45, 44, 42, 47, 47, "%"],
      327401: [46, 45, 45, 42, 47, 47, "%"],
      330201: [46, 45, 45, 43, 47, 47, "%"],
      331601: [46, 46, 45, 43, 47, 47, "%"],
      400201: [46, 46, 46, 43, 47, 47, "%"],
      419201: [47, 46, 46, 43, 47, 47, "%"],
      426401: [47, 47, 46, 43, 47, 47, "%"],
      446801: [47, 47, 46, 43, 48, 48, "%"],
      447801: [47, 47, 46, 44, 48, 48, "%"],
      514401: [47, 47, 47, 44, 48, 48, "%"],
      587001: [48, 47, 47, 44, 48, 48, "%"],
      597001: [48, 48, 47, 44, 48, 48, "%"],
      695401: [48, 48, 47, 45, 48, 48, "%"],
      720201: [48, 48, 48, 45, 48, 48, "%"],
      744601: [48, 48, 48, 45, 49, 49, "%"],
      978401: [49, 48, 48, 45, 49, 49, "%"],
      995001: [49, 49, 48, 45, 49, 49, "%"],
      1200601: [49, 49, 49, 45, 49, 49, "%"],
      1555401: [49, 49, 49, 46, 49, 49, "%"]
    },
    31: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 7],
      2101: [152, 0, 150, 2, 152, 39],
      2201: [163, 0, 158, 4, 163, 71],
      2301: [173, 0, 166, 7, 173, 103],
      2401: [184, 0, 175, 9, 184, 135],
      2501: [194, 0, 183, 11, 194, 167],
      2601: [205, 0, 191, 13, 205, 200],
      2701: [215, 0, 200, 15, 231, 231],
      2801: [217, 0, 200, 17, 263, 263],
      2901: [228, 0, 208, 19, 295, 295],
      3001: [238, 0, 216, 22, 327, 327],
      3101: [249, 0, 225, 24, 359, 359],
      3201: [259, 0, 233, 26, 391, 391],
      3301: [270, 0, 241, 28, 423, 423],
      3401: [272, 0, 241, 30, 455, 455],
      3501: [282, 0, 250, 32, 487, 487],
      3601: [293, 0, 258, 35, 519, 519],
      3701: [303, 0, 266, 37, 551, 551],
      3801: [314, 0, 275, 39, 583, 583],
      3901: [324, 0, 283, 41, 615, 615],
      4001: [327, 0, 283, 43, 647, 647],
      4101: [337, 0, 291, 45, 679, 679],
      4201: [348, 0, 300, 48, 711, 711],
      4301: [358, 0, 308, 50, 743, 743],
      4401: [369, 0, 316, 60, 775, 775],
      4501: [379, 0, 325, 82, 807, 807],
      4601: [381, 0, 325, 104, 839, 839],
      4701: [392, 0, 333, 125, 871, 871],
      4801: [402, 0, 341, 147, 901, 901],
      4901: [412, 0, 350, 168, 925, 925],
      5001: [422, 0, 358, 190, 951, 951],
      5101: [432, 0, 366, 211, 975, 975],
      5201: [442, 0, 375, 233, 1002, 1002],
      5301: [444, 0, 375, 254, 1029, 1029],
      5401: [454, 13, 384, 275, 1053, 1053],
      5501: [464, 37, 394, 297, 1079, 1079],
      5601: [474, 61, 404, 317, 1103, 1103],
      5701: [484, 85, 414, 338, 1129, 1129],
      5801: [494, 106, 423, 359, 1155, 1155],
      5901: [496, 130, 425, 380, 1178, 1178],
      6001: [506, 154, 435, 401, 1204, 1204],
      6101: [516, 178, 445, 421, 1228, 1228],
      6201: [526, 202, 455, 442, 1254, 1254],
      6301: [536, 226, 465, 463, 1280, 1280],
      6401: [546, 250, 475, 484, 1303, 1303],
      6501: [548, 274, 476, 505, 1329, 1329],
      6601: [558, 298, 486, 525, 1353, 1353],
      6701: [568, 322, 496, 546, 1379, 1379],
      6801: [578, 346, 506, 567, 1405, 1405],
      6901: [588, 370, 516, 588, 1428, 1428],
      7001: [598, 391, 526, 609, 1454, 1454],
      7101: [600, 415, 528, 630, 1478, 1478],
      7201: [611, 439, 538, 650, 1504, 1504],
      7301: [630, 463, 547, 671, 1530, 1530],
      7401: [649, 487, 557, 692, 1553, 1553],
      7501: [669, 511, 567, 713, 1579, 1579],
      7601: [688, 535, 577, 734, 1603, 1603],
      7701: [708, 559, 587, 754, 1629, 1629],
      7801: [727, 583, 589, 775, 1655, 1655],
      7901: [746, 607, 599, 796, 1678, 1678],
      8001: [766, 631, 609, 817, 1704, 1704],
      8101: [785, 655, 619, 838, 1728, 1728],
      8201: [805, 677, 629, 858, 1754, 1754],
      8301: [824, 701, 639, 879, 1780, 1780],
      8401: [843, 725, 640, 900, 1803, 1803],
      8501: [863, 749, 650, 921, 1829, 1829],
      8601: [882, 773, 660, 942, 1853, 1853],
      8701: [902, 797, 670, 962, 1879, 1879],
      8801: [921, 821, 680, 983, 1905, 1905],
      8901: [941, 845, 690, 1004, 1928, 1928],
      9001: [960, 869, 692, 1025, 1954, 1954],
      9101: [979, 893, 701, 1046, 1978, 1978],
      9201: [999, 917, 711, 1066, 2004, 2004],
      9301: [1018, 941, 721, 1087, 2030, 2030],
      9401: [1038, 962, 731, 1108, 2053, 2053],
      9501: [1057, 986, 741, 1129, 2079, 2079],
      9601: [1076, 1002, 742, 1150, 2103, 2103],
      9701: [1096, 1021, 752, 1171, 2129, 2129],
      9801: [1115, 1039, 761, 1191, 2155, 2155],
      9901: [1135, 1061, 771, 1212, 2178, 2178],
      10001: [1154, 1079, 781, 1233, 2204, 2204],
      10101: [1173, 1097, 790, 1254, 2228, 2228],
      10201: [1193, 1116, 800, 1275, 2254, 2254],
      10301: [1212, 1136, 801, 1295, 2280, 2280],
      10401: [1232, 1155, 811, 1316, 2303, 2303],
      10501: [1251, 1173, 821, 1337, 2329, 2329],
      10601: [1270, 1191, 830, 1358, 2353, 2353],
      10701: [1290, 1212, 840, 1379, 2379, 2379],
      10801: [1309, 1230, 850, 1399, 2405, 2405],
      10901: [1329, 1248, 851, 1420, 2428, 2428],
      11001: [1348, 1267, 860, 1441, 2454, 2454],
      11101: [1367, 1288, 870, 1462, 2478, 2478],
      11201: [1387, 1306, 880, 1483, 2504, 2504],
      11301: [1406, 1324, 889, 1504, 2530, 2530],
      11401: [1426, 1342, 899, 1524, 2553, 2553],
      11501: [1445, 1363, 900, 1545, 2579, 2579],
      11601: [1465, 1381, 910, 1566, 2603, 2603],
      11701: [1484, 1399, 920, 1587, 2629, 2629],
      11801: [1503, 1418, 929, 1608, 2655, 2655],
      11901: [1523, 1439, 939, 1628, 2678, 2678],
      12001: [1542, 1457, 949, 1649, 2704, 2704],
      12101: [1562, 1475, 950, 1670, 2728, 2728],
      12201: [1581, 1496, 960, 1691, 2754, 2754],
      12301: [1600, 1514, 969, 1712, 2780, 2780],
      12401: [1620, 1532, 979, 1732, 2803, 2803],
      12501: [1639, 1551, 988, 1753, 2829, 2829],
      12601: [1659, 1571, 998, 1774, 2853, 2853],
      12701: [1678, 1590, 1008, 1795, 2879, 2879],
      12801: [1698, 1608, 1009, 1816, 2905, 2905],
      12901: [1717, 1626, 1019, 1836, 2928, 2928],
      13001: [1736, 1647, 1028, 1857, 2954, 2954],
      13101: [1756, 1665, 1038, 1878, 2978, 2978],
      13201: [1775, 1683, 1047, 1899, 3004, 3004],
      13301: [1795, 1702, 1057, 1920, 3032, 3032],
      13401: [1814, 1722, 1059, 1941, 3064, 3064],
      13501: [1834, 1741, 1068, 1962, 3095, 3095],
      13601: [1854, 1759, 1078, 1983, 3126, 3126],
      13701: [1873, 1777, 1087, 2004, 3157, 3157],
      13801: [1893, 1798, 1097, 2025, 3189, 3189],
      13901: [1913, 1816, 1107, 2046, 3220, 3220],
      14001: [1932, 1834, 1108, 2067, 3251, 3251],
      14101: [1952, 1853, 1118, 2088, 3282, 3282],
      14201: [1971, 1873, 1127, 2108, 3312, 3312],
      14301: [1989, 1892, 1137, 2129, 3343, 3343],
      14401: [2008, 1910, 1146, 2149, 3373, 3373],
      14501: [2027, 1931, 1156, 2169, 3403, 3403],
      14601: [2045, 1949, 1158, 2189, 3433, 3433],
      14701: [2064, 1967, 1167, 2209, 3464, 3464],
      14801: [2083, 1985, 1177, 2229, 3494, 3494],
      14901: [2102, 2006, 1186, 2249, 3524, 3524],
      15001: [2120, 2024, 1196, 2269, 3554, 3554],
      15101: [2139, 2043, 1206, 2289, 3585, 3585],
      15201: [2158, 2061, 1215, 2309, 3615, 3615],
      15301: [2176, 2082, 1217, 2330, 3650, 3650],
      15401: [2195, 2100, 1226, 2350, 3683, 3683],
      15501: [2214, 2118, 1236, 2370, 3716, 3716],
      15601: [2232, 2136, 1245, 2390, 3748, 3748],
      15701: [2251, 2157, 1255, 2410, 3781, 3781],
      15801: [2271, 2176, 1265, 2432, 3817, 3817],
      15901: [2295, 2202, 1267, 2459, 3849, 3849],
      16001: [2320, 2225, 1277, 2485, 3882, 3882],
      16101: [2344, 2251, 1287, 2512, 3915, 3915],
      16201: [2368, 2274, 1297, 2538, 3948, 3948],
      16301: [2393, 2301, 1307, 2565, 3983, 3983],
      16401: [2417, 2327, 1317, 2591, 4016, 4016],
      16501: [2441, 2350, 1319, 2617, 4048, 4048],
      16601: [2466, 2376, 1329, 2644, 4081, 4081],
      16701: [2490, 2399, 1339, 2670, 4114, 4114],
      16801: [2514, 2426, 1349, 2697, 4149, 4149],
      16901: [2539, 2449, 1359, 2723, 4182, 4182],
      17001: [2563, 2475, 1369, 2750, 4215, 4215],
      17101: [2587, 2499, 1371, 2776, 4248, 4248],
      17201: [2612, 2524, 1381, 2803, 4280, 4280],
      17301: [2636, 2548, 1391, 2829, 4316, 4316],
      17401: [2660, 2574, 1401, 2856, 4348, 4348],
      17501: [2685, 2600, 1411, 2882, 4381, 4381],
      17601: [2709, 2623, 1421, 2909, 4414, 4414],
      17701: [2734, 2649, 1431, 2935, 4447, 4447],
      17801: [2758, 2673, 1433, 2962, 4482, 4482],
      17901: [2782, 2699, 1443, 2988, 4515, 4515],
      18001: [2807, 2722, 1453, 3015, 4548, 4548],
      18101: [2831, 2748, 1463, 3041, 4580, 4580],
      18201: [2855, 2772, 1473, 3067, 4613, 4613],
      18301: [2880, 2798, 1483, 3094, 4648, 4648],
      18401: [2904, 2824, 1485, 3120, 4681, 4681],
      18501: [2928, 2847, 1495, 3147, 4714, 4714],
      18601: [2953, 2873, 1505, 3173, 4747, 4747],
      18701: [2977, 2897, 1515, 3200, 4780, 4780],
      18801: [3001, 2923, 1525, 3226, 4815, 4815],
      18901: [3026, 2946, 1535, 3253, 4848, 4848],
      19001: [3050, 2972, 1537, 3279, 4880, 4880],
      19101: [3074, 2996, 1547, 3306, 4913, 4913],
      19201: [3099, 3022, 1557, 3332, 4946, 4946],
      19301: [3123, 3048, 1567, 3359, 4981, 4981],
      19401: [3148, 3071, 1577, 3385, 5014, 5014],
      19501: [3172, 3097, 1587, 3412, 5047, 5047],
      19601: [3196, 3121, 1589, 3438, 5079, 5079],
      19701: [3221, 3147, 1599, 3465, 5112, 5112],
      19801: [3245, 3170, 1609, 3491, 5148, 5148],
      19901: [3269, 3196, 1619, 3517, 5180, 5180],
      20001: [3318, 3246, 1639, 3570, 5246, 5246],
      20201: [3367, 3295, 1650, 3623, 5314, 5314],
      20401: [3415, 3343, 1669, 3676, 5379, 5379],
      20601: [3464, 3391, 1687, 3729, 5445, 5445],
      20801: [3513, 3438, 1697, 3782, 5513, 5513],
      21001: [3562, 3486, 1716, 3835, 5579, 5579],
      21201: [3610, 3537, 1734, 3888, 5647, 5647],
      21401: [3659, 3585, 1744, 3941, 5712, 5712],
      21601: [3708, 3633, 1763, 3994, 5778, 5778],
      21801: [3756, 3680, 1781, 4047, 5846, 5846],
      22001: [3805, 3728, 1792, 4100, 5911, 5911],
      22201: [3854, 3776, 1810, 4153, 5979, 5979],
      22401: [3902, 3824, 1829, 4206, 6045, 6045],
      22601: [3951, 3872, 1847, 4259, 6111, 6111],
      22801: [4e3, 3920, 1857, 4312, 6179, 6179],
      23001: [4050, 3968, 1876, 4366, 6246, 6246],
      23201: [4101, 4018, 1894, 4421, 6315, 6315],
      23401: [4151, 4066, 1905, 4475, 6383, 6383],
      23601: [4201, 4114, 1923, 4530, 6450, 6450],
      23801: [4252, 4162, 1942, 4585, 6520, 6520],
      24001: [4302, 4210, 1952, 4639, 6587, 6587],
      24201: [4352, 4258, 1970, 4694, 6656, 6656],
      24401: [4403, 4306, 1989, 4748, 6724, 6724],
      24601: [4453, 4356, 1999, 4803, 6791, 6791],
      24801: [4503, 4406, 2018, 4858, 6861, 6861],
      25001: [4554, 4454, 2036, 4912, 6928, 6928],
      25201: [4604, 4505, 2046, 4967, 6997, 6997],
      25401: [4654, 4553, 2065, 5021, 7065, 7065],
      25601: [4705, 4603, 2084, 5076, 7132, 7132],
      25801: [4755, 4651, 2094, 5131, 7202, 7202],
      26001: [4806, 4701, 2112, 5185, 7269, 7269],
      26201: [4856, 4749, 2131, 5240, 7338, 7338],
      26401: [4906, 4800, 2141, 5294, 7406, 7406],
      26601: [4957, 4848, 2160, 5349, 7473, 7473],
      26801: [5007, 4898, 2178, 5403, 7543, 7543],
      27001: [5057, 4946, 2188, 5458, 7610, 7610],
      27201: [5108, 4996, 2207, 5513, 7679, 7679],
      27401: [5158, 5044, 2225, 5567, 7747, 7747],
      27601: [5208, 5095, 2244, 5622, 7814, 7814],
      27801: [5259, 5145, 2254, 5676, 7884, 7884],
      28001: [5309, 5193, 2273, 5731, 7951, 7951],
      28201: [5359, 5243, 2291, 5786, 8020, 8020],
      28401: [5410, 5291, 2302, 5840, 8088, 8088],
      28601: [5460, 5342, 2320, 5895, 8155, 8155],
      28801: [5511, 5390, 2339, 5949, 8225, 8225],
      29001: [5561, 5440, 2356, 6004, 8292, 8292],
      29201: [5611, 5488, 2404, 6059, 8361, 8361],
      29401: [5662, 5538, 2455, 6113, 8429, 8429],
      29601: [5712, 5586, 2503, 6168, 8496, 8496],
      29801: [5762, 5637, 2553, 6222, 8566, 8566],
      30001: [5813, 5684, 2601, 6277, 8633, 8633],
      30201: [5863, 5735, 2651, 6332, 8702, 8702],
      30401: [5913, 5785, 2702, 6386, 8770, 8770],
      30601: [5964, 5833, 2750, 6441, 8837, 8837],
      30801: [6014, 5884, 2800, 6495, 8907, 8907],
      31001: [6064, 5932, 2848, 6550, 8974, 8974],
      31201: [6115, 5982, 2898, 6605, 9043, 9043],
      31401: [6165, 6030, 2946, 6659, 9111, 9111],
      31601: [6215, 6080, 2997, 6714, 9178, 9178],
      31801: [6266, 6128, 3045, 6768, 9248, 9248],
      32001: [6316, 6179, 3095, 6823, 9315, 9315],
      32201: [6367, 6228, 3144, 6878, 9384, 9384],
      32401: [6417, 6279, 3196, 6932, 9452, 9452],
      32601: [6467, 6328, 3245, 6987, 9519, 9519],
      32801: [6518, 6380, 3297, 7041, 9589, 9589],
      33001: [6568, 6429, 3346, 7096, 9656, 9656],
      33201: [6618, 6481, 3397, 7150, 9725, 9725],
      33401: [6669, 6532, 3449, 7205, 9793, 9793],
      33601: [6719, 6582, 3498, 7260, 9860, 9860],
      33801: [6769, 6633, 3550, 7314, 9930, 9930],
      34001: [6820, 6682, 3599, 7369, 9997, 9997],
      34201: [6870, 6734, 3650, 7423, 10066, 10066],
      34401: [6920, 6783, 3700, 7478, 10134, 10134],
      34601: [6971, 6835, 3751, 7533, 10201, 10201],
      34801: [7021, 6884, 3800, 7587, 10271, 10271],
      35001: [7072, 6935, 3852, 7642, 10338, 10338],
      35201: [7122, 6985, 3901, 7696, 10407, 10407],
      35401: [7172, 7036, 3953, 7751, 10475, 10475],
      35601: [7223, 7085, 4002, 7806, 10542, 10542],
      35801: [7273, 7137, 4053, 7860, 10612, 10612],
      36001: [7323, 7189, 4105, 7915, 10679, 10679],
      36201: [7374, 7238, 4154, 7969, 10748, 10748],
      36401: [7424, 7289, 4206, 8024, 10816, 10816],
      36601: [7474, 7338, 4255, 8078, 10883, 10883],
      36801: [7525, 7390, 4307, 8133, 10953, 10953],
      37001: [7575, 7439, 4356, 8188, 11020, 11020],
      37201: [7625, 7491, 4407, 8242, 11089, 11089],
      37401: [7676, 7540, 4456, 8297, 11157, 11157],
      37601: [7726, 7592, 4508, 8351, 11224, 11224],
      37801: [7777, 7641, 4557, 8406, 11294, 11294],
      38001: [7827, 7692, 4609, 8461, 11361, 11361],
      38201: [7877, 7741, 4658, 8515, 11430, 11430],
      38401: [7928, 7793, 4710, 8570, 11498, 11498],
      38601: [7978, 7842, 4759, 8624, 11560, 11560],
      38801: [8028, 7891, 4808, 8679, 11622, 11622],
      39001: [8078, 7940, 4857, 8733, 11684, 11684],
      39201: [8128, 7992, 4909, 8787, 11746, 11746],
      39401: [8179, 8041, 4958, 8842, 11808, 11808],
      39601: [8241, 8103, 5020, 8896, 11870, 11870],
      39801: [8303, 8165, 5082, 8950, 11932, 11932],
      40001: [8365, 8227, 5144, 9005, 11994, 11994],
      40201: [8427, 8289, 5206, 9059, 12056, 12056],
      40401: [8489, 8351, 5268, 9113, 12118, 12118],
      40601: [8551, 8413, 5330, 9168, 12180, 12180],
      40801: [8613, 8475, 5392, 9222, 12242, 12242],
      41001: [8675, 8537, 5454, 9277, 12304, 12304],
      41201: [8737, 8599, 5516, 9331, 12366, 12366],
      41401: [8799, 8661, 5578, 9385, 12428, 12428],
      41601: [8861, 8723, 5640, 9440, 12490, 12490],
      41801: [8923, 8785, 5702, 9494, 12552, 12552],
      42001: [8985, 8847, 5764, 9548, 12614, 12614],
      42201: [9047, 8909, 5826, 9603, 12676, 12676],
      42401: [9109, 8971, 5888, 9657, 12738, 12738],
      42601: [9171, 9033, 5950, 9711, 12800, 12800],
      42801: [9233, 9095, 6012, 9766, 12862, 12862],
      43001: [9295, 9157, 6074, 9820, 12924, 12924],
      43201: [9357, 9219, 6136, 9875, 12986, 12986],
      43401: [9419, 9281, 6198, 9929, 13048, 13048],
      43601: [9481, 9343, 6260, 9983, 13110, 13110],
      43801: [9543, 9405, 6322, 10038, 13172, 13172],
      44001: [9605, 9467, 6384, 10092, 13234, 13234],
      44201: [9667, 9529, 6446, 10146, 13296, 13296],
      44401: [9729, 9591, 6508, 10201, 13358, 13358],
      44601: [9791, 9653, 6570, 10255, 13420, 13420],
      44801: [9853, 9715, 6632, 10309, 13482, 13482],
      45001: [9915, 9777, 6694, 10364, 13544, 13544],
      45201: [9977, 9839, 6756, 10418, 13606, 13606],
      45401: [10039, 9901, 6818, 10472, 13668, 13668],
      45601: [10101, 9963, 6880, 10527, 13730, 13730],
      45801: [10163, 10025, 6942, 10581, 13792, 13792],
      46001: [10225, 10087, 7004, 10636, 13854, 13854],
      46201: [10287, 10149, 7066, 10690, 13916, 13916],
      46401: [10349, 10211, 7128, 10744, 13978, 13978],
      46601: [10411, 10273, 7190, 10799, 14040, 14040],
      46801: [10473, 10335, 7252, 10853, 14102, 14102],
      47001: [10535, 10397, 7314, 10907, 14164, 14164],
      47201: [10597, 10459, 7376, 10962, 14226, 14226],
      47401: [10659, 10521, 7438, 11016, 14288, 14288],
      47601: [10721, 10583, 7500, 11070, 14350, 14350],
      47801: [10783, 10645, 7562, 11125, 14412, 14412],
      48001: [10845, 10707, 7624, 11179, 14474, 14474],
      48201: [10907, 10769, 7686, 11234, 14536, 14536],
      48401: [10969, 10831, 7748, 11288, 14598, 14598],
      48601: [11031, 10893, 7810, 11342, 14660, 14660],
      48801: [11093, 10955, 7872, 11397, 14722, 14722],
      49001: [11155, 11017, 7934, 11451, 14784, 14784],
      49201: [11217, 11079, 7996, 11505, 14846, 14846],
      49401: [11279, 11141, 8058, 11560, 14908, 14908],
      49601: [11341, 11203, 8120, 11614, 14970, 14970],
      49801: [11403, 11265, 8182, 11668, 15032, 15032],
      50001: [11465, 11327, 8244, 11723, 15094, 15094],
      50201: [11527, 11389, 8306, 11777, 15156, 15156],
      50401: [11589, 11451, 8368, 11832, 15218, 15218],
      50601: [11651, 11513, 8430, 11886, 15280, 15280],
      50801: [11713, 11575, 8492, 11940, 15342, 15342],
      51001: [11775, 11637, 8554, 11995, 15404, 15404],
      51201: [11837, 11699, 8616, 12049, 15466, 15466],
      51401: [11899, 11761, 8678, 12103, 15528, 15528],
      51601: [11961, 11823, 8740, 12158, 15590, 15590],
      51801: [12023, 11885, 8802, 12212, 15652, 15652],
      52001: [12085, 11947, 8864, 12266, 15714, 15714],
      52201: [12147, 12009, 8926, 12321, 15776, 15776],
      52401: [12209, 12071, 8988, 12375, 15838, 15838],
      52601: [12271, 12133, 9050, 12429, 15900, 15900],
      52801: [12333, 12195, 9112, 12484, 15962, 15962],
      53001: [12395, 12257, 9174, 12538, 16024, 16024],
      53201: [12457, 12319, 9236, 12593, 16086, 16086],
      53401: [12521, 12381, 9298, 12649, 16149, 16149],
      53601: [12623, 12479, 9396, 12743, 16251, 16251],
      53801: [12725, 12580, 9497, 12837, 16353, 16353],
      54001: [12827, 12681, 9597, 12932, 16455, 16455],
      54201: [12929, 12782, 9698, 13026, 16557, 16557],
      54401: [13031, 12882, 9799, 13120, 16659, 16659],
      54601: [13133, 12983, 9900, 13215, 16761, 16761],
      54801: [13235, 13084, 1e4, 13309, 16863, 16863],
      55001: [13337, 13182, 10098, 13403, 16965, 16965],
      55201: [13439, 13283, 10199, 13498, 17067, 17067],
      55401: [13541, 13383, 10300, 13592, 17169, 17169],
      55601: [13643, 13484, 10401, 13687, 17271, 17271],
      55801: [13745, 13585, 10501, 13781, 17373, 17373],
      56001: [13847, 13686, 10602, 13875, 17475, 17475],
      56201: [13949, 13786, 10703, 13970, 17577, 17577],
      56401: [14051, 13887, 10804, 14064, 17679, 17679],
      56601: [14153, 13985, 10902, 14158, 17781, 17781],
      56801: [14255, 14086, 11003, 14253, 17883, 17883],
      57001: [14357, 14187, 11103, 14347, 17985, 17985],
      57201: [14459, 14288, 11204, 14441, 18087, 18087],
      57401: [14561, 14388, 11305, 14536, 18189, 18189],
      57601: [14663, 14489, 11406, 14630, 18291, 18291],
      57801: [14765, 14590, 11506, 14725, 18393, 18393],
      58001: [14867, 14691, 11607, 14819, 18495, 18495],
      58201: [14969, 14791, 11708, 14913, 18597, 18597],
      58401: [15071, 14890, 11806, 15008, 18699, 18699],
      58601: [15173, 14990, 11907, 15102, 18801, 18801],
      58801: [15275, 15091, 12008, 15196, 18903, 18903],
      59001: [15377, 15192, 12108, 15291, 19005, 19005],
      59201: [15479, 15293, 12209, 15385, 19107, 19107],
      59401: [15581, 15393, 12310, 15479, 19209, 19209],
      59601: [15683, 15494, 12411, 15574, 19311, 19311],
      59801: [15785, 15595, 12511, 15668, 19413, 19413],
      60001: [15887, 15693, 12609, 15763, 19515, 19515],
      60201: [15989, 15794, 12710, 15857, 19617, 19617],
      60401: [16091, 15894, 12811, 15951, 19719, 19719],
      60601: [16193, 15995, 12912, 16046, 19821, 19821],
      60801: [16295, 16096, 13012, 16140, 19923, 19923],
      61001: [16397, 16196, 13113, 16234, 20025, 20025],
      61201: [16499, 16298, 13215, 16329, 20127, 20127],
      61401: [16601, 16400, 13317, 16423, 20229, 20229],
      61601: [16703, 16502, 13419, 16517, 20331, 20331],
      61801: [16805, 16604, 13521, 16612, 20433, 20433],
      62001: [16907, 16706, 13623, 16706, 20535, 20535],
      62201: [17009, 16808, 13725, 16800, 20637, 20637],
      62401: [17111, 16910, 13827, 16895, 20739, 20739],
      62601: [17213, 17012, 13929, 16989, 20841, 20841],
      62801: [17315, 17114, 14031, 17084, 20943, 20943],
      63001: [17417, 17216, 14133, 17178, 21045, 21045],
      63201: [17519, 17318, 14235, 17272, 21147, 21147],
      63401: [17621, 17420, 14337, 17367, 21249, 21249],
      63601: [17723, 17522, 14439, 17461, 21351, 21351],
      63801: [17825, 17624, 14541, 17555, 21453, 21453],
      64001: [17927, 17726, 14643, 17650, 21555, 21555],
      64201: [18029, 17828, 14745, 17744, 21657, 21657],
      64401: [18131, 17930, 14847, 17838, 21759, 21759],
      64601: [18233, 18032, 14949, 17933, 21861, 21861],
      64801: [18335, 18134, 15051, 18027, 21963, 21963],
      65001: [18437, 18236, 15153, 18122, 22065, 22065],
      65201: [18539, 18338, 15255, 18216, 22167, 22167],
      65401: [18641, 18440, 15357, 18310, 22269, 22269],
      65601: [18743, 18542, 15459, 18405, 22371, 22371],
      65801: [18845, 18644, 15561, 18499, 22473, 22473],
      66001: [18947, 18746, 15663, 18593, 22575, 22575],
      66201: [19049, 18848, 15765, 18688, 22677, 22677],
      66401: [19151, 18950, 15867, 18782, 22779, 22779],
      66601: [19253, 19052, 15969, 18876, 22881, 22881],
      66801: [19355, 19154, 16071, 18971, 22983, 22983],
      67001: [19457, 19256, 16173, 19065, 23085, 23085],
      67201: [19559, 19358, 16275, 19159, 23187, 23187],
      67401: [19661, 19460, 16377, 19254, 23289, 23289],
      67601: [19763, 19562, 16479, 19348, 23391, 23391],
      67801: [19865, 19664, 16581, 19443, 23493, 23493],
      68001: [19967, 19766, 16683, 19537, 23595, 23595],
      68201: [20069, 19868, 16785, 19631, 23697, 23697],
      68401: [20171, 19970, 16887, 19726, 23799, 23799],
      68601: [20273, 20072, 16989, 19820, 23901, 23901],
      68801: [20375, 20174, 17091, 19914, 24003, 24003],
      69001: [20477, 20276, 17193, 20009, 24105, 24105],
      69201: [20579, 20378, 17295, 20103, 24207, 24207],
      69401: [20681, 20480, 17397, 20197, 24309, 24309],
      69601: [20783, 20582, 17499, 20292, 24411, 24411],
      69801: [20885, 20684, 17601, 20386, 24513, 24513],
      70001: [20987, 20786, 17703, 20481, 24615, 24615],
      70201: [21089, 20888, 17805, 20575, 24717, 24717],
      70401: [21191, 20990, 17907, 20669, 24819, 24819],
      70601: [21293, 21092, 18009, 20764, 24921, 24921],
      70801: [21395, 21194, 18111, 20858, 25023, 25023],
      71001: [21497, 21296, 18213, 20952, 25125, 25125],
      71201: [21599, 21398, 18315, 21047, 25227, 25227],
      71401: [21701, 21500, 18417, 21141, 25329, 25329],
      71601: [21803, 21602, 18519, 21235, 25431, 25431],
      71801: [21905, 21704, 18621, 21330, 25533, 25533],
      72001: [22007, 21806, 18723, 21424, 25635, 25635],
      72201: [22109, 21908, 18825, 21519, 25737, 25737],
      72401: [22211, 22010, 18927, 21613, 25839, 25839],
      72601: [22313, 22112, 19029, 21707, 25941, 25941],
      72801: [22415, 22214, 19131, 21802, 26043, 26043],
      73001: [22517, 22316, 19233, 21896, 26145, 26145],
      73201: [22619, 22418, 19335, 21990, 26247, 26247],
      73401: [22721, 22520, 19437, 22085, 26349, 26349],
      73601: [22823, 22622, 19539, 22179, 26451, 26451],
      73801: [22925, 22724, 19641, 22273, 26553, 26553],
      74001: [23027, 22826, 19743, 22368, 26655, 26655],
      74201: [23129, 22928, 19845, 22462, 26757, 26757],
      74401: [23231, 23030, 19947, 22556, 26859, 26859],
      74601: [23333, 23132, 20049, 22651, 26961, 26961],
      74801: [23435, 23234, 20151, 22745, 27063, 27063],
      75001: [23537, 23336, 20253, 22840, 27165, 27165],
      75201: [23639, 23438, 20355, 22934, 27267, 27267],
      75401: [23741, 23540, 20457, 23028, 27369, 27369],
      75601: [23843, 23642, 20559, 23123, 27471, 27471],
      75801: [23945, 23744, 20661, 23217, 27573, 27573],
      76001: [24047, 23846, 20763, 23311, 27675, 27675],
      76201: [24149, 23948, 20865, 23406, 27777, 27777],
      76401: [24251, 24050, 20967, 23500, 27879, 27879],
      76601: [24353, 24152, 21069, 23594, 27981, 27981],
      76801: [24455, 24254, 21171, 23689, 28083, 28083],
      77001: [24557, 24356, 21273, 23783, 28185, 28185],
      77201: [24659, 24458, 21375, 23878, 28287, 28287],
      77401: [24761, 24560, 21477, 23972, 28389, 28389],
      77601: [24863, 24662, 21579, 24066, 28491, 28491],
      77801: [24965, 24764, 21681, 24161, 28593, 28593],
      78001: [25067, 24866, 21783, 24255, 28695, 28695],
      78201: [25169, 24968, 21885, 24349, 28797, 28797],
      78401: [25271, 25070, 21987, 24444, 28899, 28899],
      78601: [25373, 25172, 22089, 24538, 29001, 29001],
      78801: [25475, 25274, 22191, 24632, 29103, 29103],
      79001: [25577, 25376, 22293, 24727, 29205, 29205],
      79201: [25679, 25478, 22395, 24821, 29307, 29307],
      79401: [25781, 25580, 22497, 24916, 29409, 29409],
      79601: [25883, 25682, 22599, 25010, 29511, 29511],
      79801: [25985, 25784, 22701, 25104, 29613, 29613],
      80001: [33, 32, 28, 31, 37, 37, "%"],
      80401: [33, 32, 29, 31, 37, 37, "%"],
      80601: [33, 32, 29, 32, 37, 37, "%"],
      81001: [33, 33, 29, 32, 37, 37, "%"],
      82801: [33, 33, 29, 32, 38, 38, "%"],
      84001: [33, 33, 30, 32, 38, 38, "%"],
      84601: [34, 33, 30, 32, 38, 38, "%"],
      85801: [34, 34, 30, 32, 38, 38, "%"],
      86001: [34, 34, 30, 33, 38, 38, "%"],
      88201: [34, 34, 31, 33, 38, 38, "%"],
      89401: [34, 34, 31, 33, 39, 39, "%"],
      89601: [35, 34, 31, 33, 39, 39, "%"],
      91001: [35, 35, 31, 33, 39, 39, "%"],
      92201: [35, 35, 31, 34, 39, 39, "%"],
      92801: [35, 35, 32, 34, 39, 39, "%"],
      95401: [36, 35, 32, 34, 39, 39, "%"],
      96801: [36, 36, 32, 34, 39, 39, "%"],
      97201: [36, 36, 32, 34, 40, 40, "%"],
      97801: [36, 36, 33, 34, 40, 40, "%"],
      99601: [36, 36, 33, 35, 40, 40, "%"],
      102001: [37, 36, 33, 35, 40, 40, "%"],
      103401: [37, 37, 34, 35, 40, 40, "%"],
      106401: [37, 37, 34, 35, 41, 41, "%"],
      108201: [37, 37, 34, 36, 41, 41, "%"],
      109601: [38, 37, 35, 36, 41, 41, "%"],
      111201: [38, 38, 35, 36, 41, 41, "%"],
      116601: [38, 38, 36, 36, 41, 41, "%"],
      117601: [38, 38, 36, 36, 42, 42, "%"],
      118201: [38, 38, 36, 37, 42, 42, "%"],
      118401: [39, 38, 36, 37, 42, 42, "%"],
      120001: [39, 39, 36, 37, 42, 42, "%"],
      124801: [39, 39, 37, 37, 42, 42, "%"],
      128801: [40, 39, 37, 37, 42, 42, "%"],
      130401: [40, 40, 37, 38, 42, 42, "%"],
      131601: [40, 40, 37, 38, 43, 43, "%"],
      134001: [40, 40, 38, 38, 43, 43, "%"],
      141001: [41, 40, 38, 38, 43, 43, "%"],
      143001: [41, 41, 38, 38, 43, 43, "%"],
      144601: [41, 41, 39, 38, 43, 43, "%"],
      145601: [41, 41, 39, 39, 43, 43, "%"],
      149001: [41, 41, 39, 39, 44, 44, "%"],
      155801: [42, 41, 39, 39, 44, 44, "%"],
      157201: [42, 41, 40, 39, 44, 44, "%"],
      158001: [42, 42, 40, 39, 44, 44, "%"],
      164401: [42, 42, 40, 40, 44, 44, "%"],
      172001: [42, 42, 40, 40, 45, 45, "%"],
      172201: [42, 42, 41, 40, 45, 45, "%"],
      174201: [43, 42, 41, 40, 45, 45, "%"],
      176601: [43, 43, 41, 40, 45, 45, "%"],
      189201: [43, 43, 41, 41, 45, 45, "%"],
      190401: [43, 43, 42, 41, 45, 45, "%"],
      197401: [44, 43, 42, 41, 45, 45, "%"],
      200201: [44, 44, 42, 41, 45, 45, "%"],
      203201: [44, 44, 42, 41, 46, 46, "%"],
      212801: [44, 44, 43, 41, 46, 46, "%"],
      222401: [44, 44, 43, 42, 46, 46, "%"],
      227801: [45, 44, 43, 42, 46, 46, "%"],
      231001: [45, 45, 43, 42, 46, 46, "%"],
      241201: [45, 45, 44, 42, 46, 46, "%"],
      248401: [45, 45, 44, 42, 47, 47, "%"],
      269201: [46, 45, 44, 42, 47, 47, "%"],
      270001: [46, 45, 44, 43, 47, 47, "%"],
      273001: [46, 46, 44, 43, 47, 47, "%"],
      278401: [46, 46, 45, 43, 47, 47, "%"],
      319601: [46, 46, 45, 43, 48, 48, "%"],
      329001: [46, 46, 46, 43, 48, 48, "%"],
      329201: [47, 46, 46, 43, 48, 48, "%"],
      333601: [47, 47, 46, 43, 48, 48, "%"],
      343401: [47, 47, 46, 44, 48, 48, "%"],
      402001: [47, 47, 47, 44, 48, 48, "%"],
      423201: [48, 47, 47, 44, 48, 48, "%"],
      429001: [48, 48, 47, 44, 48, 48, "%"],
      447401: [48, 48, 47, 44, 49, 49, "%"],
      471401: [48, 48, 47, 45, 49, 49, "%"],
      517001: [48, 48, 48, 45, 49, 49, "%"],
      592401: [49, 48, 48, 45, 49, 49, "%"],
      600601: [49, 49, 48, 45, 49, 49, "%"],
      723801: [49, 49, 49, 45, 49, 49, "%"],
      745601: [49, 49, 49, 45, 50, 50, "%"],
      752001: [49, 49, 49, 46, 50, 50, "%"],
      987601: [50, 49, 49, 46, 50, 50, "%"],
      1001001: [50, 50, 49, 46, 50, 50, "%"],
      1206401: [50, 50, 50, 46, 50, 50, "%"],
      1857401: [50, 50, 50, 47, 50, 50, "%"]
    },
    32: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 8],
      2101: [152, 0, 150, 2, 152, 41],
      2201: [163, 0, 158, 4, 163, 74],
      2301: [173, 0, 166, 7, 173, 107],
      2401: [184, 0, 175, 9, 184, 140],
      2501: [194, 0, 183, 11, 194, 173],
      2601: [205, 0, 191, 13, 206, 206],
      2701: [215, 0, 200, 15, 239, 239],
      2801: [217, 0, 200, 17, 272, 272],
      2901: [228, 0, 208, 19, 305, 305],
      3001: [238, 0, 216, 22, 338, 338],
      3101: [249, 0, 225, 24, 371, 371],
      3201: [259, 0, 233, 26, 404, 404],
      3301: [270, 0, 241, 28, 437, 437],
      3401: [272, 0, 241, 30, 470, 470],
      3501: [282, 0, 250, 32, 503, 503],
      3601: [293, 0, 258, 35, 536, 536],
      3701: [303, 0, 266, 37, 569, 569],
      3801: [314, 0, 275, 39, 602, 602],
      3901: [324, 0, 283, 41, 635, 635],
      4001: [327, 0, 283, 43, 668, 668],
      4101: [337, 0, 291, 45, 701, 701],
      4201: [348, 0, 300, 48, 734, 734],
      4301: [358, 0, 308, 50, 767, 767],
      4401: [369, 0, 316, 60, 800, 800],
      4501: [379, 0, 325, 83, 833, 833],
      4601: [381, 0, 325, 105, 866, 866],
      4701: [392, 0, 333, 128, 899, 899],
      4801: [402, 0, 341, 150, 929, 929],
      4901: [412, 0, 350, 172, 954, 954],
      5001: [422, 0, 358, 194, 981, 981],
      5101: [432, 0, 366, 216, 1006, 1006],
      5201: [442, 0, 375, 238, 1033, 1033],
      5301: [444, 0, 375, 260, 1061, 1061],
      5401: [454, 13, 384, 282, 1086, 1086],
      5501: [464, 38, 394, 304, 1113, 1113],
      5601: [474, 63, 404, 326, 1137, 1137],
      5701: [484, 87, 414, 347, 1164, 1164],
      5801: [494, 109, 423, 369, 1191, 1191],
      5901: [496, 134, 425, 390, 1215, 1215],
      6001: [506, 159, 435, 412, 1242, 1242],
      6101: [516, 184, 445, 433, 1266, 1266],
      6201: [526, 208, 455, 454, 1293, 1293],
      6301: [536, 233, 465, 476, 1320, 1320],
      6401: [546, 258, 475, 497, 1344, 1344],
      6501: [548, 283, 476, 519, 1371, 1371],
      6601: [558, 307, 486, 540, 1395, 1395],
      6701: [568, 332, 496, 562, 1422, 1422],
      6801: [578, 357, 506, 583, 1449, 1449],
      6901: [588, 382, 516, 605, 1473, 1473],
      7001: [598, 404, 526, 626, 1500, 1500],
      7101: [608, 428, 528, 648, 1524, 1524],
      7201: [628, 453, 538, 669, 1551, 1551],
      7301: [648, 478, 547, 691, 1578, 1578],
      7401: [668, 503, 557, 712, 1602, 1602],
      7501: [688, 527, 567, 734, 1629, 1629],
      7601: [708, 552, 577, 755, 1653, 1653],
      7701: [728, 577, 587, 776, 1680, 1680],
      7801: [748, 602, 589, 798, 1707, 1707],
      7901: [768, 626, 599, 819, 1731, 1731],
      8001: [788, 651, 609, 841, 1758, 1758],
      8101: [808, 676, 619, 862, 1782, 1782],
      8201: [828, 698, 629, 884, 1809, 1809],
      8301: [848, 723, 639, 905, 1836, 1836],
      8401: [868, 747, 640, 927, 1860, 1860],
      8501: [888, 772, 650, 948, 1887, 1887],
      8601: [908, 797, 660, 970, 1911, 1911],
      8701: [928, 822, 670, 991, 1938, 1938],
      8801: [948, 846, 680, 1013, 1965, 1965],
      8901: [968, 871, 690, 1034, 1989, 1989],
      9001: [988, 896, 692, 1056, 2016, 2016],
      9101: [1008, 921, 701, 1077, 2040, 2040],
      9201: [1028, 945, 711, 1098, 2067, 2067],
      9301: [1048, 970, 721, 1120, 2094, 2094],
      9401: [1068, 992, 731, 1141, 2118, 2118],
      9501: [1089, 1017, 741, 1163, 2145, 2145],
      9601: [1108, 1033, 742, 1184, 2169, 2169],
      9701: [1128, 1053, 752, 1206, 2196, 2196],
      9801: [1149, 1072, 761, 1227, 2223, 2223],
      9901: [1169, 1094, 771, 1249, 2247, 2247],
      10001: [1189, 1113, 781, 1270, 2274, 2274],
      10101: [1209, 1132, 790, 1292, 2298, 2298],
      10201: [1229, 1151, 800, 1313, 2325, 2325],
      10301: [1249, 1172, 801, 1335, 2352, 2352],
      10401: [1269, 1191, 811, 1356, 2376, 2376],
      10501: [1289, 1210, 821, 1378, 2403, 2403],
      10601: [1309, 1228, 830, 1399, 2427, 2427],
      10701: [1329, 1250, 840, 1420, 2454, 2454],
      10801: [1349, 1269, 850, 1442, 2481, 2481],
      10901: [1369, 1288, 851, 1463, 2505, 2505],
      11001: [1389, 1306, 860, 1485, 2532, 2532],
      11101: [1409, 1328, 870, 1506, 2556, 2556],
      11201: [1429, 1347, 880, 1528, 2583, 2583],
      11301: [1449, 1366, 889, 1549, 2610, 2610],
      11401: [1469, 1384, 899, 1571, 2634, 2634],
      11501: [1489, 1406, 900, 1592, 2661, 2661],
      11601: [1509, 1425, 910, 1614, 2685, 2685],
      11701: [1529, 1443, 920, 1635, 2712, 2712],
      11801: [1549, 1462, 929, 1657, 2739, 2739],
      11901: [1569, 1484, 939, 1678, 2763, 2763],
      12001: [1589, 1503, 949, 1700, 2790, 2790],
      12101: [1609, 1521, 950, 1721, 2814, 2814],
      12201: [1629, 1543, 960, 1742, 2841, 2841],
      12301: [1649, 1562, 969, 1764, 2868, 2868],
      12401: [1669, 1581, 979, 1785, 2892, 2892],
      12501: [1689, 1599, 988, 1807, 2919, 2919],
      12601: [1709, 1621, 998, 1828, 2943, 2943],
      12701: [1729, 1640, 1008, 1850, 2970, 2970],
      12801: [1749, 1658, 1009, 1871, 2997, 2997],
      12901: [1769, 1677, 1019, 1893, 3021, 3021],
      13001: [1789, 1699, 1028, 1914, 3048, 3048],
      13101: [1809, 1718, 1038, 1936, 3072, 3072],
      13201: [1829, 1736, 1047, 1957, 3099, 3099],
      13301: [1849, 1755, 1057, 1979, 3129, 3129],
      13401: [1870, 1777, 1059, 2e3, 3161, 3161],
      13501: [1890, 1796, 1068, 2022, 3193, 3193],
      13601: [1910, 1814, 1078, 2044, 3225, 3225],
      13701: [1931, 1833, 1087, 2066, 3258, 3258],
      13801: [1951, 1855, 1097, 2087, 3290, 3290],
      13901: [1971, 1873, 1107, 2109, 3322, 3322],
      14001: [1992, 1892, 1108, 2131, 3354, 3354],
      14101: [2012, 1911, 1118, 2153, 3386, 3386],
      14201: [2031, 1933, 1127, 2173, 3418, 3418],
      14301: [2050, 1951, 1137, 2194, 3449, 3449],
      14401: [2070, 1970, 1146, 2215, 3480, 3480],
      14501: [2089, 1992, 1156, 2236, 3511, 3511],
      14601: [2108, 2011, 1158, 2256, 3543, 3543],
      14701: [2127, 2029, 1167, 2277, 3574, 3574],
      14801: [2147, 2048, 1177, 2298, 3605, 3605],
      14901: [2166, 2070, 1186, 2319, 3636, 3636],
      15001: [2185, 2088, 1196, 2339, 3668, 3668],
      15101: [2205, 2107, 1206, 2360, 3699, 3699],
      15201: [2224, 2126, 1215, 2381, 3730, 3730],
      15301: [2243, 2148, 1217, 2402, 3767, 3767],
      15401: [2263, 2166, 1226, 2422, 3800, 3800],
      15501: [2282, 2185, 1236, 2443, 3834, 3834],
      15601: [2301, 2204, 1245, 2464, 3868, 3868],
      15701: [2321, 2226, 1255, 2485, 3902, 3902],
      15801: [2341, 2244, 1265, 2508, 3938, 3938],
      15901: [2366, 2271, 1267, 2535, 3972, 3972],
      16001: [2391, 2295, 1277, 2562, 4006, 4006],
      16101: [2416, 2322, 1287, 2590, 4040, 4040],
      16201: [2442, 2346, 1297, 2617, 4074, 4074],
      16301: [2467, 2373, 1307, 2644, 4110, 4110],
      16401: [2492, 2400, 1317, 2672, 4144, 4144],
      16501: [2517, 2424, 1319, 2699, 4178, 4178],
      16601: [2542, 2451, 1329, 2726, 4212, 4212],
      16701: [2567, 2475, 1339, 2754, 4246, 4246],
      16801: [2592, 2502, 1349, 2781, 4282, 4282],
      16901: [2618, 2527, 1359, 2808, 4316, 4316],
      17001: [2643, 2553, 1369, 2836, 4350, 4350],
      17101: [2668, 2578, 1371, 2863, 4384, 4384],
      17201: [2693, 2604, 1381, 2890, 4418, 4418],
      17301: [2718, 2629, 1391, 2918, 4454, 4454],
      17401: [2743, 2656, 1401, 2945, 4488, 4488],
      17501: [2769, 2682, 1411, 2973, 4522, 4522],
      17601: [2794, 2707, 1421, 3e3, 4556, 4556],
      17701: [2819, 2733, 1431, 3027, 4590, 4590],
      17801: [2844, 2758, 1433, 3055, 4626, 4626],
      17901: [2869, 2785, 1443, 3082, 4660, 4660],
      18001: [2894, 2809, 1453, 3109, 4694, 4694],
      18101: [2919, 2836, 1463, 3137, 4728, 4728],
      18201: [2945, 2860, 1473, 3164, 4761, 4761],
      18301: [2970, 2887, 1483, 3191, 4798, 4798],
      18401: [2995, 2914, 1485, 3219, 4832, 4832],
      18501: [3020, 2938, 1495, 3246, 4866, 4866],
      18601: [3045, 2965, 1505, 3273, 4899, 4899],
      18701: [3070, 2989, 1515, 3301, 4933, 4933],
      18801: [3096, 3016, 1525, 3328, 4970, 4970],
      18901: [3121, 3040, 1535, 3355, 5004, 5004],
      19001: [3146, 3067, 1537, 3383, 5037, 5037],
      19101: [3171, 3091, 1547, 3410, 5071, 5071],
      19201: [3196, 3118, 1557, 3437, 5105, 5105],
      19301: [3221, 3145, 1567, 3465, 5142, 5142],
      19401: [3246, 3169, 1577, 3492, 5175, 5175],
      19501: [3272, 3196, 1587, 3519, 5209, 5209],
      19601: [3297, 3220, 1589, 3547, 5243, 5243],
      19701: [3322, 3247, 1599, 3574, 5277, 5277],
      19801: [3347, 3271, 1609, 3601, 5313, 5313],
      19901: [3372, 3298, 1619, 3629, 5347, 5347],
      20001: [3423, 3349, 1639, 3683, 5415, 5415],
      20201: [3473, 3399, 1650, 3738, 5485, 5485],
      20401: [3523, 3449, 1669, 3793, 5553, 5553],
      20601: [3573, 3498, 1687, 3848, 5621, 5621],
      20801: [3624, 3548, 1697, 3902, 5691, 5691],
      21001: [3674, 3597, 1716, 3957, 5759, 5759],
      21201: [3724, 3649, 1734, 4012, 5829, 5829],
      21401: [3775, 3699, 1744, 4066, 5897, 5897],
      21601: [3825, 3748, 1763, 4121, 5965, 5965],
      21801: [3875, 3798, 1781, 4176, 6035, 6035],
      22001: [3926, 3847, 1792, 4230, 6103, 6103],
      22201: [3976, 3897, 1810, 4285, 6173, 6173],
      22401: [4026, 3946, 1829, 4340, 6241, 6241],
      22601: [4077, 3996, 1847, 4394, 6308, 6308],
      22801: [4127, 4045, 1857, 4449, 6379, 6379],
      23001: [4179, 4095, 1876, 4505, 6448, 6448],
      23201: [4231, 4147, 1894, 4562, 6520, 6520],
      23401: [4283, 4196, 1905, 4618, 6589, 6589],
      23601: [4335, 4246, 1923, 4674, 6659, 6659],
      23801: [4387, 4295, 1942, 4731, 6731, 6731],
      24001: [4439, 4345, 1952, 4787, 6800, 6800],
      24201: [4491, 4394, 1970, 4843, 6872, 6872],
      24401: [4542, 4444, 1989, 4900, 6941, 6941],
      24601: [4594, 4496, 1999, 4956, 7011, 7011],
      24801: [4646, 4548, 2018, 5012, 7083, 7083],
      25001: [4698, 4597, 2036, 5069, 7152, 7152],
      25201: [4750, 4649, 2046, 5125, 7224, 7224],
      25401: [4802, 4699, 2065, 5181, 7293, 7293],
      25601: [4854, 4751, 2084, 5238, 7363, 7363],
      25801: [4906, 4800, 2094, 5294, 7435, 7435],
      26001: [4958, 4853, 2112, 5350, 7504, 7504],
      26201: [5010, 4902, 2131, 5407, 7576, 7576],
      26401: [5062, 4954, 2141, 5463, 7645, 7645],
      26601: [5114, 5004, 2160, 5519, 7715, 7715],
      26801: [5166, 5056, 2178, 5576, 7787, 7787],
      27001: [5218, 5105, 2188, 5632, 7856, 7856],
      27201: [5270, 5157, 2207, 5688, 7928, 7928],
      27401: [5322, 5207, 2225, 5745, 7997, 7997],
      27601: [5374, 5259, 2244, 5801, 8067, 8067],
      27801: [5426, 5311, 2254, 5857, 8139, 8139],
      28001: [5478, 5360, 2277, 5914, 8208, 8208],
      28201: [5530, 5412, 2329, 5970, 8280, 8280],
      28401: [5582, 5462, 2378, 6026, 8349, 8349],
      28601: [5634, 5514, 2430, 6083, 8419, 8419],
      28801: [5686, 5563, 2480, 6139, 8491, 8491],
      29001: [5737, 5616, 2532, 6195, 8560, 8560],
      29201: [5790, 5665, 2582, 6252, 8632, 8632],
      29401: [5841, 5717, 2634, 6308, 8701, 8701],
      29601: [5893, 5767, 2683, 6364, 8771, 8771],
      29801: [5945, 5819, 2735, 6421, 8843, 8843],
      30001: [5997, 5868, 2785, 6477, 8912, 8912],
      30201: [6049, 5920, 2837, 6534, 8984, 8984],
      30401: [6101, 5972, 2889, 6590, 9053, 9053],
      30601: [6153, 6022, 2938, 6646, 9123, 9123],
      30801: [6205, 6074, 2990, 6703, 9195, 9195],
      31001: [6257, 6123, 3040, 6759, 9264, 9264],
      31201: [6309, 6175, 3092, 6815, 9336, 9336],
      31401: [6361, 6225, 3141, 6872, 9405, 9405],
      31601: [6413, 6277, 3194, 6928, 9475, 9475],
      31801: [6465, 6326, 3243, 6984, 9547, 9547],
      32001: [6517, 6379, 3295, 7041, 9616, 9616],
      32201: [6569, 6429, 3346, 7097, 9688, 9688],
      32401: [6621, 6483, 3399, 7153, 9757, 9757],
      32601: [6673, 6533, 3450, 7210, 9827, 9827],
      32801: [6725, 6587, 3503, 7266, 9899, 9899],
      33001: [6777, 6637, 3554, 7322, 9968, 9968],
      33201: [6829, 6691, 3607, 7379, 10040, 10040],
      33401: [6881, 6744, 3660, 7435, 10109, 10109],
      33601: [6933, 6795, 3711, 7491, 10179, 10179],
      33801: [6985, 6848, 3764, 7548, 10251, 10251],
      34001: [7036, 6899, 3815, 7604, 10320, 10320],
      34201: [7088, 6952, 3868, 7660, 10392, 10392],
      34401: [7140, 7003, 3919, 7717, 10461, 10461],
      34601: [7192, 7056, 3972, 7773, 10531, 10531],
      34801: [7244, 7107, 4023, 7829, 10603, 10603],
      35001: [7296, 7160, 4076, 7886, 10672, 10672],
      35201: [7348, 7211, 4127, 7942, 10744, 10744],
      35401: [7400, 7264, 4180, 7998, 10813, 10813],
      35601: [7452, 7315, 4231, 8055, 10883, 10883],
      35801: [7504, 7368, 4284, 8111, 10955, 10955],
      36001: [7556, 7421, 4338, 8167, 11024, 11024],
      36201: [7608, 7472, 4388, 8224, 11096, 11096],
      36401: [7660, 7525, 4442, 8280, 11165, 11165],
      36601: [7712, 7576, 4492, 8336, 11235, 11235],
      36801: [7764, 7629, 4546, 8393, 11307, 11307],
      37001: [7816, 7680, 4596, 8449, 11376, 11376],
      37201: [7868, 7733, 4650, 8505, 11448, 11448],
      37401: [7920, 7784, 4700, 8562, 11517, 11517],
      37601: [7972, 7837, 4754, 8618, 11587, 11587],
      37801: [8024, 7888, 4804, 8674, 11659, 11659],
      38001: [8076, 7941, 4858, 8731, 11728, 11728],
      38201: [8128, 7992, 4908, 8787, 11800, 11800],
      38401: [8180, 8045, 4962, 8843, 11869, 11869],
      38601: [8231, 8096, 5012, 8899, 11933, 11933],
      38801: [8283, 8147, 5063, 8956, 11997, 11997],
      39001: [8335, 8197, 5114, 9012, 12061, 12061],
      39201: [8386, 8251, 5167, 9068, 12125, 12125],
      39401: [8439, 8301, 5218, 9124, 12189, 12189],
      39601: [8503, 8365, 5282, 9180, 12253, 12253],
      39801: [8567, 8429, 5346, 9236, 12317, 12317],
      40001: [8631, 8493, 5410, 9292, 12381, 12381],
      40201: [8695, 8557, 5474, 9348, 12445, 12445],
      40401: [8759, 8621, 5538, 9404, 12509, 12509],
      40601: [8823, 8685, 5602, 9460, 12573, 12573],
      40801: [8887, 8749, 5666, 9517, 12637, 12637],
      41001: [8951, 8813, 5730, 9573, 12701, 12701],
      41201: [9015, 8877, 5794, 9629, 12765, 12765],
      41401: [9079, 8941, 5858, 9685, 12829, 12829],
      41601: [9143, 9005, 5922, 9741, 12893, 12893],
      41801: [9207, 9069, 5986, 9797, 12957, 12957],
      42001: [9271, 9133, 6050, 9853, 13021, 13021],
      42201: [9335, 9197, 6114, 9909, 13085, 13085],
      42401: [9399, 9261, 6178, 9965, 13149, 13149],
      42601: [9463, 9325, 6242, 10022, 13213, 13213],
      42801: [9527, 9389, 6306, 10078, 13277, 13277],
      43001: [9591, 9453, 6370, 10134, 13341, 13341],
      43201: [9655, 9517, 6434, 10190, 13405, 13405],
      43401: [9719, 9581, 6498, 10246, 13469, 13469],
      43601: [9783, 9645, 6562, 10302, 13533, 13533],
      43801: [9847, 9709, 6626, 10358, 13597, 13597],
      44001: [9911, 9773, 6690, 10414, 13661, 13661],
      44201: [9975, 9837, 6754, 10470, 13725, 13725],
      44401: [10039, 9901, 6818, 10526, 13789, 13789],
      44601: [10103, 9965, 6882, 10583, 13853, 13853],
      44801: [10167, 10029, 6946, 10639, 13917, 13917],
      45001: [10231, 10093, 7010, 10695, 13981, 13981],
      45201: [10295, 10157, 7074, 10751, 14045, 14045],
      45401: [10359, 10221, 7138, 10807, 14109, 14109],
      45601: [10423, 10285, 7202, 10863, 14173, 14173],
      45801: [10487, 10349, 7266, 10919, 14237, 14237],
      46001: [10551, 10413, 7330, 10975, 14301, 14301],
      46201: [10615, 10477, 7394, 11031, 14365, 14365],
      46401: [10679, 10541, 7458, 11088, 14429, 14429],
      46601: [10743, 10605, 7522, 11144, 14493, 14493],
      46801: [10807, 10669, 7586, 11200, 14557, 14557],
      47001: [10871, 10733, 7650, 11256, 14621, 14621],
      47201: [10935, 10797, 7714, 11312, 14685, 14685],
      47401: [10999, 10861, 7778, 11368, 14749, 14749],
      47601: [11063, 10925, 7842, 11424, 14813, 14813],
      47801: [11127, 10989, 7906, 11480, 14877, 14877],
      48001: [11191, 11053, 7970, 11536, 14941, 14941],
      48201: [11255, 11117, 8034, 11592, 15005, 15005],
      48401: [11319, 11181, 8098, 11649, 15069, 15069],
      48601: [11383, 11245, 8162, 11705, 15133, 15133],
      48801: [11447, 11309, 8226, 11761, 15197, 15197],
      49001: [11511, 11373, 8290, 11817, 15261, 15261],
      49201: [11575, 11437, 8354, 11873, 15325, 15325],
      49401: [11639, 11501, 8418, 11929, 15389, 15389],
      49601: [11703, 11565, 8482, 11985, 15453, 15453],
      49801: [11767, 11629, 8546, 12041, 15517, 15517],
      50001: [11831, 11693, 8610, 12097, 15581, 15581],
      50201: [11895, 11757, 8674, 12154, 15645, 15645],
      50401: [11959, 11821, 8738, 12210, 15709, 15709],
      50601: [12023, 11885, 8802, 12266, 15773, 15773],
      50801: [12087, 11949, 8866, 12322, 15837, 15837],
      51001: [12151, 12013, 8930, 12378, 15901, 15901],
      51201: [12215, 12077, 8994, 12434, 15965, 15965],
      51401: [12279, 12141, 9058, 12490, 16029, 16029],
      51601: [12343, 12205, 9122, 12546, 16093, 16093],
      51801: [12407, 12269, 9186, 12602, 16157, 16157],
      52001: [12471, 12333, 9250, 12658, 16221, 16221],
      52201: [12535, 12397, 9314, 12715, 16285, 16285],
      52401: [12599, 12461, 9378, 12771, 16349, 16349],
      52601: [12663, 12525, 9442, 12827, 16413, 16413],
      52801: [12727, 12589, 9506, 12883, 16477, 16477],
      53001: [12791, 12653, 9570, 12939, 16541, 16541],
      53201: [12855, 12717, 9634, 12995, 16605, 16605],
      53401: [12921, 12781, 9698, 13053, 16671, 16671],
      53601: [13025, 12883, 9799, 13149, 16775, 16775],
      53801: [13129, 12987, 9903, 13245, 16879, 16879],
      54001: [13233, 13091, 10007, 13341, 16983, 16983],
      54201: [13337, 13195, 10111, 13437, 17087, 17087],
      54401: [13441, 13299, 10215, 13533, 17191, 17191],
      54601: [13545, 13403, 10319, 13629, 17295, 17295],
      54801: [13649, 13507, 10423, 13726, 17399, 17399],
      55001: [13753, 13608, 10524, 13822, 17503, 17503],
      55201: [13857, 13712, 10628, 13918, 17607, 17607],
      55401: [13961, 13816, 10732, 14014, 17711, 17711],
      55601: [14065, 13920, 10836, 14110, 17815, 17815],
      55801: [14169, 14024, 10940, 14206, 17919, 17919],
      56001: [14273, 14128, 11044, 14302, 18023, 18023],
      56201: [14377, 14232, 11148, 14398, 18127, 18127],
      56401: [14481, 14336, 11252, 14494, 18231, 18231],
      56601: [14585, 14437, 11354, 14591, 18335, 18335],
      56801: [14689, 14541, 11458, 14687, 18439, 18439],
      57001: [14793, 14645, 11562, 14783, 18543, 18543],
      57201: [14897, 14749, 11666, 14879, 18647, 18647],
      57401: [15001, 14853, 11770, 14975, 18751, 18751],
      57601: [15105, 14957, 11874, 15071, 18855, 18855],
      57801: [15209, 15061, 11978, 15167, 18959, 18959],
      58001: [15313, 15165, 12082, 15263, 19063, 19063],
      58201: [15417, 15269, 12186, 15359, 19167, 19167],
      58401: [15521, 15371, 12287, 15455, 19271, 19271],
      58601: [15625, 15475, 12391, 15552, 19375, 19375],
      58801: [15729, 15579, 12495, 15648, 19479, 19479],
      59001: [15833, 15683, 12599, 15744, 19583, 19583],
      59201: [15937, 15787, 12703, 15840, 19687, 19687],
      59401: [16041, 15891, 12807, 15936, 19791, 19791],
      59601: [16145, 15995, 12911, 16032, 19895, 19895],
      59801: [16249, 16099, 13015, 16128, 19999, 19999],
      60001: [16353, 16200, 13116, 16224, 20103, 20103],
      60201: [16457, 16304, 13220, 16321, 20207, 20207],
      60401: [16561, 16408, 13324, 16417, 20311, 20311],
      60601: [16665, 16512, 13428, 16513, 20415, 20415],
      60801: [16769, 16616, 13532, 16609, 20519, 20519],
      61001: [16873, 16719, 13635, 16705, 20623, 20623],
      61201: [16977, 16823, 13739, 16801, 20727, 20727],
      61401: [17081, 16927, 13843, 16897, 20831, 20831],
      61601: [17185, 17031, 13947, 16993, 20935, 20935],
      61801: [17289, 17135, 14051, 17089, 21039, 21039],
      62001: [17393, 17239, 14155, 17185, 21143, 21143],
      62201: [17497, 17343, 14259, 17281, 21247, 21247],
      62401: [17601, 17447, 14363, 17378, 21351, 21351],
      62601: [17705, 17551, 14467, 17474, 21455, 21455],
      62801: [17809, 17655, 14571, 17570, 21559, 21559],
      63001: [17913, 17759, 14675, 17666, 21663, 21663],
      63201: [18017, 17863, 14779, 17762, 21767, 21767],
      63401: [18121, 17967, 14883, 17858, 21871, 21871],
      63601: [18225, 18071, 14987, 17954, 21975, 21975],
      63801: [18329, 18175, 15091, 18050, 22079, 22079],
      64001: [18433, 18279, 15195, 18146, 22183, 22183],
      64201: [18537, 18383, 15299, 18243, 22287, 22287],
      64401: [18641, 18487, 15403, 18339, 22391, 22391],
      64601: [18745, 18591, 15507, 18435, 22495, 22495],
      64801: [18849, 18695, 15611, 18531, 22599, 22599],
      65001: [18953, 18799, 15715, 18627, 22703, 22703],
      65201: [19057, 18903, 15819, 18723, 22807, 22807],
      65401: [19161, 19007, 15923, 18819, 22911, 22911],
      65601: [19265, 19111, 16027, 18915, 23015, 23015],
      65801: [19369, 19215, 16131, 19011, 23119, 23119],
      66001: [19473, 19319, 16235, 19107, 23223, 23223],
      66201: [19577, 19423, 16339, 19204, 23327, 23327],
      66401: [19681, 19527, 16443, 19300, 23431, 23431],
      66601: [19785, 19631, 16547, 19396, 23535, 23535],
      66801: [19889, 19735, 16651, 19492, 23639, 23639],
      67001: [19993, 19839, 16755, 19588, 23743, 23743],
      67201: [20097, 19943, 16859, 19684, 23847, 23847],
      67401: [20201, 20047, 16963, 19780, 23951, 23951],
      67601: [20305, 20151, 17067, 19876, 24055, 24055],
      67801: [20409, 20255, 17171, 19972, 24159, 24159],
      68001: [20513, 20359, 17275, 20069, 24263, 24263],
      68201: [20617, 20463, 17379, 20165, 24367, 24367],
      68401: [20721, 20567, 17483, 20261, 24471, 24471],
      68601: [20825, 20671, 17587, 20357, 24575, 24575],
      68801: [20929, 20775, 17691, 20453, 24679, 24679],
      69001: [21033, 20879, 17795, 20549, 24783, 24783],
      69201: [21137, 20983, 17899, 20645, 24887, 24887],
      69401: [21241, 21087, 18003, 20741, 24991, 24991],
      69601: [21345, 21191, 18107, 20837, 25095, 25095],
      69801: [21449, 21295, 18211, 20933, 25199, 25199],
      70001: [21553, 21399, 18315, 21030, 25303, 25303],
      70201: [21657, 21503, 18419, 21126, 25407, 25407],
      70401: [21761, 21607, 18523, 21222, 25511, 25511],
      70601: [21865, 21711, 18627, 21318, 25615, 25615],
      70801: [21969, 21815, 18731, 21414, 25719, 25719],
      71001: [22073, 21919, 18835, 21510, 25823, 25823],
      71201: [22177, 22023, 18939, 21606, 25927, 25927],
      71401: [22281, 22127, 19043, 21702, 26031, 26031],
      71601: [22385, 22231, 19147, 21798, 26135, 26135],
      71801: [22489, 22335, 19251, 21895, 26239, 26239],
      72001: [22593, 22439, 19355, 21991, 26343, 26343],
      72201: [22697, 22543, 19459, 22087, 26447, 26447],
      72401: [22801, 22647, 19563, 22183, 26551, 26551],
      72601: [22905, 22751, 19667, 22279, 26655, 26655],
      72801: [23009, 22855, 19771, 22375, 26759, 26759],
      73001: [23113, 22959, 19875, 22471, 26863, 26863],
      73201: [23217, 23063, 19979, 22567, 26967, 26967],
      73401: [23321, 23167, 20083, 22663, 27071, 27071],
      73601: [23425, 23271, 20187, 22759, 27175, 27175],
      73801: [23529, 23375, 20291, 22856, 27279, 27279],
      74001: [23633, 23479, 20395, 22952, 27383, 27383],
      74201: [23737, 23583, 20499, 23048, 27487, 27487],
      74401: [23841, 23687, 20603, 23144, 27591, 27591],
      74601: [23945, 23791, 20707, 23240, 27695, 27695],
      74801: [24049, 23895, 20811, 23336, 27799, 27799],
      75001: [24153, 23999, 20915, 23432, 27903, 27903],
      75201: [24257, 24103, 21019, 23528, 28007, 28007],
      75401: [24361, 24207, 21123, 23624, 28111, 28111],
      75601: [24465, 24311, 21227, 23721, 28215, 28215],
      75801: [24569, 24415, 21331, 23817, 28319, 28319],
      76001: [24673, 24519, 21435, 23913, 28423, 28423],
      76201: [24777, 24623, 21539, 24009, 28527, 28527],
      76401: [24881, 24727, 21643, 24105, 28631, 28631],
      76601: [24985, 24831, 21747, 24201, 28735, 28735],
      76801: [25089, 24935, 21851, 24297, 28839, 28839],
      77001: [25193, 25039, 21955, 24393, 28943, 28943],
      77201: [25297, 25143, 22059, 24489, 29047, 29047],
      77401: [25401, 25247, 22163, 24585, 29151, 29151],
      77601: [25505, 25351, 22267, 24682, 29255, 29255],
      77801: [25609, 25455, 22371, 24778, 29359, 29359],
      78001: [25713, 25559, 22475, 24874, 29463, 29463],
      78201: [25817, 25663, 22579, 24970, 29567, 29567],
      78401: [25921, 25767, 22683, 25066, 29671, 29671],
      78601: [26025, 25871, 22787, 25162, 29775, 29775],
      78801: [26129, 25975, 22891, 25258, 29879, 29879],
      79001: [26233, 26079, 22995, 25354, 29983, 29983],
      79201: [26337, 26183, 23099, 25450, 30087, 30087],
      79401: [26441, 26287, 23203, 25547, 30191, 30191],
      79601: [26545, 26391, 23307, 25643, 30295, 30295],
      79801: [26649, 26495, 23411, 25739, 30399, 30399],
      80001: [33, 33, 29, 32, 38, 38, "%"],
      80801: [34, 33, 30, 32, 38, 38, "%"],
      81601: [34, 34, 30, 33, 38, 38, "%"],
      82801: [34, 34, 30, 33, 39, 39, "%"],
      84401: [34, 34, 31, 33, 39, 39, "%"],
      85401: [35, 34, 31, 33, 39, 39, "%"],
      86201: [35, 35, 31, 33, 39, 39, "%"],
      87201: [35, 35, 31, 34, 39, 39, "%"],
      88601: [35, 35, 32, 34, 39, 39, "%"],
      89601: [35, 35, 32, 34, 40, 40, "%"],
      90601: [36, 35, 32, 34, 40, 40, "%"],
      91401: [36, 36, 32, 34, 40, 40, "%"],
      93201: [36, 36, 33, 34, 40, 40, "%"],
      93601: [36, 36, 33, 35, 40, 40, "%"],
      96401: [37, 36, 33, 35, 40, 40, "%"],
      97201: [37, 36, 33, 35, 41, 41, "%"],
      97401: [37, 37, 33, 35, 41, 41, "%"],
      98201: [37, 37, 34, 35, 41, 41, "%"],
      101001: [37, 37, 34, 36, 41, 41, "%"],
      103001: [38, 37, 34, 36, 41, 41, "%"],
      103801: [38, 37, 35, 36, 41, 41, "%"],
      104001: [38, 38, 35, 36, 41, 41, "%"],
      106601: [38, 38, 35, 36, 42, 42, "%"],
      109801: [38, 38, 35, 37, 42, 42, "%"],
      110201: [38, 38, 36, 37, 42, 42, "%"],
      110601: [39, 38, 36, 37, 42, 42, "%"],
      111801: [39, 39, 36, 37, 42, 42, "%"],
      117201: [39, 39, 37, 37, 42, 42, "%"],
      117801: [39, 39, 37, 37, 43, 43, "%"],
      119601: [40, 39, 37, 37, 43, 43, "%"],
      120201: [40, 39, 37, 38, 43, 43, "%"],
      120801: [40, 40, 37, 38, 43, 43, "%"],
      125401: [40, 40, 38, 38, 43, 43, "%"],
      130001: [41, 40, 38, 38, 43, 43, "%"],
      131201: [41, 41, 38, 38, 43, 43, "%"],
      131601: [41, 41, 38, 38, 44, 44, "%"],
      132801: [41, 41, 38, 39, 44, 44, "%"],
      134601: [41, 41, 39, 39, 44, 44, "%"],
      142201: [42, 41, 39, 39, 44, 44, "%"],
      143801: [42, 42, 39, 39, 44, 44, "%"],
      145401: [42, 42, 40, 39, 44, 44, "%"],
      148401: [42, 42, 40, 40, 44, 44, "%"],
      149201: [42, 42, 40, 40, 45, 45, "%"],
      157201: [43, 42, 40, 40, 45, 45, "%"],
      158001: [43, 42, 41, 40, 45, 45, "%"],
      158801: [43, 43, 41, 40, 45, 45, "%"],
      168001: [43, 43, 41, 41, 45, 45, "%"],
      172201: [43, 43, 41, 41, 46, 46, "%"],
      173201: [43, 43, 42, 41, 46, 46, "%"],
      175801: [44, 43, 42, 41, 46, 46, "%"],
      177601: [44, 44, 42, 41, 46, 46, "%"],
      191401: [44, 44, 43, 41, 46, 46, "%"],
      193801: [44, 44, 43, 42, 46, 46, "%"],
      199201: [45, 44, 43, 42, 46, 46, "%"],
      201201: [45, 45, 43, 42, 46, 46, "%"],
      203601: [45, 45, 43, 42, 47, 47, "%"],
      213801: [45, 45, 44, 42, 47, 47, "%"],
      228601: [45, 45, 44, 43, 47, 47, "%"],
      230001: [46, 45, 44, 43, 47, 47, "%"],
      232201: [46, 46, 44, 43, 47, 47, "%"],
      242401: [46, 46, 45, 43, 47, 47, "%"],
      248801: [46, 46, 45, 43, 48, 48, "%"],
      271801: [47, 46, 45, 43, 48, 48, "%"],
      274601: [47, 47, 45, 43, 48, 48, "%"],
      279001: [47, 47, 45, 44, 48, 48, "%"],
      279801: [47, 47, 46, 44, 48, 48, "%"],
      320001: [47, 47, 46, 44, 49, 49, "%"],
      330601: [47, 47, 47, 44, 49, 49, "%"],
      332201: [48, 47, 47, 44, 49, 49, "%"],
      335601: [48, 48, 47, 44, 49, 49, "%"],
      357401: [48, 48, 47, 45, 49, 49, "%"],
      404001: [48, 48, 48, 45, 49, 49, "%"],
      427001: [49, 48, 48, 45, 49, 49, "%"],
      431401: [49, 49, 48, 45, 49, 49, "%"],
      448001: [49, 49, 48, 45, 50, 50, "%"],
      497601: [49, 49, 48, 46, 50, 50, "%"],
      519601: [49, 49, 49, 46, 50, 50, "%"],
      598001: [50, 49, 49, 46, 50, 50, "%"],
      604001: [50, 50, 49, 46, 50, 50, "%"],
      727401: [50, 50, 50, 46, 50, 50, "%"],
      746601: [50, 50, 50, 46, 51, 51, "%"],
      818201: [50, 50, 50, 47, 51, 51, "%"],
      996601: [51, 50, 50, 47, 51, 51, "%"],
      1006801: [51, 51, 50, 47, 51, 51, "%"],
      1212401: [51, 51, 51, 47, 51, 51, "%"]
    },
    33: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 8],
      2101: [152, 0, 150, 2, 152, 42],
      2201: [163, 0, 158, 4, 163, 76],
      2301: [173, 0, 166, 7, 173, 110],
      2401: [184, 0, 175, 9, 184, 144],
      2501: [194, 0, 183, 11, 194, 178],
      2601: [205, 0, 191, 13, 212, 212],
      2701: [215, 0, 200, 15, 246, 246],
      2801: [217, 0, 200, 17, 280, 280],
      2901: [228, 0, 208, 19, 314, 314],
      3001: [238, 0, 216, 22, 348, 348],
      3101: [249, 0, 225, 24, 382, 382],
      3201: [259, 0, 233, 26, 416, 416],
      3301: [270, 0, 241, 28, 450, 450],
      3401: [272, 0, 241, 30, 484, 484],
      3501: [282, 0, 250, 32, 518, 518],
      3601: [293, 0, 258, 35, 552, 552],
      3701: [303, 0, 266, 37, 586, 586],
      3801: [314, 0, 275, 39, 620, 620],
      3901: [324, 0, 283, 41, 654, 654],
      4001: [327, 0, 283, 43, 688, 688],
      4101: [337, 0, 291, 45, 722, 722],
      4201: [348, 0, 300, 48, 756, 756],
      4301: [358, 0, 308, 50, 790, 790],
      4401: [369, 0, 316, 60, 824, 824],
      4501: [379, 0, 325, 84, 858, 858],
      4601: [381, 0, 325, 107, 892, 892],
      4701: [392, 0, 333, 130, 926, 926],
      4801: [402, 0, 341, 153, 957, 957],
      4901: [412, 0, 350, 176, 983, 983],
      5001: [422, 0, 358, 198, 1011, 1011],
      5101: [432, 0, 366, 221, 1036, 1036],
      5201: [442, 0, 375, 244, 1065, 1065],
      5301: [444, 0, 375, 267, 1093, 1093],
      5401: [454, 14, 384, 289, 1119, 1119],
      5501: [464, 39, 394, 312, 1147, 1147],
      5601: [474, 65, 404, 334, 1172, 1172],
      5701: [484, 90, 414, 356, 1199, 1199],
      5801: [494, 113, 423, 378, 1227, 1227],
      5901: [496, 138, 425, 400, 1252, 1252],
      6001: [506, 164, 435, 422, 1280, 1280],
      6101: [516, 189, 445, 444, 1305, 1305],
      6201: [526, 215, 455, 467, 1332, 1332],
      6301: [536, 240, 465, 489, 1360, 1360],
      6401: [546, 266, 475, 511, 1385, 1385],
      6501: [548, 291, 476, 533, 1413, 1413],
      6601: [558, 317, 486, 555, 1438, 1438],
      6701: [568, 342, 496, 577, 1465, 1465],
      6801: [578, 368, 506, 599, 1493, 1493],
      6901: [588, 393, 516, 621, 1518, 1518],
      7001: [604, 416, 526, 644, 1546, 1546],
      7101: [625, 441, 528, 666, 1571, 1571],
      7201: [645, 467, 538, 688, 1598, 1598],
      7301: [666, 492, 547, 710, 1626, 1626],
      7401: [687, 518, 557, 732, 1651, 1651],
      7501: [707, 543, 567, 754, 1679, 1679],
      7601: [728, 569, 577, 776, 1704, 1704],
      7701: [749, 594, 587, 798, 1731, 1731],
      7801: [769, 620, 589, 821, 1759, 1759],
      7901: [790, 645, 599, 843, 1784, 1784],
      8001: [811, 671, 609, 865, 1812, 1812],
      8101: [831, 696, 619, 887, 1837, 1837],
      8201: [852, 719, 629, 909, 1864, 1864],
      8301: [872, 745, 639, 931, 1892, 1892],
      8401: [893, 770, 640, 953, 1917, 1917],
      8501: [914, 796, 650, 976, 1945, 1945],
      8601: [934, 821, 660, 998, 1970, 1970],
      8701: [955, 847, 670, 1020, 1997, 1997],
      8801: [976, 872, 680, 1042, 2025, 2025],
      8901: [996, 898, 690, 1064, 2050, 2050],
      9001: [1017, 923, 692, 1086, 2078, 2078],
      9101: [1037, 949, 701, 1108, 2103, 2103],
      9201: [1058, 974, 711, 1130, 2130, 2130],
      9301: [1079, 1e3, 721, 1153, 2158, 2158],
      9401: [1099, 1022, 731, 1175, 2183, 2183],
      9501: [1120, 1048, 741, 1197, 2211, 2211],
      9601: [1141, 1065, 742, 1219, 2236, 2236],
      9701: [1161, 1085, 752, 1241, 2263, 2263],
      9801: [1182, 1104, 761, 1263, 2291, 2291],
      9901: [1202, 1127, 771, 1285, 2316, 2316],
      10001: [1223, 1147, 781, 1307, 2344, 2344],
      10101: [1244, 1166, 790, 1329, 2369, 2369],
      10201: [1264, 1185, 800, 1352, 2396, 2396],
      10301: [1285, 1208, 801, 1374, 2424, 2424],
      10401: [1306, 1227, 811, 1396, 2449, 2449],
      10501: [1326, 1246, 821, 1418, 2477, 2477],
      10601: [1347, 1266, 830, 1440, 2502, 2502],
      10701: [1368, 1288, 840, 1462, 2529, 2529],
      10801: [1388, 1307, 850, 1484, 2557, 2557],
      10901: [1409, 1327, 851, 1506, 2582, 2582],
      11001: [1430, 1346, 860, 1529, 2610, 2610],
      11101: [1450, 1368, 870, 1551, 2635, 2635],
      11201: [1471, 1388, 880, 1573, 2662, 2662],
      11301: [1491, 1407, 889, 1595, 2690, 2690],
      11401: [1512, 1427, 899, 1617, 2715, 2715],
      11501: [1533, 1449, 900, 1639, 2743, 2743],
      11601: [1553, 1468, 910, 1661, 2768, 2768],
      11701: [1574, 1487, 920, 1684, 2795, 2795],
      11801: [1595, 1507, 929, 1706, 2823, 2823],
      11901: [1615, 1529, 939, 1728, 2848, 2848],
      12001: [1636, 1549, 949, 1750, 2876, 2876],
      12101: [1656, 1568, 950, 1772, 2901, 2901],
      12201: [1677, 1590, 960, 1794, 2928, 2928],
      12301: [1698, 1609, 969, 1816, 2956, 2956],
      12401: [1718, 1629, 979, 1838, 2981, 2981],
      12501: [1739, 1648, 988, 1861, 3009, 3009],
      12601: [1760, 1670, 998, 1883, 3034, 3034],
      12701: [1780, 1690, 1008, 1905, 3061, 3061],
      12801: [1801, 1709, 1009, 1927, 3089, 3089],
      12901: [1821, 1729, 1019, 1949, 3114, 3114],
      13001: [1842, 1751, 1028, 1971, 3142, 3142],
      13101: [1863, 1770, 1038, 1993, 3167, 3167],
      13201: [1883, 1790, 1047, 2015, 3194, 3194],
      13301: [1904, 1809, 1057, 2038, 3225, 3225],
      13401: [1925, 1831, 1059, 2060, 3258, 3258],
      13501: [1946, 1850, 1068, 2083, 3291, 3291],
      13601: [1967, 1870, 1078, 2105, 3325, 3325],
      13701: [1988, 1889, 1087, 2127, 3358, 3358],
      13801: [2009, 1911, 1097, 2150, 3391, 3391],
      13901: [2030, 1931, 1107, 2172, 3424, 3424],
      14001: [2051, 1950, 1108, 2195, 3458, 3458],
      14101: [2071, 1970, 1118, 2217, 3491, 3491],
      14201: [2091, 1992, 1127, 2238, 3523, 3523],
      14301: [2111, 2011, 1137, 2260, 3555, 3555],
      14401: [2131, 2031, 1146, 2281, 3587, 3587],
      14501: [2151, 2053, 1156, 2302, 3620, 3620],
      14601: [2171, 2072, 1158, 2324, 3652, 3652],
      14701: [2191, 2092, 1167, 2345, 3684, 3684],
      14801: [2211, 2111, 1177, 2367, 3716, 3716],
      14901: [2231, 2133, 1186, 2388, 3749, 3749],
      15001: [2251, 2152, 1196, 2409, 3781, 3781],
      15101: [2270, 2172, 1206, 2431, 3813, 3813],
      15201: [2290, 2191, 1215, 2452, 3845, 3845],
      15301: [2310, 2213, 1217, 2474, 3883, 3883],
      15401: [2330, 2233, 1226, 2495, 3918, 3918],
      15501: [2350, 2252, 1236, 2517, 3953, 3953],
      15601: [2370, 2272, 1245, 2538, 3988, 3988],
      15701: [2390, 2294, 1255, 2560, 4023, 4023],
      15801: [2411, 2313, 1265, 2583, 4060, 4060],
      15901: [2437, 2341, 1267, 2611, 4095, 4095],
      16001: [2463, 2366, 1277, 2640, 4130, 4130],
      16101: [2489, 2394, 1287, 2668, 4165, 4165],
      16201: [2515, 2418, 1297, 2696, 4200, 4200],
      16301: [2541, 2446, 1307, 2724, 4238, 4238],
      16401: [2567, 2474, 1317, 2753, 4273, 4273],
      16501: [2593, 2499, 1319, 2781, 4308, 4308],
      16601: [2619, 2527, 1329, 2809, 4343, 4343],
      16701: [2645, 2551, 1339, 2837, 4378, 4378],
      16801: [2671, 2579, 1349, 2865, 4415, 4415],
      16901: [2697, 2604, 1359, 2894, 4450, 4450],
      17001: [2723, 2632, 1369, 2922, 4485, 4485],
      17101: [2748, 2657, 1371, 2950, 4520, 4520],
      17201: [2774, 2684, 1381, 2978, 4555, 4555],
      17301: [2800, 2709, 1391, 3006, 4593, 4593],
      17401: [2826, 2737, 1401, 3035, 4627, 4627],
      17501: [2852, 2765, 1411, 3063, 4662, 4662],
      17601: [2878, 2790, 1421, 3091, 4697, 4697],
      17701: [2904, 2817, 1431, 3119, 4732, 4732],
      17801: [2930, 2842, 1433, 3148, 4770, 4770],
      17901: [2956, 2870, 1443, 3176, 4805, 4805],
      18001: [2982, 2895, 1453, 3204, 4840, 4840],
      18101: [3008, 2923, 1463, 3232, 4875, 4875],
      18201: [3034, 2948, 1473, 3260, 4910, 4910],
      18301: [3060, 2975, 1483, 3289, 4947, 4947],
      18401: [3086, 3003, 1485, 3317, 4982, 4982],
      18501: [3112, 3028, 1495, 3345, 5017, 5017],
      18601: [3138, 3056, 1505, 3373, 5052, 5052],
      18701: [3164, 3081, 1515, 3401, 5087, 5087],
      18801: [3190, 3108, 1525, 3430, 5125, 5125],
      18901: [3216, 3133, 1535, 3458, 5160, 5160],
      19001: [3242, 3161, 1537, 3486, 5195, 5195],
      19101: [3268, 3186, 1547, 3514, 5229, 5229],
      19201: [3293, 3214, 1557, 3543, 5264, 5264],
      19301: [3320, 3241, 1567, 3571, 5302, 5302],
      19401: [3345, 3266, 1577, 3599, 5337, 5337],
      19501: [3371, 3294, 1587, 3627, 5372, 5372],
      19601: [3397, 3319, 1589, 3655, 5407, 5407],
      19701: [3423, 3347, 1599, 3684, 5442, 5442],
      19801: [3449, 3372, 1609, 3712, 5479, 5479],
      19901: [3475, 3399, 1619, 3740, 5514, 5514],
      20001: [3527, 3452, 1639, 3796, 5584, 5584],
      20201: [3579, 3504, 1650, 3853, 5657, 5657],
      20401: [3631, 3555, 1669, 3909, 5727, 5727],
      20601: [3683, 3606, 1687, 3966, 5797, 5797],
      20801: [3735, 3657, 1697, 4022, 5869, 5869],
      21001: [3787, 3708, 1716, 4079, 5939, 5939],
      21201: [3839, 3762, 1734, 4135, 6012, 6012],
      21401: [3890, 3813, 1744, 4191, 6081, 6081],
      21601: [3942, 3864, 1763, 4248, 6151, 6151],
      21801: [3994, 3915, 1781, 4304, 6224, 6224],
      22001: [4046, 3966, 1792, 4361, 6294, 6294],
      22201: [4098, 4017, 1810, 4417, 6366, 6366],
      22401: [4150, 4068, 1829, 4474, 6436, 6436],
      22601: [4202, 4120, 1847, 4530, 6506, 6506],
      22801: [4254, 4171, 1857, 4587, 6579, 6579],
      23001: [4307, 4222, 1876, 4645, 6650, 6650],
      23201: [4361, 4275, 1894, 4703, 6724, 6724],
      23401: [4414, 4326, 1905, 4761, 6796, 6796],
      23601: [4468, 4378, 1923, 4819, 6867, 6867],
      23801: [4522, 4429, 1942, 4877, 6942, 6942],
      24001: [4575, 4480, 1952, 4935, 7013, 7013],
      24201: [4629, 4531, 1970, 4993, 7087, 7087],
      24401: [4682, 4582, 1989, 5051, 7159, 7159],
      24601: [4736, 4636, 1999, 5109, 7230, 7230],
      24801: [4789, 4689, 2018, 5167, 7305, 7305],
      25001: [4843, 4740, 2036, 5225, 7376, 7376],
      25201: [4897, 4794, 2046, 5283, 7450, 7450],
      25401: [4950, 4845, 2065, 5342, 7522, 7522],
      25601: [5004, 4899, 2084, 5400, 7593, 7593],
      25801: [5057, 4950, 2094, 5458, 7668, 7668],
      26001: [5111, 5004, 2112, 5516, 7739, 7739],
      26201: [5164, 5055, 2131, 5574, 7813, 7813],
      26401: [5218, 5109, 2141, 5632, 7885, 7885],
      26601: [5271, 5160, 2160, 5690, 7956, 7956],
      26801: [5325, 5213, 2178, 5748, 8031, 8031],
      27001: [5379, 5264, 2188, 5806, 8102, 8102],
      27201: [5432, 5318, 2235, 5864, 8176, 8176],
      27401: [5486, 5369, 2286, 5922, 8248, 8248],
      27601: [5539, 5423, 2339, 5980, 8319, 8319],
      27801: [5593, 5477, 2393, 6039, 8394, 8394],
      28001: [5646, 5528, 2444, 6097, 8465, 8465],
      28201: [5700, 5582, 2498, 6155, 8539, 8539],
      28401: [5753, 5633, 2549, 6213, 8611, 8611],
      28601: [5807, 5686, 2603, 6271, 8682, 8682],
      28801: [5861, 5737, 2654, 6329, 8757, 8757],
      29001: [5914, 5791, 2708, 6387, 8828, 8828],
      29201: [5968, 5842, 2759, 6445, 8902, 8902],
      29401: [6021, 5896, 2812, 6503, 8974, 8974],
      29601: [6075, 5947, 2864, 6561, 9045, 9045],
      29801: [6128, 6001, 2917, 6619, 9120, 9120],
      30001: [6182, 6052, 2968, 6677, 9191, 9191],
      30201: [6236, 6106, 3022, 6736, 9265, 9265],
      30401: [6289, 6159, 3076, 6794, 9337, 9337],
      30601: [6343, 6210, 3127, 6852, 9408, 9408],
      30801: [6396, 6264, 3181, 6910, 9483, 9483],
      31001: [6450, 6315, 3232, 6968, 9554, 9554],
      31201: [6503, 6369, 3285, 7026, 9628, 9628],
      31401: [6557, 6420, 3337, 7084, 9700, 9700],
      31601: [6610, 6474, 3390, 7142, 9771, 9771],
      31801: [6664, 6525, 3441, 7200, 9846, 9846],
      32001: [6718, 6579, 3495, 7258, 9917, 9917],
      32201: [6771, 6631, 3547, 7316, 9991, 9991],
      32401: [6825, 6686, 3602, 7374, 10063, 10063],
      32601: [6878, 6738, 3655, 7432, 10134, 10134],
      32801: [6932, 6793, 3710, 7491, 10209, 10209],
      33001: [6985, 6845, 3762, 7549, 10280, 10280],
      33201: [7039, 6900, 3817, 7607, 10354, 10354],
      33401: [7092, 6955, 3872, 7665, 10426, 10426],
      33601: [7146, 7008, 3924, 7723, 10497, 10497],
      33801: [7200, 7063, 3979, 7781, 10572, 10572],
      34001: [7253, 7115, 4031, 7839, 10643, 10643],
      34201: [7307, 7170, 4086, 7897, 10717, 10717],
      34401: [7360, 7222, 4139, 7955, 10789, 10789],
      34601: [7414, 7277, 4194, 8013, 10860, 10860],
      34801: [7467, 7329, 4246, 8071, 10935, 10935],
      35001: [7521, 7384, 4301, 8129, 11006, 11006],
      35201: [7575, 7437, 4353, 8188, 11080, 11080],
      35401: [7628, 7492, 4408, 8246, 11152, 11152],
      35601: [7682, 7544, 4460, 8304, 11223, 11223],
      35801: [7735, 7599, 4515, 8362, 11298, 11298],
      36001: [7789, 7654, 4570, 8420, 11369, 11369],
      36201: [7842, 7706, 4623, 8478, 11443, 11443],
      36401: [7896, 7761, 4678, 8536, 11515, 11515],
      36601: [7949, 7813, 4730, 8594, 11586, 11586],
      36801: [8003, 7868, 4785, 8652, 11661, 11661],
      37001: [8057, 7921, 4837, 8710, 11732, 11732],
      37201: [8110, 7976, 4892, 8768, 11806, 11806],
      37401: [8164, 8028, 4944, 8826, 11878, 11878],
      37601: [8217, 8083, 4999, 8884, 11949, 11949],
      37801: [8271, 8135, 5052, 8943, 12024, 12024],
      38001: [8324, 8190, 5107, 9001, 12095, 12095],
      38201: [8378, 8242, 5159, 9059, 12169, 12169],
      38401: [8431, 8297, 5214, 9117, 12241, 12241],
      38601: [8485, 8350, 5266, 9175, 12307, 12307],
      38801: [8538, 8402, 5318, 9232, 12373, 12373],
      39001: [8591, 8454, 5371, 9290, 12439, 12439],
      39201: [8645, 8509, 5426, 9348, 12505, 12505],
      39401: [8699, 8561, 5478, 9406, 12571, 12571],
      39601: [8765, 8627, 5544, 9464, 12637, 12637],
      39801: [8831, 8693, 5610, 9522, 12703, 12703],
      40001: [8897, 8759, 5676, 9580, 12769, 12769],
      40201: [8963, 8825, 5742, 9638, 12835, 12835],
      40401: [9029, 8891, 5808, 9695, 12901, 12901],
      40601: [9095, 8957, 5874, 9753, 12967, 12967],
      40801: [9161, 9023, 5940, 9811, 13033, 13033],
      41001: [9227, 9089, 6006, 9869, 13099, 13099],
      41201: [9293, 9155, 6072, 9927, 13165, 13165],
      41401: [9359, 9221, 6138, 9985, 13231, 13231],
      41601: [9425, 9287, 6204, 10042, 13297, 13297],
      41801: [9491, 9353, 6270, 10100, 13363, 13363],
      42001: [9557, 9419, 6336, 10158, 13429, 13429],
      42201: [9623, 9485, 6402, 10216, 13495, 13495],
      42401: [9689, 9551, 6468, 10274, 13561, 13561],
      42601: [9755, 9617, 6534, 10332, 13627, 13627],
      42801: [9821, 9683, 6600, 10389, 13693, 13693],
      43001: [9887, 9749, 6666, 10447, 13759, 13759],
      43201: [9953, 9815, 6732, 10505, 13825, 13825],
      43401: [10019, 9881, 6798, 10563, 13891, 13891],
      43601: [10085, 9947, 6864, 10621, 13957, 13957],
      43801: [10151, 10013, 6930, 10679, 14023, 14023],
      44001: [10217, 10079, 6996, 10737, 14089, 14089],
      44201: [10283, 10145, 7062, 10794, 14155, 14155],
      44401: [10349, 10211, 7128, 10852, 14221, 14221],
      44601: [10415, 10277, 7194, 10910, 14287, 14287],
      44801: [10481, 10343, 7260, 10968, 14353, 14353],
      45001: [10547, 10409, 7326, 11026, 14419, 14419],
      45201: [10613, 10475, 7392, 11084, 14485, 14485],
      45401: [10679, 10541, 7458, 11141, 14551, 14551],
      45601: [10745, 10607, 7524, 11199, 14617, 14617],
      45801: [10811, 10673, 7590, 11257, 14683, 14683],
      46001: [10877, 10739, 7656, 11315, 14749, 14749],
      46201: [10943, 10805, 7722, 11373, 14815, 14815],
      46401: [11009, 10871, 7788, 11431, 14881, 14881],
      46601: [11075, 10937, 7854, 11489, 14947, 14947],
      46801: [11141, 11003, 7920, 11546, 15013, 15013],
      47001: [11207, 11069, 7986, 11604, 15079, 15079],
      47201: [11273, 11135, 8052, 11662, 15145, 15145],
      47401: [11339, 11201, 8118, 11720, 15211, 15211],
      47601: [11405, 11267, 8184, 11778, 15277, 15277],
      47801: [11471, 11333, 8250, 11836, 15343, 15343],
      48001: [11537, 11399, 8316, 11894, 15409, 15409],
      48201: [11603, 11465, 8382, 11951, 15475, 15475],
      48401: [11669, 11531, 8448, 12009, 15541, 15541],
      48601: [11735, 11597, 8514, 12067, 15607, 15607],
      48801: [11801, 11663, 8580, 12125, 15673, 15673],
      49001: [11867, 11729, 8646, 12183, 15739, 15739],
      49201: [11933, 11795, 8712, 12241, 15805, 15805],
      49401: [11999, 11861, 8778, 12298, 15871, 15871],
      49601: [12065, 11927, 8844, 12356, 15937, 15937],
      49801: [12131, 11993, 8910, 12414, 16003, 16003],
      50001: [12197, 12059, 8976, 12472, 16069, 16069],
      50201: [12263, 12125, 9042, 12530, 16135, 16135],
      50401: [12329, 12191, 9108, 12588, 16201, 16201],
      50601: [12395, 12257, 9174, 12646, 16267, 16267],
      50801: [12461, 12323, 9240, 12703, 16333, 16333],
      51001: [12527, 12389, 9306, 12761, 16399, 16399],
      51201: [12593, 12455, 9372, 12819, 16465, 16465],
      51401: [12659, 12521, 9438, 12877, 16531, 16531],
      51601: [12725, 12587, 9504, 12935, 16597, 16597],
      51801: [12791, 12653, 9570, 12993, 16663, 16663],
      52001: [12857, 12719, 9636, 13051, 16729, 16729],
      52201: [12923, 12785, 9702, 13108, 16795, 16795],
      52401: [12989, 12851, 9768, 13166, 16861, 16861],
      52601: [13055, 12917, 9834, 13224, 16927, 16927],
      52801: [13121, 12983, 9900, 13282, 16993, 16993],
      53001: [13187, 13049, 9966, 13340, 17059, 17059],
      53201: [13253, 13115, 10032, 13398, 17125, 17125],
      53401: [13321, 13181, 10098, 13457, 17192, 17192],
      53601: [13427, 13286, 10202, 13555, 17298, 17298],
      53801: [13533, 13393, 10310, 13653, 17404, 17404],
      54001: [13639, 13500, 10417, 13751, 17510, 17510],
      54201: [13745, 13608, 10524, 13849, 17616, 17616],
      54401: [13851, 13715, 10631, 13946, 17722, 17722],
      54601: [13957, 13822, 10739, 14044, 17828, 17828],
      54801: [14063, 13929, 10846, 14142, 17934, 17934],
      55001: [14169, 14034, 10950, 14240, 18040, 18040],
      55201: [14275, 14141, 11058, 14338, 18147, 18147],
      55401: [14381, 14248, 11165, 14436, 18252, 18252],
      55601: [14487, 14356, 11272, 14533, 18358, 18358],
      55801: [14593, 14463, 11379, 14631, 18464, 18464],
      56001: [14699, 14570, 11487, 14729, 18570, 18570],
      56201: [14805, 14677, 11594, 14827, 18676, 18676],
      56401: [14911, 14785, 11701, 14925, 18782, 18782],
      56601: [15017, 14889, 11806, 15023, 18888, 18888],
      56801: [15123, 14996, 11913, 15121, 18994, 18994],
      57001: [15229, 15104, 12020, 15218, 19100, 19100],
      57201: [15335, 15211, 12127, 15316, 19206, 19206],
      57401: [15441, 15318, 12235, 15414, 19312, 19312],
      57601: [15547, 15425, 12342, 15512, 19418, 19418],
      57801: [15653, 15533, 12449, 15610, 19524, 19524],
      58001: [15759, 15640, 12556, 15708, 19630, 19630],
      58201: [15865, 15747, 12664, 15806, 19736, 19736],
      58401: [15971, 15852, 12768, 15903, 19842, 19842],
      58601: [16077, 15959, 12875, 16001, 19948, 19948],
      58801: [16183, 16066, 12983, 16099, 20054, 20054],
      59001: [16289, 16173, 13090, 16197, 20160, 20160],
      59201: [16395, 16281, 13197, 16295, 20266, 20266],
      59401: [16501, 16388, 13304, 16393, 20372, 20372],
      59601: [16607, 16495, 13412, 16490, 20478, 20478],
      59801: [16713, 16602, 13519, 16588, 20584, 20584],
      60001: [16819, 16707, 13623, 16686, 20690, 20690],
      60201: [16925, 16814, 13731, 16784, 20797, 20797],
      60401: [17031, 16921, 13838, 16882, 20902, 20902],
      60601: [17137, 17029, 13945, 16980, 21008, 21008],
      60801: [17243, 17136, 14052, 17078, 21114, 21114],
      61001: [17349, 17241, 14158, 17175, 21220, 21220],
      61201: [17455, 17347, 14264, 17273, 21326, 21326],
      61401: [17561, 17453, 14370, 17371, 21432, 21432],
      61601: [17667, 17559, 14476, 17469, 21538, 21538],
      61801: [17773, 17665, 14582, 17567, 21644, 21644],
      62001: [17879, 17771, 14688, 17665, 21750, 21750],
      62201: [17985, 17877, 14794, 17762, 21856, 21856],
      62401: [18091, 17983, 14900, 17860, 21962, 21962],
      62601: [18197, 18089, 15006, 17958, 22068, 22068],
      62801: [18303, 18195, 15112, 18056, 22174, 22174],
      63001: [18409, 18301, 15218, 18154, 22280, 22280],
      63201: [18515, 18407, 15324, 18252, 22386, 22386],
      63401: [18621, 18513, 15430, 18350, 22492, 22492],
      63601: [18727, 18619, 15536, 18447, 22598, 22598],
      63801: [18833, 18725, 15642, 18545, 22704, 22704],
      64001: [18939, 18831, 15748, 18643, 22810, 22810],
      64201: [19045, 18937, 15854, 18741, 22916, 22916],
      64401: [19151, 19043, 15960, 18839, 23022, 23022],
      64601: [19257, 19149, 16066, 18937, 23128, 23128],
      64801: [19363, 19255, 16172, 19035, 23234, 23234],
      65001: [19469, 19361, 16278, 19132, 23340, 23340],
      65201: [19575, 19467, 16384, 19230, 23447, 23447],
      65401: [19681, 19573, 16490, 19328, 23552, 23552],
      65601: [19787, 19679, 16596, 19426, 23658, 23658],
      65801: [19893, 19785, 16702, 19524, 23764, 23764],
      66001: [19999, 19891, 16808, 19622, 23870, 23870],
      66201: [20105, 19997, 16914, 19719, 23976, 23976],
      66401: [20211, 20103, 17020, 19817, 24082, 24082],
      66601: [20317, 20209, 17126, 19915, 24188, 24188],
      66801: [20423, 20315, 17232, 20013, 24294, 24294],
      67001: [20529, 20421, 17338, 20111, 24400, 24400],
      67201: [20635, 20527, 17444, 20209, 24506, 24506],
      67401: [20741, 20633, 17550, 20307, 24612, 24612],
      67601: [20847, 20739, 17656, 20404, 24718, 24718],
      67801: [20953, 20845, 17762, 20502, 24824, 24824],
      68001: [21059, 20951, 17868, 20600, 24930, 24930],
      68201: [21165, 21057, 17974, 20698, 25036, 25036],
      68401: [21271, 21163, 18080, 20796, 25142, 25142],
      68601: [21377, 21269, 18186, 20894, 25248, 25248],
      68801: [21483, 21375, 18292, 20991, 25354, 25354],
      69001: [21589, 21481, 18398, 21089, 25460, 25460],
      69201: [21695, 21587, 18504, 21187, 25566, 25566],
      69401: [21801, 21693, 18610, 21285, 25672, 25672],
      69601: [21907, 21799, 18716, 21383, 25778, 25778],
      69801: [22013, 21905, 18822, 21481, 25884, 25884],
      70001: [22119, 22011, 18928, 21579, 25990, 25990],
      70201: [22225, 22117, 19034, 21677, 26097, 26097],
      70401: [22331, 22223, 19140, 21774, 26202, 26202],
      70601: [22437, 22329, 19246, 21872, 26308, 26308],
      70801: [22543, 22435, 19352, 21970, 26414, 26414],
      71001: [22649, 22541, 19458, 22068, 26520, 26520],
      71201: [22755, 22647, 19564, 22166, 26626, 26626],
      71401: [22861, 22753, 19670, 22264, 26732, 26732],
      71601: [22967, 22859, 19776, 22361, 26838, 26838],
      71801: [23073, 22965, 19882, 22459, 26944, 26944],
      72001: [23179, 23071, 19988, 22557, 27050, 27050],
      72201: [23285, 23177, 20094, 22655, 27156, 27156],
      72401: [23391, 23283, 20200, 22753, 27262, 27262],
      72601: [23497, 23389, 20306, 22851, 27368, 27368],
      72801: [23603, 23495, 20412, 22948, 27474, 27474],
      73001: [23709, 23601, 20518, 23046, 27580, 27580],
      73201: [23815, 23707, 20624, 23144, 27686, 27686],
      73401: [23921, 23813, 20730, 23242, 27792, 27792],
      73601: [24027, 23919, 20836, 23340, 27898, 27898],
      73801: [24133, 24025, 20942, 23438, 28004, 28004],
      74001: [24239, 24131, 21048, 23536, 28110, 28110],
      74201: [24345, 24237, 21154, 23633, 28216, 28216],
      74401: [24451, 24343, 21260, 23731, 28322, 28322],
      74601: [24557, 24449, 21366, 23829, 28428, 28428],
      74801: [24663, 24555, 21472, 23927, 28534, 28534],
      75001: [24769, 24661, 21578, 24025, 28640, 28640],
      75201: [24875, 24767, 21684, 24123, 28747, 28747],
      75401: [24981, 24873, 21790, 24221, 28852, 28852],
      75601: [25087, 24979, 21896, 24318, 28958, 28958],
      75801: [25193, 25085, 22002, 24416, 29064, 29064],
      76001: [25299, 25191, 22108, 24514, 29170, 29170],
      76201: [25405, 25297, 22214, 24612, 29276, 29276],
      76401: [25511, 25403, 22320, 24710, 29382, 29382],
      76601: [25617, 25509, 22426, 24808, 29488, 29488],
      76801: [25723, 25615, 22532, 24905, 29594, 29594],
      77001: [25829, 25721, 22638, 25003, 29700, 29700],
      77201: [25935, 25827, 22744, 25101, 29806, 29806],
      77401: [26041, 25933, 22850, 25199, 29912, 29912],
      77601: [26147, 26039, 22956, 25297, 30018, 30018],
      77801: [26253, 26145, 23062, 25395, 30124, 30124],
      78001: [26359, 26251, 23168, 25493, 30230, 30230],
      78201: [26465, 26357, 23274, 25590, 30336, 30336],
      78401: [26571, 26463, 23380, 25688, 30442, 30442],
      78601: [26677, 26569, 23486, 25786, 30548, 30548],
      78801: [26783, 26675, 23592, 25884, 30654, 30654],
      79001: [26889, 26781, 23698, 25982, 30760, 30760],
      79201: [26995, 26887, 23804, 26080, 30866, 30866],
      79401: [27101, 26993, 23910, 26177, 30972, 30972],
      79601: [27207, 27099, 24016, 26275, 31078, 31078],
      79801: [27313, 27205, 24122, 26373, 31184, 31184],
      80001: [34, 34, 30, 33, 39, 39, "%"],
      81201: [34, 34, 31, 33, 39, 39, "%"],
      81401: [35, 34, 31, 33, 39, 39, "%"],
      82001: [35, 35, 31, 33, 39, 39, "%"],
      82601: [35, 35, 31, 34, 39, 39, "%"],
      83001: [35, 35, 31, 34, 40, 40, "%"],
      85001: [35, 35, 32, 34, 40, 40, "%"],
      86201: [36, 35, 32, 34, 40, 40, "%"],
      86801: [36, 36, 32, 34, 40, 40, "%"],
      88401: [36, 36, 32, 35, 40, 40, "%"],
      89001: [36, 36, 33, 35, 40, 40, "%"],
      89601: [36, 36, 33, 35, 41, 41, "%"],
      91401: [37, 36, 33, 35, 41, 41, "%"],
      92001: [37, 37, 33, 35, 41, 41, "%"],
      93601: [37, 37, 34, 35, 41, 41, "%"],
      95001: [37, 37, 34, 36, 41, 41, "%"],
      97201: [38, 37, 34, 36, 41, 41, "%"],
      97401: [38, 37, 34, 36, 42, 42, "%"],
      98001: [38, 38, 34, 36, 42, 42, "%"],
      98601: [38, 38, 35, 36, 42, 42, "%"],
      102601: [38, 38, 35, 37, 42, 42, "%"],
      104001: [39, 38, 35, 37, 42, 42, "%"],
      104401: [39, 38, 36, 37, 42, 42, "%"],
      104601: [39, 39, 36, 37, 42, 42, "%"],
      106801: [39, 39, 36, 37, 43, 43, "%"],
      110601: [39, 39, 37, 37, 43, 43, "%"],
      111601: [40, 39, 37, 38, 43, 43, "%"],
      112401: [40, 40, 37, 38, 43, 43, "%"],
      117801: [40, 40, 38, 38, 43, 43, "%"],
      118001: [40, 40, 38, 38, 44, 44, "%"],
      120601: [41, 40, 38, 38, 44, 44, "%"],
      121401: [41, 41, 38, 38, 44, 44, "%"],
      122401: [41, 41, 38, 39, 44, 44, "%"],
      126001: [41, 41, 39, 39, 44, 44, "%"],
      131001: [42, 41, 39, 39, 44, 44, "%"],
      131801: [42, 41, 39, 39, 45, 45, "%"],
      132001: [42, 42, 39, 39, 45, 45, "%"],
      135201: [42, 42, 40, 39, 45, 45, "%"],
      135401: [42, 42, 40, 40, 45, 45, "%"],
      143601: [43, 42, 40, 40, 45, 45, "%"],
      144601: [43, 43, 40, 40, 45, 45, "%"],
      146201: [43, 43, 41, 40, 45, 45, "%"],
      149401: [43, 43, 41, 40, 46, 46, "%"],
      151401: [43, 43, 41, 41, 46, 46, "%"],
      158801: [44, 43, 42, 41, 46, 46, "%"],
      159801: [44, 44, 42, 41, 46, 46, "%"],
      171801: [44, 44, 42, 42, 46, 46, "%"],
      172401: [44, 44, 42, 42, 47, 47, "%"],
      174001: [44, 44, 43, 42, 47, 47, "%"],
      177401: [45, 44, 43, 42, 47, 47, "%"],
      178601: [45, 45, 43, 42, 47, 47, "%"],
      192201: [45, 45, 44, 42, 47, 47, "%"],
      198601: [45, 45, 44, 43, 47, 47, "%"],
      201001: [46, 45, 44, 43, 47, 47, "%"],
      202401: [46, 46, 44, 43, 47, 47, "%"],
      203801: [46, 46, 44, 43, 48, 48, "%"],
      215001: [46, 46, 45, 43, 48, 48, "%"],
      232001: [47, 46, 45, 43, 48, 48, "%"],
      233601: [47, 47, 45, 43, 48, 48, "%"],
      235201: [47, 47, 45, 44, 48, 48, "%"],
      243601: [47, 47, 46, 44, 48, 48, "%"],
      249201: [47, 47, 46, 44, 49, 49, "%"],
      274201: [48, 47, 46, 44, 49, 49, "%"],
      276201: [48, 48, 46, 44, 49, 49, "%"],
      281001: [48, 48, 47, 44, 49, 49, "%"],
      288401: [48, 48, 47, 45, 49, 49, "%"],
      320401: [48, 48, 47, 45, 50, 50, "%"],
      332201: [48, 48, 48, 45, 50, 50, "%"],
      335201: [49, 48, 48, 45, 50, 50, "%"],
      337601: [49, 49, 48, 45, 50, 50, "%"],
      372601: [49, 49, 48, 46, 50, 50, "%"],
      406001: [49, 49, 49, 46, 50, 50, "%"],
      431001: [50, 49, 49, 46, 50, 50, "%"],
      434001: [50, 50, 49, 46, 50, 50, "%"],
      448601: [50, 50, 49, 46, 51, 51, "%"],
      522201: [50, 50, 50, 46, 51, 51, "%"],
      526401: [50, 50, 50, 47, 51, 51, "%"],
      603401: [51, 50, 50, 47, 51, 51, "%"],
      607601: [51, 51, 50, 47, 51, 51, "%"],
      731001: [51, 51, 51, 47, 51, 51, "%"],
      747601: [51, 51, 51, 47, 52, 52, "%"],
      896001: [51, 51, 51, 48, 52, 52, "%"],
      1005601: [52, 51, 51, 48, 52, 52, "%"],
      1012801: [52, 52, 51, 48, 52, 52, "%"],
      1218401: [52, 52, 52, 48, 52, 52, "%"]
    },
    34: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 8],
      2101: [152, 0, 150, 2, 152, 43],
      2201: [163, 0, 158, 4, 163, 78],
      2301: [173, 0, 166, 7, 173, 113],
      2401: [184, 0, 175, 9, 184, 148],
      2501: [194, 0, 183, 11, 194, 183],
      2601: [205, 0, 191, 13, 218, 218],
      2701: [215, 0, 200, 15, 253, 253],
      2801: [217, 0, 200, 17, 288, 288],
      2901: [228, 0, 208, 19, 323, 323],
      3001: [238, 0, 216, 22, 358, 358],
      3101: [249, 0, 225, 24, 393, 393],
      3201: [259, 0, 233, 26, 428, 428],
      3301: [270, 0, 241, 28, 463, 463],
      3401: [272, 0, 241, 30, 498, 498],
      3501: [282, 0, 250, 32, 533, 533],
      3601: [293, 0, 258, 35, 568, 568],
      3701: [303, 0, 266, 37, 603, 603],
      3801: [314, 0, 275, 39, 638, 638],
      3901: [324, 0, 283, 41, 673, 673],
      4001: [327, 0, 283, 43, 708, 708],
      4101: [337, 0, 291, 45, 743, 743],
      4201: [348, 0, 300, 48, 778, 778],
      4301: [358, 0, 308, 50, 813, 813],
      4401: [369, 0, 316, 61, 848, 848],
      4501: [379, 0, 325, 85, 883, 883],
      4601: [381, 0, 325, 108, 918, 918],
      4701: [392, 0, 333, 132, 953, 953],
      4801: [402, 0, 341, 156, 985, 985],
      4901: [412, 0, 350, 179, 1012, 1012],
      5001: [422, 0, 358, 203, 1041, 1041],
      5101: [432, 0, 366, 226, 1067, 1067],
      5201: [442, 0, 375, 249, 1096, 1096],
      5301: [444, 0, 375, 273, 1125, 1125],
      5401: [454, 14, 384, 296, 1152, 1152],
      5501: [464, 40, 394, 319, 1180, 1180],
      5601: [474, 67, 404, 342, 1206, 1206],
      5701: [484, 93, 414, 365, 1235, 1235],
      5801: [494, 116, 423, 388, 1263, 1263],
      5901: [496, 142, 425, 410, 1289, 1289],
      6001: [506, 169, 435, 433, 1317, 1317],
      6101: [516, 195, 445, 456, 1343, 1343],
      6201: [526, 221, 455, 479, 1372, 1372],
      6301: [536, 247, 465, 502, 1400, 1400],
      6401: [546, 274, 475, 524, 1426, 1426],
      6501: [548, 300, 476, 547, 1454, 1454],
      6601: [558, 326, 486, 570, 1480, 1480],
      6701: [568, 352, 496, 593, 1509, 1509],
      6801: [578, 379, 506, 615, 1537, 1537],
      6901: [599, 405, 516, 638, 1563, 1563],
      7001: [620, 428, 526, 661, 1591, 1591],
      7101: [642, 454, 528, 684, 1617, 1617],
      7201: [663, 481, 538, 707, 1646, 1646],
      7301: [684, 507, 547, 729, 1674, 1674],
      7401: [705, 533, 557, 752, 1700, 1700],
      7501: [727, 559, 567, 775, 1728, 1728],
      7601: [748, 586, 577, 798, 1754, 1754],
      7701: [769, 612, 587, 820, 1783, 1783],
      7801: [790, 638, 589, 843, 1811, 1811],
      7901: [812, 664, 599, 866, 1837, 1837],
      8001: [833, 691, 609, 889, 1865, 1865],
      8101: [854, 717, 619, 912, 1891, 1891],
      8201: [875, 740, 629, 934, 1920, 1920],
      8301: [897, 767, 639, 957, 1948, 1948],
      8401: [918, 793, 640, 980, 1974, 1974],
      8501: [939, 819, 650, 1003, 2002, 2002],
      8601: [960, 845, 660, 1026, 2028, 2028],
      8701: [981, 872, 670, 1048, 2057, 2057],
      8801: [1003, 898, 680, 1071, 2085, 2085],
      8901: [1024, 924, 690, 1094, 2111, 2111],
      9001: [1045, 950, 692, 1117, 2139, 2139],
      9101: [1067, 977, 701, 1140, 2165, 2165],
      9201: [1088, 1003, 711, 1162, 2194, 2194],
      9301: [1109, 1029, 721, 1185, 2222, 2222],
      9401: [1130, 1052, 731, 1208, 2248, 2248],
      9501: [1152, 1079, 741, 1231, 2276, 2276],
      9601: [1173, 1096, 742, 1253, 2302, 2302],
      9701: [1194, 1117, 752, 1276, 2331, 2331],
      9801: [1215, 1137, 761, 1299, 2359, 2359],
      9901: [1236, 1160, 771, 1322, 2385, 2385],
      10001: [1258, 1180, 781, 1345, 2413, 2413],
      10101: [1279, 1200, 790, 1367, 2439, 2439],
      10201: [1300, 1220, 800, 1390, 2468, 2468],
      10301: [1321, 1243, 801, 1413, 2496, 2496],
      10401: [1343, 1263, 811, 1436, 2522, 2522],
      10501: [1364, 1283, 821, 1459, 2550, 2550],
      10601: [1385, 1303, 830, 1481, 2576, 2576],
      10701: [1406, 1326, 840, 1504, 2605, 2605],
      10801: [1428, 1346, 850, 1527, 2633, 2633],
      10901: [1449, 1366, 851, 1550, 2659, 2659],
      11001: [1470, 1386, 860, 1572, 2687, 2687],
      11101: [1491, 1409, 870, 1595, 2713, 2713],
      11201: [1513, 1429, 880, 1618, 2742, 2742],
      11301: [1534, 1449, 889, 1641, 2770, 2770],
      11401: [1555, 1469, 899, 1664, 2796, 2796],
      11501: [1576, 1492, 900, 1686, 2824, 2824],
      11601: [1598, 1512, 910, 1709, 2850, 2850],
      11701: [1619, 1531, 920, 1732, 2879, 2879],
      11801: [1640, 1551, 929, 1755, 2907, 2907],
      11901: [1661, 1574, 939, 1777, 2933, 2933],
      12001: [1683, 1594, 949, 1800, 2961, 2961],
      12101: [1704, 1614, 950, 1823, 2987, 2987],
      12201: [1725, 1637, 960, 1846, 3016, 3016],
      12301: [1746, 1657, 969, 1869, 3044, 3044],
      12401: [1768, 1677, 979, 1891, 3070, 3070],
      12501: [1789, 1697, 988, 1914, 3098, 3098],
      12601: [1810, 1720, 998, 1937, 3124, 3124],
      12701: [1831, 1740, 1008, 1960, 3153, 3153],
      12801: [1853, 1760, 1009, 1983, 3181, 3181],
      12901: [1874, 1780, 1019, 2005, 3207, 3207],
      13001: [1895, 1803, 1028, 2028, 3236, 3236],
      13101: [1916, 1823, 1038, 2051, 3261, 3261],
      13201: [1938, 1843, 1047, 2074, 3290, 3290],
      13301: [1959, 1863, 1057, 2097, 3321, 3321],
      13401: [1980, 1885, 1059, 2120, 3355, 3355],
      13501: [2002, 1905, 1068, 2143, 3390, 3390],
      13601: [2024, 1925, 1078, 2166, 3424, 3424],
      13701: [2045, 1945, 1087, 2189, 3458, 3458],
      13801: [2067, 1968, 1097, 2212, 3492, 3492],
      13901: [2088, 1988, 1107, 2235, 3527, 3527],
      14001: [2110, 2008, 1108, 2258, 3561, 3561],
      14101: [2131, 2028, 1118, 2281, 3595, 3595],
      14201: [2152, 2051, 1127, 2303, 3628, 3628],
      14301: [2172, 2071, 1137, 2325, 3661, 3661],
      14401: [2193, 2091, 1146, 2347, 3695, 3695],
      14501: [2213, 2114, 1156, 2369, 3728, 3728],
      14601: [2234, 2134, 1158, 2391, 3761, 3761],
      14701: [2254, 2154, 1167, 2413, 3794, 3794],
      14801: [2275, 2174, 1177, 2436, 3828, 3828],
      14901: [2295, 2197, 1186, 2458, 3861, 3861],
      15001: [2316, 2216, 1196, 2480, 3894, 3894],
      15101: [2336, 2237, 1206, 2502, 3927, 3927],
      15201: [2357, 2256, 1215, 2524, 3961, 3961],
      15301: [2377, 2279, 1217, 2546, 3999, 3999],
      15401: [2398, 2299, 1226, 2568, 4035, 4035],
      15501: [2419, 2319, 1236, 2590, 4071, 4071],
      15601: [2439, 2339, 1245, 2612, 4107, 4107],
      15701: [2460, 2362, 1255, 2634, 4144, 4144],
      15801: [2481, 2382, 1265, 2659, 4182, 4182],
      15901: [2508, 2411, 1267, 2688, 4218, 4218],
      16001: [2535, 2436, 1277, 2717, 4254, 4254],
      16101: [2561, 2465, 1287, 2746, 4290, 4290],
      16201: [2588, 2490, 1297, 2775, 4326, 4326],
      16301: [2615, 2519, 1307, 2804, 4365, 4365],
      16401: [2642, 2548, 1317, 2833, 4401, 4401],
      16501: [2668, 2573, 1319, 2862, 4437, 4437],
      16601: [2695, 2602, 1329, 2891, 4473, 4473],
      16701: [2722, 2627, 1339, 2921, 4509, 4509],
      16801: [2749, 2656, 1349, 2950, 4548, 4548],
      16901: [2775, 2682, 1359, 2979, 4584, 4584],
      17001: [2802, 2710, 1369, 3008, 4620, 4620],
      17101: [2829, 2736, 1371, 3037, 4656, 4656],
      17201: [2856, 2764, 1381, 3066, 4692, 4692],
      17301: [2883, 2790, 1391, 3095, 4731, 4731],
      17401: [2909, 2819, 1401, 3124, 4767, 4767],
      17501: [2936, 2847, 1411, 3153, 4803, 4803],
      17601: [2963, 2873, 1421, 3182, 4839, 4839],
      17701: [2990, 2901, 1431, 3211, 4875, 4875],
      17801: [3016, 2927, 1433, 3240, 4914, 4914],
      17901: [3043, 2956, 1443, 3270, 4950, 4950],
      18001: [3070, 2981, 1453, 3299, 4986, 4986],
      18101: [3097, 3010, 1463, 3328, 5022, 5022],
      18201: [3123, 3036, 1473, 3357, 5058, 5058],
      18301: [3150, 3064, 1483, 3386, 5097, 5097],
      18401: [3177, 3093, 1485, 3415, 5133, 5133],
      18501: [3204, 3118, 1495, 3444, 5169, 5169],
      18601: [3230, 3147, 1505, 3473, 5205, 5205],
      18701: [3257, 3173, 1515, 3502, 5241, 5241],
      18801: [3284, 3201, 1525, 3531, 5280, 5280],
      18901: [3311, 3227, 1535, 3560, 5316, 5316],
      19001: [3337, 3255, 1537, 3590, 5352, 5352],
      19101: [3364, 3281, 1547, 3619, 5388, 5388],
      19201: [3391, 3310, 1557, 3648, 5424, 5424],
      19301: [3418, 3338, 1567, 3677, 5463, 5463],
      19401: [3444, 3364, 1577, 3706, 5498, 5498],
      19501: [3471, 3392, 1587, 3735, 5534, 5534],
      19601: [3498, 3418, 1589, 3764, 5570, 5570],
      19701: [3525, 3447, 1599, 3793, 5607, 5607],
      19801: [3551, 3472, 1609, 3822, 5645, 5645],
      19901: [3578, 3501, 1619, 3851, 5681, 5681],
      20001: [3632, 3555, 1639, 3910, 5753, 5753],
      20201: [3685, 3609, 1650, 3968, 5828, 5828],
      20401: [3739, 3661, 1669, 4026, 5900, 5900],
      20601: [3792, 3714, 1687, 4084, 5972, 5972],
      20801: [3846, 3767, 1697, 4142, 6047, 6047],
      21001: [3899, 3819, 1716, 4200, 6119, 6119],
      21201: [3953, 3875, 1734, 4259, 6194, 6194],
      21401: [4006, 3927, 1744, 4317, 6266, 6266],
      21601: [4060, 3980, 1763, 4375, 6338, 6338],
      21801: [4113, 4033, 1781, 4433, 6413, 6413],
      22001: [4167, 4085, 1792, 4491, 6485, 6485],
      22201: [4220, 4138, 1810, 4549, 6560, 6560],
      22401: [4274, 4191, 1829, 4608, 6632, 6632],
      22601: [4327, 4243, 1847, 4666, 6704, 6704],
      22801: [4381, 4296, 1857, 4724, 6779, 6779],
      23001: [4436, 4349, 1876, 4784, 6852, 6852],
      23201: [4491, 4404, 1894, 4844, 6929, 6929],
      23401: [4546, 4457, 1905, 4903, 7002, 7002],
      23601: [4601, 4509, 1923, 4963, 7076, 7076],
      23801: [4657, 4562, 1942, 5023, 7153, 7153],
      24001: [4712, 4615, 1952, 5083, 7226, 7226],
      24201: [4767, 4667, 1970, 5143, 7303, 7303],
      24401: [4822, 4720, 1989, 5203, 7376, 7376],
      24601: [4877, 4775, 1999, 5262, 7450, 7450],
      24801: [4932, 4831, 2018, 5322, 7527, 7527],
      25001: [4988, 4883, 2036, 5382, 7600, 7600],
      25201: [5043, 4939, 2046, 5442, 7677, 7677],
      25401: [5098, 4991, 2065, 5502, 7750, 7750],
      25601: [5153, 5047, 2084, 5561, 7824, 7824],
      25801: [5208, 5099, 2094, 5621, 7901, 7901],
      26001: [5263, 5155, 2112, 5681, 7974, 7974],
      26201: [5319, 5208, 2131, 5741, 8051, 8051],
      26401: [5374, 5263, 2179, 5801, 8124, 8124],
      26601: [5429, 5316, 2232, 5861, 8198, 8198],
      26801: [5484, 5371, 2287, 5920, 8275, 8275],
      27001: [5539, 5424, 2340, 5980, 8348, 8348],
      27201: [5594, 5479, 2396, 6040, 8425, 8425],
      27401: [5650, 5532, 2448, 6100, 8498, 8498],
      27601: [5705, 5587, 2504, 6160, 8572, 8572],
      27801: [5760, 5643, 2559, 6220, 8649, 8649],
      28001: [5815, 5695, 2612, 6279, 8722, 8722],
      28201: [5870, 5751, 2667, 6339, 8799, 8799],
      28401: [5925, 5803, 2720, 6399, 8872, 8872],
      28601: [5980, 5859, 2775, 6459, 8946, 8946],
      28801: [6036, 5911, 2828, 6519, 9023, 9023],
      29001: [6091, 5967, 2883, 6578, 9096, 9096],
      29201: [6146, 6019, 2936, 6638, 9173, 9173],
      29401: [6201, 6075, 2991, 6698, 9246, 9246],
      29601: [6256, 6127, 3044, 6758, 9320, 9320],
      29801: [6311, 6183, 3099, 6818, 9397, 9397],
      30001: [6367, 6235, 3152, 6878, 9470, 9470],
      30201: [6422, 6291, 3207, 6937, 9547, 9547],
      30401: [6477, 6346, 3263, 6997, 9620, 9620],
      30601: [6532, 6399, 3315, 7057, 9694, 9694],
      30801: [6587, 6454, 3371, 7117, 9771, 9771],
      31001: [6642, 6507, 3424, 7177, 9844, 9844],
      31201: [6698, 6562, 3479, 7237, 9921, 9921],
      31401: [6753, 6615, 3532, 7296, 9994, 9994],
      31601: [6808, 6671, 3587, 7356, 10068, 10068],
      31801: [6863, 6723, 3640, 7416, 10145, 10145],
      32001: [6918, 6779, 3695, 7476, 10218, 10218],
      32201: [6973, 6832, 3749, 7536, 10295, 10295],
      32401: [7029, 6889, 3806, 7596, 10368, 10368],
      32601: [7084, 6943, 3859, 7655, 10442, 10442],
      32801: [7139, 7e3, 3916, 7715, 10519, 10519],
      33001: [7194, 7053, 3970, 7775, 10592, 10592],
      33201: [7249, 7110, 4027, 7835, 10669, 10669],
      33401: [7304, 7167, 4083, 7895, 10742, 10742],
      33601: [7360, 7221, 4137, 7954, 10816, 10816],
      33801: [7415, 7277, 4194, 8014, 10893, 10893],
      34001: [7470, 7331, 4248, 8074, 10966, 10966],
      34201: [7525, 7388, 4304, 8134, 11043, 11043],
      34401: [7580, 7442, 4358, 8194, 11116, 11116],
      34601: [7635, 7498, 4415, 8254, 11190, 11190],
      34801: [7691, 7552, 4469, 8313, 11267, 11267],
      35001: [7746, 7609, 4525, 8373, 11340, 11340],
      35201: [7801, 7663, 4579, 8433, 11417, 11417],
      35401: [7856, 7719, 4636, 8493, 11490, 11490],
      35601: [7911, 7773, 4690, 8553, 11564, 11564],
      35801: [7966, 7830, 4746, 8613, 11641, 11641],
      36001: [8021, 7886, 4803, 8672, 11714, 11714],
      36201: [8077, 7940, 4857, 8732, 11791, 11791],
      36401: [8132, 7997, 4913, 8792, 11864, 11864],
      36601: [8187, 8051, 4967, 8852, 11938, 11938],
      36801: [8242, 8107, 5024, 8912, 12015, 12015],
      37001: [8297, 8161, 5078, 8971, 12088, 12088],
      37201: [8352, 8218, 5134, 9031, 12165, 12165],
      37401: [8408, 8272, 5188, 9091, 12238, 12238],
      37601: [8463, 8328, 5245, 9151, 12312, 12312],
      37801: [8518, 8382, 5299, 9211, 12389, 12389],
      38001: [8573, 8439, 5355, 9271, 12462, 12462],
      38201: [8628, 8493, 5409, 9330, 12539, 12539],
      38401: [8683, 8549, 5466, 9390, 12612, 12612],
      38601: [8738, 8603, 5520, 9450, 12680, 12680],
      38801: [8793, 8657, 5574, 9509, 12748, 12748],
      39001: [8848, 8711, 5627, 9569, 12816, 12816],
      39201: [8903, 8768, 5684, 9629, 12884, 12884],
      39401: [8959, 8821, 5738, 9688, 12952, 12952],
      39601: [9027, 8889, 5806, 9748, 13020, 13020],
      39801: [9095, 8957, 5874, 9807, 13088, 13088],
      40001: [9163, 9025, 5942, 9867, 13156, 13156],
      40201: [9231, 9093, 6010, 9927, 13224, 13224],
      40401: [9299, 9161, 6078, 9986, 13292, 13292],
      40601: [9367, 9229, 6146, 10046, 13360, 13360],
      40801: [9435, 9297, 6214, 10105, 13428, 13428],
      41001: [9503, 9365, 6282, 10165, 13496, 13496],
      41201: [9571, 9433, 6350, 10225, 13564, 13564],
      41401: [9639, 9501, 6418, 10284, 13632, 13632],
      41601: [9707, 9569, 6486, 10344, 13700, 13700],
      41801: [9775, 9637, 6554, 10403, 13768, 13768],
      42001: [9843, 9705, 6622, 10463, 13836, 13836],
      42201: [9911, 9773, 6690, 10523, 13904, 13904],
      42401: [9979, 9841, 6758, 10582, 13972, 13972],
      42601: [10047, 9909, 6826, 10642, 14040, 14040],
      42801: [10115, 9977, 6894, 10701, 14108, 14108],
      43001: [10183, 10045, 6962, 10761, 14176, 14176],
      43201: [10251, 10113, 7030, 10820, 14244, 14244],
      43401: [10319, 10181, 7098, 10880, 14312, 14312],
      43601: [10387, 10249, 7166, 10940, 14380, 14380],
      43801: [10455, 10317, 7234, 10999, 14448, 14448],
      44001: [10523, 10385, 7302, 11059, 14516, 14516],
      44201: [10591, 10453, 7370, 11118, 14584, 14584],
      44401: [10659, 10521, 7438, 11178, 14652, 14652],
      44601: [10727, 10589, 7506, 11238, 14720, 14720],
      44801: [10795, 10657, 7574, 11297, 14788, 14788],
      45001: [10863, 10725, 7642, 11357, 14856, 14856],
      45201: [10931, 10793, 7710, 11416, 14924, 14924],
      45401: [10999, 10861, 7778, 11476, 14992, 14992],
      45601: [11067, 10929, 7846, 11536, 15060, 15060],
      45801: [11135, 10997, 7914, 11595, 15128, 15128],
      46001: [11203, 11065, 7982, 11655, 15196, 15196],
      46201: [11271, 11133, 8050, 11714, 15264, 15264],
      46401: [11339, 11201, 8118, 11774, 15332, 15332],
      46601: [11407, 11269, 8186, 11834, 15400, 15400],
      46801: [11475, 11337, 8254, 11893, 15468, 15468],
      47001: [11543, 11405, 8322, 11953, 15536, 15536],
      47201: [11611, 11473, 8390, 12012, 15604, 15604],
      47401: [11679, 11541, 8458, 12072, 15672, 15672],
      47601: [11747, 11609, 8526, 12132, 15740, 15740],
      47801: [11815, 11677, 8594, 12191, 15808, 15808],
      48001: [11883, 11745, 8662, 12251, 15876, 15876],
      48201: [11951, 11813, 8730, 12310, 15944, 15944],
      48401: [12019, 11881, 8798, 12370, 16012, 16012],
      48601: [12087, 11949, 8866, 12429, 16080, 16080],
      48801: [12155, 12017, 8934, 12489, 16148, 16148],
      49001: [12223, 12085, 9002, 12549, 16216, 16216],
      49201: [12291, 12153, 9070, 12608, 16284, 16284],
      49401: [12359, 12221, 9138, 12668, 16352, 16352],
      49601: [12427, 12289, 9206, 12727, 16420, 16420],
      49801: [12495, 12357, 9274, 12787, 16488, 16488],
      50001: [12563, 12425, 9342, 12847, 16556, 16556],
      50201: [12631, 12493, 9410, 12906, 16624, 16624],
      50401: [12699, 12561, 9478, 12966, 16692, 16692],
      50601: [12767, 12629, 9546, 13025, 16760, 16760],
      50801: [12835, 12697, 9614, 13085, 16828, 16828],
      51001: [12903, 12765, 9682, 13145, 16896, 16896],
      51201: [12971, 12833, 9750, 13204, 16964, 16964],
      51401: [13039, 12901, 9818, 13264, 17032, 17032],
      51601: [13107, 12969, 9886, 13323, 17100, 17100],
      51801: [13175, 13037, 9954, 13383, 17168, 17168],
      52001: [13243, 13105, 10022, 13443, 17236, 17236],
      52201: [13311, 13173, 10090, 13502, 17304, 17304],
      52401: [13379, 13241, 10158, 13562, 17372, 17372],
      52601: [13447, 13309, 10226, 13621, 17440, 17440],
      52801: [13515, 13377, 10294, 13681, 17508, 17508],
      53001: [13583, 13445, 10362, 13741, 17576, 17576],
      53201: [13651, 13513, 10430, 13800, 17644, 17644],
      53401: [13720, 13581, 10498, 13861, 17714, 17714],
      53601: [13828, 13689, 10606, 13961, 17822, 17822],
      53801: [13936, 13800, 10716, 14061, 17930, 17930],
      54001: [14044, 13910, 10827, 14160, 18038, 18038],
      54201: [14152, 14021, 10937, 14260, 18146, 18146],
      54401: [14260, 14131, 11048, 14359, 18254, 18254],
      54601: [14368, 14242, 11158, 14459, 18362, 18362],
      54801: [14476, 14352, 11269, 14559, 18470, 18470],
      55001: [14584, 14460, 11376, 14658, 18578, 18578],
      55201: [14693, 14570, 11487, 14758, 18686, 18686],
      55401: [14800, 14681, 11597, 14857, 18794, 18794],
      55601: [14908, 14791, 11708, 14957, 18902, 18902],
      55801: [15016, 14902, 11818, 15057, 19010, 19010],
      56001: [15124, 15012, 11929, 15156, 19118, 19118],
      56201: [15232, 15123, 12039, 15256, 19226, 19226],
      56401: [15340, 15233, 12150, 15355, 19334, 19334],
      56601: [15448, 15341, 12257, 15455, 19442, 19442],
      56801: [15556, 15451, 12368, 15554, 19550, 19550],
      57001: [15664, 15562, 12478, 15654, 19658, 19658],
      57201: [15772, 15672, 12589, 15754, 19766, 19766],
      57401: [15880, 15783, 12699, 15853, 19874, 19874],
      57601: [15988, 15893, 12810, 15953, 19982, 19982],
      57801: [16096, 16004, 12920, 16052, 20090, 20090],
      58001: [16204, 16114, 13031, 16152, 20198, 20198],
      58201: [16312, 16225, 13141, 16252, 20306, 20306],
      58401: [16420, 16333, 13249, 16351, 20414, 20414],
      58601: [16528, 16443, 13360, 16451, 20522, 20522],
      58801: [16636, 16554, 13470, 16550, 20630, 20630],
      59001: [16744, 16664, 13581, 16650, 20738, 20738],
      59201: [16852, 16775, 13691, 16750, 20846, 20846],
      59401: [16960, 16885, 13802, 16849, 20954, 20954],
      59601: [17068, 16996, 13912, 16949, 21062, 21062],
      59801: [17176, 17106, 14023, 17048, 21170, 21170],
      60001: [17284, 17214, 14130, 17148, 21278, 21278],
      60201: [17393, 17324, 14241, 17248, 21386, 21386],
      60401: [17500, 17435, 14351, 17347, 21494, 21494],
      60601: [17608, 17545, 14462, 17447, 21602, 21602],
      60801: [17716, 17656, 14572, 17546, 21710, 21710],
      61001: [17824, 17764, 14680, 17646, 21818, 21818],
      61201: [17932, 17872, 14788, 17746, 21926, 21926],
      61401: [18040, 17980, 14896, 17845, 22034, 22034],
      61601: [18148, 18088, 15004, 17945, 22142, 22142],
      61801: [18256, 18196, 15112, 18044, 22250, 22250],
      62001: [18364, 18304, 15220, 18144, 22358, 22358],
      62201: [18472, 18412, 15328, 18243, 22466, 22466],
      62401: [18580, 18520, 15436, 18343, 22574, 22574],
      62601: [18688, 18628, 15544, 18443, 22682, 22682],
      62801: [18796, 18736, 15652, 18542, 22790, 22790],
      63001: [18904, 18844, 15760, 18642, 22898, 22898],
      63201: [19012, 18952, 15868, 18741, 23006, 23006],
      63401: [19120, 19060, 15976, 18841, 23114, 23114],
      63601: [19228, 19168, 16084, 18941, 23222, 23222],
      63801: [19336, 19276, 16192, 19040, 23330, 23330],
      64001: [19444, 19384, 16300, 19140, 23438, 23438],
      64201: [19552, 19492, 16408, 19239, 23546, 23546],
      64401: [19660, 19600, 16516, 19339, 23654, 23654],
      64601: [19768, 19708, 16624, 19439, 23762, 23762],
      64801: [19876, 19816, 16732, 19538, 23870, 23870],
      65001: [19984, 19924, 16840, 19638, 23978, 23978],
      65201: [20093, 20032, 16948, 19737, 24086, 24086],
      65401: [20200, 20140, 17056, 19837, 24194, 24194],
      65601: [20308, 20248, 17164, 19937, 24302, 24302],
      65801: [20416, 20356, 17272, 20036, 24410, 24410],
      66001: [20524, 20464, 17380, 20136, 24518, 24518],
      66201: [20632, 20572, 17488, 20235, 24626, 24626],
      66401: [20740, 20680, 17596, 20335, 24734, 24734],
      66601: [20848, 20788, 17704, 20435, 24842, 24842],
      66801: [20956, 20896, 17812, 20534, 24950, 24950],
      67001: [21064, 21004, 17920, 20634, 25058, 25058],
      67201: [21172, 21112, 18028, 20733, 25166, 25166],
      67401: [21280, 21220, 18136, 20833, 25274, 25274],
      67601: [21388, 21328, 18244, 20932, 25382, 25382],
      67801: [21496, 21436, 18352, 21032, 25490, 25490],
      68001: [21604, 21544, 18460, 21132, 25598, 25598],
      68201: [21712, 21652, 18568, 21231, 25706, 25706],
      68401: [21820, 21760, 18676, 21331, 25814, 25814],
      68601: [21928, 21868, 18784, 21430, 25922, 25922],
      68801: [22036, 21976, 18892, 21530, 26030, 26030],
      69001: [22144, 22084, 19e3, 21630, 26138, 26138],
      69201: [22252, 22192, 19108, 21729, 26246, 26246],
      69401: [22360, 22300, 19216, 21829, 26354, 26354],
      69601: [22468, 22408, 19324, 21928, 26462, 26462],
      69801: [22576, 22516, 19432, 22028, 26570, 26570],
      70001: [22684, 22624, 19540, 22128, 26678, 26678],
      70201: [22793, 22732, 19648, 22227, 26786, 26786],
      70401: [22900, 22840, 19756, 22327, 26894, 26894],
      70601: [23008, 22948, 19864, 22426, 27002, 27002],
      70801: [23116, 23056, 19972, 22526, 27110, 27110],
      71001: [23224, 23164, 20080, 22626, 27218, 27218],
      71201: [23332, 23272, 20188, 22725, 27326, 27326],
      71401: [23440, 23380, 20296, 22825, 27434, 27434],
      71601: [23548, 23488, 20404, 22924, 27542, 27542],
      71801: [23656, 23596, 20512, 23024, 27650, 27650],
      72001: [23764, 23704, 20620, 23124, 27758, 27758],
      72201: [23872, 23812, 20728, 23223, 27866, 27866],
      72401: [23980, 23920, 20836, 23323, 27974, 27974],
      72601: [24088, 24028, 20944, 23422, 28082, 28082],
      72801: [24196, 24136, 21052, 23522, 28190, 28190],
      73001: [24304, 24244, 21160, 23622, 28298, 28298],
      73201: [24412, 24352, 21268, 23721, 28406, 28406],
      73401: [24520, 24460, 21376, 23821, 28514, 28514],
      73601: [24628, 24568, 21484, 23920, 28622, 28622],
      73801: [24736, 24676, 21592, 24020, 28730, 28730],
      74001: [24844, 24784, 21700, 24119, 28838, 28838],
      74201: [24952, 24892, 21808, 24219, 28946, 28946],
      74401: [25060, 25e3, 21916, 24319, 29054, 29054],
      74601: [25168, 25108, 22024, 24418, 29162, 29162],
      74801: [25276, 25216, 22132, 24518, 29270, 29270],
      75001: [25384, 25324, 22240, 24617, 29378, 29378],
      75201: [25493, 25432, 22348, 24717, 29486, 29486],
      75401: [25600, 25540, 22456, 24817, 29594, 29594],
      75601: [25708, 25648, 22564, 24916, 29702, 29702],
      75801: [25816, 25756, 22672, 25016, 29810, 29810],
      76001: [25924, 25864, 22780, 25115, 29918, 29918],
      76201: [26032, 25972, 22888, 25215, 30026, 30026],
      76401: [26140, 26080, 22996, 25315, 30134, 30134],
      76601: [26248, 26188, 23104, 25414, 30242, 30242],
      76801: [26356, 26296, 23212, 25514, 30350, 30350],
      77001: [26464, 26404, 23320, 25613, 30458, 30458],
      77201: [26572, 26512, 23428, 25713, 30566, 30566],
      77401: [26680, 26620, 23536, 25813, 30674, 30674],
      77601: [26788, 26728, 23644, 25912, 30782, 30782],
      77801: [26896, 26836, 23752, 26012, 30890, 30890],
      78001: [27004, 26944, 23860, 26111, 30998, 30998],
      78201: [27112, 27052, 23968, 26211, 31106, 31106],
      78401: [27220, 27160, 24076, 26311, 31214, 31214],
      78601: [27328, 27268, 24184, 26410, 31322, 31322],
      78801: [27436, 27376, 24292, 26510, 31430, 31430],
      79001: [27544, 27484, 24400, 26609, 31538, 31538],
      79201: [27652, 27592, 24508, 26709, 31646, 31646],
      79401: [27760, 27700, 24616, 26808, 31754, 31754],
      79601: [27868, 27808, 24724, 26908, 31862, 31862],
      79801: [27976, 27916, 24832, 27008, 31970, 31970],
      80001: [35, 35, 31, 34, 40, 40, "%"],
      81601: [35, 35, 32, 34, 40, 40, "%"],
      82201: [36, 35, 32, 34, 40, 40, "%"],
      82601: [36, 36, 32, 34, 40, 40, "%"],
      83001: [36, 36, 32, 34, 41, 41, "%"],
      83801: [36, 36, 32, 35, 41, 41, "%"],
      85401: [36, 36, 33, 35, 41, 41, "%"],
      86801: [37, 36, 33, 35, 41, 41, "%"],
      87201: [37, 37, 33, 35, 41, 41, "%"],
      89401: [37, 37, 34, 35, 41, 41, "%"],
      89601: [37, 37, 34, 36, 41, 41, "%"],
      89801: [37, 37, 34, 36, 42, 42, "%"],
      92201: [38, 37, 34, 36, 42, 42, "%"],
      92601: [38, 38, 34, 36, 42, 42, "%"],
      94001: [38, 38, 35, 36, 42, 42, "%"],
      96401: [38, 38, 35, 37, 42, 42, "%"],
      97601: [38, 38, 35, 37, 43, 43, "%"],
      98201: [39, 38, 35, 37, 43, 43, "%"],
      98601: [39, 39, 35, 37, 43, 43, "%"],
      99201: [39, 39, 36, 37, 43, 43, "%"],
      104201: [39, 39, 36, 38, 43, 43, "%"],
      104801: [40, 39, 37, 38, 43, 43, "%"],
      105401: [40, 40, 37, 38, 43, 43, "%"],
      106801: [40, 40, 37, 38, 44, 44, "%"],
      111201: [40, 40, 38, 38, 44, 44, "%"],
      112601: [41, 40, 38, 38, 44, 44, "%"],
      113201: [41, 41, 38, 38, 44, 44, "%"],
      113401: [41, 41, 38, 39, 44, 44, "%"],
      118201: [41, 41, 38, 39, 45, 45, "%"],
      118401: [41, 41, 39, 39, 45, 45, "%"],
      121601: [42, 41, 39, 39, 45, 45, "%"],
      122201: [42, 42, 39, 39, 45, 45, "%"],
      124401: [42, 42, 39, 40, 45, 45, "%"],
      126601: [42, 42, 40, 40, 45, 45, "%"],
      132001: [42, 42, 40, 40, 46, 46, "%"],
      132201: [43, 42, 40, 40, 46, 46, "%"],
      132801: [43, 43, 40, 40, 46, 46, "%"],
      136001: [43, 43, 41, 40, 46, 46, "%"],
      137801: [43, 43, 41, 41, 46, 46, "%"],
      144801: [44, 43, 41, 41, 46, 46, "%"],
      145401: [44, 44, 41, 41, 46, 46, "%"],
      146801: [44, 44, 42, 41, 46, 46, "%"],
      149601: [44, 44, 42, 41, 47, 47, "%"],
      154601: [44, 44, 42, 42, 47, 47, "%"],
      159601: [44, 44, 43, 42, 47, 47, "%"],
      160201: [45, 44, 43, 42, 47, 47, "%"],
      160801: [45, 45, 43, 42, 47, 47, "%"],
      172601: [45, 45, 43, 42, 48, 48, "%"],
      174801: [45, 45, 44, 42, 48, 48, "%"],
      175801: [45, 45, 44, 43, 48, 48, "%"],
      179001: [46, 45, 44, 43, 48, 48, "%"],
      179801: [46, 46, 44, 43, 48, 48, "%"],
      193201: [46, 46, 45, 43, 48, 48, "%"],
      202801: [47, 46, 45, 43, 48, 48, "%"],
      203601: [47, 47, 45, 44, 48, 48, "%"],
      204001: [47, 47, 45, 44, 49, 49, "%"],
      216001: [47, 47, 46, 44, 49, 49, "%"],
      234201: [48, 47, 46, 44, 49, 49, "%"],
      235001: [48, 48, 46, 44, 49, 49, "%"],
      242201: [48, 48, 46, 45, 49, 49, "%"],
      244801: [48, 48, 47, 45, 49, 49, "%"],
      249401: [48, 48, 47, 45, 50, 50, "%"],
      276601: [49, 48, 47, 45, 50, 50, "%"],
      277801: [49, 49, 47, 45, 50, 50, "%"],
      282401: [49, 49, 48, 45, 50, 50, "%"],
      298401: [49, 49, 48, 46, 50, 50, "%"],
      320801: [49, 49, 48, 46, 51, 51, "%"],
      333801: [49, 49, 49, 46, 51, 51, "%"],
      338201: [50, 49, 49, 46, 51, 51, "%"],
      339601: [50, 50, 49, 46, 51, 51, "%"],
      389001: [50, 50, 49, 47, 51, 51, "%"],
      408001: [50, 50, 50, 47, 51, 51, "%"],
      434801: [51, 50, 50, 47, 51, 51, "%"],
      436601: [51, 51, 50, 47, 51, 51, "%"],
      449001: [51, 51, 50, 47, 52, 52, "%"],
      524601: [51, 51, 51, 47, 52, 52, "%"],
      558601: [51, 51, 51, 48, 52, 52, "%"],
      608801: [52, 51, 51, 48, 52, 52, "%"],
      611201: [52, 52, 51, 48, 52, 52, "%"],
      734601: [52, 52, 52, 48, 52, 52, "%"],
      748601: [52, 52, 52, 48, 53, 53, "%"],
      989401: [52, 52, 52, 49, 53, 53, "%"],
      1014801: [53, 52, 52, 49, 53, 53, "%"],
      1018801: [53, 53, 52, 49, 53, 53, "%"],
      1224401: [53, 53, 53, 49, 53, 53, "%"]
    },
    35: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 8],
      2101: [152, 0, 150, 2, 152, 44],
      2201: [163, 0, 158, 4, 163, 80],
      2301: [173, 0, 166, 7, 173, 116],
      2401: [184, 0, 175, 9, 184, 152],
      2501: [194, 0, 183, 11, 194, 188],
      2601: [205, 0, 191, 13, 225, 225],
      2701: [215, 0, 200, 15, 260, 260],
      2801: [217, 0, 200, 17, 296, 296],
      2901: [228, 0, 208, 19, 332, 332],
      3001: [238, 0, 216, 22, 368, 368],
      3101: [249, 0, 225, 24, 404, 404],
      3201: [259, 0, 233, 26, 440, 440],
      3301: [270, 0, 241, 28, 476, 476],
      3401: [272, 0, 241, 30, 512, 512],
      3501: [282, 0, 250, 32, 548, 548],
      3601: [293, 0, 258, 35, 584, 584],
      3701: [303, 0, 266, 37, 620, 620],
      3801: [314, 0, 275, 39, 656, 656],
      3901: [324, 0, 283, 41, 692, 692],
      4001: [327, 0, 283, 43, 728, 728],
      4101: [337, 0, 291, 45, 764, 764],
      4201: [348, 0, 300, 48, 800, 800],
      4301: [358, 0, 308, 50, 836, 836],
      4401: [369, 0, 316, 61, 872, 872],
      4501: [379, 0, 325, 86, 908, 908],
      4601: [381, 0, 325, 110, 944, 944],
      4701: [392, 0, 333, 134, 980, 980],
      4801: [402, 0, 341, 159, 1013, 1013],
      4901: [412, 0, 350, 183, 1040, 1040],
      5001: [422, 0, 358, 207, 1070, 1070],
      5101: [432, 0, 366, 231, 1097, 1097],
      5201: [442, 0, 375, 255, 1127, 1127],
      5301: [444, 0, 375, 279, 1157, 1157],
      5401: [454, 14, 384, 303, 1184, 1184],
      5501: [464, 41, 394, 327, 1214, 1214],
      5601: [474, 68, 404, 350, 1241, 1241],
      5701: [484, 95, 414, 374, 1270, 1270],
      5801: [494, 119, 423, 397, 1299, 1299],
      5901: [496, 146, 425, 420, 1326, 1326],
      6001: [506, 173, 435, 444, 1355, 1355],
      6101: [516, 200, 445, 467, 1382, 1382],
      6201: [526, 227, 455, 491, 1411, 1411],
      6301: [536, 254, 465, 514, 1440, 1440],
      6401: [546, 281, 475, 538, 1467, 1467],
      6501: [548, 308, 476, 561, 1496, 1496],
      6601: [558, 335, 486, 585, 1523, 1523],
      6701: [571, 362, 496, 608, 1552, 1552],
      6801: [593, 389, 506, 632, 1581, 1581],
      6901: [615, 416, 516, 655, 1608, 1608],
      7001: [637, 440, 526, 678, 1637, 1637],
      7101: [658, 467, 528, 702, 1664, 1664],
      7201: [680, 494, 538, 725, 1693, 1693],
      7301: [702, 521, 547, 749, 1722, 1722],
      7401: [724, 548, 557, 772, 1749, 1749],
      7501: [746, 575, 567, 796, 1778, 1778],
      7601: [768, 602, 577, 819, 1805, 1805],
      7701: [789, 629, 587, 843, 1834, 1834],
      7801: [811, 656, 589, 866, 1863, 1863],
      7901: [833, 683, 599, 889, 1890, 1890],
      8001: [855, 710, 609, 913, 1919, 1919],
      8101: [877, 737, 619, 936, 1946, 1946],
      8201: [899, 761, 629, 960, 1975, 1975],
      8301: [921, 788, 639, 983, 2004, 2004],
      8401: [942, 815, 640, 1007, 2031, 2031],
      8501: [964, 842, 650, 1030, 2060, 2060],
      8601: [986, 869, 660, 1054, 2087, 2087],
      8701: [1008, 896, 670, 1077, 2116, 2116],
      8801: [1030, 923, 680, 1100, 2145, 2145],
      8901: [1052, 950, 690, 1124, 2172, 2172],
      9001: [1074, 977, 692, 1147, 2201, 2201],
      9101: [1096, 1004, 701, 1171, 2228, 2228],
      9201: [1117, 1031, 711, 1194, 2257, 2257],
      9301: [1139, 1058, 721, 1218, 2286, 2286],
      9401: [1161, 1082, 731, 1241, 2313, 2313],
      9501: [1183, 1109, 741, 1265, 2342, 2342],
      9601: [1205, 1127, 742, 1288, 2369, 2369],
      9701: [1227, 1148, 752, 1311, 2398, 2398],
      9801: [1249, 1169, 761, 1335, 2427, 2427],
      9901: [1270, 1193, 771, 1358, 2454, 2454],
      10001: [1292, 1214, 781, 1382, 2483, 2483],
      10101: [1314, 1235, 790, 1405, 2510, 2510],
      10201: [1336, 1255, 800, 1429, 2539, 2539],
      10301: [1358, 1279, 801, 1452, 2568, 2568],
      10401: [1380, 1299, 811, 1476, 2595, 2595],
      10501: [1402, 1320, 821, 1499, 2624, 2624],
      10601: [1423, 1340, 830, 1522, 2651, 2651],
      10701: [1445, 1364, 840, 1546, 2680, 2680],
      10801: [1467, 1385, 850, 1569, 2709, 2709],
      10901: [1489, 1405, 851, 1593, 2736, 2736],
      11001: [1511, 1426, 860, 1616, 2765, 2765],
      11101: [1533, 1449, 870, 1640, 2792, 2792],
      11201: [1555, 1470, 880, 1663, 2821, 2821],
      11301: [1576, 1490, 889, 1687, 2850, 2850],
      11401: [1598, 1511, 899, 1710, 2877, 2877],
      11501: [1620, 1534, 900, 1734, 2906, 2906],
      11601: [1642, 1555, 910, 1757, 2933, 2933],
      11701: [1664, 1575, 920, 1780, 2962, 2962],
      11801: [1686, 1596, 929, 1804, 2991, 2991],
      11901: [1708, 1620, 939, 1827, 3018, 3018],
      12001: [1729, 1640, 949, 1851, 3047, 3047],
      12101: [1751, 1661, 950, 1874, 3074, 3074],
      12201: [1773, 1684, 960, 1898, 3103, 3103],
      12301: [1795, 1705, 969, 1921, 3132, 3132],
      12401: [1817, 1725, 979, 1944, 3159, 3159],
      12501: [1839, 1746, 988, 1968, 3188, 3188],
      12601: [1861, 1769, 998, 1991, 3215, 3215],
      12701: [1882, 1790, 1008, 2015, 3244, 3244],
      12801: [1904, 1810, 1009, 2038, 3273, 3273],
      12901: [1926, 1831, 1019, 2062, 3300, 3300],
      13001: [1948, 1855, 1028, 2085, 3329, 3329],
      13101: [1970, 1875, 1038, 2109, 3356, 3356],
      13201: [1992, 1896, 1047, 2132, 3385, 3385],
      13301: [2014, 1916, 1057, 2156, 3417, 3417],
      13401: [2036, 1940, 1059, 2179, 3453, 3453],
      13501: [2058, 1960, 1068, 2203, 3488, 3488],
      13601: [2080, 1981, 1078, 2227, 3523, 3523],
      13701: [2102, 2001, 1087, 2251, 3558, 3558],
      13801: [2124, 2025, 1097, 2274, 3594, 3594],
      13901: [2147, 2045, 1107, 2298, 3629, 3629],
      14001: [2169, 2066, 1108, 2322, 3664, 3664],
      14101: [2191, 2087, 1118, 2345, 3699, 3699],
      14201: [2212, 2110, 1127, 2368, 3733, 3733],
      14301: [2233, 2131, 1137, 2391, 3768, 3768],
      14401: [2254, 2151, 1146, 2413, 3802, 3802],
      14501: [2275, 2175, 1156, 2436, 3836, 3836],
      14601: [2296, 2195, 1158, 2459, 3870, 3870],
      14701: [2318, 2216, 1167, 2482, 3905, 3905],
      14801: [2339, 2236, 1177, 2504, 3939, 3939],
      14901: [2360, 2260, 1186, 2527, 3973, 3973],
      15001: [2381, 2280, 1196, 2550, 4007, 4007],
      15101: [2402, 2301, 1206, 2573, 4042, 4042],
      15201: [2423, 2322, 1215, 2595, 4076, 4076],
      15301: [2444, 2345, 1217, 2618, 4116, 4116],
      15401: [2466, 2366, 1226, 2641, 4153, 4153],
      15501: [2487, 2386, 1236, 2664, 4190, 4190],
      15601: [2508, 2407, 1245, 2686, 4227, 4227],
      15701: [2529, 2430, 1255, 2709, 4264, 4264],
      15801: [2551, 2451, 1265, 2734, 4304, 4304],
      15901: [2579, 2480, 1267, 2764, 4341, 4341],
      16001: [2606, 2507, 1277, 2794, 4378, 4378],
      16101: [2634, 2536, 1287, 2824, 4416, 4416],
      16201: [2661, 2562, 1297, 2854, 4453, 4453],
      16301: [2689, 2592, 1307, 2884, 4493, 4493],
      16401: [2717, 2621, 1317, 2914, 4530, 4530],
      16501: [2744, 2648, 1319, 2944, 4567, 4567],
      16601: [2772, 2677, 1329, 2974, 4604, 4604],
      16701: [2799, 2703, 1339, 3004, 4641, 4641],
      16801: [2827, 2733, 1349, 3034, 4681, 4681],
      16901: [2854, 2759, 1359, 3064, 4718, 4718],
      17001: [2882, 2789, 1369, 3094, 4755, 4755],
      17101: [2910, 2815, 1371, 3124, 4792, 4792],
      17201: [2937, 2844, 1381, 3154, 4829, 4829],
      17301: [2965, 2871, 1391, 3184, 4869, 4869],
      17401: [2992, 2900, 1401, 3214, 4906, 4906],
      17501: [3020, 2930, 1411, 3244, 4944, 4944],
      17601: [3047, 2956, 1421, 3274, 4981, 4981],
      17701: [3075, 2985, 1431, 3304, 5018, 5018],
      17801: [3102, 3012, 1433, 3333, 5058, 5058],
      17901: [3130, 3041, 1443, 3363, 5095, 5095],
      18001: [3158, 3068, 1453, 3393, 5132, 5132],
      18101: [3185, 3097, 1463, 3423, 5169, 5169],
      18201: [3213, 3124, 1473, 3453, 5206, 5206],
      18301: [3240, 3153, 1483, 3483, 5246, 5246],
      18401: [3268, 3182, 1485, 3513, 5283, 5283],
      18501: [3295, 3209, 1495, 3543, 5320, 5320],
      18601: [3323, 3238, 1505, 3573, 5357, 5357],
      18701: [3350, 3265, 1515, 3603, 5395, 5395],
      18801: [3378, 3294, 1525, 3633, 5434, 5434],
      18901: [3406, 3320, 1535, 3663, 5472, 5472],
      19001: [3433, 3350, 1537, 3693, 5509, 5509],
      19101: [3461, 3376, 1547, 3723, 5546, 5546],
      19201: [3488, 3406, 1557, 3753, 5583, 5583],
      19301: [3516, 3435, 1567, 3783, 5623, 5623],
      19401: [3543, 3461, 1577, 3813, 5660, 5660],
      19501: [3571, 3491, 1587, 3843, 5697, 5697],
      19601: [3598, 3517, 1589, 3873, 5734, 5734],
      19701: [3626, 3547, 1599, 3903, 5771, 5771],
      19801: [3654, 3573, 1609, 3933, 5811, 5811],
      19901: [3681, 3602, 1619, 3963, 5848, 5848],
      20001: [3736, 3658, 1639, 4023, 5923, 5923],
      20201: [3791, 3713, 1650, 4082, 6e3, 6e3],
      20401: [3846, 3768, 1669, 4142, 6074, 6074],
      20601: [3902, 3822, 1687, 4202, 6148, 6148],
      20801: [3957, 3876, 1697, 4262, 6225, 6225],
      21001: [4012, 3930, 1716, 4322, 6299, 6299],
      21201: [4067, 3987, 1734, 4382, 6376, 6376],
      21401: [4122, 4042, 1744, 4442, 6451, 6451],
      21601: [4177, 4096, 1763, 4502, 6525, 6525],
      21801: [4232, 4150, 1781, 4562, 6602, 6602],
      22001: [4287, 4204, 1792, 4622, 6676, 6676],
      22201: [4343, 4259, 1810, 4682, 6753, 6753],
      22401: [4398, 4313, 1829, 4742, 6827, 6827],
      22601: [4453, 4367, 1847, 4801, 6902, 6902],
      22801: [4508, 4421, 1857, 4861, 6979, 6979],
      23001: [4565, 4475, 1876, 4923, 7054, 7054],
      23201: [4621, 4533, 1894, 4985, 7133, 7133],
      23401: [4678, 4587, 1905, 5046, 7209, 7209],
      23601: [4735, 4641, 1923, 5108, 7285, 7285],
      23801: [4792, 4695, 1942, 5169, 7364, 7364],
      24001: [4848, 4749, 1952, 5231, 7439, 7439],
      24201: [4905, 4804, 1970, 5292, 7518, 7518],
      24401: [4962, 4858, 1989, 5354, 7594, 7594],
      24601: [5019, 4915, 1999, 5415, 7670, 7670],
      24801: [5075, 4972, 2018, 5477, 7749, 7749],
      25001: [5132, 5026, 2036, 5539, 7824, 7824],
      25201: [5189, 5083, 2046, 5600, 7903, 7903],
      25401: [5246, 5138, 2065, 5662, 7979, 7979],
      25601: [5302, 5195, 2111, 5723, 8055, 8055],
      25801: [5359, 5249, 2165, 5785, 8134, 8134],
      26001: [5416, 5306, 2223, 5846, 8209, 8209],
      26201: [5473, 5360, 2277, 5908, 8288, 8288],
      26401: [5530, 5417, 2334, 5970, 8364, 8364],
      26601: [5586, 5472, 2388, 6031, 8440, 8440],
      26801: [5643, 5529, 2445, 6093, 8519, 8519],
      27001: [5700, 5583, 2499, 6154, 8594, 8594],
      27201: [5757, 5640, 2556, 6216, 8673, 8673],
      27401: [5813, 5694, 2611, 6277, 8749, 8749],
      27601: [5870, 5751, 2668, 6339, 8825, 8825],
      27801: [5927, 5808, 2725, 6401, 8904, 8904],
      28001: [5984, 5863, 2779, 6462, 8979, 8979],
      28201: [6040, 5920, 2836, 6524, 9058, 9058],
      28401: [6097, 5974, 2890, 6585, 9134, 9134],
      28601: [6154, 6031, 2947, 6647, 9210, 9210],
      28801: [6211, 6085, 3002, 6708, 9289, 9289],
      29001: [6267, 6142, 3059, 6770, 9364, 9364],
      29201: [6324, 6197, 3113, 6832, 9443, 9443],
      29401: [6381, 6254, 3170, 6893, 9519, 9519],
      29601: [6438, 6308, 3224, 6955, 9595, 9595],
      29801: [6495, 6365, 3281, 7016, 9674, 9674],
      30001: [6551, 6419, 3336, 7078, 9749, 9749],
      30201: [6608, 6476, 3393, 7139, 9828, 9828],
      30401: [6665, 6533, 3450, 7201, 9904, 9904],
      30601: [6722, 6588, 3504, 7262, 9980, 9980],
      30801: [6778, 6645, 3561, 7324, 10059, 10059],
      31001: [6835, 6699, 3615, 7386, 10134, 10134],
      31201: [6892, 6756, 3672, 7447, 10213, 10213],
      31401: [6949, 6810, 3727, 7509, 10289, 10289],
      31601: [7005, 6867, 3784, 7570, 10365, 10365],
      31801: [7062, 6921, 3838, 7632, 10444, 10444],
      32001: [7119, 6979, 3895, 7693, 10519, 10519],
      32201: [7176, 7034, 3950, 7755, 10598, 10598],
      32401: [7232, 7092, 4009, 7817, 10674, 10674],
      32601: [7289, 7148, 4064, 7878, 10750, 10750],
      32801: [7346, 7206, 4123, 7940, 10829, 10829],
      33001: [7403, 7261, 4178, 8001, 10904, 10904],
      33201: [7460, 7320, 4236, 8063, 10983, 10983],
      33401: [7516, 7378, 4295, 8124, 11059, 11059],
      33601: [7573, 7434, 4350, 8186, 11135, 11135],
      33801: [7630, 7492, 4408, 8248, 11214, 11214],
      34001: [7687, 7547, 4464, 8309, 11289, 11289],
      34201: [7743, 7606, 4522, 8371, 11368, 11368],
      34401: [7800, 7661, 4578, 8432, 11444, 11444],
      34601: [7857, 7719, 4636, 8494, 11520, 11520],
      34801: [7914, 7775, 4691, 8555, 11599, 11599],
      35001: [7970, 7833, 4750, 8617, 11674, 11674],
      35201: [8027, 7889, 4805, 8679, 11753, 11753],
      35401: [8084, 7947, 4863, 8740, 11829, 11829],
      35601: [8141, 8002, 4919, 8802, 11905, 11905],
      35801: [8197, 8061, 4977, 8863, 11984, 11984],
      36001: [8254, 8119, 5035, 8925, 12059, 12059],
      36201: [8311, 8174, 5091, 8986, 12138, 12138],
      36401: [8368, 8233, 5149, 9048, 12214, 12214],
      36601: [8424, 8288, 5205, 9110, 12290, 12290],
      36801: [8481, 8346, 5263, 9171, 12369, 12369],
      37001: [8538, 8402, 5318, 9233, 12444, 12444],
      37201: [8595, 8460, 5377, 9294, 12523, 12523],
      37401: [8652, 8516, 5432, 9356, 12599, 12599],
      37601: [8708, 8574, 5490, 9417, 12675, 12675],
      37801: [8765, 8629, 5546, 9479, 12754, 12754],
      38001: [8822, 8688, 5604, 9541, 12829, 12829],
      38201: [8879, 8743, 5660, 9602, 12908, 12908],
      38401: [8935, 8802, 5718, 9664, 12984, 12984],
      38601: [8992, 8857, 5773, 9725, 13054, 13054],
      38801: [9048, 8912, 5829, 9786, 13124, 13124],
      39001: [9105, 8968, 5884, 9848, 13194, 13194],
      39201: [9161, 9026, 5943, 9909, 13264, 13264],
      39401: [9219, 9081, 5998, 9970, 13334, 13334],
      39601: [9289, 9151, 6068, 10032, 13404, 13404],
      39801: [9359, 9221, 6138, 10093, 13474, 13474],
      40001: [9429, 9291, 6208, 10154, 13544, 13544],
      40201: [9499, 9361, 6278, 10216, 13614, 13614],
      40401: [9569, 9431, 6348, 10277, 13684, 13684],
      40601: [9639, 9501, 6418, 10338, 13754, 13754],
      40801: [9709, 9571, 6488, 10400, 13824, 13824],
      41001: [9779, 9641, 6558, 10461, 13894, 13894],
      41201: [9849, 9711, 6628, 10522, 13964, 13964],
      41401: [9919, 9781, 6698, 10584, 14034, 14034],
      41601: [9989, 9851, 6768, 10645, 14104, 14104],
      41801: [10059, 9921, 6838, 10706, 14174, 14174],
      42001: [10129, 9991, 6908, 10768, 14244, 14244],
      42201: [10199, 10061, 6978, 10829, 14314, 14314],
      42401: [10269, 10131, 7048, 10890, 14384, 14384],
      42601: [10339, 10201, 7118, 10952, 14454, 14454],
      42801: [10409, 10271, 7188, 11013, 14524, 14524],
      43001: [10479, 10341, 7258, 11074, 14594, 14594],
      43201: [10549, 10411, 7328, 11136, 14664, 14664],
      43401: [10619, 10481, 7398, 11197, 14734, 14734],
      43601: [10689, 10552, 7468, 11258, 14804, 14804],
      43801: [10759, 10621, 7538, 11320, 14874, 14874],
      44001: [10829, 10691, 7608, 11381, 14944, 14944],
      44201: [10899, 10761, 7678, 11442, 15014, 15014],
      44401: [10969, 10831, 7748, 11504, 15084, 15084],
      44601: [11039, 10901, 7818, 11565, 15154, 15154],
      44801: [11109, 10971, 7888, 11627, 15224, 15224],
      45001: [11179, 11041, 7958, 11688, 15294, 15294],
      45201: [11249, 11111, 8028, 11749, 15364, 15364],
      45401: [11319, 11181, 8098, 11811, 15434, 15434],
      45601: [11389, 11251, 8168, 11872, 15504, 15504],
      45801: [11459, 11321, 8238, 11933, 15574, 15574],
      46001: [11529, 11391, 8308, 11995, 15644, 15644],
      46201: [11599, 11461, 8378, 12056, 15714, 15714],
      46401: [11669, 11531, 8448, 12117, 15784, 15784],
      46601: [11739, 11601, 8518, 12179, 15854, 15854],
      46801: [11809, 11671, 8588, 12240, 15924, 15924],
      47001: [11879, 11741, 8658, 12301, 15994, 15994],
      47201: [11949, 11811, 8728, 12363, 16064, 16064],
      47401: [12019, 11881, 8798, 12424, 16134, 16134],
      47601: [12089, 11951, 8868, 12485, 16204, 16204],
      47801: [12159, 12021, 8938, 12547, 16274, 16274],
      48001: [12229, 12091, 9008, 12608, 16344, 16344],
      48201: [12299, 12161, 9078, 12669, 16414, 16414],
      48401: [12369, 12231, 9148, 12731, 16484, 16484],
      48601: [12439, 12302, 9218, 12792, 16554, 16554],
      48801: [12509, 12371, 9288, 12853, 16624, 16624],
      49001: [12579, 12441, 9358, 12915, 16694, 16694],
      49201: [12649, 12511, 9428, 12976, 16764, 16764],
      49401: [12719, 12581, 9498, 13037, 16834, 16834],
      49601: [12789, 12651, 9568, 13099, 16904, 16904],
      49801: [12859, 12721, 9638, 13160, 16974, 16974],
      50001: [12929, 12791, 9708, 13221, 17044, 17044],
      50201: [12999, 12861, 9778, 13283, 17114, 17114],
      50401: [13069, 12931, 9848, 13344, 17184, 17184],
      50601: [13139, 13001, 9918, 13405, 17254, 17254],
      50801: [13209, 13071, 9988, 13467, 17324, 17324],
      51001: [13279, 13141, 10058, 13528, 17394, 17394],
      51201: [13349, 13211, 10128, 13589, 17464, 17464],
      51401: [13419, 13281, 10198, 13651, 17534, 17534],
      51601: [13489, 13351, 10268, 13712, 17604, 17604],
      51801: [13559, 13421, 10338, 13773, 17674, 17674],
      52001: [13629, 13491, 10408, 13835, 17744, 17744],
      52201: [13699, 13561, 10478, 13896, 17814, 17814],
      52401: [13769, 13631, 10548, 13957, 17884, 17884],
      52601: [13839, 13701, 10618, 14019, 17954, 17954],
      52801: [13909, 13771, 10688, 14080, 18024, 18024],
      53001: [13979, 13841, 10758, 14141, 18094, 18094],
      53201: [14049, 13911, 10828, 14203, 18164, 18164],
      53401: [14120, 13981, 10898, 14266, 18236, 18236],
      53601: [14230, 14092, 11009, 14367, 18346, 18346],
      53801: [14340, 14206, 11123, 14468, 18456, 18456],
      54001: [14450, 14320, 11236, 14570, 18566, 18566],
      54201: [14560, 14434, 11350, 14671, 18676, 18676],
      54401: [14670, 14547, 11464, 14772, 18786, 18786],
      54601: [14780, 14661, 11578, 14874, 18896, 18896],
      54801: [14890, 14775, 11691, 14975, 19006, 19006],
      55001: [15e3, 14886, 11802, 15076, 19116, 19116],
      55201: [15111, 14999, 11916, 15178, 19226, 19226],
      55401: [15220, 15113, 12030, 15279, 19336, 19336],
      55601: [15330, 15227, 12143, 15380, 19446, 19446],
      55801: [15440, 15341, 12257, 15482, 19556, 19556],
      56001: [15550, 15454, 12371, 15583, 19666, 19666],
      56201: [15660, 15568, 12485, 15684, 19776, 19776],
      56401: [15770, 15682, 12598, 15786, 19886, 19886],
      56601: [15880, 15793, 12709, 15887, 19996, 19996],
      56801: [15990, 15906, 12823, 15988, 20106, 20106],
      57001: [16100, 16020, 12937, 16090, 20216, 20216],
      57201: [16210, 16134, 13050, 16191, 20326, 20326],
      57401: [16320, 16248, 13164, 16292, 20436, 20436],
      57601: [16430, 16361, 13278, 16394, 20546, 20546],
      57801: [16540, 16475, 13392, 16495, 20656, 20656],
      58001: [16650, 16589, 13505, 16596, 20766, 20766],
      58201: [16760, 16703, 13619, 16698, 20876, 20876],
      58401: [16870, 16814, 13730, 16799, 20986, 20986],
      58601: [16980, 16927, 13844, 16900, 21096, 21096],
      58801: [17090, 17041, 13958, 17002, 21206, 21206],
      59001: [17200, 17155, 14071, 17103, 21316, 21316],
      59201: [17310, 17269, 14185, 17204, 21426, 21426],
      59401: [17420, 17382, 14299, 17306, 21536, 21536],
      59601: [17530, 17496, 14413, 17407, 21646, 21646],
      59801: [17640, 17610, 14526, 17508, 21756, 21756],
      60001: [17750, 17721, 14637, 17610, 21866, 21866],
      60201: [17861, 17834, 14751, 17711, 21976, 21976],
      60401: [17970, 17948, 14865, 17812, 22086, 22086],
      60601: [18080, 18062, 14978, 17914, 22196, 22196],
      60801: [18190, 18176, 15092, 18015, 22306, 22306],
      61001: [18300, 18286, 15203, 18116, 22416, 22416],
      61201: [18410, 18396, 15313, 18218, 22526, 22526],
      61401: [18520, 18506, 15423, 18319, 22636, 22636],
      61601: [18630, 18616, 15533, 18420, 22746, 22746],
      61801: [18740, 18726, 15643, 18522, 22856, 22856],
      62001: [18850, 18836, 15753, 18623, 22966, 22966],
      62201: [18960, 18946, 15863, 18724, 23076, 23076],
      62401: [19070, 19056, 15973, 18826, 23186, 23186],
      62601: [19180, 19166, 16083, 18927, 23296, 23296],
      62801: [19290, 19276, 16193, 19028, 23406, 23406],
      63001: [19400, 19386, 16303, 19130, 23516, 23516],
      63201: [19510, 19496, 16413, 19231, 23626, 23626],
      63401: [19620, 19606, 16523, 19333, 23736, 23736],
      63601: [19730, 19716, 16633, 19434, 23846, 23846],
      63801: [19840, 19826, 16743, 19535, 23956, 23956],
      64001: [19950, 19936, 16853, 19637, 24066, 24066],
      64201: [20060, 20046, 16963, 19738, 24176, 24176],
      64401: [20170, 20156, 17073, 19839, 24286, 24286],
      64601: [20280, 20266, 17183, 19941, 24396, 24396],
      64801: [20390, 20376, 17293, 20042, 24506, 24506],
      65001: [20500, 20486, 17403, 20143, 24616, 24616],
      65201: [20611, 20596, 17513, 20245, 24726, 24726],
      65401: [20720, 20706, 17623, 20346, 24836, 24836],
      65601: [20830, 20816, 17733, 20447, 24946, 24946],
      65801: [20940, 20926, 17843, 20549, 25056, 25056],
      66001: [21050, 21036, 17953, 20650, 25166, 25166],
      66201: [21160, 21146, 18063, 20751, 25276, 25276],
      66401: [21270, 21256, 18173, 20853, 25386, 25386],
      66601: [21380, 21366, 18283, 20954, 25496, 25496],
      66801: [21490, 21476, 18393, 21055, 25606, 25606],
      67001: [21600, 21586, 18503, 21157, 25716, 25716],
      67201: [21710, 21696, 18613, 21258, 25826, 25826],
      67401: [21820, 21806, 18723, 21359, 25936, 25936],
      67601: [21930, 21916, 18833, 21461, 26046, 26046],
      67801: [22040, 22026, 18943, 21562, 26156, 26156],
      68001: [22150, 22136, 19053, 21663, 26266, 26266],
      68201: [22260, 22246, 19163, 21765, 26376, 26376],
      68401: [22370, 22356, 19273, 21866, 26486, 26486],
      68601: [22480, 22466, 19383, 21967, 26596, 26596],
      68801: [22590, 22576, 19493, 22069, 26706, 26706],
      69001: [22700, 22686, 19603, 22170, 26816, 26816],
      69201: [22810, 22796, 19713, 22271, 26926, 26926],
      69401: [22920, 22906, 19823, 22373, 27036, 27036],
      69601: [23030, 23016, 19933, 22474, 27146, 27146],
      69801: [23140, 23126, 20043, 22575, 27256, 27256],
      70001: [23250, 23236, 20153, 22677, 27366, 27366],
      70201: [23361, 23346, 20263, 22778, 27476, 27476],
      70401: [23470, 23456, 20373, 22879, 27586, 27586],
      70601: [23580, 23566, 20483, 22981, 27696, 27696],
      70801: [23690, 23676, 20593, 23082, 27806, 27806],
      71001: [23800, 23786, 20703, 23183, 27916, 27916],
      71201: [23910, 23896, 20813, 23285, 28026, 28026],
      71401: [24020, 24006, 20923, 23386, 28136, 28136],
      71601: [24130, 24116, 21033, 23487, 28246, 28246],
      71801: [24240, 24226, 21143, 23589, 28356, 28356],
      72001: [24350, 24336, 21253, 23690, 28466, 28466],
      72201: [24460, 24446, 21363, 23791, 28576, 28576],
      72401: [24570, 24556, 21473, 23893, 28686, 28686],
      72601: [24680, 24666, 21583, 23994, 28796, 28796],
      72801: [24790, 24776, 21693, 24095, 28906, 28906],
      73001: [24900, 24886, 21803, 24197, 29016, 29016],
      73201: [25010, 24996, 21913, 24298, 29126, 29126],
      73401: [25120, 25106, 22023, 24399, 29236, 29236],
      73601: [25230, 25216, 22133, 24501, 29346, 29346],
      73801: [25340, 25326, 22243, 24602, 29456, 29456],
      74001: [25450, 25436, 22353, 24703, 29566, 29566],
      74201: [25560, 25546, 22463, 24805, 29676, 29676],
      74401: [25670, 25656, 22573, 24906, 29786, 29786],
      74601: [25780, 25766, 22683, 25007, 29896, 29896],
      74801: [25890, 25876, 22793, 25109, 30006, 30006],
      75001: [26e3, 25986, 22903, 25210, 30116, 30116],
      75201: [26111, 26096, 23013, 25311, 30226, 30226],
      75401: [26220, 26206, 23123, 25413, 30336, 30336],
      75601: [26330, 26316, 23233, 25514, 30446, 30446],
      75801: [26440, 26426, 23343, 25615, 30556, 30556],
      76001: [26550, 26536, 23453, 25717, 30666, 30666],
      76201: [26660, 26646, 23563, 25818, 30776, 30776],
      76401: [26770, 26756, 23673, 25919, 30886, 30886],
      76601: [26880, 26866, 23783, 26021, 30996, 30996],
      76801: [26990, 26976, 23893, 26122, 31106, 31106],
      77001: [27100, 27086, 24003, 26223, 31216, 31216],
      77201: [27210, 27196, 24113, 26325, 31326, 31326],
      77401: [27320, 27306, 24223, 26426, 31436, 31436],
      77601: [27430, 27416, 24333, 26527, 31546, 31546],
      77801: [27540, 27526, 24443, 26629, 31656, 31656],
      78001: [27650, 27636, 24553, 26730, 31766, 31766],
      78201: [27760, 27746, 24663, 26831, 31876, 31876],
      78401: [27870, 27856, 24773, 26933, 31986, 31986],
      78601: [27980, 27966, 24883, 27034, 32096, 32096],
      78801: [28090, 28076, 24993, 27135, 32206, 32206],
      79001: [28200, 28186, 25103, 27237, 32316, 32316],
      79201: [28310, 28296, 25213, 27338, 32426, 32426],
      79401: [28420, 28406, 25323, 27439, 32536, 32536],
      79601: [28530, 28516, 25433, 27541, 32646, 32646],
      79801: [28640, 28626, 25543, 27642, 32756, 32756],
      80001: [36, 36, 32, 35, 41, 41, "%"],
      82001: [36, 36, 33, 35, 41, 41, "%"],
      83001: [37, 37, 33, 35, 41, 41, "%"],
      83201: [37, 37, 33, 35, 42, 42, "%"],
      84801: [37, 37, 33, 36, 42, 42, "%"],
      85801: [37, 37, 34, 36, 42, 42, "%"],
      87601: [38, 37, 34, 36, 42, 42, "%"],
      87801: [38, 38, 34, 36, 42, 42, "%"],
      89801: [38, 38, 34, 36, 43, 43, "%"],
      90001: [38, 38, 35, 36, 43, 43, "%"],
      90801: [38, 38, 35, 37, 43, 43, "%"],
      93001: [39, 39, 35, 37, 43, 43, "%"],
      94601: [39, 39, 36, 37, 43, 43, "%"],
      97601: [39, 39, 36, 37, 44, 44, "%"],
      97801: [39, 39, 36, 38, 44, 44, "%"],
      99001: [40, 40, 36, 38, 44, 44, "%"],
      99601: [40, 40, 37, 38, 44, 44, "%"],
      105401: [40, 40, 38, 38, 44, 44, "%"],
      105801: [41, 40, 38, 39, 44, 44, "%"],
      106001: [41, 41, 38, 39, 44, 44, "%"],
      107001: [41, 41, 38, 39, 45, 45, "%"],
      111801: [41, 41, 39, 39, 45, 45, "%"],
      113601: [42, 41, 39, 39, 45, 45, "%"],
      113801: [42, 42, 39, 39, 45, 45, "%"],
      115401: [42, 42, 39, 40, 45, 45, "%"],
      118201: [42, 42, 39, 40, 46, 46, "%"],
      119001: [42, 42, 40, 40, 46, 46, "%"],
      122801: [43, 43, 40, 40, 46, 46, "%"],
      126601: [43, 43, 40, 41, 46, 46, "%"],
      127201: [43, 43, 41, 41, 46, 46, "%"],
      132201: [43, 43, 41, 41, 47, 47, "%"],
      133401: [44, 43, 41, 41, 47, 47, "%"],
      133601: [44, 44, 41, 41, 47, 47, "%"],
      136601: [44, 44, 42, 41, 47, 47, "%"],
      140601: [44, 44, 42, 42, 47, 47, "%"],
      146201: [45, 44, 42, 42, 47, 47, "%"],
      146401: [45, 45, 42, 42, 47, 47, "%"],
      147601: [45, 45, 43, 42, 47, 47, "%"],
      149801: [45, 45, 43, 42, 48, 48, "%"],
      157801: [45, 45, 43, 43, 48, 48, "%"],
      160401: [45, 45, 44, 43, 48, 48, "%"],
      161601: [46, 45, 44, 43, 48, 48, "%"],
      161801: [46, 46, 44, 43, 48, 48, "%"],
      172801: [46, 46, 44, 43, 49, 49, "%"],
      175601: [46, 46, 45, 43, 49, 49, "%"],
      179801: [46, 46, 45, 44, 49, 49, "%"],
      180601: [47, 46, 45, 44, 49, 49, "%"],
      180801: [47, 47, 45, 44, 49, 49, "%"],
      194201: [47, 47, 46, 44, 49, 49, "%"],
      204401: [47, 47, 46, 44, 50, 50, "%"],
      204601: [48, 47, 46, 44, 50, 50, "%"],
      204801: [48, 48, 46, 44, 50, 50, "%"],
      209001: [48, 48, 46, 45, 50, 50, "%"],
      217001: [48, 48, 47, 45, 50, 50, "%"],
      236201: [49, 48, 47, 45, 50, 50, "%"],
      236401: [49, 49, 47, 45, 50, 50, "%"],
      246001: [49, 49, 48, 45, 50, 50, "%"],
      249401: [49, 49, 48, 46, 50, 50, "%"],
      249801: [49, 49, 48, 46, 51, 51, "%"],
      279201: [50, 49, 48, 46, 51, 51, "%"],
      279401: [50, 50, 48, 46, 51, 51, "%"],
      283801: [50, 50, 49, 46, 51, 51, "%"],
      309201: [50, 50, 49, 47, 51, 51, "%"],
      321201: [50, 50, 49, 47, 52, 52, "%"],
      335401: [50, 50, 50, 47, 52, 52, "%"],
      341201: [51, 50, 50, 47, 52, 52, "%"],
      341601: [51, 51, 50, 47, 52, 52, "%"],
      406801: [51, 51, 50, 48, 52, 52, "%"],
      410001: [51, 51, 51, 48, 52, 52, "%"],
      438801: [52, 51, 51, 48, 52, 52, "%"],
      439201: [52, 52, 51, 48, 52, 52, "%"],
      449601: [52, 52, 51, 48, 53, 53, "%"],
      527201: [52, 52, 52, 48, 53, 53, "%"],
      594401: [52, 52, 52, 49, 53, 53, "%"],
      614201: [53, 52, 52, 49, 53, 53, "%"],
      614801: [53, 53, 52, 49, 53, 53, "%"],
      738201: [53, 53, 53, 49, 53, 53, "%"],
      749401: [53, 53, 53, 49, 54, 54, "%"],
      1023801: [54, 53, 53, 49, 54, 54, "%"],
      1024801: [54, 54, 53, 49, 54, 54, "%"],
      1103201: [54, 54, 53, 50, 54, 54, "%"],
      1230401: [54, 54, 54, 50, 54, 54, "%"]
    },
    36: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 9],
      2101: [152, 0, 150, 2, 152, 46],
      2201: [163, 0, 158, 4, 163, 83],
      2301: [173, 0, 166, 7, 173, 120],
      2401: [184, 0, 175, 9, 184, 157],
      2501: [194, 0, 183, 11, 194, 194],
      2601: [205, 0, 191, 13, 231, 231],
      2701: [215, 0, 200, 15, 268, 268],
      2801: [217, 0, 200, 17, 305, 305],
      2901: [228, 0, 208, 19, 342, 342],
      3001: [238, 0, 216, 22, 379, 379],
      3101: [249, 0, 225, 24, 416, 416],
      3201: [259, 0, 233, 26, 453, 453],
      3301: [270, 0, 241, 28, 490, 490],
      3401: [272, 0, 241, 30, 527, 527],
      3501: [282, 0, 250, 32, 564, 564],
      3601: [293, 0, 258, 35, 601, 601],
      3701: [303, 0, 266, 37, 638, 638],
      3801: [314, 0, 275, 39, 675, 675],
      3901: [324, 0, 283, 41, 712, 712],
      4001: [327, 0, 283, 43, 749, 749],
      4101: [337, 0, 291, 45, 786, 786],
      4201: [348, 0, 300, 48, 823, 823],
      4301: [358, 0, 308, 50, 860, 860],
      4401: [369, 0, 316, 61, 897, 897],
      4501: [379, 0, 325, 86, 934, 934],
      4601: [381, 0, 325, 112, 971, 971],
      4701: [392, 0, 333, 137, 1008, 1008],
      4801: [402, 0, 341, 162, 1042, 1042],
      4901: [412, 0, 350, 186, 1069, 1069],
      5001: [422, 0, 358, 211, 1100, 1100],
      5101: [432, 0, 366, 236, 1128, 1128],
      5201: [442, 0, 375, 260, 1159, 1159],
      5301: [444, 0, 375, 285, 1190, 1190],
      5401: [454, 15, 384, 310, 1217, 1217],
      5501: [464, 43, 394, 334, 1248, 1248],
      5601: [474, 70, 404, 358, 1275, 1275],
      5701: [484, 98, 414, 382, 1305, 1305],
      5801: [494, 123, 423, 407, 1336, 1336],
      5901: [496, 151, 425, 431, 1363, 1363],
      6001: [506, 178, 435, 455, 1393, 1393],
      6101: [516, 206, 445, 479, 1420, 1420],
      6201: [526, 234, 455, 503, 1450, 1450],
      6301: [536, 262, 465, 527, 1481, 1481],
      6401: [546, 289, 475, 551, 1508, 1508],
      6501: [548, 317, 476, 575, 1538, 1538],
      6601: [563, 345, 486, 599, 1565, 1565],
      6701: [585, 373, 496, 623, 1595, 1595],
      6801: [608, 400, 506, 648, 1626, 1626],
      6901: [630, 428, 516, 672, 1653, 1653],
      7001: [653, 453, 526, 696, 1683, 1683],
      7101: [675, 480, 528, 720, 1710, 1710],
      7201: [698, 508, 538, 744, 1740, 1740],
      7301: [720, 536, 547, 768, 1771, 1771],
      7401: [743, 564, 557, 792, 1798, 1798],
      7501: [765, 591, 567, 816, 1828, 1828],
      7601: [787, 619, 577, 840, 1855, 1855],
      7701: [810, 647, 587, 865, 1885, 1885],
      7801: [833, 675, 589, 889, 1916, 1916],
      7901: [855, 702, 599, 913, 1943, 1943],
      8001: [877, 730, 609, 937, 1973, 1973],
      8101: [900, 758, 619, 961, 2e3, 2e3],
      8201: [922, 783, 629, 985, 2030, 2030],
      8301: [945, 810, 639, 1009, 2061, 2061],
      8401: [967, 838, 640, 1033, 2088, 2088],
      8501: [990, 866, 650, 1057, 2118, 2118],
      8601: [1012, 894, 660, 1081, 2145, 2145],
      8701: [1035, 921, 670, 1106, 2175, 2175],
      8801: [1057, 949, 680, 1130, 2206, 2206],
      8901: [1080, 977, 690, 1154, 2233, 2233],
      9001: [1102, 1005, 692, 1178, 2263, 2263],
      9101: [1125, 1032, 701, 1202, 2290, 2290],
      9201: [1147, 1060, 711, 1226, 2320, 2320],
      9301: [1170, 1088, 721, 1250, 2351, 2351],
      9401: [1192, 1113, 731, 1274, 2378, 2378],
      9501: [1215, 1140, 741, 1299, 2408, 2408],
      9601: [1237, 1159, 742, 1323, 2435, 2435],
      9701: [1259, 1180, 752, 1347, 2465, 2465],
      9801: [1282, 1202, 761, 1371, 2496, 2496],
      9901: [1304, 1227, 771, 1395, 2523, 2523],
      10001: [1327, 1248, 781, 1419, 2553, 2553],
      10101: [1349, 1269, 790, 1443, 2580, 2580],
      10201: [1372, 1290, 800, 1467, 2610, 2610],
      10301: [1394, 1314, 801, 1491, 2641, 2641],
      10401: [1417, 1336, 811, 1515, 2668, 2668],
      10501: [1439, 1357, 821, 1540, 2698, 2698],
      10601: [1462, 1378, 830, 1564, 2725, 2725],
      10701: [1484, 1402, 840, 1588, 2755, 2755],
      10801: [1507, 1423, 850, 1612, 2786, 2786],
      10901: [1529, 1444, 851, 1636, 2813, 2813],
      11001: [1552, 1465, 860, 1660, 2843, 2843],
      11101: [1574, 1490, 870, 1684, 2870, 2870],
      11201: [1596, 1511, 880, 1708, 2900, 2900],
      11301: [1619, 1532, 889, 1732, 2931, 2931],
      11401: [1641, 1553, 899, 1756, 2958, 2958],
      11501: [1664, 1577, 900, 1781, 2988, 2988],
      11601: [1686, 1598, 910, 1805, 3015, 3015],
      11701: [1709, 1619, 920, 1829, 3045, 3045],
      11801: [1731, 1641, 929, 1853, 3076, 3076],
      11901: [1754, 1665, 939, 1877, 3103, 3103],
      12001: [1776, 1686, 949, 1901, 3133, 3133],
      12101: [1799, 1707, 950, 1925, 3160, 3160],
      12201: [1821, 1731, 960, 1949, 3190, 3190],
      12301: [1844, 1752, 969, 1973, 3221, 3221],
      12401: [1866, 1774, 979, 1997, 3248, 3248],
      12501: [1889, 1795, 988, 2022, 3278, 3278],
      12601: [1911, 1819, 998, 2046, 3305, 3305],
      12701: [1933, 1840, 1008, 2070, 3335, 3335],
      12801: [1956, 1861, 1009, 2094, 3366, 3366],
      12901: [1978, 1882, 1019, 2118, 3393, 3393],
      13001: [2001, 1906, 1028, 2142, 3423, 3423],
      13101: [2023, 1928, 1038, 2166, 3450, 3450],
      13201: [2046, 1949, 1047, 2190, 3480, 3480],
      13301: [2069, 1970, 1057, 2215, 3514, 3514],
      13401: [2091, 1994, 1059, 2239, 3550, 3550],
      13501: [2114, 2015, 1068, 2263, 3586, 3586],
      13601: [2137, 2036, 1078, 2288, 3622, 3622],
      13701: [2160, 2058, 1087, 2312, 3659, 3659],
      13801: [2182, 2082, 1097, 2337, 3695, 3695],
      13901: [2205, 2103, 1107, 2361, 3731, 3731],
      14001: [2228, 2124, 1108, 2385, 3767, 3767],
      14101: [2250, 2145, 1118, 2410, 3803, 3803],
      14201: [2272, 2169, 1127, 2433, 3839, 3839],
      14301: [2294, 2190, 1137, 2456, 3874, 3874],
      14401: [2316, 2212, 1146, 2480, 3909, 3909],
      14501: [2337, 2236, 1156, 2503, 3944, 3944],
      14601: [2359, 2257, 1158, 2526, 3980, 3980],
      14701: [2381, 2278, 1167, 2550, 4015, 4015],
      14801: [2403, 2299, 1177, 2573, 4050, 4050],
      14901: [2424, 2323, 1186, 2597, 4085, 4085],
      15001: [2446, 2344, 1196, 2620, 4121, 4121],
      15101: [2468, 2366, 1206, 2643, 4156, 4156],
      15201: [2490, 2387, 1215, 2667, 4191, 4191],
      15301: [2512, 2411, 1217, 2690, 4232, 4232],
      15401: [2533, 2432, 1226, 2714, 4270, 4270],
      15501: [2555, 2453, 1236, 2737, 4309, 4309],
      15601: [2577, 2474, 1245, 2760, 4347, 4347],
      15701: [2599, 2499, 1255, 2784, 4385, 4385],
      15801: [2621, 2520, 1265, 2810, 4426, 4426],
      15901: [2650, 2550, 1267, 2841, 4464, 4464],
      16001: [2678, 2577, 1277, 2871, 4503, 4503],
      16101: [2706, 2607, 1287, 2902, 4541, 4541],
      16201: [2735, 2634, 1297, 2933, 4579, 4579],
      16301: [2763, 2665, 1307, 2964, 4620, 4620],
      16401: [2792, 2695, 1317, 2995, 4658, 4658],
      16501: [2820, 2722, 1319, 3026, 4696, 4696],
      16601: [2848, 2752, 1329, 3056, 4735, 4735],
      16701: [2877, 2779, 1339, 3087, 4773, 4773],
      16801: [2905, 2810, 1349, 3118, 4814, 4814],
      16901: [2933, 2837, 1359, 3149, 4852, 4852],
      17001: [2962, 2867, 1369, 3180, 4890, 4890],
      17101: [2990, 2894, 1371, 3211, 4928, 4928],
      17201: [3018, 2924, 1381, 3241, 4967, 4967],
      17301: [3047, 2952, 1391, 3272, 5008, 5008],
      17401: [3075, 2982, 1401, 3303, 5046, 5046],
      17501: [3103, 3012, 1411, 3334, 5084, 5084],
      17601: [3132, 3039, 1421, 3365, 5122, 5122],
      17701: [3160, 3069, 1431, 3396, 5161, 5161],
      17801: [3189, 3097, 1433, 3426, 5202, 5202],
      17901: [3217, 3127, 1443, 3457, 5240, 5240],
      18001: [3245, 3154, 1453, 3488, 5278, 5278],
      18101: [3274, 3184, 1463, 3519, 5316, 5316],
      18201: [3302, 3211, 1473, 3550, 5354, 5354],
      18301: [3330, 3242, 1483, 3581, 5396, 5396],
      18401: [3359, 3272, 1485, 3611, 5434, 5434],
      18501: [3387, 3299, 1495, 3642, 5472, 5472],
      18601: [3415, 3329, 1505, 3673, 5510, 5510],
      18701: [3444, 3356, 1515, 3704, 5548, 5548],
      18801: [3472, 3387, 1525, 3735, 5589, 5589],
      18901: [3500, 3414, 1535, 3766, 5628, 5628],
      19001: [3529, 3444, 1537, 3796, 5666, 5666],
      19101: [3557, 3471, 1547, 3827, 5704, 5704],
      19201: [3586, 3501, 1557, 3858, 5742, 5742],
      19301: [3614, 3532, 1567, 3889, 5783, 5783],
      19401: [3642, 3559, 1577, 3920, 5821, 5821],
      19501: [3671, 3589, 1587, 3951, 5860, 5860],
      19601: [3699, 3616, 1589, 3981, 5898, 5898],
      19701: [3727, 3646, 1599, 4012, 5936, 5936],
      19801: [3756, 3674, 1609, 4043, 5977, 5977],
      19901: [3784, 3704, 1619, 4074, 6015, 6015],
      20001: [3841, 3761, 1639, 4136, 6092, 6092],
      20201: [3898, 3818, 1650, 4197, 6171, 6171],
      20401: [3954, 3874, 1669, 4259, 6247, 6247],
      20601: [4011, 3930, 1687, 4321, 6324, 6324],
      20801: [4068, 3986, 1697, 4382, 6403, 6403],
      21001: [4124, 4041, 1716, 4444, 6479, 6479],
      21201: [4181, 4100, 1734, 4506, 6559, 6559],
      21401: [4238, 4156, 1744, 4567, 6635, 6635],
      21601: [4295, 4212, 1763, 4629, 6712, 6712],
      21801: [4351, 4268, 1781, 4690, 6791, 6791],
      22001: [4408, 4323, 1792, 4752, 6867, 6867],
      22201: [4465, 4379, 1810, 4814, 6947, 6947],
      22401: [4521, 4435, 1829, 4875, 7023, 7023],
      22601: [4578, 4491, 1847, 4937, 7099, 7099],
      22801: [4635, 4547, 1857, 4999, 7179, 7179],
      23001: [4693, 4602, 1876, 5062, 7257, 7257],
      23201: [4752, 4661, 1894, 5125, 7338, 7338],
      23401: [4810, 4717, 1905, 5189, 7416, 7416],
      23601: [4868, 4773, 1923, 5252, 7494, 7494],
      23801: [4927, 4829, 1942, 5315, 7575, 7575],
      24001: [4985, 4884, 1952, 5379, 7653, 7653],
      24201: [5043, 4940, 1970, 5442, 7734, 7734],
      24401: [5102, 4996, 1989, 5505, 7812, 7812],
      24601: [5160, 5055, 2028, 5569, 7890, 7890],
      24801: [5218, 5114, 2072, 5632, 7971, 7971],
      25001: [5277, 5169, 2114, 5695, 8049, 8049],
      25201: [5335, 5228, 2159, 5759, 8130, 8130],
      25401: [5394, 5284, 2201, 5822, 8208, 8208],
      25601: [5452, 5343, 2259, 5885, 8286, 8286],
      25801: [5510, 5398, 2315, 5948, 8367, 8367],
      26001: [5569, 5457, 2374, 6012, 8445, 8445],
      26201: [5627, 5513, 2430, 6075, 8526, 8526],
      26401: [5685, 5572, 2488, 6138, 8604, 8604],
      26601: [5744, 5628, 2544, 6202, 8682, 8682],
      26801: [5802, 5686, 2603, 6265, 8763, 8763],
      27001: [5860, 5742, 2659, 6328, 8841, 8841],
      27201: [5919, 5801, 2717, 6392, 8922, 8922],
      27401: [5977, 5857, 2773, 6455, 9e3, 9e3],
      27601: [6036, 5915, 2832, 6518, 9078, 9078],
      27801: [6094, 5974, 2891, 6582, 9159, 9159],
      28001: [6152, 6030, 2947, 6645, 9237, 9237],
      28201: [6211, 6089, 3005, 6708, 9318, 9318],
      28401: [6269, 6145, 3061, 6772, 9396, 9396],
      28601: [6327, 6203, 3120, 6835, 9474, 9474],
      28801: [6386, 6259, 3176, 6898, 9555, 9555],
      29001: [6444, 6318, 3234, 6961, 9633, 9633],
      29201: [6503, 6374, 3290, 7025, 9714, 9714],
      29401: [6561, 6432, 3349, 7088, 9792, 9792],
      29601: [6619, 6488, 3405, 7151, 9870, 9870],
      29801: [6678, 6547, 3464, 7215, 9951, 9951],
      30001: [6736, 6603, 3519, 7278, 10029, 10029],
      30201: [6794, 6662, 3578, 7341, 10110, 10110],
      30401: [6853, 6720, 3637, 7405, 10188, 10188],
      30601: [6911, 6776, 3693, 7468, 10266, 10266],
      30801: [6969, 6835, 3751, 7531, 10347, 10347],
      31001: [7028, 6891, 3807, 7595, 10425, 10425],
      31201: [7086, 6949, 3866, 7658, 10506, 10506],
      31401: [7144, 7005, 3922, 7721, 10584, 10584],
      31601: [7203, 7064, 3981, 7785, 10662, 10662],
      31801: [7261, 7120, 4036, 7848, 10743, 10743],
      32001: [7320, 7179, 4095, 7911, 10821, 10821],
      32201: [7378, 7236, 4152, 7975, 10902, 10902],
      32401: [7436, 7296, 4212, 8038, 10980, 10980],
      32601: [7495, 7353, 4269, 8101, 11058, 11058],
      32801: [7553, 7413, 4329, 8164, 11139, 11139],
      33001: [7611, 7470, 4386, 8228, 11217, 11217],
      33201: [7670, 7530, 4446, 8291, 11298, 11298],
      33401: [7728, 7590, 4506, 8354, 11376, 11376],
      33601: [7787, 7647, 4563, 8418, 11454, 11454],
      33801: [7845, 7707, 4623, 8481, 11535, 11535],
      34001: [7903, 7764, 4680, 8544, 11613, 11613],
      34201: [7962, 7824, 4740, 8608, 11694, 11694],
      34401: [8020, 7881, 4797, 8671, 11772, 11772],
      34601: [8078, 7941, 4857, 8734, 11850, 11850],
      34801: [8137, 7998, 4914, 8798, 11931, 11931],
      35001: [8195, 8058, 4974, 8861, 12009, 12009],
      35201: [8253, 8115, 5031, 8924, 12090, 12090],
      35401: [8312, 8175, 5091, 8987, 12168, 12168],
      35601: [8370, 8232, 5148, 9051, 12246, 12246],
      35801: [8429, 8292, 5208, 9114, 12327, 12327],
      36001: [8487, 8352, 5268, 9177, 12405, 12405],
      36201: [8545, 8409, 5325, 9241, 12486, 12486],
      36401: [8604, 8469, 5385, 9304, 12564, 12564],
      36601: [8662, 8526, 5442, 9367, 12642, 12642],
      36801: [8720, 8586, 5502, 9431, 12723, 12723],
      37001: [8779, 8643, 5559, 9494, 12801, 12801],
      37201: [8837, 8703, 5619, 9557, 12882, 12882],
      37401: [8895, 8760, 5676, 9621, 12960, 12960],
      37601: [8954, 8820, 5736, 9684, 13038, 13038],
      37801: [9012, 8877, 5793, 9747, 13119, 13119],
      38001: [9071, 8937, 5853, 9811, 13197, 13197],
      38201: [9129, 8994, 5910, 9874, 13278, 13278],
      38401: [9187, 9054, 5970, 9937, 13356, 13356],
      38601: [9245, 9111, 6027, 1e4, 13428, 13428],
      38801: [9304, 9168, 6084, 10063, 13500, 13500],
      39001: [9362, 9225, 6141, 10126, 13572, 13572],
      39201: [9420, 9285, 6201, 10190, 13644, 13644],
      39401: [9479, 9342, 6258, 10253, 13716, 13716],
      39601: [9551, 9414, 6330, 10316, 13788, 13788],
      39801: [9623, 9486, 6402, 10379, 13860, 13860],
      40001: [9695, 9558, 6474, 10442, 13932, 13932],
      40201: [9767, 9630, 6546, 10505, 14004, 14004],
      40401: [9839, 9702, 6618, 10568, 14076, 14076],
      40601: [9911, 9774, 6690, 10631, 14148, 14148],
      40801: [9983, 9846, 6762, 10694, 14220, 14220],
      41001: [10055, 9918, 6834, 10757, 14292, 14292],
      41201: [10127, 9990, 6906, 10820, 14364, 14364],
      41401: [10199, 10062, 6978, 10883, 14436, 14436],
      41601: [10271, 10134, 7050, 10946, 14508, 14508],
      41801: [10343, 10206, 7122, 11010, 14580, 14580],
      42001: [10415, 10278, 7194, 11073, 14652, 14652],
      42201: [10487, 10350, 7266, 11136, 14724, 14724],
      42401: [10559, 10422, 7338, 11199, 14796, 14796],
      42601: [10631, 10494, 7410, 11262, 14868, 14868],
      42801: [10703, 10566, 7482, 11325, 14940, 14940],
      43001: [10775, 10638, 7554, 11388, 15012, 15012],
      43201: [10847, 10710, 7626, 11451, 15084, 15084],
      43401: [10919, 10782, 7698, 11514, 15156, 15156],
      43601: [10991, 10854, 7770, 11577, 15228, 15228],
      43801: [11063, 10926, 7842, 11640, 15300, 15300],
      44001: [11135, 10998, 7914, 11703, 15372, 15372],
      44201: [11207, 11070, 7986, 11767, 15444, 15444],
      44401: [11279, 11142, 8058, 11830, 15516, 15516],
      44601: [11351, 11214, 8130, 11893, 15588, 15588],
      44801: [11423, 11286, 8202, 11956, 15660, 15660],
      45001: [11495, 11358, 8274, 12019, 15732, 15732],
      45201: [11567, 11430, 8346, 12082, 15804, 15804],
      45401: [11639, 11502, 8418, 12145, 15876, 15876],
      45601: [11711, 11574, 8490, 12208, 15948, 15948],
      45801: [11783, 11646, 8562, 12271, 16020, 16020],
      46001: [11855, 11718, 8634, 12334, 16092, 16092],
      46201: [11927, 11790, 8706, 12397, 16164, 16164],
      46401: [11999, 11862, 8778, 12460, 16236, 16236],
      46601: [12071, 11934, 8850, 12524, 16308, 16308],
      46801: [12143, 12006, 8922, 12587, 16380, 16380],
      47001: [12215, 12078, 8994, 12650, 16452, 16452],
      47201: [12287, 12150, 9066, 12713, 16524, 16524],
      47401: [12359, 12222, 9138, 12776, 16596, 16596],
      47601: [12431, 12294, 9210, 12839, 16668, 16668],
      47801: [12503, 12366, 9282, 12902, 16740, 16740],
      48001: [12575, 12438, 9354, 12965, 16812, 16812],
      48201: [12647, 12510, 9426, 13028, 16884, 16884],
      48401: [12719, 12582, 9498, 13091, 16956, 16956],
      48601: [12791, 12654, 9570, 13154, 17028, 17028],
      48801: [12863, 12726, 9642, 13217, 17100, 17100],
      49001: [12935, 12798, 9714, 13280, 17172, 17172],
      49201: [13007, 12870, 9786, 13344, 17244, 17244],
      49401: [13079, 12942, 9858, 13407, 17316, 17316],
      49601: [13151, 13014, 9930, 13470, 17388, 17388],
      49801: [13223, 13086, 10002, 13533, 17460, 17460],
      50001: [13295, 13158, 10074, 13596, 17532, 17532],
      50201: [13367, 13230, 10146, 13659, 17604, 17604],
      50401: [13439, 13302, 10218, 13722, 17676, 17676],
      50601: [13511, 13374, 10290, 13785, 17748, 17748],
      50801: [13583, 13446, 10362, 13848, 17820, 17820],
      51001: [13655, 13518, 10434, 13911, 17892, 17892],
      51201: [13727, 13590, 10506, 13974, 17964, 17964],
      51401: [13799, 13662, 10578, 14037, 18036, 18036],
      51601: [13871, 13734, 10650, 14101, 18108, 18108],
      51801: [13943, 13806, 10722, 14164, 18180, 18180],
      52001: [14015, 13878, 10794, 14227, 18252, 18252],
      52201: [14087, 13950, 10866, 14290, 18324, 18324],
      52401: [14159, 14022, 10938, 14353, 18396, 18396],
      52601: [14231, 14094, 11010, 14416, 18468, 18468],
      52801: [14303, 14166, 11082, 14479, 18540, 18540],
      53001: [14375, 14238, 11154, 14542, 18612, 18612],
      53201: [14447, 14310, 11226, 14605, 18684, 18684],
      53401: [14520, 14382, 11298, 14670, 18757, 18757],
      53601: [14632, 14496, 11412, 14773, 18869, 18869],
      53801: [14744, 14613, 11529, 14876, 18981, 18981],
      54001: [14856, 14730, 11646, 14979, 19093, 19093],
      54201: [14968, 14847, 11763, 15082, 19205, 19205],
      54401: [15080, 14964, 11880, 15185, 19317, 19317],
      54601: [15192, 15081, 11997, 15288, 19429, 19429],
      54801: [15304, 15198, 12114, 15391, 19541, 19541],
      55001: [15416, 15312, 12228, 15495, 19653, 19653],
      55201: [15529, 15429, 12345, 15598, 19765, 19765],
      55401: [15640, 15546, 12462, 15701, 19877, 19877],
      55601: [15752, 15663, 12579, 15804, 19989, 19989],
      55801: [15864, 15780, 12696, 15907, 20101, 20101],
      56001: [15976, 15897, 12813, 16010, 20213, 20213],
      56201: [16088, 16014, 12930, 16113, 20325, 20325],
      56401: [16200, 16131, 13047, 16216, 20437, 20437],
      56601: [16312, 16245, 13161, 16319, 20549, 20549],
      56801: [16424, 16362, 13278, 16422, 20661, 20661],
      57001: [16536, 16479, 13395, 16525, 20773, 20773],
      57201: [16648, 16596, 13512, 16628, 20885, 20885],
      57401: [16760, 16713, 13629, 16732, 20997, 20997],
      57601: [16872, 16830, 13746, 16835, 21109, 21109],
      57801: [16984, 16947, 13863, 16938, 21221, 21221],
      58001: [17096, 17064, 13980, 17041, 21333, 21333],
      58201: [17208, 17181, 14097, 17144, 21445, 21445],
      58401: [17320, 17295, 14211, 17247, 21557, 21557],
      58601: [17432, 17412, 14328, 17350, 21669, 21669],
      58801: [17544, 17529, 14445, 17453, 21781, 21781],
      59001: [17656, 17646, 14562, 17556, 21893, 21893],
      59201: [17768, 17763, 14679, 17659, 22005, 22005],
      59401: [17880, 17880, 14796, 17762, 22117, 22117],
      59601: [17992, 17997, 14913, 17865, 22229, 22229],
      59801: [18104, 18114, 15030, 17969, 22341, 22341],
      60001: [18216, 18228, 15144, 18072, 22453, 22453],
      60201: [18329, 18345, 15261, 18175, 22565, 22565],
      60401: [18440, 18462, 15378, 18278, 22677, 22677],
      60601: [18552, 18579, 15495, 18381, 22789, 22789],
      60801: [18664, 18696, 15612, 18484, 22901, 22901],
      61001: [18776, 18809, 15725, 18587, 23013, 23013],
      61201: [18888, 18921, 15837, 18690, 23125, 23125],
      61401: [19e3, 19033, 15949, 18793, 23237, 23237],
      61601: [19112, 19145, 16061, 18896, 23349, 23349],
      61801: [19224, 19257, 16173, 18999, 23461, 23461],
      62001: [19336, 19369, 16285, 19102, 23573, 23573],
      62201: [19448, 19481, 16397, 19205, 23685, 23685],
      62401: [19560, 19593, 16509, 19309, 23797, 23797],
      62601: [19672, 19705, 16621, 19412, 23909, 23909],
      62801: [19784, 19817, 16733, 19515, 24021, 24021],
      63001: [19896, 19929, 16845, 19618, 24133, 24133],
      63201: [20008, 20041, 16957, 19721, 24245, 24245],
      63401: [20120, 20153, 17069, 19824, 24357, 24357],
      63601: [20232, 20265, 17181, 19927, 24469, 24469],
      63801: [20344, 20377, 17293, 20030, 24581, 24581],
      64001: [20456, 20489, 17405, 20133, 24693, 24693],
      64201: [20568, 20601, 17517, 20236, 24805, 24805],
      64401: [20680, 20713, 17629, 20339, 24917, 24917],
      64601: [20792, 20825, 17741, 20442, 25029, 25029],
      64801: [20904, 20937, 17853, 20546, 25141, 25141],
      65001: [21016, 21049, 17965, 20649, 25253, 25253],
      65201: [21129, 21161, 18077, 20752, 25365, 25365],
      65401: [21240, 21273, 18189, 20855, 25477, 25477],
      65601: [21352, 21385, 18301, 20958, 25589, 25589],
      65801: [21464, 21497, 18413, 21061, 25701, 25701],
      66001: [21576, 21609, 18525, 21164, 25813, 25813],
      66201: [21688, 21721, 18637, 21267, 25925, 25925],
      66401: [21800, 21833, 18749, 21370, 26037, 26037],
      66601: [21912, 21945, 18861, 21473, 26149, 26149],
      66801: [22024, 22057, 18973, 21576, 26261, 26261],
      67001: [22136, 22169, 19085, 21679, 26373, 26373],
      67201: [22248, 22281, 19197, 21783, 26485, 26485],
      67401: [22360, 22393, 19309, 21886, 26597, 26597],
      67601: [22472, 22505, 19421, 21989, 26709, 26709],
      67801: [22584, 22617, 19533, 22092, 26821, 26821],
      68001: [22696, 22729, 19645, 22195, 26933, 26933],
      68201: [22808, 22841, 19757, 22298, 27045, 27045],
      68401: [22920, 22953, 19869, 22401, 27157, 27157],
      68601: [23032, 23065, 19981, 22504, 27269, 27269],
      68801: [23144, 23177, 20093, 22607, 27381, 27381],
      69001: [23256, 23289, 20205, 22710, 27493, 27493],
      69201: [23368, 23401, 20317, 22813, 27605, 27605],
      69401: [23480, 23513, 20429, 22916, 27717, 27717],
      69601: [23592, 23625, 20541, 23019, 27829, 27829],
      69801: [23704, 23737, 20653, 23123, 27941, 27941],
      70001: [23816, 23849, 20765, 23226, 28053, 28053],
      70201: [23929, 23961, 20877, 23329, 28165, 28165],
      70401: [24040, 24073, 20989, 23432, 28277, 28277],
      70601: [24152, 24185, 21101, 23535, 28389, 28389],
      70801: [24264, 24297, 21213, 23638, 28501, 28501],
      71001: [24376, 24409, 21325, 23741, 28613, 28613],
      71201: [24488, 24521, 21437, 23844, 28725, 28725],
      71401: [24600, 24633, 21549, 23947, 28837, 28837],
      71601: [24712, 24745, 21661, 24050, 28949, 28949],
      71801: [24824, 24857, 21773, 24153, 29061, 29061],
      72001: [24936, 24969, 21885, 24256, 29173, 29173],
      72201: [25048, 25081, 21997, 24360, 29285, 29285],
      72401: [25160, 25193, 22109, 24463, 29397, 29397],
      72601: [25272, 25305, 22221, 24566, 29509, 29509],
      72801: [25384, 25417, 22333, 24669, 29621, 29621],
      73001: [25496, 25529, 22445, 24772, 29733, 29733],
      73201: [25608, 25641, 22557, 24875, 29845, 29845],
      73401: [25720, 25753, 22669, 24978, 29957, 29957],
      73601: [25832, 25865, 22781, 25081, 30069, 30069],
      73801: [25944, 25977, 22893, 25184, 30181, 30181],
      74001: [26056, 26089, 23005, 25287, 30293, 30293],
      74201: [26168, 26201, 23117, 25390, 30405, 30405],
      74401: [26280, 26313, 23229, 25493, 30517, 30517],
      74601: [26392, 26425, 23341, 25597, 30629, 30629],
      74801: [26504, 26537, 23453, 25700, 30741, 30741],
      75001: [26616, 26649, 23565, 25803, 30853, 30853],
      75201: [26729, 26761, 23677, 25906, 30965, 30965],
      75401: [26840, 26873, 23789, 26009, 31077, 31077],
      75601: [26952, 26985, 23901, 26112, 31189, 31189],
      75801: [27064, 27097, 24013, 26215, 31301, 31301],
      76001: [27176, 27209, 24125, 26318, 31413, 31413],
      76201: [27288, 27321, 24237, 26421, 31525, 31525],
      76401: [27400, 27433, 24349, 26524, 31637, 31637],
      76601: [27512, 27545, 24461, 26627, 31749, 31749],
      76801: [27624, 27657, 24573, 26730, 31861, 31861],
      77001: [27736, 27769, 24685, 26833, 31973, 31973],
      77201: [27848, 27881, 24797, 26937, 32085, 32085],
      77401: [27960, 27993, 24909, 27040, 32197, 32197],
      77601: [28072, 28105, 25021, 27143, 32309, 32309],
      77801: [28184, 28217, 25133, 27246, 32421, 32421],
      78001: [28296, 28329, 25245, 27349, 32533, 32533],
      78201: [28408, 28441, 25357, 27452, 32645, 32645],
      78401: [28520, 28553, 25469, 27555, 32757, 32757],
      78601: [28632, 28665, 25581, 27658, 32869, 32869],
      78801: [28744, 28777, 25693, 27761, 32981, 32981],
      79001: [28856, 28889, 25805, 27864, 33093, 33093],
      79201: [28968, 29001, 25917, 27967, 33205, 33205],
      79401: [29080, 29113, 26029, 28070, 33317, 33317],
      79601: [29192, 29225, 26141, 28174, 33429, 33429],
      79801: [29304, 29337, 26253, 28277, 33541, 33541],
      80001: [37, 37, 33, 35, 42, 42, "%"],
      80601: [37, 37, 33, 36, 42, 42, "%"],
      82401: [37, 37, 34, 36, 42, 42, "%"],
      83201: [37, 37, 34, 36, 43, 43, "%"],
      83401: [37, 38, 34, 36, 43, 43, "%"],
      83601: [38, 38, 34, 36, 43, 43, "%"],
      86001: [38, 38, 34, 37, 43, 43, "%"],
      86201: [38, 38, 35, 37, 43, 43, "%"],
      88201: [38, 39, 35, 37, 43, 43, "%"],
      88401: [39, 39, 35, 37, 43, 43, "%"],
      90001: [39, 39, 35, 37, 44, 44, "%"],
      90401: [39, 39, 36, 37, 44, 44, "%"],
      92201: [39, 39, 36, 38, 44, 44, "%"],
      93601: [39, 40, 36, 38, 44, 44, "%"],
      93801: [40, 40, 36, 38, 44, 44, "%"],
      95001: [40, 40, 37, 38, 44, 44, "%"],
      97801: [40, 40, 37, 38, 45, 45, "%"],
      99201: [40, 40, 37, 39, 45, 45, "%"],
      99601: [40, 41, 37, 39, 45, 45, "%"],
      99801: [41, 41, 37, 39, 45, 45, "%"],
      100201: [41, 41, 38, 39, 45, 45, "%"],
      105801: [41, 41, 39, 39, 45, 45, "%"],
      106601: [41, 42, 39, 39, 45, 45, "%"],
      106801: [42, 42, 39, 39, 45, 45, "%"],
      107201: [42, 42, 39, 39, 46, 46, "%"],
      107401: [42, 42, 39, 40, 46, 46, "%"],
      112401: [42, 42, 40, 40, 46, 46, "%"],
      114401: [42, 43, 40, 40, 46, 46, "%"],
      114601: [43, 43, 40, 40, 46, 46, "%"],
      117201: [43, 43, 40, 41, 46, 46, "%"],
      118401: [43, 43, 40, 41, 47, 47, "%"],
      119601: [43, 43, 41, 41, 47, 47, "%"],
      123601: [43, 44, 41, 41, 47, 47, "%"],
      123801: [44, 44, 41, 41, 47, 47, "%"],
      127801: [44, 44, 42, 41, 47, 47, "%"],
      129001: [44, 44, 42, 42, 47, 47, "%"],
      132401: [44, 44, 42, 42, 48, 48, "%"],
      134401: [44, 45, 42, 42, 48, 48, "%"],
      134601: [45, 45, 42, 42, 48, 48, "%"],
      137201: [45, 45, 43, 42, 48, 48, "%"],
      143201: [45, 45, 43, 43, 48, 48, "%"],
      147201: [45, 46, 43, 43, 48, 48, "%"],
      147401: [46, 46, 43, 43, 48, 48, "%"],
      148201: [46, 46, 44, 43, 48, 48, "%"],
      150001: [46, 46, 44, 43, 49, 49, "%"],
      161001: [46, 46, 44, 44, 49, 49, "%"],
      161201: [46, 46, 45, 44, 49, 49, "%"],
      162601: [46, 47, 45, 44, 49, 49, "%"],
      163001: [47, 47, 45, 44, 49, 49, "%"],
      173201: [47, 47, 45, 44, 50, 50, "%"],
      176601: [47, 47, 46, 44, 50, 50, "%"],
      181801: [47, 48, 46, 44, 50, 50, "%"],
      182201: [48, 48, 46, 44, 50, 50, "%"],
      184001: [48, 48, 46, 45, 50, 50, "%"],
      195201: [48, 48, 47, 45, 50, 50, "%"],
      204601: [48, 48, 47, 45, 51, 51, "%"],
      206001: [48, 49, 47, 45, 51, 51, "%"],
      206601: [49, 49, 47, 45, 51, 51, "%"],
      214401: [49, 49, 47, 46, 51, 51, "%"],
      218001: [49, 49, 48, 46, 51, 51, "%"],
      237801: [49, 50, 48, 46, 51, 51, "%"],
      238201: [50, 50, 48, 46, 51, 51, "%"],
      247201: [50, 50, 49, 46, 51, 51, "%"],
      250001: [50, 50, 49, 46, 52, 52, "%"],
      257001: [50, 50, 49, 47, 52, 52, "%"],
      281001: [50, 51, 49, 47, 52, 52, "%"],
      281601: [51, 51, 49, 47, 52, 52, "%"],
      285201: [51, 51, 50, 47, 52, 52, "%"],
      320601: [51, 51, 50, 48, 52, 52, "%"],
      321601: [51, 51, 50, 48, 53, 53, "%"],
      337201: [51, 51, 51, 48, 53, 53, "%"],
      343601: [51, 52, 51, 48, 53, 53, "%"],
      344201: [52, 52, 51, 48, 53, 53, "%"],
      412001: [52, 52, 52, 48, 53, 53, "%"],
      426001: [52, 52, 52, 49, 53, 53, "%"],
      441601: [52, 53, 52, 49, 53, 53, "%"],
      442601: [53, 53, 52, 49, 53, 53, "%"],
      450201: [53, 53, 52, 49, 54, 54, "%"],
      529801: [53, 53, 53, 49, 54, 54, "%"],
      618401: [53, 54, 53, 49, 54, 54, "%"],
      619801: [54, 54, 53, 49, 54, 54, "%"],
      634801: [54, 54, 53, 50, 54, 54, "%"],
      741801: [54, 54, 54, 50, 54, 54, "%"],
      750401: [54, 54, 54, 50, 55, 55, "%"],
      1030801: [54, 55, 54, 50, 55, 55, "%"],
      1033001: [55, 55, 54, 50, 55, 55, "%"],
      1236401: [55, 55, 55, 50, 55, 55, "%"],
      1245001: [55, 55, 55, 51, 55, 55, "%"]
    },
    37: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 9],
      2101: [152, 0, 150, 2, 152, 47],
      2201: [163, 0, 158, 4, 163, 85],
      2301: [173, 0, 166, 7, 173, 123],
      2401: [184, 0, 175, 9, 184, 161],
      2501: [194, 0, 183, 11, 199, 199],
      2601: [205, 0, 191, 13, 237, 237],
      2701: [215, 0, 200, 15, 275, 275],
      2801: [217, 0, 200, 17, 313, 313],
      2901: [228, 0, 208, 19, 351, 351],
      3001: [238, 0, 216, 22, 389, 389],
      3101: [249, 0, 225, 24, 427, 427],
      3201: [259, 0, 233, 26, 465, 465],
      3301: [270, 0, 241, 28, 503, 503],
      3401: [272, 0, 241, 30, 541, 541],
      3501: [282, 0, 250, 32, 579, 579],
      3601: [293, 0, 258, 35, 617, 617],
      3701: [303, 0, 266, 37, 655, 655],
      3801: [314, 0, 275, 39, 693, 693],
      3901: [324, 0, 283, 41, 731, 731],
      4001: [327, 0, 283, 43, 769, 769],
      4101: [337, 0, 291, 45, 807, 807],
      4201: [348, 0, 300, 48, 845, 845],
      4301: [358, 0, 308, 50, 883, 883],
      4401: [369, 0, 316, 62, 921, 921],
      4501: [379, 0, 325, 87, 959, 959],
      4601: [381, 0, 325, 113, 997, 997],
      4701: [392, 0, 333, 139, 1035, 1035],
      4801: [402, 0, 341, 165, 1070, 1070],
      4901: [412, 0, 350, 190, 1098, 1098],
      5001: [422, 0, 358, 215, 1130, 1130],
      5101: [432, 0, 366, 241, 1158, 1158],
      5201: [442, 0, 375, 266, 1190, 1190],
      5301: [444, 0, 375, 291, 1222, 1222],
      5401: [454, 15, 384, 317, 1250, 1250],
      5501: [464, 44, 394, 342, 1282, 1282],
      5601: [474, 72, 404, 367, 1310, 1310],
      5701: [484, 101, 414, 391, 1341, 1341],
      5801: [494, 126, 423, 416, 1372, 1372],
      5901: [496, 155, 425, 441, 1400, 1400],
      6001: [506, 183, 435, 466, 1431, 1431],
      6101: [516, 212, 445, 490, 1459, 1459],
      6201: [526, 240, 455, 515, 1490, 1490],
      6301: [536, 269, 465, 540, 1521, 1521],
      6401: [546, 297, 475, 565, 1549, 1549],
      6501: [553, 326, 476, 589, 1580, 1580],
      6601: [576, 354, 486, 614, 1608, 1608],
      6701: [600, 383, 496, 639, 1639, 1639],
      6801: [623, 411, 506, 664, 1670, 1670],
      6901: [646, 440, 516, 688, 1698, 1698],
      7001: [669, 465, 526, 713, 1729, 1729],
      7101: [692, 493, 528, 738, 1757, 1757],
      7201: [715, 522, 538, 763, 1788, 1788],
      7301: [738, 550, 547, 788, 1819, 1819],
      7401: [761, 579, 557, 812, 1847, 1847],
      7501: [784, 607, 567, 837, 1878, 1878],
      7601: [807, 636, 577, 862, 1906, 1906],
      7701: [830, 664, 587, 887, 1937, 1937],
      7801: [854, 693, 589, 911, 1968, 1968],
      7901: [877, 721, 599, 936, 1996, 1996],
      8001: [900, 750, 609, 961, 2027, 2027],
      8101: [923, 778, 619, 986, 2055, 2055],
      8201: [946, 804, 629, 1010, 2086, 2086],
      8301: [969, 832, 639, 1035, 2117, 2117],
      8401: [992, 861, 640, 1060, 2145, 2145],
      8501: [1015, 889, 650, 1085, 2176, 2176],
      8601: [1038, 918, 660, 1109, 2204, 2204],
      8701: [1061, 946, 670, 1134, 2235, 2235],
      8801: [1084, 975, 680, 1159, 2266, 2266],
      8901: [1107, 1003, 690, 1184, 2294, 2294],
      9001: [1131, 1032, 692, 1209, 2325, 2325],
      9101: [1154, 1060, 701, 1233, 2353, 2353],
      9201: [1177, 1089, 711, 1258, 2384, 2384],
      9301: [1200, 1117, 721, 1283, 2415, 2415],
      9401: [1223, 1143, 731, 1308, 2443, 2443],
      9501: [1246, 1171, 741, 1332, 2474, 2474],
      9601: [1269, 1190, 742, 1357, 2502, 2502],
      9701: [1292, 1212, 752, 1382, 2533, 2533],
      9801: [1315, 1234, 761, 1407, 2564, 2564],
      9901: [1338, 1260, 771, 1431, 2592, 2592],
      10001: [1361, 1282, 781, 1456, 2623, 2623],
      10101: [1384, 1303, 790, 1481, 2651, 2651],
      10201: [1408, 1325, 800, 1506, 2682, 2682],
      10301: [1431, 1350, 801, 1531, 2713, 2713],
      10401: [1454, 1372, 811, 1555, 2741, 2741],
      10501: [1477, 1393, 821, 1580, 2772, 2772],
      10601: [1500, 1415, 830, 1605, 2800, 2800],
      10701: [1523, 1440, 840, 1630, 2831, 2831],
      10801: [1546, 1462, 850, 1654, 2862, 2862],
      10901: [1569, 1483, 851, 1679, 2890, 2890],
      11001: [1592, 1505, 860, 1704, 2921, 2921],
      11101: [1615, 1530, 870, 1729, 2949, 2949],
      11201: [1638, 1552, 880, 1753, 2980, 2980],
      11301: [1662, 1573, 889, 1778, 3011, 3011],
      11401: [1685, 1595, 899, 1803, 3039, 3039],
      11501: [1708, 1620, 900, 1828, 3070, 3070],
      11601: [1731, 1642, 910, 1852, 3098, 3098],
      11701: [1754, 1663, 920, 1877, 3129, 3129],
      11801: [1777, 1685, 929, 1902, 3160, 3160],
      11901: [1800, 1710, 939, 1927, 3188, 3188],
      12001: [1823, 1732, 949, 1952, 3219, 3219],
      12101: [1846, 1754, 950, 1976, 3247, 3247],
      12201: [1869, 1778, 960, 2001, 3278, 3278],
      12301: [1892, 1800, 969, 2026, 3309, 3309],
      12401: [1915, 1822, 979, 2051, 3337, 3337],
      12501: [1939, 1844, 988, 2075, 3368, 3368],
      12601: [1962, 1868, 998, 2100, 3396, 3396],
      12701: [1985, 1890, 1008, 2125, 3427, 3427],
      12801: [2008, 1912, 1009, 2150, 3458, 3458],
      12901: [2031, 1934, 1019, 2174, 3486, 3486],
      13001: [2054, 1958, 1028, 2199, 3517, 3517],
      13101: [2077, 1980, 1038, 2224, 3545, 3545],
      13201: [2100, 2002, 1047, 2249, 3576, 3576],
      13301: [2123, 2024, 1057, 2274, 3610, 3610],
      13401: [2147, 2048, 1059, 2299, 3647, 3647],
      13501: [2170, 2070, 1068, 2324, 3684, 3684],
      13601: [2193, 2092, 1078, 2349, 3722, 3722],
      13701: [2217, 2114, 1087, 2374, 3759, 3759],
      13801: [2240, 2138, 1097, 2399, 3796, 3796],
      13901: [2263, 2160, 1107, 2424, 3833, 3833],
      14001: [2287, 2182, 1108, 2449, 3871, 3871],
      14101: [2310, 2204, 1118, 2474, 3908, 3908],
      14201: [2332, 2228, 1127, 2498, 3944, 3944],
      14301: [2355, 2250, 1137, 2522, 3980, 3980],
      14401: [2377, 2272, 1146, 2546, 4016, 4016],
      14501: [2400, 2297, 1156, 2570, 4053, 4053],
      14601: [2422, 2318, 1158, 2594, 4089, 4089],
      14701: [2444, 2340, 1167, 2618, 4125, 4125],
      14801: [2467, 2362, 1177, 2642, 4161, 4161],
      14901: [2489, 2387, 1186, 2666, 4198, 4198],
      15001: [2511, 2408, 1196, 2690, 4234, 4234],
      15101: [2534, 2430, 1206, 2714, 4270, 4270],
      15201: [2556, 2452, 1215, 2738, 4306, 4306],
      15301: [2579, 2477, 1217, 2762, 4349, 4349],
      15401: [2601, 2499, 1226, 2787, 4388, 4388],
      15501: [2623, 2520, 1236, 2811, 4427, 4427],
      15601: [2646, 2542, 1245, 2835, 4466, 4466],
      15701: [2668, 2567, 1255, 2859, 4506, 4506],
      15801: [2692, 2589, 1265, 2885, 4548, 4548],
      15901: [2721, 2620, 1267, 2917, 4587, 4587],
      16001: [2750, 2648, 1277, 2949, 4627, 4627],
      16101: [2779, 2679, 1287, 2980, 4666, 4666],
      16201: [2808, 2706, 1297, 3012, 4705, 4705],
      16301: [2837, 2738, 1307, 3044, 4747, 4747],
      16401: [2866, 2769, 1317, 3076, 4787, 4787],
      16501: [2896, 2797, 1319, 3107, 4826, 4826],
      16601: [2925, 2828, 1329, 3139, 4865, 4865],
      16701: [2954, 2855, 1339, 3171, 4905, 4905],
      16801: [2983, 2887, 1349, 3202, 4947, 4947],
      16901: [3012, 2914, 1359, 3234, 4986, 4986],
      17001: [3041, 2946, 1369, 3266, 5025, 5025],
      17101: [3071, 2974, 1371, 3297, 5065, 5065],
      17201: [3100, 3004, 1381, 3329, 5104, 5104],
      17301: [3129, 3032, 1391, 3361, 5146, 5146],
      17401: [3158, 3063, 1401, 3393, 5185, 5185],
      17501: [3187, 3095, 1411, 3424, 5225, 5225],
      17601: [3216, 3122, 1421, 3456, 5264, 5264],
      17701: [3246, 3153, 1431, 3488, 5303, 5303],
      17801: [3275, 3181, 1433, 3519, 5346, 5346],
      17901: [3304, 3212, 1443, 3551, 5385, 5385],
      18001: [3333, 3240, 1453, 3583, 5424, 5424],
      18101: [3362, 3271, 1463, 3614, 5463, 5463],
      18201: [3391, 3299, 1473, 3646, 5503, 5503],
      18301: [3421, 3330, 1483, 3678, 5545, 5545],
      18401: [3450, 3362, 1485, 3710, 5584, 5584],
      18501: [3479, 3389, 1495, 3741, 5623, 5623],
      18601: [3508, 3420, 1505, 3773, 5663, 5663],
      18701: [3537, 3448, 1515, 3805, 5702, 5702],
      18801: [3566, 3479, 1525, 3836, 5744, 5744],
      18901: [3595, 3507, 1535, 3868, 5784, 5784],
      19001: [3625, 3538, 1537, 3900, 5823, 5823],
      19101: [3654, 3566, 1547, 3932, 5862, 5862],
      19201: [3683, 3597, 1557, 3963, 5901, 5901],
      19301: [3712, 3628, 1567, 3995, 5944, 5944],
      19401: [3741, 3656, 1577, 4027, 5983, 5983],
      19501: [3770, 3687, 1587, 4058, 6022, 6022],
      19601: [3800, 3715, 1589, 4090, 6061, 6061],
      19701: [3829, 3746, 1599, 4122, 6101, 6101],
      19801: [3858, 3774, 1609, 4153, 6143, 6143],
      19901: [3887, 3805, 1619, 4185, 6182, 6182],
      20001: [3945, 3864, 1639, 4249, 6261, 6261],
      20201: [4004, 3923, 1650, 4312, 6342, 6342],
      20401: [4062, 3980, 1669, 4375, 6421, 6421],
      20601: [4120, 4038, 1687, 4439, 6500, 6500],
      20801: [4179, 4095, 1697, 4502, 6581, 6581],
      21001: [4237, 4152, 1716, 4566, 6660, 6660],
      21201: [4295, 4213, 1734, 4629, 6741, 6741],
      21401: [4354, 4270, 1744, 4692, 6820, 6820],
      21601: [4412, 4328, 1763, 4756, 6898, 6898],
      21801: [4470, 4385, 1781, 4819, 6980, 6980],
      22001: [4529, 4442, 1792, 4883, 7058, 7058],
      22201: [4587, 4500, 1810, 4946, 7140, 7140],
      22401: [4645, 4557, 1829, 5009, 7218, 7218],
      22601: [4703, 4615, 1847, 5073, 7297, 7297],
      22801: [4762, 4672, 1857, 5136, 7379, 7379],
      23001: [4822, 4729, 1876, 5201, 7459, 7459],
      23201: [4882, 4790, 1894, 5266, 7542, 7542],
      23401: [4942, 4847, 1905, 5331, 7622, 7622],
      23601: [5002, 4905, 1947, 5396, 7702, 7702],
      23801: [5062, 4962, 1991, 5462, 7786, 7786],
      24001: [5122, 5019, 2034, 5527, 7866, 7866],
      24201: [5182, 5077, 2078, 5592, 7949, 7949],
      24401: [5241, 5134, 2121, 5657, 8029, 8029],
      24601: [5301, 5195, 2167, 5722, 8109, 8109],
      24801: [5361, 5255, 2214, 5787, 8193, 8193],
      25001: [5421, 5312, 2257, 5852, 8273, 8273],
      25201: [5481, 5373, 2304, 5917, 8356, 8356],
      25401: [5541, 5430, 2347, 5982, 8436, 8436],
      25601: [5601, 5491, 2407, 6047, 8516, 8516],
      25801: [5661, 5548, 2464, 6112, 8600, 8600],
      26001: [5721, 5608, 2525, 6177, 8680, 8680],
      26201: [5781, 5666, 2582, 6242, 8763, 8763],
      26401: [5841, 5726, 2643, 6307, 8843, 8843],
      26601: [5901, 5784, 2700, 6372, 8923, 8923],
      26801: [5961, 5844, 2760, 6437, 9007, 9007],
      27001: [6021, 5901, 2818, 6502, 9087, 9087],
      27201: [6081, 5962, 2878, 6568, 9170, 9170],
      27401: [6141, 6019, 2936, 6633, 9250, 9250],
      27601: [6201, 6080, 2996, 6698, 9330, 9330],
      27801: [6261, 6140, 3057, 6763, 9414, 9414],
      28001: [6321, 6197, 3114, 6828, 9494, 9494],
      28201: [6381, 6258, 3174, 6893, 9577, 9577],
      28401: [6441, 6315, 3232, 6958, 9657, 9657],
      28601: [6501, 6376, 3292, 7023, 9737, 9737],
      28801: [6561, 6433, 3350, 7088, 9821, 9821],
      29001: [6621, 6493, 3410, 7153, 9901, 9901],
      29201: [6681, 6551, 3467, 7218, 9984, 9984],
      29401: [6741, 6611, 3528, 7283, 10064, 10064],
      29601: [6801, 6669, 3585, 7348, 10144, 10144],
      29801: [6861, 6729, 3646, 7413, 10228, 10228],
      30001: [6921, 6786, 3703, 7478, 10308, 10308],
      30201: [6981, 6847, 3763, 7543, 10391, 10391],
      30401: [7041, 6907, 3824, 7608, 10471, 10471],
      30601: [7100, 6965, 3881, 7673, 10551, 10551],
      30801: [7160, 7025, 3942, 7739, 10635, 10635],
      31001: [7220, 7083, 3999, 7804, 10715, 10715],
      31201: [7280, 7143, 4059, 7869, 10798, 10798],
      31401: [7340, 7200, 4117, 7934, 10878, 10878],
      31601: [7400, 7261, 4177, 7999, 10958, 10958],
      31801: [7460, 7318, 4235, 8064, 11042, 11042],
      32001: [7520, 7379, 4295, 8129, 11122, 11122],
      32201: [7580, 7437, 4354, 8194, 11205, 11205],
      32401: [7640, 7499, 4415, 8259, 11285, 11285],
      32601: [7700, 7557, 4474, 8324, 11365, 11365],
      32801: [7760, 7619, 4536, 8389, 11449, 11449],
      33001: [7820, 7678, 4594, 8454, 11529, 11529],
      33201: [7880, 7739, 4656, 8519, 11612, 11612],
      33401: [7940, 7801, 4717, 8584, 11692, 11692],
      33601: [8e3, 7860, 4776, 8649, 11772, 11772],
      33801: [8060, 7921, 4838, 8714, 11856, 11856],
      34001: [8120, 7980, 4896, 8779, 11936, 11936],
      34201: [8180, 8041, 4958, 8844, 12019, 12019],
      34401: [8240, 8100, 5017, 8910, 12099, 12099],
      34601: [8300, 8162, 5078, 8975, 12179, 12179],
      34801: [8360, 8220, 5137, 9040, 12263, 12263],
      35001: [8420, 8282, 5198, 9105, 12343, 12343],
      35201: [8480, 8341, 5257, 9170, 12426, 12426],
      35401: [8540, 8402, 5319, 9235, 12506, 12506],
      35601: [8600, 8461, 5377, 9300, 12586, 12586],
      35801: [8660, 8522, 5439, 9365, 12670, 12670],
      36001: [8720, 8584, 5501, 9430, 12750, 12750],
      36201: [8780, 8643, 5559, 9495, 12833, 12833],
      36401: [8840, 8704, 5621, 9560, 12913, 12913],
      36601: [8899, 8763, 5679, 9625, 12993, 12993],
      36801: [8960, 8825, 5741, 9690, 13077, 13077],
      37001: [9019, 8883, 5800, 9755, 13157, 13157],
      37201: [9079, 8945, 5861, 9820, 13240, 13240],
      37401: [9139, 9003, 5920, 9885, 13320, 13320],
      37601: [9199, 9065, 5982, 9950, 13400, 13400],
      37801: [9259, 9124, 6040, 10015, 13484, 13484],
      38001: [9319, 9185, 6102, 10081, 13564, 13564],
      38201: [9379, 9244, 6160, 10146, 13647, 13647],
      38401: [9439, 9306, 6222, 10211, 13727, 13727],
      38601: [9499, 9364, 6281, 10275, 13801, 13801],
      38801: [9559, 9423, 6339, 10340, 13875, 13875],
      39001: [9618, 9481, 6398, 10405, 13949, 13949],
      39201: [9678, 9543, 6460, 10470, 14023, 14023],
      39401: [9739, 9602, 6518, 10535, 14097, 14097],
      39601: [9813, 9676, 6592, 10600, 14171, 14171],
      39801: [9887, 9750, 6666, 10664, 14245, 14245],
      40001: [9961, 9824, 6740, 10729, 14319, 14319],
      40201: [10035, 9898, 6814, 10794, 14393, 14393],
      40401: [10109, 9972, 6888, 10859, 14467, 14467],
      40601: [10183, 10046, 6962, 10924, 14541, 14541],
      40801: [10257, 10120, 7036, 10989, 14615, 14615],
      41001: [10331, 10194, 7110, 11053, 14689, 14689],
      41201: [10405, 10268, 7184, 11118, 14763, 14763],
      41401: [10479, 10342, 7258, 11183, 14837, 14837],
      41601: [10553, 10416, 7332, 11248, 14911, 14911],
      41801: [10627, 10490, 7406, 11313, 14985, 14985],
      42001: [10701, 10564, 7480, 11378, 15059, 15059],
      42201: [10775, 10638, 7554, 11442, 15133, 15133],
      42401: [10849, 10712, 7628, 11507, 15207, 15207],
      42601: [10923, 10786, 7702, 11572, 15281, 15281],
      42801: [10997, 10860, 7776, 11637, 15355, 15355],
      43001: [11071, 10934, 7850, 11702, 15429, 15429],
      43201: [11145, 11008, 7924, 11766, 15503, 15503],
      43401: [11219, 11082, 7998, 11831, 15577, 15577],
      43601: [11293, 11156, 8072, 11896, 15651, 15651],
      43801: [11367, 11230, 8146, 11961, 15725, 15725],
      44001: [11441, 11304, 8220, 12026, 15799, 15799],
      44201: [11515, 11378, 8294, 12091, 15873, 15873],
      44401: [11589, 11452, 8368, 12155, 15947, 15947],
      44601: [11663, 11526, 8442, 12220, 16021, 16021],
      44801: [11737, 11600, 8516, 12285, 16095, 16095],
      45001: [11811, 11674, 8590, 12350, 16169, 16169],
      45201: [11885, 11748, 8664, 12415, 16243, 16243],
      45401: [11959, 11822, 8738, 12480, 16317, 16317],
      45601: [12033, 11896, 8812, 12544, 16391, 16391],
      45801: [12107, 11970, 8886, 12609, 16465, 16465],
      46001: [12181, 12044, 8960, 12674, 16539, 16539],
      46201: [12255, 12118, 9034, 12739, 16613, 16613],
      46401: [12329, 12192, 9108, 12804, 16687, 16687],
      46601: [12403, 12266, 9182, 12868, 16761, 16761],
      46801: [12477, 12340, 9256, 12933, 16835, 16835],
      47001: [12551, 12414, 9330, 12998, 16909, 16909],
      47201: [12625, 12488, 9404, 13063, 16983, 16983],
      47401: [12699, 12562, 9478, 13128, 17057, 17057],
      47601: [12773, 12636, 9552, 13193, 17131, 17131],
      47801: [12847, 12710, 9626, 13257, 17205, 17205],
      48001: [12921, 12784, 9700, 13322, 17279, 17279],
      48201: [12995, 12858, 9774, 13387, 17353, 17353],
      48401: [13069, 12932, 9848, 13452, 17427, 17427],
      48601: [13143, 13006, 9922, 13517, 17501, 17501],
      48801: [13217, 13080, 9996, 13582, 17575, 17575],
      49001: [13291, 13154, 10070, 13646, 17649, 17649],
      49201: [13365, 13228, 10144, 13711, 17723, 17723],
      49401: [13439, 13302, 10218, 13776, 17797, 17797],
      49601: [13513, 13376, 10292, 13841, 17871, 17871],
      49801: [13587, 13450, 10366, 13906, 17945, 17945],
      50001: [13661, 13524, 10440, 13970, 18019, 18019],
      50201: [13735, 13598, 10514, 14035, 18093, 18093],
      50401: [13809, 13672, 10588, 14100, 18167, 18167],
      50601: [13883, 13746, 10662, 14165, 18241, 18241],
      50801: [13957, 13820, 10736, 14230, 18315, 18315],
      51001: [14031, 13894, 10810, 14295, 18389, 18389],
      51201: [14105, 13968, 10884, 14359, 18463, 18463],
      51401: [14179, 14042, 10958, 14424, 18537, 18537],
      51601: [14253, 14116, 11032, 14489, 18611, 18611],
      51801: [14327, 14190, 11106, 14554, 18685, 18685],
      52001: [14401, 14264, 11180, 14619, 18759, 18759],
      52201: [14475, 14338, 11254, 14684, 18833, 18833],
      52401: [14549, 14412, 11328, 14748, 18907, 18907],
      52601: [14623, 14486, 11402, 14813, 18981, 18981],
      52801: [14697, 14560, 11476, 14878, 19055, 19055],
      53001: [14771, 14634, 11550, 14943, 19129, 19129],
      53201: [14845, 14708, 11624, 15008, 19203, 19203],
      53401: [14920, 14782, 11698, 15074, 19279, 19279],
      53601: [15034, 14899, 11815, 15179, 19393, 19393],
      53801: [15148, 15019, 11936, 15284, 19507, 19507],
      54001: [15262, 15139, 12056, 15389, 19621, 19621],
      54201: [15376, 15260, 12176, 15493, 19735, 19735],
      54401: [15490, 15380, 12296, 15598, 19849, 19849],
      54601: [15604, 15500, 12417, 15703, 19963, 19963],
      54801: [15718, 15620, 12537, 15808, 20077, 20077],
      55001: [15832, 15737, 12654, 15913, 20191, 20191],
      55201: [15947, 15858, 12774, 16018, 20305, 20305],
      55401: [16060, 15978, 12894, 16122, 20419, 20419],
      55601: [16174, 16098, 13015, 16227, 20533, 20533],
      55801: [16288, 16218, 13135, 16332, 20647, 20647],
      56001: [16402, 16339, 13255, 16437, 20761, 20761],
      56201: [16516, 16459, 13375, 16542, 20875, 20875],
      56401: [16630, 16579, 13496, 16647, 20989, 20989],
      56601: [16744, 16696, 13613, 16751, 21103, 21103],
      56801: [16858, 16817, 13733, 16856, 21217, 21217],
      57001: [16972, 16937, 13853, 16961, 21331, 21331],
      57201: [17086, 17057, 13974, 17066, 21445, 21445],
      57401: [17200, 17177, 14094, 17171, 21559, 21559],
      57601: [17314, 17298, 14214, 17276, 21673, 21673],
      57801: [17428, 17418, 14334, 17380, 21787, 21787],
      58001: [17542, 17538, 14455, 17485, 21901, 21901],
      58201: [17656, 17658, 14575, 17590, 22015, 22015],
      58401: [17770, 17776, 14692, 17695, 22129, 22129],
      58601: [17884, 17896, 14812, 17800, 22243, 22243],
      58801: [17998, 18016, 14933, 17904, 22357, 22357],
      59001: [18112, 18136, 15053, 18009, 22471, 22471],
      59201: [18226, 18257, 15173, 18114, 22585, 22585],
      59401: [18340, 18377, 15293, 18219, 22699, 22699],
      59601: [18454, 18497, 15414, 18324, 22813, 22813],
      59801: [18568, 18617, 15534, 18429, 22927, 22927],
      60001: [18682, 18734, 15651, 18533, 23041, 23041],
      60201: [18797, 18855, 15771, 18638, 23155, 23155],
      60401: [18910, 18975, 15891, 18743, 23269, 23269],
      60601: [19024, 19095, 16012, 18848, 23383, 23383],
      60801: [19138, 19215, 16132, 18953, 23497, 23497],
      61001: [19252, 19331, 16248, 19058, 23611, 23611],
      61201: [19366, 19445, 16362, 19162, 23725, 23725],
      61401: [19480, 19559, 16476, 19267, 23839, 23839],
      61601: [19594, 19673, 16590, 19372, 23953, 23953],
      61801: [19708, 19787, 16704, 19477, 24067, 24067],
      62001: [19822, 19901, 16818, 19582, 24181, 24181],
      62201: [19936, 20015, 16932, 19686, 24295, 24295],
      62401: [20050, 20129, 17046, 19791, 24409, 24409],
      62601: [20164, 20243, 17160, 19896, 24523, 24523],
      62801: [20278, 20357, 17274, 20001, 24637, 24637],
      63001: [20392, 20471, 17388, 20106, 24751, 24751],
      63201: [20506, 20585, 17502, 20211, 24865, 24865],
      63401: [20620, 20699, 17616, 20315, 24979, 24979],
      63601: [20734, 20813, 17730, 20420, 25093, 25093],
      63801: [20848, 20927, 17844, 20525, 25207, 25207],
      64001: [20962, 21041, 17958, 20630, 25321, 25321],
      64201: [21076, 21155, 18072, 20735, 25435, 25435],
      64401: [21190, 21269, 18186, 20840, 25549, 25549],
      64601: [21304, 21383, 18300, 20944, 25663, 25663],
      64801: [21418, 21497, 18414, 21049, 25777, 25777],
      65001: [21532, 21611, 18528, 21154, 25891, 25891],
      65201: [21647, 21725, 18642, 21259, 26005, 26005],
      65401: [21760, 21839, 18756, 21364, 26119, 26119],
      65601: [21874, 21953, 18870, 21469, 26233, 26233],
      65801: [21988, 22067, 18984, 21573, 26347, 26347],
      66001: [22102, 22181, 19098, 21678, 26461, 26461],
      66201: [22216, 22295, 19212, 21783, 26575, 26575],
      66401: [22330, 22409, 19326, 21888, 26689, 26689],
      66601: [22444, 22523, 19440, 21993, 26803, 26803],
      66801: [22558, 22637, 19554, 22097, 26917, 26917],
      67001: [22672, 22751, 19668, 22202, 27031, 27031],
      67201: [22786, 22865, 19782, 22307, 27145, 27145],
      67401: [22900, 22979, 19896, 22412, 27259, 27259],
      67601: [23014, 23093, 20010, 22517, 27373, 27373],
      67801: [23128, 23207, 20124, 22622, 27487, 27487],
      68001: [23242, 23321, 20238, 22726, 27601, 27601],
      68201: [23356, 23435, 20352, 22831, 27715, 27715],
      68401: [23470, 23549, 20466, 22936, 27829, 27829],
      68601: [23584, 23663, 20580, 23041, 27943, 27943],
      68801: [23698, 23777, 20694, 23146, 28057, 28057],
      69001: [23812, 23891, 20808, 23251, 28171, 28171],
      69201: [23926, 24005, 20922, 23355, 28285, 28285],
      69401: [24040, 24119, 21036, 23460, 28399, 28399],
      69601: [24154, 24233, 21150, 23565, 28513, 28513],
      69801: [24268, 24347, 21264, 23670, 28627, 28627],
      70001: [24382, 24461, 21378, 23775, 28741, 28741],
      70201: [24497, 24575, 21492, 23880, 28855, 28855],
      70401: [24610, 24689, 21606, 23984, 28969, 28969],
      70601: [24724, 24803, 21720, 24089, 29083, 29083],
      70801: [24838, 24917, 21834, 24194, 29197, 29197],
      71001: [24952, 25031, 21948, 24299, 29311, 29311],
      71201: [25066, 25145, 22062, 24404, 29425, 29425],
      71401: [25180, 25259, 22176, 24508, 29539, 29539],
      71601: [25294, 25373, 22290, 24613, 29653, 29653],
      71801: [25408, 25487, 22404, 24718, 29767, 29767],
      72001: [25522, 25601, 22518, 24823, 29881, 29881],
      72201: [25636, 25715, 22632, 24928, 29995, 29995],
      72401: [25750, 25829, 22746, 25033, 30109, 30109],
      72601: [25864, 25943, 22860, 25137, 30223, 30223],
      72801: [25978, 26057, 22974, 25242, 30337, 30337],
      73001: [26092, 26171, 23088, 25347, 30451, 30451],
      73201: [26206, 26285, 23202, 25452, 30565, 30565],
      73401: [26320, 26399, 23316, 25557, 30679, 30679],
      73601: [26434, 26513, 23430, 25662, 30793, 30793],
      73801: [26548, 26627, 23544, 25766, 30907, 30907],
      74001: [26662, 26741, 23658, 25871, 31021, 31021],
      74201: [26776, 26855, 23772, 25976, 31135, 31135],
      74401: [26890, 26969, 23886, 26081, 31249, 31249],
      74601: [27004, 27083, 24e3, 26186, 31363, 31363],
      74801: [27118, 27197, 24114, 26290, 31477, 31477],
      75001: [27232, 27311, 24228, 26395, 31591, 31591],
      75201: [27347, 27425, 24342, 26500, 31705, 31705],
      75401: [27460, 27539, 24456, 26605, 31819, 31819],
      75601: [27574, 27653, 24570, 26710, 31933, 31933],
      75801: [27688, 27767, 24684, 26815, 32047, 32047],
      76001: [27802, 27881, 24798, 26919, 32161, 32161],
      76201: [27916, 27995, 24912, 27024, 32275, 32275],
      76401: [28030, 28109, 25026, 27129, 32389, 32389],
      76601: [28144, 28223, 25140, 27234, 32503, 32503],
      76801: [28258, 28337, 25254, 27339, 32617, 32617],
      77001: [28372, 28451, 25368, 27444, 32731, 32731],
      77201: [28486, 28565, 25482, 27548, 32845, 32845],
      77401: [28600, 28679, 25596, 27653, 32959, 32959],
      77601: [28714, 28793, 25710, 27758, 33073, 33073],
      77801: [28828, 28907, 25824, 27863, 33187, 33187],
      78001: [28942, 29021, 25938, 27968, 33301, 33301],
      78201: [29056, 29135, 26052, 28072, 33415, 33415],
      78401: [29170, 29249, 26166, 28177, 33529, 33529],
      78601: [29284, 29363, 26280, 28282, 33643, 33643],
      78801: [29398, 29477, 26394, 28387, 33757, 33757],
      79001: [29512, 29591, 26508, 28492, 33871, 33871],
      79201: [29626, 29705, 26622, 28597, 33985, 33985],
      79401: [29740, 29819, 26736, 28701, 34099, 34099],
      79601: [29854, 29933, 26850, 28806, 34213, 34213],
      79801: [29968, 30047, 26964, 28911, 34327, 34327],
      80001: [38, 38, 34, 36, 43, 43, "%"],
      81801: [38, 38, 34, 37, 43, 43, "%"],
      82801: [38, 38, 35, 37, 43, 43, "%"],
      83401: [38, 38, 35, 37, 44, 44, "%"],
      84001: [38, 39, 35, 37, 44, 44, "%"],
      84401: [39, 39, 35, 37, 44, 44, "%"],
      86601: [39, 39, 36, 37, 44, 44, "%"],
      87201: [39, 39, 36, 38, 44, 44, "%"],
      88801: [39, 40, 36, 38, 44, 44, "%"],
      89201: [40, 40, 36, 38, 44, 44, "%"],
      90001: [40, 40, 36, 38, 45, 45, "%"],
      90801: [40, 40, 37, 38, 45, 45, "%"],
      93401: [40, 40, 37, 39, 45, 45, "%"],
      94201: [40, 41, 37, 39, 45, 45, "%"],
      94601: [41, 41, 37, 39, 45, 45, "%"],
      95401: [41, 41, 38, 39, 45, 45, "%"],
      98001: [41, 41, 38, 39, 46, 46, "%"],
      100201: [41, 42, 38, 39, 46, 46, "%"],
      100601: [41, 42, 39, 39, 46, 46, "%"],
      100801: [42, 42, 39, 40, 46, 46, "%"],
      106401: [42, 42, 40, 40, 46, 46, "%"],
      107201: [42, 43, 40, 40, 47, 47, "%"],
      107801: [43, 43, 40, 40, 47, 47, "%"],
      109201: [43, 43, 40, 41, 47, 47, "%"],
      112801: [43, 43, 41, 41, 47, 47, "%"],
      115201: [43, 44, 41, 41, 47, 47, "%"],
      115601: [44, 44, 41, 41, 47, 47, "%"],
      118601: [44, 44, 41, 41, 48, 48, "%"],
      119201: [44, 44, 41, 42, 48, 48, "%"],
      120201: [44, 44, 42, 42, 48, 48, "%"],
      124401: [44, 45, 42, 42, 48, 48, "%"],
      125001: [45, 45, 42, 42, 48, 48, "%"],
      128401: [45, 45, 43, 42, 48, 48, "%"],
      131201: [45, 45, 43, 43, 48, 48, "%"],
      132601: [45, 45, 43, 43, 49, 49, "%"],
      135201: [45, 46, 43, 43, 49, 49, "%"],
      135801: [46, 46, 43, 43, 49, 49, "%"],
      138001: [46, 46, 44, 43, 49, 49, "%"],
      146001: [46, 46, 44, 44, 49, 49, "%"],
      148001: [46, 47, 44, 44, 49, 49, "%"],
      148801: [47, 47, 44, 44, 49, 49, "%"],
      149001: [47, 47, 45, 44, 49, 49, "%"],
      150201: [47, 47, 45, 44, 50, 50, "%"],
      162001: [47, 47, 46, 44, 50, 50, "%"],
      163601: [47, 48, 46, 44, 50, 50, "%"],
      164401: [48, 48, 46, 45, 50, 50, "%"],
      173401: [48, 48, 46, 45, 51, 51, "%"],
      177401: [48, 48, 47, 45, 51, 51, "%"],
      182801: [48, 49, 47, 45, 51, 51, "%"],
      183801: [49, 49, 47, 45, 51, 51, "%"],
      188201: [49, 49, 47, 46, 51, 51, "%"],
      196001: [49, 49, 48, 46, 51, 51, "%"],
      204801: [49, 49, 48, 46, 52, 52, "%"],
      207201: [49, 50, 48, 46, 52, 52, "%"],
      208401: [50, 50, 48, 46, 52, 52, "%"],
      219201: [50, 50, 49, 46, 52, 52, "%"],
      220001: [50, 50, 49, 47, 52, 52, "%"],
      239201: [50, 51, 49, 47, 52, 52, "%"],
      240401: [51, 51, 49, 47, 52, 52, "%"],
      248401: [51, 51, 50, 47, 52, 52, "%"],
      250401: [51, 51, 50, 47, 53, 53, "%"],
      265001: [51, 51, 50, 48, 53, 53, "%"],
      282601: [51, 52, 50, 48, 53, 53, "%"],
      284201: [52, 52, 50, 48, 53, 53, "%"],
      286601: [52, 52, 51, 48, 53, 53, "%"],
      322001: [52, 52, 51, 48, 54, 54, "%"],
      332601: [52, 52, 51, 49, 54, 54, "%"],
      338801: [52, 52, 52, 49, 54, 54, "%"],
      345601: [52, 53, 52, 49, 54, 54, "%"],
      347201: [53, 53, 52, 49, 54, 54, "%"],
      414001: [53, 53, 53, 49, 54, 54, "%"],
      444201: [53, 54, 53, 49, 54, 54, "%"],
      446601: [54, 54, 53, 49, 54, 54, "%"],
      446801: [54, 54, 53, 50, 54, 54, "%"],
      450801: [54, 54, 53, 50, 55, 55, "%"],
      532401: [54, 54, 54, 50, 55, 55, "%"],
      622001: [54, 55, 54, 50, 55, 55, "%"],
      625201: [55, 55, 54, 50, 55, 55, "%"],
      680601: [55, 55, 54, 51, 55, 55, "%"],
      745401: [55, 55, 55, 51, 55, 55, "%"],
      751401: [55, 55, 55, 51, 56, 56, "%"],
      1036801: [55, 56, 55, 51, 56, 56, "%"],
      1042001: [56, 56, 55, 51, 56, 56, "%"],
      1242201: [56, 56, 56, 51, 56, 56, "%"],
      1426601: [56, 56, 56, 52, 56, 56, "%"]
    },
    38: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 9],
      2101: [152, 0, 150, 2, 152, 48],
      2201: [163, 0, 158, 4, 163, 87],
      2301: [173, 0, 166, 7, 173, 126],
      2401: [184, 0, 175, 9, 184, 165],
      2501: [194, 0, 183, 11, 204, 204],
      2601: [205, 0, 191, 13, 243, 243],
      2701: [215, 0, 200, 15, 282, 282],
      2801: [217, 0, 200, 17, 321, 321],
      2901: [228, 0, 208, 19, 360, 360],
      3001: [238, 0, 216, 22, 399, 399],
      3101: [249, 0, 225, 24, 438, 438],
      3201: [259, 0, 233, 26, 477, 477],
      3301: [270, 0, 241, 28, 516, 516],
      3401: [272, 0, 241, 30, 555, 555],
      3501: [282, 0, 250, 32, 594, 594],
      3601: [293, 0, 258, 35, 633, 633],
      3701: [303, 0, 266, 37, 672, 672],
      3801: [314, 0, 275, 39, 711, 711],
      3901: [324, 0, 283, 41, 750, 750],
      4001: [327, 0, 283, 43, 789, 789],
      4101: [337, 0, 291, 45, 828, 828],
      4201: [348, 0, 300, 48, 867, 867],
      4301: [358, 0, 308, 50, 906, 906],
      4401: [369, 0, 316, 62, 945, 945],
      4501: [379, 0, 325, 88, 984, 984],
      4601: [381, 0, 325, 115, 1023, 1023],
      4701: [392, 0, 333, 141, 1062, 1062],
      4801: [402, 0, 341, 167, 1098, 1098],
      4901: [412, 0, 350, 193, 1127, 1127],
      5001: [422, 0, 358, 219, 1160, 1160],
      5101: [432, 0, 366, 245, 1189, 1189],
      5201: [442, 0, 375, 272, 1221, 1221],
      5301: [444, 0, 375, 298, 1254, 1254],
      5401: [454, 16, 384, 324, 1283, 1283],
      5501: [464, 45, 394, 349, 1315, 1315],
      5601: [474, 74, 404, 375, 1344, 1344],
      5701: [484, 103, 414, 400, 1376, 1376],
      5801: [494, 129, 423, 426, 1408, 1408],
      5901: [496, 159, 425, 451, 1437, 1437],
      6001: [506, 188, 435, 476, 1468, 1468],
      6101: [516, 217, 445, 502, 1497, 1497],
      6201: [526, 246, 455, 527, 1529, 1529],
      6301: [536, 276, 465, 553, 1561, 1561],
      6401: [546, 305, 475, 578, 1590, 1590],
      6501: [567, 334, 476, 604, 1621, 1621],
      6601: [590, 363, 486, 629, 1650, 1650],
      6701: [614, 393, 496, 654, 1682, 1682],
      6801: [638, 422, 506, 680, 1714, 1714],
      6901: [661, 451, 516, 705, 1743, 1743],
      7001: [685, 477, 526, 731, 1774, 1774],
      7101: [709, 506, 528, 756, 1803, 1803],
      7201: [732, 536, 538, 781, 1835, 1835],
      7301: [756, 565, 547, 807, 1867, 1867],
      7401: [780, 594, 557, 832, 1896, 1896],
      7501: [804, 623, 567, 858, 1927, 1927],
      7601: [827, 653, 577, 883, 1956, 1956],
      7701: [851, 682, 587, 909, 1988, 1988],
      7801: [875, 711, 589, 934, 2020, 2020],
      7901: [898, 740, 599, 959, 2049, 2049],
      8001: [922, 770, 609, 985, 2080, 2080],
      8101: [946, 799, 619, 1010, 2109, 2109],
      8201: [969, 825, 629, 1036, 2141, 2141],
      8301: [993, 854, 639, 1061, 2173, 2173],
      8401: [1017, 883, 640, 1087, 2202, 2202],
      8501: [1041, 913, 650, 1112, 2233, 2233],
      8601: [1064, 942, 660, 1137, 2262, 2262],
      8701: [1088, 971, 670, 1163, 2294, 2294],
      8801: [1112, 1e3, 680, 1188, 2326, 2326],
      8901: [1135, 1030, 690, 1214, 2355, 2355],
      9001: [1159, 1059, 692, 1239, 2386, 2386],
      9101: [1183, 1088, 701, 1265, 2415, 2415],
      9201: [1206, 1117, 711, 1290, 2447, 2447],
      9301: [1230, 1147, 721, 1315, 2479, 2479],
      9401: [1254, 1173, 731, 1341, 2508, 2508],
      9501: [1278, 1202, 741, 1366, 2539, 2539],
      9601: [1301, 1221, 742, 1392, 2568, 2568],
      9701: [1325, 1244, 752, 1417, 2600, 2600],
      9801: [1349, 1267, 761, 1443, 2632, 2632],
      9901: [1372, 1293, 771, 1468, 2661, 2661],
      10001: [1396, 1315, 781, 1493, 2692, 2692],
      10101: [1420, 1338, 790, 1519, 2721, 2721],
      10201: [1443, 1360, 800, 1544, 2753, 2753],
      10301: [1467, 1386, 801, 1570, 2785, 2785],
      10401: [1491, 1408, 811, 1595, 2814, 2814],
      10501: [1514, 1430, 821, 1621, 2845, 2845],
      10601: [1538, 1452, 830, 1646, 2874, 2874],
      10701: [1562, 1478, 840, 1671, 2906, 2906],
      10801: [1586, 1500, 850, 1697, 2938, 2938],
      10901: [1609, 1523, 851, 1722, 2967, 2967],
      11001: [1633, 1545, 860, 1748, 2998, 2998],
      11101: [1657, 1570, 870, 1773, 3027, 3027],
      11201: [1680, 1593, 880, 1798, 3059, 3059],
      11301: [1704, 1615, 889, 1824, 3091, 3091],
      11401: [1728, 1637, 899, 1849, 3120, 3120],
      11501: [1751, 1663, 900, 1875, 3151, 3151],
      11601: [1775, 1685, 910, 1900, 3180, 3180],
      11701: [1799, 1707, 920, 1926, 3212, 3212],
      11801: [1823, 1730, 929, 1951, 3244, 3244],
      11901: [1846, 1755, 939, 1976, 3273, 3273],
      12001: [1870, 1778, 949, 2002, 3304, 3304],
      12101: [1894, 1800, 950, 2027, 3333, 3333],
      12201: [1917, 1825, 960, 2053, 3365, 3365],
      12301: [1941, 1848, 969, 2078, 3397, 3397],
      12401: [1965, 1870, 979, 2104, 3426, 3426],
      12501: [1988, 1892, 988, 2129, 3457, 3457],
      12601: [2012, 1918, 998, 2154, 3486, 3486],
      12701: [2036, 1940, 1008, 2180, 3518, 3518],
      12801: [2059, 1962, 1009, 2205, 3550, 3550],
      12901: [2083, 1985, 1019, 2231, 3579, 3579],
      13001: [2107, 2010, 1028, 2256, 3611, 3611],
      13101: [2130, 2033, 1038, 2281, 3639, 3639],
      13201: [2154, 2055, 1047, 2307, 3671, 3671],
      13301: [2178, 2077, 1057, 2333, 3706, 3706],
      13401: [2202, 2103, 1059, 2358, 3744, 3744],
      13501: [2226, 2125, 1068, 2384, 3783, 3783],
      13601: [2250, 2147, 1078, 2410, 3821, 3821],
      13701: [2274, 2170, 1087, 2435, 3859, 3859],
      13801: [2298, 2195, 1097, 2461, 3897, 3897],
      13901: [2322, 2217, 1107, 2487, 3936, 3936],
      14001: [2346, 2240, 1108, 2512, 3974, 3974],
      14101: [2370, 2262, 1118, 2538, 4012, 4012],
      14201: [2393, 2288, 1127, 2563, 4049, 4049],
      14301: [2416, 2310, 1137, 2588, 4086, 4086],
      14401: [2439, 2332, 1146, 2612, 4124, 4124],
      14501: [2462, 2358, 1156, 2637, 4161, 4161],
      14601: [2485, 2380, 1158, 2662, 4198, 4198],
      14701: [2508, 2402, 1167, 2686, 4235, 4235],
      14801: [2531, 2425, 1177, 2711, 4273, 4273],
      14901: [2554, 2450, 1186, 2736, 4310, 4310],
      15001: [2577, 2472, 1196, 2760, 4347, 4347],
      15101: [2599, 2495, 1206, 2785, 4384, 4384],
      15201: [2622, 2517, 1215, 2810, 4422, 4422],
      15301: [2646, 2543, 1217, 2835, 4465, 4465],
      15401: [2669, 2565, 1226, 2859, 4505, 4505],
      15501: [2692, 2587, 1236, 2884, 4546, 4546],
      15601: [2715, 2610, 1245, 2909, 4586, 4586],
      15701: [2738, 2635, 1255, 2934, 4627, 4627],
      15801: [2762, 2657, 1265, 2961, 4670, 4670],
      15901: [2792, 2689, 1267, 2993, 4710, 4710],
      16001: [2822, 2718, 1277, 3026, 4751, 4751],
      16101: [2852, 2750, 1287, 3059, 4791, 4791],
      16201: [2881, 2778, 1297, 3091, 4831, 4831],
      16301: [2911, 2810, 1307, 3124, 4875, 4875],
      16401: [2941, 2842, 1317, 3156, 4915, 4915],
      16501: [2971, 2871, 1319, 3189, 4956, 4956],
      16601: [3001, 2903, 1329, 3221, 4996, 4996],
      16701: [3031, 2931, 1339, 3254, 5036, 5036],
      16801: [3061, 2963, 1349, 3287, 5080, 5080],
      16901: [3091, 2992, 1359, 3319, 5120, 5120],
      17001: [3121, 3024, 1369, 3352, 5160, 5160],
      17101: [3151, 3053, 1371, 3384, 5201, 5201],
      17201: [3181, 3084, 1381, 3417, 5241, 5241],
      17301: [3211, 3113, 1391, 3450, 5285, 5285],
      17401: [3241, 3145, 1401, 3482, 5325, 5325],
      17501: [3271, 3177, 1411, 3515, 5365, 5365],
      17601: [3301, 3206, 1421, 3547, 5406, 5406],
      17701: [3331, 3237, 1431, 3580, 5446, 5446],
      17801: [3361, 3266, 1433, 3612, 5489, 5489],
      17901: [3391, 3298, 1443, 3645, 5530, 5530],
      18001: [3421, 3327, 1453, 3678, 5570, 5570],
      18101: [3451, 3359, 1463, 3710, 5611, 5611],
      18201: [3481, 3387, 1473, 3743, 5651, 5651],
      18301: [3511, 3419, 1483, 3775, 5694, 5694],
      18401: [3541, 3451, 1485, 3808, 5735, 5735],
      18501: [3571, 3480, 1495, 3840, 5775, 5775],
      18601: [3600, 3512, 1505, 3873, 5815, 5815],
      18701: [3630, 3540, 1515, 3906, 5856, 5856],
      18801: [3660, 3572, 1525, 3938, 5899, 5899],
      18901: [3690, 3601, 1535, 3971, 5940, 5940],
      19001: [3720, 3633, 1537, 4003, 5980, 5980],
      19101: [3750, 3661, 1547, 4036, 6020, 6020],
      19201: [3780, 3693, 1557, 4068, 6061, 6061],
      19301: [3810, 3725, 1567, 4101, 6104, 6104],
      19401: [3840, 3754, 1577, 4134, 6144, 6144],
      19501: [3870, 3786, 1587, 4166, 6185, 6185],
      19601: [3900, 3814, 1589, 4199, 6225, 6225],
      19701: [3930, 3846, 1599, 4231, 6266, 6266],
      19801: [3960, 3875, 1609, 4264, 6309, 6309],
      19901: [3990, 3907, 1619, 4296, 6349, 6349],
      20001: [4050, 3967, 1639, 4362, 6430, 6430],
      20201: [4110, 4027, 1650, 4427, 6514, 6514],
      20401: [4170, 4086, 1669, 4492, 6595, 6595],
      20601: [4230, 4145, 1687, 4557, 6675, 6675],
      20801: [4290, 4204, 1697, 4622, 6759, 6759],
      21001: [4350, 4263, 1716, 4687, 6840, 6840],
      21201: [4409, 4325, 1734, 4753, 6924, 6924],
      21401: [4469, 4384, 1744, 4818, 7004, 7004],
      21601: [4529, 4443, 1763, 4883, 7085, 7085],
      21801: [4589, 4502, 1781, 4948, 7169, 7169],
      22001: [4649, 4561, 1792, 5013, 7250, 7250],
      22201: [4709, 4620, 1810, 5078, 7333, 7333],
      22401: [4769, 4679, 1829, 5143, 7414, 7414],
      22601: [4829, 4738, 1851, 5209, 7495, 7495],
      22801: [4889, 4797, 1896, 5274, 7579, 7579],
      23001: [4950, 4856, 1941, 5340, 7661, 7661],
      23201: [5012, 4918, 1989, 5407, 7746, 7746],
      23401: [5073, 4977, 2034, 5474, 7829, 7829],
      23601: [5135, 5036, 2079, 5541, 7911, 7911],
      23801: [5197, 5095, 2124, 5608, 7997, 7997],
      24001: [5258, 5154, 2169, 5674, 8079, 8079],
      24201: [5320, 5213, 2214, 5741, 8164, 8164],
      24401: [5381, 5272, 2259, 5808, 8247, 8247],
      24601: [5443, 5334, 2307, 5875, 8329, 8329],
      24801: [5504, 5396, 2355, 5942, 8415, 8415],
      25001: [5566, 5455, 2400, 6008, 8497, 8497],
      25201: [5628, 5517, 2448, 6075, 8582, 8582],
      25401: [5689, 5576, 2493, 6142, 8665, 8665],
      25601: [5751, 5639, 2555, 6209, 8747, 8747],
      25801: [5812, 5697, 2614, 6276, 8833, 8833],
      26001: [5874, 5760, 2676, 6342, 8915, 8915],
      26201: [5935, 5819, 2735, 6409, 9e3, 9e3],
      26401: [5997, 5881, 2797, 6476, 9083, 9083],
      26601: [6059, 5940, 2856, 6543, 9165, 9165],
      26801: [6120, 6002, 2918, 6610, 9251, 9251],
      27001: [6182, 6061, 2977, 6676, 9333, 9333],
      27201: [6243, 6123, 3039, 6743, 9418, 9418],
      27401: [6305, 6182, 3098, 6810, 9501, 9501],
      27601: [6366, 6244, 3160, 6877, 9583, 9583],
      27801: [6428, 6306, 3222, 6944, 9669, 9669],
      28001: [6490, 6365, 3281, 7010, 9751, 9751],
      28201: [6551, 6427, 3343, 7077, 9836, 9836],
      28401: [6613, 6486, 3402, 7144, 9919, 9919],
      28601: [6674, 6548, 3464, 7211, 10001, 10001],
      28801: [6736, 6607, 3523, 7278, 10087, 10087],
      29001: [6797, 6669, 3586, 7344, 10169, 10169],
      29201: [6859, 6728, 3645, 7411, 10254, 10254],
      29401: [6921, 6790, 3707, 7478, 10337, 10337],
      29601: [6982, 6849, 3766, 7545, 10419, 10419],
      29801: [7044, 6911, 3828, 7612, 10505, 10505],
      30001: [7105, 6970, 3887, 7679, 10587, 10587],
      30201: [7167, 7032, 3949, 7745, 10672, 10672],
      30401: [7228, 7094, 4011, 7812, 10755, 10755],
      30601: [7290, 7153, 4070, 7879, 10837, 10837],
      30801: [7352, 7215, 4132, 7946, 10923, 10923],
      31001: [7413, 7274, 4191, 8012, 11005, 11005],
      31201: [7475, 7336, 4253, 8079, 11090, 11090],
      31401: [7536, 7395, 4312, 8146, 11173, 11173],
      31601: [7598, 7458, 4374, 8213, 11255, 11255],
      31801: [7659, 7516, 4433, 8280, 11341, 11341],
      32001: [7721, 7579, 4495, 8346, 11423, 11423],
      32201: [7783, 7639, 4555, 8413, 11508, 11508],
      32401: [7844, 7702, 4619, 8480, 11591, 11591],
      32601: [7906, 7762, 4679, 8547, 11673, 11673],
      32801: [7967, 7826, 4742, 8614, 11759, 11759],
      33001: [8029, 7886, 4802, 8680, 11841, 11841],
      33201: [8090, 7949, 4866, 8747, 11926, 11926],
      33401: [8152, 8012, 4929, 8814, 12009, 12009],
      33601: [8214, 8073, 4989, 8881, 12091, 12091],
      33801: [8275, 8136, 5052, 8948, 12177, 12177],
      34001: [8337, 8196, 5113, 9014, 12259, 12259],
      34201: [8398, 8259, 5176, 9081, 12344, 12344],
      34401: [8460, 8320, 5236, 9148, 12427, 12427],
      34601: [8521, 8383, 5299, 9215, 12509, 12509],
      34801: [8583, 8443, 5360, 9282, 12595, 12595],
      35001: [8644, 8506, 5423, 9348, 12677, 12677],
      35201: [8706, 8567, 5483, 9415, 12762, 12762],
      35401: [8768, 8630, 5546, 9482, 12845, 12845],
      35601: [8829, 8690, 5607, 9549, 12927, 12927],
      35801: [8891, 8753, 5670, 9616, 13013, 13013],
      36001: [8952, 8817, 5733, 9682, 13095, 13095],
      36201: [9014, 8877, 5793, 9749, 13180, 13180],
      36401: [9075, 8940, 5857, 9816, 13263, 13263],
      36601: [9137, 9e3, 5917, 9883, 13345, 13345],
      36801: [9199, 9064, 5980, 9950, 13431, 13431],
      37001: [9260, 9124, 6040, 10016, 13513, 13513],
      37201: [9322, 9187, 6104, 10083, 13598, 13598],
      37401: [9383, 9247, 6164, 10150, 13681, 13681],
      37601: [9445, 9311, 6227, 10217, 13763, 13763],
      37801: [9506, 9371, 6287, 10284, 13849, 13849],
      38001: [9568, 9434, 6351, 10350, 13931, 13931],
      38201: [9630, 9494, 6411, 10417, 14016, 14016],
      38401: [9691, 9558, 6474, 10484, 14099, 14099],
      38601: [9753, 9618, 6534, 10551, 14175, 14175],
      38801: [9814, 9678, 6595, 10617, 14251, 14251],
      39001: [9875, 9738, 6655, 10684, 14327, 14327],
      39201: [9937, 9802, 6718, 10750, 14403, 14403],
      39401: [9999, 9862, 6778, 10817, 14479, 14479],
      39601: [10075, 9938, 6854, 10884, 14555, 14555],
      39801: [10151, 10014, 6930, 10950, 14631, 14631],
      40001: [10227, 10090, 7006, 11017, 14707, 14707],
      40201: [10303, 10166, 7082, 11083, 14783, 14783],
      40401: [10379, 10242, 7158, 11150, 14859, 14859],
      40601: [10455, 10318, 7234, 11216, 14935, 14935],
      40801: [10531, 10394, 7310, 11283, 15011, 15011],
      41001: [10607, 10470, 7386, 11350, 15087, 15087],
      41201: [10683, 10546, 7462, 11416, 15163, 15163],
      41401: [10759, 10622, 7538, 11483, 15239, 15239],
      41601: [10835, 10698, 7614, 11549, 15315, 15315],
      41801: [10911, 10774, 7690, 11616, 15391, 15391],
      42001: [10987, 10850, 7766, 11682, 15467, 15467],
      42201: [11063, 10926, 7842, 11749, 15543, 15543],
      42401: [11139, 11002, 7918, 11815, 15619, 15619],
      42601: [11215, 11078, 7994, 11882, 15695, 15695],
      42801: [11291, 11154, 8070, 11949, 15771, 15771],
      43001: [11367, 11230, 8146, 12015, 15847, 15847],
      43201: [11443, 11306, 8222, 12082, 15923, 15923],
      43401: [11519, 11382, 8298, 12148, 15999, 15999],
      43601: [11595, 11458, 8374, 12215, 16075, 16075],
      43801: [11671, 11534, 8450, 12281, 16151, 16151],
      44001: [11747, 11610, 8526, 12348, 16227, 16227],
      44201: [11823, 11686, 8602, 12415, 16303, 16303],
      44401: [11899, 11762, 8678, 12481, 16379, 16379],
      44601: [11975, 11838, 8754, 12548, 16455, 16455],
      44801: [12051, 11914, 8830, 12614, 16531, 16531],
      45001: [12127, 11990, 8906, 12681, 16607, 16607],
      45201: [12203, 12066, 8982, 12748, 16683, 16683],
      45401: [12279, 12142, 9058, 12814, 16759, 16759],
      45601: [12355, 12218, 9134, 12881, 16835, 16835],
      45801: [12431, 12294, 9210, 12947, 16911, 16911],
      46001: [12507, 12370, 9286, 13014, 16987, 16987],
      46201: [12583, 12446, 9362, 13080, 17063, 17063],
      46401: [12659, 12522, 9438, 13147, 17139, 17139],
      46601: [12735, 12598, 9514, 13213, 17215, 17215],
      46801: [12811, 12674, 9590, 13280, 17291, 17291],
      47001: [12887, 12750, 9666, 13347, 17367, 17367],
      47201: [12963, 12826, 9742, 13413, 17443, 17443],
      47401: [13039, 12902, 9818, 13480, 17519, 17519],
      47601: [13115, 12978, 9894, 13546, 17595, 17595],
      47801: [13191, 13054, 9970, 13613, 17671, 17671],
      48001: [13267, 13130, 10046, 13679, 17747, 17747],
      48201: [13343, 13206, 10122, 13746, 17823, 17823],
      48401: [13419, 13282, 10198, 13813, 17899, 17899],
      48601: [13495, 13358, 10274, 13879, 17975, 17975],
      48801: [13571, 13434, 10350, 13946, 18051, 18051],
      49001: [13647, 13510, 10426, 14012, 18127, 18127],
      49201: [13723, 13586, 10502, 14079, 18203, 18203],
      49401: [13799, 13662, 10578, 14145, 18279, 18279],
      49601: [13875, 13738, 10654, 14212, 18355, 18355],
      49801: [13951, 13814, 10730, 14279, 18431, 18431],
      50001: [14027, 13890, 10806, 14345, 18507, 18507],
      50201: [14103, 13966, 10882, 14412, 18583, 18583],
      50401: [14179, 14042, 10958, 14478, 18659, 18659],
      50601: [14255, 14118, 11034, 14545, 18735, 18735],
      50801: [14331, 14194, 11110, 14611, 18811, 18811],
      51001: [14407, 14270, 11186, 14678, 18887, 18887],
      51201: [14483, 14346, 11262, 14745, 18963, 18963],
      51401: [14559, 14422, 11338, 14811, 19039, 19039],
      51601: [14635, 14498, 11414, 14878, 19115, 19115],
      51801: [14711, 14574, 11490, 14944, 19191, 19191],
      52001: [14787, 14650, 11566, 15011, 19267, 19267],
      52201: [14863, 14726, 11642, 15077, 19343, 19343],
      52401: [14939, 14802, 11718, 15144, 19419, 19419],
      52601: [15015, 14878, 11794, 15211, 19495, 19495],
      52801: [15091, 14954, 11870, 15277, 19571, 19571],
      53001: [15167, 15030, 11946, 15344, 19647, 19647],
      53201: [15243, 15106, 12022, 15410, 19723, 19723],
      53401: [15320, 15182, 12098, 15478, 19800, 19800],
      53601: [15436, 15302, 12219, 15585, 19916, 19916],
      53801: [15552, 15426, 12342, 15692, 20032, 20032],
      54001: [15668, 15549, 12466, 15798, 20148, 20148],
      54201: [15784, 15673, 12589, 15905, 20264, 20264],
      54401: [15900, 15796, 12713, 16011, 20380, 20380],
      54601: [16016, 15920, 12836, 16118, 20496, 20496],
      54801: [16132, 16043, 12960, 16224, 20612, 20612],
      55001: [16248, 16163, 13080, 16331, 20728, 20728],
      55201: [16364, 16287, 13203, 16438, 20844, 20844],
      55401: [16480, 16410, 13327, 16544, 20960, 20960],
      55601: [16596, 16534, 13450, 16651, 21076, 21076],
      55801: [16712, 16657, 13574, 16757, 21192, 21192],
      56001: [16828, 16781, 13697, 16864, 21308, 21308],
      56201: [16944, 16904, 13821, 16970, 21424, 21424],
      56401: [17060, 17028, 13944, 17077, 21540, 21540],
      56601: [17176, 17148, 14065, 17184, 21656, 21656],
      56801: [17292, 17272, 14188, 17290, 21772, 21772],
      57001: [17408, 17395, 14312, 17397, 21888, 21888],
      57201: [17524, 17519, 14435, 17503, 22004, 22004],
      57401: [17640, 17642, 14559, 17610, 22120, 22120],
      57601: [17756, 17766, 14682, 17716, 22236, 22236],
      57801: [17872, 17889, 14806, 17823, 22352, 22352],
      58001: [17988, 18013, 14929, 17930, 22468, 22468],
      58201: [18104, 18136, 15053, 18036, 22584, 22584],
      58401: [18220, 18257, 15173, 18143, 22700, 22700],
      58601: [18336, 18380, 15297, 18249, 22816, 22816],
      58801: [18452, 18504, 15420, 18356, 22932, 22932],
      59001: [18568, 18627, 15544, 18462, 23048, 23048],
      59201: [18684, 18751, 15667, 18569, 23164, 23164],
      59401: [18800, 18874, 15791, 18676, 23280, 23280],
      59601: [18916, 18998, 15914, 18782, 23396, 23396],
      59801: [19032, 19121, 16038, 18889, 23512, 23512],
      60001: [19148, 19241, 16158, 18995, 23628, 23628],
      60201: [19264, 19365, 16281, 19102, 23744, 23744],
      60401: [19380, 19488, 16405, 19208, 23860, 23860],
      60601: [19496, 19612, 16528, 19315, 23976, 23976],
      60801: [19612, 19735, 16652, 19422, 24092, 24092],
      61001: [19728, 19854, 16770, 19528, 24208, 24208],
      61201: [19844, 19970, 16886, 19635, 24324, 24324],
      61401: [19960, 20086, 17002, 19741, 24440, 24440],
      61601: [20076, 20202, 17118, 19848, 24556, 24556],
      61801: [20192, 20318, 17234, 19954, 24672, 24672],
      62001: [20308, 20434, 17350, 20061, 24788, 24788],
      62201: [20424, 20550, 17466, 20167, 24904, 24904],
      62401: [20540, 20666, 17582, 20274, 25020, 25020],
      62601: [20656, 20782, 17698, 20381, 25136, 25136],
      62801: [20772, 20898, 17814, 20487, 25252, 25252],
      63001: [20888, 21014, 17930, 20594, 25368, 25368],
      63201: [21004, 21130, 18046, 20700, 25484, 25484],
      63401: [21120, 21246, 18162, 20807, 25600, 25600],
      63601: [21236, 21362, 18278, 20913, 25716, 25716],
      63801: [21352, 21478, 18394, 21020, 25832, 25832],
      64001: [21468, 21594, 18510, 21127, 25948, 25948],
      64201: [21584, 21710, 18626, 21233, 26064, 26064],
      64401: [21700, 21826, 18742, 21340, 26180, 26180],
      64601: [21816, 21942, 18858, 21446, 26296, 26296],
      64801: [21932, 22058, 18974, 21553, 26412, 26412],
      65001: [22048, 22174, 19090, 21659, 26528, 26528],
      65201: [22164, 22290, 19206, 21766, 26644, 26644],
      65401: [22280, 22406, 19322, 21873, 26760, 26760],
      65601: [22396, 22522, 19438, 21979, 26876, 26876],
      65801: [22512, 22638, 19554, 22086, 26992, 26992],
      66001: [22628, 22754, 19670, 22192, 27108, 27108],
      66201: [22744, 22870, 19786, 22299, 27224, 27224],
      66401: [22860, 22986, 19902, 22405, 27340, 27340],
      66601: [22976, 23102, 20018, 22512, 27456, 27456],
      66801: [23092, 23218, 20134, 22619, 27572, 27572],
      67001: [23208, 23334, 20250, 22725, 27688, 27688],
      67201: [23324, 23450, 20366, 22832, 27804, 27804],
      67401: [23440, 23566, 20482, 22938, 27920, 27920],
      67601: [23556, 23682, 20598, 23045, 28036, 28036],
      67801: [23672, 23798, 20714, 23151, 28152, 28152],
      68001: [23788, 23914, 20830, 23258, 28268, 28268],
      68201: [23904, 24030, 20946, 23365, 28384, 28384],
      68401: [24020, 24146, 21062, 23471, 28500, 28500],
      68601: [24136, 24262, 21178, 23578, 28616, 28616],
      68801: [24252, 24378, 21294, 23684, 28732, 28732],
      69001: [24368, 24494, 21410, 23791, 28848, 28848],
      69201: [24484, 24610, 21526, 23897, 28964, 28964],
      69401: [24600, 24726, 21642, 24004, 29080, 29080],
      69601: [24716, 24842, 21758, 24111, 29196, 29196],
      69801: [24832, 24958, 21874, 24217, 29312, 29312],
      70001: [24948, 25074, 21990, 24324, 29428, 29428],
      70201: [25064, 25190, 22106, 24430, 29544, 29544],
      70401: [25180, 25306, 22222, 24537, 29660, 29660],
      70601: [25296, 25422, 22338, 24643, 29776, 29776],
      70801: [25412, 25538, 22454, 24750, 29892, 29892],
      71001: [25528, 25654, 22570, 24857, 30008, 30008],
      71201: [25644, 25770, 22686, 24963, 30124, 30124],
      71401: [25760, 25886, 22802, 25070, 30240, 30240],
      71601: [25876, 26002, 22918, 25176, 30356, 30356],
      71801: [25992, 26118, 23034, 25283, 30472, 30472],
      72001: [26108, 26234, 23150, 25389, 30588, 30588],
      72201: [26224, 26350, 23266, 25496, 30704, 30704],
      72401: [26340, 26466, 23382, 25603, 30820, 30820],
      72601: [26456, 26582, 23498, 25709, 30936, 30936],
      72801: [26572, 26698, 23614, 25816, 31052, 31052],
      73001: [26688, 26814, 23730, 25922, 31168, 31168],
      73201: [26804, 26930, 23846, 26029, 31284, 31284],
      73401: [26920, 27046, 23962, 26135, 31400, 31400],
      73601: [27036, 27162, 24078, 26242, 31516, 31516],
      73801: [27152, 27278, 24194, 26348, 31632, 31632],
      74001: [27268, 27394, 24310, 26455, 31748, 31748],
      74201: [27384, 27510, 24426, 26562, 31864, 31864],
      74401: [27500, 27626, 24542, 26668, 31980, 31980],
      74601: [27616, 27742, 24658, 26775, 32096, 32096],
      74801: [27732, 27858, 24774, 26881, 32212, 32212],
      75001: [27848, 27974, 24890, 26988, 32328, 32328],
      75201: [27964, 28090, 25006, 27095, 32444, 32444],
      75401: [28080, 28206, 25122, 27201, 32560, 32560],
      75601: [28196, 28322, 25238, 27308, 32676, 32676],
      75801: [28312, 28438, 25354, 27414, 32792, 32792],
      76001: [28428, 28554, 25470, 27521, 32908, 32908],
      76201: [28544, 28670, 25586, 27627, 33024, 33024],
      76401: [28660, 28786, 25702, 27734, 33140, 33140],
      76601: [28776, 28902, 25818, 27840, 33256, 33256],
      76801: [28892, 29018, 25934, 27947, 33372, 33372],
      77001: [29008, 29134, 26050, 28054, 33488, 33488],
      77201: [29124, 29250, 26166, 28160, 33604, 33604],
      77401: [29240, 29366, 26282, 28267, 33720, 33720],
      77601: [29356, 29482, 26398, 28373, 33836, 33836],
      77801: [29472, 29598, 26514, 28480, 33952, 33952],
      78001: [29588, 29714, 26630, 28586, 34068, 34068],
      78201: [29704, 29830, 26746, 28693, 34184, 34184],
      78401: [29820, 29946, 26862, 28800, 34300, 34300],
      78601: [29936, 30062, 26978, 28906, 34416, 34416],
      78801: [30052, 30178, 27094, 29013, 34532, 34532],
      79001: [30168, 30294, 27210, 29119, 34648, 34648],
      79201: [30284, 30410, 27326, 29226, 34764, 34764],
      79401: [30400, 30526, 27442, 29332, 34880, 34880],
      79601: [30516, 30642, 27558, 29439, 34996, 34996],
      79801: [30632, 30758, 27674, 29546, 35112, 35112],
      80001: [38, 38, 35, 37, 44, 44, "%"],
      80201: [38, 39, 35, 37, 44, 44, "%"],
      80801: [39, 39, 35, 37, 44, 44, "%"],
      82801: [39, 39, 35, 38, 44, 44, "%"],
      83201: [39, 39, 36, 38, 44, 44, "%"],
      83601: [39, 39, 36, 38, 45, 45, "%"],
      84401: [39, 40, 36, 38, 45, 45, "%"],
      85201: [40, 40, 36, 38, 45, 45, "%"],
      87001: [40, 40, 37, 38, 45, 45, "%"],
      88401: [40, 40, 37, 39, 45, 45, "%"],
      89201: [40, 41, 37, 39, 45, 45, "%"],
      90001: [41, 41, 37, 39, 45, 45, "%"],
      90201: [41, 41, 37, 39, 46, 46, "%"],
      91201: [41, 41, 38, 39, 46, 46, "%"],
      94601: [41, 42, 38, 39, 46, 46, "%"],
      94801: [41, 42, 38, 40, 46, 46, "%"],
      95401: [42, 42, 38, 40, 46, 46, "%"],
      96001: [42, 42, 39, 40, 46, 46, "%"],
      98001: [42, 42, 39, 40, 47, 47, "%"],
      100801: [42, 43, 39, 40, 47, 47, "%"],
      101201: [42, 43, 40, 40, 47, 47, "%"],
      101601: [43, 43, 40, 40, 47, 47, "%"],
      102201: [43, 43, 40, 41, 47, 47, "%"],
      107001: [43, 43, 41, 41, 47, 47, "%"],
      107401: [43, 43, 41, 41, 48, 48, "%"],
      107801: [43, 44, 41, 41, 48, 48, "%"],
      108601: [44, 44, 41, 41, 48, 48, "%"],
      111001: [44, 44, 41, 42, 48, 48, "%"],
      113401: [44, 44, 42, 42, 48, 48, "%"],
      115801: [44, 45, 42, 42, 48, 48, "%"],
      116601: [45, 45, 42, 42, 48, 48, "%"],
      118801: [45, 45, 42, 42, 49, 49, "%"],
      120801: [45, 45, 43, 42, 49, 49, "%"],
      121201: [45, 45, 43, 43, 49, 49, "%"],
      125001: [45, 46, 43, 43, 49, 49, "%"],
      126001: [46, 46, 43, 43, 49, 49, "%"],
      129001: [46, 46, 44, 43, 49, 49, "%"],
      132601: [46, 46, 44, 43, 50, 50, "%"],
      133601: [46, 46, 44, 44, 50, 50, "%"],
      136001: [46, 47, 44, 44, 50, 50, "%"],
      137001: [47, 47, 44, 44, 50, 50, "%"],
      138601: [47, 47, 45, 44, 50, 50, "%"],
      148801: [47, 48, 45, 45, 50, 50, "%"],
      149801: [47, 48, 46, 45, 50, 50, "%"],
      150001: [48, 48, 46, 45, 50, 50, "%"],
      150401: [48, 48, 46, 45, 51, 51, "%"],
      162801: [48, 48, 47, 45, 51, 51, "%"],
      164601: [48, 49, 47, 45, 51, 51, "%"],
      165801: [49, 49, 47, 45, 51, 51, "%"],
      168001: [49, 49, 47, 46, 51, 51, "%"],
      173601: [49, 49, 47, 46, 52, 52, "%"],
      178201: [49, 49, 48, 46, 52, 52, "%"],
      184001: [49, 50, 48, 46, 52, 52, "%"],
      185401: [50, 50, 48, 46, 52, 52, "%"],
      192801: [50, 50, 48, 47, 52, 52, "%"],
      197001: [50, 50, 49, 47, 52, 52, "%"],
      205201: [50, 50, 49, 47, 53, 53, "%"],
      208401: [50, 51, 49, 47, 53, 53, "%"],
      210201: [51, 51, 49, 47, 53, 53, "%"],
      220201: [51, 51, 50, 47, 53, 53, "%"],
      226001: [51, 51, 50, 48, 53, 53, "%"],
      240601: [51, 52, 50, 48, 53, 53, "%"],
      242401: [52, 52, 50, 48, 53, 53, "%"],
      249601: [52, 52, 51, 48, 53, 53, "%"],
      250801: [52, 52, 51, 48, 54, 54, "%"],
      273401: [52, 52, 51, 49, 54, 54, "%"],
      284201: [52, 53, 51, 49, 54, 54, "%"],
      286601: [53, 53, 51, 49, 54, 54, "%"],
      288001: [53, 53, 52, 49, 54, 54, "%"],
      322401: [53, 53, 52, 49, 55, 55, "%"],
      340401: [53, 53, 53, 49, 55, 55, "%"],
      345601: [53, 53, 53, 50, 55, 55, "%"],
      347401: [53, 54, 53, 50, 55, 55, "%"],
      350201: [54, 54, 53, 50, 55, 55, "%"],
      416001: [54, 54, 54, 50, 55, 55, "%"],
      446801: [54, 55, 54, 50, 55, 55, "%"],
      450401: [55, 55, 54, 50, 55, 55, "%"],
      451401: [55, 55, 54, 50, 56, 56, "%"],
      469801: [55, 55, 54, 51, 56, 56, "%"],
      535001: [55, 55, 55, 51, 56, 56, "%"],
      625601: [55, 56, 55, 51, 56, 56, "%"],
      630601: [56, 56, 55, 51, 56, 56, "%"],
      733001: [56, 56, 55, 52, 56, 56, "%"],
      749001: [56, 56, 56, 52, 56, 56, "%"],
      752401: [56, 56, 56, 52, 57, 57, "%"],
      1042801: [56, 57, 56, 52, 57, 57, "%"],
      1051201: [57, 57, 56, 52, 57, 57, "%"],
      1248401: [57, 57, 57, 52, 57, 57, "%"],
      1667401: [57, 57, 57, 53, 57, 57, "%"]
    },
    39: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 9],
      2101: [152, 0, 150, 2, 152, 49],
      2201: [163, 0, 158, 4, 163, 89],
      2301: [173, 0, 166, 7, 173, 129],
      2401: [184, 0, 175, 9, 184, 169],
      2501: [194, 0, 183, 11, 209, 209],
      2601: [205, 0, 191, 13, 250, 250],
      2701: [215, 0, 200, 15, 289, 289],
      2801: [217, 0, 200, 17, 329, 329],
      2901: [228, 0, 208, 19, 369, 369],
      3001: [238, 0, 216, 22, 409, 409],
      3101: [249, 0, 225, 24, 449, 449],
      3201: [259, 0, 233, 26, 489, 489],
      3301: [270, 0, 241, 28, 529, 529],
      3401: [272, 0, 241, 30, 569, 569],
      3501: [282, 0, 250, 32, 609, 609],
      3601: [293, 0, 258, 35, 649, 649],
      3701: [303, 0, 266, 37, 689, 689],
      3801: [314, 0, 275, 39, 729, 729],
      3901: [324, 0, 283, 41, 769, 769],
      4001: [327, 0, 283, 43, 809, 809],
      4101: [337, 0, 291, 45, 849, 849],
      4201: [348, 0, 300, 48, 889, 889],
      4301: [358, 0, 308, 50, 929, 929],
      4401: [369, 0, 316, 62, 969, 969],
      4501: [379, 0, 325, 89, 1009, 1009],
      4601: [381, 0, 325, 116, 1049, 1049],
      4701: [392, 0, 333, 143, 1089, 1089],
      4801: [402, 0, 341, 170, 1126, 1126],
      4901: [412, 0, 350, 197, 1156, 1156],
      5001: [422, 0, 358, 224, 1189, 1189],
      5101: [432, 0, 366, 250, 1219, 1219],
      5201: [442, 0, 375, 277, 1253, 1253],
      5301: [444, 0, 375, 304, 1286, 1286],
      5401: [454, 16, 384, 330, 1316, 1316],
      5501: [464, 46, 394, 357, 1349, 1349],
      5601: [474, 76, 404, 383, 1379, 1379],
      5701: [484, 106, 414, 409, 1411, 1411],
      5801: [494, 133, 423, 435, 1444, 1444],
      5901: [496, 163, 425, 461, 1473, 1473],
      6001: [506, 193, 435, 487, 1506, 1506],
      6101: [516, 223, 445, 513, 1536, 1536],
      6201: [526, 253, 455, 539, 1568, 1568],
      6301: [536, 283, 465, 566, 1601, 1601],
      6401: [555, 313, 475, 592, 1630, 1630],
      6501: [580, 343, 476, 618, 1663, 1663],
      6601: [604, 373, 486, 644, 1693, 1693],
      6701: [628, 403, 496, 670, 1725, 1725],
      6801: [653, 433, 506, 696, 1758, 1758],
      6901: [677, 463, 516, 722, 1787, 1787],
      7001: [701, 489, 526, 748, 1820, 1820],
      7101: [725, 519, 528, 774, 1850, 1850],
      7201: [750, 549, 538, 800, 1882, 1882],
      7301: [774, 579, 547, 826, 1915, 1915],
      7401: [798, 609, 557, 852, 1944, 1944],
      7501: [823, 639, 567, 879, 1977, 1977],
      7601: [847, 669, 577, 905, 2007, 2007],
      7701: [871, 699, 587, 931, 2039, 2039],
      7801: [896, 729, 589, 957, 2072, 2072],
      7901: [920, 759, 599, 983, 2101, 2101],
      8001: [944, 789, 609, 1009, 2134, 2134],
      8101: [969, 819, 619, 1035, 2164, 2164],
      8201: [993, 846, 629, 1061, 2196, 2196],
      8301: [1017, 876, 639, 1087, 2229, 2229],
      8401: [1042, 906, 640, 1113, 2258, 2258],
      8501: [1066, 936, 650, 1139, 2291, 2291],
      8601: [1090, 966, 660, 1165, 2321, 2321],
      8701: [1114, 996, 670, 1191, 2353, 2353],
      8801: [1139, 1026, 680, 1218, 2386, 2386],
      8901: [1163, 1056, 690, 1244, 2415, 2415],
      9001: [1187, 1086, 692, 1270, 2448, 2448],
      9101: [1212, 1116, 701, 1296, 2478, 2478],
      9201: [1236, 1146, 711, 1322, 2510, 2510],
      9301: [1260, 1176, 721, 1348, 2543, 2543],
      9401: [1285, 1203, 731, 1374, 2572, 2572],
      9501: [1309, 1233, 741, 1400, 2605, 2605],
      9601: [1333, 1253, 742, 1426, 2635, 2635],
      9701: [1358, 1276, 752, 1452, 2667, 2667],
      9801: [1382, 1299, 761, 1478, 2700, 2700],
      9901: [1406, 1326, 771, 1504, 2729, 2729],
      10001: [1431, 1349, 781, 1531, 2762, 2762],
      10101: [1455, 1372, 790, 1557, 2792, 2792],
      10201: [1479, 1395, 800, 1583, 2824, 2824],
      10301: [1503, 1421, 801, 1609, 2857, 2857],
      10401: [1528, 1444, 811, 1635, 2887, 2887],
      10501: [1552, 1467, 821, 1661, 2919, 2919],
      10601: [1576, 1490, 830, 1687, 2949, 2949],
      10701: [1601, 1516, 840, 1713, 2981, 2981],
      10801: [1625, 1539, 850, 1739, 3014, 3014],
      10901: [1649, 1562, 851, 1765, 3043, 3043],
      11001: [1674, 1585, 860, 1791, 3076, 3076],
      11101: [1698, 1611, 870, 1817, 3106, 3106],
      11201: [1722, 1634, 880, 1844, 3138, 3138],
      11301: [1747, 1657, 889, 1870, 3171, 3171],
      11401: [1771, 1680, 899, 1896, 3200, 3200],
      11501: [1795, 1706, 900, 1922, 3233, 3233],
      11601: [1819, 1729, 910, 1948, 3263, 3263],
      11701: [1844, 1751, 920, 1974, 3295, 3295],
      11801: [1868, 1774, 929, 2e3, 3328, 3328],
      11901: [1892, 1801, 939, 2026, 3357, 3357],
      12001: [1917, 1824, 949, 2052, 3390, 3390],
      12101: [1941, 1846, 950, 2078, 3420, 3420],
      12201: [1965, 1873, 960, 2104, 3452, 3452],
      12301: [1990, 1895, 969, 2131, 3485, 3485],
      12401: [2014, 1918, 979, 2157, 3514, 3514],
      12501: [2038, 1941, 988, 2183, 3547, 3547],
      12601: [2062, 1967, 998, 2209, 3577, 3577],
      12701: [2087, 1990, 1008, 2235, 3609, 3609],
      12801: [2111, 2013, 1009, 2261, 3642, 3642],
      12901: [2135, 2036, 1019, 2287, 3671, 3671],
      13001: [2160, 2062, 1028, 2313, 3704, 3704],
      13101: [2184, 2085, 1038, 2339, 3734, 3734],
      13201: [2208, 2108, 1047, 2365, 3766, 3766],
      13301: [2233, 2131, 1057, 2392, 3802, 3802],
      13401: [2257, 2157, 1059, 2418, 3842, 3842],
      13501: [2282, 2180, 1068, 2444, 3881, 3881],
      13601: [2307, 2203, 1078, 2471, 3920, 3920],
      13701: [2331, 2226, 1087, 2497, 3959, 3959],
      13801: [2356, 2252, 1097, 2523, 3999, 3999],
      13901: [2380, 2275, 1107, 2550, 4038, 4038],
      14001: [2405, 2298, 1108, 2576, 4077, 4077],
      14101: [2429, 2321, 1118, 2602, 4116, 4116],
      14201: [2453, 2347, 1127, 2628, 4154, 4154],
      14301: [2477, 2370, 1137, 2653, 4193, 4193],
      14401: [2500, 2393, 1146, 2678, 4231, 4231],
      14501: [2524, 2419, 1156, 2704, 4269, 4269],
      14601: [2547, 2442, 1158, 2729, 4307, 4307],
      14701: [2571, 2465, 1167, 2754, 4346, 4346],
      14801: [2595, 2487, 1177, 2780, 4384, 4384],
      14901: [2618, 2514, 1186, 2805, 4422, 4422],
      15001: [2642, 2536, 1196, 2831, 4460, 4460],
      15101: [2665, 2559, 1206, 2856, 4499, 4499],
      15201: [2689, 2582, 1215, 2881, 4537, 4537],
      15301: [2713, 2608, 1217, 2907, 4582, 4582],
      15401: [2736, 2631, 1226, 2932, 4623, 4623],
      15501: [2760, 2654, 1236, 2958, 4664, 4664],
      15601: [2783, 2677, 1245, 2983, 4706, 4706],
      15701: [2807, 2703, 1255, 3008, 4747, 4747],
      15801: [2832, 2726, 1265, 3036, 4792, 4792],
      15901: [2862, 2759, 1267, 3070, 4833, 4833],
      16001: [2893, 2788, 1277, 3103, 4875, 4875],
      16101: [2924, 2821, 1287, 3137, 4916, 4916],
      16201: [2955, 2850, 1297, 3170, 4958, 4958],
      16301: [2986, 2883, 1307, 3204, 5002, 5002],
      16401: [3016, 2916, 1317, 3237, 5044, 5044],
      16501: [3047, 2945, 1319, 3270, 5085, 5085],
      16601: [3078, 2978, 1329, 3304, 5127, 5127],
      16701: [3109, 3007, 1339, 3337, 5168, 5168],
      16801: [3139, 3040, 1349, 3371, 5213, 5213],
      16901: [3170, 3070, 1359, 3404, 5254, 5254],
      17001: [3201, 3102, 1369, 3438, 5296, 5296],
      17101: [3232, 3132, 1371, 3471, 5337, 5337],
      17201: [3262, 3164, 1381, 3505, 5378, 5378],
      17301: [3293, 3194, 1391, 3538, 5423, 5423],
      17401: [3324, 3227, 1401, 3572, 5464, 5464],
      17501: [3355, 3259, 1411, 3605, 5506, 5506],
      17601: [3385, 3289, 1421, 3638, 5547, 5547],
      17701: [3416, 3321, 1431, 3672, 5589, 5589],
      17801: [3447, 3351, 1433, 3705, 5633, 5633],
      17901: [3478, 3384, 1443, 3739, 5675, 5675],
      18001: [3509, 3413, 1453, 3772, 5716, 5716],
      18101: [3539, 3446, 1463, 3806, 5758, 5758],
      18201: [3570, 3475, 1473, 3839, 5799, 5799],
      18301: [3601, 3508, 1483, 3873, 5844, 5844],
      18401: [3632, 3541, 1485, 3906, 5885, 5885],
      18501: [3662, 3570, 1495, 3939, 5927, 5927],
      18601: [3693, 3603, 1505, 3973, 5968, 5968],
      18701: [3724, 3632, 1515, 4006, 6010, 6010],
      18801: [3755, 3665, 1525, 4040, 6054, 6054],
      18901: [3785, 3694, 1535, 4073, 6096, 6096],
      19001: [3816, 3727, 1537, 4107, 6137, 6137],
      19101: [3847, 3757, 1547, 4140, 6178, 6178],
      19201: [3878, 3789, 1557, 4174, 6220, 6220],
      19301: [3909, 3822, 1567, 4207, 6265, 6265],
      19401: [3939, 3851, 1577, 4240, 6306, 6306],
      19501: [3970, 3884, 1587, 4274, 6347, 6347],
      19601: [4001, 3914, 1589, 4307, 6389, 6389],
      19701: [4031, 3946, 1599, 4341, 6430, 6430],
      19801: [4062, 3976, 1609, 4374, 6475, 6475],
      19901: [4093, 4008, 1619, 4408, 6516, 6516],
      20001: [4154, 4071, 1639, 4475, 6599, 6599],
      20201: [4216, 4132, 1650, 4542, 6685, 6685],
      20401: [4277, 4193, 1669, 4608, 6768, 6768],
      20601: [4339, 4253, 1687, 4675, 6851, 6851],
      20801: [4401, 4314, 1697, 4742, 6937, 6937],
      21001: [4462, 4374, 1716, 4809, 7020, 7020],
      21201: [4524, 4438, 1734, 4876, 7106, 7106],
      21401: [4585, 4499, 1744, 4943, 7189, 7189],
      21601: [4647, 4559, 1763, 5010, 7272, 7272],
      21801: [4708, 4620, 1789, 5077, 7358, 7358],
      22001: [4770, 4680, 1835, 5144, 7441, 7441],
      22201: [4831, 4741, 1882, 5210, 7527, 7527],
      22401: [4893, 4801, 1928, 5277, 7610, 7610],
      22601: [4954, 4862, 1975, 5344, 7693, 7693],
      22801: [5016, 4923, 2021, 5411, 7779, 7779],
      23001: [5079, 4983, 2068, 5480, 7863, 7863],
      23201: [5142, 5047, 2118, 5548, 7951, 7951],
      23401: [5205, 5107, 2164, 5617, 8035, 8035],
      23601: [5268, 5168, 2211, 5685, 8120, 8120],
      23801: [5332, 5229, 2258, 5754, 8208, 8208],
      24001: [5395, 5289, 2304, 5822, 8292, 8292],
      24201: [5458, 5350, 2351, 5891, 8380, 8380],
      24401: [5521, 5410, 2397, 5959, 8464, 8464],
      24601: [5584, 5474, 2447, 6028, 8549, 8549],
      24801: [5647, 5538, 2497, 6097, 8637, 8637],
      25001: [5711, 5598, 2543, 6165, 8721, 8721],
      25201: [5774, 5662, 2593, 6234, 8809, 8809],
      25401: [5837, 5723, 2640, 6302, 8893, 8893],
      25601: [5900, 5786, 2703, 6371, 8978, 8978],
      25801: [5963, 5847, 2763, 6439, 9066, 9066],
      26001: [6026, 5911, 2827, 6508, 9150, 9150],
      26201: [6090, 5971, 2888, 6576, 9238, 9238],
      26401: [6153, 6035, 2952, 6645, 9322, 9322],
      26601: [6216, 6096, 3012, 6713, 9407, 9407],
      26801: [6279, 6159, 3076, 6782, 9495, 9495],
      27001: [6342, 6220, 3136, 6851, 9579, 9579],
      27201: [6406, 6284, 3200, 6919, 9667, 9667],
      27401: [6469, 6344, 3261, 6988, 9751, 9751],
      27601: [6532, 6408, 3324, 7056, 9836, 9836],
      27801: [6595, 6472, 3388, 7125, 9924, 9924],
      28001: [6658, 6532, 3449, 7193, 10008, 10008],
      28201: [6721, 6596, 3513, 7262, 10096, 10096],
      28401: [6785, 6657, 3573, 7330, 10180, 10180],
      28601: [6848, 6720, 3637, 7399, 10265, 10265],
      28801: [6911, 6781, 3697, 7467, 10353, 10353],
      29001: [6974, 6845, 3761, 7536, 10437, 10437],
      29201: [7037, 6905, 3822, 7605, 10525, 10525],
      29401: [7100, 6969, 3885, 7673, 10609, 10609],
      29601: [7164, 7030, 3946, 7742, 10694, 10694],
      29801: [7227, 7093, 4010, 7810, 10782, 10782],
      30001: [7290, 7154, 4070, 7879, 10866, 10866],
      30201: [7353, 7218, 4134, 7947, 10954, 10954],
      30401: [7416, 7281, 4198, 8016, 11038, 11038],
      30601: [7479, 7342, 4258, 8084, 11123, 11123],
      30801: [7543, 7406, 4322, 8153, 11211, 11211],
      31001: [7606, 7466, 4383, 8221, 11295, 11295],
      31201: [7669, 7530, 4446, 8290, 11383, 11383],
      31401: [7732, 7591, 4507, 8359, 11467, 11467],
      31601: [7795, 7654, 4571, 8427, 11552, 11552],
      31801: [7858, 7715, 4631, 8496, 11640, 11640],
      32001: [7922, 7779, 4695, 8564, 11724, 11724],
      32201: [7985, 7840, 4757, 8633, 11812, 11812],
      32401: [8048, 7905, 4822, 8701, 11896, 11896],
      32601: [8111, 7967, 4884, 8770, 11981, 11981],
      32801: [8174, 8032, 4949, 8838, 12069, 12069],
      33001: [8237, 8094, 5010, 8907, 12153, 12153],
      33201: [8301, 8159, 5075, 8975, 12241, 12241],
      33401: [8364, 8224, 5140, 9044, 12325, 12325],
      33601: [8427, 8286, 5202, 9113, 12410, 12410],
      33801: [8490, 8351, 5267, 9181, 12498, 12498],
      34001: [8553, 8412, 5329, 9250, 12582, 12582],
      34201: [8617, 8477, 5394, 9318, 12670, 12670],
      34401: [8680, 8539, 5456, 9387, 12754, 12754],
      34601: [8743, 8604, 5521, 9455, 12839, 12839],
      34801: [8806, 8666, 5582, 9524, 12927, 12927],
      35001: [8869, 8731, 5647, 9592, 13011, 13011],
      35201: [8932, 8793, 5709, 9661, 13099, 13099],
      35401: [8996, 8858, 5774, 9729, 13183, 13183],
      35601: [9059, 8919, 5836, 9798, 13268, 13268],
      35801: [9122, 8984, 5901, 9867, 13356, 13356],
      36001: [9185, 9049, 5966, 9935, 13440, 13440],
      36201: [9248, 9111, 6028, 10004, 13528, 13528],
      36401: [9311, 9176, 6093, 10072, 13612, 13612],
      36601: [9375, 9238, 6154, 10141, 13697, 13697],
      36801: [9438, 9303, 6219, 10209, 13785, 13785],
      37001: [9501, 9365, 6281, 10278, 13869, 13869],
      37201: [9564, 9430, 6346, 10346, 13957, 13957],
      37401: [9627, 9491, 6408, 10415, 14041, 14041],
      37601: [9690, 9556, 6473, 10483, 14126, 14126],
      37801: [9754, 9618, 6535, 10552, 14214, 14214],
      38001: [9817, 9683, 6600, 10620, 14298, 14298],
      38201: [9880, 9745, 6661, 10689, 14386, 14386],
      38401: [9943, 9810, 6726, 10758, 14470, 14470],
      38601: [10006, 9872, 6788, 10826, 14548, 14548],
      38801: [10069, 9933, 6850, 10894, 14626, 14626],
      39001: [10132, 9995, 6912, 10963, 14704, 14704],
      39201: [10195, 10060, 6977, 11031, 14782, 14782],
      39401: [10259, 10122, 7038, 11099, 14860, 14860],
      39601: [10337, 10200, 7116, 11167, 14938, 14938],
      39801: [10415, 10278, 7194, 11236, 15016, 15016],
      40001: [10493, 10356, 7272, 11304, 15094, 15094],
      40201: [10571, 10434, 7350, 11372, 15172, 15172],
      40401: [10649, 10512, 7428, 11441, 15250, 15250],
      40601: [10727, 10590, 7506, 11509, 15328, 15328],
      40801: [10805, 10668, 7584, 11577, 15406, 15406],
      41001: [10883, 10746, 7662, 11646, 15484, 15484],
      41201: [10961, 10824, 7740, 11714, 15562, 15562],
      41401: [11039, 10902, 7818, 11782, 15640, 15640],
      41601: [11117, 10980, 7896, 11851, 15718, 15718],
      41801: [11195, 11058, 7974, 11919, 15796, 15796],
      42001: [11273, 11136, 8052, 11987, 15874, 15874],
      42201: [11351, 11214, 8130, 12056, 15952, 15952],
      42401: [11429, 11292, 8208, 12124, 16030, 16030],
      42601: [11507, 11370, 8286, 12192, 16108, 16108],
      42801: [11585, 11448, 8364, 12260, 16186, 16186],
      43001: [11663, 11526, 8442, 12329, 16264, 16264],
      43201: [11741, 11604, 8520, 12397, 16342, 16342],
      43401: [11819, 11682, 8598, 12465, 16420, 16420],
      43601: [11897, 11760, 8676, 12534, 16498, 16498],
      43801: [11975, 11838, 8754, 12602, 16576, 16576],
      44001: [12053, 11916, 8832, 12670, 16654, 16654],
      44201: [12131, 11994, 8910, 12739, 16732, 16732],
      44401: [12209, 12072, 8988, 12807, 16810, 16810],
      44601: [12287, 12150, 9066, 12875, 16888, 16888],
      44801: [12365, 12228, 9144, 12944, 16966, 16966],
      45001: [12443, 12306, 9222, 13012, 17044, 17044],
      45201: [12521, 12384, 9300, 13080, 17122, 17122],
      45401: [12599, 12462, 9378, 13149, 17200, 17200],
      45601: [12677, 12540, 9456, 13217, 17278, 17278],
      45801: [12755, 12618, 9534, 13285, 17356, 17356],
      46001: [12833, 12696, 9612, 13353, 17434, 17434],
      46201: [12911, 12774, 9690, 13422, 17512, 17512],
      46401: [12989, 12852, 9768, 13490, 17590, 17590],
      46601: [13067, 12930, 9846, 13558, 17668, 17668],
      46801: [13145, 13008, 9924, 13627, 17746, 17746],
      47001: [13223, 13086, 10002, 13695, 17824, 17824],
      47201: [13301, 13164, 10080, 13763, 17902, 17902],
      47401: [13379, 13242, 10158, 13832, 17980, 17980],
      47601: [13457, 13320, 10236, 13900, 18058, 18058],
      47801: [13535, 13398, 10314, 13968, 18136, 18136],
      48001: [13613, 13476, 10392, 14037, 18214, 18214],
      48201: [13691, 13554, 10470, 14105, 18292, 18292],
      48401: [13769, 13632, 10548, 14173, 18370, 18370],
      48601: [13847, 13710, 10626, 14242, 18448, 18448],
      48801: [13925, 13788, 10704, 14310, 18526, 18526],
      49001: [14003, 13866, 10782, 14378, 18604, 18604],
      49201: [14081, 13944, 10860, 14446, 18682, 18682],
      49401: [14159, 14022, 10938, 14515, 18760, 18760],
      49601: [14237, 14100, 11016, 14583, 18838, 18838],
      49801: [14315, 14178, 11094, 14651, 18916, 18916],
      50001: [14393, 14256, 11172, 14720, 18994, 18994],
      50201: [14471, 14334, 11250, 14788, 19072, 19072],
      50401: [14549, 14412, 11328, 14856, 19150, 19150],
      50601: [14627, 14490, 11406, 14925, 19228, 19228],
      50801: [14705, 14568, 11484, 14993, 19306, 19306],
      51001: [14783, 14646, 11562, 15061, 19384, 19384],
      51201: [14861, 14724, 11640, 15130, 19462, 19462],
      51401: [14939, 14802, 11718, 15198, 19540, 19540],
      51601: [15017, 14880, 11796, 15266, 19618, 19618],
      51801: [15095, 14958, 11874, 15335, 19696, 19696],
      52001: [15173, 15036, 11952, 15403, 19774, 19774],
      52201: [15251, 15114, 12030, 15471, 19852, 19852],
      52401: [15329, 15192, 12108, 15539, 19930, 19930],
      52601: [15407, 15270, 12186, 15608, 20008, 20008],
      52801: [15485, 15348, 12264, 15676, 20086, 20086],
      53001: [15563, 15426, 12342, 15744, 20164, 20164],
      53201: [15641, 15504, 12420, 15813, 20242, 20242],
      53401: [15720, 15582, 12498, 15883, 20322, 20322],
      53601: [15838, 15705, 12622, 15991, 20440, 20440],
      53801: [15956, 15832, 12749, 16099, 20558, 20558],
      54001: [16074, 15959, 12875, 16208, 20676, 20676],
      54201: [16192, 16086, 13002, 16316, 20794, 20794],
      54401: [16310, 16212, 13129, 16424, 20912, 20912],
      54601: [16428, 16339, 13256, 16533, 21030, 21030],
      54801: [16546, 16466, 13382, 16641, 21148, 21148],
      55001: [16664, 16589, 13506, 16749, 21266, 21266],
      55201: [16782, 16716, 13633, 16858, 21384, 21384],
      55401: [16900, 16843, 13759, 16966, 21502, 21502],
      55601: [17018, 16970, 13886, 17074, 21620, 21620],
      55801: [17136, 17096, 14013, 17182, 21738, 21738],
      56001: [17254, 17223, 14140, 17291, 21856, 21856],
      56201: [17372, 17350, 14266, 17399, 21974, 21974],
      56401: [17490, 17477, 14393, 17507, 22092, 22092],
      56601: [17608, 17600, 14517, 17616, 22210, 22210],
      56801: [17726, 17727, 14643, 17724, 22328, 22328],
      57001: [17844, 17854, 14770, 17832, 22446, 22446],
      57201: [17962, 17980, 14897, 17941, 22564, 22564],
      57401: [18080, 18107, 15024, 18049, 22682, 22682],
      57601: [18198, 18234, 15150, 18157, 22800, 22800],
      57801: [18316, 18361, 15277, 18266, 22918, 22918],
      58001: [18434, 18487, 15404, 18374, 23036, 23036],
      58201: [18552, 18614, 15531, 18482, 23154, 23154],
      58401: [18670, 18738, 15654, 18591, 23272, 23272],
      58601: [18788, 18864, 15781, 18699, 23390, 23390],
      58801: [18906, 18991, 15908, 18807, 23508, 23508],
      59001: [19024, 19118, 16034, 18915, 23626, 23626],
      59201: [19142, 19245, 16161, 19024, 23744, 23744],
      59401: [19260, 19371, 16288, 19132, 23862, 23862],
      59601: [19378, 19498, 16415, 19240, 23980, 23980],
      59801: [19496, 19625, 16541, 19349, 24098, 24098],
      60001: [19614, 19748, 16665, 19457, 24216, 24216],
      60201: [19732, 19875, 16792, 19565, 24334, 24334],
      60401: [19850, 20002, 16918, 19674, 24452, 24452],
      60601: [19968, 20129, 17045, 19782, 24570, 24570],
      60801: [20086, 20255, 17172, 19890, 24688, 24688],
      61001: [20204, 20376, 17293, 19999, 24806, 24806],
      61201: [20322, 20494, 17411, 20107, 24924, 24924],
      61401: [20440, 20612, 17529, 20215, 25042, 25042],
      61601: [20558, 20730, 17647, 20324, 25160, 25160],
      61801: [20676, 20848, 17765, 20432, 25278, 25278],
      62001: [20794, 20966, 17883, 20540, 25396, 25396],
      62201: [20912, 21084, 18001, 20648, 25514, 25514],
      62401: [21030, 21202, 18119, 20757, 25632, 25632],
      62601: [21148, 21320, 18237, 20865, 25750, 25750],
      62801: [21266, 21438, 18355, 20973, 25868, 25868],
      63001: [21384, 21556, 18473, 21082, 25986, 25986],
      63201: [21502, 21674, 18591, 21190, 26104, 26104],
      63401: [21620, 21792, 18709, 21298, 26222, 26222],
      63601: [21738, 21910, 18827, 21407, 26340, 26340],
      63801: [21856, 22028, 18945, 21515, 26458, 26458],
      64001: [21974, 22146, 19063, 21623, 26576, 26576],
      64201: [22092, 22264, 19181, 21732, 26694, 26694],
      64401: [22210, 22382, 19299, 21840, 26812, 26812],
      64601: [22328, 22500, 19417, 21948, 26930, 26930],
      64801: [22446, 22618, 19535, 22057, 27048, 27048],
      65001: [22564, 22736, 19653, 22165, 27166, 27166],
      65201: [22682, 22854, 19771, 22273, 27284, 27284],
      65401: [22800, 22972, 19889, 22382, 27402, 27402],
      65601: [22918, 23090, 20007, 22490, 27520, 27520],
      65801: [23036, 23208, 20125, 22598, 27638, 27638],
      66001: [23154, 23326, 20243, 22706, 27756, 27756],
      66201: [23272, 23444, 20361, 22815, 27874, 27874],
      66401: [23390, 23562, 20479, 22923, 27992, 27992],
      66601: [23508, 23680, 20597, 23031, 28110, 28110],
      66801: [23626, 23798, 20715, 23140, 28228, 28228],
      67001: [23744, 23916, 20833, 23248, 28346, 28346],
      67201: [23862, 24034, 20951, 23356, 28464, 28464],
      67401: [23980, 24152, 21069, 23465, 28582, 28582],
      67601: [24098, 24270, 21187, 23573, 28700, 28700],
      67801: [24216, 24388, 21305, 23681, 28818, 28818],
      68001: [24334, 24506, 21423, 23790, 28936, 28936],
      68201: [24452, 24624, 21541, 23898, 29054, 29054],
      68401: [24570, 24742, 21659, 24006, 29172, 29172],
      68601: [24688, 24860, 21777, 24115, 29290, 29290],
      68801: [24806, 24978, 21895, 24223, 29408, 29408],
      69001: [24924, 25096, 22013, 24331, 29526, 29526],
      69201: [25042, 25214, 22131, 24439, 29644, 29644],
      69401: [25160, 25332, 22249, 24548, 29762, 29762],
      69601: [25278, 25450, 22367, 24656, 29880, 29880],
      69801: [25396, 25568, 22485, 24764, 29998, 29998],
      70001: [25514, 25686, 22603, 24873, 30116, 30116],
      70201: [25632, 25804, 22721, 24981, 30234, 30234],
      70401: [25750, 25922, 22839, 25089, 30352, 30352],
      70601: [25868, 26040, 22957, 25198, 30470, 30470],
      70801: [25986, 26158, 23075, 25306, 30588, 30588],
      71001: [26104, 26276, 23193, 25414, 30706, 30706],
      71201: [26222, 26394, 23311, 25523, 30824, 30824],
      71401: [26340, 26512, 23429, 25631, 30942, 30942],
      71601: [26458, 26630, 23547, 25739, 31060, 31060],
      71801: [26576, 26748, 23665, 25848, 31178, 31178],
      72001: [26694, 26866, 23783, 25956, 31296, 31296],
      72201: [26812, 26984, 23901, 26064, 31414, 31414],
      72401: [26930, 27102, 24019, 26172, 31532, 31532],
      72601: [27048, 27220, 24137, 26281, 31650, 31650],
      72801: [27166, 27338, 24255, 26389, 31768, 31768],
      73001: [27284, 27456, 24373, 26497, 31886, 31886],
      73201: [27402, 27574, 24491, 26606, 32004, 32004],
      73401: [27520, 27692, 24609, 26714, 32122, 32122],
      73601: [27638, 27810, 24727, 26822, 32240, 32240],
      73801: [27756, 27928, 24845, 26931, 32358, 32358],
      74001: [27874, 28046, 24963, 27039, 32476, 32476],
      74201: [27992, 28164, 25081, 27147, 32594, 32594],
      74401: [28110, 28282, 25199, 27256, 32712, 32712],
      74601: [28228, 28400, 25317, 27364, 32830, 32830],
      74801: [28346, 28518, 25435, 27472, 32948, 32948],
      75001: [28464, 28636, 25553, 27581, 33066, 33066],
      75201: [28582, 28754, 25671, 27689, 33184, 33184],
      75401: [28700, 28872, 25789, 27797, 33302, 33302],
      75601: [28818, 28990, 25907, 27905, 33420, 33420],
      75801: [28936, 29108, 26025, 28014, 33538, 33538],
      76001: [29054, 29226, 26143, 28122, 33656, 33656],
      76201: [29172, 29344, 26261, 28230, 33774, 33774],
      76401: [29290, 29462, 26379, 28339, 33892, 33892],
      76601: [29408, 29580, 26497, 28447, 34010, 34010],
      76801: [29526, 29698, 26615, 28555, 34128, 34128],
      77001: [29644, 29816, 26733, 28664, 34246, 34246],
      77201: [29762, 29934, 26851, 28772, 34364, 34364],
      77401: [29880, 30052, 26969, 28880, 34482, 34482],
      77601: [29998, 30170, 27087, 28989, 34600, 34600],
      77801: [30116, 30288, 27205, 29097, 34718, 34718],
      78001: [30234, 30406, 27323, 29205, 34836, 34836],
      78201: [30352, 30524, 27441, 29314, 34954, 34954],
      78401: [30470, 30642, 27559, 29422, 35072, 35072],
      78601: [30588, 30760, 27677, 29530, 35190, 35190],
      78801: [30706, 30878, 27795, 29638, 35308, 35308],
      79001: [30824, 30996, 27913, 29747, 35426, 35426],
      79201: [30942, 31114, 28031, 29855, 35544, 35544],
      79401: [31060, 31232, 28149, 29963, 35662, 35662],
      79601: [31178, 31350, 28267, 30072, 35780, 35780],
      79801: [31296, 31468, 28385, 30180, 35898, 35898],
      80001: [39, 39, 36, 38, 45, 45, "%"],
      80601: [39, 40, 36, 38, 45, 45, "%"],
      81401: [40, 40, 36, 38, 45, 45, "%"],
      83601: [40, 40, 37, 38, 46, 46, "%"],
      83801: [40, 40, 37, 39, 46, 46, "%"],
      85001: [40, 41, 37, 39, 46, 46, "%"],
      85801: [41, 41, 37, 39, 46, 46, "%"],
      87401: [41, 41, 38, 39, 46, 46, "%"],
      89601: [41, 41, 38, 40, 46, 46, "%"],
      89801: [41, 42, 38, 40, 46, 46, "%"],
      90401: [41, 42, 38, 40, 47, 47, "%"],
      90801: [42, 42, 38, 40, 47, 47, "%"],
      91601: [42, 42, 39, 40, 47, 47, "%"],
      95201: [42, 43, 39, 40, 47, 47, "%"],
      96201: [43, 43, 39, 41, 47, 47, "%"],
      96401: [43, 43, 40, 41, 47, 47, "%"],
      98201: [43, 43, 40, 41, 48, 48, "%"],
      101401: [43, 44, 40, 41, 48, 48, "%"],
      101601: [43, 44, 41, 41, 48, 48, "%"],
      102601: [44, 44, 41, 41, 48, 48, "%"],
      103801: [44, 44, 41, 42, 48, 48, "%"],
      107401: [44, 44, 42, 42, 48, 48, "%"],
      107601: [44, 44, 42, 42, 49, 49, "%"],
      108401: [44, 45, 42, 42, 49, 49, "%"],
      109601: [45, 45, 42, 42, 49, 49, "%"],
      112601: [45, 45, 42, 43, 49, 49, "%"],
      114001: [45, 45, 43, 43, 49, 49, "%"],
      116401: [45, 46, 43, 43, 49, 49, "%"],
      117801: [46, 46, 43, 43, 49, 49, "%"],
      118801: [46, 46, 43, 43, 50, 50, "%"],
      121201: [46, 46, 44, 43, 50, 50, "%"],
      123201: [46, 46, 44, 44, 50, 50, "%"],
      125801: [46, 47, 44, 44, 50, 50, "%"],
      127201: [47, 47, 44, 44, 50, 50, "%"],
      129601: [47, 47, 45, 44, 50, 50, "%"],
      132801: [47, 47, 45, 44, 51, 51, "%"],
      136001: [47, 47, 45, 45, 51, 51, "%"],
      136601: [47, 48, 45, 45, 51, 51, "%"],
      138201: [48, 48, 45, 45, 51, 51, "%"],
      139201: [48, 48, 46, 45, 51, 51, "%"],
      149801: [48, 49, 46, 45, 51, 51, "%"],
      150401: [48, 49, 47, 45, 51, 51, "%"],
      150601: [48, 49, 47, 45, 52, 52, "%"],
      151401: [49, 49, 47, 45, 52, 52, "%"],
      151801: [49, 49, 47, 46, 52, 52, "%"],
      163601: [49, 49, 48, 46, 52, 52, "%"],
      165401: [49, 50, 48, 46, 52, 52, "%"],
      167401: [50, 50, 48, 46, 52, 52, "%"],
      171601: [50, 50, 48, 47, 52, 52, "%"],
      173801: [50, 50, 48, 47, 53, 53, "%"],
      179001: [50, 50, 49, 47, 53, 53, "%"],
      185001: [50, 51, 49, 47, 53, 53, "%"],
      187001: [51, 51, 49, 47, 53, 53, "%"],
      197401: [51, 51, 49, 48, 53, 53, "%"],
      198001: [51, 51, 50, 48, 53, 53, "%"],
      205401: [51, 51, 50, 48, 54, 54, "%"],
      209601: [51, 52, 50, 48, 54, 54, "%"],
      212001: [52, 52, 50, 48, 54, 54, "%"],
      221201: [52, 52, 51, 48, 54, 54, "%"],
      232201: [52, 52, 51, 49, 54, 54, "%"],
      242001: [52, 53, 51, 49, 54, 54, "%"],
      244601: [53, 53, 51, 49, 54, 54, "%"],
      250801: [53, 53, 52, 49, 54, 54, "%"],
      251001: [53, 53, 52, 49, 55, 55, "%"],
      282201: [53, 53, 52, 50, 55, 55, "%"],
      286001: [53, 54, 52, 50, 55, 55, "%"],
      289001: [54, 54, 52, 50, 55, 55, "%"],
      289401: [54, 54, 53, 50, 55, 55, "%"],
      322801: [54, 54, 53, 50, 56, 56, "%"],
      342001: [54, 54, 54, 50, 56, 56, "%"],
      349401: [54, 55, 54, 50, 56, 56, "%"],
      353401: [55, 55, 54, 50, 56, 56, "%"],
      359401: [55, 55, 54, 51, 56, 56, "%"],
      418001: [55, 55, 55, 51, 56, 56, "%"],
      449401: [55, 56, 55, 51, 56, 56, "%"],
      452001: [55, 56, 55, 51, 57, 57, "%"],
      454201: [56, 56, 55, 51, 57, 57, "%"],
      494801: [56, 56, 55, 52, 57, 57, "%"],
      537401: [56, 56, 56, 52, 57, 57, "%"],
      629201: [56, 57, 56, 52, 57, 57, "%"],
      636001: [57, 57, 56, 52, 57, 57, "%"],
      752601: [57, 57, 57, 52, 57, 57, "%"],
      753401: [57, 57, 57, 52, 58, 58, "%"],
      793401: [57, 57, 57, 53, 58, 58, "%"],
      1048801: [57, 58, 57, 53, 58, 58, "%"],
      1060201: [58, 58, 57, 53, 58, 58, "%"],
      1254201: [58, 58, 58, 53, 58, 58, "%"]
    },
    40: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 10],
      2101: [152, 0, 150, 2, 152, 51],
      2201: [163, 0, 158, 4, 163, 92],
      2301: [173, 0, 166, 7, 173, 133],
      2401: [184, 0, 175, 9, 184, 174],
      2501: [194, 0, 183, 11, 215, 215],
      2601: [205, 0, 191, 13, 256, 256],
      2701: [215, 0, 200, 15, 297, 297],
      2801: [217, 0, 200, 17, 338, 338],
      2901: [228, 0, 208, 19, 379, 379],
      3001: [238, 0, 216, 22, 420, 420],
      3101: [249, 0, 225, 24, 461, 461],
      3201: [259, 0, 233, 26, 502, 502],
      3301: [270, 0, 241, 28, 543, 543],
      3401: [272, 0, 241, 30, 584, 584],
      3501: [282, 0, 250, 32, 625, 625],
      3601: [293, 0, 258, 35, 666, 666],
      3701: [303, 0, 266, 37, 707, 707],
      3801: [314, 0, 275, 39, 748, 748],
      3901: [324, 0, 283, 41, 789, 789],
      4001: [327, 0, 283, 43, 830, 830],
      4101: [337, 0, 291, 45, 871, 871],
      4201: [348, 0, 300, 48, 912, 912],
      4301: [358, 0, 308, 50, 953, 953],
      4401: [369, 0, 316, 62, 994, 994],
      4501: [379, 0, 325, 90, 1035, 1035],
      4601: [381, 0, 325, 118, 1076, 1076],
      4701: [392, 0, 333, 146, 1117, 1117],
      4801: [402, 0, 341, 173, 1154, 1154],
      4901: [412, 0, 350, 201, 1185, 1185],
      5001: [422, 0, 358, 228, 1219, 1219],
      5101: [432, 0, 366, 255, 1250, 1250],
      5201: [442, 0, 375, 283, 1284, 1284],
      5301: [444, 0, 375, 310, 1318, 1318],
      5401: [454, 17, 384, 337, 1349, 1349],
      5501: [464, 47, 394, 364, 1383, 1383],
      5601: [474, 78, 404, 391, 1413, 1413],
      5701: [484, 109, 414, 418, 1447, 1447],
      5801: [494, 136, 423, 445, 1480, 1480],
      5901: [496, 167, 425, 471, 1510, 1510],
      6001: [506, 198, 435, 498, 1544, 1544],
      6101: [516, 228, 445, 525, 1574, 1574],
      6201: [526, 259, 455, 552, 1608, 1608],
      6301: [543, 290, 465, 578, 1641, 1641],
      6401: [568, 321, 475, 605, 1671, 1671],
      6501: [593, 351, 476, 632, 1705, 1705],
      6601: [618, 382, 486, 658, 1735, 1735],
      6701: [643, 413, 496, 685, 1769, 1769],
      6801: [668, 444, 506, 712, 1802, 1802],
      6901: [692, 474, 516, 739, 1832, 1832],
      7001: [717, 502, 526, 766, 1866, 1866],
      7101: [742, 532, 528, 792, 1896, 1896],
      7201: [767, 563, 538, 819, 1930, 1930],
      7301: [792, 594, 547, 846, 1963, 1963],
      7401: [817, 625, 557, 872, 1993, 1993],
      7501: [842, 655, 567, 899, 2027, 2027],
      7601: [867, 686, 577, 926, 2057, 2057],
      7701: [892, 717, 587, 953, 2091, 2091],
      7801: [917, 748, 589, 980, 2124, 2124],
      7901: [942, 778, 599, 1006, 2154, 2154],
      8001: [967, 809, 609, 1033, 2188, 2188],
      8101: [991, 840, 619, 1060, 2218, 2218],
      8201: [1016, 867, 629, 1086, 2252, 2252],
      8301: [1041, 898, 639, 1113, 2285, 2285],
      8401: [1066, 929, 640, 1140, 2315, 2315],
      8501: [1091, 960, 650, 1167, 2349, 2349],
      8601: [1116, 990, 660, 1193, 2379, 2379],
      8701: [1141, 1021, 670, 1220, 2413, 2413],
      8801: [1166, 1052, 680, 1247, 2446, 2446],
      8901: [1191, 1083, 690, 1274, 2476, 2476],
      9001: [1216, 1113, 692, 1300, 2510, 2510],
      9101: [1241, 1144, 701, 1327, 2540, 2540],
      9201: [1266, 1175, 711, 1354, 2574, 2574],
      9301: [1291, 1206, 721, 1381, 2607, 2607],
      9401: [1315, 1233, 731, 1407, 2637, 2637],
      9501: [1340, 1264, 741, 1434, 2671, 2671],
      9601: [1365, 1284, 742, 1461, 2701, 2701],
      9701: [1390, 1308, 752, 1488, 2735, 2735],
      9801: [1415, 1332, 761, 1514, 2768, 2768],
      9901: [1440, 1359, 771, 1541, 2798, 2798],
      10001: [1465, 1383, 781, 1568, 2832, 2832],
      10101: [1490, 1406, 790, 1595, 2862, 2862],
      10201: [1515, 1430, 800, 1621, 2896, 2896],
      10301: [1540, 1457, 801, 1648, 2929, 2929],
      10401: [1565, 1480, 811, 1675, 2959, 2959],
      10501: [1590, 1504, 821, 1702, 2993, 2993],
      10601: [1615, 1527, 830, 1728, 3023, 3023],
      10701: [1639, 1554, 840, 1755, 3057, 3057],
      10801: [1664, 1577, 850, 1782, 3090, 3090],
      10901: [1689, 1601, 851, 1808, 3120, 3120],
      11001: [1714, 1624, 860, 1835, 3154, 3154],
      11101: [1739, 1651, 870, 1862, 3184, 3184],
      11201: [1764, 1675, 880, 1889, 3218, 3218],
      11301: [1789, 1698, 889, 1916, 3251, 3251],
      11401: [1814, 1722, 899, 1942, 3281, 3281],
      11501: [1839, 1749, 900, 1969, 3315, 3315],
      11601: [1864, 1772, 910, 1996, 3345, 3345],
      11701: [1889, 1795, 920, 2022, 3379, 3379],
      11801: [1914, 1819, 929, 2049, 3412, 3412],
      11901: [1939, 1846, 939, 2076, 3442, 3442],
      12001: [1964, 1869, 949, 2103, 3476, 3476],
      12101: [1988, 1893, 950, 2129, 3506, 3506],
      12201: [2013, 1920, 960, 2156, 3540, 3540],
      12301: [2038, 1943, 969, 2183, 3573, 3573],
      12401: [2063, 1967, 979, 2210, 3603, 3603],
      12501: [2088, 1990, 988, 2236, 3637, 3637],
      12601: [2113, 2017, 998, 2263, 3667, 3667],
      12701: [2138, 2040, 1008, 2290, 3701, 3701],
      12801: [2163, 2064, 1009, 2317, 3734, 3734],
      12901: [2188, 2087, 1019, 2343, 3764, 3764],
      13001: [2213, 2114, 1028, 2370, 3798, 3798],
      13101: [2238, 2138, 1038, 2397, 3828, 3828],
      13201: [2262, 2161, 1047, 2424, 3862, 3862],
      13301: [2288, 2185, 1057, 2450, 3899, 3899],
      13401: [2313, 2211, 1059, 2477, 3939, 3939],
      13501: [2338, 2235, 1068, 2505, 3979, 3979],
      13601: [2363, 2258, 1078, 2532, 4019, 4019],
      13701: [2388, 2282, 1087, 2559, 4060, 4060],
      13801: [2414, 2309, 1097, 2586, 4100, 4100],
      13901: [2439, 2332, 1107, 2613, 4140, 4140],
      14001: [2464, 2356, 1108, 2640, 4180, 4180],
      14101: [2489, 2379, 1118, 2667, 4220, 4220],
      14201: [2513, 2406, 1127, 2693, 4260, 4260],
      14301: [2538, 2429, 1137, 2719, 4299, 4299],
      14401: [2562, 2453, 1146, 2745, 4338, 4338],
      14501: [2586, 2480, 1156, 2771, 4377, 4377],
      14601: [2610, 2503, 1158, 2797, 4417, 4417],
      14701: [2634, 2527, 1167, 2823, 4456, 4456],
      14801: [2658, 2550, 1177, 2849, 4495, 4495],
      14901: [2683, 2577, 1186, 2875, 4534, 4534],
      15001: [2707, 2600, 1196, 2901, 4574, 4574],
      15101: [2731, 2624, 1206, 2927, 4613, 4613],
      15201: [2755, 2647, 1215, 2953, 4652, 4652],
      15301: [2780, 2674, 1217, 2979, 4698, 4698],
      15401: [2804, 2698, 1226, 3005, 4740, 4740],
      15501: [2828, 2721, 1236, 3031, 4783, 4783],
      15601: [2852, 2745, 1245, 3057, 4825, 4825],
      15701: [2877, 2772, 1255, 3083, 4868, 4868],
      15801: [2902, 2795, 1265, 3112, 4914, 4914],
      15901: [2933, 2829, 1267, 3146, 4956, 4956],
      16001: [2965, 2859, 1277, 3181, 4999, 4999],
      16101: [2997, 2892, 1287, 3215, 5041, 5041],
      16201: [3028, 2922, 1297, 3249, 5084, 5084],
      16301: [3060, 2956, 1307, 3284, 5130, 5130],
      16401: [3091, 2990, 1317, 3318, 5172, 5172],
      16501: [3123, 3020, 1319, 3352, 5215, 5215],
      16601: [3154, 3053, 1329, 3386, 5257, 5257],
      16701: [3186, 3083, 1339, 3421, 5300, 5300],
      16801: [3217, 3117, 1349, 3455, 5346, 5346],
      16901: [3249, 3147, 1359, 3489, 5388, 5388],
      17001: [3281, 3181, 1369, 3524, 5431, 5431],
      17101: [3312, 3211, 1371, 3558, 5473, 5473],
      17201: [3344, 3244, 1381, 3592, 5516, 5516],
      17301: [3375, 3275, 1391, 3627, 5561, 5561],
      17401: [3407, 3308, 1401, 3661, 5604, 5604],
      17501: [3438, 3342, 1411, 3695, 5646, 5646],
      17601: [3470, 3372, 1421, 3730, 5689, 5689],
      17701: [3502, 3405, 1431, 3764, 5732, 5732],
      17801: [3533, 3436, 1433, 3798, 5777, 5777],
      17901: [3565, 3469, 1443, 3833, 5820, 5820],
      18001: [3596, 3499, 1453, 3867, 5862, 5862],
      18101: [3628, 3533, 1463, 3901, 5905, 5905],
      18201: [3659, 3563, 1473, 3936, 5947, 5947],
      18301: [3691, 3597, 1483, 3970, 5993, 5993],
      18401: [3722, 3630, 1485, 4004, 6036, 6036],
      18501: [3754, 3660, 1495, 4038, 6078, 6078],
      18601: [3786, 3694, 1505, 4073, 6121, 6121],
      18701: [3817, 3724, 1515, 4107, 6163, 6163],
      18801: [3849, 3758, 1525, 4141, 6209, 6209],
      18901: [3880, 3788, 1535, 4176, 6252, 6252],
      19001: [3912, 3821, 1537, 4210, 6294, 6294],
      19101: [3943, 3852, 1547, 4244, 6337, 6337],
      19201: [3975, 3885, 1557, 4279, 6379, 6379],
      19301: [4007, 3919, 1567, 4313, 6425, 6425],
      19401: [4038, 3949, 1577, 4347, 6467, 6467],
      19501: [4070, 3982, 1587, 4382, 6510, 6510],
      19601: [4101, 4013, 1589, 4416, 6552, 6552],
      19701: [4133, 4046, 1599, 4450, 6595, 6595],
      19801: [4164, 4076, 1609, 4485, 6641, 6641],
      19901: [4196, 4110, 1619, 4519, 6683, 6683],
      20001: [4259, 4174, 1639, 4588, 6768, 6768],
      20201: [4322, 4237, 1650, 4656, 6857, 6857],
      20401: [4385, 4299, 1669, 4725, 6942, 6942],
      20601: [4448, 4361, 1687, 4794, 7027, 7027],
      20801: [4511, 4423, 1697, 4862, 7115, 7115],
      21001: [4575, 4485, 1716, 4931, 7200, 7200],
      21201: [4638, 4551, 1762, 4999, 7288, 7288],
      21401: [4701, 4613, 1810, 5068, 7373, 7373],
      21601: [4764, 4675, 1858, 5137, 7459, 7459],
      21801: [4827, 4737, 1906, 5205, 7547, 7547],
      22001: [4890, 4799, 1954, 5274, 7632, 7632],
      22201: [4953, 4861, 2002, 5343, 7720, 7720],
      22401: [5016, 4924, 2051, 5411, 7805, 7805],
      22601: [5080, 4986, 2099, 5480, 7890, 7890],
      22801: [5143, 5048, 2147, 5549, 7979, 7979],
      23001: [5207, 5110, 2195, 5619, 8065, 8065],
      23201: [5272, 5175, 2246, 5689, 8155, 8155],
      23401: [5337, 5238, 2295, 5759, 8242, 8242],
      23601: [5402, 5300, 2343, 5830, 8329, 8329],
      23801: [5467, 5362, 2391, 5900, 8419, 8419],
      24001: [5531, 5424, 2439, 5970, 8505, 8505],
      24201: [5596, 5486, 2487, 6041, 8595, 8595],
      24401: [5661, 5548, 2535, 6111, 8682, 8682],
      24601: [5726, 5614, 2587, 6181, 8769, 8769],
      24801: [5790, 5679, 2638, 6251, 8859, 8859],
      25001: [5855, 5741, 2686, 6322, 8945, 8945],
      25201: [5920, 5807, 2738, 6392, 9035, 9035],
      25401: [5985, 5869, 2786, 6462, 9122, 9122],
      25601: [6050, 5934, 2851, 6533, 9209, 9209],
      25801: [6114, 5996, 2913, 6603, 9299, 9299],
      26001: [6179, 6062, 2978, 6673, 9385, 9385],
      26201: [6244, 6124, 3041, 6743, 9475, 9475],
      26401: [6309, 6189, 3106, 6814, 9562, 9562],
      26601: [6373, 6252, 3168, 6884, 9649, 9649],
      26801: [6438, 6317, 3233, 6954, 9739, 9739],
      27001: [6503, 6379, 3296, 7025, 9825, 9825],
      27201: [6568, 6445, 3361, 7095, 9915, 9915],
      27401: [6633, 6507, 3423, 7165, 10002, 10002],
      27601: [6697, 6572, 3489, 7235, 10089, 10089],
      27801: [6762, 6638, 3554, 7306, 10179, 10179],
      28001: [6827, 6700, 3616, 7376, 10265, 10265],
      28201: [6892, 6765, 3682, 7446, 10355, 10355],
      28401: [6956, 6827, 3744, 7517, 10442, 10442],
      28601: [7021, 6893, 3809, 7587, 10529, 10529],
      28801: [7086, 6955, 3871, 7657, 10619, 10619],
      29001: [7151, 7020, 3937, 7727, 10705, 10705],
      29201: [7216, 7082, 3999, 7798, 10795, 10795],
      29401: [7280, 7148, 4064, 7868, 10882, 10882],
      29601: [7345, 7210, 4126, 7938, 10969, 10969],
      29801: [7410, 7275, 4192, 8009, 11059, 11059],
      30001: [7475, 7337, 4254, 8079, 11145, 11145],
      30201: [7539, 7403, 4319, 8149, 11235, 11235],
      30401: [7604, 7468, 4385, 8219, 11322, 11322],
      30601: [7669, 7530, 4447, 8290, 11409, 11409],
      30801: [7734, 7596, 4512, 8360, 11499, 11499],
      31001: [7798, 7658, 4575, 8430, 11585, 11585],
      31201: [7863, 7723, 4640, 8501, 11675, 11675],
      31401: [7928, 7786, 4702, 8571, 11762, 11762],
      31601: [7993, 7851, 4768, 8641, 11849, 11849],
      31801: [8058, 7913, 4830, 8712, 11939, 11939],
      32001: [8122, 7979, 4895, 8782, 12025, 12025],
      32201: [8187, 8042, 4958, 8852, 12115, 12115],
      32401: [8252, 8109, 5025, 8922, 12202, 12202],
      32601: [8317, 8172, 5088, 8993, 12289, 12289],
      32801: [8381, 8239, 5155, 9063, 12379, 12379],
      33001: [8446, 8302, 5218, 9133, 12465, 12465],
      33201: [8511, 8369, 5285, 9204, 12555, 12555],
      33401: [8576, 8435, 5352, 9274, 12642, 12642],
      33601: [8641, 8499, 5415, 9344, 12729, 12729],
      33801: [8705, 8565, 5482, 9414, 12819, 12819],
      34001: [8770, 8629, 5545, 9485, 12905, 12905],
      34201: [8835, 8695, 5612, 9555, 12995, 12995],
      34401: [8900, 8759, 5675, 9625, 13082, 13082],
      34601: [8964, 8825, 5742, 9696, 13169, 13169],
      34801: [9029, 8889, 5805, 9766, 13259, 13259],
      35001: [9094, 8955, 5872, 9836, 13345, 13345],
      35201: [9159, 9019, 5935, 9906, 13435, 13435],
      35401: [9223, 9085, 6002, 9977, 13522, 13522],
      35601: [9288, 9149, 6065, 10047, 13609, 13609],
      35801: [9353, 9215, 6132, 10117, 13699, 13699],
      36001: [9418, 9282, 6198, 10188, 13785, 13785],
      36201: [9483, 9345, 6262, 10258, 13875, 13875],
      36401: [9547, 9412, 6328, 10328, 13962, 13962],
      36601: [9612, 9475, 6392, 10398, 14049, 14049],
      36801: [9677, 9542, 6458, 10469, 14139, 14139],
      37001: [9742, 9605, 6522, 10539, 14225, 14225],
      37201: [9806, 9672, 6588, 10609, 14315, 14315],
      37401: [9871, 9735, 6652, 10680, 14402, 14402],
      37601: [9936, 9802, 6718, 10750, 14489, 14489],
      37801: [10001, 9865, 6782, 10820, 14579, 14579],
      38001: [10065, 9932, 6848, 10890, 14665, 14665],
      38201: [10130, 9995, 6912, 10961, 14755, 14755],
      38401: [10195, 10062, 6978, 11031, 14842, 14842],
      38601: [10260, 10125, 7042, 11101, 14922, 14922],
      38801: [10324, 10189, 7105, 11171, 15002, 15002],
      39001: [10389, 10252, 7168, 11241, 15082, 15082],
      39201: [10453, 10319, 7235, 11311, 15162, 15162],
      39401: [10519, 10382, 7298, 11381, 15242, 15242],
      39601: [10599, 10462, 7378, 11451, 15322, 15322],
      39801: [10679, 10542, 7458, 11521, 15402, 15402],
      40001: [10759, 10622, 7538, 11592, 15482, 15482],
      40201: [10839, 10702, 7618, 11662, 15562, 15562],
      40401: [10919, 10782, 7698, 11732, 15642, 15642],
      40601: [10999, 10862, 7778, 11802, 15722, 15722],
      40801: [11079, 10942, 7858, 11872, 15802, 15802],
      41001: [11159, 11022, 7938, 11942, 15882, 15882],
      41201: [11239, 11102, 8018, 12012, 15962, 15962],
      41401: [11319, 11182, 8098, 12082, 16042, 16042],
      41601: [11399, 11262, 8178, 12152, 16122, 16122],
      41801: [11479, 11342, 8258, 12222, 16202, 16202],
      42001: [11559, 11422, 8338, 12292, 16282, 16282],
      42201: [11639, 11502, 8418, 12362, 16362, 16362],
      42401: [11719, 11582, 8498, 12432, 16442, 16442],
      42601: [11799, 11662, 8578, 12502, 16522, 16522],
      42801: [11879, 11742, 8658, 12572, 16602, 16602],
      43001: [11959, 11822, 8738, 12642, 16682, 16682],
      43201: [12039, 11902, 8818, 12712, 16762, 16762],
      43401: [12119, 11982, 8898, 12782, 16842, 16842],
      43601: [12199, 12062, 8978, 12853, 16922, 16922],
      43801: [12279, 12142, 9058, 12923, 17002, 17002],
      44001: [12359, 12222, 9138, 12993, 17082, 17082],
      44201: [12439, 12302, 9218, 13063, 17162, 17162],
      44401: [12519, 12382, 9298, 13133, 17242, 17242],
      44601: [12599, 12462, 9378, 13203, 17322, 17322],
      44801: [12679, 12542, 9458, 13273, 17402, 17402],
      45001: [12759, 12622, 9538, 13343, 17482, 17482],
      45201: [12839, 12702, 9618, 13413, 17562, 17562],
      45401: [12919, 12782, 9698, 13483, 17642, 17642],
      45601: [12999, 12862, 9778, 13553, 17722, 17722],
      45801: [13079, 12942, 9858, 13623, 17802, 17802],
      46001: [13159, 13022, 9938, 13693, 17882, 17882],
      46201: [13239, 13102, 10018, 13763, 17962, 17962],
      46401: [13319, 13182, 10098, 13833, 18042, 18042],
      46601: [13399, 13262, 10178, 13903, 18122, 18122],
      46801: [13479, 13342, 10258, 13973, 18202, 18202],
      47001: [13559, 13422, 10338, 14043, 18282, 18282],
      47201: [13639, 13502, 10418, 14114, 18362, 18362],
      47401: [13719, 13582, 10498, 14184, 18442, 18442],
      47601: [13799, 13662, 10578, 14254, 18522, 18522],
      47801: [13879, 13742, 10658, 14324, 18602, 18602],
      48001: [13959, 13822, 10738, 14394, 18682, 18682],
      48201: [14039, 13902, 10818, 14464, 18762, 18762],
      48401: [14119, 13982, 10898, 14534, 18842, 18842],
      48601: [14199, 14062, 10978, 14604, 18922, 18922],
      48801: [14279, 14142, 11058, 14674, 19002, 19002],
      49001: [14359, 14222, 11138, 14744, 19082, 19082],
      49201: [14439, 14302, 11218, 14814, 19162, 19162],
      49401: [14519, 14382, 11298, 14884, 19242, 19242],
      49601: [14599, 14462, 11378, 14954, 19322, 19322],
      49801: [14679, 14542, 11458, 15024, 19402, 19402],
      50001: [14759, 14622, 11538, 15094, 19482, 19482],
      50201: [14839, 14702, 11618, 15164, 19562, 19562],
      50401: [14919, 14782, 11698, 15234, 19642, 19642],
      50601: [14999, 14862, 11778, 15305, 19722, 19722],
      50801: [15079, 14942, 11858, 15375, 19802, 19802],
      51001: [15159, 15022, 11938, 15445, 19882, 19882],
      51201: [15239, 15102, 12018, 15515, 19962, 19962],
      51401: [15319, 15182, 12098, 15585, 20042, 20042],
      51601: [15399, 15262, 12178, 15655, 20122, 20122],
      51801: [15479, 15342, 12258, 15725, 20202, 20202],
      52001: [15559, 15422, 12338, 15795, 20282, 20282],
      52201: [15639, 15502, 12418, 15865, 20362, 20362],
      52401: [15719, 15582, 12498, 15935, 20442, 20442],
      52601: [15799, 15662, 12578, 16005, 20522, 20522],
      52801: [15879, 15742, 12658, 16075, 20602, 20602],
      53001: [15959, 15822, 12738, 16145, 20682, 20682],
      53201: [16039, 15902, 12818, 16215, 20762, 20762],
      53401: [16120, 15982, 12898, 16287, 20844, 20844],
      53601: [16240, 16109, 13025, 16397, 20964, 20964],
      53801: [16360, 16239, 13155, 16507, 21084, 21084],
      54001: [16480, 16369, 13285, 16617, 21204, 21204],
      54201: [16600, 16499, 13415, 16727, 21324, 21324],
      54401: [16720, 16629, 13545, 16837, 21444, 21444],
      54601: [16840, 16759, 13675, 16947, 21564, 21564],
      54801: [16960, 16889, 13805, 17057, 21684, 21684],
      55001: [17080, 17015, 13932, 17167, 21804, 21804],
      55201: [17200, 17145, 14062, 17278, 21924, 21924],
      55401: [17320, 17275, 14192, 17388, 22044, 22044],
      55601: [17440, 17405, 14322, 17498, 22164, 22164],
      55801: [17560, 17535, 14452, 17608, 22284, 22284],
      56001: [17680, 17665, 14582, 17718, 22404, 22404],
      56201: [17800, 17795, 14712, 17828, 22524, 22524],
      56401: [17920, 17925, 14842, 17938, 22644, 22644],
      56601: [18040, 18052, 14968, 18048, 22764, 22764],
      56801: [18160, 18182, 15098, 18158, 22884, 22884],
      57001: [18280, 18312, 15228, 18268, 23004, 23004],
      57201: [18400, 18442, 15358, 18378, 23124, 23124],
      57401: [18520, 18572, 15488, 18488, 23244, 23244],
      57601: [18640, 18702, 15618, 18598, 23364, 23364],
      57801: [18760, 18832, 15748, 18708, 23484, 23484],
      58001: [18880, 18962, 15878, 18818, 23604, 23604],
      58201: [19e3, 19092, 16008, 18928, 23724, 23724],
      58401: [19120, 19219, 16135, 19038, 23844, 23844],
      58601: [19240, 19349, 16265, 19148, 23964, 23964],
      58801: [19360, 19479, 16395, 19259, 24084, 24084],
      59001: [19480, 19609, 16525, 19369, 24204, 24204],
      59201: [19600, 19739, 16655, 19479, 24324, 24324],
      59401: [19720, 19869, 16785, 19589, 24444, 24444],
      59601: [19840, 19999, 16915, 19699, 24564, 24564],
      59801: [19960, 20129, 17045, 19809, 24684, 24684],
      60001: [20080, 20255, 17172, 19919, 24804, 24804],
      60201: [20200, 20385, 17302, 20029, 24924, 24924],
      60401: [20320, 20515, 17432, 20139, 25044, 25044],
      60601: [20440, 20645, 17562, 20249, 25164, 25164],
      60801: [20560, 20775, 17692, 20359, 25284, 25284],
      61001: [20680, 20899, 17815, 20469, 25404, 25404],
      61201: [20800, 21019, 17935, 20579, 25524, 25524],
      61401: [20920, 21139, 18055, 20689, 25644, 25644],
      61601: [21040, 21259, 18175, 20799, 25764, 25764],
      61801: [21160, 21379, 18295, 20909, 25884, 25884],
      62001: [21280, 21499, 18415, 21019, 26004, 26004],
      62201: [21400, 21619, 18535, 21129, 26124, 26124],
      62401: [21520, 21739, 18655, 21240, 26244, 26244],
      62601: [21640, 21859, 18775, 21350, 26364, 26364],
      62801: [21760, 21979, 18895, 21460, 26484, 26484],
      63001: [21880, 22099, 19015, 21570, 26604, 26604],
      63201: [22e3, 22219, 19135, 21680, 26724, 26724],
      63401: [22120, 22339, 19255, 21790, 26844, 26844],
      63601: [22240, 22459, 19375, 21900, 26964, 26964],
      63801: [22360, 22579, 19495, 22010, 27084, 27084],
      64001: [22480, 22699, 19615, 22120, 27204, 27204],
      64201: [22600, 22819, 19735, 22230, 27324, 27324],
      64401: [22720, 22939, 19855, 22340, 27444, 27444],
      64601: [22840, 23059, 19975, 22450, 27564, 27564],
      64801: [22960, 23179, 20095, 22560, 27684, 27684],
      65001: [23080, 23299, 20215, 22670, 27804, 27804],
      65201: [23200, 23419, 20335, 22780, 27924, 27924],
      65401: [23320, 23539, 20455, 22890, 28044, 28044],
      65601: [23440, 23659, 20575, 23e3, 28164, 28164],
      65801: [23560, 23779, 20695, 23111, 28284, 28284],
      66001: [23680, 23899, 20815, 23221, 28404, 28404],
      66201: [23800, 24019, 20935, 23331, 28524, 28524],
      66401: [23920, 24139, 21055, 23441, 28644, 28644],
      66601: [24040, 24259, 21175, 23551, 28764, 28764],
      66801: [24160, 24379, 21295, 23661, 28884, 28884],
      67001: [24280, 24499, 21415, 23771, 29004, 29004],
      67201: [24400, 24619, 21535, 23881, 29124, 29124],
      67401: [24520, 24739, 21655, 23991, 29244, 29244],
      67601: [24640, 24859, 21775, 24101, 29364, 29364],
      67801: [24760, 24979, 21895, 24211, 29484, 29484],
      68001: [24880, 25099, 22015, 24321, 29604, 29604],
      68201: [25e3, 25219, 22135, 24431, 29724, 29724],
      68401: [25120, 25339, 22255, 24541, 29844, 29844],
      68601: [25240, 25459, 22375, 24651, 29964, 29964],
      68801: [25360, 25579, 22495, 24761, 30084, 30084],
      69001: [25480, 25699, 22615, 24871, 30204, 30204],
      69201: [25600, 25819, 22735, 24981, 30324, 30324],
      69401: [25720, 25939, 22855, 25092, 30444, 30444],
      69601: [25840, 26059, 22975, 25202, 30564, 30564],
      69801: [25960, 26179, 23095, 25312, 30684, 30684],
      70001: [26080, 26299, 23215, 25422, 30804, 30804],
      70201: [26200, 26419, 23335, 25532, 30924, 30924],
      70401: [26320, 26539, 23455, 25642, 31044, 31044],
      70601: [26440, 26659, 23575, 25752, 31164, 31164],
      70801: [26560, 26779, 23695, 25862, 31284, 31284],
      71001: [26680, 26899, 23815, 25972, 31404, 31404],
      71201: [26800, 27019, 23935, 26082, 31524, 31524],
      71401: [26920, 27139, 24055, 26192, 31644, 31644],
      71601: [27040, 27259, 24175, 26302, 31764, 31764],
      71801: [27160, 27379, 24295, 26412, 31884, 31884],
      72001: [27280, 27499, 24415, 26522, 32004, 32004],
      72201: [27400, 27619, 24535, 26632, 32124, 32124],
      72401: [27520, 27739, 24655, 26742, 32244, 32244],
      72601: [27640, 27859, 24775, 26852, 32364, 32364],
      72801: [27760, 27979, 24895, 26963, 32484, 32484],
      73001: [27880, 28099, 25015, 27073, 32604, 32604],
      73201: [28e3, 28219, 25135, 27183, 32724, 32724],
      73401: [28120, 28339, 25255, 27293, 32844, 32844],
      73601: [28240, 28459, 25375, 27403, 32964, 32964],
      73801: [28360, 28579, 25495, 27513, 33084, 33084],
      74001: [28480, 28699, 25615, 27623, 33204, 33204],
      74201: [28600, 28819, 25735, 27733, 33324, 33324],
      74401: [28720, 28939, 25855, 27843, 33444, 33444],
      74601: [28840, 29059, 25975, 27953, 33564, 33564],
      74801: [28960, 29179, 26095, 28063, 33684, 33684],
      75001: [29080, 29299, 26215, 28173, 33804, 33804],
      75201: [29200, 29419, 26335, 28283, 33924, 33924],
      75401: [29320, 29539, 26455, 28393, 34044, 34044],
      75601: [29440, 29659, 26575, 28503, 34164, 34164],
      75801: [29560, 29779, 26695, 28613, 34284, 34284],
      76001: [29680, 29899, 26815, 28723, 34404, 34404],
      76201: [29800, 30019, 26935, 28833, 34524, 34524],
      76401: [29920, 30139, 27055, 28944, 34644, 34644],
      76601: [30040, 30259, 27175, 29054, 34764, 34764],
      76801: [30160, 30379, 27295, 29164, 34884, 34884],
      77001: [30280, 30499, 27415, 29274, 35004, 35004],
      77201: [30400, 30619, 27535, 29384, 35124, 35124],
      77401: [30520, 30739, 27655, 29494, 35244, 35244],
      77601: [30640, 30859, 27775, 29604, 35364, 35364],
      77801: [30760, 30979, 27895, 29714, 35484, 35484],
      78001: [30880, 31099, 28015, 29824, 35604, 35604],
      78201: [31e3, 31219, 28135, 29934, 35724, 35724],
      78401: [31120, 31339, 28255, 30044, 35844, 35844],
      78601: [31240, 31459, 28375, 30154, 35964, 35964],
      78801: [31360, 31579, 28495, 30264, 36084, 36084],
      79001: [31480, 31699, 28615, 30374, 36204, 36204],
      79201: [31600, 31819, 28735, 30484, 36324, 36324],
      79401: [31720, 31939, 28855, 30594, 36444, 36444],
      79601: [31840, 32059, 28975, 30704, 36564, 36564],
      79801: [31960, 32179, 29095, 30815, 36684, 36684],
      80001: [40, 40, 36, 39, 46, 46, "%"],
      80401: [40, 40, 37, 39, 46, 46, "%"],
      81001: [40, 41, 37, 39, 46, 46, "%"],
      82201: [41, 41, 37, 39, 46, 46, "%"],
      83801: [41, 41, 37, 39, 47, 47, "%"],
      84001: [41, 41, 38, 39, 47, 47, "%"],
      85001: [41, 41, 38, 40, 47, 47, "%"],
      85401: [41, 42, 38, 40, 47, 47, "%"],
      86601: [42, 42, 38, 40, 47, 47, "%"],
      87801: [42, 42, 39, 40, 47, 47, "%"],
      90401: [42, 43, 39, 40, 48, 48, "%"],
      90801: [42, 43, 39, 41, 48, 48, "%"],
      91601: [43, 43, 39, 41, 48, 48, "%"],
      92201: [43, 43, 40, 41, 48, 48, "%"],
      95801: [43, 44, 40, 41, 48, 48, "%"],
      96801: [43, 44, 41, 41, 48, 48, "%"],
      97201: [44, 44, 41, 41, 48, 48, "%"],
      97601: [44, 44, 41, 42, 48, 48, "%"],
      98201: [44, 44, 41, 42, 49, 49, "%"],
      102001: [44, 45, 42, 42, 49, 49, "%"],
      103401: [45, 45, 42, 42, 49, 49, "%"],
      105401: [45, 45, 42, 43, 49, 49, "%"],
      107601: [45, 45, 42, 43, 50, 50, "%"],
      108001: [45, 45, 43, 43, 50, 50, "%"],
      109001: [45, 46, 43, 43, 50, 50, "%"],
      110601: [46, 46, 43, 43, 50, 50, "%"],
      114401: [46, 46, 44, 44, 50, 50, "%"],
      117001: [46, 47, 44, 44, 50, 50, "%"],
      118801: [47, 47, 44, 44, 50, 50, "%"],
      119001: [47, 47, 44, 44, 51, 51, "%"],
      121801: [47, 47, 45, 44, 51, 51, "%"],
      125401: [47, 47, 45, 45, 51, 51, "%"],
      126401: [47, 48, 45, 45, 51, 51, "%"],
      128201: [48, 48, 45, 45, 51, 51, "%"],
      130201: [48, 48, 46, 45, 51, 51, "%"],
      133001: [48, 48, 46, 45, 52, 52, "%"],
      137401: [48, 49, 46, 45, 52, 52, "%"],
      138601: [48, 49, 46, 46, 52, 52, "%"],
      139401: [49, 49, 46, 46, 52, 52, "%"],
      140001: [49, 49, 47, 46, 52, 52, "%"],
      150601: [49, 50, 47, 46, 52, 52, "%"],
      150801: [49, 50, 47, 46, 53, 53, "%"],
      151201: [49, 50, 48, 46, 53, 53, "%"],
      152601: [50, 50, 48, 46, 53, 53, "%"],
      154801: [50, 50, 48, 47, 53, 53, "%"],
      164201: [50, 50, 49, 47, 53, 53, "%"],
      166401: [50, 51, 49, 47, 53, 53, "%"],
      168801: [51, 51, 49, 47, 53, 53, "%"],
      174001: [51, 51, 49, 47, 54, 54, "%"],
      175401: [51, 51, 49, 48, 54, 54, "%"],
      180001: [51, 51, 50, 48, 54, 54, "%"],
      186001: [51, 52, 50, 48, 54, 54, "%"],
      188601: [52, 52, 50, 48, 54, 54, "%"],
      198801: [52, 52, 51, 48, 54, 54, "%"],
      202201: [52, 52, 51, 49, 54, 54, "%"],
      205601: [52, 52, 51, 49, 55, 55, "%"],
      210801: [52, 53, 51, 49, 55, 55, "%"],
      213801: [53, 53, 51, 49, 55, 55, "%"],
      222401: [53, 53, 52, 49, 55, 55, "%"],
      238801: [53, 53, 52, 50, 55, 55, "%"],
      243201: [53, 54, 52, 50, 55, 55, "%"],
      246601: [54, 54, 52, 50, 55, 55, "%"],
      251401: [54, 54, 52, 50, 56, 56, "%"],
      252001: [54, 54, 53, 50, 56, 56, "%"],
      287601: [54, 55, 53, 50, 56, 56, "%"],
      290801: [54, 55, 54, 50, 56, 56, "%"],
      291601: [55, 55, 54, 51, 56, 56, "%"],
      323201: [55, 55, 54, 51, 57, 57, "%"],
      343601: [55, 55, 55, 51, 57, 57, "%"],
      351401: [55, 56, 55, 51, 57, 57, "%"],
      356401: [56, 56, 55, 51, 57, 57, "%"],
      374201: [56, 56, 55, 52, 57, 57, "%"],
      420001: [56, 56, 56, 52, 57, 57, "%"],
      452001: [56, 57, 56, 52, 57, 57, "%"],
      452601: [56, 57, 56, 52, 58, 58, "%"],
      458201: [57, 57, 56, 52, 58, 58, "%"],
      522201: [57, 57, 56, 53, 58, 58, "%"],
      540001: [57, 57, 57, 53, 58, 58, "%"],
      632801: [57, 58, 57, 53, 58, 58, "%"],
      641601: [58, 58, 57, 53, 58, 58, "%"],
      754201: [58, 58, 57, 53, 59, 59, "%"],
      756201: [58, 58, 58, 53, 59, 59, "%"],
      864001: [58, 58, 58, 54, 59, 59, "%"],
      1054601: [58, 59, 58, 54, 59, 59, "%"],
      1069201: [59, 59, 58, 54, 59, 59, "%"],
      1260201: [59, 59, 59, 54, 59, 59, "%"]
    },
    41: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 10],
      2101: [152, 0, 150, 2, 152, 52],
      2201: [163, 0, 158, 4, 163, 94],
      2301: [173, 0, 166, 7, 173, 136],
      2401: [184, 0, 175, 9, 184, 178],
      2501: [194, 0, 183, 11, 220, 220],
      2601: [205, 0, 191, 13, 262, 262],
      2701: [215, 0, 200, 15, 304, 304],
      2801: [217, 0, 200, 17, 346, 346],
      2901: [228, 0, 208, 19, 388, 388],
      3001: [238, 0, 216, 22, 430, 430],
      3101: [249, 0, 225, 24, 472, 472],
      3201: [259, 0, 233, 26, 514, 514],
      3301: [270, 0, 241, 28, 556, 556],
      3401: [272, 0, 241, 30, 598, 598],
      3501: [282, 0, 250, 32, 640, 640],
      3601: [293, 0, 258, 35, 682, 682],
      3701: [303, 0, 266, 37, 724, 724],
      3801: [314, 0, 275, 39, 766, 766],
      3901: [324, 0, 283, 41, 808, 808],
      4001: [327, 0, 283, 43, 850, 850],
      4101: [337, 0, 291, 45, 892, 892],
      4201: [348, 0, 300, 48, 934, 934],
      4301: [358, 0, 308, 50, 976, 976],
      4401: [369, 0, 316, 63, 1018, 1018],
      4501: [379, 0, 325, 91, 1060, 1060],
      4601: [381, 0, 325, 120, 1102, 1102],
      4701: [392, 0, 333, 148, 1144, 1144],
      4801: [402, 0, 341, 176, 1182, 1182],
      4901: [412, 0, 350, 204, 1214, 1214],
      5001: [422, 0, 358, 232, 1249, 1249],
      5101: [432, 0, 366, 260, 1280, 1280],
      5201: [442, 0, 375, 288, 1315, 1315],
      5301: [444, 0, 375, 316, 1350, 1350],
      5401: [454, 17, 384, 344, 1382, 1382],
      5501: [464, 48, 394, 372, 1417, 1417],
      5601: [474, 80, 404, 399, 1448, 1448],
      5701: [484, 111, 414, 427, 1482, 1482],
      5801: [494, 139, 423, 454, 1516, 1516],
      5901: [496, 171, 425, 481, 1547, 1547],
      6001: [506, 202, 435, 509, 1582, 1582],
      6101: [516, 234, 445, 536, 1613, 1613],
      6201: [529, 265, 455, 564, 1647, 1647],
      6301: [555, 297, 465, 591, 1681, 1681],
      6401: [580, 328, 475, 618, 1712, 1712],
      6501: [606, 360, 476, 646, 1747, 1747],
      6601: [631, 391, 486, 673, 1778, 1778],
      6701: [657, 423, 496, 701, 1812, 1812],
      6801: [683, 454, 506, 728, 1846, 1846],
      6901: [708, 486, 516, 755, 1877, 1877],
      7001: [734, 514, 526, 783, 1912, 1912],
      7101: [759, 545, 528, 810, 1943, 1943],
      7201: [785, 577, 538, 838, 1977, 1977],
      7301: [810, 608, 547, 865, 2011, 2011],
      7401: [836, 640, 557, 893, 2042, 2042],
      7501: [861, 671, 567, 920, 2077, 2077],
      7601: [887, 703, 577, 947, 2108, 2108],
      7701: [912, 734, 587, 975, 2142, 2142],
      7801: [938, 766, 589, 1002, 2176, 2176],
      7901: [963, 797, 599, 1030, 2207, 2207],
      8001: [989, 829, 609, 1057, 2242, 2242],
      8101: [1014, 860, 619, 1084, 2273, 2273],
      8201: [1040, 888, 629, 1112, 2307, 2307],
      8301: [1066, 920, 639, 1139, 2341, 2341],
      8401: [1091, 951, 640, 1167, 2372, 2372],
      8501: [1117, 983, 650, 1194, 2407, 2407],
      8601: [1142, 1014, 660, 1221, 2438, 2438],
      8701: [1168, 1046, 670, 1249, 2472, 2472],
      8801: [1193, 1077, 680, 1276, 2506, 2506],
      8901: [1219, 1109, 690, 1304, 2537, 2537],
      9001: [1244, 1140, 692, 1331, 2572, 2572],
      9101: [1270, 1172, 701, 1358, 2603, 2603],
      9201: [1295, 1203, 711, 1386, 2637, 2637],
      9301: [1321, 1235, 721, 1413, 2671, 2671],
      9401: [1346, 1263, 731, 1441, 2702, 2702],
      9501: [1372, 1294, 741, 1468, 2737, 2737],
      9601: [1397, 1315, 742, 1495, 2768, 2768],
      9701: [1423, 1340, 752, 1523, 2802, 2802],
      9801: [1449, 1364, 761, 1550, 2836, 2836],
      9901: [1474, 1392, 771, 1578, 2867, 2867],
      10001: [1500, 1417, 781, 1605, 2902, 2902],
      10101: [1525, 1441, 790, 1632, 2933, 2933],
      10201: [1551, 1465, 800, 1660, 2967, 2967],
      10301: [1576, 1492, 801, 1687, 3001, 3001],
      10401: [1602, 1516, 811, 1715, 3032, 3032],
      10501: [1627, 1540, 821, 1742, 3067, 3067],
      10601: [1653, 1564, 830, 1769, 3098, 3098],
      10701: [1678, 1592, 840, 1797, 3132, 3132],
      10801: [1704, 1616, 850, 1824, 3166, 3166],
      10901: [1729, 1640, 851, 1852, 3197, 3197],
      11001: [1755, 1664, 860, 1879, 3232, 3232],
      11101: [1780, 1692, 870, 1906, 3263, 3263],
      11201: [1806, 1716, 880, 1934, 3297, 3297],
      11301: [1832, 1740, 889, 1961, 3331, 3331],
      11401: [1857, 1764, 899, 1989, 3362, 3362],
      11501: [1883, 1791, 900, 2016, 3397, 3397],
      11601: [1908, 1815, 910, 2043, 3428, 3428],
      11701: [1934, 1839, 920, 2071, 3462, 3462],
      11801: [1959, 1864, 929, 2098, 3496, 3496],
      11901: [1985, 1891, 939, 2126, 3527, 3527],
      12001: [2010, 1915, 949, 2153, 3562, 3562],
      12101: [2036, 1939, 950, 2180, 3593, 3593],
      12201: [2061, 1967, 960, 2208, 3627, 3627],
      12301: [2087, 1991, 969, 2235, 3661, 3661],
      12401: [2112, 2015, 979, 2263, 3692, 3692],
      12501: [2138, 2039, 988, 2290, 3727, 3727],
      12601: [2163, 2066, 998, 2317, 3758, 3758],
      12701: [2189, 2090, 1008, 2345, 3792, 3792],
      12801: [2215, 2114, 1009, 2372, 3826, 3826],
      12901: [2240, 2139, 1019, 2400, 3857, 3857],
      13001: [2266, 2166, 1028, 2427, 3892, 3892],
      13101: [2291, 2190, 1038, 2454, 3923, 3923],
      13201: [2317, 2214, 1047, 2482, 3957, 3957],
      13301: [2342, 2238, 1057, 2509, 3995, 3995],
      13401: [2368, 2266, 1059, 2537, 4036, 4036],
      13501: [2394, 2290, 1068, 2565, 4077, 4077],
      13601: [2420, 2314, 1078, 2592, 4119, 4119],
      13701: [2446, 2338, 1087, 2620, 4160, 4160],
      13801: [2471, 2365, 1097, 2648, 4201, 4201],
      13901: [2497, 2389, 1107, 2676, 4242, 4242],
      14001: [2523, 2414, 1108, 2703, 4284, 4284],
      14101: [2549, 2438, 1118, 2731, 4325, 4325],
      14201: [2574, 2465, 1127, 2757, 4365, 4365],
      14301: [2598, 2489, 1137, 2784, 4405, 4405],
      14401: [2623, 2513, 1146, 2811, 4445, 4445],
      14501: [2648, 2541, 1156, 2837, 4486, 4486],
      14601: [2673, 2565, 1158, 2864, 4526, 4526],
      14701: [2698, 2589, 1167, 2891, 4566, 4566],
      14801: [2722, 2613, 1177, 2918, 4606, 4606],
      14901: [2747, 2640, 1186, 2944, 4647, 4647],
      15001: [2772, 2664, 1196, 2971, 4687, 4687],
      15101: [2797, 2689, 1206, 2998, 4727, 4727],
      15201: [2822, 2713, 1215, 3024, 4767, 4767],
      15301: [2847, 2740, 1217, 3051, 4814, 4814],
      15401: [2871, 2764, 1226, 3078, 4858, 4858],
      15501: [2896, 2788, 1236, 3104, 4902, 4902],
      15601: [2921, 2812, 1245, 3131, 4945, 4945],
      15701: [2946, 2840, 1255, 3158, 4989, 4989],
      15801: [2972, 2864, 1265, 3187, 5036, 5036],
      15901: [3004, 2898, 1267, 3223, 5079, 5079],
      16001: [3037, 2929, 1277, 3258, 5123, 5123],
      16101: [3069, 2964, 1287, 3293, 5167, 5167],
      16201: [3101, 2994, 1297, 3328, 5210, 5210],
      16301: [3134, 3029, 1307, 3363, 5257, 5257],
      16401: [3166, 3063, 1317, 3399, 5301, 5301],
      16501: [3199, 3094, 1319, 3434, 5344, 5344],
      16601: [3231, 3129, 1329, 3469, 5388, 5388],
      16701: [3263, 3159, 1339, 3504, 5432, 5432],
      16801: [3296, 3194, 1349, 3539, 5478, 5478],
      16901: [3328, 3225, 1359, 3574, 5522, 5522],
      17001: [3360, 3259, 1369, 3610, 5566, 5566],
      17101: [3393, 3290, 1371, 3645, 5609, 5609],
      17201: [3425, 3324, 1381, 3680, 5653, 5653],
      17301: [3457, 3355, 1391, 3715, 5700, 5700],
      17401: [3490, 3390, 1401, 3750, 5743, 5743],
      17501: [3522, 3424, 1411, 3786, 5787, 5787],
      17601: [3555, 3455, 1421, 3821, 5831, 5831],
      17701: [3587, 3489, 1431, 3856, 5874, 5874],
      17801: [3619, 3520, 1433, 3891, 5921, 5921],
      17901: [3652, 3555, 1443, 3926, 5965, 5965],
      18001: [3684, 3586, 1453, 3962, 6008, 6008],
      18101: [3716, 3620, 1463, 3997, 6052, 6052],
      18201: [3749, 3651, 1473, 4032, 6096, 6096],
      18301: [3781, 3685, 1483, 4067, 6143, 6143],
      18401: [3813, 3720, 1485, 4102, 6186, 6186],
      18501: [3846, 3751, 1495, 4138, 6230, 6230],
      18601: [3878, 3785, 1505, 4173, 6273, 6273],
      18701: [3911, 3816, 1515, 4208, 6317, 6317],
      18801: [3943, 3850, 1525, 4243, 6364, 6364],
      18901: [3975, 3881, 1535, 4278, 6408, 6408],
      19001: [4008, 3916, 1537, 4314, 6451, 6451],
      19101: [4040, 3947, 1547, 4349, 6495, 6495],
      19201: [4072, 3981, 1557, 4384, 6538, 6538],
      19301: [4105, 4015, 1567, 4419, 6585, 6585],
      19401: [4137, 4046, 1577, 4454, 6629, 6629],
      19501: [4169, 4081, 1587, 4489, 6673, 6673],
      19601: [4202, 4112, 1589, 4525, 6716, 6716],
      19701: [4234, 4146, 1599, 4560, 6760, 6760],
      19801: [4266, 4177, 1609, 4595, 6807, 6807],
      19901: [4299, 4211, 1619, 4630, 6850, 6850],
      20001: [4364, 4277, 1639, 4701, 6938, 6938],
      20201: [4428, 4341, 1650, 4771, 7028, 7028],
      20401: [4493, 4405, 1672, 4841, 7115, 7115],
      20601: [4558, 4469, 1722, 4912, 7203, 7203],
      20801: [4622, 4533, 1772, 4982, 7293, 7293],
      21001: [4687, 4596, 1821, 5053, 7380, 7380],
      21201: [4752, 4663, 1874, 5123, 7471, 7471],
      21401: [4817, 4727, 1924, 5193, 7558, 7558],
      21601: [4881, 4791, 1974, 5264, 7645, 7645],
      21801: [4946, 4855, 2024, 5334, 7736, 7736],
      22001: [5011, 4918, 2073, 5404, 7823, 7823],
      22201: [5076, 4982, 2123, 5475, 7914, 7914],
      22401: [5140, 5046, 2173, 5545, 8001, 8001],
      22601: [5205, 5110, 2222, 5616, 8088, 8088],
      22801: [5270, 5173, 2272, 5686, 8179, 8179],
      23001: [5336, 5237, 2322, 5758, 8267, 8267],
      23201: [5402, 5304, 2375, 5830, 8360, 8360],
      23401: [5469, 5368, 2425, 5902, 8448, 8448],
      23601: [5535, 5432, 2474, 5974, 8537, 8537],
      23801: [5602, 5495, 2524, 6046, 8630, 8630],
      24001: [5668, 5559, 2574, 6118, 8718, 8718],
      24201: [5734, 5623, 2624, 6190, 8811, 8811],
      24401: [5801, 5686, 2673, 6262, 8899, 8899],
      24601: [5867, 5754, 2726, 6334, 8988, 8988],
      24801: [5933, 5821, 2780, 6406, 9081, 9081],
      25001: [6e3, 5884, 2829, 6478, 9169, 9169],
      25201: [6066, 5951, 2882, 6550, 9262, 9262],
      25401: [6133, 6015, 2932, 6622, 9350, 9350],
      25601: [6199, 6082, 2999, 6694, 9439, 9439],
      25801: [6265, 6146, 3062, 6766, 9532, 9532],
      26001: [6332, 6213, 3130, 6838, 9620, 9620],
      26201: [6398, 6277, 3193, 6911, 9713, 9713],
      26401: [6465, 6344, 3260, 6983, 9802, 9802],
      26601: [6531, 6408, 3324, 7055, 9890, 9890],
      26801: [6597, 6475, 3391, 7127, 9983, 9983],
      27001: [6664, 6538, 3455, 7199, 10071, 10071],
      27201: [6730, 6605, 3522, 7271, 10164, 10164],
      27401: [6796, 6669, 3586, 7343, 10252, 10252],
      27601: [6863, 6736, 3653, 7415, 10341, 10341],
      27801: [6929, 6803, 3720, 7487, 10434, 10434],
      28001: [6995, 6867, 3784, 7559, 10522, 10522],
      28201: [7062, 6934, 3851, 7631, 10615, 10615],
      28401: [7128, 6998, 3914, 7703, 10703, 10703],
      28601: [7195, 7065, 3981, 7775, 10792, 10792],
      28801: [7261, 7129, 4045, 7847, 10885, 10885],
      29001: [7327, 7196, 4112, 7919, 10973, 10973],
      29201: [7394, 7260, 4176, 7991, 11066, 11066],
      29401: [7460, 7327, 4243, 8063, 11154, 11154],
      29601: [7526, 7390, 4307, 8135, 11243, 11243],
      29801: [7593, 7457, 4374, 8207, 11336, 11336],
      30001: [7659, 7521, 4438, 8279, 11424, 11424],
      30201: [7726, 7588, 4505, 8351, 11517, 11517],
      30401: [7792, 7655, 4572, 8423, 11605, 11605],
      30601: [7858, 7719, 4636, 8495, 11694, 11694],
      30801: [7925, 7786, 4703, 8567, 11787, 11787],
      31001: [7991, 7850, 4766, 8639, 11875, 11875],
      31201: [8058, 7917, 4833, 8711, 11968, 11968],
      31401: [8124, 7981, 4897, 8783, 12056, 12056],
      31601: [8190, 8048, 4964, 8855, 12145, 12145],
      31801: [8257, 8111, 5028, 8927, 12238, 12238],
      32001: [8323, 8179, 5095, 8999, 12326, 12326],
      32201: [8389, 8243, 5160, 9072, 12419, 12419],
      32401: [8456, 8312, 5228, 9144, 12507, 12507],
      32601: [8522, 8377, 5293, 9216, 12596, 12596],
      32801: [8588, 8445, 5362, 9288, 12689, 12689],
      33001: [8655, 8510, 5426, 9360, 12777, 12777],
      33201: [8721, 8578, 5495, 9432, 12870, 12870],
      33401: [8788, 8647, 5563, 9504, 12958, 12958],
      33601: [8854, 8712, 5628, 9576, 13047, 13047],
      33801: [8920, 8780, 5696, 9648, 13140, 13140],
      34001: [8987, 8845, 5761, 9720, 13228, 13228],
      34201: [9053, 8913, 5830, 9792, 13321, 13321],
      34401: [9119, 8978, 5895, 9864, 13409, 13409],
      34601: [9186, 9046, 5963, 9936, 13498, 13498],
      34801: [9252, 9111, 6028, 10008, 13591, 13591],
      35001: [9319, 9180, 6096, 10080, 13679, 13679],
      35201: [9385, 9245, 6161, 10152, 13772, 13772],
      35401: [9451, 9313, 6229, 10224, 13860, 13860],
      35601: [9518, 9378, 6294, 10296, 13949, 13949],
      35801: [9584, 9446, 6363, 10368, 14042, 14042],
      36001: [9650, 9514, 6431, 10440, 14130, 14130],
      36201: [9717, 9579, 6496, 10512, 14223, 14223],
      36401: [9783, 9648, 6564, 10584, 14311, 14311],
      36601: [9850, 9713, 6629, 10656, 14400, 14400],
      36801: [9916, 9781, 6697, 10728, 14493, 14493],
      37001: [9982, 9846, 6762, 10800, 14581, 14581],
      37201: [10049, 9914, 6831, 10872, 14674, 14674],
      37401: [10115, 9979, 6896, 10944, 14762, 14762],
      37601: [10181, 10047, 6964, 11016, 14851, 14851],
      37801: [10248, 10112, 7029, 11088, 14944, 14944],
      38001: [10314, 10181, 7097, 11160, 15032, 15032],
      38201: [10381, 10246, 7162, 11233, 15125, 15125],
      38401: [10447, 10314, 7231, 11305, 15213, 15213],
      38601: [10513, 10379, 7295, 11376, 15295, 15295],
      38801: [10579, 10444, 7360, 11448, 15377, 15377],
      39001: [10645, 10509, 7425, 11520, 15459, 15459],
      39201: [10712, 10577, 7494, 11592, 15541, 15541],
      39401: [10779, 10642, 7558, 11664, 15623, 15623],
      39601: [10861, 10724, 7640, 11735, 15705, 15705],
      39801: [10943, 10806, 7722, 11807, 15787, 15787],
      40001: [11025, 10888, 7804, 11879, 15869, 15869],
      40201: [11107, 10970, 7886, 11951, 15952, 15952],
      40401: [11189, 11052, 7968, 12023, 16033, 16033],
      40601: [11271, 11134, 8050, 12094, 16115, 16115],
      40801: [11353, 11216, 8132, 12166, 16197, 16197],
      41001: [11435, 11298, 8214, 12238, 16279, 16279],
      41201: [11517, 11380, 8296, 12310, 16361, 16361],
      41401: [11599, 11462, 8378, 12382, 16443, 16443],
      41601: [11681, 11544, 8460, 12453, 16525, 16525],
      41801: [11763, 11626, 8542, 12525, 16607, 16607],
      42001: [11845, 11708, 8624, 12597, 16689, 16689],
      42201: [11927, 11790, 8706, 12669, 16771, 16771],
      42401: [12009, 11872, 8788, 12741, 16853, 16853],
      42601: [12091, 11954, 8870, 12812, 16935, 16935],
      42801: [12173, 12036, 8952, 12884, 17017, 17017],
      43001: [12255, 12118, 9034, 12956, 17099, 17099],
      43201: [12337, 12200, 9116, 13028, 17181, 17181],
      43401: [12419, 12282, 9198, 13100, 17263, 17263],
      43601: [12501, 12364, 9281, 13171, 17345, 17345],
      43801: [12583, 12446, 9362, 13243, 17427, 17427],
      44001: [12665, 12528, 9444, 13315, 17509, 17509],
      44201: [12747, 12610, 9526, 13387, 17591, 17591],
      44401: [12829, 12692, 9608, 13459, 17673, 17673],
      44601: [12911, 12774, 9690, 13530, 17755, 17755],
      44801: [12993, 12856, 9772, 13602, 17837, 17837],
      45001: [13075, 12938, 9854, 13674, 17919, 17919],
      45201: [13157, 13020, 9936, 13746, 18002, 18002],
      45401: [13239, 13102, 10018, 13818, 18083, 18083],
      45601: [13321, 13184, 10100, 13889, 18165, 18165],
      45801: [13403, 13266, 10182, 13961, 18247, 18247],
      46001: [13485, 13348, 10264, 14033, 18329, 18329],
      46201: [13567, 13430, 10346, 14105, 18411, 18411],
      46401: [13649, 13512, 10428, 14177, 18493, 18493],
      46601: [13731, 13594, 10510, 14248, 18575, 18575],
      46801: [13813, 13676, 10592, 14320, 18657, 18657],
      47001: [13895, 13758, 10674, 14392, 18739, 18739],
      47201: [13977, 13840, 10756, 14464, 18821, 18821],
      47401: [14059, 13922, 10838, 14536, 18903, 18903],
      47601: [14141, 14004, 10920, 14607, 18985, 18985],
      47801: [14223, 14086, 11002, 14679, 19067, 19067],
      48001: [14305, 14168, 11084, 14751, 19149, 19149],
      48201: [14387, 14250, 11166, 14823, 19231, 19231],
      48401: [14469, 14332, 11248, 14895, 19313, 19313],
      48601: [14551, 14414, 11331, 14966, 19395, 19395],
      48801: [14633, 14496, 11412, 15038, 19477, 19477],
      49001: [14715, 14578, 11494, 15110, 19559, 19559],
      49201: [14797, 14660, 11576, 15182, 19641, 19641],
      49401: [14879, 14742, 11658, 15254, 19723, 19723],
      49601: [14961, 14824, 11740, 15325, 19805, 19805],
      49801: [15043, 14906, 11822, 15397, 19887, 19887],
      50001: [15125, 14988, 11904, 15469, 19969, 19969],
      50201: [15207, 15070, 11986, 15541, 20052, 20052],
      50401: [15289, 15152, 12068, 15613, 20133, 20133],
      50601: [15371, 15234, 12150, 15684, 20215, 20215],
      50801: [15453, 15316, 12232, 15756, 20297, 20297],
      51001: [15535, 15398, 12314, 15828, 20379, 20379],
      51201: [15617, 15480, 12396, 15900, 20461, 20461],
      51401: [15699, 15562, 12478, 15972, 20543, 20543],
      51601: [15781, 15644, 12560, 16043, 20625, 20625],
      51801: [15863, 15726, 12642, 16115, 20707, 20707],
      52001: [15945, 15808, 12724, 16187, 20789, 20789],
      52201: [16027, 15890, 12806, 16259, 20871, 20871],
      52401: [16109, 15972, 12888, 16331, 20953, 20953],
      52601: [16191, 16054, 12970, 16402, 21035, 21035],
      52801: [16273, 16136, 13052, 16474, 21117, 21117],
      53001: [16355, 16218, 13134, 16546, 21199, 21199],
      53201: [16437, 16300, 13216, 16618, 21281, 21281],
      53401: [16520, 16382, 13298, 16691, 21365, 21365],
      53601: [16642, 16512, 13428, 16803, 21487, 21487],
      53801: [16764, 16645, 13562, 16915, 21609, 21609],
      54001: [16886, 16778, 13695, 17027, 21731, 21731],
      54201: [17008, 16912, 13828, 17138, 21853, 21853],
      54401: [17130, 17045, 13961, 17250, 21975, 21975],
      54601: [17252, 17178, 14095, 17362, 22097, 22097],
      54801: [17374, 17311, 14228, 17474, 22219, 22219],
      55001: [17496, 17441, 14358, 17586, 22341, 22341],
      55201: [17618, 17574, 14491, 17698, 22463, 22463],
      55401: [17740, 17708, 14624, 17809, 22585, 22585],
      55601: [17862, 17841, 14757, 17921, 22707, 22707],
      55801: [17984, 17974, 14891, 18033, 22829, 22829],
      56001: [18106, 18107, 15024, 18145, 22951, 22951],
      56201: [18228, 18241, 15157, 18256, 23073, 23073],
      56401: [18350, 18374, 15290, 18368, 23195, 23195],
      56601: [18472, 18504, 15420, 18480, 23317, 23317],
      56801: [18594, 18637, 15553, 18592, 23439, 23439],
      57001: [18716, 18770, 15687, 18704, 23561, 23561],
      57201: [18838, 18903, 15820, 18815, 23683, 23683],
      57401: [18960, 19037, 15953, 18927, 23805, 23805],
      57601: [19082, 19170, 16086, 19039, 23927, 23927],
      57801: [19204, 19303, 16220, 19151, 24049, 24049],
      58001: [19326, 19436, 16353, 19263, 24171, 24171],
      58201: [19448, 19570, 16486, 19374, 24293, 24293],
      58401: [19570, 19700, 16616, 19486, 24415, 24415],
      58601: [19692, 19833, 16749, 19598, 24537, 24537],
      58801: [19814, 19966, 16883, 19710, 24659, 24659],
      59001: [19936, 20099, 17016, 19822, 24781, 24781],
      59201: [20058, 20233, 17149, 19933, 24903, 24903],
      59401: [20180, 20366, 17282, 20045, 25025, 25025],
      59601: [20302, 20499, 17416, 20157, 25147, 25147],
      59801: [20424, 20632, 17549, 20269, 25269, 25269],
      60001: [20546, 20762, 17679, 20381, 25391, 25391],
      60201: [20668, 20895, 17812, 20493, 25513, 25513],
      60401: [20790, 21029, 17945, 20604, 25635, 25635],
      60601: [20912, 21162, 18078, 20716, 25757, 25757],
      60801: [21034, 21295, 18212, 20828, 25879, 25879],
      61001: [21156, 21421, 18338, 20940, 26001, 26001],
      61201: [21278, 21543, 18460, 21051, 26123, 26123],
      61401: [21400, 21665, 18582, 21163, 26245, 26245],
      61601: [21522, 21787, 18704, 21275, 26367, 26367],
      61801: [21644, 21909, 18826, 21387, 26489, 26489],
      62001: [21766, 22031, 18948, 21499, 26611, 26611],
      62201: [21888, 22153, 19070, 21610, 26733, 26733],
      62401: [22010, 22275, 19192, 21722, 26855, 26855],
      62601: [22132, 22397, 19314, 21834, 26977, 26977],
      62801: [22254, 22519, 19436, 21946, 27099, 27099],
      63001: [22376, 22641, 19558, 22058, 27221, 27221],
      63201: [22498, 22763, 19680, 22170, 27343, 27343],
      63401: [22620, 22885, 19802, 22281, 27465, 27465],
      63601: [22742, 23007, 19924, 22393, 27587, 27587],
      63801: [22864, 23129, 20046, 22505, 27709, 27709],
      64001: [22986, 23251, 20168, 22617, 27831, 27831],
      64201: [23108, 23373, 20290, 22729, 27953, 27953],
      64401: [23230, 23495, 20412, 22840, 28075, 28075],
      64601: [23352, 23617, 20534, 22952, 28197, 28197],
      64801: [23474, 23739, 20656, 23064, 28319, 28319],
      65001: [23596, 23861, 20778, 23176, 28441, 28441],
      65201: [23718, 23983, 20900, 23288, 28563, 28563],
      65401: [23840, 24105, 21022, 23399, 28685, 28685],
      65601: [23962, 24227, 21144, 23511, 28807, 28807],
      65801: [24084, 24349, 21266, 23623, 28929, 28929],
      66001: [24206, 24471, 21388, 23735, 29051, 29051],
      66201: [24328, 24593, 21510, 23847, 29173, 29173],
      66401: [24450, 24715, 21632, 23958, 29295, 29295],
      66601: [24572, 24837, 21754, 24070, 29417, 29417],
      66801: [24694, 24959, 21876, 24182, 29539, 29539],
      67001: [24816, 25081, 21998, 24294, 29661, 29661],
      67201: [24938, 25203, 22120, 24406, 29783, 29783],
      67401: [25060, 25325, 22242, 24517, 29905, 29905],
      67601: [25182, 25447, 22364, 24629, 30027, 30027],
      67801: [25304, 25569, 22486, 24741, 30149, 30149],
      68001: [25426, 25691, 22608, 24853, 30271, 30271],
      68201: [25548, 25813, 22730, 24965, 30393, 30393],
      68401: [25670, 25935, 22852, 25076, 30515, 30515],
      68601: [25792, 26057, 22974, 25188, 30637, 30637],
      68801: [25914, 26179, 23096, 25300, 30759, 30759],
      69001: [26036, 26301, 23218, 25412, 30881, 30881],
      69201: [26158, 26423, 23340, 25524, 31003, 31003],
      69401: [26280, 26545, 23462, 25635, 31125, 31125],
      69601: [26402, 26667, 23584, 25747, 31247, 31247],
      69801: [26524, 26789, 23706, 25859, 31369, 31369],
      70001: [26646, 26911, 23828, 25971, 31491, 31491],
      70201: [26768, 27033, 23950, 26083, 31613, 31613],
      70401: [26890, 27155, 24072, 26194, 31735, 31735],
      70601: [27012, 27277, 24194, 26306, 31857, 31857],
      70801: [27134, 27399, 24316, 26418, 31979, 31979],
      71001: [27256, 27521, 24438, 26530, 32101, 32101],
      71201: [27378, 27643, 24560, 26642, 32223, 32223],
      71401: [27500, 27765, 24682, 26753, 32345, 32345],
      71601: [27622, 27887, 24804, 26865, 32467, 32467],
      71801: [27744, 28009, 24926, 26977, 32589, 32589],
      72001: [27866, 28131, 25048, 27089, 32711, 32711],
      72201: [27988, 28253, 25170, 27201, 32833, 32833],
      72401: [28110, 28375, 25292, 27312, 32955, 32955],
      72601: [28232, 28497, 25414, 27424, 33077, 33077],
      72801: [28354, 28619, 25536, 27536, 33199, 33199],
      73001: [28476, 28741, 25658, 27648, 33321, 33321],
      73201: [28598, 28863, 25780, 27760, 33443, 33443],
      73401: [28720, 28985, 25902, 27871, 33565, 33565],
      73601: [28842, 29107, 26024, 27983, 33687, 33687],
      73801: [28964, 29229, 26146, 28095, 33809, 33809],
      74001: [29086, 29351, 26268, 28207, 33931, 33931],
      74201: [29208, 29473, 26390, 28319, 34053, 34053],
      74401: [29330, 29595, 26512, 28430, 34175, 34175],
      74601: [29452, 29717, 26634, 28542, 34297, 34297],
      74801: [29574, 29839, 26756, 28654, 34419, 34419],
      75001: [29696, 29961, 26878, 28766, 34541, 34541],
      75201: [29818, 30083, 27e3, 28878, 34663, 34663],
      75401: [29940, 30205, 27122, 28989, 34785, 34785],
      75601: [30062, 30327, 27244, 29101, 34907, 34907],
      75801: [30184, 30449, 27366, 29213, 35029, 35029],
      76001: [30306, 30571, 27488, 29325, 35151, 35151],
      76201: [30428, 30693, 27610, 29437, 35273, 35273],
      76401: [30550, 30815, 27732, 29548, 35395, 35395],
      76601: [30672, 30937, 27854, 29660, 35517, 35517],
      76801: [30794, 31059, 27976, 29772, 35639, 35639],
      77001: [30916, 31181, 28098, 29884, 35761, 35761],
      77201: [31038, 31303, 28220, 29996, 35883, 35883],
      77401: [31160, 31425, 28342, 30107, 36005, 36005],
      77601: [31282, 31547, 28464, 30219, 36127, 36127],
      77801: [31404, 31669, 28586, 30331, 36249, 36249],
      78001: [31526, 31791, 28708, 30443, 36371, 36371],
      78201: [31648, 31913, 28830, 30555, 36493, 36493],
      78401: [31770, 32035, 28952, 30666, 36615, 36615],
      78601: [31892, 32157, 29074, 30778, 36737, 36737],
      78801: [32014, 32279, 29196, 30890, 36859, 36859],
      79001: [32136, 32401, 29318, 31002, 36981, 36981],
      79201: [32258, 32523, 29440, 31114, 37103, 37103],
      79401: [32380, 32645, 29562, 31225, 37225, 37225],
      79601: [32502, 32767, 29684, 31337, 37347, 37347],
      79801: [32624, 32889, 29806, 31449, 37469, 37469],
      80001: [41, 41, 37, 39, 47, 47, "%"],
      80801: [41, 41, 38, 40, 47, 47, "%"],
      81401: [41, 42, 38, 40, 47, 47, "%"],
      82801: [42, 42, 38, 40, 47, 47, "%"],
      83801: [42, 42, 38, 40, 48, 48, "%"],
      84401: [42, 42, 39, 40, 48, 48, "%"],
      86001: [42, 43, 39, 41, 48, 48, "%"],
      87401: [43, 43, 39, 41, 48, 48, "%"],
      88201: [43, 43, 40, 41, 48, 48, "%"],
      90601: [43, 43, 40, 41, 49, 49, "%"],
      90801: [43, 44, 40, 41, 49, 49, "%"],
      92001: [43, 44, 40, 42, 49, 49, "%"],
      92401: [44, 44, 40, 42, 49, 49, "%"],
      92601: [44, 44, 41, 42, 49, 49, "%"],
      96401: [44, 45, 41, 42, 49, 49, "%"],
      97401: [44, 45, 42, 42, 49, 49, "%"],
      98001: [45, 45, 42, 42, 49, 49, "%"],
      98401: [45, 45, 42, 42, 50, 50, "%"],
      99001: [45, 45, 42, 43, 50, 50, "%"],
      102601: [45, 46, 43, 43, 50, 50, "%"],
      104201: [46, 46, 43, 43, 50, 50, "%"],
      107001: [46, 46, 43, 44, 50, 50, "%"],
      107801: [46, 46, 43, 44, 51, 51, "%"],
      108401: [46, 46, 44, 44, 51, 51, "%"],
      109601: [46, 47, 44, 44, 51, 51, "%"],
      111401: [47, 47, 44, 44, 51, 51, "%"],
      115001: [47, 47, 45, 44, 51, 51, "%"],
      116401: [47, 47, 45, 45, 51, 51, "%"],
      117801: [47, 48, 45, 45, 51, 51, "%"],
      119201: [47, 48, 45, 45, 52, 52, "%"],
      119801: [48, 48, 45, 45, 52, 52, "%"],
      122401: [48, 48, 46, 45, 52, 52, "%"],
      127201: [48, 49, 46, 45, 52, 52, "%"],
      127401: [48, 49, 46, 46, 52, 52, "%"],
      129401: [49, 49, 46, 46, 52, 52, "%"],
      130801: [49, 49, 47, 46, 52, 52, "%"],
      133201: [49, 49, 47, 46, 53, 53, "%"],
      138201: [49, 50, 47, 46, 53, 53, "%"],
      140601: [50, 50, 48, 46, 53, 53, "%"],
      141001: [50, 50, 48, 47, 53, 53, "%"],
      151001: [50, 50, 48, 47, 54, 54, "%"],
      151401: [50, 51, 48, 47, 54, 54, "%"],
      151801: [50, 51, 49, 47, 54, 54, "%"],
      154001: [51, 51, 49, 47, 54, 54, "%"],
      157801: [51, 51, 49, 48, 54, 54, "%"],
      165001: [51, 51, 50, 48, 54, 54, "%"],
      167401: [51, 52, 50, 48, 54, 54, "%"],
      170201: [52, 52, 50, 48, 54, 54, "%"],
      174201: [52, 52, 50, 48, 55, 55, "%"],
      179201: [52, 52, 50, 49, 55, 55, "%"],
      180801: [52, 52, 51, 49, 55, 55, "%"],
      187001: [52, 53, 51, 49, 55, 55, "%"],
      190201: [53, 53, 51, 49, 55, 55, "%"],
      199801: [53, 53, 52, 49, 55, 55, "%"],
      206001: [53, 53, 52, 49, 56, 56, "%"],
      207201: [53, 53, 52, 50, 56, 56, "%"],
      212001: [53, 54, 52, 50, 56, 56, "%"],
      215601: [54, 54, 52, 50, 56, 56, "%"],
      223401: [54, 54, 53, 50, 56, 56, "%"],
      244601: [54, 55, 53, 50, 56, 56, "%"],
      245601: [54, 55, 53, 51, 56, 56, "%"],
      248801: [55, 55, 53, 51, 56, 56, "%"],
      251801: [55, 55, 53, 51, 57, 57, "%"],
      253201: [55, 55, 54, 51, 57, 57, "%"],
      289201: [55, 56, 54, 51, 57, 57, "%"],
      292201: [55, 56, 55, 51, 57, 57, "%"],
      294001: [56, 56, 55, 51, 57, 57, "%"],
      301401: [56, 56, 55, 52, 57, 57, "%"],
      323601: [56, 56, 55, 52, 58, 58, "%"],
      345201: [56, 56, 56, 52, 58, 58, "%"],
      353401: [56, 57, 56, 52, 58, 58, "%"],
      359401: [57, 57, 56, 52, 58, 58, "%"],
      390201: [57, 57, 56, 53, 58, 58, "%"],
      422001: [57, 57, 57, 53, 58, 58, "%"],
      453201: [57, 57, 57, 53, 59, 59, "%"],
      454601: [57, 58, 57, 53, 59, 59, "%"],
      462001: [58, 58, 57, 53, 59, 59, "%"],
      542601: [58, 58, 58, 53, 59, 59, "%"],
      552801: [58, 58, 58, 54, 59, 59, "%"],
      636401: [58, 59, 58, 54, 59, 59, "%"],
      647001: [59, 59, 58, 54, 59, 59, "%"],
      755201: [59, 59, 58, 54, 60, 60, "%"],
      759601: [59, 59, 59, 54, 60, 60, "%"],
      947401: [59, 59, 59, 55, 60, 60, "%"],
      1060601: [59, 60, 59, 55, 60, 60, "%"],
      1078201: [60, 60, 59, 55, 60, 60, "%"],
      1266201: [60, 60, 60, 55, 60, 60, "%"]
    },
    42: {
      1: [0, 0, 0, 0, 0, 0],
      2001: [150, 0, 150, 0, 150, 10],
      2101: [152, 0, 150, 2, 152, 53],
      2201: [163, 0, 158, 4, 163, 96],
      2301: [173, 0, 166, 7, 173, 139],
      2401: [184, 0, 175, 9, 184, 182],
      2501: [194, 0, 183, 11, 225, 225],
      2601: [205, 0, 191, 13, 268, 268],
      2701: [215, 0, 200, 15, 311, 311],
      2801: [217, 0, 200, 17, 354, 354],
      2901: [228, 0, 208, 19, 397, 397],
      3001: [238, 0, 216, 22, 440, 440],
      3101: [249, 0, 225, 24, 483, 483],
      3201: [259, 0, 233, 26, 526, 526],
      3301: [270, 0, 241, 28, 569, 569],
      3401: [272, 0, 241, 30, 612, 612],
      3501: [282, 0, 250, 32, 655, 655],
      3601: [293, 0, 258, 35, 698, 698],
      3701: [303, 0, 266, 37, 741, 741],
      3801: [314, 0, 275, 39, 784, 784],
      3901: [324, 0, 283, 41, 827, 827],
      4001: [327, 0, 283, 43, 870, 870],
      4101: [337, 0, 291, 45, 913, 913],
      4201: [348, 0, 300, 48, 956, 956],
      4301: [358, 0, 308, 50, 999, 999],
      4401: [369, 0, 316, 63, 1042, 1042],
      4501: [379, 0, 325, 92, 1085, 1085],
      4601: [381, 0, 325, 121, 1128, 1128],
      4701: [392, 0, 333, 150, 1171, 1171],
      4801: [402, 0, 341, 179, 1211, 1211],
      4901: [412, 0, 350, 208, 1243, 1243],
      5001: [422, 0, 358, 236, 1279, 1279],
      5101: [432, 0, 366, 265, 1311, 1311],
      5201: [442, 0, 375, 294, 1347, 1347],
      5301: [444, 0, 375, 322, 1383, 1383],
      5401: [454, 17, 384, 351, 1415, 1415],
      5501: [464, 50, 394, 379, 1450, 1450],
      5601: [474, 82, 404, 407, 1482, 1482],
      5701: [484, 114, 414, 436, 1517, 1517],
      5801: [494, 143, 423, 464, 1553, 1553],
      5901: [496, 175, 425, 492, 1584, 1584],
      6001: [506, 207, 435, 520, 1619, 1619],
      6101: [516, 240, 445, 548, 1651, 1651],
      6201: [541, 272, 455, 576, 1686, 1686],
      6301: [567, 304, 465, 604, 1722, 1722],
      6401: [593, 336, 475, 632, 1753, 1753],
      6501: [619, 369, 476, 660, 1788, 1788],
      6601: [645, 401, 486, 688, 1820, 1820],
      6701: [671, 433, 496, 716, 1855, 1855],
      6801: [698, 465, 506, 744, 1891, 1891],
      6901: [724, 498, 516, 772, 1922, 1922],
      7001: [750, 526, 526, 800, 1957, 1957],
      7101: [776, 558, 528, 828, 1989, 1989],
      7201: [802, 591, 538, 856, 2024, 2024],
      7301: [828, 623, 547, 885, 2060, 2060],
      7401: [854, 655, 557, 913, 2091, 2091],
      7501: [881, 687, 567, 941, 2126, 2126],
      7601: [907, 720, 577, 969, 2158, 2158],
      7701: [933, 752, 587, 997, 2193, 2193],
      7801: [959, 784, 589, 1025, 2229, 2229],
      7901: [985, 816, 599, 1053, 2260, 2260],
      8001: [1011, 849, 609, 1081, 2295, 2295],
      8101: [1037, 881, 619, 1109, 2327, 2327],
      8201: [1063, 910, 629, 1137, 2362, 2362],
      8301: [1090, 942, 639, 1165, 2398, 2398],
      8401: [1116, 974, 640, 1193, 2429, 2429],
      8501: [1142, 1006, 650, 1221, 2464, 2464],
      8601: [1168, 1039, 660, 1249, 2496, 2496],
      8701: [1194, 1071, 670, 1277, 2531, 2531],
      8801: [1220, 1103, 680, 1306, 2567, 2567],
      8901: [1247, 1135, 690, 1333, 2598, 2598],
      9001: [1273, 1168, 692, 1362, 2633, 2633],
      9101: [1299, 1200, 701, 1390, 2665, 2665],
      9201: [1325, 1232, 711, 1418, 2700, 2700],
      9301: [1351, 1264, 721, 1446, 2736, 2736],
      9401: [1377, 1293, 731, 1474, 2767, 2767],
      9501: [1403, 1325, 741, 1502, 2802, 2802],
      9601: [1430, 1347, 742, 1530, 2834, 2834],
      9701: [1456, 1372, 752, 1558, 2869, 2869],
      9801: [1482, 1397, 761, 1586, 2905, 2905],
      9901: [1508, 1426, 771, 1614, 2936, 2936],
      10001: [1534, 1450, 781, 1642, 2971, 2971],
      10101: [1560, 1475, 790, 1670, 3003, 3003],
      10201: [1586, 1500, 800, 1698, 3038, 3038],
      10301: [1613, 1528, 801, 1726, 3074, 3074],
      10401: [1639, 1553, 811, 1754, 3105, 3105],
      10501: [1665, 1577, 821, 1783, 3140, 3140],
      10601: [1691, 1602, 830, 1811, 3172, 3172],
      10701: [1717, 1630, 840, 1839, 3207, 3207],
      10801: [1743, 1655, 850, 1867, 3243, 3243],
      10901: [1769, 1679, 851, 1895, 3274, 3274],
      11001: [1796, 1704, 860, 1923, 3309, 3309],
      11101: [1822, 1732, 870, 1951, 3341, 3341],
      11201: [1848, 1757, 880, 1979, 3376, 3376],
      11301: [1874, 1781, 889, 2007, 3412, 3412],
      11401: [1900, 1806, 899, 2035, 3443, 3443],
      11501: [1926, 1834, 900, 2063, 3478, 3478],
      11601: [1952, 1859, 910, 2091, 3510, 3510],
      11701: [1979, 1883, 920, 2119, 3545, 3545],
      11801: [2005, 1908, 929, 2147, 3581, 3581],
      11901: [2031, 1936, 939, 2175, 3612, 3612],
      12001: [2057, 1961, 949, 2203, 3647, 3647],
      12101: [2083, 1986, 950, 2231, 3679, 3679],
      12201: [2109, 2014, 960, 2260, 3714, 3714],
      12301: [2136, 2038, 969, 2288, 3750, 3750],
      12401: [2162, 2063, 979, 2316, 3781, 3781],
      12501: [2188, 2088, 988, 2344, 3816, 3816],
      12601: [2214, 2116, 998, 2372, 3848, 3848],
      12701: [2240, 2141, 1008, 2400, 3883, 3883],
      12801: [2266, 2165, 1009, 2428, 3919, 3919],
      12901: [2292, 2190, 1019, 2456, 3950, 3950],
      13001: [2319, 2218, 1028, 2484, 3986, 3986],
      13101: [2345, 2243, 1038, 2512, 4017, 4017],
      13201: [2371, 2267, 1047, 2540, 4052, 4052],
      13301: [2397, 2292, 1057, 2568, 4091, 4091],
      13401: [2424, 2320, 1059, 2597, 4133, 4133],
      13501: [2450, 2345, 1068, 2625, 4176, 4176],
      13601: [2476, 2369, 1078, 2653, 4218, 4218],
      13701: [2503, 2394, 1087, 2682, 4260, 4260],
      13801: [2529, 2422, 1097, 2710, 4302, 4302],
      13901: [2556, 2447, 1107, 2738, 4345, 4345],
      14001: [2582, 2471, 1108, 2767, 4387, 4387],
      14101: [2608, 2496, 1118, 2795, 4429, 4429],
      14201: [2634, 2524, 1127, 2822, 4470, 4470],
      14301: [2659, 2549, 1137, 2850, 4511, 4511],
      14401: [2685, 2574, 1146, 2877, 4553, 4553],
      14501: [2710, 2602, 1156, 2904, 4594, 4594],
      14601: [2736, 2626, 1158, 2932, 4635, 4635],
      14701: [2761, 2651, 1167, 2959, 4676, 4676],
      14801: [2786, 2676, 1177, 2986, 4718, 4718],
      14901: [2812, 2704, 1186, 3014, 4759, 4759],
      15001: [2837, 2728, 1196, 3041, 4800, 4800],
      15101: [2863, 2753, 1206, 3068, 4841, 4841],
      15201: [2888, 2778, 1215, 3096, 4883, 4883],
      15301: [2914, 2806, 1217, 3123, 4931, 4931],
      15401: [2939, 2831, 1226, 3151, 4975, 4975],
      15501: [2965, 2855, 1236, 3178, 5020, 5020],
      15601: [2990, 2880, 1245, 3205, 5065, 5065],
      15701: [3016, 2908, 1255, 3233, 5110, 5110],
      15801: [3042, 2933, 1265, 3263, 5158, 5158],
      15901: [3075, 2968, 1267, 3299, 5202, 5202],
      16001: [3108, 3e3, 1277, 3335, 5247, 5247],
      16101: [3142, 3035, 1287, 3371, 5292, 5292],
      16201: [3175, 3066, 1297, 3407, 5336, 5336],
      16301: [3208, 3102, 1307, 3443, 5385, 5385],
      16401: [3241, 3137, 1317, 3479, 5429, 5429],
      16501: [3274, 3169, 1319, 3515, 5474, 5474],
      16601: [3307, 3204, 1329, 3551, 5519, 5519],
      16701: [3341, 3235, 1339, 3588, 5563, 5563],
      16801: [3374, 3271, 1349, 3624, 5611, 5611],
      16901: [3407, 3302, 1359, 3660, 5656, 5656],
      17001: [3440, 3338, 1369, 3696, 5701, 5701],
      17101: [3473, 3369, 1371, 3732, 5745, 5745],
      17201: [3506, 3404, 1381, 3768, 5790, 5790],
      17301: [3540, 3436, 1391, 3804, 5838, 5838],
      17401: [3573, 3471, 1401, 3840, 5883, 5883],
      17501: [3606, 3507, 1411, 3876, 5928, 5928],
      17601: [3639, 3538, 1421, 3912, 5972, 5972],
      17701: [3672, 3573, 1431, 3948, 6017, 6017],
      17801: [3705, 3605, 1433, 3984, 6065, 6065],
      17901: [3739, 3640, 1443, 4020, 6110, 6110],
      18001: [3772, 3672, 1453, 4056, 6155, 6155],
      18101: [3805, 3707, 1463, 4092, 6199, 6199],
      18201: [3838, 3739, 1473, 4128, 6244, 6244],
      18301: [3871, 3774, 1483, 4165, 6292, 6292],
      18401: [3904, 3809, 1485, 4201, 6337, 6337],
      18501: [3938, 3841, 1495, 4237, 6381, 6381],
      18601: [3971, 3876, 1505, 4273, 6426, 6426],
      18701: [4004, 3908, 1515, 4309, 6471, 6471],
      18801: [4037, 3943, 1525, 4345, 6519, 6519],
      18901: [4070, 3975, 1535, 4381, 6564, 6564],
      19001: [4103, 4010, 1537, 4417, 6608, 6608],
      19101: [4137, 4042, 1547, 4453, 6653, 6653],
      19201: [4170, 4077, 1557, 4489, 6698, 6698],
      19301: [4203, 4112, 1567, 4525, 6746, 6746],
      19401: [4236, 4144, 1577, 4561, 6790, 6790],
      19501: [4269, 4179, 1587, 4597, 6835, 6835],
      19601: [4302, 4211, 1589, 4633, 6880, 6880],
      19701: [4336, 4246, 1599, 4669, 6925, 6925],
      19801: [4369, 4278, 1609, 4705, 6973, 6973],
      19901: [4402, 4313, 1622, 4741, 7017, 7017],
      20001: [4468, 4380, 1675, 4814, 7107, 7107],
      20201: [4535, 4446, 1727, 4886, 7200, 7200],
      20401: [4601, 4511, 1778, 4958, 7289, 7289],
      20601: [4667, 4577, 1830, 5030, 7378, 7378],
      20801: [4733, 4642, 1881, 5102, 7471, 7471],
      21001: [4800, 4707, 1932, 5174, 7560, 7560],
      21201: [4866, 4776, 1987, 5246, 7653, 7653],
      21401: [4932, 4841, 2038, 5319, 7743, 7743],
      21601: [4999, 4907, 2090, 5391, 7832, 7832],
      21801: [5065, 4972, 2141, 5463, 7925, 7925],
      22001: [5131, 5037, 2192, 5535, 8014, 8014],
      22201: [5198, 5103, 2244, 5607, 8107, 8107],
      22401: [5264, 5168, 2295, 5679, 8196, 8196],
      22601: [5330, 5233, 2346, 5751, 8286, 8286],
      22801: [5397, 5299, 2397, 5823, 8379, 8379],
      23001: [5465, 5364, 2449, 5897, 8470, 8470],
      23201: [5533, 5433, 2504, 5971, 8564, 8564],
      23401: [5601, 5498, 2555, 6045, 8655, 8655],
      23601: [5669, 5563, 2606, 6118, 8746, 8746],
      23801: [5737, 5629, 2658, 6192, 8841, 8841],
      24001: [5805, 5694, 2709, 6266, 8932, 8932],
      24201: [5873, 5759, 2760, 6340, 9026, 9026],
      24401: [5941, 5825, 2811, 6414, 9117, 9117],
      24601: [6008, 5893, 2866, 6487, 9208, 9208],
      24801: [6076, 5962, 2921, 6561, 9303, 9303],
      25001: [6144, 6027, 2972, 6635, 9394, 9394],
      25201: [6212, 6096, 3027, 6709, 9488, 9488],
      25401: [6280, 6161, 3078, 6782, 9579, 9579],
      25601: [6348, 6230, 3147, 6856, 9670, 9670],
      25801: [6416, 6295, 3212, 6930, 9765, 9765],
      26001: [6484, 6364, 3281, 7004, 9856, 9856],
      26201: [6552, 6430, 3346, 7078, 9950, 9950],
      26401: [6620, 6498, 3415, 7151, 10041, 10041],
      26601: [6688, 6564, 3480, 7225, 10132, 10132],
      26801: [6756, 6632, 3549, 7299, 10227, 10227],
      27001: [6824, 6698, 3614, 7373, 10318, 10318],
      27201: [6892, 6766, 3683, 7447, 10412, 10412],
      27401: [6960, 6832, 3748, 7520, 10503, 10503],
      27601: [7028, 6900, 3817, 7594, 10594, 10594],
      27801: [7096, 6969, 3886, 7668, 10689, 10689],
      28001: [7164, 7035, 3951, 7742, 10780, 10780],
      28201: [7232, 7103, 4020, 7815, 10874, 10874],
      28401: [7300, 7169, 4085, 7889, 10965, 10965],
      28601: [7368, 7237, 4154, 7963, 11056, 11056],
      28801: [7436, 7303, 4219, 8037, 11151, 11151],
      29001: [7504, 7371, 4288, 8110, 11242, 11242],
      29201: [7572, 7437, 4353, 8184, 11336, 11336],
      29401: [7640, 7505, 4422, 8258, 11427, 11427],
      29601: [7708, 7571, 4487, 8332, 11518, 11518],
      29801: [7776, 7640, 4556, 8406, 11613, 11613],
      30001: [7844, 7705, 4621, 8479, 11704, 11704],
      30201: [7912, 7774, 4690, 8553, 11798, 11798],
      30401: [7980, 7842, 4759, 8627, 11889, 11889],
      30601: [8048, 7908, 4824, 8701, 11980, 11980],
      30801: [8116, 7976, 4893, 8774, 12075, 12075],
      31001: [8184, 8042, 4958, 8848, 12166, 12166],
      31201: [8252, 8110, 5027, 8922, 12260, 12260],
      31401: [8320, 8176, 5092, 8996, 12351, 12351],
      31601: [8388, 8245, 5161, 9070, 12442, 12442],
      31801: [8456, 8310, 5226, 9143, 12537, 12537],
      32001: [8524, 8379, 5295, 9217, 12628, 12628],
      32201: [8592, 8445, 5362, 9291, 12722, 12722],
      32401: [8660, 8515, 5432, 9365, 12813, 12813],
      32601: [8728, 8582, 5498, 9438, 12904, 12904],
      32801: [8796, 8652, 5568, 9512, 12999, 12999],
      33001: [8864, 8718, 5635, 9586, 13090, 13090],
      33201: [8932, 8788, 5705, 9660, 13184, 13184],
      33401: [8999, 8858, 5775, 9734, 13275, 13275],
      33601: [9068, 8925, 5841, 9807, 13366, 13366],
      33801: [9135, 8995, 5911, 9881, 13461, 13461],
      34001: [9203, 9061, 5978, 9955, 13552, 13552],
      34201: [9271, 9131, 6048, 10029, 13646, 13646],
      34401: [9339, 9198, 6114, 10102, 13737, 13737],
      34601: [9407, 9268, 6184, 10176, 13828, 13828],
      34801: [9475, 9334, 6251, 10250, 13923, 13923],
      35001: [9543, 9404, 6321, 10324, 14014, 14014],
      35201: [9611, 9471, 6387, 10398, 14108, 14108],
      35401: [9679, 9541, 6457, 10471, 14199, 14199],
      35601: [9747, 9607, 6524, 10545, 14290, 14290],
      35801: [9815, 9677, 6594, 10619, 14385, 14385],
      36001: [9883, 9747, 6664, 10693, 14476, 14476],
      36201: [9951, 9814, 6730, 10766, 14570, 14570],
      36401: [10019, 9884, 6800, 10840, 14661, 14661],
      36601: [10087, 9950, 6867, 10914, 14752, 14752],
      36801: [10155, 10020, 6937, 10988, 14847, 14847],
      37001: [10223, 10087, 7003, 11062, 14938, 14938],
      37201: [10291, 10157, 7073, 11135, 15032, 15032],
      37401: [10359, 10223, 7140, 11209, 15123, 15123],
      37601: [10427, 10293, 7210, 11283, 15214, 15214],
      37801: [10495, 10360, 7276, 11357, 15309, 15309],
      38001: [10563, 10430, 7346, 11430, 15400, 15400],
      38201: [10631, 10496, 7413, 11504, 15494, 15494],
      38401: [10699, 10566, 7483, 11578, 15585, 15585],
      38601: [10767, 10633, 7549, 11652, 15669, 15669],
      38801: [10834, 10699, 7616, 11725, 15753, 15753],
      39001: [10902, 10766, 7682, 11799, 15837, 15837],
      39201: [10970, 10836, 7752, 11872, 15921, 15921],
      39401: [11039, 10902, 7819, 11946, 16005, 16005],
      39601: [11123, 10986, 7903, 12019, 16089, 16089],
      39801: [11207, 11070, 7987, 12093, 16173, 16173],
      40001: [11291, 11154, 8071, 12166, 16257, 16257],
      40201: [11375, 11238, 8155, 12240, 16341, 16341],
      40401: [11459, 11322, 8239, 12313, 16425, 16425],
      40601: [11543, 11406, 8323, 12387, 16509, 16509],
      40801: [11627, 11490, 8407, 12461, 16593, 16593],
      41001: [11711, 11574, 8491, 12534, 16677, 16677],
      41201: [11795, 11658, 8575, 12608, 16761, 16761],
      41401: [11879, 11742, 8659, 12681, 16845, 16845],
      41601: [11963, 11826, 8743, 12755, 16929, 16929],
      41801: [12047, 11910, 8827, 12828, 17013, 17013],
      42001: [12131, 11994, 8911, 12902, 17097, 17097],
      42201: [12215, 12078, 8995, 12975, 17181, 17181],
      42401: [12299, 12162, 9079, 13049, 17265, 17265],
      42601: [12383, 12246, 9163, 13122, 17349, 17349],
      42801: [12467, 12330, 9247, 13196, 17433, 17433],
      43001: [12551, 12414, 9331, 13270, 17517, 17517],
      43201: [12635, 12498, 9415, 13343, 17601, 17601],
      43401: [12719, 12582, 9499, 13417, 17685, 17685],
      43601: [12803, 12666, 9583, 13490, 17769, 17769],
      43801: [12887, 12750, 9667, 13564, 17853, 17853],
      44001: [12971, 12834, 9751, 13637, 17937, 17937],
      44201: [13055, 12918, 9835, 13711, 18021, 18021],
      44401: [13139, 13002, 9919, 13784, 18105, 18105],
      44601: [13223, 13086, 10003, 13858, 18189, 18189],
      44801: [13307, 13170, 10087, 13931, 18273, 18273],
      45001: [13391, 13254, 10171, 14005, 18357, 18357],
      45201: [13475, 13338, 10255, 14079, 18441, 18441],
      45401: [13559, 13422, 10339, 14152, 18525, 18525],
      45601: [13643, 13506, 10423, 14226, 18609, 18609],
      45801: [13727, 13590, 10507, 14299, 18693, 18693],
      46001: [13811, 13674, 10591, 14373, 18777, 18777],
      46201: [13895, 13758, 10675, 14446, 18861, 18861],
      46401: [13979, 13842, 10759, 14520, 18945, 18945],
      46601: [14063, 13926, 10843, 14593, 19029, 19029],
      46801: [14147, 14010, 10927, 14667, 19113, 19113],
      47001: [14231, 14094, 11011, 14740, 19197, 19197],
      47201: [14315, 14178, 11095, 14814, 19281, 19281],
      47401: [14399, 14262, 11179, 14888, 19365, 19365],
      47601: [14483, 14346, 11263, 14961, 19449, 19449],
      47801: [14567, 14430, 11347, 15035, 19533, 19533],
      48001: [14651, 14514, 11431, 15108, 19617, 19617],
      48201: [14735, 14598, 11515, 15182, 19701, 19701],
      48401: [14819, 14682, 11599, 15255, 19785, 19785],
      48601: [14903, 14766, 11683, 15329, 19869, 19869],
      48801: [14987, 14850, 11767, 15402, 19953, 19953],
      49001: [15071, 14934, 11851, 15476, 20037, 20037],
      49201: [15155, 15018, 11935, 15549, 20121, 20121],
      49401: [15239, 15102, 12019, 15623, 20205, 20205],
      49601: [15323, 15186, 12103, 15697, 20289, 20289],
      49801: [15407, 15270, 12187, 15770, 20373, 20373],
      50001: [15491, 15354, 12271, 15844, 20457, 20457],
      50201: [15575, 15438, 12355, 15917, 20541, 20541],
      50401: [15659, 15522, 12439, 15991, 20625, 20625],
      50601: [15743, 15606, 12523, 16064, 20709, 20709],
      50801: [15827, 15690, 12607, 16138, 20793, 20793],
      51001: [15911, 15774, 12691, 16211, 20877, 20877],
      51201: [15995, 15858, 12775, 16285, 20961, 20961],
      51401: [16079, 15942, 12859, 16358, 21045, 21045],
      51601: [16163, 16026, 12943, 16432, 21129, 21129],
      51801: [16247, 16110, 13027, 16505, 21213, 21213],
      52001: [16331, 16194, 13111, 16579, 21297, 21297],
      52201: [16415, 16278, 13195, 16653, 21381, 21381],
      52401: [16499, 16362, 13279, 16726, 21465, 21465],
      52601: [16583, 16446, 13363, 16800, 21549, 21549],
      52801: [16667, 16530, 13447, 16873, 21633, 21633],
      53001: [16751, 16614, 13531, 16947, 21717, 21717],
      53201: [16835, 16698, 13615, 17020, 21801, 21801],
      53401: [16920, 16782, 13699, 17096, 21887, 21887],
      53601: [17044, 16915, 13832, 17209, 22011, 22011],
      53801: [17168, 17052, 13968, 17323, 22135, 22135],
      54001: [17292, 17188, 14105, 17436, 22259, 22259],
      54201: [17416, 17325, 14241, 17550, 22383, 22383],
      54401: [17540, 17461, 14378, 17663, 22507, 22507],
      54601: [17664, 17598, 14514, 17777, 22631, 22631],
      54801: [17788, 17734, 14651, 17890, 22755, 22755],
      55001: [17912, 17867, 14784, 18004, 22879, 22879],
      55201: [18036, 18004, 14920, 18118, 23003, 23003],
      55401: [18160, 18140, 15057, 18231, 23127, 23127],
      55601: [18284, 18277, 15193, 18345, 23251, 23251],
      55801: [18408, 18413, 15330, 18458, 23375, 23375],
      56001: [18532, 18550, 15466, 18572, 23499, 23499],
      56201: [18656, 18686, 15603, 18685, 23623, 23623],
      56401: [18780, 18823, 15739, 18799, 23747, 23747],
      56601: [18904, 18956, 15872, 18912, 23871, 23871],
      56801: [19028, 19092, 16009, 19026, 23995, 23995],
      57001: [19152, 19229, 16145, 19139, 24119, 24119],
      57201: [19276, 19365, 16282, 19253, 24243, 24243],
      57401: [19400, 19502, 16418, 19366, 24367, 24367],
      57601: [19524, 19638, 16555, 19480, 24491, 24491],
      57801: [19648, 19775, 16691, 19594, 24615, 24615],
      58001: [19772, 19911, 16828, 19707, 24739, 24739],
      58201: [19896, 20048, 16964, 19821, 24863, 24863],
      58401: [20020, 20181, 17097, 19934, 24987, 24987],
      58601: [20144, 20317, 17234, 20048, 25111, 25111],
      58801: [20268, 20454, 17370, 20161, 25235, 25235],
      59001: [20392, 20590, 17507, 20275, 25359, 25359],
      59201: [20516, 20727, 17643, 20388, 25483, 25483],
      59401: [20640, 20863, 17780, 20502, 25607, 25607],
      59601: [20764, 21e3, 17916, 20615, 25731, 25731],
      59801: [20888, 21136, 18053, 20729, 25855, 25855],
      60001: [21012, 21269, 18186, 20843, 25979, 25979],
      60201: [21136, 21406, 18322, 20956, 26103, 26103],
      60401: [21260, 21542, 18459, 21070, 26227, 26227],
      60601: [21384, 21679, 18595, 21183, 26351, 26351],
      60801: [21508, 21815, 18732, 21297, 26475, 26475],
      61001: [21632, 21944, 18860, 21410, 26599, 26599],
      61201: [21756, 22068, 18984, 21524, 26723, 26723],
      61401: [21880, 22192, 19108, 21637, 26847, 26847],
      61601: [22004, 22316, 19232, 21751, 26971, 26971],
      61801: [22128, 22440, 19356, 21864, 27095, 27095],
      62001: [22252, 22564, 19480, 21978, 27219, 27219],
      62201: [22376, 22688, 19604, 22091, 27343, 27343],
      62401: [22500, 22812, 19728, 22205, 27467, 27467],
      62601: [22624, 22936, 19852, 22319, 27591, 27591],
      62801: [22748, 23060, 19976, 22432, 27715, 27715],
      63001: [22872, 23184, 20100, 22546, 27839, 27839],
      63201: [22996, 23308, 20224, 22659, 27963, 27963],
      63401: [23120, 23432, 20348, 22773, 28087, 28087],
      63601: [23244, 23556, 20472, 22886, 28211, 28211],
      63801: [23368, 23680, 20596, 23e3, 28335, 28335],
      64001: [23492, 23804, 20720, 23113, 28459, 28459],
      64201: [23616, 23928, 20844, 23227, 28583, 28583],
      64401: [23740, 24052, 20968, 23340, 28707, 28707],
      64601: [23864, 24176, 21092, 23454, 28831, 28831],
      64801: [23988, 24300, 21216, 23568, 28955, 28955],
      65001: [24112, 24424, 21340, 23681, 29079, 29079],
      65201: [24236, 24548, 21464, 23795, 29203, 29203],
      65401: [24360, 24672, 21588, 23908, 29327, 29327],
      65601: [24484, 24796, 21712, 24022, 29451, 29451],
      65801: [24608, 24920, 21836, 24135, 29575, 29575],
      66001: [24732, 25044, 21960, 24249, 29699, 29699],
      66201: [24856, 25168, 22084, 24362, 29823, 29823],
      66401: [24980, 25292, 22208, 24476, 29947, 29947],
      66601: [25104, 25416, 22332, 24589, 30071, 30071],
      66801: [25228, 25540, 22456, 24703, 30195, 30195],
      67001: [25352, 25664, 22580, 24817, 30319, 30319],
      67201: [25476, 25788, 22704, 24930, 30443, 30443],
      67401: [25600, 25912, 22828, 25044, 30567, 30567],
      67601: [25724, 26036, 22952, 25157, 30691, 30691],
      67801: [25848, 26160, 23076, 25271, 30815, 30815],
      68001: [25972, 26284, 23200, 25384, 30939, 30939],
      68201: [26096, 26408, 23324, 25498, 31063, 31063],
      68401: [26220, 26532, 23448, 25611, 31187, 31187],
      68601: [26344, 26656, 23572, 25725, 31311, 31311],
      68801: [26468, 26780, 23696, 25838, 31435, 31435],
      69001: [26592, 26904, 23820, 25952, 31559, 31559],
      69201: [26716, 27028, 23944, 26066, 31683, 31683],
      69401: [26840, 27152, 24068, 26179, 31807, 31807],
      69601: [26964, 27276, 24192, 26293, 31931, 31931],
      69801: [27088, 27400, 24316, 26406, 32055, 32055],
      70001: [27212, 27524, 24440, 26520, 32179, 32179],
      70201: [27336, 27648, 24564, 26633, 32303, 32303],
      70401: [27460, 27772, 24688, 26747, 32427, 32427],
      70601: [27584, 27896, 24812, 26860, 32551, 32551],
      70801: [27708, 28020, 24936, 26974, 32675, 32675],
      71001: [27832, 28144, 25060, 27087, 32799, 32799],
      71201: [27956, 28268, 25184, 27201, 32923, 32923],
      71401: [28080, 28392, 25308, 27315, 33047, 33047],
      71601: [28204, 28516, 25432, 27428, 33171, 33171],
      71801: [28328, 28640, 25556, 27542, 33295, 33295],
      72001: [28452, 28764, 25680, 27655, 33419, 33419],
      72201: [28576, 28888, 25804, 27769, 33543, 33543],
      72401: [28700, 29012, 25928, 27882, 33667, 33667],
      72601: [28824, 29136, 26052, 27996, 33791, 33791],
      72801: [28948, 29260, 26176, 28109, 33915, 33915],
      73001: [29072, 29384, 26300, 28223, 34039, 34039],
      73201: [29196, 29508, 26424, 28336, 34163, 34163],
      73401: [29320, 29632, 26548, 28450, 34287, 34287],
      73601: [29444, 29756, 26672, 28564, 34411, 34411],
      73801: [29568, 29880, 26796, 28677, 34535, 34535],
      74001: [29692, 30004, 26920, 28791, 34659, 34659],
      74201: [29816, 30128, 27044, 28904, 34783, 34783],
      74401: [29940, 30252, 27168, 29018, 34907, 34907],
      74601: [30064, 30376, 27292, 29131, 35031, 35031],
      74801: [30188, 30500, 27416, 29245, 35155, 35155],
      75001: [30312, 30624, 27540, 29358, 35279, 35279],
      75201: [30436, 30748, 27664, 29472, 35403, 35403],
      75401: [30560, 30872, 27788, 29585, 35527, 35527],
      75601: [30684, 30996, 27912, 29699, 35651, 35651],
      75801: [30808, 31120, 28036, 29813, 35775, 35775],
      76001: [30932, 31244, 28160, 29926, 35899, 35899],
      76201: [31056, 31368, 28284, 30040, 36023, 36023],
      76401: [31180, 31492, 28408, 30153, 36147, 36147],
      76601: [31304, 31616, 28532, 30267, 36271, 36271],
      76801: [31428, 31740, 28656, 30380, 36395, 36395],
      77001: [31552, 31864, 28780, 30494, 36519, 36519],
      77201: [31676, 31988, 28904, 30607, 36643, 36643],
      77401: [31800, 32112, 29028, 30721, 36767, 36767],
      77601: [31924, 32236, 29152, 30834, 36891, 36891],
      77801: [32048, 32360, 29276, 30948, 37015, 37015],
      78001: [32172, 32484, 29400, 31062, 37139, 37139],
      78201: [32296, 32608, 29524, 31175, 37263, 37263],
      78401: [32420, 32732, 29648, 31289, 37387, 37387],
      78601: [32544, 32856, 29772, 31402, 37511, 37511],
      78801: [32668, 32980, 29896, 31516, 37635, 37635],
      79001: [32792, 33104, 30020, 31629, 37759, 37759],
      79201: [32916, 33228, 30144, 31743, 37883, 37883],
      79401: [33040, 33352, 30268, 31856, 38007, 38007],
      79601: [33164, 33476, 30392, 31970, 38131, 38131],
      79801: [33288, 33600, 30516, 32083, 38255, 38255],
      80001: [42, 42, 38, 40, 48, 48, "%"],
      81201: [42, 42, 39, 40, 48, 48, "%"],
      81801: [42, 42, 39, 41, 48, 48, "%"],
      82001: [42, 43, 39, 41, 48, 48, "%"],
      83601: [43, 43, 39, 41, 48, 48, "%"],
      84001: [43, 43, 39, 41, 49, 49, "%"],
      84801: [43, 43, 40, 41, 49, 49, "%"],
      86401: [43, 44, 40, 41, 49, 49, "%"],
      87201: [43, 44, 40, 42, 49, 49, "%"],
      88001: [44, 44, 40, 42, 49, 49, "%"],
      88601: [44, 44, 41, 42, 49, 49, "%"],
      90601: [44, 44, 41, 42, 50, 50, "%"],
      91401: [44, 45, 41, 42, 50, 50, "%"],
      93001: [44, 45, 42, 42, 50, 50, "%"],
      93201: [45, 45, 42, 42, 50, 50, "%"],
      93401: [45, 45, 42, 43, 50, 50, "%"],
      96801: [45, 46, 42, 43, 50, 50, "%"],
      97801: [45, 46, 43, 43, 50, 50, "%"],
      98601: [45, 46, 43, 43, 51, 51, "%"],
      98801: [46, 46, 43, 43, 51, 51, "%"],
      100401: [46, 46, 43, 44, 51, 51, "%"],
      103001: [46, 46, 44, 44, 51, 51, "%"],
      103201: [46, 47, 44, 44, 51, 51, "%"],
      105201: [47, 47, 44, 44, 51, 51, "%"],
      108001: [47, 47, 44, 44, 52, 52, "%"],
      108601: [47, 47, 44, 45, 52, 52, "%"],
      109001: [47, 47, 45, 45, 52, 52, "%"],
      110201: [47, 48, 45, 45, 52, 52, "%"],
      112401: [48, 48, 45, 45, 52, 52, "%"],
      115601: [48, 48, 46, 45, 52, 52, "%"],
      118201: [48, 48, 46, 46, 52, 52, "%"],
      118401: [48, 49, 46, 46, 52, 52, "%"],
      119401: [48, 49, 46, 46, 53, 53, "%"],
      120801: [49, 49, 46, 46, 53, 53, "%"],
      123001: [49, 49, 47, 46, 53, 53, "%"],
      127801: [49, 50, 47, 46, 53, 53, "%"],
      129801: [49, 50, 47, 47, 53, 53, "%"],
      130401: [50, 50, 47, 47, 53, 53, "%"],
      131601: [50, 50, 48, 47, 53, 53, "%"],
      133401: [50, 50, 48, 47, 54, 54, "%"],
      139001: [50, 51, 48, 47, 54, 54, "%"],
      141201: [50, 51, 49, 47, 54, 54, "%"],
      141801: [51, 51, 49, 47, 54, 54, "%"],
      143801: [51, 51, 49, 48, 54, 54, "%"],
      151201: [51, 51, 49, 48, 55, 55, "%"],
      152201: [51, 52, 49, 48, 55, 55, "%"],
      152601: [51, 52, 50, 48, 55, 55, "%"],
      155201: [52, 52, 50, 48, 55, 55, "%"],
      161001: [52, 52, 50, 49, 55, 55, "%"],
      165801: [52, 52, 51, 49, 55, 55, "%"],
      168401: [52, 53, 51, 49, 55, 55, "%"],
      171601: [53, 53, 51, 49, 55, 55, "%"],
      174401: [53, 53, 51, 49, 56, 56, "%"],
      181601: [53, 53, 52, 49, 56, 56, "%"],
      183201: [53, 53, 52, 50, 56, 56, "%"],
      188201: [53, 54, 52, 50, 56, 56, "%"],
      191801: [54, 54, 52, 50, 56, 56, "%"],
      200801: [54, 54, 53, 50, 56, 56, "%"],
      206201: [54, 54, 53, 50, 57, 57, "%"],
      212401: [54, 54, 53, 51, 57, 57, "%"],
      213201: [54, 55, 53, 51, 57, 57, "%"],
      217401: [55, 55, 53, 51, 57, 57, "%"],
      224401: [55, 55, 54, 51, 57, 57, "%"],
      246001: [55, 56, 54, 51, 57, 57, "%"],
      250801: [56, 56, 54, 51, 57, 57, "%"],
      252001: [56, 56, 54, 51, 58, 58, "%"],
      252801: [56, 56, 54, 52, 58, 58, "%"],
      254401: [56, 56, 55, 52, 58, 58, "%"],
      290801: [56, 57, 55, 52, 58, 58, "%"],
      293401: [56, 57, 56, 52, 58, 58, "%"],
      296401: [57, 57, 56, 52, 58, 58, "%"],
      312001: [57, 57, 56, 53, 58, 58, "%"],
      324001: [57, 57, 56, 53, 59, 59, "%"],
      346801: [57, 57, 57, 53, 59, 59, "%"],
      355401: [57, 58, 57, 53, 59, 59, "%"],
      362401: [58, 58, 57, 53, 59, 59, "%"],
      407401: [58, 58, 57, 54, 59, 59, "%"],
      424001: [58, 58, 58, 54, 59, 59, "%"],
      453601: [58, 58, 58, 54, 60, 60, "%"],
      457001: [58, 59, 58, 54, 60, 60, "%"],
      466001: [59, 59, 58, 54, 60, 60, "%"],
      545201: [59, 59, 59, 54, 60, 60, "%"],
      586601: [59, 59, 59, 55, 60, 60, "%"],
      639801: [59, 60, 59, 55, 60, 60, "%"],
      652401: [60, 60, 59, 55, 60, 60, "%"],
      756201: [60, 60, 59, 55, 61, 61, "%"],
      763201: [60, 60, 60, 55, 61, 61, "%"],
      1047801: [60, 60, 60, 56, 61, 61, "%"],
      1066601: [60, 61, 60, 56, 61, 61, "%"],
      1087401: [61, 61, 60, 56, 61, 61, "%"],
      1272201: [61, 61, 61, 56, 61, 61, "%"]
    }
  },
  h = {
    2024: {
      columns: 7,
      retiredAge: 67
    },
    2025: {
      columns: 7,
      retiredAge: 67
    }
  },
  E = {
    age: 50,
    almostRetiredThreshold: 62,
    occupation: 0,
    income: 0,
    county: "",
    municipality: "",
    table: 33,
    taxYear: 2024
  },
  O = {
    2024: f,
    2025: v
  },
  D = {
    2024: A,
    2025: R
  },
  P = (o, e, n) => o > h[n].retiredAge && e === 1 ? 2 : o > h[n].retiredAge && e === 0 ? 3 : o < h[n].retiredAge && e === 1 ? 6 : 1,
  M = (o, e, n, t) => {
    let a = O[t],
      s = `${o}${e}`,
      i = a[s] || n;
    return i % 1 > .5 ? Math.ceil(i) : Math.floor(i);
  },
  I = o => {
    let e = {
        ...E,
        ...o
      },
      {
        occupation: n,
        county: t,
        municipality: a,
        table: s,
        taxYear: i,
        almostRetiredThreshold: T,
        age: c
      } = e,
      {
        income: l
      } = e,
      p = D[i],
      m = n === 0 && c >= T ? Math.floor(l * .7) : void 0,
      d = M(t, a, s, i),
      y = P(c, n, i),
      g = d,
      r = p[g][1];
    m && (l = m);
    for (let L of Object.keys(p[g])) {
      let C = Number.parseInt(L);
      if (C <= l) r = p[g][C];else break;
    }
    let b = y - 1,
      u = r[b];
    return u = r.length === h[i].columns ? u * (m || l) / 100 : u, {
      tax: u,
      taxColumn: y,
      taxPercent: r && r.length === h[i].columns ? r[b] : void 0,
      income: m || l,
      age: c,
      incomeAfterAgeReduction: m,
      table: d
    };
  };
var x = class {
  kalp;
  monthlyNetIncome = 0;
  monthlyIncome = 0;
  monthlyCost = 0;
  monthlyTaxFreeIncome = 0;
  monthlyStudyLoan = 0;
  monthlyIncomeTax = 0;
  monthlyOtherCosts = 0;
  reducedIncome = !1;
  yearlyLoanInterestAmount = 0;
  yearlyLoanTaxReturn = 0;
  monthlyLoanTaxReturn = 0;
  applicantsAge = 0;
  applicantsMaxAge = 0;
  applicantsMinAge = 0;
  applicantsAverageAge = 0;
  applicantsAges = [];
  monthlyLoanMortgage = 0;
  monthlyLoanInterest = 0;
  monthlyLoanCost = 0;
  newLoanTotalAmount = 0;
  monthlyLivingExpense = 0;
  childrenCount = 0;
  monthlyChildrenCost = 0;
  childrenBenefitTotal = 0;
  childrenBenefitExtra = 0;
  monthlyChildrenIncome = 0;
  debtQuota = null;
  loanDegree = 0;
  amortizationRequirement = 0;
  annualTotalIncome = 0;
  totalMortgageLoanAmount = 0;
  mortgageRequirementDebt = 0;
  mortgageRequirementValue = 0;
  marketValue = 0;
  monthlyPropertyCost = 0;
  monthlyPropertyTax = 0;
  monthlyPropertyRent = 0;
  monthlyPropertyRunCost = 0;
  monthlyCarCost = 0;
  carCount = 0;
  ownedCarCount = 0;
  leasedCarCount = 0;
  ownedCarCost = 0;
  leaseCarCost = 0;
  budget;
  income;
  cost;
  applicants = [];
  incomes = [];
  properties = [];
  loans = [];
  children = [];
  cars = [];
  pets = [];
  increasedInterest = 0;
  pMonthlyIncomeOverride = null;
  pOriginalIncome;
  pNewLoanTotalAmountOverride = null;
  parameters = {
    defaultTaxTable: 33,
    domain: "default",
    livingExpenseDeduction: [],
    livingExpenses: [{
      age: 65,
      expense: 9100
    }, {
      age: 200,
      expense: 5e3
    }],
    cityDwellerFactor: 1,
    almostRetiredThreshold: 62,
    almostRetiredLivingExpense: 5e3,
    almostRetiredIncomeFactor: .7,
    childrenBenefit: 1250,
    childrenBenefitExtra: [0, 0, 150, 730, 1740, 2990, 4240],
    loanDefaults: {
      MORTGAGE: {
        interest: .05,
        mortgagePercent: .05
      },
      BLANCO: {
        interest: .9,
        mortgagePercent: .1
      },
      CREDIT: {
        interest: .1,
        mortgagePercent: .1
      },
      BORGEN: {
        interest: .1,
        mortgagePercent: .1
      }
    },
    propertyDefaults: {
      VILLA: {
        runCost: 4500,
        yearlyTax: 9287
      },
      HYRES: {
        runCost: 200,
        yearlyTax: 0
      },
      BOSTADSRATT: {
        runCost: 200,
        yearlyTax: 0
      },
      FRITIDSHUS: {
        runCost: 1500,
        yearlyTax: 9287
      }
    },
    carDefaults: {
      LEASE: {
        cost: 0
      },
      OWN: {
        cost: 0
      }
    }
  };
  constructor(e = {}) {
    this.setParameters(e);
  }
  get monthlyIncomeOverride() {
    return this.pMonthlyIncomeOverride;
  }
  set monthlyIncomeOverride(e) {
    this.pMonthlyIncomeOverride = e, this.calculate();
  }
  get newLoanTotalAmountOverride() {
    return this.pNewLoanTotalAmountOverride;
  }
  set newLoanTotalAmountOverride(e) {
    this.pNewLoanTotalAmountOverride = e, this.calculate();
  }
  calculate() {
    return this.setApplicants(), this.setIncomes(), this.setCars(), this.setChildren(), this.setProperties(), this.setLoans(), this.calculateDebtQuota(), this.makeKalp();
  }
  add(e) {
    this[e.type].push(e);
  }
  set(e) {
    e.length !== 0 && (this[e[0].type] = e);
  }
  remove(e, n) {
    this[n] = this[n].filter(t => t.id !== e);
  }
  setIncreasedInterest(e) {
    this.increasedInterest = e / 100 / 100, this.calculate();
  }
  setParameters(e) {
    Object.assign(this.parameters, e);
  }
  makeKalp() {
    let e = this.monthlyIncome;
    return this.budget = this.monthlyLivingExpense + this.monthlyChildrenCost, this.income = e + this.monthlyChildrenIncome, this.cost = this.monthlyCost + this.monthlyPropertyCost + this.monthlyLoanCost + this.monthlyCarCost, this.kalp = this.income - this.budget - this.cost, this.kalp;
  }
  setCars() {
    this.monthlyCarCost = 0;
    let e = this.cars.filter(t => t.carType === "OWN");
    this.ownedCarCost = this.sumCarCost(e, "OWN"), this.ownedCarCount = e.length;
    let n = this.cars.filter(t => t.carType === "LEASE");
    this.leaseCarCost = this.sumCarCost(n, "LEASE"), this.leasedCarCount = n.length, this.carCount = this.leasedCarCount + this.ownedCarCount, this.monthlyCarCost = this.leaseCarCost + this.ownedCarCost;
  }
  sumCarCost(e, n) {
    return e.map(t => t.monthlyCost ?? 0).reduce((t, a) => t + Math.max(a, this.parameters.carDefaults[n].cost), 0);
  }
  getYearlyLoanInterest(e) {
    return (e || this.loans).map(t => t.amount * (t.interestRate + this.increasedInterest)).reduce((t, a) => t + a, 0);
  }
  getOnlyHousingYearlyLoanAmount() {
    return this.getYearlyLoanInterest(this.loans.filter(e => e.loanType === "MORTGAGE"));
  }
  calculateInterestReturn(e) {
    let t = e / this.applicants.length;
    return this.applicants.reduce(a => {
      let s = t > 1e5 ? (t - 1e5) * .21 + 3e4 : t * .3;
      return a + s;
    }, 0);
  }
  setApplicants() {
    this.applicants.forEach(n => this.calculateTaxAge(n)), this.monthlyLivingExpense = this.getLivingExpense(), this.applicantsMaxAge = Math.max(...this.applicantsAges), this.applicantsMinAge = Math.min(...this.applicantsAges);
    let e = this.applicantsAges.reduce((n, t) => n + t, 0);
    this.applicantsAverageAge = e / this.applicantsAges.length, this.applicantsAge = this.applicantsMinAge;
  }
  setLoans() {
    this.monthlyLoanCost = 0, this.monthlyLoanInterest = 0, this.monthlyLoanMortgage = 0, this.yearlyLoanInterestAmount = 0, this.loans.forEach(t => {
      t.originalAmount || (t.originalAmount = t.amount);
    }), this.newLoanTotalAmount = this.newLoanTotalAmount = this.loans.filter(t => t.new && t.id !== "new_property_extra").reduce((t, a) => t + a.amount, 0);
    let e = this.loans.filter(t => t.new && t.id !== "new_property_extra").map(t => t.amount / this.newLoanTotalAmount);
    this.loans.filter(t => t.new && t.id !== "new_property_extra").forEach((t, a) => {
      this.newLoanTotalAmountOverride !== null ? t.amount = this.pNewLoanTotalAmountOverride * e[a] : t.amount = t.originalAmount;
    }), this.loans.filter(t => !!t.new && t.loanType === "MORTGAGE").forEach(t => {
      t.mortgage < 0 && (t.mortgage = this.amortizationRequirement * t.amount / 12);
    }), this.loans.forEach(t => {
      if (t.mortgage < 0) {
        let a = this.parameters.loanDefaults[t.loanType].mortgagePercent * t.amount / 12;
        t.mortgage = Math.max(t.mortgage, a);
      }
      if (t.interestRate < 0) {
        let a = this.parameters.loanDefaults[t.loanType].interest;
        t.interestRate = Math.max(t.interestRate, a);
      }
      t.additionalLoan && (t.mortgage = t.amount / 120), t.monthlyInterest = t.amount * (t.interestRate + this.increasedInterest) / 12, t.monthlyCost = t.monthlyInterest + t.mortgage, this.monthlyLoanMortgage += t.mortgage, this.monthlyLoanInterest += t.monthlyInterest, this.monthlyLoanCost += t.monthlyCost;
    }), this.yearlyLoanInterestAmount = this.getYearlyLoanInterest(), this.monthlyLoanInterest = this.yearlyLoanInterestAmount / 12;
    let n = this.getOnlyHousingYearlyLoanAmount();
    this.yearlyLoanTaxReturn = this.calculateInterestReturn(n), this.monthlyLoanTaxReturn = this.yearlyLoanTaxReturn / 12, this.monthlyLoanCost = this.monthlyLoanCost - this.monthlyLoanTaxReturn;
  }
  setProperties() {
    this.calculateDebtQuota(), this.monthlyPropertyCost = 0, this.monthlyPropertyTax = 0, this.monthlyPropertyRent = 0, this.monthlyPropertyRunCost = 0, this.properties.forEach(e => {
      e.degree = null, e.runCost = e.runCost || 0, e.rent = e.rent || 0;
      let n = this.loans.filter(s => s.propertyId === e.id);
      e.sumOfLoans = n.map(s => s.amount).reduce((s, i) => s + i, 0);
      let t = Math.ceil(this.parameters.propertyDefaults[e.propertyType].yearlyTax / 12),
        a = Math.max(this.parameters.propertyDefaults[e.propertyType].runCost, e.runCost);
      e.monthlyCost = a + t + e.rent, e.tax = t, e.runCost = a, e.primary === !0 && this.setPrimaryBuilding(e), this.monthlyPropertyCost += e.monthlyCost, this.monthlyPropertyTax += e.tax, this.monthlyPropertyRent += e.rent, this.monthlyPropertyRunCost += e.runCost;
    });
  }
  setPrimaryBuilding(e) {
    e.marketValue = e.marketValue || e.loanAmount, e.mortgageRequirementDebt = e.mortgageRequirementDebt || e.sumOfLoans, e.mortgageRequirementValue = e.mortgageRequirementValue || e.marketValue, this.mortgageRequirementValue = e.mortgageRequirementValue, this.marketValue = e.marketValue, this.mortgageRequirementDebt = e.mortgageRequirementDebt, e.degree = e.mortgageRequirementDebt / e.mortgageRequirementValue, this.calculateAmortizationRequirement(e);
  }
  calculateAmortizationRequirement(e) {
    let n = e.degree;
    this.loanDegree = n;
    let t = 0;
    return [{
      degree: .5,
      result: .01
    }, {
      degree: .7,
      result: .02
    }].forEach(s => {
      n > s.degree && (t = s.result);
    }), this.debtQuota > 4.5 && (t += .01), this.amortizationRequirement = t, t;
  }
  setChildren() {
    this.childrenCount = this.children.length, this.monthlyChildrenCost = this.children.map(e => (e.monthlyCost = 0, e.age = e.age || 17, this.parameters.livingExpenses.forEach(n => {
      e.monthlyCost = e.monthlyCost === 0 && e.age <= n.age ? n.expense : e.monthlyCost;
    }), e.monthlyCost)).reduce((e, n) => e + n, 0), this.childrenBenefitTotal = this.children.filter(e => e.age < 19).map(e => e.age <= 15 ? this.parameters.childrenBenefit : Math.floor(this.parameters.childrenBenefit * 10 / 12)).reduce((e, n) => e + n, 0), this.childrenBenefitExtra = this.parameters.childrenBenefitExtra[Math.min(this.childrenCount, 6)], this.monthlyChildrenIncome = this.childrenBenefitExtra + this.childrenBenefitTotal;
  }
  getLivingExpense() {
    if (this.applicants.forEach(e => {
      e.livingExpense = 0, this.applicantsAges.push(e.age);
      let n = e.occupationType === "RETIRED" ? 65 : e.age;
      this.parameters.livingExpenses.forEach(t => {
        e.livingExpense = e.livingExpense === 0 && n < t.age ? t.expense : e.livingExpense;
      }), this.setApplicantReducedIncome(e) && (e.livingExpense = this.parameters.almostRetiredLivingExpense), e.cityDweller === !0 && (e.livingExpense = e.livingExpense * this.parameters.cityDwellerFactor);
    }), this.applicants.length > 1) {
      let e = this.applicants.map(n => n.occupationType);
      e.sort((n, t) => n.localeCompare(t)), this.parameters.livingExpenseDeduction.forEach(n => {
        n.class.sort(), n.class[0] === e[0] && n.class[1] === e[1] && (this.applicants[1].livingExpense = this.applicants[1].livingExpense - n.deduction);
      });
    }
    return this.applicants.map(e => e.livingExpense).reduce((e, n) => e + n, 0);
  }
  setIncomes() {
    this.monthlyNetIncome = 0, this.monthlyIncome = 0, this.monthlyCost = 0, this.monthlyTaxFreeIncome = 0, this.monthlyStudyLoan = 0, this.monthlyIncomeTax = 0, this.monthlyOtherCosts = 0;
    let e = this.incomes.map(n => n.monthlyIncome).reduce((n, t) => n + t, 0);
    this.incomes.forEach(n => {
      this.pMonthlyIncomeOverride > 0 ? (this.pOriginalIncome = n.monthlyIncome, n.monthlyIncome = n.monthlyIncome / e * this.pMonthlyIncomeOverride) : this.pOriginalIncome && (n.monthlyIncome = this.pOriginalIncome), this.getTax(n), this.monthlyNetIncome += n.monthlyNetIncome, this.monthlyIncome += n.monthlyTotalIncome, this.monthlyCost += n.monthlyTotalCost, this.monthlyTaxFreeIncome += n.taxFreeIncome, this.monthlyStudyLoan += n.studyLoan, this.monthlyIncomeTax += n.monthlyTax, this.monthlyOtherCosts += n.otherCosts;
    });
  }
  calculateTaxAge(e) {
    let n = new Date().getFullYear(),
      t = Number.parseInt(e.sub.substring(0, 4)),
      a = n - t;
    return e.age = a, a;
  }
  getTax(e) {
    let n = this.parameters.defaultTaxTable,
      t = this.getApplicantFromIncome(e),
      a = 0;
    t.occupationType === "RETIRED" && (a = 1);
    let s = I({
      occupation: a,
      income: e.monthlyIncome,
      table: n,
      taxYear: 2025,
      municipality: t.municipalityCode,
      county: t.countyCode,
      almostRetiredThreshold: this.parameters.almostRetiredThreshold,
      age: t.age
    });
    return e.taxColumn = s.taxColumn, e.monthlyReducedIncome = e.monthlyIncome, s.incomeAfterAgeReduction && (e.monthlyReducedIncome = s.incomeAfterAgeReduction, e.reducedIncome = !0), e.taxTable = s.table, e.monthlyTax = s.tax, e.taxFreeIncome = e.taxFreeIncome || 0, e.studyLoan = e.studyLoan || 0, e.otherCosts = e.otherCosts || 0, e.monthlyTotalIncome = e.monthlyReducedIncome + e.taxFreeIncome, e.monthlyTotalCost = s.tax + e.studyLoan + e.otherCosts, e.monthlyNetIncome = e.monthlyTotalIncome - e.monthlyTotalCost, s.tax;
  }
  getApplicantFromIncome(e) {
    return this.applicants.find(n => n.id === e.applicantId) || {
      occupationType: "EMPLOYED",
      sub: "197309193592"
    };
  }
  setApplicantReducedIncome(e) {
    let n = this.calculateTaxAge(e);
    return e.reducedIncome = n >= this.parameters.almostRetiredThreshold && e.occupationType !== "RETIRED", this.reducedIncome = this.reducedIncome || e.reducedIncome, e.reducedIncome;
  }
  calculateDebtQuota() {
    let e = this.loans.filter(a => a.loanType === "MORTGAGE").map(a => a.amount).reduce((a, s) => a + s, 0),
      t = this.incomes.map(a => a.monthlyIncome + a.taxFreeIncome).reduce((a, s) => a + s, 0) * 12 + this.childrenBenefitTotal * 12;
    return this.annualTotalIncome = t, this.totalMortgageLoanAmount = e, this.debtQuota = e / t, this.debtQuota;
  }
};
export { x as Kalpylator };
